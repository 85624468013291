import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { filter, map } from "lodash";
import { getSubSpecialties, areSubSpecialtiesLoading } from "app/main/specialtyProfiles/reducers/specialtyProfiles.reducers";
import { AutoComplete, AutoCompleteLoading } from "components/inputs";
import { fetchAllSubSpecialties } from "app/main/specialtyProfiles/actions/specialtyProfiles.actions";
import { SubSpecialtyIcon } from "helpers/icon-finder";
import { getSubSpecialtyLabel } from "app/auth/store/reducers/system-configuration";

export default ({
  specialtyProfileId,
  label,
  showIcon = true,
  ...other
}) => {
  const dispatch = useDispatch();
  let allSubSpecialties = useSelector(getSubSpecialties);
  const loading = useSelector(areSubSpecialtiesLoading);
  const subSpecialtyLabel = useSelector(getSubSpecialtyLabel);
  const displayLabel = label ?? subSpecialtyLabel;

  let options = [];

  React.useEffect(() => {
    dispatch(fetchAllSubSpecialties()); // only load if haven't been loaded
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <AutoCompleteLoading label={displayLabel} />;

  if (specialtyProfileId) {
    allSubSpecialties = filter(allSubSpecialties, x => x.specialtyProfileId === specialtyProfileId);
  }

  options = map(allSubSpecialties, x => ({ value: x.id, label: x.name }));

  return (
    <AutoComplete
      label={displayLabel}
      options={options}
      filterSelectedOptions
      icon={showIcon && <SubSpecialtyIcon />}
      {...other}
    />
  );
};
