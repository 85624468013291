/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, find, includes, compact, map } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Badge } from "@material-ui/core";

import { printReferralReport, fetchReferral } from "app/main/referrals/actions/referrals.actions";
import { isReferralLoading, getReferralError, getCurrentReferral } from "app/main/referrals/reducers/referrals.reducers";
import { getReferralActionTypeSettings } from "app/auth/store/reducers/system-configuration";
import { printActionType, cancelActionType, editActionType } from "app/main/referrals/helpers/action-type-names";
import { cancelledWorkflowState } from "app/main/referrals/helpers/workflow-state-names";
import ReferralHeader from "app/main/referrals/components/referral-header";
import DefaultTabBar from "components/items/default-tab-bar";
import withPermissions from "permissions/withPermissions";
import LoadingState from "components/items/loading-state";
import ErrorState from "components/items/error-state";
import ReferralAssignTo from "app/main/referralWorklist/components/referral-assign-to";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import AddReferralForm from "app/main/referrals/components/add-referral-form";
import downloadFromApi, { openPdf } from "utils/download-from-api";
import { RecordOutlineIcon } from "helpers/icon-finder";
import CancelReferralForm from "app/main/referrals/components/cancel-referral-form";
import IconButton from "components/items/icon-button";
import { OverviewPanel, DetailPanel, PreviewPanel, AttachmentPanel, AppointmentPanel, SourceDocumentPanel } from "app/main/referralWorklist/components/panels";
import PrintDialog from "components/items/print-dialog";

const TabBadge = withStyles(theme => ({
  badge: {
    transform: "none",
    position: "relative",
    marginLeft: theme.spacing(0.5),
  },
}))(Badge);

const Header = ({
  state,
  setState,
  referral,
  tabs,
  getActionSettings,
  hasPermissionReferralsEdit,
  hasPermissionReferralsView,
  hasPermissionReferralsPrint,
  hasPermissionReferralsPdfDownload,
  hasPermissionReferralsCancel,
  loading,
  showMedicalRecordButton,
}) => {
  const dispatch = useDispatch();

  if (loading) return <LoadingState />;

  const { id } = referral;
  const manualReferral = referral?.referralDocument.referralDocumentType === "Manual";
  const printActionLabel = getActionSettings(printActionType)?.nameSingle || "Print";
  const cancelActionSettings = getActionSettings(cancelActionType);

  const cancelActionLabel = cancelActionSettings?.nameSingle || "Cancel";
  const isCancelled = referral?.referralStatus === cancelledWorkflowState;
  const canCancel = hasPermissionReferralsCancel && includes(referral.availableActions, cancelActionType);
  const canEdit = hasPermissionReferralsEdit && includes(referral.availableActions, editActionType);

  const handleCloseDialog = () => dispatch(closeDialog());

  const handleTabChange = value => {
    setState(prev => ({ ...prev, currentTab: value }));
  };

  const onEditReferral = () => dispatch(openDialog({
    maxWidth: "xl",
    children: <AddReferralForm title="Edit Referral" currentReferral={referral} onSucceed={handleCloseDialog} isEdit shouldClearSourceDocumentFiles={state.currentTab !== 5} />,
  }));

  const downloadPdf = () => downloadFromApi(`api/referrals/GetPdf?referralId=${id}&recordedAction=${false}`);

  const handleCancel = () => {
    const cancelSettings = getActionSettings(cancelActionType);
    dispatch(openDialog({
      children: <CancelReferralForm title={cancelSettings?.formTitle || "Cancel Referral"} referralId={referral.id} onSucceed={handleCloseDialog} />,
    }));
  };

  return (
    <Grid container>
      <ReferralHeader referral={referral} showMedicalRecordButton={showMedicalRecordButton} />
      <DefaultTabBar
        tabs={tabs}
        currentTab={state.currentTab}
        onChange={(event, value) => handleTabChange(value)}
        content={<ReferralAssignTo referral={referral} />}
        rightContent={isCancelled ? <div /> : (
          <div>
            {(manualReferral && canEdit)
              && (
                <IconButton
                  onClick={onEditReferral}
                  icon={<RecordOutlineIcon />}
                  title="Edit"
                  disabled={!hasPermissionReferralsEdit}
                />
              )}
            <>
              <IconButton
                icon="print"
                title={printActionLabel}
                loading={state.generatingPDF}
                disabled={!hasPermissionReferralsPrint}
                onClick={() => setState(prev => ({ ...prev, showGeneratingPDF: true }))}
              />
              {hasPermissionReferralsPdfDownload && (
                <IconButton
                  onClick={downloadPdf}
                  icon="attachment"
                  title="Pdf"
                  disabled={!hasPermissionReferralsView}
                />
              )}
            </>
            {canCancel && (
              <IconButton
                onClick={handleCancel}
                icon={cancelActionSettings?.icon ?? "cancel"}
                title={cancelActionLabel}
              />
            )}
          </div>
        )}
      />
    </Grid>
  );
};

const Content = ({
  state,
  setState,
  referral,
  getActionSettings,
  width,
  loading,
  tabs,
}) => {
  const dispatch = useDispatch();
  const { id } = referral;
  const { currentTab, generatingPDF, showGeneratingPDF } = state;
  const referralLoadingError = useSelector(getReferralError);
  const pdfWidth = width > 960 ? 960 : width;

  if (loading) return <LoadingState />;

  if (referralLoadingError) {
    return <ErrorState title={referralLoadingError} />;
  }

  const handlePrintPopup = () => {
    setState(prev => ({ ...prev, generatingPDF: true }));
    dispatch(printReferralReport(id)).then(res => {
      if (!res.error) {
        openPdf(`api/referrals/GetPdf?referralId=${id}`).then(() => {
          setState(prev => ({ ...prev, generatingPDF: false, showGeneratingPDF: false }));
        });
      }
    });
  };

  const handleClosePrintDialog = () => setState(prev => ({ ...prev, showGeneratingPDF: false }));

  const printConfirmationPopup = () => {
    const printActionSettings = getActionSettings(printActionType);

    return (
      <PrintDialog
        open={showGeneratingPDF}
        loading={generatingPDF}
        title={printActionSettings?.formTitle}
        onCancel={handleClosePrintDialog}
        onClose={handleClosePrintDialog}
        content={(
          <>
            <Typography align="center" gutterBottom>{`Please confirm you wish to ${printActionSettings?.nameSingle?.toLowerCase() || "print"} the referral`}</Typography>
            {referral.attachmentCount > 0 && <Typography variant="body1" className="font-bold" align="center" gutterBottom>Please ensure all attachments have been automatically included in the print.</Typography>}
          </>
        )}
        printLabel={printActionSettings?.nameSingle}
        onPrint={handlePrintPopup}
      />
    );
  };

  return (
    <>
      {printConfirmationPopup()}
      {map(tabs, tab => {
        if (tab.key !== currentTab) return undefined;
        return <React.Fragment key={tab.key}>{tab.component({ referral, pdfWidth })}</React.Fragment>;
      })}
    </>
  );
};

const ReferralContent = ({ referralId, renderContent, hasPermissionAppointmentsView, ...other }) => {
  const dispatch = useDispatch();

  const INITIAL_STATE = {
    initialLoad: true,
    currentTab: 0,
    showGeneratingPDF: false,
  };
  const [state, setState] = useState(INITIAL_STATE);
  const loading = useSelector(isReferralLoading);
  const currentReferral = useSelector(getCurrentReferral);
  const referralActionSettings = useSelector(getReferralActionTypeSettings);
  const currentLoading = state.initialLoad || loading;

  const getActionSettings = actionType => find(referralActionSettings, x => x.actionType === actionType);

  const resetCurrentTab = () => {
    if (state.currentTab !== 0) {
      setState(prevState => ({ ...prevState, currentTab: 0 }));
    }
  };

  useEffect(() => {
    if ((referralId && isEmpty(currentReferral))) {
      dispatch(fetchReferral(referralId)).then(() => setState(prev => ({ ...prev, initialLoad: false })));
    } else {
      setState(prev => ({ ...prev, initialLoad: false }));
    }
  }, []);

  const getAttachmentLabel = x => {
    if (x && x.attachmentCount) {
      return (
        <TabBadge badgeContent={x.attachmentCount} color="secondary">
          Attachments
        </TabBadge>
      );
    }
    return "Attachments";
  };

  let tabs = compact([
    { label: "Overview", component: props => <OverviewPanel {...props} /> },
    { label: "Details", component: props => <DetailPanel {...props} /> },
    { label: "Source Document", component: props => <SourceDocumentPanel {...props} /> },
    { label: getAttachmentLabel(currentReferral), component: props => <AttachmentPanel {...props} /> },
    { label: "Preview", component: props => <PreviewPanel {...props} /> },
    hasPermissionAppointmentsView && { label: "Appointments", component: props => <AppointmentPanel {...props} /> },
  ]);

  tabs = map(tabs, (r, key) => ({ ...r, key }));

  const childrenProps = {
    referral: currentReferral,
    getActionSettings,
    state,
    setState,
    loading: currentLoading,
    tabs,
  };

  return renderContent({
    loading: currentLoading,
    currentReferral,
    currentTab: state.currentTab,
    resetTab: () => resetCurrentTab(),
    header: props => (
      <Header
        {...childrenProps}
        {...props}
        {...other}
      />
    ),
    content: props => (
      <Content
        {...childrenProps}
        {...props}
      />
    ),
  });
};

export default withPermissions(
  "ReferralsView",
  "ReferralsPrint",
  "ReferralsPdfDownload",
  "ReferralsEdit",
  "ReferralsCancel",
  "AppointmentsView",
)(ReferralContent);
