import React from "react";
import { Typography } from "@material-ui/core";
import CheckboxBlankCircleIcon from "mdi-react/CheckboxBlankCircleIcon";
import CheckboxBlankCircleOutlineIcon from "mdi-react/CheckboxBlankCircleOutlineIcon";
import { green, red } from "@material-ui/core/colors";

import DefaultButton from "components/items/default-button";
import { PanelHeaderAction } from "components/items/chat-panel";
import getPriorityColour from "app/main/patients/helpers/get-priority-colour";
import { ContextItem } from "components/items/default-item";

export const DateItem = ({ date, name }) => {
  const { isToday, dateTime } = date;
  return (
    <>
      <Typography variant="caption" className={isToday ? "font-bold" : ""}>{dateTime}</Typography>
      <Typography variant="caption" className="ml-8">{name}</Typography>
    </>
  );
};

export const ResolvedText = () => <Typography variant="caption" className="text-green-700 font-bold">Resolved </Typography>;

const button = ({ label = "Add", icon = "add", onClick, ...props }) => <DefaultButton fab label={label} icon={icon} onClick={onClick} {...props} />;

export const HeaderAction = ({ isRelative, ...props }) => {
  if (isRelative) {
    return button({ ...props });
  }
  return (
    <PanelHeaderAction>
      {button({ ...props })}
    </PanelHeaderAction>
  );
};

export const PriorityStatus = ({ priority }) => (
  <ContextItem
    icon="fiber_manual_record"
    iconColor={getPriorityColour(priority).color}
    content={priority}
  />
);

export const IsCurrentStatus = ({ status }) => (
  <div className="flex items-center">
    {status === "Current" ? <CheckboxBlankCircleIcon color={red[600]} size={20} /> : <CheckboxBlankCircleOutlineIcon color={green[600]} size={20} />}
    <div className="ml-8">
      <Typography color="textSecondary">{status}</Typography>
    </div>
  </div>
);
