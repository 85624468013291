import React from "react";

import InterventionListContainer from "app/main/patients/interventions/containers/intervention-list-container";
import InterventionList from "app/main/patients/interventions/components/intervention-list";
import { HeaderAction } from "app/main/patients/components/patient-components";
import PatientRecordPageContent from "app/main/patients/components/patient-record-page-content";

export default function PatientInterventionPage({ patientId }) {
  return (
    <InterventionListContainer
      patientId={patientId}
      renderContent={({ onRecordIntervention, ...other }) => (
        <PatientRecordPageContent pageAction={onRecordIntervention && <HeaderAction onClick={() => onRecordIntervention()} label="Add Intervention" />}>
          <InterventionList {...other} />
        </PatientRecordPageContent>
      )}
    />
  );
}
