import moment from "moment";
import { isEmpty } from "lodash";

export default function formatDaysFromNow(input) {
  if (isEmpty(input)) {
    return "";
  }

  const date = moment(input);

  if (!date.isValid()) {
    return "";
  }

  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");
  const tomorrow = moment().add(1, "days").startOf("day");

  if (date.isSame(today, "d")) {
    return "today";
  } if (date.isSame(yesterday, "d")) {
    return "1 day ago";
  } if (date.isSame(tomorrow, "d")) {
    return "tomorrow";
  }

  return date.fromNow();
}
