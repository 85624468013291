import React from "react";
import { useHistory } from "react-router-dom";
import withPermissions from "permissions/withPermissions";
import DefaultPageLayout from "components/layouts/default-page-layout";
import PatientsPageHeader from "../../components/patients-page-header";
import PatientsTable from "../../components/patients-table";

const PatientsIndexPage = ({
  hasPermissionPatientsMaintain,
}) => {
  const history = useHistory();
  return (
    <DefaultPageLayout
      accessDenied={!hasPermissionPatientsMaintain}
      header={(
        <PatientsPageHeader history={history} />
      )}
      content={
        <PatientsTable history={history} />
      }
    />
  );
};

export default withPermissions("PatientsMaintain")(PatientsIndexPage);
