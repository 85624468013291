import React from "react";

import ProcedureListContainer from "app/main/patients/procedures/containers/procedure-list-container";
import ProcedureList from "app/main/patients/procedures/components/procedure-list";
import { HeaderAction } from "app/main/patients/components/patient-components";
import PatientRecordPageContent from "app/main/patients/components/patient-record-page-content";

export default function PatientProcedurePage({ patientId }) {
  return (
    <ProcedureListContainer
      patientId={patientId}
      renderContent={({ onRecordProcedure, ...other }) => (
        <PatientRecordPageContent pageAction={onRecordProcedure && <HeaderAction onClick={onRecordProcedure} label="Add Procedure" />}>
          <ProcedureList {...other} />
        </PatientRecordPageContent>
      )}
    />
  );
}
