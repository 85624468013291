import React from "react";
import { withMobileDialog, Slide, Dialog } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "app/store/actions/dialog.actions";

const Transition = React.forwardRef((props, ref) => <Slide mountOnEnter unmountOnExit direction="up" ref={ref} {...props} />);

const DefaultDialog = props => {
  const dispatch = useDispatch();
  const state = useSelector(({ dialog }) => dialog.defaultDialog);
  const options = useSelector(({ dialog }) => dialog.options);

  return (
    <Dialog
      fullScreen={props.fullScreen}
      fullWidth
      disableEnforceFocus // prevent loss focus when render dialog on top of dialog
      onClose={() => dispatch(Actions.closeDialog())}
      onExited={() => dispatch(Actions.resetDialog())}
      open={state}
      TransitionComponent={Transition}
      keepMounted
      {...options}
    />
  );
};

export default withMobileDialog({ breakpoint: "xs" })(DefaultDialog);
