import { get, concat, find, orderBy, filter, take } from "lodash";
import curryConnector from "utils/connectors";

export const PROCEDURES_STATE_KEY = "procedures";

const curry = fn => curryConnector(fn, PROCEDURES_STATE_KEY);

export const ActionTypes = {
  LOADING_PROCEDURES: "LOADING_PROCEDURES",
  LOADED_PROCEDURES: "LOADED_PROCEDURES",
  ERROR_LOADING_PROCEDURES: "ERROR_LOADING_PROCEDURES",

  LOADING_CURRENT_PROCEDURE: "LOADING_CURRENT_PROCEDURE",
  ERROR_LOADING_CURRENT_PROCEDURE: "ERROR_LOADING_CURRENT_PROCEDURE",
  LOADED_CURRENT_PROCEDURE: "LOADED_CURRENT_PROCEDURE",

  SAVED_PROCEDURE: "SAVED_PROCEDURE",
  CREATED_PROCEDURE: "CREATED_PROCEDURE",
  DELETED_PROCEDURE: "DELETED_PROCEDURE",
};

const INITIAL_STATE = {
  all: {},
  current: null,
};

const addOrUpdateProcedure = (state, action) => {
  const { payload: { procedure, patientId } } = action;
  let procedures = get(state.all, [patientId, "procedures"], []);
  procedures = orderBy(concat(filter(procedures, x => x.id !== procedure.id), [procedure]), [p => p.recordedDateTimeUtc || ""], ["desc"]);

  return {
    ...state,
    current: procedure,
    all: { ...state.all, [patientId]: { ...state.all[patientId], procedures } },
  };
};

const removeProcedure = (state, action) => {
  const { payload: { procedure, patientId } } = action;
  let procedures = get(state.all, [patientId, "procedures"], []);
  procedures = orderBy(filter(procedures, x => x.id !== procedure.id), [p => p.recordedDateTimeUtc || ""], ["desc"]);

  return {
    ...state,
    all: { ...state.all, [patientId]: { ...state.all[patientId], procedures } },
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_PROCEDURES:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: true } },
      };
    case ActionTypes.ERROR_LOADING_PROCEDURES:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: false, error: action.payload.message } },
      };
    case ActionTypes.LOADED_PROCEDURES:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]:
            {
              ...state.all[action.payload.patientId],
              loading: false,
              procedures: action.payload.procedures,
            },
        },
      };
    case ActionTypes.LOADING_CURRENT_PROCEDURE:
      return {
        ...state,
        current: {
          ...state.current,
          loading: true,
        },
      };
    case ActionTypes.ERROR_LOADING_CURRENT_PROCEDURE:
      return {
        ...state,
        current: {
          ...state.current,
          error: action.error,
        },
      };
    case ActionTypes.LOADED_CURRENT_PROCEDURE:
      return {
        ...state,
        current: action.payload.procedure,
      };
    case ActionTypes.SAVED_PROCEDURE:
    case ActionTypes.CREATED_PROCEDURE:
      return addOrUpdateProcedure(state, action);
    case ActionTypes.DELETED_PROCEDURE:
      return removeProcedure(state, action);
    case ActionTypes.SET_CURRENT_PROCEDURE:
      return {
        ...state,
        current: action.payload.procedure,
      };
    default:
      return state;
  }
};

export const areProceduresLoading = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loading"], false));

export const areProceduresLoaded = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loaded"], false));

export const proceduresLoadingError = curry(({ localState }, patientId) => get(localState, ["all", patientId, "error"], null));

export const getProceduresByPatientId = curry(({ localState }, patientId) => get(localState, ["all", patientId, "procedures"], []));

export const getProcedureById = curry(({ localState }, patientId, procedureId) => {
  const procedures = get(localState, ["all", patientId, "procedures"], []);
  return find(procedures, x => x.id === procedureId) || {};
});

export const getCurrentProcedure = curry(({ localState }) => get(localState, ["current"], null));

export const isCurrentProcedureLoading = curry(({ localState }) => get(localState, ["current", "loading"], false));

export const getRecentProcedures = curry(({ localState }, patientId, number) => {
  const procedures = get(localState, ["all", patientId, "procedures"], []);
  return take(procedures, number);
});

export const getErrorMessage = curry(({ localState }, patientId) => get(localState, ["all", patientId, "error"], null));
