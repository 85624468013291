import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExistingPatientById } from "app/main/patients/actions/patients.actions";
import { getCurrentPatient, isCurrentPatientLoading } from "app/main/patients/reducers/patients.reducers";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import AddReferralForm from "app/main/referrals/components/add-referral-form";

const useAddPatientReferral = ({
  patientId,
  onSucceed,
}) => {
  const dispatch = useDispatch();
  const currentPatientLoading = useSelector(isCurrentPatientLoading);
  const patient = useSelector(getCurrentPatient);

  useEffect(() => {
    if (!currentPatientLoading && patient?.patientId !== patientId) {
      dispatch(getExistingPatientById(patientId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => dispatch(closeDialog());

  const createdReferral = () => Promise.resolve(onSucceed()).then(() => onClose());

  const onAddReferral = () => dispatch(openDialog({
    maxWidth: "xl",
    children: (
      <AddReferralForm
        patient={patient}
        onCancel={onClose}
        onSucceed={createdReferral}
        title="Add Referral"
      />
    ),
  }));

  return ({
    openAddReferralForm: () => (onSucceed ? onAddReferral() : null),
  });
};

export default useAddPatientReferral;
