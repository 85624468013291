import React from "react";
import Select from "react-select";
import { isString, filter, get, find } from "lodash";

import BaseTextInput from "./base-text-input";
import SelectStyles from "./styles";

class SelectInput extends React.Component {
  onChange = option => {
    const { onChange, field, form: { setFieldValue } } = this.props;

    if (option) {
      setFieldValue(field.name, option.value);
    } else {
      setFieldValue(field.name, null);
    }

    if (onChange) {
      onChange(option, setFieldValue);
    }
  }

  render() {
    const {
      field,
      label,
      form: { touched, errors },
      icon,
      options,
      disabled,
      isClearable,
      inputProps,
      ...other
    } = this.props;

    const inputValue = isString(field.value)
      ? filter(options, option => option.value === field.value || option.label === field.value)
      : field.value;

    const selectedOption = find(options, option => option.value === field.value);
    const optionIcon = get(selectedOption, "icon", null);

    const SelectInputComponent = () => (
      <Select
        options={options}
        styles={SelectStyles}
        isClearable
        isSearchable={false}
        menuPortalTarget={document.body}
        placeholder=""
        onChange={option => this.onChange(option)}
        value={inputValue}
        isDisabled={disabled}
        {...inputProps}
      />
    );

    return (
      <BaseTextInput
        inputComponent={SelectInputComponent}
        disabled={disabled}
        value={inputValue}
        icon={optionIcon || icon}
        label={label}
        error={get(errors, field.name, null)}
        touched={get(touched, field.name, null)}
        {...other}
      />
    );
  }
}

export default SelectInput;
