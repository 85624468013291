import { isEmpty, mapKeys, map, isArray, isString, isBoolean, isNumber } from "lodash";

const checkForNoneEmpty = value => !isEmpty(value) || value === true || value === false || isNumber(value);

const formatSearchParams = params => {
  let url = "";
  if (!isEmpty(params)) {
    mapKeys(params, (value, key) => {
      if (checkForNoneEmpty(value)) {
        if (isString(value) || isBoolean(value) || isNumber(value)) {
          url += `&${key}=${encodeURIComponent(value)}`;
        } else if (isArray(value)) {
          map(value, x => {
            if (checkForNoneEmpty(x)) {
              url += `&${key}=${encodeURIComponent(x)}`;
            }
          });
        } else {
          formatSearchParams(value);
        }
      }
    });
  }
  return url;
};

export default formatSearchParams;
