import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TableCell, TableRow } from "@material-ui/core";
import { fetchSpecialtyProfilesResults, getSpecialtyProfile } from "app/main/specialtyProfiles/actions/specialtyProfiles.actions";
import { SPECIALTY_PROFILES_PAGE_SIZE_OPTIONS, SPECIALTY_PROFILES_PAGE_SIZE, getAllSpecialtyProfiles, isPageLoading, getErrorMessage, getSpecialtyProfileSearchTerms, getPageInfo } from "app/main/specialtyProfiles/reducers/specialtyProfiles.reducers";
import PagingTable from "components/items/paging-table";

const OrgUnitSpecialtyProfilesPageContent = ({ id, orgUnit, history }) => {
  const dispatch = useDispatch();
  const specialtyProfiles = useSelector(getAllSpecialtyProfiles);
  const loading = useSelector(isPageLoading);
  const error = useSelector(getErrorMessage);
  const pageInfo = useSelector(getPageInfo);
  const searchParams = useSelector(getSpecialtyProfileSearchTerms);

  const searchOrgUnitSpecialtyProfiles = (pageNumber, pageSize, reload) => dispatch(fetchSpecialtyProfilesResults(
    id, pageNumber, pageSize, { ...searchParams }, reload || history.location.state?.forceLoad === true,
  ));

  useEffect(() => {
    searchOrgUnitSpecialtyProfiles(1, SPECIALTY_PROFILES_PAGE_SIZE, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeRowsPerPage = pageSize => searchOrgUnitSpecialtyProfiles(1, pageSize);
  const onChangePage = pageNumber => searchOrgUnitSpecialtyProfiles(pageNumber + 1, pageInfo.pageSize);
  const onEdit = specialtyProfilesId => dispatch(getSpecialtyProfile(id, specialtyProfilesId)).then(() => {
    history.push(`/admin/org-units/${orgUnit.orgUnitType}/${id}/specialtyProfiles/${specialtyProfilesId}`);
  });

  const renderTableRow = specialtyProfile => (
    <TableRow
      hover
      key={specialtyProfile.id}
      onClick={() => onEdit(specialtyProfile.id)}
    >
      <TableCell>{specialtyProfile.name}</TableCell>
    </TableRow>
  );

  return (
    <PagingTable
      loading={loading}
      error={error}
      data={specialtyProfiles}
      rowsPerPage={pageInfo.pageSize}
      pageSizeOptions={SPECIALTY_PROFILES_PAGE_SIZE_OPTIONS}
      page={pageInfo.pageNumber - 1}
      count={pageInfo.totalRecords}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      tableRow={item => renderTableRow(item)}
      emptyMessage="No Specialty Profile Recorded"
    />
  );
};

export default OrgUnitSpecialtyProfilesPageContent;
