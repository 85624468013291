import React, { useState } from "react";
import { Provider as StoreProvider } from "react-redux";

import ThemeProvider from "app/theme/theme-provider";

import store from "./store";
import AppContext from "./AppContext";
import StartUp from "./start-up";

const App = () => {
  const [backURL, setBackURL] = useState(null);

  return (
    <AppContext.Provider
      value={{
        backURL,
        setBackURL,
      }}
    >
      <StoreProvider store={store}>
        <ThemeProvider>
          <StartUp />
        </ThemeProvider>
      </StoreProvider>
    </AppContext.Provider>
  );
};

export default App;
