import React from "react";
import { isEmpty, every } from "lodash";
import { PatientAgeLabel, NameLabel, TextLabel, PatientIdentifierLabel, AddressDetailsLabel, EmailLabel, ContactDetailsLabel } from "app/main/patients/components/patient-details";
import TextLink from "components/items/text-link";

/**
 * This component will share between referral worklist and patient referral worklist
 */
const ReferralPatientDetails = ({
  patient,
  goToPatientRecordPage,
  dobProps,
  identifierProps,
}) => {
  if (!patient) return null;
  const { nextOfKin, birthDate, sex, isDead, patientId, patientIdentifiers, address, contactDetails, displayNameFull } = patient;

  let nok = "";

  if (!isEmpty(nextOfKin) && every(nextOfKin, x => x !== null)) {
    nok = `${nextOfKin.givenName} ${nextOfKin.familyName} ${nextOfKin.contact}`;
  }

  return (
    <div className="items-container">
      <div className="flex-row-container with-gutter">
        <NameLabel name={displayNameFull} onClick={goToPatientRecordPage} />
        <PatientAgeLabel birthDate={birthDate} formatDOB {...dobProps} />
        <TextLabel className="font-bold" content={sex?.label ?? sex ?? ""} />
        <TextLabel content={isDead && "Deceased"} />
      </div>
      <PatientIdentifierLabel patientId={patientId} patientIdentifiers={patientIdentifiers} {...identifierProps} />
      <div className="flex-row-container with-gutter">
        <AddressDetailsLabel address={address} />
        <EmailLabel email={!isEmpty(contactDetails) ? contactDetails.email : null} />
        <ContactDetailsLabel contact={contactDetails} />
        {nok && <TextLink label="NOK" content={nok} />}
      </div>
    </div>
  );
};

export default ReferralPatientDetails;
