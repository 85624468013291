import React from "react";
import { isEmpty, size } from "lodash";
import { Field } from "formik";
import { connect } from "react-redux";
import { getYear, getMonth } from "date-fns";
import CalendarTextOutlineIcon from "mdi-react/CalendarTextOutlineIcon";
import CommentAlertOutlineIcon from "mdi-react/CommentAlertOutlineIcon";
import CommentTextMultipleOutlineIcon from "mdi-react/CommentTextMultipleOutlineIcon";
import ChartLineIcon from "mdi-react/ChartLineIcon";
import TimetableIcon from "mdi-react/TimetableIcon";
import TriangleOutlineIcon from "mdi-react/TriangleOutlineIcon";
import PoundIcon from "mdi-react/PoundIcon";

import { TODAY } from "utils/date";
import LoadingState from "components/items/loading-state";
import { getDecisionStatusValues, getDecimalNumberRegex, getDecimalPlacesRegex } from "app/auth/store/reducers/system-configuration";
import { DatePicker, TextInput, TextAreaInput, NoteInput, CurrencyInput, SelectInput, CheckboxInput } from "components/inputs";
import { onSaveOrUpdateDecision, onDeleteDecision } from "app/main/applications/actions/applications.actions";
import Form from "components/form";
import useFormFieldConfig from "hooks/use-form-field-config";
import generateSchema from "./yup-schema";

const generateFieldLabels = fieldSettings => {
  const getFieldLabel = (key, defaultLabel) => ({ [key]: fieldSettings?.[key]?.label || defaultLabel });

  const fieldLabels = {
    ...getFieldLabel("status", "Status"),
    ...getFieldLabel("totalCost", "Total Cost"),
    ...getFieldLabel("approvalDuration", "Approval Duration"),
    ...getFieldLabel("urgentTreatmentApproval", "Urgent Treatment Approval"),
    ...getFieldLabel("compassionateAccess", "Compassionate Access"),
    ...getFieldLabel("outOfSessionAssessment", "Out of Session Assessment"),
    ...getFieldLabel("reportingYear", "Reporting Year (FY)"),
    ...getFieldLabel("outcomeMeasures", "Outcome Measures"),
    ...getFieldLabel("restrictionDetails", "Restriction Details"),
    ...getFieldLabel("notes", "Internal Notes"),
    ...getFieldLabel("commentsForDecisionLetter", "Comments for Decision Letter"),
    ...getFieldLabel("agendaItemNumber", "Agenda Item Number"),
    ...getFieldLabel("decisionRationale", "Decision Rationale"),
    ...getFieldLabel("decisionDate", "Decision Date"),
    ...getFieldLabel("isRatified", "Ratified"),
    ...getFieldLabel("expiryDate", "Expiry Date"),
  };

  return fieldLabels;
};

const generateMeetingDate = date => {
  const meetingDate = new Date(date);
  const meetingDateYear = getYear(meetingDate);
  const meetingDateMonth = getMonth(meetingDate) + 1;
  return { meetingDateMonth, meetingDateYear };
};

const generateDecision = applicationDecision => {
  const { meetingDateMonth, meetingDateYear } = generateMeetingDate(applicationDecision.meetingDate);
  const reportingYear = applicationDecision.reportingYear || (meetingDateMonth > 6 ? meetingDateYear : meetingDateYear - 1);

  return {
    ...applicationDecision,
    reportingYear,
    endOfFinancialYear: reportingYear + 1,
    decisionDate: applicationDecision.decisionDate || TODAY,
    status: applicationDecision.status || null,
  };
};

const DecisionForm = props => {
  const {
    canDelete,
    canViewInternalNotes,
    decisionOptions,
    canMarkRatifiedDecision,
    decision: applicationDecision,
    orgUnitId,
    decimalNumberRegex,
    decimalPlacesRegex,
    ...other
  } = props;

  const { fieldSettings, loadingFieldSettings } = useFormFieldConfig("CreateEditDecision");
  const fieldLabels = generateFieldLabels(fieldSettings);
  const schema = generateSchema(fieldLabels, fieldSettings, decimalNumberRegex, decimalPlacesRegex);
  const decision = generateDecision(applicationDecision);

  const handleSubmit = (values, { setErrors }) => {
    const { id } = props;

    props.onSaveOrUpdateDecision(id, orgUnitId, values.decision).then(response => {
      if (response.error !== true) {
        props.onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  const handleDelete = ({ setErrors }) => {
    props.onDeleteDecision(props.id, props.decision.id, orgUnitId).then(response => {
      if (response.error !== true) {
        props.onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  if (loadingFieldSettings) return (<LoadingState />);

  return (
    <Form
      initialValues={{ decision }}
      onSubmit={handleSubmit}
      contentProps={{ ...other, confirmationMessage: "Remove this decision? All decision details will be lost." }}
      onRemove={!isEmpty(decision.status) && canDelete ? (formProps => handleDelete(formProps)) : null}
      validationSchema={schema}
      content={({ setFieldValue }) => (
        <>
          <Field
            name="decision.status"
            component={SelectInput}
            label={fieldLabels.status}
            options={decisionOptions}
            icon={<TriangleOutlineIcon />}
            required
          />
          <Field
            name="decision.decisionDate"
            component={DatePicker}
            label={fieldLabels.decisionDate}
            required
          />
          {fieldSettings?.expiryDate && (
          <Field
            name="decision.expiryDate"
            component={DatePicker}
            required={fieldSettings.expiryDate.required}
            label={fieldLabels.expiryDate}
          />
          )}
          <div className="flex">
            {fieldSettings?.totalCost && (
            <Field
              name="decision.totalCost"
              component={CurrencyInput}
              label={fieldLabels.totalCost}
              required={fieldSettings.totalCost.required}
              className="flex-1"
            />
            )}
            {fieldSettings?.approvalDuration && (
            <Field
              name="decision.approvalDuration"
              component={TextInput}
              label={fieldLabels.approvalDuration}
              required={fieldSettings.approvalDuration.required}
              className="flex-1"
              icon={<TimetableIcon />}
            />
            )}
          </div>
          <div className="flex flex-col">
            {canMarkRatifiedDecision && fieldSettings?.isRatified
              && (
              <Field
                name="decision.isRatified"
                component={CheckboxInput}
                label={fieldLabels.isRatified}
                required={fieldSettings.isRatified.required}
              />
              )}
            {fieldSettings?.urgentTreatmentApproval && (
            <Field
              name="decision.urgentTreatmentApproval"
              component={CheckboxInput}
              label={fieldLabels.urgentTreatmentApproval}
              required={fieldSettings.urgentTreatmentApproval.required}
            />
            )}
            {fieldSettings?.compassionateAccess && (
            <Field
              name="decision.compassionateAccess"
              component={CheckboxInput}
              label={fieldLabels.compassionateAccess}
              required={fieldSettings.compassionateAccess.required}
            />
            )}
            {fieldSettings?.outOfSessionAssessment && (
            <Field
              name="decision.outOfSessionAssessment"
              component={CheckboxInput}
              label={fieldLabels.outOfSessionAssessment}
              required={fieldSettings.outOfSessionAssessment.required}
            />
            )}
          </div>
          {fieldSettings?.agendaItemNumber && (
          <Field
            name="decision.agendaItemNumber"
            required={fieldSettings.agendaItemNumber.required}
            label={fieldLabels.agendaItemNumber}
            component={TextInput}
            icon={<PoundIcon />}
          />
          )}
          <div className="flex">
            {fieldSettings?.reportingYear && (
            <Field
              name="decision.reportingYear"
              component={TextInput}
              required={fieldSettings.reportingYear.required}
              label={fieldLabels.reportingYear}
              icon={<CalendarTextOutlineIcon />}
              onChange={value => {
                if (size(value) === 4) {
                  const date = new Date(value);
                  setFieldValue("decision.endOfFinancialYear", date.getFullYear() + 1);
                }
              }}
              className="flex-1"
            />
            )}
            {fieldSettings?.endOfFinancialYear && (
            <Field
              name="decision.endOfFinancialYear"
              component={TextInput}
              required={fieldSettings.endOfFinancialYear.required}
              disabled
              className="flex-1"
            />
            )}
          </div>
          {fieldSettings?.outcomeMeasures && (
          <Field
            name="decision.outcomeMeasures"
            component={NoteInput}
            required={fieldSettings.outcomeMeasures.required}
            label={fieldLabels.outcomeMeasures}
            icon={<ChartLineIcon />}
            maxLength={255}
          />
          )}
          {fieldSettings?.restrictionDetails && (
          <Field
            name="decision.restrictionDetails"
            component={TextAreaInput}
            required={fieldSettings.restrictionDetails.required}
            label={fieldLabels.restrictionDetails}
            icon={<CommentAlertOutlineIcon />}
            maxLength={500}
          />
          )}
          {canViewInternalNotes && fieldSettings?.notes && (
          <Field
            name="decision.notes"
            component={NoteInput}
            required={fieldSettings.notes.required}
            label={fieldLabels.notes}
            maxLength={2000}
          />
          )}
          {fieldSettings?.commentsForDecisionLetter && (
          <Field
            name="decision.commentsForDecisionLetter"
            component={TextAreaInput}
            required={fieldSettings.commentsForDecisionLetter.required}
            label={fieldLabels.commentsForDecisionLetter}
            icon={<CommentTextMultipleOutlineIcon />}
            maxLength={2000}
          />
          )}
          {fieldSettings?.decisionRationale && (
          <Field
            name="decision.decisionRationale"
            component={TextAreaInput}
            required={fieldSettings.decisionRationale.required}
            label={fieldLabels.decisionRationale}
            icon={<CommentTextMultipleOutlineIcon />}
            maxLength={2000}
          />
          )}
        </>
      )}
    />
  );
};

const mapStateToProps = state => ({
  decisionOptions: getDecisionStatusValues(state),
  decimalNumberRegex: getDecimalNumberRegex(state),
  decimalPlacesRegex: getDecimalPlacesRegex(state),
});

export default connect(mapStateToProps, {
  onSaveOrUpdateDecision, onDeleteDecision,
})(DecisionForm);
