import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Field } from "formik";

import { withdrawEnrolment } from "app/main/patients/actions/patients.actions";
import Form from "components/form";
import { NoteInput } from "components/inputs";

const reasonLabel = "Reason";

const schema = Yup.object().shape({
  withdrawal: Yup.object().shape({
    reason: Yup.string()
      .max(255, "Too Long!")
      .nullable(),
  }),
});

const PatientWithDrawnForm = ({
  patientId,
  enrolmentType,
  onSucceed,
  ...other
}) => {
  const dispatch = useDispatch();

  const handleSubmit = ({ withdrawal }, { setErrors }) => {
    const enrolment = {
      reason: withdrawal.reason,
      enrolmentType,
    };
    dispatch(withdrawEnrolment(patientId, enrolment)).then((response => {
      if (response.error === true) {
        setErrors(response.payload);
      } else {
        onSucceed();
      }
    }));
  };

  const { withdrawal } = schema.cast();

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ withdrawal }}
      validationSchema={schema}
      contentProps={other}
      content={() => (
        <Field
          name="withdrawal.reason"
          component={NoteInput}
          label={reasonLabel}
          maxLength={255}
        />
      )}
    />
  );
};

export default PatientWithDrawnForm;
