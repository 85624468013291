import React from "react";
import { map } from "lodash";
import { Typography } from "@material-ui/core";

import EncounterListItem from "app/main/patients/encounters/components/encounter-list-item";
import StateContainer from "components/layouts/default-state-container";
import { getEncounterAssessmentButtonTitle, getEncounterEditTitle } from "app/main/patients/helpers/suggested-encounter-label";

const EncounterList = ({
  stateProps,
  ...other
}) => {
  if (stateProps === undefined) {
    return <EncounterListComponent {...other} />;
  }
  return <EncounterListWithState {...stateProps} {...other} />;
};

const EncounterListComponent = ({
  encounters,
  onEditEncounter,
  onRemoveEncounter,
  onCreateAssessment,
}) => (
  map(encounters, (encounter, index) => (
    <EncounterListItem
      type="encounter"
      item={encounter}
      key={encounter.id}
      isFirst={index === 0}
      assignedBy={encounter.participantDisplayName}
      buttonTitle={getEncounterEditTitle(encounter.encounterType)}
      confirmRemoveTitle="Remove Encounter"
      // actions
      onEdit={onEditEncounter ? () => onEditEncounter(encounter.id, getEncounterEditTitle(encounter.encounterType)) : null}
      onRemove={onRemoveEncounter ? () => onRemoveEncounter(encounter.id) : null}
      onCreateAssessment={onCreateAssessment && (encounter.encounterType === "InitialAssessment" || encounter.encounterType === "FollowUpAssessment") ? () => onCreateAssessment({
        assessmentType: encounter.encounterType,
        encounterId: encounter.id,
        assessmentId: encounter.assessmentId,
      }) : null}
      recordButtonTitle={getEncounterAssessmentButtonTitle(encounter.assessmentStatus)}
    />
  ))
);

export const EncounterListWithState = ({
  title,
  isEmpty,
  isError,
  isLoading,
  emptyTitle = "No Encounters",
  ...other
}) => (
  <StateContainer loading={isLoading} error={isError} empty={isEmpty} emptyTitle={emptyTitle}>
    {title && <Typography className="px-16" gutterBottom>{title}</Typography>}
    <EncounterListComponent {...other} />
  </StateContainer>
)

export default EncounterList;
