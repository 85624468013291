import React from "react";
import { Button, CircularProgress, Tooltip, Box, Fab } from "@material-ui/core";
import clsx from "clsx";

import IconComponent from "components/items/icon-component";

const DefaultButton = ({
  icon,
  label,
  onClick,
  loading,
  size,
  disabled,
  tooltip,
  fab,
  className,
  component: Component = fab ? Fab : Button,
  component: Container = tooltip ? Tooltip : React.Fragment,
  ...other
}) => {
  const containerProps = tooltip ? { title: tooltip } : null;

  return (
    <Container {...containerProps}>
      <div className={className}>
        <Component
          variant={fab ? "extended" : "contained"}
          color="secondary"
          onClick={onClick}
          size={size}
          disabled={loading || disabled}
          className="w-full"
          {...other}
        >
          <Box className={clsx("pointer-events-none flex items-center justify-center", fab && "px-8")}>
            {loading && <div className="flex items-center mr-16"><CircularProgress size={24} /></div>}
            {icon && <div className={clsx("flex items-center", label && "mr-8")}><IconComponent icon={icon} /></div>}
            {label}
          </Box>
        </Component>
      </div>
    </Container>
  );
};

DefaultButton.defaultProps = {
  size: "small",
};

export default DefaultButton;
