import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TableCell, TableRow } from "@material-ui/core";
import { isEmpty } from "lodash";

import PagingTable from "components/items/paging-table";
import { USERS_PAGE_SIZE_OPTIONS, getAllUsers, isPageLoading, getErrorMessage, getUserSearchTerms, getPageInfo } from "app/main/users/reducers/users.reducers";
import Avatar from "components/items/avatar";
import withPermissions from "permissions/withPermissions";
import TextTag from "components/items/text-tag";

import { fetchUsersResults, getExistingUserById } from "../actions/users.actions";

const UsersTable = ({
  history,
  hasPermissionUsersMaintainSpecialtyProfile,
  orgUnit,
  hasPermissionUsersMaintain,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(isPageLoading);
  const error = useSelector(getErrorMessage);
  const users = useSelector(getAllUsers);
  const pageInfo = useSelector(getPageInfo);
  const searchParams = useSelector(getUserSearchTerms);
  const enableUpdate = hasPermissionUsersMaintain;
  const orgUnitId = orgUnit?.id;

  useEffect(() => {
    dispatch(fetchUsersResults(1, 50, null, false, history.location.state?.forceLoad === true || false));
  }, [dispatch, history]);

  const loadUserResult = (pageNumber, pageSize, forceLoad = true) => dispatch(fetchUsersResults(pageNumber, pageSize, searchParams, false, forceLoad));
  const onChangeRowsPerPage = pageSize => loadUserResult(1, pageSize);
  const onChangePage = pageNumber => loadUserResult(pageNumber + 1, pageInfo.pageSize);
  const onEdit = id => dispatch(getExistingUserById(orgUnitId, id)).then(() => history.push(`/admin/users/${id}`));

  const renderTableHeader = () => (
    <TableRow>
      <TableCell />
      <TableCell>Name</TableCell>
      <TableCell>Username</TableCell>
      <TableCell>{hasPermissionUsersMaintainSpecialtyProfile ? "Specialty" : "Email Address"}</TableCell>
    </TableRow>
  );

  const renderTableRow = user => (
    <TableRow
      hover={enableUpdate}
      key={user.userId}
      onClick={enableUpdate ? () => onEdit(user.userId) : null}
    >
      <TableCell className="w-52" component="th" scope="row" padding="none">
        <div className="flex justify-center py-8 px-16">
          <Avatar />
          {!isEmpty(user.inactiveDateTimeUtc) && (
          <TextTag label="INACTIVE" variant="warning" type="badge" />
          )}
        </div>
      </TableCell>
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.username}</TableCell>
      <TableCell>{hasPermissionUsersMaintainSpecialtyProfile ? user.specialtyProfile?.name : user.email}</TableCell>
    </TableRow>
  );

  return (
    <PagingTable
      loading={loading}
      error={error}
      data={users}
      rowsPerPage={pageInfo.pageSize}
      columnSpan={5}
      pageSizeOptions={USERS_PAGE_SIZE_OPTIONS}
      page={pageInfo.pageNumber - 1}
      count={pageInfo.totalRecords}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      emptyMessage="No Users Recorded"
      tableHead={renderTableHeader}
      tableRow={item => renderTableRow(item)}
    />
  );
};

export default withPermissions("UsersMaintain", "UsersMaintainSpecialtyProfile")(UsersTable);
