import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core";
import { drawer } from "components/styles/base";

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawer.width,
    },
  },
}));

export default ({
  content,
  ...other
}) => {
  const classes = useStyles();
  return (
    <Drawer
      classes={{ paper: classes.drawer }}
      {...other}
    >
      <div className="flex flex-col overflow-hidden h-full">
        {content}
      </div>
    </Drawer>
  );
};
