import routesConfig from "app/routesConfig";

const initialState = routesConfig;

const routes = (state = initialState, action) => {
  switch (action.type) {
    default:
    {
      return state;
    }
  }
};

export default routes;
