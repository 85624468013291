import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TableCell, TableRow } from "@material-ui/core";

import PagingTable from "components/items/paging-table";
import { formatUtcDate } from "helpers/format-date-time";
import { PreferredShortDateTimeFormat } from "utils/date";
import { getPatientLabel } from "utils/get-environment-variables";
import TextTag from "components/items/text-tag";
import { PatientSummary } from "app/main/patients/components/patient-details";

import { getPageInfo, getSearchParams, isPageLoading, getErrorMessage, getPatientAccessLogs, PATIENT_ACCESS_LOG_PAGE_SIZE } from "../reducers/patientAccessLog.reducers";
import { fetchPatientAccessLogResults } from "../actions/patientAccessLog.actions";

const patientLabel = getPatientLabel();

const PatientAccessLogTable = ({ history }) => {
  const dispatch = useDispatch();
  const loading = useSelector(isPageLoading);
  const error = useSelector(getErrorMessage);
  const pageInfo = useSelector(getPageInfo);
  const searchParams = useSelector(getSearchParams);
  const patientAccessLogs = useSelector(getPatientAccessLogs);

  useEffect(() => {
    dispatch(fetchPatientAccessLogResults(1, PATIENT_ACCESS_LOG_PAGE_SIZE, null, false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeRowsPerPage = pageSize => dispatch(fetchPatientAccessLogResults(1, pageSize, searchParams));
  const onChangePage = pageNumber => dispatch(fetchPatientAccessLogResults(pageNumber + 1, pageInfo.pageSize, searchParams));
  const goToPatientRecordPage = patientId => history.push(`/patientAccess/patients/${patientId}`);
  const renderTableHeader = () => (
    <TableRow>
      <TableCell className="w-160">Accessed</TableCell>
      <TableCell>{patientLabel.single}</TableCell>
      <TableCell>User</TableCell>
      <TableCell>Username</TableCell>
    </TableRow>
  );

  const renderTableRow = log => (
    <TableRow hover key={log.id} onClick={() => goToPatientRecordPage(log.patient.patientId)}>
      <TableCell>{formatUtcDate(log.accessedDateTimeUtc, PreferredShortDateTimeFormat)}</TableCell>
      <TableCell>
        <PatientSummary patient={log.patient} inlineContent />
        {log.patient.isDeprecated && <TextTag label="DEPRECATED" variant="error" />}
        {log.patient.isRemoved && <TextTag label="REMOVED" variant="warning" />}
      </TableCell>
      <TableCell>{`${log.user?.firstName} ${log.user?.lastName}`}</TableCell>
      <TableCell>{log.user.username}</TableCell>
    </TableRow>
  );

  return (
    <PagingTable
      loading={loading}
      error={error}
      data={patientAccessLogs}
      rowsPerPage={pageInfo?.pageSize}
      columnSpan={5}
      page={pageInfo?.pageNumber - 1}
      count={pageInfo?.totalRecords}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      emptyMessage={`No ${patientLabel.single} Access Records`}
      tableHead={renderTableHeader}
      tableRow={item => renderTableRow(item)}
    />
  );
};
export default PatientAccessLogTable;
