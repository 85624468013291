import AccountArrowLeftIcon from "mdi-react/AccountArrowLeftIcon";
import MessageTextOutlineIcon from "mdi-react/MessageTextOutlineIcon";
import CommentQuestionOutlineIcon from "mdi-react/CommentQuestionOutlineIcon";
import AccountDetailsIcon from "mdi-react/AccountDetailsOutlineIcon";
import CursorPointerIcon from "mdi-react/CursorPointerIcon";
import BeakerOutlineIcon from "mdi-react/BeakerOutlineIcon";
import MedicalBagIcon from "mdi-react/MedicalBagIcon";
import CommentPlusOutlineIcon from "mdi-react/CommentPlusOutlineIcon";
import InformationVariantIcon from "mdi-react/InformationVariantIcon";
import PriorityHighIcon from "mdi-react/PriorityHighIcon";
import ClipboardListOutlineIcon from "mdi-react/ClipboardListOutlineIcon";
import PillIcon from "mdi-react/PillIcon";
import Close from "mdi-react/CloseIcon";
import Record from "mdi-react/PencilOutlineIcon";
import CircleLetterS from "mdi-react/AlphaSCircleIcon";
import CircleLetterW from "mdi-react/AlphaWCircleIcon";
import CircleLetterP from "mdi-react/AlphaPCircleIcon";
import HeartPulse from "mdi-react/HeartPulseIcon";
import RecordOutline from "mdi-react/PencilBoxMultipleOutlineIcon";
import Phone from "mdi-react/PhoneOutlineIcon";
import Home from "mdi-react/HomeOutlineIcon";
import Book from "mdi-react/BookOutlineIcon";
import BallotOutlineIcon from "mdi-react/BallotOutlineIcon";
import FollowUpAssessment from "mdi-react/BallotRecountOutlineIcon";
import AccountOutLine from "mdi-react/AccountOutlineIcon";
import Contacts from "mdi-react/AccountSupervisorOutlineIcon";
import AccountCheck from "mdi-react/AccountCheckOutlineIcon";
import Autorenew from "mdi-react/AutorenewIcon";
import CellPhone from "mdi-react/CellphoneAndroidIcon";
import Chart from "mdi-react/ChartPieIcon";
import SupervisorAccount from "mdi-react/AccountSupervisorIcon";
import ClipboardTextOutlineIcon from "mdi-react/ClipboardTextOutlineIcon";
import ReportProblemOutlined from "mdi-react/AlertOutlineIcon";
import ClipboardAccountOutlineIcon from "mdi-react/ClipboardAccountOutlineIcon";
import BedOutlineIcon from "mdi-react/BedOutlineIcon";
import FlowerOutlineIcon from "mdi-react/FlowerOutlineIcon";
import ListStatusIcon from "mdi-react/ListStatusIcon";
import BugOutlineIcon from "mdi-react/BugOutlineIcon";
import Doctor from "mdi-react/DoctorIcon";
import Fax from "mdi-react/FaxIcon";
import Bookmark from "mdi-react/BookmarkOutlineIcon";
import CalendarOutlineIcon from "mdi-react/CalendarOutlineIcon";
import AccountRemoveOutlineIcon from "mdi-react/AccountRemoveOutlineIcon";
import SmartCardOutlineIcon from "mdi-react/SmartCardOutlineIcon";
import FormatListBulletedSquareIcon from "mdi-react/FormatListBulletedSquareIcon";
import SortDescendingIcon from "mdi-react/SortDescendingIcon";
import SortAscendingIcon from "mdi-react/SortAscendingIcon";
import SortIcon from "mdi-react/SortIcon";
import HospitalBuildingIcon from "mdi-react/HospitalBuildingIcon";
import AccountSwitchOutlineIcon from "mdi-react/AccountSwitchOutlineIcon";
import TextAccountIcon from "mdi-react/TextAccountIcon";
import TextBoxMultipleOutlineIcon from "mdi-react/TextBoxMultipleOutlineIcon";
import FormatListChecksIcon from "mdi-react/FormatListChecksIcon";
import AccountBoxMultipleOutlineIcon from "mdi-react/AccountBoxMultipleOutlineIcon";
import ShieldAccountOutlineIcon from "mdi-react/ShieldAccountOutlineIcon";
import MicrosoftExcelIcon from "mdi-react/MicrosoftExcelIcon";
import FileTreeOutlineIcon from "mdi-react/FileTreeOutlineIcon";
import TuneVerticalVariantIcon from "mdi-react/TuneVerticalVariantIcon";
import ArrowRight from "mdi-react/ArrowRightIcon";
import AccountClockOutlineIcon from "mdi-react/AccountClockOutlineIcon";
import AccountKeyOutlineIcon from "mdi-react/AccountKeyOutlineIcon";
import TextBoxOutlineIcon from "mdi-react/TextBoxOutlineIcon";
import ClipboardAlertOutlineIcon from "mdi-react/ClipboardAlertOutlineIcon";
import BookmarkMultipleOutlineIcon from "mdi-react/BookmarkMultipleOutlineIcon";
import BadgeAccountHorizontalOutlineIcon from "mdi-react/BadgeAccountHorizontalOutlineIcon";
import Number0BoxMultipleOutlineIcon from "mdi-react/Number0BoxMultipleOutlineIcon";
import DownloadCircleOutlineIcon from "mdi-react/DownloadCircleOutlineIcon";
import TriangleOutlineIcon from "mdi-react/TriangleOutlineIcon";
import FileImportIcon from "mdi-react/FileImportIcon";

export const AssignedUserIcon = AccountArrowLeftIcon;
export const AssignedSpecialtyIcon = SupervisorAccount;
export const AssignedRoleIcon = BadgeAccountHorizontalOutlineIcon;
export const NoteIcon = MessageTextOutlineIcon;
export const RequestInfoIcon = CommentQuestionOutlineIcon;
export const PatientDetailsIcon = AccountDetailsIcon;
export const IndicationIcon = CursorPointerIcon;
export const DoseIcon = BeakerOutlineIcon;
export const TreatmentIcon = MedicalBagIcon;
export const AdditionalInformationIcon = CommentPlusOutlineIcon;
export const SummaryIcon = ClipboardTextOutlineIcon;
export const InterventionIcon = ClipboardAlertOutlineIcon;
export const ProblemIcon = ReportProblemOutlined;
export const EncounterIcon = ClipboardAccountOutlineIcon;
export const ProcedureIcon = BedOutlineIcon;
export const AllergyIcon = FlowerOutlineIcon;
export const AssessmentIcon = ListStatusIcon;
export const CareSummaryIcon = InformationVariantIcon;
export const PriorityIcon = PriorityHighIcon;
export const ImmunisationIcon = BugOutlineIcon;
export const ConditionIcon = ClipboardListOutlineIcon;
export const MedicationIcon = PillIcon;
export const CloseIcon = Close;
export const RecordIcon = Record;
export const CircleIconS = CircleLetterS;
export const CircleIconW = CircleLetterW;
export const CircleIconP = CircleLetterP;
export const HeartPulseIcon = HeartPulse;
export const RecordOutlineIcon = RecordOutline;
export const PhoneIcon = Phone;
export const HomeIcon = Home;
export const BookIcon = Book;
export const InitialAssessmentIcon = BallotOutlineIcon;
export const FollowUpAssessmentIcon = FollowUpAssessment;
export const ContactsIcon = Contacts;
export const AccountOutLineIcon = AccountOutLine;
export const AccountCheckIcon = AccountCheck;
export const AutorenewIcon = Autorenew;
export const CellPhoneIcon = CellPhone;
export const ChartIcon = Chart;
export const BookmarkIcon = Bookmark;
export const DoctorIcon = Doctor;
export const FaxIcon = Fax;
export const AppointmentIcon = CalendarOutlineIcon;
export const RemoveAccount = AccountRemoveOutlineIcon;
export const CardsIcon = SmartCardOutlineIcon;
export const ReferralWorklistIcon = FormatListBulletedSquareIcon;
export const AscendingIcon = SortAscendingIcon;
export const DescendingIcon = SortDescendingIcon;
export const SortByIcon = SortIcon;
export const ReferralOrgIcon = HospitalBuildingIcon;
export const PatientMergeIcon = AccountSwitchOutlineIcon;
export const PatientWorklistIcon = TextAccountIcon;
export const DocumentWorklistIcon = TextBoxMultipleOutlineIcon;
export const IPAWorklistIcon = FormatListChecksIcon;
export const CandidateWorklistIcon = AccountBoxMultipleOutlineIcon;
export const UserIcon = ShieldAccountOutlineIcon;
export const ExcelExportIcon = MicrosoftExcelIcon;
export const OrgUnitsIcon = FileTreeOutlineIcon;
export const AdminSettingIcon = TuneVerticalVariantIcon;
export const ArrowRightIcon = ArrowRight;
export const PatientAccessIcon = AccountClockOutlineIcon;
export const UserRoleIcon = AccountKeyOutlineIcon;
export const TaskIcon = TextBoxOutlineIcon;
export const AddendumIcon = ClipboardAlertOutlineIcon;
export const CodeSetIcon = Number0BoxMultipleOutlineIcon;
export const CodeSetFileImportIcon = FileImportIcon;
export const SubSpecialtyIcon = BookmarkMultipleOutlineIcon;
export const DownloadIcon = DownloadCircleOutlineIcon;
export const StatusIcon = TriangleOutlineIcon;
