import React from "react";
import { map, isEmpty, size } from "lodash";
import { connect } from "react-redux";
import clsx from "clsx";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { Icon, IconButton } from "@material-ui/core";
import DoctorIcon from "mdi-react/DoctorIcon";
import AccountTieOutlineIcon from "mdi-react/AccountTieOutlineIcon";

import { showMessage } from "app/store/actions/message.actions";
import DefaultPanelLayout from "components/items/default-panel-layout";
import EmptyState, { NoneRecordedState } from "components/items/empty-state";
import ApplicationPanelContent from "app/main/applications/components/application-panel-content";
import ApplicationPersonForm from "app/main/applications/components/application-person-form";
import { onSaveOrUpdateInterestedPerson, onRemoveInterestedPerson } from "app/main/applications/actions/applications.actions";
import ApplicationItem from "app/main/applications/components/application-item";
import renderContactInformation from "app/main/applications/helpers/render-application-person-contact-info";

import ApplicationRemoveButton from "./application-remove-button";

class InterestedPeoplePanel extends React.Component {
  onClose = () => this.props.closeDialog();

  onSucceed = () => {
    this.onClose();
  }

  handleSubmit = ({ applicationPerson }, { setErrors }) => {
    const { id, orgUnitId } = this.props;

    this.props.onSaveOrUpdateInterestedPerson(id, orgUnitId, applicationPerson).then(response => {
      if (response.error !== true) {
        this.onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  }

  onAddOrUpdatePerson = (interestedPerson, isEdit) => {
    this.props.openDialog({
      maxWidth: "md",
      children: (
        <ApplicationPersonForm
          onSucceed={this.onSucceed}
          title={`${isEdit ? "Edit" : "Add"} Interested Party`}
          applicationPerson={interestedPerson}
          handleSubmit={this.handleSubmit}
          showRoleDescription
        />
      ),
    });
  };

  onRemovePerson = personId => {
    const { id: applicationId, orgUnitId } = this.props;
    this.props.onRemoveInterestedPerson(applicationId, personId, orgUnitId).then(response => {
      if (response.error === true) {
        this.props.showMessage({ message: response.payload.exceptionMessage || "Error occurred while deleting the interested person", variant: "error" });
      } else {
        this.props.showMessage({ message: "Successfully removed the interested party", variant: "success" });
      }
    });
  }

  render() {
    const { width, interestedPeople, canView, canEdit } = this.props;

    const isMobile = isWidthDown("sm", width);

    return (
      <DefaultPanelLayout
        title="Interested Parties for Notification"
        icon="person"
        isEmpty={isEmpty(interestedPeople)}
        emptyState={canEdit
          ? <EmptyState icon="person" subtitle="Add Interested People" onClick={() => this.onAddOrUpdatePerson()} />
          : <NoneRecordedState />}
        accessDenied={!canView}
        headerActions={canEdit
          && [
            {
              label: "Add Interested Party",
              icon: "add",
              onClick: () => this.onAddOrUpdatePerson(),
            },
          ]}
      >
        <ApplicationPanelContent>
          {map(interestedPeople, (x, index) => (
            <React.Fragment key={x.id}>
              <div className="flex justify-between items-start">
                <div className="flex-1 items-container">
                  <ApplicationItem icon={<DoctorIcon />} content={`${x.title ? `${x.title} ` : ""}${x.givenName} ${x.familyName}`} />
                  <ApplicationItem icon="phone" content={x.phone} />
                  {renderContactInformation(x)}
                  <ApplicationItem icon={<AccountTieOutlineIcon />} content={x.roleDescription} />
                </div>
                {canEdit
                && (
                  <div className={clsx("flex items-center justify-end", index % 2 === 0 && !isMobile && "mr-16")}>
                    <ApplicationRemoveButton onClick={() => this.onRemovePerson(x.personId)} />
                    <IconButton onClick={() => this.onAddOrUpdatePerson(x, true)}><Icon>edit</Icon></IconButton>
                  </div>
                )}
              </div>
            </React.Fragment>
          ))}
          {size(interestedPeople) % 2 !== 0 && !isMobile && <div />}
        </ApplicationPanelContent>
      </DefaultPanelLayout>
    );
  }
}

export default connect(
  null,
  {
    onSaveOrUpdateInterestedPerson,
    onRemoveInterestedPerson,
    showMessage,
  },
)(withWidth()(InterestedPeoplePanel));
