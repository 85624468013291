import React from "react";
import PatientRecordContent from "app/main/patients/components/patient-record-content";

const ReferralPatientPage = ({
  match: { params: { patientId } },
  ...other
}) => (
  <PatientRecordContent routePrefix="/referralWorklist" patientId={patientId} {...other} />
);

export default ReferralPatientPage;
