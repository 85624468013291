import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { map, isEmpty } from "lodash";
import DialogPopup from "components/items/dialog-popup";
import { setDialogOptions, closeDialog } from "app/store/actions/dialog.actions";
import DefaultButton from "components/items/default-button";
import { getReferralAccessLog } from "app/main/referrals/actions/referrals.actions";
import formatDateTime from "helpers/format-date-time";
import ResponsiveTable, { TableCell, TableRow, TableBody } from "components/items/responsive-table";
import { getReferralAccessLog as getAccessLog, isReferralAccessLogLoading } from "../reducers/referrals.reducers";

const ReferralAccessLogDialog = ({ referralId }) => {
  const dispatch = useDispatch();

  const accessLog = useSelector(state => getAccessLog(state));
  const isLoading = useSelector(state => isReferralAccessLogLoading(state));

  useEffect(() => {
    dispatch(setDialogOptions({ maxWidth: "md" }));
    if (!accessLog && !isLoading) {
      dispatch(getReferralAccessLog(referralId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const renderLogItem = (item, index) => {
    const { firstName, lastName, dateAccessed, username } = item;
    return (
      <TableBody key={index}>
        <TableRow>
          <TableCell title="Date Accessed">{formatDateTime(dateAccessed, true)}</TableCell>
          <TableCell title="Name">{`${firstName} ${lastName}`}</TableCell>
          <TableCell title="Username">{username || "No Username Recorded"}</TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const renderHeader = () => (
    <TableRow>
      <TableCell>Date Accessed</TableCell>
      <TableCell>Name</TableCell>
      <TableCell>Username</TableCell>
    </TableRow>
  );

  return (
    <DialogPopup
      title="Referral Access Log"
      content={(
        <ResponsiveTable
          isLoading={isLoading}
          isEmpty={isEmpty(accessLog)}
          renderTableContent={map(accessLog, (logItem, index) => renderLogItem(logItem, index))}
          renderTableHeader={renderHeader()}
        />
      )}
      renderActions={<DefaultButton color="default" onClick={() => dispatch(closeDialog())} label="Close" size="medium" variant="text" />}
    />
  );
};

export default ReferralAccessLogDialog;
