import { green, blue, orange, lightBlue, grey, amber } from "@material-ui/core/colors";

export default function getAppointmentStatus(status) {
  switch (status) {
    case "Proposed":
      return {
        color: amber[600],
      };
    case "Booked":
      return {
        color: lightBlue[600],
      };
    case "Arrived":
      return {
        color: blue[600],
      };
    case "Completed":
      return {
        color: green[600],
      };
    case "Cancelled":
      return {
        color: grey[600],
      };
    case "NoShow":
      return {
        color: orange[600],
      };
    default:
      return {
        color: blue[600],
      };
  }
}
