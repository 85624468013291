import React from "react";
import { map, isString, get, isObject } from "lodash";
import CircleSmallIcon from "mdi-react/CircleSmallIcon";
import { Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const defaultTextColor = grey[700];

const ContentWithDivider = ({
  contents,
  divider,
}) => (
  <div className="flex flex-wrap">
    {map(contents, (content, index) => {
      let displayDivider = index !== 0 && content !== null && contents[index - 1] !== null;
      if (isObject(content)) {
        displayDivider = displayDivider && content.label !== null;
      }
      return (
        <React.Fragment key={index.toString()}>
          {displayDivider && divider}
          {isString(content)
            ? <Typography style={{ color: defaultTextColor }} variant="body2" className="enable-shrink">{content}</Typography>
            : <Typography style={get(content, "color", null) ? null : { color: defaultTextColor }} variant="body2" className="enable-shrink" {...content}>{get(content, "label")}</Typography>}
        </React.Fragment>
      );
    })}
  </div>
);

ContentWithDivider.defaultProps = {
  divider: <CircleSmallIcon size={20} />,
};

export default ContentWithDivider;
