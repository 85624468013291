const calculateCostEstimation = treatment => {
  const {
    treatmentDurationType,
    treatmentDurationUnit,
  } = treatment;

  const medicationDoseNumber = parseFloat(treatment.medicationDose);
  const medicationFrequencyNumber = parseFloat(treatment.medicationFrequency);
  const medicationInventoryNumber = parseFloat(treatment.medicationInventory);
  const unitOfMeasureCostNumber = parseFloat(treatment.unitOfMeasureCost);
  const treatmentDurationValueNumber = parseFloat(treatment.treatmentDurationValue);

  if (treatmentDurationType && treatmentDurationValueNumber && treatmentDurationUnit && medicationDoseNumber && medicationFrequencyNumber && medicationInventoryNumber && unitOfMeasureCostNumber) {
    switch (treatmentDurationType) {
      case "TreatmentCourse":
        switch (treatmentDurationUnit) {
          case "Doses":
            return unitOfMeasureCostNumber * (medicationDoseNumber / medicationInventoryNumber) * treatmentDurationValueNumber;
          case "Days":
            return unitOfMeasureCostNumber * (medicationDoseNumber / medicationInventoryNumber) * treatmentDurationValueNumber * medicationFrequencyNumber;
          case "Weeks":
            return unitOfMeasureCostNumber * (medicationDoseNumber / medicationInventoryNumber) * treatmentDurationValueNumber * 7 * medicationFrequencyNumber;
          case "Months":
            return unitOfMeasureCostNumber * (medicationDoseNumber / medicationInventoryNumber) * treatmentDurationValueNumber * 28 * medicationFrequencyNumber;
          default:
            return NaN;
        }

      case "CyclicalTreatment":
        return unitOfMeasureCostNumber * (medicationDoseNumber / medicationInventoryNumber) * treatmentDurationValueNumber * medicationFrequencyNumber;
      case "OngoingTreatment":
        return unitOfMeasureCostNumber * (medicationDoseNumber / medicationInventoryNumber) * treatmentDurationValueNumber * medicationFrequencyNumber * 28;
      default:
        return NaN;
    }
  }

  return NaN;
};

export default treatment => {
  const costEstimation = calculateCostEstimation(treatment);

  if (Number.isNaN(costEstimation)) {
    return costEstimation;
  }

  return costEstimation?.toFixed(2);
};
