import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableRow as NativeTableRow } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  row: {
    position: "relative",
  },
}));

const TableRow = ({ className, children, ...other }) => {
  const classes = useStyles();
  return (
    <NativeTableRow className={clsx(classes.row, className)} {...other}>{children}</NativeTableRow>
  );
};

export default TableRow;
