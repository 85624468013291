import React from "react";
import TextLink from "components/items/text-link";
import { addressIsEmpty } from "app/main/patients/helpers/check-object-is-empty";

export default function AddressDetailsLabel({ address, showIcon = true }) {
  if (addressIsEmpty(address) || address === " ") return null;

  const icon = showIcon ? "home" : null;
  const rootClass = {
    root: "w-100 enable-shrink",
  };

  if (typeof address === "string") {
    return <TextLink icon={icon} content={address} classes={rootClass} />;
  }

  return (
    <TextLink icon={icon} classes={rootClass}>
      {address.street && `${address.street},`}
      {" "}
      {(address.suburb || address.city)}{(address.suburb || address.city) && ","}
      {" "}
      {address.state}
      {" "}
      {address.postcode}
    </TextLink>
  );
}
