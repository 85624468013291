import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import { getConditionsByPatientId, areConditionsLoading, getErrorMessage } from "app/main/patients/reducers/conditions.reducers";
import { fetchPatientConditions, editExistingCondition, recordNewCondition, removeCondition, fetchConditionsById } from "app/main/patients/actions/conditions.actions";
import RecordConditionForm from "app/main/patients/conditions/components/record-condition-form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import withPermissions from "permissions/withPermissions";

class ConditionListContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    const { patientId } = this.props;
    if (patientId) {
      this.props.fetchPatientConditions(patientId);
    }
    this.onRecordCondition = this.onRecordCondition.bind(this);
    this.onEditCondition = this.onEditCondition.bind(this);
  }

  onClose = () => this.props.closeDialog();

  openRecordConditionForm = (title, isEdit) => {
    this.props.openDialog({
      children: <RecordConditionForm
        title={title}
        onSucceed={this.onClose}
        isEdit={isEdit}
      />,
    });
  }

  onRecordCondition = () => {
    this.props.recordNewCondition(this.props.patientId);
    this.openRecordConditionForm("Add Medical Condition");
  };

  onEditCondition = conditionId => {
    this.props.editExistingCondition(this.props.patientId, conditionId);
    this.openRecordConditionForm("Edit Medical Condition", true);
  };

  onRemoveCondition = conditionId => {
    this.props.removeCondition(this.props.patientId, conditionId);
    this.onClose();
  };

  render() {
    const {
      loading,
      error,
      conditions,
      renderContent,
      hasPermissionConditionsCreate,
      hasPermissionConditionsUpdate,
      hasPermissionConditionsDelete,
    } = this.props;

    return (
      renderContent({
        conditions,
        onEditCondition: hasPermissionConditionsUpdate ? this.onEditCondition : null,
        onRemoveCondition: hasPermissionConditionsDelete ? this.onRemoveCondition : null,
        onRecordCondition: hasPermissionConditionsCreate ? this.onRecordCondition : null,
        isLoading: loading,
        isError: error,
        isEmpty: isEmpty(conditions),
      })
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { patientId } = ownProps;
  const conditions = getConditionsByPatientId(state, patientId);
  return {
    conditions,
    error: getErrorMessage(state, patientId),
    loading: areConditionsLoading(state, patientId),
  };
};

export default withPermissions(
  "ConditionsDelete",
  "ConditionsUpdate",
  "ConditionsCreate",
)(connect(
  mapStateToProps,
  {
    openDialog,
    closeDialog,
    fetchConditionsById,
    fetchPatientConditions,
    recordNewCondition,
    editExistingCondition,
    removeCondition,
  },
)(ConditionListContainer));
