import React, { useState, useEffect } from "react";
import { map, find, toLower, join } from "lodash";
import { Chip, InputLabel, Input } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import DefaultButton from "components/items/default-button";
import IconButton from "components/items/icon-button";
import DisabledInput from "components/inputs/components/disabled-input";
import BaseInputField from "components/inputs/components/base-input-component";
import HelperText from "components/inputs/components/helper-text";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { getSubSpecialtyLabel } from "app/auth/store/reducers/system-configuration";

import SubSpecialtyForm from "./sub-specialty-form";

const SubSpecialtyInput = ({
  field: { name, value },
  form: { setFieldValue },
  label,
  disabled,
}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [items, setItems] = useState(value ?? []);
  const [error, setError] = useState(null);
  const subSpecialtyLabel = useSelector(getSubSpecialtyLabel);
  const displayLabel = label ?? subSpecialtyLabel;

  useEffect(() => {
    setFieldValue(name, items);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const openForm = item => {
    dispatch(openDialog({
      maxWidth: "xs",
      children: (
        <SubSpecialtyForm
          title="Edit Sub Specialty"
          onSucceed={() => dispatch(closeDialog())}
          subSpecialty={item}
          subSpecialties={items}
          setItems={setItems}
          isNewItem={item.label === item.value}
        />
      ),
    }));
  };

  const addItem = () => {
    const newItem = { value: inputValue, label: inputValue };
    setItems(prevState => {
      const isMatch = find(prevState, x => toLower(x.label) === toLower(inputValue));

      if (isMatch) {
        setError("The sub specialty entered is already existed in the list");
        return prevState;
      }
      setInputValue("");
      return prevState.concat(newItem);
    });
  };

  if (disabled) {
    return (
      <BaseInputField multiline>
        <DisabledInput label={displayLabel} value={value ? join(map(value, x => x.label), ", ") : null} />
      </BaseInputField>
    );
  }

  return (
    <BaseInputField>
      <InputLabel htmlFor={displayLabel}>{displayLabel}</InputLabel>
      <form className="MuiInputBase-root MuiInputBase-input mt-16 flex flex-col sm:flex-row">
        <Input
          id={displayLabel}
          label={displayLabel}
          classes={{ root: "flex-1 flex-row-container with-gutter", input: "w-auto flex-1" }}
          placeholder="Enter name of sub specialty"
          value={inputValue}
          // multiline
          onChange={e => {
            if (error) {
              setError(null);
            }
            setInputValue(e.target.value);
          }}
          startAdornment={map(items, item => <Chip label={item.label} size="small" key={item.label} onClick={() => openForm(item)} />)}
          endAdornment={inputValue
            && (
            <div className="absolute right-0">
              <IconButton
                icon="clear"
                title="Clear input"
                onClick={() => { setInputValue(""); setError(null); }}
              />
            </div>
            )}
        />
        <div className="my-8 sm:my-0 mx-8 w-full sm:w-auto">
          <DefaultButton type="submit" label="Add" disabled={inputValue === ""} onClick={addItem} />
        </div>
      </form>
      <HelperText error={error} helperText={error} />
    </BaseInputField>
  );
};

export default SubSpecialtyInput;
