import { mapKeys, map, concat, get, find, filter, orderBy, isEmpty, remove, trim, some } from "lodash";
import curryConnector from "utils/connectors";

import { ActionTypes as ApplicationWorkListActionTypes } from "app/main/applicationWorklist/reducers/applicationWorklist.reducers";

export const APPLICATIONS_STATE_KEY = "applications";
export const APPLICATIONS_PAGE_SIZE = 50;
export const APPLICATIONS_PAGE_SIZE_OPTIONS = [5, 10, 25, 50];

const curry = fn => curryConnector(fn, APPLICATIONS_STATE_KEY);

export const ActionTypes = {
  LOADING_APPLICATION: "LOADING_APPLICATION",
  LOADED_APPLICATION: "LOADED_APPLICATION",
  ERROR_LOADING_APPLICATION: "ERROR_LOADING_APPLICATION",

  CREATED_APPLICATION: "CREATED_APPLICATION",
  SAVED_APPLICATION: "SAVED_APPLICATION",

  CREATED_INTERESTED_PERSON: "CREATED_INTERESTED_PERSON",
  SAVED_INTERESTED_PERSON: "SAVED_INTERESTED_PERSON",
  DELETED_INTERESTED_PERSON: "DELETED_INTERESTED_PERSON",

  CREATED_ENDORSEMENT: "CREATED_ENDORSEMENT",
  SAVED_ENDORSEMENT: "SAVED_ENDORSEMENT",
  DELETED_ENDORSEMENT: "DELETED_ENDORSEMENT",

  CREATED_EXPIRED_PRESCRIPTION: "CREATED_EXPIRED_PRESCRIPTION",
  SAVED_EXPIRED_PRESCRIPTION: "SAVED_EXPIRED_PRESCRIPTION",
  DELETED_EXPIRED_PRESCRIPTION_PRESENTATION: "DELETED_EXPIRED_PRESCRIPTION_PRESENTATION",

  CREATED_APPLICATION_APPEAL: "CREATED_APPLICATION_APPEAL",
  SAVED_APPLICATION_APPEAL: "SAVED_APPLICATION_APPEAL",
  DELETED_APPLICATION_APPEAL: "DELETED_APPLICATION_APPEAL",

  CREATED_REPORTBACK: "CREATED_REPORTBACK",
  SAVED_REPORTBACK: "SAVED_REPORTBACK",
  DELETED_REPORTBACK: "DELETED_REPORTBACK",
  SAVED_REPORTBACK_REPORT: "SAVED_REPORTBACK_REPORT",

  CREATED_APPLICATION_ATTACHMENT: "CREATED_APPLICATION_ATTACHMENT",
  LOADED_ATTACHMENT_FILE: "LOADED_ATTACHMENT_FILE",
  DELETED_APPLICATION_ATTACHMENT: "DELETED_APPLICATION_ATTACHMENT",

  CREATED_APPLICATION_ADDITIONAL_INFORMATION: "CREATED_APPLICATION_ADDITIONAL_INFORMATION",
  LOADED_APPLICATION_ADDITIONAL_INFORMATION: "LOADED_APPLICATION_ADDITIONAL_INFORMATION",

  CREATED_APPROVAL_RECOMMENDATION_ASSIGNMENT: "CREATED_APPROVAL_RECOMMENDATION_ASSIGNMENT",
  CREATED_APPROVAL_RECOMMENDATION_COMMENT: "CREATED_APPROVAL_RECOMMENDATION_COMMENT",
  DELETED_APPROVAL_RECOMMENDATION_COMMENT: "DELETED_APPROVAL_RECOMMENDATION_COMMENT",

  CREATED_ATTACHMENT_FILE: "CREATED_ATTACHMENT_FILE",
  DELETED_REPORTBACK_REPORT: "DELETED_REPORTBACK_REPORT",

  CREATED_APPLICANT: "CREATED_APPLICANT",
  SAVED_APPLICANT: "SAVED_APPLICANT",

  CREATED_CONSULTANT: "CREATED_CONSULTANT",
  SAVED_CONSULTANT: "SAVED_CONSULTANT",
  DELETED_CONSULTANT: "DELETED_CONSULTANT",

  ACCEPT_APPLICATION: "ACCEPT_APPLICATION",
  CREATED_DECISION: "CREATED_DECISION",
  SAVED_DECISION: "SAVED_DECISION",
  DELETED_APPLICATION_DECISION: "DELETED_APPLICATION_DECISION",
  ACCEPTING_APPLICATION: "ACCEPTING_APPLICATION",
  ERROR_SAVING_APPLICATION: "ERROR_SAVING_APPLICATION",
  SAVING_APPLICATION: "SAVING_APPLICATION",
  ASSIGNED_APPLICATION: "ASSIGNED_APPLICATION",

  CLONED_APPLICATION: "CLONED_APPLICATION",

  SET_CURRENT_APPLICATION: "SET_CURRENT_APPLICATION",

  LOADING_DRUG_COST_INFORMATION: "LOADING_DRUG_COST_INFORMATION",
  LOADED_DRUG_COST_INFORMATION: "LOADED_DRUG_COST_INFORMATION",
  ERROR_LOADING_DRUG_COST_INFORMATION: "ERROR_LOADING_DRUG_COST_INFORMATION",

};

const INITIAL_STATE = {
  all: {},
  pages: {},
  pageInfo: { pageNumber: 1, pageSize: APPLICATIONS_PAGE_SIZE, totalRecords: 0 },
  applicationsForWorkList: [],
  current: {},
  loadingCurrent: false,
  errorLoadingCurrent: null,
  loadedCurrent: false,
  currentAttachment: null,
  drugCostInformation: {
    current: {},
    meta: { loading: false, loaded: false, error: null },
  },
};

// find and update the object
const updateData = (arr, data) => map(arr, x => {
  if (x.id === data.id) {
    return data;
  }
  return x;
});

const validateTreatmentSectionIsCompleted = application => !isEmpty(get(application, "requestedMedication", null));

const validatePatientSectionIsCompleted = application => !isEmpty(get(application, "patient", null));

const validateEndorsementSectionFields = application => {
  const endorsements = get(application, "endorsements", []);

  if (isEmpty(endorsements)) {
    return ["At least one endorsement is required."];
  }

  if (some(endorsements, x => isEmpty(x.endorsedUserId))) {
    return ["All endorsements must be assigned to a user"];
  }

  return [];
};

const validateApplicantSectionFields = application => {
  let fieldValidationErrors = [];

  const conflictOnInterestNotPresent = value => isEmpty(trim(get(value, ["conflictOfInterest"], null)));

  if (conflictOnInterestNotPresent(application.applicant) || (application.consultant && conflictOnInterestNotPresent(application.consultant))) {
    fieldValidationErrors = [...fieldValidationErrors, "Conflict of interest is required."];
  }

  return fieldValidationErrors;
};

const validateEndorsementSectionIsCompleted = application => isEmpty(validateEndorsementSectionFields(application));

const validateApplicantSectionIsCompleted = application => !isEmpty(get(application, "applicant", null)) && isEmpty(validateApplicantSectionFields(application));

function normaliseApplication(application) {
  if (!application) return null;

  return {
    ...application,
    expiredPrescriptionPresentations: orderBy(application.expiredPrescriptionPresentations, "presentationDate", ["desc"]),
    attachments: orderBy(application.attachments, "addedDateTimeUtc", ["desc"]),
    endorsements: orderBy(application.endorsements, "signedDateTimeUtc", ["asc"]),
    reportBacks: orderBy(application.reportBacks, "dueDate", ["desc"]),
    requiredSections: [
      { name: "treatment", complete: validateTreatmentSectionIsCompleted(application), message: "Treatment details are required.", visible: true },
      { name: "patient", complete: validatePatientSectionIsCompleted(application), message: "Patient details are required.", visible: true },
      { name: "endorsement", complete: validateEndorsementSectionIsCompleted(application), message: "Endorsements", fieldValidationErrors: validateEndorsementSectionFields(application), visible: application.status !== "New" },
      { name: "applicant", complete: validateApplicantSectionIsCompleted(application), message: "Applicant details are required.", fieldValidationErrors: validateApplicantSectionFields(application), visible: application.status !== "New" },
    ],
  };
}

const addCurrentApplicationItem = (state, data, entity, extra) => ({
  ...state,
  current: normaliseApplication({
    ...state.current,
    [entity]: concat(data, get(state.current, entity)),
    ...extra,
  }),
  currentAttachment: null,
});

const updateCurrentApplicationItem = (state, data, entity, extra) => ({
  ...state,
  current: normaliseApplication({
    ...state.current,
    [entity]: updateData(get(state.current, entity), data),
    ...extra,
  }),
  currentAttachment: null,
});

const removeCurrentApplicationItem = ({ state, refId = "id", removeId, entity, ...extra }) => ({
  ...state,
  current: normaliseApplication({
    ...state.current,
    [entity]: filter(get(state.current, entity), x => get(x, refId, null) !== removeId),
    ...extra,
  }),
});

const updateApplicationsFromEndorsement = (state, applicationId, payload) => ({
  all: {
    ...state.all,
    [applicationId]: {
      ...state.all[applicationId],
      urgentTreatmentApprovalGranted: payload.urgentTreatmentApprovalGranted,
      status: payload.status,
      statusChangedDateTimeUtc: payload.statusChangedDateTimeUtc,
    },
  },
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ApplicationWorkListActionTypes.SEARCHING_APPLICATIONS:
      return {
        ...state,
        applicationsForWorkList: (action.payload.pageNumber === 1) ? [] : state.applicationsForWorkList,
      };
    case ApplicationWorkListActionTypes.SEARCHED_APPLICATIONS:
      return {
        ...state,
        all: { ...state.all, ...mapKeys(action.payload.applications, x => x.id) },
        pages: { ...state.pages, [action.payload.pageInfo.pageNumber]: { loading: false, ids: map(action.payload.applications, x => x.id) } },
        pageInfo: action.payload.pageInfo,
        applicationsForWorkList: (action.payload.pageInfo.pageNumber === 1)
          ? map(action.payload.applications, x => x.id)
          : concat(state.applicationsForWorkList, map(action.payload.applications, x => x.id)),
      };
    case ActionTypes.LOADING_APPLICATION:
      return {
        ...state,
        loadingCurrent: true,
      };
    case ActionTypes.LOADED_APPLICATION:
      return {
        ...state,
        loadingCurrent: false,
        loadedCurrent: true,
        errorLoadingCurrent: false,
        current: normaliseApplication(action.payload),
        all: {
          ...state.all,
          [action.payload.id]: {
            ...state.all[action.payload.id],
            status: action.payload.status,
            urgentTreatmentApprovalGranted: action.payload.urgentTreatmentApprovalGranted,
            requestedMedication: action.payload.requestedMedication,
            patient: action.payload.patient,
            statusChangedDateTimeUtc: action.payload.statusChangedDateTimeUtc,
            indication: action.payload.indication,
          },
        },
      };
    case ActionTypes.ERROR_LOADING_APPLICATION:
      return {
        ...state,
        loadingCurrent: false,
        loadedCurrent: false,
        errorLoadingCurrent: action.payload.message,
      };
    case ActionTypes.CREATED_APPLICATION:
    case ActionTypes.CLONED_APPLICATION:
      return {
        ...state,
        all: { ...state.all, [action.payload.id]: action.payload },
        current: normaliseApplication(action.payload),
        applicationsForWorkList: concat(action.payload.id, state.applicationsForWorkList),
        pageInfo: {
          ...state.pageInfo,
          applicationsCount: state.pageInfo.applicationsCount + 1,
        },
      };
    case ActionTypes.ERROR_SAVING_APPLICATION:
      return {
        ...state,
        loadingCurrent: false,
        loadedCurrent: false,
        errorLoadingCurrent: true,
      };
    case ActionTypes.SAVED_APPLICATION:
    case ActionTypes.ACCEPT_APPLICATION:
      return {
        ...state,
        all: { ...state.all, [action.payload.id]: action.payload },
        current: normaliseApplication(action.payload),
        loadingCurrent: false,
        loadedCurrent: true,
      };
    // expiredPrescriptionPresentations
    case ActionTypes.CREATED_EXPIRED_PRESCRIPTION:
      return addCurrentApplicationItem(state, action.payload, "expiredPrescriptionPresentations");
    case ActionTypes.SAVED_EXPIRED_PRESCRIPTION:
      return updateCurrentApplicationItem(state, action.payload, "expiredPrescriptionPresentations");
    case ActionTypes.DELETED_EXPIRED_PRESCRIPTION_PRESENTATION:
      return removeCurrentApplicationItem({ state, removeId: action.payload.prescriptionId, entity: "expiredPrescriptionPresentations" });
    // applicationAppeals
    case ActionTypes.CREATED_APPLICATION_APPEAL:
      return addCurrentApplicationItem(state, action.payload, "applicationAppeals");
    case ActionTypes.SAVED_APPLICATION_APPEAL:
      return updateCurrentApplicationItem(state, action.payload, "applicationAppeals");
    case ActionTypes.DELETED_APPLICATION_APPEAL:
      return removeCurrentApplicationItem({ state, removeId: action.payload.appealId, entity: "applicationAppeals" });
    // approvalRecommendations
    case ActionTypes.CREATED_APPROVAL_RECOMMENDATION_ASSIGNMENT:
      return addCurrentApplicationItem(state, action.payload, "approvalRecommendations");
    case ActionTypes.CREATED_APPROVAL_RECOMMENDATION_COMMENT:
      return updateCurrentApplicationItem(state, action.payload, "approvalRecommendations");
    case ActionTypes.DELETED_APPROVAL_RECOMMENDATION_COMMENT:
      return removeCurrentApplicationItem({ state, removeId: action.payload.approvalRecommendationId, entity: "approvalRecommendations" });
    // reportBacks
    case ActionTypes.CREATED_REPORTBACK:
      return addCurrentApplicationItem(state, action.payload, "reportBacks");
    case ActionTypes.SAVED_REPORTBACK_REPORT:
      return {
        ...state,
        current: {
          ...state.current,
          reportBacks: updateData(state.current.reportBacks, action.payload),
        },
        currentAttachment: null,
      };
    case ActionTypes.SAVED_REPORTBACK:
    case ActionTypes.DELETED_REPORTBACK_REPORT:
      return updateCurrentApplicationItem(state, action.payload, "reportBacks");
    case ActionTypes.DELETED_REPORTBACK:
      return removeCurrentApplicationItem({ state, removeId: action.payload.reportBackId, entity: "reportBacks" });
    // attachments
    case ActionTypes.CREATED_APPLICATION_ATTACHMENT:
      return addCurrentApplicationItem(state, action.payload, "attachments");
    case ActionTypes.DELETED_APPLICATION_ATTACHMENT:
      return {
        ...state,
        current: {
          ...state.current,
          attachments: filter(state.current.attachments, x => x.id !== action.payload.attachmentId),
        },
      };
    case ActionTypes.CREATED_ATTACHMENT_FILE:
    case ActionTypes.LOADED_ATTACHMENT_FILE:
      return {
        ...state,
        currentAttachment: action.payload,
      };
    // additionalInformation
    case ActionTypes.CREATED_APPLICATION_ADDITIONAL_INFORMATION:
    case ActionTypes.LOADED_APPLICATION_ADDITIONAL_INFORMATION:
      return {
        ...state,
        current: {
          ...state.current,
          additionalInformation: action.payload.additionalInformation,
        },
      };
    // applicant and consultant
    case ActionTypes.CREATED_APPLICANT:
    case ActionTypes.SAVED_APPLICANT:
      return {
        ...state,
        current: normaliseApplication({
          ...state.current,
          otherSpecialty: action.payload.otherSpecialty,
          consultantSpecialty: action.payload.consultantSpecialty,
          applicant: action.payload.applicant,
          consultant: (!isEmpty(state.current.consultant) && state.current.consultant.id === action.payload.applicant.id) ? action.payload.applicant : state.current.consultant,
          statusFlags: action.payload.statusFlags,
        }),
      };
    case ActionTypes.CREATED_CONSULTANT:
    case ActionTypes.SAVED_CONSULTANT:
      return {
        ...state,
        current: normaliseApplication({
          ...state.current,
          otherSpecialty: action.payload.otherSpecialty,
          consultantSpecialty: action.payload.consultantSpecialty,
          consultant: action.payload.consultant,
          statusFlags: action.payload.statusFlags,
        }),
      };
    case ActionTypes.DELETED_CONSULTANT:
      return {
        ...state,
        current: {
          ...state.current,
          consultant: null,
        },
      };
    // endorsements
    case ActionTypes.CREATED_ENDORSEMENT:
      return {
        ...addCurrentApplicationItem(
          state,
          action.payload.endorsement,
          "endorsements", {
            urgentTreatmentApprovalGranted: action.payload.urgentTreatmentApprovalGranted,
            statusFlags: action.payload.statusFlags,
          },
        ),
        ...updateApplicationsFromEndorsement(state, action.payload.applicationId, action.payload),
      };
    case ActionTypes.SAVED_ENDORSEMENT:
      return {
        ...updateCurrentApplicationItem(
          state,
          action.payload.endorsement,
          "endorsements", {
            urgentTreatmentApprovalGranted: action.payload.urgentTreatmentApprovalGranted,
            status: action.payload.status,
            statusFlags: action.payload.statusFlags,
          },
        ),
        ...updateApplicationsFromEndorsement(state, action.payload.applicationId, action.payload),
      };
    case ActionTypes.DELETED_ENDORSEMENT:
      return {
        ...removeCurrentApplicationItem({
          state,
          removeId: action.payload.endorsementId,
          entity: "endorsements",
          urgentTreatmentApprovalGranted: action.payload.urgentTreatmentApprovalGranted,
          statusFlags: action.payload.statusFlags,
          status: action.payload.status,
        }),
        ...updateApplicationsFromEndorsement(state, action.payload.applicationId, action.payload),
      };
    case ActionTypes.CREATED_INTERESTED_PERSON:
      return addCurrentApplicationItem(state, action.payload, "interestedPeople");
    case ActionTypes.SAVED_INTERESTED_PERSON:
      return updateCurrentApplicationItem(state, action.payload, "interestedPeople");
    case ActionTypes.DELETED_INTERESTED_PERSON:
      return removeCurrentApplicationItem({
        state,
        refId: "personId",
        removeId: action.payload.personId,
        entity: "interestedPeople",
      });
    case ActionTypes.ASSIGNED_APPLICATION:
      return {
        ...state,
        current: {
          ...state.current,
          assignment: action.payload.applicationAssignment,
        },
      };
    case ActionTypes.CREATED_DECISION:
    case ActionTypes.SAVED_DECISION:
    case ActionTypes.DELETED_APPLICATION_DECISION:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.applicationId]: {
            ...state.all[action.payload.applicationId],
            status: action.payload.status,
            displayProvisionalApprovalLabel: action.payload.displayProvisionalApprovalLabel,
          },
        },
        current: {
          ...state.current,
          status: action.payload.status,
          decision: action.payload.decision,
          statusFlags: action.payload.statusFlags,
          displayProvisionalApprovalLabel: action.payload.displayProvisionalApprovalLabel,
        },
      };
    case ActionTypes.ACCEPTING_APPLICATION:
    case ActionTypes.SAVING_APPLICATION:
      return {
        ...state,
        loadingCurrent: true,
      };
    case ActionTypes.SET_CURRENT_APPLICATION:
      return {
        ...state,
        current: normaliseApplication(action.payload),
      };
    case ActionTypes.LOADING_DRUG_COST_INFORMATION:
      return {
        ...state,
        drugCostInformation: {
          ...state.drugCostInformation,
          meta: { loading: true, loaded: false, error: false },
        },
      };
    case ActionTypes.LOADED_DRUG_COST_INFORMATION:
      return {
        ...state,
        drugCostInformation: {
          current: {
            result: action.payload.drugCostInformation,
            identifier: action.payload.identifier,
            identifierType: action.payload.identifierType,
          },
          meta: { loading: false, loaded: true, error: false },
        },
      };
    case ActionTypes.ERROR_LOADING_DRUG_COST_INFORMATION:
      return {
        ...state,
        drugCostInformation: {
          ...state.drugCostInformation,
          meta: { loading: false, loaded: false, error: action.payload.message },
        },
      };
    default:
      return state;
  }
};

export const getApplicationsForWorklist = curry(({ localState }) => {
  const applications = get(localState, ["all"], {});
  const applicationsForWorkList = map(localState.applicationsForWorkList, key => applications[key]);
  return applicationsForWorkList;
});

export const isCurrentApplicationLoading = curry(({ localState }) => localState.loadingCurrent);
export const isCurrentApplicationLoadingError = curry(({ localState }) => localState.errorLoadingCurrent);

export const getCurrentApplication = curry(({ localState }) => get(localState, ["current"], null));

export const getApplicationById = curry(({ localState }, applicationId) => {
  const applications = get(localState, ["all"], {});
  return find(applications, x => x.id === applicationId);
});

export const getCurrentAttachment = curry(({ localState }) => get(localState, ["currentAttachment"], null));

export const getAttachmentByAttachmentId = curry(({ localState }, attachmentId) => {
  const attachments = get(localState, ["current", "attachments"], null);
  return find(attachments, x => x.id === attachmentId);
});

export const getRequiredSections = curry(({ localState }, isReportBackApplication = false) => {
  const allRequiredSections = get(localState, ["current", "requiredSections"], null);
  if (isReportBackApplication) {
    remove(allRequiredSections, x => x.name === "endorsement");
  }
  return allRequiredSections;
});

export const isSectionCompleted = curry(({ localState }, name) => {
  const requiredSections = get(localState, ["current", "requiredSections"], null);
  const section = find(requiredSections, x => x.name === name);

  return get(section, "complete", false) === false;
});

export const isDrugCostInformationLoading = curry(({ localState }) => localState.drugCostInformation.meta.loading);
export const isDrugCostInformationLoadingError = curry(({ localState }) => localState.drugCostInformation.meta.error);
export const getDrugCostInformation = curry(({ localState }) => get(localState, ["drugCostInformation", "current"], null));
