import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import { getAllergiesByPatientId, areAllergiesLoading, getErrorMessage } from "app/main/patients/reducers/allergies.reducers";
import { fetchPatientAllergies, editExistingAllergy, recordNewAllergy, removeAllergy, fetchAllergiesById } from "app/main/patients/actions/allergies.actions";
import RecordAllergyForm from "app/main/patients/allergies/components/record-allergy-form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import withPermissions from "permissions/withPermissions";

class AllergyListContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    const { patientId } = this.props;
    if (patientId) {
      this.props.fetchPatientAllergies(patientId);
    }
    this.onRecordAllergy = this.onRecordAllergy.bind(this);
    this.onEditAllergy = this.onEditAllergy.bind(this);
  }

  onClose = () => this.props.closeDialog();

  openRecordAllergyForm = (title, isEdit) => {
    this.props.openDialog({
      children: <RecordAllergyForm
        title={title}
        onSucceed={this.onClose}
        isEdit={isEdit}
      />,
    });
  }

  onRecordAllergy = () => {
    this.props.recordNewAllergy(this.props.patientId);
    this.openRecordAllergyForm("Add Allergy");
  };

  onEditAllergy = allergyId => {
    this.props.editExistingAllergy(this.props.patientId, allergyId);
    this.openRecordAllergyForm("Edit Allergy", true);
  };

  onRemoveAllergy = allergyId => {
    this.props.removeAllergy(this.props.patientId, allergyId);
    this.onClose();
  };

  render() {
    const {
      loading,
      error,
      allergies,
      renderContent,
      hasPermissionAllergiesCreate,
      hasPermissionAllergiesUpdate,
      hasPermissionAllergiesDelete,
    } = this.props;

    return (
      renderContent({
        allergies,
        onEditAllergy: hasPermissionAllergiesUpdate ? this.onEditAllergy : null,
        onRemoveAllergy: hasPermissionAllergiesDelete ? this.onRemoveAllergy : null,
        onRecordAllergy: hasPermissionAllergiesCreate ? this.onRecordAllergy : null,
        isLoading: loading,
        isError: error,
        isEmpty: isEmpty(allergies),
      })
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { patientId } = ownProps;
  const allergies = getAllergiesByPatientId(state, patientId);
  return {
    allergies,
    error: getErrorMessage(state, patientId),
    loading: areAllergiesLoading(state, patientId),
  };
};

export default withPermissions(
  "AllergiesDelete",
  "AllergiesUpdate",
  "AllergiesCreate",
)(connect(
  mapStateToProps,
  {
    openDialog,
    closeDialog,
    fetchAllergiesById,
    fetchPatientAllergies,
    recordNewAllergy,
    editExistingAllergy,
    removeAllergy,
  },
)(AllergyListContainer));
