import React from "react";
import { InputAdornment, IconButton, Icon } from "@material-ui/core";
import { get } from "lodash";
import { NoteIcon } from "helpers/icon-finder";
import BaseTextInput from "./base-text-input";
import BaseTextField from "./components/base-text-field";

export function TextInput({
  field: { name, value },
  form: { touched, errors, setFieldValue, setFieldTouched },
  suffix,
  onChange,
  onBlur,
  ...other
}) {
  const isTouched = get(touched, name, false);

  const handleTouched = () => {
    if (isTouched) return;
    setFieldTouched(name, true);
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <BaseTextInput
      name={name}
      touched={isTouched}
      error={get(errors, name, null)}
      value={value || ""}
      onChange={e => {
        setFieldValue(name, e.target.value);
        if (onChange) {
          onChange(e.target.value, setFieldValue);
        }
      }}
      onBlur={handleTouched}
      endAdornment={suffix && <InputAdornment position="end" className="px-8">{suffix}</InputAdornment>}
      {...other}
    />
  );
}

export function TextInputField({
  field: { name, value },
  form: { touched, errors, setFieldValue, setFieldTouched },
  suffix,
  onChange,
  onBlur,
  ...other
}) {
  const isTouched = get(touched, name, false);

  const handleTouched = event => {
    if (isTouched) return;
    setFieldTouched(name, true);
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <BaseTextField
      name={name}
      touched={isTouched}
      error={get(errors, name, null)}
      value={value || ""}
      onChange={e => {
        const val = e.target.value;
        setFieldValue(name, val);
        if (onChange) {
          onChange(val, setFieldValue);
        }
      }}
      onBlur={handleTouched}
      endAdornment={suffix ? <InputAdornment position="end" className="px-8">{suffix}</InputAdornment> : null}
      {...other}
    />
  );
}

export default TextInputField;

export const NumberInput = props => (
  <TextInputField
    {...props}
    type="number"
    onKeyDown={e => (e.key === "-" || e.key === "+" || e.key === "e") && e.preventDefault()}
  />
);

export const TextAreaInput = props => (
  <TextInputField
    {...props}
    rows={props.rows || "4"}
    multiline
  />
);

export const NoteInput = props => (
  <TextAreaInput
    icon={<NoteIcon />}
    {...props}
  />
);

export const CurrencyInput = props => {
  const { form: { setFieldValue }, field: { name, value }, loading } = props;
  return (
    <TextInputField
      loading={loading}
      type="number"
      defaultActive
      onKeyDown={e => (e.key === "-" || e.key === "+" || e.key === "e") && e.preventDefault()}
      suffix={value && <IconButton edge="end" onClick={() => setFieldValue(name, null)}><Icon fontSize="small">clear</Icon></IconButton>}
      startAdornment={<InputAdornment position="start">$</InputAdornment>}
      {...props}
    />
  );
};

export const PasswordInput = props => {
  const [showPassword, setShowPassword] = React.useState(false);
  const type = showPassword ? "text" : "password";

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowPassword(false);
    }, 6000);
    return () => clearTimeout(timer);
  }, [showPassword]);

  return (
    <TextInputField
      type={type}
      inputProps={{
        autoComplete: "current-password",
      }}
      suffix={(
        <IconButton
          edge="end"
          onClick={() => setShowPassword(!showPassword)}
        >
          <Icon fontSize="small">{showPassword ? "visibility" : "visibility_off"}</Icon>
        </IconButton>
      )}
      {...props}
    />
  );
};
