import moment from "moment-timezone";

import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import { isPageLoaded, isPageLoading, ActionTypes } from "../reducers/externalDocumentWorklist.reducers";

export const setCurrentExternalSourceDocument = document => ({
  type: ActionTypes.SET_CURRENT_EXTERNAL_DOCUMENT_WORKLIST,
  payload: { document },
});

export const searchExternalSourceDocuments = (pageNumber, pageSize, searchParams, forceLoad = false, isWorklist = true) => dispatch => {
  const params = formatSearchParams(searchParams);
  const timeZone = moment.tz.guess();
  const url = `/externalSourceDocuments?pageNumber=${pageNumber}&pageSize=${pageSize}&timeZone=${timeZone}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: state => isPageLoading(state, pageNumber) || (!forceLoad && isPageLoaded(state, pageNumber)),
      entityType: isWorklist ? "EXTERNAL_DOCUMENT_WORKLIST" : "EXTERNAL_DOCUMENT_OPTIONS",
      pageNumber,
      isWorklist,
    },
  });
};

export const removeExternalSourceDocument = externalSourceDocumentId => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/externalSourceDocuments/remove?externalSourceDocumentId=${externalSourceDocumentId}`,
    types: ["DELETING_EXTERNAL_DOCUMENT_WORKLIST_ITEM", ActionTypes.DELETED_EXTERNAL_DOCUMENT_WORKLIST_ITEM, "ERROR_DELETING_EXTERNAL_DOCUMENT_WORKLIST_ITEM"],
    externalSourceDocumentId,
  },
});

export const restoreExternalSourceDocument = externalSourceDocumentId => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/externalSourceDocuments/restore?externalSourceDocumentId=${externalSourceDocumentId}`,
    body: { externalSourceDocumentId },
    types: ["RESTORING_EXTERNAL_DOCUMENT_WORKLIST_ITEM", ActionTypes.RESTORED_EXTERNAL_DOCUMENT_WORKLIST_ITEM, "ERROR_RESTORING_EXTERNAL_DOCUMENT_WORKLIST_ITEM"],
    externalSourceDocumentId,
  },
});

export const fetchExternalSourceDocument = externalSourceDocumentId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/externalSourceDocuments/${externalSourceDocumentId}`,
    method: "GET",
    entityType: "CURRENT_SOURCE_DOCUMENT_FILE",
  },
});
