import { ApiActions } from "middleware/call-api";
import { get } from "lodash";
import moment from "moment";
import { ActionTypes,
  areProblemsLoading,
  areProblemsLoaded,
  getProblemById } from "../reducers/problems.reducers";

export const setCurrentProblem = problem => ({
  type: ActionTypes.LOADED_CURRENT_PROBLEM,
  payload: { problem },
});

export const fetchPatientProblems = patientId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/problems`,
    bailout: state => areProblemsLoaded(state, patientId) || areProblemsLoading(state, patientId),
    entityType: "problems",
    patientId,
  },
});

export const fetchProblemsById = (patientId, problemId) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/problems/${problemId}`,
    types: [ActionTypes.LOADING_CURRENT_PROBLEM, ActionTypes.LOADED_CURRENT_PROBLEM, ActionTypes.ERROR_LOADING_CURRENT_PROBLEM],
    patientId,
    problemId,
  },
});

/** * Add Problem */
export const recordNewProblem = () => setCurrentProblem({
  id: null,
  recordedDateTimeUtc: moment(),
  priority: null,
  description: null,
  note: null,
});

export const editExistingProblem = (patientId, problemId) => (dispatch, getState) => {
  const problem = getProblemById(getState(), patientId, problemId);
  dispatch(setCurrentProblem(problem));
};

export const recordOrUpdateProblem = (patientId, problem) => async dispatch => {
  const PROBLEM_ACTION = get(problem, "id", false)
    ? ["UPDATING_PROBLEM", ActionTypes.UPDATED_PROBLEM, "ERROR_UPDATING_PROBLEM"]
    : ["CREATING_PROBLEM", ActionTypes.CREATED_PROBLEM, "ERROR_CREATING_PROBLEM"];

  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/patient/${patientId}/problems/record`,
      body: { problem },
      types: PROBLEM_ACTION,
      patientId,
    },
  });
};

export const removeProblem = (patientId, problemId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/patient/${patientId}/problems/remove`,
    body: { problemId },
    entityType: "problem",
    patientId,
    problemId,
  },
});

export const resolveProblem = (patientId, problemId) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/patient/${patientId}/problems/resolve`,
    body: { problemId },
    types: ["RESOLVING_PROBLEM", ActionTypes.RESOLVE_PROBLEM, "ERROR_RESOLVING_PROBLEM"],
    patientId,
  },
});

export const renewProblem = (patientId, problemId) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/patient/${patientId}/problems/renew`,
    body: { problemId },
    types: ["RENEWING_PROBLEM", ActionTypes.RENEW_PROBLEM, "ERROR_RENEWING_PROBLEM"],
    patientId,
  },
});
