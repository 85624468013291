import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { map } from "lodash";
import PropTypes from "prop-types";

import { areCodeSetValuesLoaded, areCodeSetValuesLoading, getCodeSetValuesByTypeCode } from "app/main/codeSet/reducers/codeSet.reducers";
import { fetchAllCodeSetValues } from "app/main/codeSet/actions/codeSet.actions";
import { AutoComplete, AutoCompleteLoading } from "components/inputs";

const CodeSetValueSelector = ({
  label,
  codeSetTypeCode,
  useCodeAsValue,
  ...other
}) => {
  const dispatch = useDispatch();
  const loaded = useSelector(areCodeSetValuesLoaded);
  const loading = useSelector(areCodeSetValuesLoading);
  const options = useSelector(state => getCodeSetValuesByTypeCode(state, codeSetTypeCode));

  React.useEffect(() => {
    if (loaded === false) {
      dispatch(fetchAllCodeSetValues());
    }
  }, [dispatch, loaded]);

  if (loading) return <AutoCompleteLoading label={label} />;

  return (
    <AutoComplete
      label={label}
      options={map(options?.codeSetValues, x => ({ label: x.description, value: useCodeAsValue ? x.code : x.id, settings: x.propertiesJson }))}
      filterSelectedOptions
      getOptionSelected={(option, val) => option.value === val.value}
      {...other}
    />
  );
};

CodeSetValueSelector.propTypes = {
  codeSetTypeCode: PropTypes.string.isRequired,
};

export default CodeSetValueSelector;
