import { useEffect } from "react";
import { reduce } from "lodash";
import { fetchEndorsementTypeConfiguration } from "app/main/orgUnits/actions/orgUnit.actions";
import { useSelector, useDispatch } from "react-redux";
import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import { getEndorsementTypeSettings, areEndorsementTypesLoading } from "app/main/orgUnits/reducers/orgUnit.reducers";

const useEndorsementTypes = orgUnitId => {
  const dispatch = useDispatch();
  const signedInOrgUnit = useSelector(getSignedInOrgUnit);
  const currOrgUnitId = orgUnitId ?? signedInOrgUnit?.id;

  const loadingEndorsementTypes = useSelector(state => areEndorsementTypesLoading(state, currOrgUnitId));
  const endorsementTypeSettings = useSelector(state => getEndorsementTypeSettings(state, currOrgUnitId));

  const endorsementTypeOptions = reduce(endorsementTypeSettings, (results, endorsementTypeSetting) => {
    if (endorsementTypeSetting.enabled) {
      return [...results, { value: endorsementTypeSetting.endorsedByType, label: endorsementTypeSetting.endorsedByTypeLabel }];
    }
    return results;
  }, []);

  useEffect(() => {
    if (currOrgUnitId) {
      dispatch(fetchEndorsementTypeConfiguration(currOrgUnitId));
    }
  }, [currOrgUnitId, dispatch]);
  return { endorsementTypeSettings, endorsementTypeOptions, loadingEndorsementTypes };
};

export default useEndorsementTypes;
