import { reduce, isEmpty } from "lodash";

export const getPatientIdentifier = (identifiers, identifier, includeLabel = false) => {
  const result = reduce(identifiers, (r, i) => {
    if (i.patientIdentifierTypeCode === identifier) {
      return { ...r, label: i.label, identifier: i.identifier };
    }
    return r;
  }, {});

  if (isEmpty(result)) return "";

  return `${includeLabel ? `${result.label}: ` : ""}${result.identifier}`;
};
