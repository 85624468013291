import React from "react";
import { Divider, Typography } from "@material-ui/core";
import clsx from "clsx";

export default ({ withDivider, title, children, classes }) => (
  <div className={clsx("py-8", classes?.root)}>
    {title && <Typography className="mx-32" gutterBottom>{title}</Typography>}
    <div className="items-container md">
      {children}
    </div>
    {withDivider && <div className="pb-8"><Divider /></div>}
  </div>
);
