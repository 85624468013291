const SelectStyles = {
  container: base => ({
    ...base,
    padding: 0,
    flex: 1,
  }),
  control: (base, state) => {
    const { hasValue, selectProps: { isMulti } } = state;

    return ({
      ...base,
      border: 0,
      backgroundColor: "transparent",
      minHeight: "auto",
      boxShadow: 0,
      "&>div:first-of-type": {
        padding: (hasValue || isMulti) ? 0 : "6px 0 7px",
      },
    });
  },

  input: base => ({
    ...base,
    padding: 0,
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 9999,
  }),
  indicatorSeparator: () => null,
  option: base => ({
    ...base,
    fontSize: 14,
  }),
};

export default SelectStyles;
