import { get, mapKeys, map, concat } from "lodash";
import curryConnector from "utils/connectors";
import { ActionTypes as ReferralsActionTypes } from "app/main/referrals/reducers/referrals.reducers";

export const PATIENT_REFERRAL_WORKLIST_STATE_KEY = "patientReferralWorklist";
export const PATIENT_REFERRAL_PAGE_SIZE = 5; // update to 10 when done

const curry = fn => curryConnector(fn, PATIENT_REFERRAL_WORKLIST_STATE_KEY);

export const ActionTypes = {
  LOADING_PATIENT_REFERRAL_WORKLIST: "LOADING_PATIENT_REFERRAL_WORKLIST",
  LOADED_PATIENT_REFERRAL_WORKLIST: "LOADED_PATIENT_REFERRAL_WORKLIST",
  ERROR_LOADING_PATIENT_REFERRAL_WORKLIST: "ERROR_LOADING_PATIENT_REFERRAL_WORKLIST",
};

const INITIAL_STATE = {
  all: null,
  pageInfo: {
    pageNumber: 1,
    pageSize: PATIENT_REFERRAL_PAGE_SIZE,
    totalRecords: 0,
  },
  pages: { loading: false, loaded: false, error: null },
  searchParams: { name: null },
  patientReferralForWorklist: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_PATIENT_REFERRAL_WORKLIST:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: true, loaded: false, error: false } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.LOADED_PATIENT_REFERRAL_WORKLIST:
      return {
        ...state,
        searchParams: action.payload.searchParams,
        pageInfo: action.payload.pageInfo,
        all: { ...state.all, ...mapKeys(action.payload.patients, x => x.patientId) },
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: false, error: false, loaded: true } },
        patientReferralForWorklist: (action.payload.pageInfo.pageNumber === 1)
          ? map(action.payload.patients, x => x.patientId)
          : concat(state.patientReferralForWorklist, map(action.payload.patients, x => x.patientId)),
      };
    case ActionTypes.ERROR_LOADING_PATIENT_REFERRAL_WORKLIST:
      return { ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: false, error: action.payload.message, loaded: false } } };
    case ReferralsActionTypes.CREATE_MANUAL_REFERRAL:
      return {
        ...state,
        all: state.all ? {
          ...state.all,
          [action.payload.patientId]: {
            ...state.all[action.payload.patientId],
            referralCount: state.all[action.payload.patientId]?.referralCount + 1,
          },
        } : state.all,
      };
    default:
      return state || INITIAL_STATE;
  }
};

export const getPatientReferralsList = curry(({ localState }) => {
  const patients = get(localState, ["all"], {});
  return map(localState.patientReferralForWorklist, key => patients[key]);
});

export const isPageLoading = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState?.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loading"], false);
});

export const isPageLoaded = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState?.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loaded"], false);
});

export const getPageErrorMessage = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState?.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "error"], null);
});

export const getPatientReferralWorklistPageInfo = curry(({ localState }) => get(localState, ["pageInfo"], null));

export const getPatientReferralWorklistSearchParams = curry(({ localState }) => get(localState, ["searchParams"], null));