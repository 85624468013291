import { isString } from "lodash";

// check if address is empty
export function addressIsEmpty(address) {
  if (!address) return true;
  if (isString(address)) return false;

  return Object.keys(address).every(key => {
    switch (key) {
      case "countryCode":
        return true;
      default:
        return !address[key];
    }
  });
}

// check if contact is empty
export function contactIsEmpty(contact) {
  if (!contact) return true;
  if (isString(contact)) return false;

  return Object.keys(contact).every(key => {
    switch (key) {
      case "preferredContactMethod":
      case "email":
      case "fax":
        return true;
      default:
        return !contact[key];
    }
  });
}
