import React from "react";
import { map, chunk, size } from "lodash";
import clsx from "clsx";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    "& > div:not(.dummy):empty": {
      display: "none",
    },
  },
  toTop: {
    marginTop: theme.spacing(1),
  },
  gutter: {
    paddingLeft: theme.spacing(2),
  },
  dummy: {
    flex: "1 1 100%",
  },
}));

const PanelContent = ({
  width,
  children,
}) => {
  const sm = isWidthDown("sm", width);
  const classes = useStyles();
  const tileRows = chunk(children, 2);

  return (
    map(tileRows, (row, rowIndex) => (
      <div
        key={rowIndex.toString()}
        className={clsx(
          "flex",
          sm && "flex-col",
          rowIndex !== 0 && classes.toTop,
          classes.root,
        )}
      >
        {map(row, (x, index) => {
          const withCutter = index % 2 !== 0;
          const isFirstRow = index === 0 || index === 1;

          return (
            <React.Fragment key={index.toString()}>
              <div
                className={clsx(
                  "items-container",
                  (withCutter && !sm) && classes.gutter,
                  (!isFirstRow || (sm && index !== 0)) && classes.toTop,
                  sm && "w-full",
                )}
              >
                {x}
              </div>
              {size(row) < 2 && <div className={clsx("dummy", !sm && classes.gutter, classes.dummy)} key={`dummy${index}`} />}
            </React.Fragment>
          );
        })}
      </div>
    ))
  );
};

export default withWidth()(PanelContent);
