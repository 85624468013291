import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AccountEdit from "mdi-react/AccountEditIcon";
import { isEmpty } from "lodash";

import PatientForm from "app/main/patients/components/patient-form";
import { getExistingPatientById, resetCurrentPatient } from "app/main/patients/actions/patients.actions";
import { isCurrentPatientLoading, getCurrentPatient } from "app/main/patients/reducers/patients.reducers";
import DefaultPageLayout from "components/layouts/default-page-layout";
import ScreenHeader from "components/items/screen-header";
import withPermissions from "permissions/withPermissions";
import DefaultButton from "components/items/default-button";
import useSetUrl from "app/main/admin/hooks/useSetUrl";

const PatientEditPage = ({
  match: { params: { id } },
  hasPermissionPatientsUpdate,
  hasPermissionPatientsMaintainReadonly,
  history,
  route,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(isCurrentPatientLoading);
  const currentPatient = useSelector(getCurrentPatient);
  const [disabled, setDisabled] = useState(true);

  useSetUrl({ id, ...route });

  useEffect(() => {
    if (!loading && isEmpty(currentPatient)) {
      dispatch(getExistingPatientById(id));
    }
    return () => dispatch(resetCurrentPatient());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultPageLayout
      loading={loading}
      header={(
        <ScreenHeader
          title={currentPatient.displayNameFull}
          renderActions={(
            !hasPermissionPatientsMaintainReadonly && hasPermissionPatientsUpdate && <DefaultButton label="Edit" variant="outlined" color="inherit" icon={<AccountEdit />} onClick={() => setDisabled(false)} disabled={!disabled} />)}
        />
      )}
      content={(
        <PatientForm
          patient={currentPatient}
          isEdit
          onCancel={() => setDisabled(true)}
          onSucceed={() => history.push("/patients")}
          disabled={disabled}
        />
      )}
    />
  );
};

export default withPermissions("PatientsUpdate", "PatientsMaintainReadonly")(PatientEditPage);
