import React from "react";
import PropTypes from "prop-types";
import { map, get } from "lodash";
import { Card, CardContent, Typography, Box } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import DefaultButton from "components/items/default-button";
import { actionPanel } from "components/styles/base";
import IconComponent from "components/items/icon-component";
import StateContainer from "components/layouts/default-state-container";

const useStyles = makeStyles(theme => ({
  headerContainer: {
    flex: "1 1 auto !important",
  },
  headerActionContainer: {
    display: "flex",
    flexShrink: 1,
    flexWrap: "wrap",
    [theme.breakpoints.up("md")]: {
      minWidth: actionPanel.width - theme.spacing(3),
      justifyContent: "flex-end",
    },
  },
  headerAction: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },
  contentContainer: {
    margin: theme.spacing(0, 2),
  },
}));

export const DefaultPanelLayout = ({
  icon,
  title,
  headerActions,
  children,
  action,
  isLoading,
  isEmpty,
  emptyTitle,
  error,
  isError,
  headerContent,
  headerRightContent,
  status,
  emptyState,
  accessDenied,
  isLast,
  subtitle,
  classes,
  elevation = 1,
}) => {
  const styles = useStyles();

  return (
    <div className={clsx("w-full", styles.container, classes?.container)}>
      <Box p={2}>
        <Box className="flex-row-container">
          <Box className={clsx(styles.headerContainer, classes?.headerContainer)}>
            {(icon || title)
          && (
          <Box className="flex-row-container">
            <Box className="mr-16 flex"><IconComponent icon={icon} size={24} /></Box>
            <Typography variant="h6" color="inherit" className={clsx(status && "mr-8", "enable-shrink")}>{title}</Typography>
            {status && status}
          </Box>
          )}
            {headerContent && headerContent}
          </Box>
          {(headerActions || headerRightContent)
          && (
          <Box className={styles.headerActionContainer}>
            {headerRightContent && headerRightContent}
            {headerActions
              && (
              <Box className={styles.headerAction}>
                {map(headerActions, (headerAction, index) => {
                  if (!headerAction) return null;
                  return (
                    <DefaultButton
                      className={clsx(index !== 0 && "ml-16")}
                      icon={headerAction.icon}
                      label={headerAction.label}
                      key={headerAction.label}
                      onClick={headerAction.onClick}
                      disabled={headerAction.disabled}
                      loading={get(headerAction, "loading", false)}
                      fab
                    />
                  );
                })}
              </Box>
              )}
          </Box>
          )}
        </Box>
        {subtitle && <Box mt={1}>{subtitle}</Box>}
      </Box>
      <Card classes={{ root: clsx(styles.contentContainer, isLast && "mb-16", classes?.contentContainer) }} elevation={elevation}>
        <CardContent className={classes?.content}>
          <StateContainer loading={isLoading} error={error || isError} accessDenied={accessDenied} empty={isEmpty} emptyTitle={emptyTitle} emptyState={emptyState}>
            {children}
            {action
          && (
          <Box display="flex" justifyContent="center" className="px-16 mt-32">
            <DefaultButton
              className="w-full sm:w-320"
              variant="outlined"
              onClick={action.onClick}
              label={action.label}
            />
          </Box>
          )}
          </StateContainer>
        </CardContent>
      </Card>
    </div>
  );
};

DefaultPanelLayout.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  title: PropTypes.string,
  headerAction: PropTypes.shape({
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    elevation: PropTypes.number,
  }),
  elevation: PropTypes.number,
};

export default DefaultPanelLayout;
