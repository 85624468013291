import curryConnector from "utils/connectors";
import { formatUtcDate } from "helpers/format-date-time";
import { applicationStatusFilterOptions } from "./applicationWorklist.reducers";

export const APPLICATION_HISTORY_STATE_KEY = "applicationHistory";

const curry = fn => curryConnector(fn, APPLICATION_HISTORY_STATE_KEY);

export const ActionTypes = {
  LOADING_APPLICATION_HISTORY: "LOADING_APPLICATION_HISTORY",
  ERROR_LOADING_APPLICATION_HISTORY: "ERROR_LOADING_APPLICATION_HISTORY",
  LOADED_APPLICATION_HISTORY: "LOADED_APPLICATION_HISTORY",
};

const normaliseApplicationHistory = history => ({
  ...history,
  applicationStatus: applicationStatusFilterOptions.find(x => x.value === history.applicationStatus),
  changedDateTime: formatUtcDate(history.changedDateTimeUtc, "DD-MMM-YY HH:mm"),
});

const INITIAL_STATE = {
  meta: {},
  history: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_APPLICATION_HISTORY:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.applicationId]: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };
    case ActionTypes.ERROR_LOADING_APPLICATION_HISTORY:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.applicationId]: {
            loading: false,
            loaded: false,
            error: action.payload.message,
          },
        },
      };
    case ActionTypes.LOADED_APPLICATION_HISTORY:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.applicationId]: {
            loading: false,
            loaded: true,
            error: null,
          },
        },
        history: {
          ...state.history,
          [action.payload.applicationId]: action.payload.applicationHistory.map(normaliseApplicationHistory),
        },
      };
    default:
      return state || INITIAL_STATE;
  }
};

export const getApplicationHistory = curry(({ localState }, applicationId) => localState.history[applicationId] ?? []);

export const isApplicationHistoryLoading = curry(({ localState }, applicationId) => localState.meta[applicationId]?.loading);

export const isApplicationHistoryLoaded = curry(({ localState }, applicationId) => localState.meta[applicationId]?.loaded);
