import React from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import { Grid, Icon } from "@material-ui/core";
import { TextInput, NoteInput } from "components/inputs/text-input";
import LoadingState from "components/items/loading-state";
import { addReferralCorrespondence } from "app/main/referrals/actions/referrals.actions";
import { validateRequired, validateMaxLength } from "utils/validators";
import Form from "components/form";

const messageLabel = "Message";
const subjectLabel = "Subject";
const validateMessage = value => validateRequired(messageLabel)(value) || validateMaxLength(messageLabel, 4000)(value);
const validateSubject = value => validateRequired(subjectLabel)(value) || validateMaxLength(subjectLabel, 200)(value);
class ReferralCorrespondenceForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = ({ referralCorrespondence }, { setErrors }) => {
    const newReferralCorrespondence = {
      ...referralCorrespondence,
      referralId: this.props.referralId,
    };
    this.props.addReferralCorrespondence(newReferralCorrespondence).then(responds => {
      if (responds.error !== true) {
        this.props.onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  render() {
    const {
      referrerFullName,
      loading,
      ...other
    } = this.props;

    if (loading) {
      return <LoadingState />;
    }

    return (
      <Form
        onSubmit={this.handleSubmit}
        contentProps={other}
        initialValues={{ referralCorrespondence: { subject: null, message: null } }}
        variant="filled"
        content={() => (
          <div>
            <Grid container direction="row" alignItems="center" className="mb-24">
              <Icon className="mr-8">person</Icon>To: {referrerFullName}
            </Grid>
            <Field
              name="referralCorrespondence.subject"
              component={TextInput}
              label={subjectLabel}
              icon="mode_comment"
              validate={validateSubject}
            />
            <Field
              name="referralCorrespondence.message"
              component={NoteInput}
              label={messageLabel}
              validate={validateMessage}
            />
          </div>
        )}
      />
    );
  }
}

export default connect(null, { addReferralCorrespondence })(ReferralCorrespondenceForm);
