import { get, filter, isEmpty, find } from "lodash";
import curryConnector from "utils/connectors";
import { addItem, updateItem, removeItem } from "app/main/patients/helpers/reducer-helper-functions";
import { ActionTypes as PatientActionTypes } from "app/main/patients/reducers/patients.reducers";
import { ActionTypes as ReferralActionTypes } from "app/main/referrals/reducers/referrals.reducers";

export const CONTACTS_STATE_KEY = "contacts";

const curry = fn => curryConnector(fn, CONTACTS_STATE_KEY);

export const ActionTypes = {
  LOADING_CONTACTS: "LOADING_CONTACTS",
  LOADED_CONTACTS: "LOADED_CONTACTS",
  ERROR_LOADING_CONTACTS: "ERROR_LOADING_CONTACTS",

  LOADING_CURRENT_CONTACT: "LOADING_CURRENT_CONTACT",
  ERROR_LOADING_CURRENT_CONTACT: "ERROR_LOADING_CURRENT_CONTACT",
  LOADED_CURRENT_CONTACT: "LOADED_CURRENT_CONTACT",

  SAVED_CONTACT: "SAVED_CONTACT",
  UPDATED_CONTACT: "UPDATED_CONTACT",
  CREATED_CONTACT: "CREATED_CONTACT",
  DELETED_CONTACT: "DELETED_CONTACT",
};

const INITIAL_STATE = {
  all: {},
  current: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_CONTACTS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: true } },
      };
    case ActionTypes.ERROR_LOADING_CONTACTS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: false, error: action.payload.message } },
      };
    case ActionTypes.LOADED_CONTACTS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]:
            {
              ...state.all[action.payload.patientId],
              loading: false,
              contacts: action.payload.contacts,
            },
        },
      };
    case ActionTypes.LOADING_CURRENT_CONTACT:
      return {
        ...state,
        current: {
          ...state.current,
          loading: true,
        },
      };
    case ActionTypes.ERROR_LOADING_CURRENT_CONTACT:
      return {
        ...state,
        current: {
          ...state.current,
          error: action.error,
        },
      };
    case ActionTypes.LOADED_CURRENT_CONTACT:
      return {
        ...state,
        current: action.payload.contact,
      };
    case PatientActionTypes.CREATED_NEXT_OF_KIN:
    case ActionTypes.SAVED_CONTACT:
    case ActionTypes.CREATED_CONTACT:
    case ReferralActionTypes.CREATE_MANUAL_REFERRAL:
      return addItem(state, action.payload.patientId, action.payload.contact, "contacts", false);
    case PatientActionTypes.UPDATED_NEXT_OF_KIN:
    case ActionTypes.UPDATED_CONTACT:
      return updateItem(state, action.payload.patientId, action.payload.contact, "contacts");
    case ActionTypes.DELETED_CONTACT:
      return removeItem(state, action.payload.patientId, action.payload.contact, "contacts");
    case ActionTypes.SET_CURRENT_CONTACT:
      return {
        ...state,
        current: action.payload.contact,
      };
    default:
      return state;
  }
};

export const areContactsLoading = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loading"], false));

export const areContactsLoaded = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loaded"], false));

export const getContactsByPatientId = curry(({ localState }, patientId) => get(localState, ["all", patientId, "contacts"], []));

export const getContactById = curry(({ localState }, patientId, contactId) => {
  const contacts = get(localState, ["all", patientId, "contacts"], []);
  return find(contacts, x => x.id === contactId) || {};
});

export const getNextOfKinContact = curry(({ localState }, patientId) => {
  const contacts = get(localState, ["all", patientId, "contacts"], []);
  return !isEmpty(filter(contacts, x => x.relationshipType.value === "NextOfKin"));
});

export const getCurrentContact = curry(({ localState }) => get(localState, ["current"], null));

export const getErrorMessage = curry(({ localState }, patientId) => get(localState, ["all", patientId, "error"], null));
