import { get, map, take, unionBy, concat, find, filter, mapKeys } from "lodash";
import curryConnector from "utils/connectors";

export const USERS_STATE_KEY = "users";
export const USERS_PAGE_SIZE = 50;
export const USERS_PAGE_SIZE_OPTIONS = [5, 10, 25, 50];

const curry = fn => curryConnector(fn, USERS_STATE_KEY);

export const ActionTypes = {
  LOADING_USERS: "LOADING_USERS",
  ERROR_LOADING_USERS: "ERROR_LOADING_USERS",
  LOADED_USERS: "LOADED_USERS",

  LOADING_ROLES: "LOADING_ROLES",
  LOADED_ROLES: "LOADED_ROLES",
  ERROR_LOADING_ROLES: "ERROR_LOADING_ROLES",

  SET_CURRENT_USER: "SET_CURRENT_USER",
  LOADING_CURRENT_USER: "LOADING_CURRENT_USER",
  LOADED_CURRENT_USER: "LOADED_CURRENT_USER",
  ERROR_LOADING_CURRENT_USER: "ERROR_LOADING_CURRENT_USER",
  RESET_CURRENT_USER: "RESET_CURRENT_USER",

  SET_RECENT_SELECTED_USER: "SET_RECENT_SELECTED_USER",

  CREATED_USER: "CREATED_USER",
  SAVED_USER: "SAVED_USER",

  LOADED_USER: "LOADED_USER",
  LOADING_USER: "LOADING_USER",

  RESET_USERS_FILTERS: "RESET_USERS_FILTERS",

  SAVED_DEACTIVATE_USER: "SAVED_DEACTIVATE_USER",
  SAVED_ACTIVATE_USER: "SAVED_ACTIVATE_USER",

  IMPORTING_USER_VALUES: "IMPORTING_USER_VALUES",
  IMPORTED_USER_VALUES: "IMPORTED_USER_VALUES",
  ERROR_IMPORTING_USER_VALUES: "ERROR_IMPORTING_USER_VALUES",
};

const meta = {
  loading: false,
  loaded: false,
  error: null,
};

const INITIAL_STATE = {
  all: [],
  pages: {},
  pageInfo: { pageNumber: 1, pageSize: USERS_PAGE_SIZE, totalRecords: 0 },
  searchParams: { name: "", includeInactive: false },
  current: {},
  roles: {
    all: [],
    ...meta,
  },
  recentUsers: [],
  loadingUsers: true,
  meta: {
    current: { ...meta },
  },
};

const loadedUsers = (state, action) => {
  let allUsers = action.payload.users;
  const { currentUser } = action.payload;
  if (currentUser) {
    const isExisted = find(allUsers, x => x.userId === currentUser.userId);
    // add current user to the top of the list
    if (isExisted) {
      allUsers = concat(currentUser, filter(allUsers, x => x.userId !== currentUser.userId));
    } else {
      allUsers = concat(currentUser, allUsers);
    }
  }
  return {
    ...state,
    loadingUsers: false,
    all: mapKeys(allUsers, x => x.userId),
    pages: {
      ...state.pages,
      [action.payload.pageNumber]: { loading: false, loaded: true, error: false, ids: map(action.payload.users, x => x.userId) },
    },
    pageInfo: action.payload.pageInfo,
    searchParams: action.payload.searchParams,
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_ROLES:
      return {
        ...state,
        roles: { ...state.roles, loading: true },
      };
    case ActionTypes.ERROR_LOADING_ROLES:
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: false,
          error: action.payload.message,
        },
      };
    case ActionTypes.LOADED_ROLES:
      return {
        ...state,
        roles: {
          ...state.roles,
          all: action.payload.roles,
          loading: false,
          loaded: true,
        },
      };
    /** ALL USERS */
    case ActionTypes.LOADING_USERS:
      return {
        ...state,
        loadingUsers: true,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: true, error: false } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.ERROR_LOADING_USERS:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: false, error: action.payload.message } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.LOADED_USERS:
      return loadedUsers(state, action);
    /** CURRENT USER */
    case ActionTypes.LOADING_CURRENT_USER:
      return {
        ...state,
        meta: {
          ...state.meta,
          current: {
            ...state.meta.current,
            loading: true,
          },
        },
      };
    case ActionTypes.SET_CURRENT_USER:
    case ActionTypes.LOADED_CURRENT_USER:
      return {
        ...state,
        current: action.payload.user,
        meta: {
          ...state.meta,
          current: {
            ...state.meta.current,
            loading: false,
            loaded: true,
            error: null,
          },
        },
      };
    case ActionTypes.ERROR_LOADED_CURRENT_USER:
      return {
        ...state,
        meta: {
          ...state.meta,
          current: {
            loading: false,
            loaded: false,
            error: action.payload.message, // confirm correct? used to be action.error???
          },
        },
      };
    case ActionTypes.RESET_CURRENT_USER:
      return {
        ...state,
        current: INITIAL_STATE.current,
        meta: {
          ...state.meta,
          current: INITIAL_STATE.meta.current,
        },
      };
    /** SAVE OR ADD USER */
    case ActionTypes.SAVED_USER:
    case ActionTypes.CREATED_USER:
      return {
        ...state,
        current: action.payload.user,
        all: { ...state.all, [action.payload.user.userId]: action.payload.user },
      };

    /** TO BE REMOVED??? */
    case ActionTypes.SET_RECENT_SELECTED_USER:
      return {
        ...state,
        recentUsers: take(unionBy([action.payload.user], state.recentUsers, "value"), 5),
      };

    // TO BE REMOVED start
    case ActionTypes.LOADING_USER:
      return {
        ...state,
      };
    case ActionTypes.LOADED_USER:
      return {
        ...state,
        all: concat({ ...action.payload.user, value: action.payload.user.userId, label: action.payload.user.name }, state.all),
      };
    // TO BE REMOVED end
    /** USER LIST FILTER */
    case ActionTypes.RESET_USERS_FILTERS:
      return {
        ...state,
        searchParams: INITIAL_STATE.searchParams,
      };

    case ActionTypes.SAVED_DEACTIVATE_USER:
    case ActionTypes.SAVED_ACTIVATE_USER:
      return {
        ...state,
        current: { ...state.current, inactiveDateTimeUtc: action.payload?.inactiveDateTimeUtc || null },
        all: { ...state.all, [action.payload.userId]: { ...state.all[action.payload.userId], inactiveDateTimeUtc: action.payload?.inactiveDateTimeUtc || null } },
      };
    default:
      return state || INITIAL_STATE;
  }
};

export const areRolesLoading = curry(state => state.localState.roles.loading);

export const areRolesLoaded = curry(state => state.localState.roles.loaded);

export const areRolesError = curry(state => state.localState.roles.error);

export const getAllRoles = curry(state => state.localState.roles.all);

export const isPageLoading = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loading"], false);
});

export const isPageLoaded = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loaded"], false);
});

export const getErrorMessage = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "error"], null);
});

export const getUserSearchTerms = curry(({ localState }) => localState.searchParams);

export const getPageInfo = curry(({ localState }) => localState.pageInfo);

export const getCurrentUser = curry(state => state.localState.current);

export const getAllUsers = curry(({ localState }) => {
  const users = get(localState, ["all"], {});
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  const ids = get(localState.pages, [pageNumber, "ids"], []);
  return map(ids, x => find(users, y => y.userId === x));
});

export const isCurrentUserLoading = curry(state => state.localState.meta.current.loading === true);
