import React from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import { isEmpty } from "lodash";
import { NoteInput } from "components/inputs/text-input";
import LoadingState from "components/items/loading-state";
import { addReferralNote, editReferralNote, removeReferralNote } from "app/main/referrals/actions/referrals.actions";
import { validateRequired, validateMaxLength } from "utils/validators";
import Form from "components/form";

const noteLabel = "Note";
const validateNote = value => validateRequired(noteLabel)(value) || validateMaxLength(noteLabel, 2000)(value);
class EditReferralNoteForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = ({ referralNote }, { setErrors }) => {
    const newReferralNote = {
      ...referralNote,
      referralId: this.props.referralId,
    };

    const apiCall = !referralNote.referralNoteId ? this.props.addReferralNote : this.props.editReferralNote;

    apiCall(this.props.referralId, newReferralNote).then(responds => {
      if (responds.error !== true) {
        this.props.onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  handleRemove = ({ noteId, setErrors }) => {
    this.props.removeReferralNote(this.props.referralId, noteId).then(responds => {
      if (responds.error !== true) {
        this.props.onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  render() {
    const {
      loading,
      note,
      noteId,
      hasPermissionReferralNotesDelete,
      ...other
    } = this.props;

    if (loading) {
      return <LoadingState />;
    }

    const initialValues = { referralNote: { note, referralNoteId: noteId } };
    const allowRemove = hasPermissionReferralNotesDelete && !isEmpty(initialValues.referralNote.referralNoteId);

    return (
      <Form
        contentProps={{ ...other, removeLabel: "Delete" }}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        onRemove={allowRemove ? (formProps => this.handleRemove({ noteId, ...formProps })) : null}
        variant="filled"
        content={() => (
          <>
            <Field
              name="referralNote.note"
              component={NoteInput}
              label={noteLabel}
              validate={validateNote}
              autoFocus
            />
          </>
        )}
      />
    );
  }
}

export default connect(() => ({}), { addReferralNote, editReferralNote, removeReferralNote })(EditReferralNoteForm);
