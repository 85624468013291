import { ApiActions } from "middleware/call-api";
import moment from "moment";
import { ActionTypes,
  areEncountersLoading,
  areEncountersLoaded,
  getEncounterById } from "../reducers/encounters.reducers";

export const setCurrentEncounter = encounter => ({
  type: ActionTypes.LOADED_CURRENT_ENCOUNTER,
  payload: { encounter },
});

export const fetchPatientEncounters = patientId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/encounters`,
    bailout: state => areEncountersLoaded(state, patientId) || areEncountersLoading(state, patientId),
    entityType: "ENCOUNTERS",
    patientId,
  },
});

export const recordNewWelfareQuestionnaire = () => ({
  livesAlone: null,
  hasSupport: null,
  hasMedicationSupplies: null,
  hasPrescriptionHelp: null,
  hasEnoughFood: null,
  hasInternetAccess: null,
});

/** * Add Encountert */
export const recordNewEncounter = (patientId, participantUserId) => setCurrentEncounter({
  id: null,
  patientId,
  participantUserId,
  appointmentId: null,
  encounterType: null,
  reason: null,
  status: null,
  contactMethod: null,
  startDateTimeUtc: moment(),
  endDateTimeUtc: null,
  duration: null,
  consented: null,
  nextContactDate: null,
  nextContactAssessmentId: null,
  doNotContactAgain: null,
  details: null,
  enrolmentType: null,
  welfareQuestionnaire: recordNewWelfareQuestionnaire(),
});

export const recordEncounter = encounter => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${encounter.patientId}/encounters/new`,
    body: { encounter },
    entityType: "encounter",
    patientId: encounter.patientId,
  },
});

export const editExistingEncounter = (patientId, encounterId) => (dispatch, getState) => {
  let encounter = getEncounterById(getState(), patientId, encounterId);
  const endDate = encounter.endDateTimeUtc ? moment(encounter.endDateTimeUtc) : null;
  const duration = endDate ? parseInt(moment.duration(endDate.diff(encounter.startDateTimeUtc)).asMinutes(), 10) : null;
  encounter = { ...encounter, duration, participantUserId: { value: encounter.participantUserId, label: encounter.participantDisplayName } };
  dispatch(setCurrentEncounter(encounter));
};

export const editEncounter = encounter => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/patient/${encounter.patientId}/encounters/edit`,
    body: { encounter },
    entityType: "encounter",
    patientId: encounter.patientId,
  },
});

export const removeEncounter = (patientId, encounterId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/patient/${patientId}/encounters/remove`,
    body: { encounter: { id: encounterId, patientId } },
    entityType: "encounter",
    patientId,
    encounterId,
  },
});
