import React from "react";
import { withRouter } from "react-router-dom";

import DefaultPanelLayout from "components/items/default-panel-layout";
import InterventionList from "app/main/patients/interventions/components/intervention-list";
import InterventionListContainer from "app/main/patients/interventions/containers/intervention-list-container";
import { InterventionIcon } from "helpers/icon-finder";
import { HeaderAction } from "app/main/patients/components/patient-components";

const InterventionSummaryPanel = ({
  patientId,
  history,
  ...other
}) => (
  <InterventionListContainer
    numberOfItems={3}
    patientId={patientId}
    renderContent={({
      interventions,
      // actions
      onRecordIntervention,
      onEditIntervention,
      onRemoveIntervention,
      onResolveIntervention,
      onRenewIntervention,
      // states
      isLoading,
      isEmpty,
      isError,
    }) => (
      <DefaultPanelLayout
        isEmpty={isEmpty}
        error={isError}
        isLoading={isLoading}
        title="Interventions"
        icon={<InterventionIcon />}
        headerRightContent={<HeaderAction onClick={onRecordIntervention} isRelative label="Add Intervention" />}
        action={!isEmpty
         && {
           label: "View More",
           onClick: () => history.push(`/patients/${patientId}?tab=interventions`),
         }}
        emptyTitle="No Recorded Interventions"
        {...other}
      >
        <InterventionList
          interventions={interventions}
          onEditIntervention={onEditIntervention}
          onRemoveIntervention={onRemoveIntervention}
          onResolveIntervention={onResolveIntervention}
          onRenewIntervention={onRenewIntervention}
        />
      </DefaultPanelLayout>
    )}
  />
);

export default withRouter(InterventionSummaryPanel);
