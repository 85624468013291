import React from "react";
import { connect } from "react-redux";
import { Field } from "formik";

import { TextInput, NoteInput } from "components/inputs/text-input";
import CheckboxInput from "components/inputs/checkbox";
import SelectInput from "components/inputs/select-input";
import { getCurrentCondition } from "app/main/patients/reducers/conditions.reducers";
import { recordCondition, editCondition } from "app/main/patients/actions/conditions.actions";
import { validateRequired, validateDateIsAfter } from "utils/validators";
import { DatePicker } from "components/inputs";

import CalendarQuestionIcon from "mdi-react/CalendarQuestionIcon";
import CalendarEndIcon from "mdi-react/CalendarEndIcon";
import CalendarEditIcon from "mdi-react/CalendarEditIcon";

import Form from "components/form";
import DefaultItem from "components/items/default-item";
import { TODAY } from "utils/date";

const severityOptions = [
  { value: "NotSpecified", label: "Not Specified" },
  { value: "Mild", label: "Mild" },
  { value: "Moderate", label: "Moderate" },
  { value: "Severe", label: "Severe" },
];

const conditionStatus = [
  { value: "Acute", label: "Acute" },
  { value: "Chronic", label: "Chronic" },
  { value: "AtRisk", label: "At Risk" },
];

const conditionDescription = "Condition Description";
const details = "Condition Details";
const severity = "Severity";
const status = "Status Description";
const onsetDate = "Onset Date";
const reportedDate = "Reported Date";
const endDate = "End Date";

const validateDescription = validateRequired(conditionDescription);
const validateEndDate = validateDateIsAfter(endDate, onsetDate, ["condition", "onsetDate"]);

class RecordConditionForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = ({ condition }, { setErrors }) => {
    const newCondition = { ...condition };
    const apiCall = this.props.isEdit ? this.props.editCondition : this.props.recordCondition;
    apiCall(newCondition).then(responds => {
      if (responds.error !== true) {
        this.props.onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  render() {
    const { initialValues, ...other } = this.props;

    return (
      <Form
        contentProps={other}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        content={({ values }) => {
          const { condition: { isCurrent } } = values;
          return (
            <>
              <DefaultItem
                labelClassName="w-160 h-80"
                label="Condition Status"
                content={(
                  <Field
                    name="condition.isCurrent"
                    label="Current"
                    component={CheckboxInput}
                    onChange={(value, change) => {
                      if (value === false) {
                        change("condition.endDate", TODAY);
                      } else {
                        change("condition.endDate", null);
                      }
                    }}
                  />
                )}
              />
              <Field
                name="condition.condition"
                icon="mode_comment"
                label={conditionDescription}
                component={TextInput}
                validate={validateDescription}
              />
              <Field
                name="condition.severity"
                component={SelectInput}
                icon="report_condition"
                label={severity}
                options={severityOptions}
              />
              <Field
                name="condition.status"
                component={SelectInput}
                label={status}
                options={conditionStatus}
              />
              <Field
                name="condition.onsetDate"
                label={onsetDate}
                component={DatePicker}
                icon={<CalendarQuestionIcon />}
              />
              <Field
                name="condition.reportedDate"
                label={reportedDate}
                component={DatePicker}
                icon={<CalendarEditIcon />}
              />
              <Field
                name="condition.endDate"
                label={endDate}
                component={DatePicker}
                icon={<CalendarEndIcon />}
                validate={value => validateEndDate(value, values)}
                disabled={isCurrent}
              />
              <Field
                name="condition.notes"
                label={details}
                component={NoteInput}
              />
            </>
          );
        }}
      />
    );
  }
}

export default connect(state => {
  const condition = getCurrentCondition(state);

  return ({
    initialValues: { condition },
  });
}, {
  recordCondition,
  editCondition,
})(RecordConditionForm);
