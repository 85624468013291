import React from "react";
import { Typography } from "@material-ui/core";
import { get, isEmpty } from "lodash";

import normaliseDateTime from "app/main/patients/helpers/normalise-date-time";
import getEncounterTypeIcon from "app/main/patients/helpers/get-encounter-type-icon";
import { encounterSubjectDisplay } from "app/main/patients/helpers/encounter-subject-display";

const PatientNextContactLabel = ({
  nextEncounter,
  textColor,
}) => {
  if (isEmpty(nextEncounter)) {
    return (
      <Typography variant="caption" className="mt-8" style={{ color: textColor }}>No Contact</Typography>
    );
  }

  const nextEncounterScheduledDate = get(nextEncounter, ["startDateTimeUtc"], null);
  const scheduledDate = normaliseDateTime(nextEncounterScheduledDate);

  const textStyle = {
    fontSize: 12,
    color: textColor,
  };

  return (
    <div className="flex-row-container justify-between">
      {nextEncounterScheduledDate && (
        <div className="flex-row-container">
          <Typography className="font-bold mr-4" style={textStyle}>
            {scheduledDate.date}
          </Typography>
          <Typography style={textStyle}> at {scheduledDate.time}
          </Typography>
        </div>
      )}
      <div className="flex items-center">
        {getEncounterTypeIcon({ type: nextEncounter.encounterType, size: 20, color: textColor })}
        <div className="ml-4">
          <Typography style={textStyle}>{encounterSubjectDisplay(nextEncounter.encounterType, nextEncounter.reason)}</Typography>
        </div>
      </div>
    </div>
  );
};

export default PatientNextContactLabel;
