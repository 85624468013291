import React from "react";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";

import ScreenHeader from "components/items/screen-header";
import DefaultButton from "components/items/default-button";
import { deprecateOrgUnit, restoreOrgUnit } from "app/main/orgUnits/actions/orgUnit.actions";
import ConfirmationDialog from "components/items/confirmation-dialog";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import formatDate from "helpers/format-date";
import withPermissions from "permissions/withPermissions";
import LoadingState from "components/items/loading-state";

const OrgUnitDetailPageHeader = ({
  loading,
  orgUnit,
  formDisabled,
  onToggleFormStatus,
  hasPermissionOrgUnitUpdate,
  hasPermissionOrgUnitMaintainStatus,
}) => {
  const dispatch = useDispatch();

  if (loading) return <LoadingState />;

  const isActive = isEmpty(orgUnit?.deprecatedDate);

  const onClose = () => dispatch(closeDialog());

  const onUpdateOrgUnitStatus = () => dispatch(openDialog({
    disableBackdropClick: false,
    maxWidth: "xs",
    children: (
      <ConfirmationDialog
        type="warning"
        title={`${isActive ? "Deprecate" : "Restore"}`}
        content={`Are you sure you want to ${isActive ? "deprecate" : "restore"} ${orgUnit.name}?`}
        onConfirm={() => {
          const apiCall = isActive ? deprecateOrgUnit : restoreOrgUnit;

          dispatch(apiCall(orgUnit.id)).then(() => {
            onClose();
            onToggleFormStatus(true);
          });
        }}
        onCancel={onClose}
      />
    ),
  }));

  return (
    <ScreenHeader
      title={orgUnit?.name}
      subtitle={!isActive ? `Deprecated Date: ${formatDate(orgUnit?.deprecatedDate)}` : ""}
      renderActions={(
        <>
          {hasPermissionOrgUnitMaintainStatus && (
          <DefaultButton
            variant="outlined"
            color="inherit"
            label={isActive ? "Deprecate" : "Restore"}
            icon={isActive ? "cancel" : "undo"}
            onClick={onUpdateOrgUnitStatus}
          />
          )}
          {hasPermissionOrgUnitUpdate && (
          <DefaultButton
            label="Edit"
            variant="outlined"
            color="inherit"
            icon="edit"
            onClick={() => onToggleFormStatus(false)}
            disabled={!formDisabled}
          />
          )}
        </>
      )}
    />
  );
};

export default withPermissions("OrgUnitUpdate", "OrgUnitMaintainStatus")(OrgUnitDetailPageHeader);
