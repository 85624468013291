import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AppLayout from "components/layouts/app-layout";
import { getCurrentThemeValue } from "utils/get-environment-variables";
import { setCurrentTheme } from "app/store/actions/theme.actions";

import Auth from "./auth/Auth";
import history from "./browserHistory";

const themeName = getCurrentThemeValue() ?? null;
const favicon = document.getElementById("favicon");

const StartUp = () => {
  const dispatch = useDispatch();
  const themes = useSelector(state => state.theme.themes);

  useEffect(() => {
    if (themeName) {
      dispatch(setCurrentTheme(themeName));
      const icon = themes[themeName]?.logo?.favicon;
      if (icon) {
        favicon.href = `${icon}`;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Auth>
      <Router history={history}>
        <AppLayout />
      </Router>
    </Auth>
  );
};
export default StartUp;
