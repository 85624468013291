import { get, concat, find, orderBy, filter } from "lodash";
import curryConnector from "utils/connectors";

export const IMMUNISATIONS_STATE_KEY = "immunisations";

const curry = fn => curryConnector(fn, IMMUNISATIONS_STATE_KEY);

export const ActionTypes = {
  LOADING_IMMUNISATIONS: "LOADING_IMMUNISATIONS",
  LOADED_IMMUNISATIONS: "LOADED_IMMUNISATIONS",
  ERROR_LOADING_IMMUNISATIONS: "ERROR_LOADING_IMMUNISATIONS",

  LOADING_CURRENT_IMMUNISATION: "LOADING_CURRENT_IMMUNISATION",
  ERROR_LOADING_CURRENT_IMMUNISATION: "ERROR_LOADING_CURRENT_IMMUNISATION",
  LOADED_CURRENT_IMMUNISATION: "LOADED_CURRENT_IMMUNISATION",

  SAVED_IMMUNISATION: "SAVED_IMMUNISATION",
  CREATED_IMMUNISATION: "CREATED_IMMUNISATION",
  DELETED_IMMUNISATION: "DELETED_IMMUNISATION",
};

const INITIAL_STATE = {
  all: {},
  current: null,
};

const addOrUpdateImmunisation = (state, action) => {
  const { payload: { immunisation, patientId } } = action;
  let immunisations = get(state.all, [patientId, "immunisations"], []);
  immunisations = orderBy(concat(filter(immunisations, x => x.id !== immunisation.id), [immunisation]), [a => a.givenDate || ""], ["desc"]);

  return {
    ...state,
    current: immunisation,
    all: { ...state.all, [patientId]: { ...state.all[patientId], immunisations } },
  };
};

const removeImmunisation = (state, action) => {
  const { payload: { immunisation, patientId } } = action;
  let immunisations = get(state.all, [patientId, "immunisations"], []);
  immunisations = orderBy(filter(immunisations, x => x.id !== immunisation.id), [a => a.reportedDate || ""], ["desc"]);

  return {
    ...state,
    all: { ...state.all, [patientId]: { ...state.all[patientId], immunisations } },
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_IMMUNISATIONS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: true } },
      };
    case ActionTypes.ERROR_LOADING_IMMUNISATIONS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: false, error: action.payload.message } },
      };
    case ActionTypes.LOADED_IMMUNISATIONS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]:
            {
              ...state.all[action.payload.patientId],
              loading: false,
              immunisations: action.payload.immunisations,
            },
        },
      };
    case ActionTypes.LOADING_CURRENT_IMMUNISATION:
      return {
        ...state,
        current: {
          ...state.current,
          loading: true,
        },
      };
    case ActionTypes.ERROR_LOADING_CURRENT_IMMUNISATION:
      return {
        ...state,
        current: {
          ...state.current,
          error: action.error,
        },
      };
    case ActionTypes.LOADED_CURRENT_IMMUNISATION:
      return {
        ...state,
        current: action.payload.immunisation,
      };
    case ActionTypes.SAVED_IMMUNISATION:
    case ActionTypes.CREATED_IMMUNISATION:
      return addOrUpdateImmunisation(state, action);
    case ActionTypes.DELETED_IMMUNISATION:
      return removeImmunisation(state, action);
    case ActionTypes.SET_CURRENT_IMMUNISATION:
      return {
        ...state,
        current: action.payload.immunisation,
      };
    default:
      return state;
  }
};

export const areImmunisationsLoading = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loading"], false));

export const areImmunisationsLoaded = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loaded"], false));

export const immunisationsLoadingError = curry(({ localState }, patientId) => get(localState, ["all", patientId, "error"], null));

export const getImmunisationsByPatientId = curry(({ localState }, patientId) => get(localState, ["all", patientId, "immunisations"], []));

export const getImmunisationById = curry(({ localState }, patientId, immunisationId) => {
  const immunisations = get(localState, ["all", patientId, "immunisations"], []);
  return find(immunisations, x => x.id === immunisationId) || {};
});

export const getCurrentImmunisation = curry(({ localState }) => get(localState, ["current"], null));

export const isCurrentImmunisationLoading = curry(({ localState }) => get(localState, ["current", "loading"], false));

export const getErrorMessage = curry(({ localState }, patientId) => get(localState, ["all", patientId, "error"], null));
