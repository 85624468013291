import React from "react";
import { Grid, IconButton, Icon, AppBar, Toolbar, Button } from "@material-ui/core";
import { Formik } from "formik";

import DefaultButton from "components/items/default-button";

const FilterForm = ({
  initialValues,
  filters,
  onSubmit,
  onClose,
  onReset,
  showHeader = true,
  renderActions,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    render={props => (
      <>
        {showHeader && (
          <AppBar className="relative">
            <Toolbar>
              <Grid container justify="space-between" alignItems="center">
                <IconButton edge="start" color="inherit" onClick={() => onClose()}><Icon>clear</Icon></IconButton>
                <div>
                  {onReset && <Button color="inherit" onClick={() => { onReset(); onClose(); }}>Clear</Button>}
                  <Button type="submit" color="inherit" className="ml-8" onClick={() => { props.handleSubmit(); onClose(); }}>Apply</Button>
                </div>
              </Grid>
            </Toolbar>
          </AppBar>
        )}
        <div className="pt-24 overflow-y-auto flex flex-col flex-1 justify-between">
          {filters && filters(props)}
          <div className="mt-16 py-16 flex justify-center">
            {renderActions ? renderActions({ onReset, onClose, ...props }) : (
              <DefaultButton type="submit" variant="outlined" className="w-320" onClick={() => { props.handleSubmit(); onClose(); }} label="Apply" size="medium" />
            )}
          </div>
        </div>
      </>
    )}
  />
);

export default FilterForm;
