import React from "react";
import { RecordOutlineIcon, PhoneIcon, HomeIcon, InitialAssessmentIcon, FollowUpAssessmentIcon, CircleIconW } from "helpers/icon-finder";

export default function getEncounterTypeIcon({ type, ...other }) {
  switch (type) {
    case "Other":
      return <RecordOutlineIcon {...other} />;
    case "ConsentCall":
      return <PhoneIcon {...other} />;
    case "ConsentVisit":
      return <HomeIcon {...other} />;
    case "InitialAssessment":
      return <InitialAssessmentIcon {...other} />;
    case "FollowUpAssessment":
      return <FollowUpAssessmentIcon {...other} />;
    case "WelfareCheck":
      return <CircleIconW {...other} />;
    default:
      return null;
  }
}
