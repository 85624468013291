import React from "react";
import { Icon } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

import ReferralPatientDetails from "app/main/referrals/components/referral-patient-details";

const useStyles = makeStyles({
  root: {
    boxShadow: "4px 4px 11px 6px rgb(0 0 0 / 10%)",
  },
});

const PatientSummaryCard = ({
  patient,
}) => {
  const classes = useStyles();
  return (
    <Alert
      icon={<Icon>account_circle</Icon>}
      color="info"
      classes={{ icon: "items-center", root: classes.root }}
    >
      <ReferralPatientDetails
        patient={patient}
        identifierProps={{ showIcon: false, disabled: true }}
        dobProps={{ className: "font-normal" }}
      />
    </Alert>
  );
};

export default PatientSummaryCard;
