import React from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import { map, join, isArray, startCase } from "lodash";

import { validateRequired, validateMaxLength } from "utils/validators";
import { TextInput, AutoComplete } from "components/inputs";
import { getAllRoles } from "app/auth/store/reducers/system-configuration";

import Form from "components/form";
import { addNewSpecialtyProfile, updateExistingSpecialtyProfile } from "../actions/specialtyProfiles.actions";
import { getCurrentSpecialtyProfile } from "../reducers/specialtyProfiles.reducers";
import SubSpecialtyInput from "./subSpecialty-input";

const name = "Name";
const availableRolesLabel = "Available Roles";
const autoAssignRoleLabel = "Auto Assign Role";
const healthLinkServiceCodeLabel = "Health Link Service Code";

const validateName = value => validateRequired(name)(value) || validateMaxLength(name, 100)(value);
const validateServiceCode = validateMaxLength(healthLinkServiceCodeLabel, 50);

class SpecialtyProfileForm extends React.PureComponent {
  handleSubmit = ({ specialtyProfile }, { setErrors }) => {
    const apiCall = this.props.isEdit ? this.props.updateExistingSpecialtyProfile : this.props.addNewSpecialtyProfile;

    const data = {
      ...specialtyProfile,
      currentSpecialtyRoles: isArray(specialtyProfile.currentSpecialtyRoles) ? join(specialtyProfile.currentSpecialtyRoles, ",") : specialtyProfile.currentSpecialtyRoles,
      autoAssignRoleId: specialtyProfile.autoAssignRoleId?.value ?? specialtyProfile.autoAssignRoleId,
      // compare between the value and label, it's an new added sub specialty if they are equal
      subSpecialties: specialtyProfile.subSpecialties ? map(specialtyProfile.subSpecialties, x => ({ value: x.value === x.label ? null : x.value, label: x.label })) : null,
    };

    apiCall(data).then(responds => {
      if (responds.error !== true) {
        this.props.onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  render() {
    const {
      roleOptions,
      ...other
    } = this.props;

    return (
      <Form
        onSubmit={this.handleSubmit}
        isModal={false}
        {...other}
        content={() => (
          <div>
            <Field
              name="specialtyProfile.name"
              label={name}
              type="text"
              component={TextInput}
              validate={validateName}
            />
            <Field
              name="specialtyProfile.currentSpecialtyRoles"
              label={availableRolesLabel}
              component={AutoComplete}
              options={roleOptions}
              multiple
            />
            <Field
              name="specialtyProfile.autoAssignRoleId"
              label={autoAssignRoleLabel}
              component={AutoComplete}
              options={roleOptions}
            />
            <Field
              name="specialtyProfile.serviceCode"
              component={TextInput}
              validate={validateServiceCode}
              label={healthLinkServiceCodeLabel}
            />
            <Field
              name="specialtyProfile.subSpecialties"
              component={SubSpecialtyInput}
              disabled={other.disabled}
            />
          </div>
        )}
      />
    );
  }
}

export default (connect((state, ownProps) => {
  const currentSpecialtyProfile = ownProps.specialtyProfile || getCurrentSpecialtyProfile(state);
  const allRoles = getAllRoles(state);
  const roleOptions = map(allRoles, role => ({ value: role.roleId, label: role.roleDescription ?? startCase(role.roleName) }));

  return {
    initialValues: { specialtyProfile: currentSpecialtyProfile },
    roleOptions,
  };
}, { addNewSpecialtyProfile, updateExistingSpecialtyProfile })(SpecialtyProfileForm));
