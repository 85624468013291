import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { getCurrentReferralSourceDocumentFile, isCurrentReferralSourceDocumentFileLoading, errorLoadingCurrentReferralSourceDocumentFile } from "app/main/referrals/reducers/referrals.reducers";
import { getReferralSourceDocumentFilePreviewData } from "app/main/referrals/actions/referrals.actions";
import PreviewContent from "components/items/preview-content";
import TabContent from "components/items/tab-content";

const ReferralSourceDocumentPanel = ({
  referral,
  pdfWidth,
}) => {
  const sourceDocumentId = referral.referralSourceDocument?.id;
  const sourceDocumentFileId = referral.referralSourceDocument?.fileId;

  const dispatch = useDispatch();
  const sourceDocumentFile = useSelector(getCurrentReferralSourceDocumentFile);
  const isSourceDocumentFileLoading = useSelector(isCurrentReferralSourceDocumentFileLoading);
  const errorLoadingSourceDocument = useSelector(errorLoadingCurrentReferralSourceDocumentFile);

  useEffect(() => {
    if (sourceDocumentId && sourceDocumentFileId) {
      dispatch(getReferralSourceDocumentFilePreviewData(sourceDocumentId, sourceDocumentFileId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceDocumentId && sourceDocumentFileId]);

  return (
    <TabContent
      withGutters
      loading={isSourceDocumentFileLoading}
      empty={isEmpty(sourceDocumentFile)}
      emptyTitle="No Source Document"
      error={errorLoadingSourceDocument}
    >
      <PreviewContent
        fileData={sourceDocumentFile?.base64File || sourceDocumentFile?.htmlPreview}
        mimeType={sourceDocumentFile?.mimeType}
        pdfProps={{ width: pdfWidth }}
        hasPreview={sourceDocumentFile?.hasPreview}
      />
    </TabContent>
  );
};

export default ReferralSourceDocumentPanel;
