import React from "react";
import { Checkbox as NativeCheckbox, FormControlLabel } from "@material-ui/core";
import { get, isEmpty } from "lodash";

import FormContext from "components/FormContext";
import BaseFieldInput from "components/inputs/components/base-input-component";

export const Checkbox = ({
  checked,
  onChange,
  disabled,
  label,
  helperText,
  ...other
}) => (
  <FormContext.Consumer>
    {({ formDisabled }) => (
      <BaseFieldInput
        showHelperText={!isEmpty(helperText)}
        helperText={helperText}
        isCheckBox
        {...other}
      >
        <FormControlLabel
          disabled={formDisabled || disabled}
          control={(
            <NativeCheckbox
              checked={checked}
              onChange={onChange}
              className="checkbox-control"
            />
          )}
          label={label}
        />
      </BaseFieldInput>
    )}
  </FormContext.Consumer>
);

const CheckboxInput = ({
  field: { name, value },
  form: { errors, touched, setFieldValue },
  onChange,
  showHelperText,
  ...other
}) => {
  const inputValue = value === "true" ? true : value;
  const error = get(errors, name, null);
  const isTouched = get(touched, name, false);

  return (
    <Checkbox
      checked={inputValue || false}
      error={!isEmpty(error)}
      helperText={showHelperText && isTouched && error}
      onChange={val => {
        setFieldValue(name, val.target.checked);
        if (onChange) {
          onChange(val.target.checked, setFieldValue);
        }
      }}
      {...other}
    />
  );
};

export default CheckboxInput;
