import React from "react";

import { PanelHeaderAction } from "components/items/chat-panel";
import EncounterSummaryListContainer from "app/main/patients/encounters/containers/encounter-summary-list-container";
import EncounterFab from "app/main/patients/encounters/components/encounter-fab";
import AppointmentList from "app/main/patients/appointments/components/appointment-list";
import EncounterList from "app/main/patients/encounters/components/encounter-list";
import DefaultPanelLayout from "components/items/default-panel-layout";

export default function PatientEncounterPage({ patientId }) {
  return (
    <EncounterSummaryListContainer
      patientId={patientId}
      renderContent={({
        encounter: {
          encounters,
          // actions
          onRecordEncounter,
          onEditEncounter,
          onRemoveEncounter,
          // states
          isEmpty: isEncountersEmpty,
          isError: isEncountersError,
          isLoading: isEncountersLoading,
        },
        appointment: {
          appointments,
          // actions
          onScheduleAppointment,
          onCancelAppointment,
          onEditAppointment,
          onRecordAppointmentEncounter,
          onRemoveAppointment,
          onCreateAssessment,
          // states
          isEmpty: isAppointmentsEmpty,
          isError: isAppointmentsError,
          isLoading: isAppointmentsLoading,
        },
      }) => (
        <>
          <PanelHeaderAction>
            <EncounterFab
              patientId={patientId}
              onScheduleAppointment={onScheduleAppointment}
              onRecordEncounter={onRecordEncounter}
            />
          </PanelHeaderAction>
          <DefaultPanelLayout
            title="Upcoming Encounters"
            isLoading={isAppointmentsLoading}
            isEmpty={isAppointmentsEmpty}
            isError={isAppointmentsError}
            emptyTitle="No Upcoming Encounters"
          >
            <AppointmentList
              appointments={appointments}
              onRecordAppointmentEncounter={onRecordAppointmentEncounter}
              onEditAppointment={onEditAppointment}
              onCancelAppointment={onCancelAppointment}
              onRemoveAppointment={onRemoveAppointment}
              onCreateAssessment={onCreateAssessment}
            />
          </DefaultPanelLayout>
          <DefaultPanelLayout
            title="Past Encounters"
            isLoading={isEncountersLoading}
            isEmpty={isEncountersEmpty}
            isError={isEncountersError}
            emptyTitle="No Past Encounters"
          >
            <EncounterList
              encounters={encounters}
              onEditEncounter={onEditEncounter}
              onRemoveEncounter={onRemoveEncounter}
              onCreateAssessment={onCreateAssessment}
            />
          </DefaultPanelLayout>
        </>
      )}
    />
  );
}
