import React from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { get, find } from "lodash";
import { Field } from "formik";
import { Alert } from "@material-ui/lab";

import { setRecentSelectedUser } from "app/main/users/actions/users.actions";
import { saveReferralAssignedUser } from "app/main/referrals/actions/referrals.actions";
import Form from "components/form";
import { TextInput } from "components/inputs/text-input";
import AssignToUserSelector from "app/main/referrals/components/assign-to-user-selector";
import formatAssignmentOptions from "app/main/referrals/helpers/format-assignment-options";
import { getReferralWorklistSettings, getPrimaryPatientIdentifierSettings } from "app/auth/store/reducers/system-configuration";
import withPermissions from "permissions/withPermissions";
import "utils/yup-validation";

const ReferralAssignUserForm = ({
  referral,
  assignmentTypes,
  loading,
  roles,
  hasPermissionPatientsIdentifiersUpdate,
  onSucceed,
  ...other
}) => {
  const dispatch = useDispatch();

  const settings = useSelector(getReferralWorklistSettings);
  const primaryPatientIdentifierSettings = useSelector(getPrimaryPatientIdentifierSettings);
  const showIdentifier = settings?.requirePrimaryIdentifierOnAssignment ?? false;
  const max = primaryPatientIdentifierSettings?.maxLength;
  const min = primaryPatientIdentifierSettings?.minLength;
  const primaryPatientIdentifier = primaryPatientIdentifierSettings?.type;
  const numericIdentifiersOnly = primaryPatientIdentifierSettings?.numericIdentifiersOnly;

  const hasPrimaryPatientIdentifier = find(referral.patient.patientIdentifiers, x => x.patientIdentifierTypeCode === primaryPatientIdentifier);
  const showIdentifierField = showIdentifier && hasPermissionPatientsIdentifiersUpdate && !hasPrimaryPatientIdentifier;

  const assignToLabel = "Assign to";
  const identifierLabel = `${primaryPatientIdentifier} Identifier`;

  const initialValues = {
    assignReferral: {
      identifier: null,
      assignTo: referral.assignedToId
        ? formatAssignmentOptions(referral)
        : null,
      showIdentifierField,
    },
  };

  const schema = Yup.object().shape({
    assignReferral: Yup.object().shape({
      showIdentifierField: Yup.bool(),
      identifier: Yup.string()
        .when("showIdentifierField", {
          is: true,
          then: Yup.string()
            .trim().required(`${primaryPatientIdentifier} is required`)
            .validateIdentifier(identifierLabel, { min, max, numberOnly: numericIdentifiersOnly })
            .nullable(),
        })
        .nullable(),
      assignTo: Yup.string()
        .required(`${assignToLabel} is required`)
        .nullable(),
    }),
  });

  const getConvertedAssigningReferral = assignReferral => ({
    referralId: referral.id,
    assignmentType: get(assignReferral.assignTo, "assignmentType"),
    assignedToUserId: get(assignReferral.assignTo, "assignedToUserId"),
    assignedToSpecialtyId: get(assignReferral.assignTo, "assignedToSpecialtyId"),
    assignedToRoleId: get(assignReferral.assignTo, "assignedToRoleId"),
    value: get(assignReferral.assignTo, "value"),
    identifier: assignReferral.identifier,
  });

  const handleSubmit = ({ assignReferral }, { setErrors, setSubmitting }) => {
    const convertedAssigningReferral = getConvertedAssigningReferral(assignReferral);

    dispatch(saveReferralAssignedUser(convertedAssigningReferral)).then(responds => {
      setSubmitting(false);
      if (responds.error !== true) {
        if (convertedAssigningReferral.assignmentType === "User") {
          dispatch(setRecentSelectedUser(convertedAssigningReferral.value)); // are you use this feature?
        }
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  return (
    <Form
      validationSchema={schema}
      contentProps={other}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      variant="filled"
      content={({ values }) => {
        const showPermissionAlert = showIdentifierField && !hasPermissionPatientsIdentifiersUpdate && !values.assignReferral.identifier;
        return (
          <>
            {showPermissionAlert && (
              <Alert severity="error">
                {`Patient requires a ${primaryPatientIdentifier} before assignment. Please contact a system administrator`}
              </Alert>
            )}
            {showIdentifierField && (
              <Field
                name="assignReferral.identifier"
                label={identifierLabel}
                component={TextInput}
                icon="message"
              />
            )}
            <Field
              name="assignReferral.assignTo"
              label={assignToLabel}
              component={AssignToUserSelector}
              isAssignmentField
            />
          </>
        );
      }}
    />
  );
};

export default withPermissions("PatientsIdentifiersUpdate")(ReferralAssignUserForm);
