/* eslint-disable no-console */
// eslint-disable-next-line no-unused-vars
import { LogLevel, Configuration, PublicClientApplication } from "@azure/msal-browser";

// See https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
// for instructions for msal-react usage.

// Note: The redirect URLs in Azure AAD MUST use the SPA format, not Web.

const tenant = process.env.REACT_APP_MSAL_TENANTID;
const instanceUri = process.env.REACT_APP_MSAL_INSTANCE;

// MSAL configuration
/** @type {Configuration} */
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENTID,
    authority: `${instanceUri}/${tenant}/`,
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    knownAuthorities: [instanceUri],
  },
  cache: {
    cacheLocation: "sessionStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
        }
      },
      piiLoggingEnabled: false,
      logLevel: LogLevel.Warning,
    },
  },
};

export const msalScopes = [process.env.REACT_APP_MSAL_SCOPE];

const createMsalInstance = config => {
  const instance = new PublicClientApplication(config);
  // Default to using the first account if no account is active on page load
  if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    instance.setActiveAccount(instance.getAllAccounts()[0]);
  }
  return instance;
};

export const msalInstance = createMsalInstance(msalConfig);

export const getAccessToken = async () => {
  const account = msalInstance.getActiveAccount() ?? msalInstance.getAllAccounts()[0];
  const authResult = await msalInstance.acquireTokenSilent({
    scopes: msalScopes,
    account,
  });
  return authResult.accessToken;
};
