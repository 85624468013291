import { ApiActions } from "middleware/call-api";
import { get } from "lodash";
import { ActionTypes,
  areContactsLoading,
  areContactsLoaded,
  getContactById } from "../reducers/contacts.reducers";

export const setCurrentContact = contact => ({
  type: ActionTypes.LOADED_CURRENT_CONTACT,
  payload: { contact },
});

export const fetchPatientContacts = patientId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/contacts`,
    bailout: state => areContactsLoaded(state, patientId) || areContactsLoading(state, patientId),
    entityType: "contacts",
    patientId,
  },
});

export const fetchPatientNOK = patientId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/contacts/nextOfKin`,
    types: [ActionTypes.LOADING_CURRENT_CONTACT, ActionTypes.LOADED_CURRENT_CONTACT, ActionTypes.ERROR_LOADING_CURRENT_CONTACT],
    patientId,
  },
});

export const fetchContactsById = (patientId, contactId) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/contacts/${contactId}`,
    types: [ActionTypes.LOADING_CURRENT_CONTACT, ActionTypes.LOADED_CURRENT_CONTACT, ActionTypes.ERROR_LOADING_CURRENT_CONTACT],
    patientId,
    contactId,
  },
});

/** * Add Contact */
export const recordNewContact = () => setCurrentContact({
  id: null,
  givenName: null,
  familyName: null,
  isEmergencyContact: false,
  contact: null,
  alternativeContact: null,
  relationshipType: null,
  relationshipDescription: null,
});

export const recordOrUpdateContact = (patientId, contact) => async dispatch => {
  const CONTACT_ACTION = get(contact, "id", false)
    ? ["UPDATING_CONTACT", ActionTypes.UPDATED_CONTACT, "ERROR_UPDATING_CONTACT"]
    : ["CREATING_CONTACT", ActionTypes.CREATED_CONTACT, "ERROR_CREATING_CONTACT"];

  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/patient/${patientId}/contacts/record`,
      body: { contact },
      types: CONTACT_ACTION,
      patientId,
    },
  });
};

export const editExistingContact = (patientId, contactId) => (dispatch, getState) => {
  const contact = getContactById(getState(), patientId, contactId);
  dispatch(setCurrentContact(contact));
};

export const removeContact = (patientId, id) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/patient/${patientId}/contacts/remove`,
    body: { contact: { id, patientId } },
    entityType: "contact",
    patientId,
    id,
  },
});
