import React from "react";
import { map, isEmpty } from "lodash";
import { connect } from "react-redux";
import { getActivityByTypeAndId } from "app/main/referrals/reducers/referrals.reducers";
import ActivityLog from "components/items/activity-log";

import ActionTypeItem from "./action-type-item";
import NoteItem from "./note-item";
import CorrespondenceItem from "./correspondence-item";
import AssignmentItem from "./assignment-item";
import TaskItem from "./task-item";

import Header from "./header";

function ReferralActivityLog({ mappedActivities, referral }) {
  return (
    <ActivityLog>
      <Header referral={referral} />
      {map(mappedActivities, activity => {
        if (activity && activity.detail) {
          switch (activity.type) {
            case "action":
              return <ActionTypeItem key={activity.detail.id} activity={activity.detail} />;
            case "note":
              return <NoteItem key={activity.detail.id} activity={activity.detail} canEdit={referral.isActive} />;
            case "assignment":
              return <AssignmentItem key={activity.detail.id} activity={activity.detail} />;
            case "correspondence":
              return <CorrespondenceItem key={activity.detail.id} activity={activity.detail} />;
            case "task":
              return <TaskItem key={activity.detail.id} activity={activity.detail} canEdit={referral.isActive} canDelete={referral.isActive} />;
            default:
              return null;
          }
        }
        return null;
      })}
    </ActivityLog>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { activities, referralId } = ownProps;
  let mappedActivities = null;

  if (!isEmpty(activities)) {
    mappedActivities = map(activities, activity => (activity.type ? getActivityByTypeAndId(state, activity.type, activity.id) : activity));
  }

  return {
    mappedActivities,
    referralId,
  };
};

export default (connect(
  mapStateToProps,
  {},
)(ReferralActivityLog));
