import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { find } from "lodash";
import HomePhoneIcon from "mdi-react/PhoneClassicIcon";
import SmartCardOutlineIcon from "mdi-react/SmartCardOutlineIcon";

import { validateRequired, validateEmailFormat, validatePhoneFormat, validatePostcodeFormat, validateDateIsAfter, validateMedicareNumber, validateMaxLength, validatePatientIdentifier } from "utils/validators";
import { normalizePatient, getCurrentPatient, isCurrentPatientLoading } from "app/main/patients/reducers/patients.reducers";
import { recordNewPatient, updateExistingPatient } from "app/main/patients/actions/patients.actions";
import { TextInput, SelectInput, DatePicker, CheckboxInput } from "components/inputs";
import LoadingState from "components/items/loading-state";
import Form from "components/form";
import { getSexOptions, getPatientIdentifierTypeOptions, getPrimaryPatientIdentifierSettings } from "app/auth/store/reducers/system-configuration";

const preferredName = "Preferred Name";
const givenName = "Given Name";
const middleName = "Middle Name";
const familyName = "Family Name";
const birthDate = "Birth Date";
const deathDate = "Death Date";
const sex = "Sex";
const medicareCard = "Medicare Card";
const medicareCardExpiryDate = "Expiry Date";

const phoneHome = "Home Phone";
const phoneMobile = "Mobile Phone";
const email = "Email";
const street = "Street";
const suburb = "City/Suburb";
const stateLabel = "State";
const postcode = "Postcode";
const title = "Title";
const phoneWork = " Work Phone";

const DVACard = "DVA Card";
const DVACardExpiryDate = "DVA Card Expiry Date";

const validatePreferredName = value => validateMaxLength(preferredName, 100)(value);
const validateGivenName = value => validateRequired(givenName)(value) || validateMaxLength(givenName, 100)(value);
const validateFamilyName = value => validateRequired(familyName)(value) || validateMaxLength(familyName, 100)(value);
const validateBirthDate = validateRequired(birthDate);
const validateDeathDate = validateDateIsAfter(deathDate, birthDate, ["patient", "birthDate"]);
const validateSex = validateRequired(sex);
const validateMedicareCard = validateMedicareNumber(medicareCard);
const validateEmail = validateEmailFormat(email);
const validatePhoneHome = validatePhoneFormat(phoneHome);
const validatePhoneMobile = validatePhoneFormat(phoneMobile);
const validatePostcode = validatePostcodeFormat(postcode);
const validatePhoneWork = validatePhoneFormat(phoneWork);
const validateDVACard = validatePatientIdentifier(DVACard, { min: 8, max: 9, numberOnly: false });

const PatientFormContainer = ({
  loading,
  isModal = false,
  handleSubmit,
  onCancel,
  onSucceed,
  disabled,
  isEdit,
  initialValues,
  sexOptions,
  classes,
  renderContent,
  ...other
}) => {
  const dispatch = useDispatch();
  const typeOptions = useSelector(state => getPatientIdentifierTypeOptions(state, true));

  const primaryPatientIdentifierSettings = useSelector(getPrimaryPatientIdentifierSettings);
  const primaryPatientIdentifier = primaryPatientIdentifierSettings?.type;
  const primaryPatientIdentifierMinLength = primaryPatientIdentifierSettings?.minLength;
  const primaryPatientIdentifierMaxLength = primaryPatientIdentifierSettings?.maxLength;
  const primaryPatientIdentifierLabel = find(typeOptions, x => x.value === primaryPatientIdentifier)?.label ?? "Identifier";
  const numericIdentifiersOnly = primaryPatientIdentifierSettings?.numericIdentifiersOnly;

  if (loading) {
    return <LoadingState />;
  }

  const onSubmit = ({ patient }, { setErrors, setSubmitting }) => {
    const newPatient = normalizePatient(patient);
    const apiCall = isEdit ? updateExistingPatient : recordNewPatient;

    dispatch(apiCall(newPatient)).then(responds => {
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    }).finally(() => setSubmitting(false));
  };

  const primaryIdentifierSection = () => (
    <div className="flex">
      <Field
        name="patient.primaryPatientIdentifier"
        component={TextInput}
        icon={<SmartCardOutlineIcon />}
        label={primaryPatientIdentifierLabel}
        className="flex-1"
        required
        validate={value => validatePatientIdentifier(primaryPatientIdentifierLabel,
          { min: primaryPatientIdentifierMinLength, max: primaryPatientIdentifierMaxLength, numberOnly: numericIdentifiersOnly, required: true })(value)}
      />
      <div className="flex-1" />
    </div>
  );

  const personalSection = ({ values }) => (
    <>
      <div className="flex flex-col sm:flex-row">
        <Field
          name="patient.title"
          label={title}
          component={TextInput}
          icon="person"
        />
        <Field
          name="patient.givenName"
          label={givenName}
          component={TextInput}
          validate={validateGivenName}
          required
        />
        <Field
          name="patient.middleName"
          label={middleName}
          component={TextInput}
        />
        <Field
          name="patient.familyName"
          label={familyName}
          component={TextInput}
          validate={validateFamilyName}
          required
        />
      </div>
      <div className="flex flex-col sm:flex-row">
        <Field
          name="patient.preferredName"
          label={preferredName}
          component={TextInput}
          validate={validatePreferredName}
          icon="person"
        />
        <Field
          name="patient.sex"
          component={SelectInput}
          label={sex}
          validate={validateSex}
          options={sexOptions}
          required
        />
      </div>
      <div className="flex flex-col sm:flex-row">
        <div className="flex-1">
          <Field
            name="patient.birthDate"
            label={birthDate}
            component={DatePicker}
            icon="cake"
            isPast
            validate={validateBirthDate}
            required
          />
        </div>
        <div className="flex items-center">
          {!disabled && (
            <Field
              name="patient.isDead"
              label="Deceased "
              component={CheckboxInput}
              onChange={(value, change) => {
                if (!value) {
                  change("patient.deathDate", null);
                }
              }}
            />
          )}
        </div>
        <div className="flex-1">
          {(values?.patient.isDead === true) && (
            <Field
              name="patient.deathDate"
              label={deathDate}
              component={DatePicker}
              icon=" "
              isPast
              validate={value => validateDeathDate(value, values)}
            />
          )}
        </div>
      </div>
    </>
  );

  const healthSection = () => (
    <>
      <div className="flex flex-col sm:flex-row">
        <Field
          name="patient.medicareCard"
          label={medicareCard}
          component={TextInput}
          validate={validateMedicareCard}
          icon="payment"
        />
        <Field
          name="patient.medicareExpiryDate"
          label={medicareCardExpiryDate}
          component={DatePicker}
        />
      </div>
      <div className="flex flex-col sm:flex-row">
        <Field
          name="patient.dva"
          label={DVACard}
          component={TextInput}
          icon="payment"
          validate={validateDVACard}
        />
        <Field
          name="patient.dvaExpiryDate"
          label={DVACardExpiryDate}
          component={DatePicker}
        />
      </div>
    </>
  );

  const contactSection = () => (
    <>
      <div className="flex flex-col sm:flex-row">
        <Field
          name="patient.contactDetails.phoneHome"
          label={phoneHome}
          component={TextInput}
          validate={validatePhoneHome}
          icon={<HomePhoneIcon />}
        />
        <Field
          name="patient.contactDetails.phoneMobile"
          label={phoneMobile}
          component={TextInput}
          validate={validatePhoneMobile}
          icon="smartphone"
        />
        <Field
          name="patient.contactDetails.phoneWork"
          label={phoneWork}
          component={TextInput}
          validate={validatePhoneWork}
          icon="phone"
        />
      </div>
      <div className="flex flex-col sm:flex-row">
        <Field
          name="patient.contactDetails.email"
          label={email}
          component={TextInput}
          validate={validateEmail}
          icon="email"
        />
        <div className="flex-1" />
        <div className="flex-1" />
      </div>
    </>
  );

  const addressSection = () => (
    <>
      <Field
        name="patient.address.street"
        label={street}
        component={TextInput}
        icon="location_on"
      />
      <div className="flex flex-col sm:flex-row">
        <Field
          name="patient.address.suburb"
          label={suburb}
          component={TextInput}
        />
        <Field
          name="patient.address.state"
          label={stateLabel}
          component={TextInput}
        />
        <Field
          name="patient.address.postcode"
          label={postcode}
          component={TextInput}
          validate={validatePostcode}
        />
      </div>
    </>
  );

  const nokSection = () => (
    <div className="flex flex-col sm:flex-row">
      <Field
        name="patient.nextOfKin.givenName"
        component={TextInput}
        label="Given Name"
        icon="people"
      />
      <Field
        name="patient.nextOfKin.familyName"
        component={TextInput}
        label="Family Name"
      />
      <Field
        name="patient.nextOfKin.contact"
        component={TextInput}
        label="Contact"
        icon="phone"
      />
    </div>
  );

  return (
    <Form
      isModal={isModal}
      onSubmit={handleSubmit ?? onSubmit}
      initialValues={initialValues}
      onCancel={onCancel}
      disabled={disabled}
      classes={classes}
      contentProps={{ ...other }}
      content={props => renderContent({
        ...props,
        personalSection,
        healthSection,
        contactSection,
        addressSection,
        nokSection,
        primaryIdentifierSection,
      })}
    />
  );
};

export default connect((state, ownProps) => {
  const patient = ownProps.patient || getCurrentPatient(state);
  const sexOptions = getSexOptions(state);

  let medicare = null;
  let dva = null;

  if (patient && patient.patientCards) {
    medicare = find(patient.patientCards, x => x.patientCardTypeCode === "MC");
    dva = find(patient.patientCards, x => x.patientCardTypeCode === "DVA");
  }

  return {
    loading: ownProps.loading || isCurrentPatientLoading(state),
    initialValues: {
      patient: {
        ...patient,
        givenName: ownProps.givenName ?? patient.givenName,
        familyName: ownProps.familyName ?? patient.familyName,
        sex: patient.sex ? find(sexOptions, x => x.label === patient.sex)?.value : patient.sex,
        primaryPatientIdentifier: null,
        medicareCard: medicare?.patientCard,
        medicareExpiryDate: medicare?.expiryDate,
        dva: dva?.patientCard,
        dvaExpiryDate: dva?.expiryDate,
      },
    },
    sexOptions,
  };
}, {})(PatientFormContainer);
