import React from "react";
import { Field } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import moment from "moment";

import Form from "components/form";
import { TextInput, NoteInput } from "components/inputs/text-input";
import { DatePicker } from "components/inputs";
import { onSaveOrUpdateExpiredPrescriptionPresentation } from "app/main/applications/actions/applications.actions";
import { getUser } from "app/auth/store/reducers/user.reducer";

const presentationDate = "Presentation Date";
const pharmacist = "Pharmacist";
const notes = "Notes";

const schema = Yup.object().shape({
  prescription: Yup.object().shape({
    presentationDate: Yup.date()
      .required(`${presentationDate} is required`).nullable(),
    pharmacistName: Yup.string()
      .max(128, "Too Long!")
      .trim().required(`${pharmacist} is required`),
    notes: Yup.string()
      .max(4000, "Too Long")
      .nullable(),
  }),
});

class ExpiredPrescriptionForm extends React.Component {
  handleSubmit = ({ prescription }, { setErrors }) => {
    const { applicationId, orgUnitId } = this.props;

    this.props.onSaveOrUpdateExpiredPrescriptionPresentation(applicationId, orgUnitId, prescription)
      .then(response => {
        if (response.error !== true) {
          this.props.closeDialog();
        } else {
          setErrors(response.payload);
        }
      });
  };

  render() {
    const { initialValues, ...other } = this.props;
    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        contentProps={other}
        validationSchema={schema}
        content={() => (
          <>
            <Field
              name="prescription.presentationDate"
              component={DatePicker}
              label={presentationDate}
              required
            />
            <Field
              name="prescription.pharmacistName"
              component={TextInput}
              label={pharmacist}
              icon="person"
              maxLength={128}
              required
            />
            <Field
              name="prescription.notes"
              component={NoteInput}
              label={notes}
              maxLength={4000}
            />
          </>
        )}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { prescription: expiredPrescription, id } = ownProps;
  const currentUser = getUser(state);

  let { prescription } = schema.cast();

  if (!isEmpty(expiredPrescription)) {
    prescription = expiredPrescription;
  } else {
    prescription = {
      ...prescription,
      presentationDate: moment(moment().toDate()).format("YYYY-MM-DD"),
      pharmacistName: currentUser.role === "Pharmacist" ? currentUser.name : prescription.pharmacistName,
    };
  }

  return ({
    initialValues: { prescription },
    applicationId: id,
  });
};

export default connect(mapStateToProps, {
  onSaveOrUpdateExpiredPrescriptionPresentation,
})(ExpiredPrescriptionForm);
