import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PopupLink from "components/items/popup-link";
import getEnrolmentStatus from "app/main/patients/helpers/get-enrolment-status";

const useStyles = makeStyles(theme => ({
  text: ({ status, fontSize }) => ({
    fontWeight: "bold",
    lineHeight: 1,
    fontSize,
    color: status.color,
    padding: `0 ${theme.spacing(1) / 2}px`,
  }),
}));

const PatientConsentStatus = ({
  consent,
  fontSize,
  reason,
}) => {
  const status = getEnrolmentStatus(consent);
  const classes = useStyles({ status, fontSize });

  const content = () => <span className={classes.text}>{status.label}</span>;

  if (reason) {
    return (
      <PopupLink
        label={content()}
        content={reason}
      />
    );
  }

  return <Typography>{content()}</Typography>;
};

export default PatientConsentStatus;
