import { ApiActions } from "middleware/call-api";
import { ActionTypes, isApplicationDispensingLoading, isApplicationDispensingLoaded } from "../reducers/applicationDispensing.reducers";

export const loadApplicationDispensing = (applicationId, orgUnitId) => dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/dispensing`,
    method: "GET",
    bailout: state => isApplicationDispensingLoading(state, applicationId) || isApplicationDispensingLoaded(state, applicationId),
    applicationId,
    types: [
      ActionTypes.LOADING_APPLICATION_DISPENSING,
      ActionTypes.LOADED_APPLICATION_DISPENSING,
      ActionTypes.ERROR_LOADING_APPLICATION_DISPENSING,
    ],
  },
});
