import curryConnector from "utils/connectors";

export const CANDIDATE_WORKLIST_STATE_KEY = "candidateWorklist";

const curry = fn => curryConnector(fn, CANDIDATE_WORKLIST_STATE_KEY);

export const ActionTypes = {
  SEARCHING_PATIENTS: "SEARCHING_PATIENTS",
  SEARCHED_PATIENTS: "SEARCHED_PATIENTS",
  ERROR_SEARCHING_PATIENTS: "ERROR_SEARCHING_PATIENTS",

  SEARCHING_CANDIDATES: "SEARCHING_CANDIDATES",
  SEARCHED_CANDIDATES: "SEARCHED_CANDIDATES",
  ERROR_SEARCHING_CANDIDATES: "ERROR_SEARCHING_CANDIDATES",

  SET_WORKLIST_FILTERS: "SET_WORKLIST_FILTERS",
  SET_CURRENT_PATIENT: "SET_CURRENT_PATIENT",
};

export const defaultSearchParams = {
  name: null,
  postCode: [],
  diseaseState: [],
};

const INITIAL_STATE = {
  loading: false,
  error: null,
  loaded: false,
  searchParams: defaultSearchParams,
  pageNumber: 1,
  total: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SEARCHING_CANDIDATES:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.ERROR_SEARCHING_CANDIDATES:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case ActionTypes.SEARCHED_CANDIDATES:
      return {
        ...state,
        loading: false,
        loaded: true,
        searchParams: action.payload.searchParams,
        total: action.payload.pageInfo.totalRecords,
        pageNumber: action.payload.pageInfo.pageNumber,
      };
    default:
      return state || INITIAL_STATE;
  }
};

export const isCandidateWorklistLoading = curry(({ localState }) => localState.loading);

export const isCandidateWorklistErrorLoading = curry(({ localState }) => localState.error);

export const getTotalNumberOfCandidates = curry(({ localState }) => localState.total);

export const getCurrentPageOfCandidates = curry(({ localState }) => localState.pageNumber);

export const getCandidateSearchParams = curry(({ localState }) => localState.searchParams);

export const bailOutOfLoadingCandidates = curry(({ localState }, pageNumber) => {
  const { loading, total } = localState;

  if (loading || (pageNumber === 1 && total !== 0)) {
    return true;
  }
  return false;
});
