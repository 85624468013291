import React from "react";
import { connect } from "react-redux";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import { find } from "lodash";

import ItemStatus from "components/items/item-status";
import ReferralCategoryLabel from "app/main/referrals/components/referral-category-label";
import UrgentStatus from "app/main/referrals/components/urgent-status";
import { AssignedUserIcon, NoteIcon, TaskIcon, AddendumIcon } from "helpers/icon-finder";
import { getReferralWorkflowStateSettings } from "app/auth/store/reducers/system-configuration";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    zIndex: 999,
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1) / 2,
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
  },
}));

function ReferralStatusBar(props) {
  const { referral, referralStatus } = props;
  const classes = useStyles();
  const status = find(props.referralWorkflowSettings, x => x.workflowState === referralStatus) || { icon: "lens", color: blue[600], label: "" };

  return (
    <div className={classes.container}>
      {/* //http://devtools.hcs.local:8090/pages/viewpage.action?spaceKey=EMP&title=Referral+Worklist+Design&preview=/7018665/7018666/image2019-3-26%2015%3A7%3A50.png */}
      {/* are the following two belongs to this component? */}
      {referralStatus && <ItemStatus label={status.label.toUpperCase()} color={status.color} />}
      <ReferralCategoryLabel triageCategory={referral.triageCategory} isUrgent={referral.isUrgent} />
      {referral.isUrgent === true && (
        <Tooltip title="Urgent">
          <div className={classes.icon}><UrgentStatus iconOnly /></div>
        </Tooltip>
      )}
      {/* if has assigned user */}
      {referral.assignedToUserId && (
        <Tooltip title="Assigned">
          <div className={classes.icon}><AssignedUserIcon size={20} /></div>
        </Tooltip>
      )}
      {/* if has notes/comments */}
      {referral.hasNotes && (
        <Tooltip title="Notes Recorded">
          <div className={classes.icon}><NoteIcon size={20} /></div>
        </Tooltip>
      )}
      {/* if has addendum */}
      {referral.hasAddendum && (
        <Tooltip title="Update Received">
          <div className={classes.icon}><AddendumIcon size={20} /></div>
        </Tooltip>
      )}
      {/* if has addendum */}
      {referral.hasIncompleteTasks && (
        <Tooltip title="Incomplete tasks">
          <div className={classes.icon}><TaskIcon size={20} /></div>
        </Tooltip>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  referralWorkflowSettings: getReferralWorkflowStateSettings(state),
});

export default (connect(mapStateToProps, { getReferralWorkflowStateSettings })(ReferralStatusBar));
