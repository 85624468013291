import React from "react";
import { isEmpty, find, map, get } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";

import ChatPanel from "components/items/chat-panel/index";
import DefaultListItem from "components/items/default-list-item";
import { getCandidatesForWorklist } from "app/main/candidates/reducers/candidates.reducers";
import { isCandidateWorklistLoading, isCandidateWorklistErrorLoading, getTotalNumberOfCandidates, getCurrentPageOfCandidates, getCandidateSearchParams } from "app/main/candidateWorklist/reducers/candidateWorklist.reducers";
import CandidateHeader from "app/main/candidates/components/candidate-header";
import CandidateRegistrationForm from "app/main/candidates/components/candidate-registration-form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import DefaultButton from "components/items/default-button";
import InfiniteLoadingList from "components/items/infinite-Loading-list";
import { setMobileContentSidebarStatus } from "app/store/actions/sidebars.actions";
import withPermissions from "permissions/withPermissions";
import CandidateAdvancedFilter from "app/main/candidateWorklist/components/candidate-advanced-filter";
import { searchCandidates } from "app/main/candidateWorklist/actions/candidateWorklist.actions";
import CareSummaryPanel from "app/main/candidateWorklist/components/care-summary-panel";
import getEnrolmentTypeIcon from "app/main/patients/helpers/get-enrolment-type-icon";
import { isMobileContentSidebarOpen } from "app/store/reducers/sidebars.reducer";
import { calculateAge } from "app/main/patients/helpers/format-dob";
import { getCurrentPatient } from "app/main/patients/reducers/patients.reducers";
import { getExistingPatientById } from "app/main/patients/actions/patients.actions";
import { CandidateWorklistIcon } from "helpers/icon-finder";

class CandidateWorklist extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedCandidateId: null,
    };
    this.onClickItem = this.onClickItem.bind(this);
  }

  onClickItem = selectedCandidateId => {
    this.setState({ selectedCandidateId });
    if (this.props.isMobileContentSidebarOpen) {
      this.props.setMobileContentSidebarStatus(false);
    }
  };

  // eslint-disable-next-line no-async-promise-executor
  onSearchCandidate = (searchParams, page, pageSize, forceLoad) => new Promise(async resolve => {
    setTimeout(() => {
      resolve(this.props.searchCandidates(searchParams, page || 1, pageSize || 20, forceLoad));
    }, 500);
  });

  onViewPatientDetail = patientId => {
    // check if patient id is match current patient id,
    // if not load the patient with patient id
    if (this.props.currentPatient?.patientId !== patientId) {
      this.props.getExistingPatientById(patientId, true).then(() => this.props.history.push(`candidateWorklist/patients/${patientId}`));
    } else {
      this.props.history.push(`candidateWorklist/patients/${patientId}`);
    }
  };

  assignToNurse = (id, isSalveoProgram) => {
    this.props.openDialog({
      children: (
        <CandidateRegistrationForm
          title="Assign to Nurse"
          id={id}
          onSucceed={() => this.props.closeDialog()}
          isSalveoProgram={isSalveoProgram}
        />
      ),
    });
  };

  renderItem = (item, selectedItem) => {
    const { birthDate, gender, firstname, surname, enrolments } = item;
    return (
      <DefaultListItem
        key={item.id}
        active={!isEmpty(selectedItem) && selectedItem.id === item.id}
        onClick={() => this.onClickItem(item.id)}
        content={(
          <>
            <Typography variant="body1" className="font-bold">{`${firstname} ${surname}`}</Typography>
            <div className="flex items-center mt-8 flex-wrap">
              {item.birthDate && (
              <Typography variant="caption" color="textSecondary" className="mr-4">{`${calculateAge(birthDate)} year old ${gender}`}</Typography>
              )}
              {map(enrolments, enrolment => (getEnrolmentTypeIcon({ type: enrolment.enrolmentType.value, label: enrolment.enrolmentType.label, key: enrolment.id })))}
            </div>
          </>
        )}
      />
    );
  }

  renderSidebarContent = selectedItem => {
    const { candidates, total, loading, error, pageNumber, candidateSearchParams } = this.props;
    return (
      <InfiniteLoadingList
        initialLoad
        data={candidates}
        renderItem={item => this.renderItem(item, selectedItem)}
        loading={loading}
        error={error}
        isEmptyStatus={isEmpty(candidates)}
        total={total}
        pageStart={pageNumber}
        loadFunction={page => {
          const number = page === undefined ? 1 : pageNumber + 1;
          return this.onSearchCandidate(candidateSearchParams, number, null, true);
        }}
      />
    );
  };

  renderHeader = selectedItem => {
    const patientId = get(selectedItem, "patientId", null);
    return (
      <div className="p-16">
        <CandidateHeader
          candidate={selectedItem}
          onClick={patientId && (() => this.onViewPatientDetail(patientId))}
        />
      </div>
    );
  }

  renderContent = (selectedItem, lastEncounter, nextEncounter, hasPermissionCandidatesRegister) => {
    const hasSalveoEnrolment = find(selectedItem.enrolments, x => x.enrolmentType.value === "SalveoNursing") !== undefined || false;
    const hasWelfareCheckEnrolment = find(selectedItem.enrolments, x => x.enrolmentType.value === "WelfareCheck") !== undefined || false;
    return (
      <div>
        <CareSummaryPanel
          patientId={selectedItem.patientId}
          lastEncounter={lastEncounter}
          nextEncounter={nextEncounter}
          primaryCondition={null}
          secondaryCondition={null}
          candidate={selectedItem}
        />
        <div className="flex justify-center px-16 pb-16 mt-16">
          {hasPermissionCandidatesRegister && (
            <div className="flex justify-center px-16 pb-16">
              <div className="flex items-center flex-col sm:flex-row">
                <DefaultButton
                  size="large"
                  icon="person_add"
                  label="Salveo Nursing"
                  onClick={() => this.assignToNurse(selectedItem.id, true)}
                  disabled={hasSalveoEnrolment}
                />
                <DefaultButton
                  className="mt-8 sm:ml-8 sm:mt-0"
                  size="large"
                  icon="person_add"
                  label="Welfare Check"
                  disabled={hasWelfareCheckEnrolment}
                  onClick={() => this.assignToNurse(selectedItem.id, false)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const {
      candidates, lastEncounter, nextEncounter, hasPermissionCandidatesRegister, total, candidateSearchParams, initialValues,
    } = this.props;
    const { selectedCandidateId } = this.state;
    const selectedItem = find(candidates, x => x.id === selectedCandidateId) || {};

    return (
      <ChatPanel
        empty={isEmpty(selectedItem)}
        renderSidebarHeader={() => (
          <CandidateAdvancedFilter
            total={total}
            searchParams={candidateSearchParams}
            initialValues={initialValues}
            onSearch={this.onSearchCandidate}
            renderFilterInfo={(
              <div>
                <Typography variant="caption">Total Results: {total}</Typography>
              </div>
            )}
          />
        )}
        renderSidebarContent={() => this.renderSidebarContent(selectedItem)}
        renderContent={() => this.renderContent(selectedItem, lastEncounter, nextEncounter, hasPermissionCandidatesRegister)}
        renderHeader={() => this.renderHeader(selectedItem)}
        emptyIcon={<CandidateWorklistIcon />}
        emptyTitle="Select a candidate to view details"
      />
    );
  }
}

const mapStateToProps = state => {
  const params = getCandidateSearchParams(state);

  return ({
    candidates: getCandidatesForWorklist(state),
    loading: isCandidateWorklistLoading(state),
    error: isCandidateWorklistErrorLoading(state),
    total: getTotalNumberOfCandidates(state),
    pageNumber: getCurrentPageOfCandidates(state),
    lastEncounter: {},
    nextEncounter: {},
    candidateSearchParams: params,
    initialValues: { candidateFilter: params },
    isMobileContentSidebarOpen: isMobileContentSidebarOpen(state),
    currentPatient: getCurrentPatient(state),
  });
};

export default withPermissions("CandidatesRegister")(
  withRouter(connect(mapStateToProps, { openDialog, closeDialog, setMobileContentSidebarStatus, searchCandidates, getExistingPatientById })(CandidateWorklist)),
);
