import { ApiActions } from "middleware/call-api";
import { isEmpty, forEach } from "lodash";
import { openPdf } from "utils/download-from-api";
import { ActionTypes, areAssessmentsLoading } from "../reducers/assessments.reducers";

export const setCurrentAssessment = assessment => ({
  type: ActionTypes.SET_CURRENT_ASSESSMENT,
  payload: { assessment },
});

export const setCurrentSurvey = survey => ({
  type: ActionTypes.SET_CURRENT_SURVEY,
  payload: { survey },
});

export const setAssessmentPrintHistory = history => ({
  type: ActionTypes.SET_ASSESSMENT_PRINT_HISTORY,
  payload: { history },
});

export const fetchPatientAssessments = patientId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/assessments`,
    bailout: state => areAssessmentsLoading(state, patientId),
    entityType: "Assessments",
    patientId,
  },
});

export const createAssessment = assessment => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${assessment.patientId}/assessments/new`,
    body: { assessment },
    types: ["CREATING_ASSESSMENT", ActionTypes.CREATED_ASSESSMENT, "ERROR_CREATING_ASSESSMENT"],
    patientId: assessment.patientId,
    appointmentId: assessment.appointmentId,
    encounterId: assessment.encounterId,
  },
});

export const updateSurveyList = surveys => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${surveys.patientId}/assessments/UpdateSurveyList`,
    body: { surveys },
    types: ["UPDATING_SURVEY_LIST", ActionTypes.UPDATED_SURVEY_LIST, "ERROR_UPDATING_SURVEY_LIST"],
  },
});

export const completeAssessment = (patientId, assessment) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${patientId}/assessments/complete`,
    body: { assessment },
    types: ["COMPLETING_ASSESSMENT", ActionTypes.COMPLETED_ASSESSMENT, "ERROR_COMPLETING_ASSESSMENT"],
    patientId,
  },
});

export const fetchAssessmentById = (patientId, assessmentId) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/assessments/${assessmentId}`,
    types: [ActionTypes.LOADING_CURRENT_ASSESSMENT, ActionTypes.LOADED_CURRENT_ASSESSMENT, ActionTypes.ERROR_LOADING_CURRENT_ASSESSMENT],
    patientId,
    assessmentId,
  },
});

export const getAssessmentItemById = ({ patientId, assessmentItemId }) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/assessments/getAssessmentItemById/${assessmentItemId}`,
    types: [ActionTypes.LOADING_SURVEY_STATUS_CHANGE, ActionTypes.SURVEY_STATUS_CHANGE, ActionTypes.ERROR_UPDATING_SURVEY_STATUS],
  },
});

export const onMedicationHistoryComplete = (patientId, assessmentItem) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${patientId}/assessments/medicationHistoryComplete`,
    body: { assessmentItem },
    types: ["COMPLETING_SURVEY", ActionTypes.COMPLETED_SURVEY, "ERROR_COMPLETING_SURVEY"],
  },
});

// CURRENT SURVEY SECTION
export const onSurveyComplete = (patientId, survey) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${patientId}/assessments/surveyComplete`,
    body: { survey },
    types: ["COMPLETING_SURVEY", ActionTypes.COMPLETED_SURVEY, "ERROR_COMPLETING_SURVEY"],
  },
});

export const onSurveyChange = (patientId, survey) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${patientId}/assessments/surveySave`,
    body: { survey },
    types: ["LOADING_AUTOSAVE_SURVEY", ActionTypes.AUTOSAVE_SURVEY, "ERROR_SAVING_ASSESSMENT"],
  },
});

export const clearSurveyResults = (patientId, survey) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${patientId}/assessments/clearSurveyResults`,
    body: { survey },
    types: [ActionTypes.CLEARING_CURRENT_SURVEY_RESULTS, ActionTypes.CLEARED_CURRENT_SURVEY_RESULTS, ActionTypes.ERROR_CLEARING_CURRENT_SURVEY_RESULTS],
  },
});

export const setSurveyNotRequired = (patientId, survey) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${patientId}/assessments/setSurveyNotRequired`,
    body: { survey },
    types: [ActionTypes.LOADING_SURVEY_STATUS_CHANGE, ActionTypes.SURVEY_STATUS_CHANGE, ActionTypes.ERROR_UPDATING_SURVEY_STATUS],
  },
});

export const setSurveyRequired = (patientId, survey) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${patientId}/assessments/setSurveyRequired`,
    body: { survey },
    types: [ActionTypes.LOADING_SURVEY_STATUS_CHANGE, ActionTypes.SURVEY_STATUS_CHANGE, ActionTypes.ERROR_UPDATING_SURVEY_STATUS],
  },
});

export const fetchLastSurveyResults = (patientId, surveyId, surveyType) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/assessments/fetchLastSurveyResults/${surveyId}/${surveyType}`,
    types: [ActionTypes.LOADING_LAST_SURVEY_RESULT, ActionTypes.LOADED_LAST_SURVEY_RESULT, "ERROR_LOADING_LAST_SURVEY_RESULT"],
    id: surveyId,
  },
});

export const getSurveyBySurveyId = ({ patientId, surveyId }) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/assessments/getSurveyBySurveyId/${surveyId}`,
    entityType: "CURRENT_SURVEY",
  },
});

export const setSurveyInProgress = (patientId, survey) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${patientId}/assessments/setSurveyInProgress`,
    body: { survey },
    types: [ActionTypes.LOADING_SURVEY_STATUS_CHANGE, ActionTypes.SURVEY_STATUS_CHANGE, ActionTypes.ERROR_UPDATING_SURVEY_STATUS],
  },
});

export const continueAssessment = assessment => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${assessment.patientId}/assessments/continue`,
    body: { assessment },
    types: ["CONTINUING_ASSESSMENT", ActionTypes.CONTINUED_ASSESSMENT, "ERROR_CONTINUING_ASSESSMENT"],
    patientId: assessment.patientId,
    appointmentId: assessment.appointmentId,
  },
});

export const printAssessmentItems = ({ patientId, currentAssessmentId, surveys }) => () => {
  let url = `api/patient/${patientId}/assessments/printAssessmentItems/${currentAssessmentId}?`;

  if (!isEmpty(surveys)) {
    forEach(surveys, (value, key) => {
      if (value === true) {
        url += `&surveyType=${key}`;
      }
    });
  }
  return openPdf(url);
};

export const fetchAssessmentPrintHistory = ({ patientId, assessmentId }) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/assessments/FetchAssessmentPrintHistory/${assessmentId}`,
    entityType: "ASSESSMENT_PRINT_HISTORY",
  },
});

export const removeAssessment = (patientId, assessmentId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/patient/${patientId}/assessments/remove/${assessmentId}`,
    entityType: "ASSESSMENT",
    patientId,
    assessmentId,
  },
});
