import { green, blue, lightBlue, blueGrey, amber, grey, cyan } from "@material-ui/core/colors";

export default function getEncounterStatus(status) {
  switch (status) {
    case "Scheduled":
      return {
        color: lightBlue[600],
      };
    case "Completed":
      return {
        color: green[600],
      };
    case "Cancelled":
      return {
        color: grey[600],
      };
    case "Postponed":
      return {
        color: amber[600],
      };
    case "InProgress":
      return {
        color: cyan[600],
      };
    case "Archive":
      return {
        color: blueGrey[600],
      };
    default:
      return {
        color: blue[600],
      };
  }
}
