import { isNil, isNumber, isObject, isString, isEmpty } from "lodash";

export default function checkIfEmpty(content) {
  return isNil(content)
    || (isNumber(content) && content === 0)
    || (isObject(content) && isEmpty(content))
    || (isString(content) && (content.trim() === ""))
    || content === undefined
    || content === false;
}
