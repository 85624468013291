import React from "react";
import { map } from "lodash";
import clsx from "clsx";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

const chunkArray = (items, size) => {
  const arr = [];
  let index = 0;
  while (index < items.length) {
    arr.push(items.slice(index, size + index));
    index += size;
  }
  return arr;
};

const ApplicationPanelContent = ({
  width,
  children,
}) => {
  const sm = isWidthDown("sm", width);
  const tileRows = chunkArray(React.Children.map(children, child => child), 2);

  return (
    map(tileRows, (row, rowIndex) => (
      <div
        key={rowIndex.toString()}
        className={clsx(
          "flex",
          sm && "flex-col",
          rowIndex !== 0 && "mt-16 with-horizontal-divider",
        )}
      >
        {map(row, (x, index) => {
          const withBorder = index % 2 !== 0;
          const isFirstRow = index === 0 || index === 1;

          return (
            <div
              key={index.toString()}
              className={clsx(
                "items-container",
                (withBorder && !sm) && "with-vertical-divider",
                !isFirstRow && "mt-16",
                sm && index !== 0 && "mt-16 with-horizontal-divider",
              )}
            >
              {x}
            </div>
          );
        })}
      </div>
    ))
  );
};

export default withWidth()(ApplicationPanelContent);
