import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoadingState from "components/items/loading-state";
import { find, isEmpty, filter, sortBy, take } from "lodash";
import ErrorState from "components/items/error-state";
import WarningState from "components/warning-state";
import { formatCostOutput } from "helpers/format-cost";
import { Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { isDrugCostInformationLoading, isDrugCostInformationLoadingError, getDrugCostInformation } from "app/main/applications/reducers/applications.reducers";
import { searchDrugCostInformation } from "../../actions";

// Amount of rows to show in the cost field
const COST_ROWS_TO_SHOW = 10;

const DrugCostInformation = ({
  drugResultsJson,
  strength,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isDrugCostInformationLoading); // Has it been started
  const error = useSelector(isDrugCostInformationLoadingError);// Error handler
  const currentDrugInformation = useSelector(getDrugCostInformation);
  const [data, setData] = useState(null); // Data from the dispatch call

  let results = "";
  if (drugResultsJson != null) {
    results = JSON.parse(drugResultsJson);
  }

  async function setupAndQueryCostData(isMounted) {
    if (isMounted && !isEmpty(strength)) {
      if (!isLoading) {
        // Only search when the strength and results are not null
        if (strength && results) {
          const foundStrength = find(results.strengths, x => x.name === strength);

          // Code taken from the drug cost search in treatment-request-form/form.js
          if (foundStrength.amtMpuuCode || foundStrength.externalIdentifier) {
            const code = foundStrength.amtMpuuCode ?? foundStrength.externalIdentifier;
            const identifierType = !foundStrength.amtMpuuCode && foundStrength.externalIdentifier ? "L" : "AMT-MPUU";
            if (currentDrugInformation?.identifier !== code || currentDrugInformation?.identifierType !== identifierType) {
              // Await this asynchronous function, so on load or close of parent modal it won't cause a memory leak
              await dispatch(searchDrugCostInformation(code, identifierType)).then(value => {
                if (!value.error) {
                  setData(value.payload.drugCostInformation);
                }
              });
            } else {
              setData(currentDrugInformation?.result);
            }
          }
        }
      }
    }
  }

  React.useEffect(() => {
    let isMounted = !isEmpty(strength);
    if (isEmpty(strength)) {
      // If strength has been set to empty, nullify the data
      setData(null);
    } else {
      // Else we query the data
      setupAndQueryCostData(isMounted);
    }
    return () => {
      isMounted = false;
    };
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [strength]);

  const renderLoading = () => (
    <LoadingState />
  );

  const renderError = () => (
    <ErrorState title="Medicine cost information not found, please contact pharmacy for further information" />
  );

  if (isEmpty(drugResultsJson) || isEmpty(strength)) {
    return null;
  }

  const renderOutput = () => {
    if (isLoading) {
      return renderLoading();
    }

    if (error) {
      return renderError();
    }

    if (data !== null && data.length > 0) {
      const filteredData = filter(data, x => x.avgWeightedPricePackSize !== "0" && !isEmpty(x.avgWeightedPricePackSize) && formatCostOutput(x.avgWeightedPricePackSize) !== "$0.00" && !Number.isNaN(x.avgWeightedPricePackSize));
      // Filter out any $0.00, 0, or empty returns

      const sortedData = sortBy(filteredData, x => parseFloat(x.avgWeightedPricePackSize));
      // Sort by the average lowest weight price pack first

      return (
        <Table
          style={{
            border: "1px solid black",
            borderCollapse: "inherit",
          }}
        >
          <TableHead
            style={{
              border: "1px solid black",
            }}
          >
            <TableRow>
              <TableCell style={{ width: "20%" }}>
                Medicine
              </TableCell>
              <TableCell style={{ width: "20%" }}>
                Brand
              </TableCell>
              <TableCell align="right" style={{ width: "20%" }}>
                Pack Size
              </TableCell>
              <TableCell align="right" style={{ width: "20%" }}>
                Average Weighted Price - Pack Size
              </TableCell>
              <TableCell align="right" style={{ width: "20%" }}>
                Average Weighted Price - Inventory Cost
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {take(sortedData, COST_ROWS_TO_SHOW).map(item => (
              <TableRow
                style={{ border: "1px solid black" }}
                key={item.medicine + item.brand}
              >
                <TableCell>
                  {item.medicine}
                </TableCell>
                <TableCell>
                  {item.brand}
                </TableCell>
                <TableCell align="right">
                  {item.packSize}
                </TableCell>
                <TableCell align="right">
                  {formatCostOutput(item.avgWeightedPricePackSize)}
                </TableCell>
                <TableCell align="right">
                  {formatCostOutput(item.avgWeightedPriceUnitOfMeasure)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }

    return (
      <WarningState title="Medicine cost information not found, please contact pharmacy for further information" />
    );
  };

  return (
    <div
      className="flex flex-col sm:flex-row sm:items-center"
      style={{
        width: "100%",
        justifyContent: "center",
        marginLeft: 32,
        marginRight: 32,
      }}
    >
      {renderOutput()}
    </div>
  );
};

export default DrugCostInformation;
