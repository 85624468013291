import React from "react";
import { Field } from "formik";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Form from "components/form";
import { UserSelector } from "components/inputs";
import { onAssignApplicationToUser } from "app/main/applications/actions/applications.actions";

const assignedTo = "Assigned To";

const schema = Yup.object().shape({
  assignment: Yup.object().shape({
    assignedToUserId: Yup.string().nullable(),
  }),
});

const AssignUserForm = ({
  id,
  onSucceed,
  applicationAssignment,
  orgUnitId,
  ...other
}) => {
  const dispatch = useDispatch();

  const handleSubmit = (data, { setErrors }) => {
    const { assignedToUserId } = data.assignment;
    const assignData = { assignedToUserId: assignedToUserId?.value };

    dispatch(onAssignApplicationToUser(id, orgUnitId, assignData)).then(response => {
      if (response.error !== true) {
        onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  let { assignment } = schema.cast();

  if (!isEmpty(applicationAssignment)) {
    assignment = {
      assignedToUserId: {
        value: applicationAssignment.assignedToUserId,
        label: applicationAssignment.assignedTo,
      },
    };
  }
  return (
    <Form
      initialValues={{ assignment }}
      onSubmit={handleSubmit}
      contentProps={other}
      validationSchema={schema}
      content={() => (
        <Field
          name="assignment.assignedToUserId"
          component={UserSelector}
          label={assignedTo}
          icon="person"
          orgUnitId={orgUnitId}
          userHasPermission="IPADecisionEdit"
        />
      )}
    />
  );
};

export default AssignUserForm;
