import React from "react";
import ScreenHeader from "components/items/screen-header";
import CandidatesSearch from "app/main/candidates/components/candidates-search";
import CandidateTable from "app/main/candidates/components/candidates-table";
import DefaultPageLayout from "components/layouts/default-page-layout";

export default function CandidatesIndexPage() {
  return (
    <DefaultPageLayout
      header={(
        <ScreenHeader
          renderSearch={<CandidatesSearch />}
        />
      )}
      content={
        <CandidateTable />
      }
    />
  );
}
