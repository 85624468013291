import { ApiActions } from "middleware/call-api";
import { RSAA } from "redux-api-middleware";
import { isEmpty, forEach, get } from "lodash";
import { ActionTypes, isPageLoading, isCurrentPatientLoading, isCurrentPatientLoaded, isPageLoaded } from "../reducers/patients.reducers";

export const setCurrentPatient = patient => ({
  type: ActionTypes.LOADED_CURRENT_PATIENT,
  payload: { patient },
});

export const resetCurrentPatient = () => ({
  type: ActionTypes.RESET_CURRENT_PATIENT,
});

export const createNewPatient = () => setCurrentPatient({
  givenName: null,
  middleName: null,
  familyName: null,
  preferredName: null,
  birthDate: null,
  deathDate: null,
  isDead: false,
  sex: null,
  medicareCard: null,
  medicareExpiryDate: null,
  dva: null,
  dvaExpiryDate: null,
  contactDetails: {
    email: null,
    fax: null,
    phoneHome: null,
    phoneMobile: null,
    PhoneWork: null,
  },
  address: {
    street: null,
    suburb: null,
    state: null,
    postcode: null,
  },
  nextOfKin: {
    givenName: null,
    familyName: null,
    relationshipType: null,
    contact: null,
  },
});

export const fetchPatientResults = (pageNumber, pageSize, searchParams, forceLoad = true) => dispatch => {
  let url = `/patient?pageNumber=${pageNumber}&pageSize=${pageSize}`;

  if (!isEmpty(searchParams)) {
    forEach(searchParams, (value, key) => {
      if (!isEmpty(value)) {
        url += `&${key}=${value}`;
      }
    });
  }

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "PATIENTS",
      pageNumber,
      bailout: state => isPageLoading(state, pageNumber) || (!forceLoad && isPageLoaded(state, pageNumber)),
    },
  });
};

export const savePatient = () => ({
  [RSAA]: {
    endpoint: "/patient",
    method: "POST",
    types: [
      ActionTypes.LOADING_PATIENTS,
      ActionTypes.LOADED_PATIENTS,
      ActionTypes.ERROR_LOADING_PATIENTS,
    ],
  },
});

export const recordNewPatient = patient => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: "/patient",
    body: { patient },
    entityType: "patient",
  },
});

export const getExistingPatientById = (patientId, forceReload = false) => ({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}`,
    method: "GET",
    entityType: "CURRENT_PATIENT",
    bailout: state => isCurrentPatientLoading(state) || (!forceReload && isCurrentPatientLoaded(state)),
  },
});

export const updateExistingPatient = patient => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/patient/${patient.patientId}`,
    body: { patient },
    entityType: "patient",
  },
});

export const fetchAllAddresses = () => ({
  [RSAA]: {
    endpoint: "/address",
    method: "GET",
    types: [
      ActionTypes.LOADING_ADDRESS,
      ActionTypes.LOADED_ADDRESS,
      ActionTypes.ERROR_LOADING_ADDRESS,
    ],
  },
});

/** Patient Disease State */
export const setCurrentDiseaseState = cuurentDiseaseState => ({
  type: ActionTypes.SET_CURRENT_DISEASE_STATE,
  payload: { diseaseState: cuurentDiseaseState },
});

export const saveDiseaseState = (patientId, editDiseaseState) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/patient/${patientId}/PatientDiseaseState`,
    body: { diseaseState: { patientId, diseaseState: editDiseaseState } },
    entityType: "DISEASE_STATE",
    patientId,
  },
});

export const removeDiseaseState = (patientId, diseaseStateId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/patient/${patientId}/RemovePatientDiseaseState`,
    body: { diseaseState: { patientId, diseaseState: { id: diseaseStateId } } },
    entityType: "DISEASE_STATE",
    patientId,
  },
});

export const saveAssignedUser = (patientId, enrolment) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/enrolment/${patientId}/AssignUser`,
    body: { enrolment },
    types: ["SAVING_PATIENT_ASSIGNED_USER", ActionTypes.SAVED_PATIENT_ASSIGNED_USER, "ERROR_SAVING_PATIENT_ASSIGNED_USER"],
    patientId,
  },
});

export const withdrawEnrolment = (patientId, enrolment) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/enrolment/${patientId}/Withdraw`,
    body: { enrolment },
    types: ["WITHDRAWING_ENROLMENT", ActionTypes.WITHDRAWN_ENROLMENT, "ERROR_WITHDRAWING_ENROLMENT"],
    patientId,
  },
});

export const reEnrol = (patientId, enrolment) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/enrolment/${patientId}/Enrol`,
    body: { enrolment },
    types: ["SAVING_ENROLMENT", ActionTypes.SAVED_ENROLMENT, "ERROR_SAVING_ENROLMENT"],
    patientId,
  },
});

export const savePatientIdentifier = (patientId, patientIdentifier) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/patient/${patientId}/SavePatientIdentifier`,
    body: { patientIdentifier: { ...patientIdentifier, patientId } },
    entityType: "PATIENT_IDENTIFIER",
    patientId,
  },
});

export const removePatientIdentifier = (patientId, patientIdentifierId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/patient/${patientId}/RemovePatientIdentifier/${patientIdentifierId}`,
    body: { patientId, patientIdentifier: { patientIdentifierId } },
    entityType: "PATIENT_IDENTIFIER",
    patientId,
  },
});

export const recordOrUpdateNextOfKin = (patientId, nextOfKin) => async dispatch => {
  const NEXT_OF_KIN_ACTION = get(nextOfKin, "id", false)
    ? ["UPDATING_NEXT_OF_KIN", ActionTypes.UPDATED_NEXT_OF_KIN, "ERROR_UPDATING_NEXT_OF_KIN"]
    : ["CREATING_NEXT_OF_KIN", ActionTypes.CREATED_NEXT_OF_KIN, "ERROR_CREATING_NEXT_OF_KIN"];

  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/patient/${patientId}/patientNextOfKin`,
      body: { nextOfKin },
      types: NEXT_OF_KIN_ACTION,
      patientId,
    },
  });
};

export const savePatientCard = (patientId, patientCard) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/patient/${patientId}/SavePatientCard`,
    body: { patientCard: { patientId, ...patientCard } },
    entityType: "PATIENT_CARD",
    patientId,
  },
});

export const removePatientCard = (patientId, patientCardId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/patient/${patientId}/RemovePatientCard/${patientCardId}`,
    entityType: "PATIENT_CARD",
    patientId,
  },
});
