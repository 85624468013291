import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import { ActionTypes,
  isPageLoading,
  isCurrentSpecialtyProfileLoaded,
  isCurrentSpecialtyProfileLoading,
  areSearchedSpecialtyProfilesLoading,
  areSearchedSpecialtyProfilesLoaded,
  isPageLoaded,
  areSubSpecialtiesLoading,
  areSpecialtyRolesLoading } from "../reducers/specialtyProfiles.reducers";

const setCurrentSpecialtyProfile = specialtyProfile => ({
  type: ActionTypes.SET_CURRENT_SPECIALTY_PROFILE,
  payload: { specialtyProfile },
});

export const resetCurrentSpecialtyProfile = () => ({
  type: ActionTypes.RESET_CURRENT_SPECIALTY_PROFILE,
});

export const createNewSpecialtyProfile = ({ orgUnitId = null }) => dispatch => {
  dispatch(
    setCurrentSpecialtyProfile({
      id: null,
      orgUnitId,
      name: null,
      currentSpecialtyRoles: [],
      autoAssignRoleId: null,
      serviceCode: null,
    }),
  );
};

export const fetchSpecialtyProfilesResults = (orgUnitId, pageNumber, pageSize, searchParams, forceLoad = true) => dispatch => {
  const url = `/specialtyprofiles/${orgUnitId}?pageNumber=${pageNumber}&pageSize=${pageSize}${formatSearchParams(searchParams)}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "specialty_Profiles",
      pageNumber,
      bailout: state => isPageLoading(state, pageNumber) || (!forceLoad && isPageLoaded(state, pageNumber)),
    },
  });
};

export const getSpecialtyProfile = (orgUnitId, id) => ({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/specialtyprofiles/${orgUnitId}/${id}`,
    entityType: "SPECIALTY_PROFILE",
    bailout: state => isCurrentSpecialtyProfileLoaded(state) || isCurrentSpecialtyProfileLoading(state),
  },
});

export const addNewSpecialtyProfile = specialtyProfile => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/specialtyprofiles/${specialtyProfile.orgUnitId}`,
    body: { specialtyProfile },
    entityType: "SPECIALTY_PROFILE",
  },
});

export const updateExistingSpecialtyProfile = specialtyProfile => async dispatch => {
  const { orgUnitId, id } = specialtyProfile;
  return dispatch({
    [ApiActions.SAVE_TO_API]: {
      endpoint: `/specialtyprofiles/${orgUnitId}/${id}`,
      body: { specialtyProfile },
      entityType: "SPECIALTY_PROFILE",
    },
  });
};

export const fetchAllSpecialtyProfiles = () => dispatch => {
  const url = "/specialtyprofiles/getAllSpecialtyProfiles";

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: state => areSearchedSpecialtyProfilesLoaded(state) || areSearchedSpecialtyProfilesLoading(state),
      entityType: "SEARCHED_SPECIALTIES",
    },
  });
};

export const fetchSpecialtyRoles = specialtyId => dispatch => {
  const url = `/specialtyprofiles/${specialtyId}/getSpecialtyRoles`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: state => areSpecialtyRolesLoading(state, specialtyId),
      entityType: "SPECIALTY_ROLES",
      specialtyId,
    },
  });
};

export const fetchAllSubSpecialties = () => dispatch => {
  const url = "/specialtyprofiles/getAllSubSpecialties";

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: state => areSubSpecialtiesLoading(state),
      entityType: "SUB_SPECIALTIES",
    },
  });
};

export const fetchSpecialtyProfilesForSelector = (orgUnitId, searchParams) => dispatch => {
  const params = formatSearchParams(searchParams);
  const pageNumber = 1;
  const url = `/specialtyprofiles/${orgUnitId}?pageNumber=${pageNumber}&pageSize=999${params}`;
  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      bailout: state => areSearchedSpecialtyProfilesLoading(state),
      method: "GET",
      entityType: "SEARCHED_SPECIALTIES",
    },
  });
};
