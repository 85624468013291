import { get, isEmpty, concat, map, find, forEach, orderBy, groupBy, findIndex, filter } from "lodash";
import curryConnector from "utils/connectors";

import { getSurveySettings, getSurveyGroupSettings, getSurveySettingsBySurveyType } from "app/auth/store/reducers/system-configuration";
import { ActionTypes as MedicationHistoryActionTypes } from "app/main/patients/reducers/medicationHistory.reducers";

export const ASSESSMENTS_STATE_KEY = "assessments";

const curry = fn => curryConnector(fn, ASSESSMENTS_STATE_KEY);

export const ActionTypes = {
  LOADING_ASSESSMENTS: "LOADING_ASSESSMENTS",
  LOADED_ASSESSMENTS: "LOADED_ASSESSMENTS",
  ERROR_LOADING_ASSESSMENTS: "ERROR_LOADING_ASSESSMENTS",

  LOADING_CURRENT_ASSESSMENT: "LOADING_CURRENT_ASSESSMENT",
  LOADED_CURRENT_ASSESSMENT: "LOADED_CURRENT_ASSESSMENT",
  ERROR_LOADING_CURRENT_ASSESSMENT: "ERROR_LOADING_CURRENT_ASSESSMENT",

  CREATING_ASSESSMENT: "CREATING_ASSESSMENT",
  CREATED_ASSESSMENT: "CREATED_ASSESSMENT",
  ERROR_CREATING_ASSESSMENT: "ERROR_CREATING_ASSESSMENT",

  COMPLETED_ASSESSMENT: "COMPLETED_ASSESSMENT",

  CONTINUED_ASSESSMENT: "CONTINUED_ASSESSMENT",

  SET_CURRENT_ASSESSMENT: "SET_CURRENT_ASSESSMENT",
  AUTOSAVE_SURVEY: "AUTOSAVE_SURVEY",
  LOADING_LAST_SURVEY_RESULT: "LOADING_LAST_SURVEY_RESULT",
  LOADED_LAST_SURVEY_RESULT: "LOADED_LAST_SURVEY_RESULT",

  CLEARING_CURRENT_SURVEY_RESULTS: "CLEARING_CURRENT_SURVEY_RESULTS",
  CLEARED_CURRENT_SURVEY_RESULTS: "CLEARED_CURRENT_SURVEY_RESULTS",
  ERROR_CLEARING_CURRENT_SURVEY_RESULTS: "ERROR_CLEARING_CURRENT_SURVEY_RESULTS",

  LOADING_SURVEY_STATUS_CHANGE: "LOADING_SURVEY_STATUS_CHANGE",
  SURVEY_STATUS_CHANGE: "SURVEY_STATUS_CHANGE",
  ERROR_UPDATING_SURVEY_STATUS: "ERROR_UPDATING_SURVEY_STATUS",

  UPDATED_SURVEY_LIST: "UPDATED_SURVEY_LIST",
  COMPLETED_SURVEY: "COMPLETED_SURVEY",
  COMPLETING_SURVEY: "COMPLETING_SURVEY",

  LOADING_CURRENT_SURVEY: "LOADING_CURRENT_SURVEY",
  LOADED_CURRENT_SURVEY: "LOADED_CURRENT_SURVEY",

  SET_CURRENT_SURVEY: "SET_CURRENT_SURVEY",

  LOADED_ASSESSMENT_PRINT_HISTORY: "LOADED_ASSESSMENT_PRINT_HISTORY",
  SET_ASSESSMENT_PRINT_HISTORY: "SET_ASSESSMENT_PRINT_HISTORY",

  DELETED_ASSESSMENT: "DELETED_ASSESSMENT",
};

const INITIAL_STATE = {
  all: {},
  current: null,
  currentSurvey: null,
  assessmentPrintHistory: null,
};

const updateSurvey = (state, action) => {
  const index = findIndex(state.current.assessmentItems, x => x.id === action.payload.assessmentItem.id);
  state.current.assessmentItems.splice(index, 1, action.payload.assessmentItem);

  return {
    ...state,
    currentSurvey: {
      ...state.currentSurvey,
      loading: false,
      ...action.payload.assessmentItem,
    },
  };
};

const updateSurveyResults = (state, action) => {
  const index = findIndex(state.current.assessmentItems, x => x.id === action.payload.id);
  state.current.assessmentItems.splice(index, 1, {
    ...state.currentSurvey,
    status: action.payload.resultsJson ? "InProgress" : "NotStarted",
  });

  return {
    ...state,
    currentSurvey: {
      ...state.currentSurvey,
      loading: false,
      resultsJson: action.payload.resultsJson,
    },
  };
};

const onAutoSaveSurvey = (state, action) => {
  // find the updated assessment item from the list of assessmentItems
  const currentAssessmentItem = action.payload.assessmentItem;
  let { assessmentItems } = state.current;
  assessmentItems = map(assessmentItems, x => {
    if (x.id === currentAssessmentItem.id && x.status !== currentAssessmentItem.status) {
      return {
        ...x,
        status: currentAssessmentItem.status,
      };
    }
    return x;
  });

  return {
    ...state,
    current: {
      ...state.current,
      assessmentItems,
    },
    // TODO update currentSurvey will cause dom to re-render
    // currentSurvey: {
    //   ...state.currentSurvey,
    //   status: currentAssessmentItem.status,
    // },
  };
};

// we should update the status when we complete the assessment
const onCompleteAssessment = (state, action) => {
  let { assessments } = state.all[action.payload.patientId];
  assessments = map(assessments, x => {
    if (x.id === action.payload.assessment.id) {
      return ({
        ...x,
        status: action.payload.assessment.status,
      });
    }
    return x;
  });

  return {
    ...state,
    all: {
      ...state.all,
      [action.payload.patientId]: {
        ...state.all[action.payload.patientId],
        assessments,
      },
    },
    current: {
      ...state.current,
      status: action.payload.assessment.status,
    },
  };
};

const removeAssessment = (state, action) => {
  let { assessments } = state.all[action.payload.patientId];

  assessments = filter(assessments, x => x.id !== action.payload.assessmentId);

  return {
    ...state,
    all: {
      ...state.all,
      [action.payload.patientId]: {
        ...state.all[action.payload.patientId],
        assessments,
      },
    },
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_ASSESSMENTS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: true } },
      };
    case ActionTypes.ERROR_LOADING_ASSESSMENTS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: false, error: action.payload.message } },
      };
    case ActionTypes.LOADED_ASSESSMENTS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]:
          {
            ...state.all[action.payload.patientId],
            loading: false,
            loaded: true,
            assessments: action.payload.assessments,
          },
        },
      };

    case ActionTypes.LOADING_CURRENT_ASSESSMENT:
      return {
        ...state,
        current: {
          ...state.current,
          loading: true,
        },
      };
    case ActionTypes.ERROR_LOADING_CURRENT_ASSESSMENT:
      return {
        ...state,
        current: {
          ...state.current,
          error: action.error,
        },
      };
    case ActionTypes.LOADED_CURRENT_ASSESSMENT:
      return {
        ...state,
        current: action.payload.assessment,
      };
    case ActionTypes.CREATED_ASSESSMENT:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]:
          {
            ...state.all[action.payload.patientId],
            assessments: isEmpty(state.all[action.payload.patientId]) || isEmpty(state.all[action.payload.patientId].assessments)
              ? [action.payload.assessment]
              : concat(...state.all[action.payload.patientId].assessments, action.payload.assessment),
          },
        },
      };
    case ActionTypes.UPDATED_SURVEY_LIST:
      return {
        ...state,
        current: {
          ...state.current,
          assessmentItems: action.payload.assessmentItems,
        },
      };

    case ActionTypes.COMPLETED_ASSESSMENT:
      return onCompleteAssessment(state, action);
    case ActionTypes.SET_CURRENT_ASSESSMENT:
      return {
        ...state,
        current: action.payload.assessment,
      };

    case ActionTypes.LOADED_LAST_SURVEY_RESULT:
      return updateSurveyResults(state, action);
    case ActionTypes.COMPLETED_SURVEY:
    case ActionTypes.SURVEY_STATUS_CHANGE:
    case MedicationHistoryActionTypes.UPDATED_MEDICATION_HISTORY_STATUS:
    case ActionTypes.CLEARED_CURRENT_SURVEY_RESULTS:
      return updateSurvey(state, action);
    case ActionTypes.AUTOSAVE_SURVEY:
      return onAutoSaveSurvey(state, action);
    case ActionTypes.LOADING_CURRENT_SURVEY:
    case ActionTypes.CLEARING_CURRENT_SURVEY_RESULTS:
    case ActionTypes.LOADING_SURVEY_STATUS_CHANGE:
    case ActionTypes.LOADING_LAST_SURVEY_RESULT:
    case ActionTypes.COMPLETING_SURVEY:
      return {
        ...state,
        currentSurvey: { ...state.currentSurvey, loading: true },
      };
    case ActionTypes.LOADED_CURRENT_SURVEY:
      return {
        ...state,
        currentSurvey: { ...action.payload.assessmentItem, loading: false },
      };
    case ActionTypes.SET_CURRENT_SURVEY:
      return {
        ...state,
        currentSurvey: action.payload.survey,
      };
    case ActionTypes.SET_ASSESSMENT_PRINT_HISTORY:
      return {
        ...state,
        assessmentPrintHistory: action.payload.history,
      };
    case ActionTypes.LOADED_ASSESSMENT_PRINT_HISTORY:
      return {
        ...state,
        assessmentPrintHistory: action.payload.assessmentPrintHistory,
      };
    case ActionTypes.DELETED_ASSESSMENT:
      return removeAssessment(state, action);
    default:
      return state;
  }
};

export const areAssessmentsLoading = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loading"], false));

export const areAssessmentsLoaded = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loaded"], false));

export const assessmentsLoadingError = curry(({ localState }, patientId) => get(localState, ["all", patientId, "error"], null));

export const getAssessmentsByPatientId = curry(({ localState }, patientId) => get(localState, ["all", patientId, "assessments"], []));

export const getCurrentAssessment = curry(({ localState }) => get(localState, ["current"], null));

export const isCurrentAssessmentLoading = curry(({ localState }) => get(localState, ["current", "loading"], false));

export const getAssessmentPrintHistory = curry(({ localState }) => get(localState, ["assessmentPrintHistory"], null));

export const getSurveyById = curry(({ localState }, surveyId) => {
  const surveyList = get(localState, ["current", "assessmentItems"], []);
  return find(surveyList, c => c.id === surveyId);
});

export const getSurveyListWithSetting = curry(({ localState, state }) => {
  const surveyList = get(localState, ["current", "assessmentItems"], []);
  const surveySettings = getSurveySettings(state);
  const surveyGroupSettings = getSurveyGroupSettings(state);

  const list = map(surveyList, survey => {
    const setting = find(surveySettings, item => item.surveyType === survey.surveyType);
    // TODO: this need to reduce in the future, only return the properties are needed to render list of surveys
    return {
      ...survey,
      groupName: setting ? setting.groupName : undefined,
      shortName: setting ? setting.shortName : "",
      displayName: setting ? setting.displayName : survey.surveyType,
      displayOrder: setting ? setting.defaultDisplayOrder : null,
    };
  });

  // group the survey list by their group name
  const groupedSurveyList = groupBy(list, "groupName");
  const surveyListWithSetting = [];

  // transform the list to required format (used in the render)
  forEach(groupedSurveyList, (value, key) => surveyListWithSetting.push({ groupName: key, items: orderBy(value, "displayOrder") }));

  // give order numbers to each group
  const surveyListWithSettingWithOrders = map(surveyListWithSetting, setting => {
    const match = find(surveyGroupSettings, order => order.groupName === setting.groupName);

    return {
      ...setting,
      name: match ? match.displayName : setting.groupName,
      orderNumber: match ? match.displayOrder : setting.items.displayOrder, // if survey groups is not defined in the group setting use its default display order
      isSingleItem: match ? match.isSingleItem : true,
    };
  });

  return orderBy(surveyListWithSettingWithOrders, "orderNumber");
});

export const isCurrentSurveyLoading = curry(({ localState }) => get(localState, ["currentSurvey", "loading"], false));

export const getCurrentSurvey = curry(({ localState, state }) => {
  const currentSurvey = get(localState, ["currentSurvey"], false);
  if (!currentSurvey) return null;
  const setting = getSurveySettingsBySurveyType(state, currentSurvey.surveyType);

  return {
    ...currentSurvey,
    loading: false,
    displayName: setting ? setting.displayName : currentSurvey.surveyType,
    groupName: setting ? setting.groupName : "",
    allowNotRequired: setting ? setting.allowNotRequired : false,
    allowPreFillFromLast: setting ? setting.allowPreFillFromLast : false,
  };
});

export const getPatientAssessmentList = curry(({ localState }, patientId) => get(localState, ["all", patientId, "assessments"], []));

export const getAssessmentByHeaderId = curry(({ localState }, patientId, assessmentHeaderId) => {
  const assessments = get(localState, ["all", patientId, "assessments"], []);
  return find(assessments, x => x.assessmentHeaderId === assessmentHeaderId) || {};
});

export const getDefaultPatientAssessment = curry(({ localState }, patientId, encounterType) => {
  const assessments = localState?.all[patientId]?.assessments;

  if (isEmpty(assessments)) {
    return "NewAssessment";
  }

  return find(assessments, x => x.type === encounterType && x.status === "InProgress")?.id ?? "NewAssessment";
});
