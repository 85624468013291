import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import { getMedicationsByPatientId, areMedicationsLoading, getErrorMessage } from "app/main/patients/reducers/medications.reducers";
import { fetchPatientMedications, editExistingMedication, recordNewMedication, removeMedication, fetchMedicationsById, recordMedication, editMedication } from "app/main/patients/actions/medications.actions";
import RecordMedicationForm from "app/main/patients/medications/components/record-medication-form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import withPermissions from "permissions/withPermissions";

class MedicationListContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    const { patientId } = this.props;
    if (patientId) {
      this.props.fetchPatientMedications(patientId);
    }
    this.onRecordMedication = this.onRecordMedication.bind(this);
    this.onEditMedication = this.onEditMedication.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = ({ medication }, { setErrors }) => {
    const apiCall = this.props.isEdit ? this.props.editMedication : this.props.recordMedication;

    apiCall(medication).then(responds => {
      if (responds.error !== true) {
        this.onClose();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  onClose = () => this.props.closeDialog();

  openRecordMedicationForm = (title, isEdit) => {
    this.props.openDialog({
      children: <RecordMedicationForm
        title={title}
        isEdit={isEdit}
        handleSubmit={this.handleSubmit}
      />,
    });
  }

  onRecordMedication = () => {
    this.props.recordNewMedication(this.props.patientId);
    this.openRecordMedicationForm("Add Medication");
  };

  onEditMedication = medicationId => {
    this.props.editExistingMedication(this.props.patientId, medicationId);
    this.openRecordMedicationForm("Edit Medication", true);
  };

  onRemoveMedication = medicationId => {
    this.props.removeMedication(this.props.patientId, medicationId);
    this.onClose();
  };

  render() {
    const {
      loading,
      error,
      medications,
      renderContent,
      hasPermissionMedicationsCreate,
      hasPermissionMedicationsUpdate,
      hasPermissionMedicationsDelete,
    } = this.props;

    return (
      renderContent({
        medications,
        onRecordMedication: hasPermissionMedicationsCreate ? this.onRecordMedication : null,
        onEditMedication: hasPermissionMedicationsUpdate ? this.onEditMedication : null,
        onRemoveMedication: hasPermissionMedicationsDelete ? this.onRemoveMedication : null,
        isLoading: loading,
        isError: error,
        isEmpty: isEmpty(medications),
      })
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { patientId } = ownProps;
  const medications = getMedicationsByPatientId(state, patientId);

  return {
    medications,
    error: getErrorMessage(state, patientId),
    loading: areMedicationsLoading(state, patientId),
  };
};

export default withPermissions(
  "MedicationsDelete",
  "MedicationsUpdate",
  "MedicationsCreate",
)(connect(
  mapStateToProps,
  {
    openDialog,
    closeDialog,
    fetchMedicationsById,
    fetchPatientMedications,
    recordNewMedication,
    editExistingMedication,
    removeMedication,
    recordMedication,
    editMedication,
  },
)(MedicationListContainer));
