import React from "react";
import { Typography } from "@material-ui/core";
import { ContextNoteItem, ContextItem} from "components/items/default-item";
import { ImmunisationIcon } from "helpers/icon-finder";
import normaliseDateTime from "app/main/patients/helpers/normalise-date-time";
import PatientContextListItem, { ItemContent } from "app/main/patients/components/patient-context-list-item";

const ImmunisationListItem = ({
  item: {
    vaccine,
    note,
    givenDate,
    indication,
  },
  ...other
}) => {
  const immunisationDate = normaliseDateTime(givenDate);

  return (
    <PatientContextListItem
      title={vaccine}
      {...other}
      renderItemContent={() => (
        <ItemContent
          title={vaccine}
          icon={<ImmunisationIcon />}
          content={<Typography variant="caption">{immunisationDate.date}</Typography>}
        />
      )}
      renderPreviewContent={() => (
        <>
          <ContextItem icon="timeline" content={indication} />
          <ContextItem icon="date_range" content={immunisationDate.date} />
          <ContextNoteItem content={note} />
        </>
      )}
    />
  );
};

export default ImmunisationListItem;
