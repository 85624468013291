import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import AccountSearchOutlineIcon from "mdi-react/AccountSearchOutlineIcon";

import DefaultButton from "components/items/default-button";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import PatientSearchForm from "app/main/patients/components/external-patient-search-button/patient-search-form";
import { getPatientLabel } from "utils/get-environment-variables";
import { showExternalPatientSearch } from "app/auth/store/reducers/system-configuration";

const patientLabel = getPatientLabel();

const PatientSearchButton = ({ onContinue, buttonProps }) => {
  const dispatch = useDispatch();
  const show = useSelector(showExternalPatientSearch); // possible move this to where we import it

  if (!show) return null;

  const openPatientSearchFrom = () => {
    dispatch(openDialog({
      maxWidth: "md",
      children: (
        <PatientSearchForm
          title={`Search ${patientLabel.plural}`}
          onClose={() => dispatch(closeDialog())}
          onContinue={onContinue}
        />
      ),
    }));
  };

  return (
    <DefaultButton
      label={`Search ${patientLabel.plural}`}
      icon={<AccountSearchOutlineIcon />}
      onClick={openPatientSearchFrom}
      {...buttonProps}
    />
  );
};

PatientSearchButton.propTypes = {
  onContinue: PropTypes.func.isRequired,
};

export default PatientSearchButton;
