import React from "react";
import { connect } from "react-redux";
import { Field } from "formik";

import { TextInput, NoteInput } from "components/inputs/text-input";
import { getCurrentImmunisation } from "app/main/patients/reducers/immunisations.reducers";
import { recordImmunisation, editImmunisation } from "app/main/patients/actions/immunisations.actions";
import { validateRequired } from "utils/validators";
import { DatePicker } from "components/inputs";
import Form from "components/form";

const immunisationDescription = "Immunisation Description";
const immunisationIndication = "Immunisation Indication";
const givenDate = "Date Given";
const immunisationDetails = "Details";

const validateDescription = validateRequired(immunisationDescription);
const validateGivenDate = validateRequired(givenDate);

class RecordImmunisationForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = ({ immunisation }, { setErrors }) => {
    const newImmunisation = { ...immunisation };
    const apiCall = this.props.isEdit ? this.props.editImmunisation : this.props.recordImmunisation;

    apiCall(newImmunisation).then(responds => {
      if (responds.error !== true) {
        this.props.onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  render() {
    const { initialValues, ...other } = this.props;

    return (
      <Form
        contentProps={other}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        content={() => (
          <>
            <Field
              name="immunisation.vaccine"
              icon="mode_comment"
              label={immunisationDescription}
              component={TextInput}
              validate={validateDescription}
            />
            <Field
              name="immunisation.indication"
              icon="timeline"
              label={immunisationIndication}
              component={TextInput}
            />
            <Field
              name="immunisation.givenDate"
              label={givenDate}
              component={DatePicker}
              validate={validateGivenDate}
            />
            <Field
              name="immunisation.note"
              label={immunisationDetails}
              component={NoteInput}
            />
          </>
        )}
      />
    );
  }
}

export default connect(state => {
  const immunisation = getCurrentImmunisation(state);

  return ({
    initialValues: { immunisation },
  });
}, {
  recordImmunisation,
  editImmunisation,
})(RecordImmunisationForm);
