import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { concat, map, isEmpty, some } from "lodash";
import { useDispatch } from "react-redux";
import { AutoComplete } from "components/inputs";
import DefaultButton from "components/items/default-button";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import EndorsementTypeForm from "./endorsement-type-form";

const useStyles = makeStyles(() => ({
  tag: {
    "& svg": {
      pointerEvents: "initial",
    },
  },
}));

const EndorsementTypeSelector = ({
  orgUnit,
  isDisabled,
  ...other
}) => {
  const dispatch = useDispatch();
  const onClose = () => dispatch(closeDialog());
  const { field: { name, value }, form: { setFieldValue } } = other;

  const addEndorsementType = ({ endorsementType }) => {
    if (!some(value, x => x.endorsedByType === endorsementType.endorsedByType && x.orgUnitId === endorsementType.orgUnitId)) {
      setFieldValue(name, isEmpty(value) ? [endorsementType] : concat(value, endorsementType));
    }
    onClose();
  };

  const onAddEndorsementType = () => {
    dispatch(openDialog({
      maxWidth: "xs",
      fullScreen: false,
      children: (
        <EndorsementTypeForm
          endorsementTypes={value}
          title="Add Endorsement Type"
          onSubmit={addEndorsementType}
          submitLabel="Add"
          orgUnit={orgUnit}
        />
      ),
    }));
  };

  const classes = useStyles();

  return (
    <div className="flex sm:flex-row sm:items-center flex-col">
      <AutoComplete
        className={clsx("flex-1", "isDisableLink", classes.tag)}
        options={map(value, x => ({ label: `${x.endorsedByTypeLabel} (${x.orgUnitName})`, value: x }))}
        getOptionSelected={(option, val) => option.value === val.value}
        multiple
        forcePopupIcon={false}
        disableClearable
        open={false}
        disabled={isDisabled}
        multiline
        {...other}
      />
      {!isDisabled && (
        <div className="sm:pl-8 pl-32 flex">
          <DefaultButton
            onClick={onAddEndorsementType}
            label="Add"
            edge="end"
          />
        </div>
      )}
    </div>
  );
};

export default EndorsementTypeSelector;
