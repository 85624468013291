import { get, concat, map, filter } from "lodash";

export function addItem(state, patientId, data, entity, addToEnd) {
  if (!data) return state;
  let arr = get(state.all, [patientId, entity], []);

  if (addToEnd) {
    arr = concat(arr, data);
  } else {
    arr = [data, ...arr];
  }

  return {
    ...state,
    all: {
      ...state.all,
      [patientId]: {
        ...state.all[patientId],
        [entity]: arr,
      },
    },
  };
}

export function removeItem(state, patientId, data, entity) {
  let arr = get(state.all, [patientId, entity], []);
  arr = filter(arr, x => x.id !== data.id);

  return {
    ...state,
    all: { ...state.all, [patientId]: { ...state.all[patientId], [entity]: arr } },
  };
}

export function updateItem(state, patientId, data, entity) {
  let arr = get(state.all, [patientId, entity], []);

  arr = map(arr, entry => {
    if (entry.id === data.id) {
      return data;
    }
    return entry;
  });
  return {
    ...state,
    all: { ...state.all, [patientId]: { ...state.all[patientId], [entity]: arr } },
  };
}
