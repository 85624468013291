import React from "react";
import { FormHelperText, FormControl, InputLabel, Input, LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { isEmpty, size } from "lodash";
import clsx from "clsx";
import IconComponent from "components/items/icon-component";
import checkIfEmpty from "helpers/check-if-empty";

const styles = theme => ({
  root: {
    "& > div": {
      height: "auto",
    },
  },
  input: {
    pointerEvents: "none",
  },
  helperText: {
    color: theme.palette.text.hint,
    display: "flex",
    minHeight: 20,
  },
  activeLabel: {
    color: theme.palette.text.primary,
  },
});

class BaseTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: props.defaultActive || false,
      maxLengthMessage: "",
    };
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onFocus() {
    const { onFocus } = this.props;

    if (onFocus) {
      onFocus();
    }
    this.setState({ isActive: true });
  }

  onBlur() {
    const { onBlur, defaultActive } = this.props;

    if (onBlur) {
      onBlur();
    }

    this.setState({ isActive: defaultActive || false });
  }

  onKeyDown(event) {
    const { maxLength, onKeyDown } = this.props;
    const excludingKeys = event.key !== "Backspace" && event.key !== "Delete" && event.key !== "Tab";

    if (size(event.target.value) >= maxLength && excludingKeys) {
      this.setState({ maxLengthMessage: `The maximum length of this field is ${maxLength} characters` });
    } else {
      this.setState({ maxLengthMessage: "" });
    }

    if (onKeyDown) {
      onKeyDown(event);
    }
  }

  render() {
    const {
      classes,
      icon,
      label,
      touched,
      error,
      inputComponent,
      value,
      textarea,
      renderExtraAction,
      showIcon = true,
      className,
      iconComponent,
      readOnly,
      defaultActive,
      loading,
      required,
      helperText,
      maxLength,
      renderInput,
      ...other
    } = this.props;
    const { isActive, maxLengthMessage } = this.state;
    const shrinkLabel = isActive || !checkIfEmpty(value);
    const hasError = !isEmpty(error) || !isEmpty(maxLengthMessage);

    return (
      <div className={clsx("flex flex-row", !textarea && "items-center h-72", className)}>
        {(showIcon || iconComponent || icon)
          && (
            <div className={clsx("flex mt-8 w-32", textarea && "mt-20")}>
              {iconComponent || <IconComponent icon={icon} size={24} />}
            </div>
          )}
        <FormControl className="flex-1" error={touched && hasError} required={required && checkIfEmpty(value)}>
          <InputLabel
            shrink={shrinkLabel}
            className={clsx(shrinkLabel && classes.activeLabel, "z-0")}
          >
            {label}
          </InputLabel>
          {renderInput ? <div className="MuiInputBase-root MuiInput-root z-1 MuiInput-underline MuiInputBase-formControl MuiInput-formControl">{renderInput}</div>
            : (
              <Input
                classes={{ root: clsx(classes.root, "z-1"), formControl: readOnly && classes.input }}
                inputComponent={inputComponent}
                value={value}
                readOnly={readOnly}
                inputProps={{ maxLength }}
                {...other}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onKeyDown={this.onKeyDown}
              />
            )}
          {loading && <LinearProgress />}
          <FormHelperText error={hasError} className={classes.helperText}>{(touched && error) || maxLengthMessage || helperText}</FormHelperText>
        </FormControl>
        {renderExtraAction && renderExtraAction}
      </div>
    );
  }
}

export default withStyles(styles)(BaseTextInput);
