import React from "react";
import PropTypes from "prop-types";
import { startCase } from "lodash";
import { red } from "@material-ui/core/colors";

import getAppointmentStatus from "app/main/patients/helpers/get-appointment-status";
import getEncounterStatus from "app/main/patients/helpers/get-encounter-status";
import DefaultItem from "components/items/default-item";

const overDueColor = red[900];

const statusBarBackgroundColor = (type, status, isOverdue) => {
  if (isOverdue) {
    return overDueColor;
  }

  if (type === "encounter") {
    return getEncounterStatus(status).color;
  }
  return getAppointmentStatus(status).color;
};

const EncounterStatus = ({ type, status, isOverdue }) => (
  <DefaultItem
    icon="fiber_manual_record"
    textColor={isOverdue && overDueColor}
    iconColor={statusBarBackgroundColor(type, status, isOverdue)}
    content={isOverdue ? "Overdue" : startCase(status)}
  />
);

EncounterStatus.propTypes = {
  status: PropTypes.string,
  type: PropTypes.string,
};

EncounterStatus.defaultProps = {
  type: "encounter",
};

export default EncounterStatus;
