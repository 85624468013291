import React from "react";
import { Typography, Box } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

import { PatientConsentStatus } from "app/main/patients/components/patient-details";
import DefaultListItem from "components/items/default-list-item";
import PatientNextContactLabel from "app/main/patients/encounters/components/patient-next-contact-label";

const defaultTextColor = grey[500];

const WorklistItem = ({
  active, index, onClick, title, enrolmentStatus, nextEncounter, assignedToUser,
}) => (
  <DefaultListItem
    key={index}
    onClick={onClick}
    active={active}
    content={(
      <>
        <div className="flex-row-container">
          <div className="flex-row-container">
            <Typography variant="body1" className="font-bold">{title}</Typography>
            {enrolmentStatus && <div className="ml-4"><PatientConsentStatus consent={enrolmentStatus} fontSize={10} /></div>}
          </div>
          {assignedToUser && (
            <Box className="enable-grow" fontSize={12} color={defaultTextColor} textAlign="right">
              {assignedToUser}
            </Box>
          )}
        </div>
        <div className="mt-16">
          <PatientNextContactLabel nextEncounter={nextEncounter} textColor={defaultTextColor} />
        </div>
      </>
    )}
  />
);

export default WorklistItem;
