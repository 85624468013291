import PatientAccessLogPatientRecordPage from "./pages/patient-access-log-patient-record-page";

const PatientReferralWorklistConfig = {
  routes: [
    {
      path: "/patientAccess/patients/:patientId",
      component: PatientAccessLogPatientRecordPage,
      exact: true,
    },
  ],
};

export default PatientReferralWorklistConfig;
