import React from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import { ButtonBase, Typography, Box } from "@material-ui/core";
import clsx from "clsx";
import IconComponent from "components/items/icon-component";

const useStyles = makeStyles(theme => (
  {
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
  },
  icon: ({ iconSize }) => ({
    borderRadius: (iconSize + 20) / 2,
    width: iconSize + 20,
    height: iconSize + 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: fade(theme.palette.grey[50], 0.5),
    marginBottom: theme.spacing(1),
    color: theme.palette.warning.main,
  }),
  text: ({ color }) => ({
    color: color || theme.palette.text.secondary,
  }),
}));

const WarningState = ({
  color,
  onClick,
  title,
  classes,
  iconSize = 48,
}) => {
  const className = useStyles({ iconSize, color });

  const EmptyStateContent = () => (
    <Box className={clsx(className.root, classes?.root)}>
      <Box className={className.icon}>
        <IconComponent size={iconSize} icon="warning" />
      </Box>
      {title && <Typography className={className.text}>{title}</Typography>}
    </Box>
  );

  if (onClick) {
    return (
      <ButtonBase disableRipple onClick={onClick}>
        <EmptyStateContent />
      </ButtonBase>
    );
  }

  return (
    <EmptyStateContent />
  );
};

export default WarningState;
