import React from "react";
import { Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { isEmpty } from "lodash";

import Form from "components/form";
import { UserSelector } from "components/inputs";
import { NoteInput } from "components/inputs/text-input";
import SelectInput from "components/inputs/select-input";
import RadioButtonsGroup from "components/inputs/radio-buttons-group";
import { RequestInfoIcon } from "helpers/icon-finder";
import { onSaveOrUpdateEndorsement } from "app/main/applications/actions/applications.actions";
import { getHelperTextBySectionName, getEndorsementDecisionValues } from "app/auth/store/reducers/system-configuration";
import useEndorsementTypes from "hooks/use-endorsement-types";

const userLabel = "User";
const decisionLabel = "Decision";
const moreInformationRequestedLabel = "More Information Requested";
const notesLabel = "Notes";
const type = "Type";

const schema = Yup.object().shape({
  endorsement: Yup.object().shape({
    endorsedByType: Yup.string()
      .required(`${type} is required`)
      .nullable(),
    endorsedUserId: Yup.string()
      .required(`${userLabel} is required`)
      .nullable(),
    decision: Yup.string()
      .required(`${decisionLabel} is required`)
      .nullable(),
    moreInformationRequested: Yup.string()
      .max(255, "Too Long!")
      .nullable(),
    notes: Yup.string()
      .max(4000, "Too Long!")
      .nullable(),
  }),
});

const EndorsementForm = ({
  applicationId,
  onSucceed,
  endorsement: defaultEndorsement,
  orgUnitId,
  ...other
}) => {
  const dispatch = useDispatch();
  const helperText = useSelector(state => getHelperTextBySectionName(state, "endorsements"));
  const decisionChoices = useSelector(getEndorsementDecisionValues);
  const { endorsementTypeOptions } = useEndorsementTypes();

  let { endorsement } = schema.cast();

  if (!isEmpty(defaultEndorsement)) {
    endorsement = {
      ...defaultEndorsement,
      endorsedUserId: {
        value: defaultEndorsement.endorsedUserId,
        label: defaultEndorsement.endorsedBy,
      },
    };
  }

  const handleSubmit = (data, { setErrors }) => {
    const { id, endorsedByType, endorsedUserId: endorsedBy, moreInformationRequested, notes, decision } = data.endorsement;

    const newEndorsement = {
      id,
      endorsedByType,
      endorsedBy: endorsedBy.label,
      endorsedUserId: endorsedBy.value,
      moreInformationRequested,
      notes,
      decision,
    };

    dispatch(onSaveOrUpdateEndorsement(applicationId, orgUnitId, newEndorsement)).then(response => {
      if (response.error !== true) {
        onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  return (
    <Form
      initialValues={{ endorsement }}
      onSubmit={handleSubmit}
      contentProps={other}
      validationSchema={schema}
      content={({ values }) => (
        <>
          <Field
            name="endorsement.endorsedByType"
            component={SelectInput}
            label={type}
            options={endorsementTypeOptions}
            disabled
            onChange={(value, change) => {
              change("endorsement.endorsedUserId", null);
            }}
          />
          <Field
            name="endorsement.endorsedUserId"
            component={UserSelector}
            label={userLabel}
            orgUnitId={orgUnitId}
            loadOptions={values.endorsement.endorsedByType != null}
            filteredEndorsedByType={values.endorsement.endorsedByType}
            icon="person"
            disabled
          />
          <Field
            name="endorsement.decision"
            component={RadioButtonsGroup}
            choices={decisionChoices}
            displayRow
            label={decisionLabel}
            required
            className="ml-32"
          />
          {values.endorsement.decision === "RequireMoreInformation" && (
          <Field
            name="endorsement.moreInformationRequested"
            component={NoteInput}
            label={moreInformationRequestedLabel}
            icon={<RequestInfoIcon />}
            maxLength={255}
            helperText={helperText.moreInformationRequested}
          />
          )}
          <Field
            name="endorsement.notes"
            component={NoteInput}
            label={notesLabel}
            maxLength={4000}
          />
        </>
      )}
    />
  );
};

export default EndorsementForm;
