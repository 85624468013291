import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";

import withPermissions from "permissions/withPermissions";
import ExpiredPrescriptionPanel from "app/main/applicationWorklist/components/expired-prescription-panel";
import ApplicationAppealPanel from "app/main/applicationWorklist/components/application-appeal-panel";
import ReportBackPanel from "app/main/applicationWorklist/components/report-back-panel";
import DecisionPanel from "app/main/applicationWorklist/components/decision-panel";
import TreatmentPanel from "app/main/applicationWorklist/components/treatment-panel";
import PatientPanel from "app/main/applicationWorklist/components/patient-panel";
import EndorsementPanel from "app/main/applicationWorklist/components/endorsement-panel";
import ApplicantConsultantPanel from "app/main/applicationWorklist/components/applicant-consultant-panel";
import InterestedPeoplePanel from "app/main/applicationWorklist/components/interested-people-panel";
import ResourcePanel from "app/main/applicationWorklist/components/resource-panel";
import AdditionalInformationPanel from "app/main/applicationWorklist/components/additional-information-panel";
import ApprovalRecommendationPanel from "app/main/applicationWorklist/components/approval-recommendation-panel";

const useStyles = makeStyles(theme => ({
  container: {
    "& > div:last-child": {
      marginBottom: theme.spacing(2),
    },
  },
}));

const ApplicationWorklistPanels = ({
  application,
  hasPermissionIPAReportBackView,
  hasPermissionIPAEndorsementView,
  hasPermissionIPAEndorsementEdit,
  hasPermissionIPADecisionView,
  hasPermissionIPAInterestedPeopleView,
  hasPermissionIPAInterestedPeopleEdit,
  hasPermissionIPAApplicantConsultantView,
  hasPermissionIPATreatmentView,
  hasPermissionIPATreatmentCreate,
  hasPermissionPatientsView,
  hasPermissionIPAPatientCreate,
  hasPermissionIPAResourceView,
  hasPermissionIPAResourceDelete,
  hasPermissionIPAAttachmentFilePreview,
  hasPermissionIPAAttachmentFileDownload,
  hasPermissionIPAAdditionalInformationView,
  hasPermissionIPACanSubmitOnBehalfOfOther,
  hasPermissionIPADecisionLetterCreate,
  hasPermissionIPACanEndorseAssignedApplications,
  hasPermissionIPACanEndorseOtherApplications,
  hasPermissionIPADecisionDelete,
  hasPermissionIPADecisionInternalInformationView,
  hasPermissionIPAResourceInternalInformationView,
  hasPermissionIPAApplicationAppealEdit,
  hasPermissionIPAApplicationAppealView,
  hasPermissionIPACanMarkRatifiedDecision,
  hasPermissionIPAApprovalRecommendationEdit,
  hasPermissionIPAApprovalRecommendationView,
  hasPermissionIPAApprovalRecommendationAssignee,
  hasPermissionIPAEndorsementRemoveAutomaticallyAdded,
  openDialog,
  closeDialog,
  onSaveApplication, // onSaveApplication is use to update the isNewApplication status only
  patientSetting,
  scrollItemToView,
}) => {
  const dialogFunc = {
    openDialog,
    closeDialog,
  };
  const {
    patient,
    applicant,
    consultant,
    interestedPeople,
    endorsements,
    decision,
    expiredPrescriptionPresentations,
    reportBacks,
    attachments,
    additionalInformation,
    assignment,
    applicationAppeals,
    approvalRecommendations,
    ...other
  } = application;

  const classes = useStyles();
  const { status, statusFlags } = application;
  const hideDecisionPanel = status === "Draft";
  const decisionIsMade = get(decision, "decisionDate", false);
  const {
    canEditTreatment,
    canEditPatient,
    canEditMainApplicationContent,
    canEditApplicant,
    canEditEndorsements,
    canEditDecision,
    canEditExpiredPrescriptionPresentations,
    canViewExpiredPrescriptionPresentations,
    canEditReportBacks,
    canUploadReportBacks,
    canUploadResources,
    canClone,
    canEditApplicationAppeals,
    canEditAdditionalInformation,
  } = statusFlags;

  return (
    <div className={classes.container}>
      {hasPermissionPatientsView && (
      <PatientPanel
        onSaveApplication={() => onSaveApplication()}
        patient={patient}
        openTreatmentFormOnSuccess={!application.requestedMedication && hasPermissionIPATreatmentView}
        canView={hasPermissionPatientsView}
        canEdit={canEditPatient}
        canCreate={hasPermissionIPAPatientCreate}
        patientSetting={patientSetting}
        {...other}
        {...dialogFunc}
      />
      )}
      {hasPermissionIPATreatmentView && (
        <TreatmentPanel
          onSaveApplication={() => onSaveApplication()}
          decision={decision}
          canView={hasPermissionIPATreatmentView}
          canEdit={canEditTreatment}
          canCreate={hasPermissionIPATreatmentCreate}
          application={application}
          {...other}
          {...dialogFunc}
        />
      )}
      {status !== "New" && (
        <>
          {hasPermissionIPAApplicantConsultantView && (
            <ApplicantConsultantPanel
              applicant={applicant}
              consultant={consultant}
              canView={hasPermissionIPAApplicantConsultantView}
              canEdit={canEditApplicant}
              canSuperEdit={hasPermissionIPACanSubmitOnBehalfOfOther}
              {...other}
              {...dialogFunc}
            />
          )}
          {hasPermissionIPAInterestedPeopleView && (
            <InterestedPeoplePanel
              interestedPeople={interestedPeople}
              canView={hasPermissionIPAInterestedPeopleView}
              canEdit={canEditMainApplicationContent && hasPermissionIPAInterestedPeopleEdit}
              {...other}
              {...dialogFunc}
            />
          )}
          {hasPermissionIPAResourceView && (
            <ResourcePanel
              attachments={attachments}
              canView={hasPermissionIPAResourceView}
              canEdit={canUploadResources}
              canRemove={hasPermissionIPAResourceDelete}
              canPreview={hasPermissionIPAAttachmentFilePreview}
              canDownload={hasPermissionIPAAttachmentFileDownload}
              canViewInternalSource={hasPermissionIPAResourceInternalInformationView}
              {...other}
              {...dialogFunc}
            />
          )}
          {hasPermissionIPAAdditionalInformationView && (
            <AdditionalInformationPanel
              additionalInformation={additionalInformation}
              canView={hasPermissionIPAAdditionalInformationView}
              canEdit={canEditAdditionalInformation}
              {...other}
              {...dialogFunc}
            />
          )}
          {hasPermissionIPAEndorsementView && (
            <EndorsementPanel
              endorsements={endorsements}
              canView={hasPermissionIPAEndorsementView}
              canEdit={canEditEndorsements && hasPermissionIPAEndorsementEdit}
              canEndorseAssigned={hasPermissionIPACanEndorseAssignedApplications}
              canEndorseOther={hasPermissionIPACanEndorseOtherApplications}
              canRemoveAutomaticallyAddedEndorsement={hasPermissionIPAEndorsementRemoveAutomaticallyAdded}
              {...other}
              {...dialogFunc}
            />
          )}
          {hasPermissionIPADecisionView && !hideDecisionPanel
            && (
              <DecisionPanel
                decision={decision}
                assignment={assignment}
                canEdit={canEditDecision}
                canView={hasPermissionIPADecisionView}
                canDownload={canEditDecision && hasPermissionIPADecisionLetterCreate}
                canDelete={canEditDecision && hasPermissionIPADecisionDelete}
                canViewInternalNotes={hasPermissionIPADecisionInternalInformationView}
                canMarkRatifiedDecision={hasPermissionIPACanMarkRatifiedDecision}
                {...other}
                {...dialogFunc}
              />
            )}
            {hasPermissionIPAApprovalRecommendationView
            && (
              <ApprovalRecommendationPanel
                approvalRecommendations={approvalRecommendations}
                canView={hasPermissionIPAApprovalRecommendationView}
                canEdit={hasPermissionIPAApprovalRecommendationEdit}
                canDelete={hasPermissionIPAApprovalRecommendationEdit}
                canBeAssigned={hasPermissionIPAApprovalRecommendationAssignee}
                {...other}
                {...dialogFunc}
              />
            )}
          {canViewExpiredPrescriptionPresentations
            && (
              <ExpiredPrescriptionPanel
                expiredPrescriptionPresentations={expiredPrescriptionPresentations}
                canView={canViewExpiredPrescriptionPresentations}
                canEdit={canEditExpiredPrescriptionPresentations}
                {...other}
                {...dialogFunc}
              />
            )}
          {decisionIsMade && (
            <>
              {hasPermissionIPAReportBackView && (
                <ReportBackPanel
                  reportBacks={reportBacks}
                  canView={hasPermissionIPAReportBackView}
                  canEdit={canEditReportBacks}
                  canUpload={canUploadReportBacks}
                  canCopy={canClone}
                  canAttachmentView={hasPermissionIPAResourceView}
                  scrollItemToView={scrollItemToView}
                  {...other}
                  {...dialogFunc}
                />
              )}
            </>
          )}
          {decisionIsMade && (
            <>
              {hasPermissionIPAApplicationAppealView && (
                <ApplicationAppealPanel
                  applicationAppeals={applicationAppeals}
                  canView={hasPermissionIPAApplicationAppealView}
                  canEdit={canEditApplicationAppeals && hasPermissionIPAApplicationAppealEdit}
                  {...other}
                  {...dialogFunc}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default withPermissions(
  "IPACompleteApplication",
  "IPAAddApplication",
  "IPAReportBackView",
  "IPAEndorsementView",
  "IPAEndorsementEdit",
  "IPADecisionView",
  "IPADecisionEdit",
  "IPAInterestedPeopleView",
  "IPAInterestedPeopleEdit",
  "IPAApplicantConsultantView",
  "IPAApplicantConsultantEdit",
  "IPATreatmentEdit",
  "IPATreatmentView",
  "IPATreatmentCreate",
  "IPAPatientEdit",
  "PatientsView",
  "IPAPatientCreate",
  "IPAResourceView",
  "IPAResourceDelete",
  "IPAAttachmentFilePreview",
  "IPAAttachmentFileDownload",
  "IPAAdditionalInformationView",
  "IPACanSubmitOnBehalfOfOther",
  "IPADecisionLetterCreate",
  "IPACanEndorseAssignedApplications",
  "IPACanEndorseOtherApplications",
  "IPACanViewOtherUserApplications",
  "IPADecisionDelete",
  "IPADecisionInternalInformationView",
  "IPAResourceInternalInformationView",
  "IPACloneApplication",
  "IPAApplicationAppealEdit",
  "IPAApplicationAppealView",
  "IPACanMarkRatifiedDecision",
  "IPAApprovalRecommendationView",
  "IPAApprovalRecommendationEdit",
  "IPAApprovalRecommendationAssignee",
  "IPAEndorsementRemoveAutomaticallyAdded",
)(ApplicationWorklistPanels);
