import React from "react";
import * as Yup from "yup";
import { Field } from "formik";
import { map } from "lodash";

import Form from "components/form";
import { TextInputField } from "components/inputs/text-input";

const NameLabel = "Name";

const schema = Yup.object().shape({
  name: Yup.string().trim().required(`${NameLabel} is required`).nullable(),
});

const SubSpecialtyForm = ({
  subSpecialties,
  subSpecialty,
  fieldName,
  setItems,
  isNewItem,
  onSucceed,
  ...other
}) => {
  // in case this form is use in different cases -> e.g. create
  let initialValues = schema.cast();

  if (subSpecialty) {
    initialValues = {
      name: subSpecialty?.label,
    };
  }

  const handleSubmit = ({ name }) => {
    const newItems = map(subSpecialties, x => {
      if (x.value === subSpecialty.value) {
        // if its new added item, replace the value as well as the label
        if (isNewItem) {
          return { value: name, label: name };
        }
        // else means its already existed in the database, so we only need to update the label
        return { value: x.value, label: name };
      }
      return x;
    });
    setItems(newItems);
    onSucceed();
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      contentProps={other}
      validationSchema={schema}
      content={() => (
        <Field
          name="name"
          component={TextInputField}
          label={NameLabel}
          showIcon={false}
        />
      )}
    />
  );
};

export default SubSpecialtyForm;
