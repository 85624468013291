import { ApiActions } from "middleware/call-api";
import { ActionTypes, isApplicationHistoryLoading, isApplicationHistoryLoaded } from "../reducers/applicationHistory.reducers";

export const loadApplicationHistory = (applicationId, orgUnitId, forceLoad = false) => dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/history`,
    method: "GET",
    bailout: state => isApplicationHistoryLoading(state, applicationId) || (!forceLoad && isApplicationHistoryLoaded(state, applicationId)),
    applicationId,
    types: [
      ActionTypes.LOADING_APPLICATION_HISTORY,
      ActionTypes.LOADED_APPLICATION_HISTORY,
      ActionTypes.ERROR_LOADING_APPLICATION_HISTORY,
    ],
  },
});
