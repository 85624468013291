import React from "react";
import { every, filter, map, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import BookmarkRemoveIcon from "mdi-react/BookmarkRemoveIcon";
import RedoVariantIcon from "mdi-react/RedoVariantIcon";
import UndoVariantIcon from "mdi-react/UndoVariantIcon";

import DefaultTabBar from "components/items/default-tab-bar";
import DefaultButton from "components/items/default-button";
import UpdateApplicationStatusForm from "app/main/applications/components/update-application-status-form";
import { showMessage } from "app/store/actions/message.actions";
import ConfirmationDialog from "components/items/confirmation-dialog";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { onWithdrawApplication, onCompleteApplication, placeApplicationInDraft, reinstateApplication } from "app/main/applications/actions/applications.actions";
import { getRequiredSections } from "app/main/applications/reducers/applications.reducers";
import { getHelperTextBySectionName } from "app/auth/store/reducers/system-configuration";

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
}));

const ApplicationTabBar = ({
  application,
  hasPermissionIPACompleteApplication,
  discardNewApplication,
  updateApplicationStatus,
  currentTab,
  onChange,
  tabs,
  orgUnitId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { statusFlags, isReportBackApplication } = application;

  const requiredSections = useSelector(state => getRequiredSections(state, isReportBackApplication));

  const { canEdit, canReinstate, canWithdraw } = statusFlags;
  const showCompleteButton = application.status === "New" || application.status === "Draft" || application.status === "AwaitingInformation";

  const helperText = useSelector(state => getHelperTextBySectionName(state, "application"));

  const onClose = () => dispatch(closeDialog());

  const onWithdraw = () => {
    dispatch(openDialog({
      children: (
        <UpdateApplicationStatusForm
          title="Withdraw Application"
          onSucceed={() => onClose()}
          onSubmit={onWithdrawApplication}
          applicationId={application.id}
          helperText={helperText.withdraw}
          submitLabel="Withdraw"
          orgUnitId={orgUnitId}
        />
      ),
    }));
  };

  const onReInstate = () => {
    dispatch(openDialog({
      maxWidth: "xs",
      fullScreen: false,
      children: (
        <UpdateApplicationStatusForm
          title="Reinstate this Application"
          onSucceed={() => onClose()}
          onSubmit={reinstateApplication}
          applicationId={application.id}
          helperText="Reinstate this Application?"
          submitLabel="Reinstate"
          orgUnitId={orgUnitId}
        />
      ),
    }));
  };

  const WithdrawButton = () => (
    <DefaultButton
      icon={<UndoVariantIcon />}
      label="Withdraw"
      color="default"
      variant="text"
      onClick={() => onWithdraw()}
    />
  );

  const onSubmit = () => {
    // check if all the required sections have been completed
    const isComplete = !isEmpty(requiredSections) && every(requiredSections, x => x.complete);

    if (isComplete) {
      dispatch(openDialog({
        maxWidth: "xs",
        fullScreen: false,
        children: (
          <ConfirmationDialog
            confirmLabel="Submit"
            onCancel={onClose}
            onConfirm={() => {
              dispatch(onCompleteApplication(application.id, orgUnitId)).then(response => {
                if (response.error === true) {
                  dispatch(showMessage({ message: response.payload.exceptionMessage || "An error has occurred", variant: "error" }));
                }

                updateApplicationStatus(false);
                onClose();
              });
            }}
            title={<div className="text-center">Submit this Application?</div>}
            content={helperText.submit}
          />
        ),
      }));
    } else {
      const inCompleteSections = filter(requiredSections, x => x.complete === false && x.visible);
      dispatch(showMessage({
        title: "Please complete the following before submitting the application",
        variant: "warning",
        message: map(inCompleteSections, x => (
          <React.Fragment key={x.name}>
            <div className="mt-4">{x.message}</div>
            {map(x.fieldValidationErrors, fieldValidationError => <div key={fieldValidationError} className="mt-4 ml-16">{fieldValidationError}</div>)}
          </React.Fragment>
        )),
      }));
    }
  };

  const onEdit = () => {
    dispatch(openDialog({
      maxWidth: "xs",
      fullScreen: false,
      children: (
        <ConfirmationDialog
          onCancel={onClose}
          onConfirm={() => {
            dispatch(placeApplicationInDraft(application.id, orgUnitId)).then(response => {
              if (response.error === true) {
                dispatch(showMessage({ message: response.payload.exceptionMessage || "An error has occurred", variant: "error" }));
              }
              onClose();
            });
          }}
          title={<div className="text-center">Would you like to edit this Application?</div>}
        />
      ),
    }));
  };

  return (
    <DefaultTabBar
      tabs={tabs}
      currentTab={currentTab}
      onChange={onChange}
      rightContent={(
        <div className={classes.buttonContainer}>
          {canReinstate && (
            <DefaultButton
              icon={<RedoVariantIcon />}
              label="Reinstate"
              color="default"
              variant="text"
              onClick={() => onReInstate()}
            />
          )}
          {showCompleteButton
            ? (
              <>
                {application.status === "New"
                  && (
                    <DefaultButton
                      icon={<BookmarkRemoveIcon />}
                      label="Discard"
                      color="default"
                      variant="text"
                      onClick={() => discardNewApplication()}
                    />
                  )}
                {canWithdraw && <WithdrawButton />}
                {hasPermissionIPACompleteApplication
                  && (
                    <DefaultButton
                      icon="check"
                      label="Submit"
                      onClick={() => onSubmit()}
                    />
                  )}
              </>
            ) : (
              <>
                {canWithdraw && <WithdrawButton />}
                {canEdit
                  && (
                    <DefaultButton
                      icon="edit"
                      label="Edit"
                      onClick={() => onEdit()}
                    />
                  )}
              </>
            )}
        </div>
      )}
    />
  );
};

export default ApplicationTabBar;
