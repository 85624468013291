import React from "react";
import IconButton from "components/items/icon-button";
import DownloadIcon from "mdi-react/DownloadIcon";
import ImageSearchOutlineIcon from "mdi-react/ImageSearchOutlineIcon";
import FileFindOutlineIcon from "mdi-react/FileFindOutlineIcon";

export const PreviewImageButton = (onClick, loading) => (
  <IconButton
    title="Preview Image"
    iconSize={24}
    icon={<ImageSearchOutlineIcon />}
    loading={loading}
    onClick={onClick}
  />
);

export const PreviewPdfButton = (onClick, loading, title) => (
  <IconButton
    title={title ?? "Preview PDF"}
    iconSize={24}
    icon={<FileFindOutlineIcon />}
    loading={loading}
    onClick={onClick}
  />
);

export const DownloadButton = (onClick, loading) => (
  <IconButton
    title="Download File"
    iconSize={24}
    icon={<DownloadIcon />}
    loading={loading}
    onClick={onClick}
  />
);
