import React from "react";
import { Redirect } from "react-router-dom";
import { WorklistConfig } from "app/main/worklist/WorklistConfig";
import ReferralWorklistConfig from "app/main/referralWorklist/ReferralWorklistConfig";
import UserConfig from "app/main/users/UserConfig";
import { PatientsRouteConfig } from "app/main/patients/PatientsRouteConfig";
import { CandidatesRouteConfig } from "app/main/candidates/CandidatesRouteConfig";
import { CandidateWorklistConfig } from "app/main/candidateWorklist/CandidateWorklistConfig";
import ApplicationWorklistConfig from "app/main/applicationWorklist/ApplicationWorklistConfig";
import PatientReferralWorklistConfig from "app/main/patientReferralWorklist/PaitentReferralWorklistConfig";
import ExternalDocumentWorklistConfig from "app/main/externalDocumentWorklist/ExternalDocumentWorklistConfig";
import PatientMergeLogConfig from "app/main/patientMergeLog/PatientMergeLogConfig";
import OrgUnitConfig from "app/main/orgUnits/OrgUnitConfig";
import AdminConfig from "app/main/admin/AdminConfig";
import PatientAccessLogConfig from "app/main/patientAccessLog/PatientAccessLogConfig";
import CodeSetConfig from "app/main/codeSet/CodeSetConfig";

import { getDefaultRoute } from "utils/get-environment-variables";

const generateRoutesFromConfigs = configs => {
  let allRoutes = [];
  configs.forEach(config => {
    allRoutes = [
      ...allRoutes,
      ...config.routes,
    ];
  });
  return allRoutes;
};

const routeConfigs = [
  UserConfig,
  WorklistConfig,
  PatientsRouteConfig,
  ReferralWorklistConfig,
  CandidatesRouteConfig,
  CandidateWorklistConfig,
  ApplicationWorklistConfig,
  PatientReferralWorklistConfig,
  ExternalDocumentWorklistConfig,
  PatientMergeLogConfig,
  OrgUnitConfig,
  AdminConfig,
  PatientAccessLogConfig,
  CodeSetConfig,
];

const DEFAULT_ROUTE = getDefaultRoute();

const routes = [
  ...generateRoutesFromConfigs(routeConfigs),
  {
    path: "/",
    component: () => <Redirect to={DEFAULT_ROUTE || ""} />,
  },
];

export default routes;
