import { get, find, includes, mapKeys, map, concat, mapValues, isEmpty, filter, flattenDeep, values, lowerCase, size, head, some } from "lodash";
import moment from "moment";
import { formatUtcDate } from "helpers/format-date-time";

import curryConnector from "utils/connectors";
import { ActionTypes as ReferralWorkListActionTypes } from "app/main/referralWorklist/reducers/referralWorklist.reducers";
import { ActionTypes as PatientActionTypes } from "app/main/patients/reducers/patients.reducers";
import { ActionTypes as ExternalDocumentActionTypes } from "app/main/externalDocumentWorklist/reducers/externalDocumentWorklist.reducers";

export const REFERRALS_STATE_KEY = "referrals";
export const REFERRALS_PAGE_SIZE = 50;
export const REFERRALS_PAGE_SIZE_OPTIONS = [5, 10, 25, 50];

const curry = fn => curryConnector(fn, REFERRALS_STATE_KEY);

export const ActionTypes = {
  LOADING_REFERRALS: "LOADING_REFERRALS",
  LOADED_REFERRALS: "LOADED_REFERRALS",
  ERROR_LOADING_REFERRALS: "ERROR_LOADING_REFERRALS",

  ACCEPTED_REFERRAL: "ACCEPTED_REFERRAL",
  REJECTED_REFERRAL: "REJECTED_REFERRAL",
  COMPLETED_REFERRAL: "COMPLETED_REFERRAL",
  ARCHIVED_REFERRAL: "ARCHIVED_REFERRAL",
  UNDONE_REFERRAL_ACTION: "UNDONE_REFERRAL_ACTION",
  LOADED_PRINT_REFERRAL: "LOADED_PRINT_REFERRAL",
  REQUESTED_MORE_INFO: "REQUESTED_MORE_INFO",
  CANCELLED_REFERRAL: "CANCELLED_REFERRAL",

  LOADED_REFERRAL_REPORT: "LOADED_REFERRAL_REPORT",
  LOADING_REFERRAL_REPORT: "LOADING_REFERRAL_REPORT",
  ERROR_LOADING_REFERRAL_REPORT: "LOADING_REFERRAL_REPORT",

  SAVED_REFERRAL_ASSIGNED_USER: "SAVED_REFERRAL_ASSIGNED_USER",

  LOADED_REFERRAL: "LOADED_REFERRAL",
  LOADING_REFERRAL: "LOADING_REFERRAL",
  ERROR_LOADING_REFERRAL: "ERROR_LOADING_REFERRAL",

  LOADED_REFERRAL_ATTACHMENTS: "LOADED_REFERRAL_ATTACHMENTS",
  LOADING_REFERRAL_ATTACHMENTS: "LOADING_REFERRAL_ATTACHMENTS",
  ERROR_LOADING_REFERRAL_ATTACHMENTS: "ERROR_LOADING_REFERRAL_ATTACHMENTS",
  LOADING_REFERRAL_ATTACHMENT_FILE: "LOADING_REFERRAL_ATTACHMENT_FILE",
  LOADED_REFERRAL_ATTACHMENT_FILE: "LOADED_REFERRAL_ATTACHMENT_FILE",
  ERROR_LOADING_REFERRAL_ATTACHMENT_FILE: "ERROR_LOADING_REFERRAL_ATTACHMENT_FILE",
  CREATE_MANUAL_REFERRAL: "CREATE_MANUAL_REFERRAL",
  EDIT_MANUAL_REFERRAL: "EDIT_MANUAL_REFERRAL",

  LOADING_ASSIGNMENT_OPTIONS: "LOADING_ASSIGNMENT_OPTIONS",
  LOADED_ASSIGNMENT_OPTIONS: "LOADED_ASSIGNMENT_OPTIONS",
  ERROR_LOADING_ASSIGNMENT_OPTIONS: "ERROR_LOADING_ASSIGNMENT_OPTIONS",

  ADDED_REFERRAL_NOTE: "ADDED_REFERRAL_NOTE",
  SAVED_REFERRAL_NOTE: "SAVED_REFERRAL_NOTE",
  DELETED_REFERRAL_NOTE: "DELETED_REFERRAL_NOTE",

  ADDED_REFERRAL_CORRESPONDENCE: "ADDED_REFERRAL_CORRESPONDENCE",
  LOADED_REFERRAL_PDF_PREVIEW: "LOADED_REFERRAL_PDF_PREVIEW",
  LOADING_REFERRAL_PDF_PREVIEW: "LOADING_REFERRAL_PDF_PREVIEW",
  ERROR_LOADING_REFERRAL_PDF_PREVIEW: "ERROR_LOADING_REFERRAL_PDF_PREVIEW",
  LOADED_CURRENT_REFERRAL: "LOADED_CURRENT_REFERRAL",

  CREATING_SOURCE_DOCUMENT_FILE: "CREATING_SOURCE_DOCUMENT_FILE",
  CREATED_SOURCE_DOCUMENT_FILE: "CREATED_SOURCE_DOCUMENT_FILE",
  CLEAR_SOURCE_DOCUMENT_FILES: "CLEAR_SOURCE_DOCUMENT_FILES",
  LOADED_REFERRAL_APPOINTMENTS: "LOADED_REFERRAL_APPOINTMENTS",
  LOADING_REFERRAL_APPOINTMENTS: "LOADING_REFERRAL_APPOINTMENTS",
  ERROR_LOADING_REFERRAL_APPOINTMENTS: "ERROR_LOADING_REFERRAL_APPOINTMENTS",

  LOADED_REFERRAL_SOURCE_DOCUMENT: "LOADED_REFERRAL_SOURCE_DOCUMENT",
  LOADING_REFERRAL_SOURCE_DOCUMENT: "LOADING_REFERRAL_SOURCE_DOCUMENT",
  ERROR_LOADING_REFERRAL_SOURCE_DOCUMENT: "ERROR_LOADING_REFERRAL_SOURCE_DOCUMENT",

  LOADED_REFERRAL_SOURCE_DOCUMENT_FILE_DATA: "LOADED_REFERRAL_SOURCE_DOCUMENT_FILE_DATA",
  LOADING_REFERRAL_ACCESS_LOG: "LOADING_REFERRAL_ACCESS_LOG",
  LOADED_REFERRAL_ACCESS_LOG: "LOADED_REFERRAL_ACCESS_LOG",
  ERROR_LOADING_REFERRAL_ACCESS_LOG: "ERROR_LOADING_REFERRAL_ACCESS_LOG",

  LOADING_EXISTING_SOURCE_DOCUMENT_FILE: "LOADING_EXISTING_SOURCE_DOCUMENT_FILE",
  LOADED_EXISTING_SOURCE_DOCUMENT_FILE: "LOADED_EXISTING_SOURCE_DOCUMENT_FILE",
  RESET_CURRENT_REFERRAL: "RESET_CURRENT_REFERRAL",

  CREATED_REFERRAL_TASK: "CREATED_REFERRAL_TASK",
  SAVED_REFERRAL_TASK: "SAVED_REFERRAL_TASK",
  DELETED_REFERRAL_TASK: "DELETED_REFERRAL_TASK",
};

function updatePatientIdentifiers(patient, patientIdentifier) {
  let updated = false;

  if (!isEmpty(patient) && patient.patientId === patientIdentifier.patientId) {
    const patientIdentifiers = map(patient.patientIdentifiers, item => {
      if (item.patientIdentifierId === patientIdentifier.patientIdentifierId) {
        updated = true;
        return patientIdentifier;
      }
      return item;
    });
    return {
      ...patient,
      patientIdentifiers: updated ? patientIdentifiers : concat(patientIdentifiers, patientIdentifier),
    };
  }
  return patient;
}

function removePatientIdentifiers(patient, patientIdentifier) {
  if (!isEmpty(patient) && patient.patientId === patientIdentifier.patientId) {
    return {
      ...patient,
      patientIdentifiers: filter(patient.patientIdentifiers, item => item.patientIdentifierId !== patientIdentifier.patientIdentifierId),
    };
  }
  return patient;
}

function updateAttachmentFile(file, payload) {
  let updatedFile = file;
  if (!isEmpty(file) && file.id === payload.fileId) {
    updatedFile = {
      ...updatedFile,
      base64File: payload.base64File,
      mimeType: payload.mimeType,
      htmlPreview: payload.htmlPreview,
    };
  }

  return updatedFile;
}

const displayDateFormat = date => {
  const sortDateFormat = "DD-MMM HH:mm";
  const longDateFormat = "DD-MMM-YY HH:mm";

  return moment(date).isSame(moment(), "year") ? sortDateFormat : longDateFormat;
};

function normaliseActivity(activity) {
  if (!isEmpty(activity)) {
    const createdDate = get(activity, "actionDateTimeUtc", null) || get(activity, "addedDateTimeUtc", null) || get(activity, "assignedDateTimeUtc", null);
    const editDate = get(activity, "lastEditedDateTimeUtc", null);

    return {
      ...activity,
      createdDateLocalised: isEmpty(createdDate) ? createdDate : formatUtcDate(createdDate, displayDateFormat(createdDate)),
      editDateLocalised: isEmpty(editDate) ? editDate : formatUtcDate(editDate, displayDateFormat(editDate)),
    };
  }
  return null;
}

function normaliseSortedActivity(activity) {
  const normalisedActivity = { ...activity, sortOrder: moment(activity.sortDateTimeUtc).format("X") };
  delete normalisedActivity.sortDateTimeUtc;
  return normalisedActivity;
}

const updateActivityActions = (activities, activity) => ({
  ...activities,
  action: concat(normaliseActivity(activity), activities.action),
  sortedActivities: concat(normaliseSortedActivity({
    id: activity.id,
    type: "Action",
    sortDateTimeUtc: activity.actionDateTimeUtc,
  }), activities.sortedActivities),
});

const setCurrentPDFPreview = (state, payload, loading) => {
  const preview = state.current.id === payload.referralId ? payload.data : null;

  return {
    ...state,
    current: {
      ...state.current,
      pdfPreview: {
        data: preview,
      },
    },
    meta: {
      ...state.meta,
      loadingPdfPreview: loading,
      errorLoadingPdfPreview: payload.message,
    },
  };
};

const loadExistingSourceDocument = (state, action) => {
  let sourceDocument = state.current.referralSourceDocument;

  if (sourceDocument.id === action.payload.sourceDocumentId) {
    sourceDocument = {
      ...state.current.referralSourceDocument,
      ...action.payload,
      id: action.payload.sourceDocumentId,
      fileId: action.payload.id,
    };
  }

  return {
    ...state,
    current: {
      ...state.current,
      referralSourceDocument: sourceDocument,
    },
    referralSourceDocumentFiles: {
      loading: false,
      loaded: true,
      all: concat(
        [action.payload],
        filter(state.referralSourceDocumentFiles?.all, x => x.id !== action.payload?.id),
      ),
    },
    meta: {
      ...state.meta,
      loadingCurrentSourceDocument: false,
      loadedCurrentSourceDocument: true,
      errorLoadingCurrentSourceDocument: null,
    },
  };
};

const updateReferralTasks = (state, action) => {
  const tasks = map(state.current.activities.task, x => (x.id === action.payload?.id ? normaliseActivity(action.payload) : x));

  return {
    ...state,
    all: {
      ...state.all,
      [action.payload.referralId]: {
        ...state.all[action.payload.referralId],
        hasIncompleteTasks: some(tasks, x => x.completedDateTimeUtc === null),
      },
    },
    current: {
      ...state.current,
      activities: {
        ...state.current.activities,
        task: tasks,
      },
    },
  };
};

const removeReferralTask = (state, action) => {
  const tasks = filter(state.current.activities.task, x => x.referralTaskId !== action.payload.referralTaskId);

  return {
    ...state,
    all: {
      ...state.all,
      [action.payload.referralId]: {
        ...state.all[action.payload.referralId],
        hasIncompleteTasks: some(tasks, x => x.completedDateTimeUtc === null),
      },
    },
    current: {
      ...state.current,
      activities: {
        ...state.current.activities,
        task: tasks,
      },
    },
  };
};

const referralSourceDocumentFilesState = {
  all: null,
  loading: false,
  loaded: false,
};

const INITIAL_STATE = {
  all: {},
  pages: {},
  pageInfo: { pageNumber: 1, pageSize: REFERRALS_PAGE_SIZE, totalRecords: 0 },
  searchParams: {},
  referralsForWorkList: [],
  assignmentOptions: {
    loading: false,
    loaded: false,
    error: null,
    all: null,
  },
  current: null,
  meta: {
    loading: false,
    error: null,
    loadingReports: true,
    errorLoadingReports: null,
    loadingAttachments: true,
    errorLoadingAttachments: null,
    loadingPdfPreview: true,
    errorLoadingPdfPreview: null,
    loadingAppointments: false,
    loadedAppointments: false,
    errorLoadingAppointments: null,
    loadingCurrentSourceDocument: false,
    loadedCurrentSourceDocument: false,
    errorLoadingCurrentSourceDocument: null,
  },
  referralSourceDocumentFiles: referralSourceDocumentFilesState,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReferralWorkListActionTypes.SEARCHING_REFERRALS:
      return {
        ...state,
        referralsForWorkList: (action.payload.pageNumber === 1) ? [] : state.referralsForWorkList,
      };
    case ReferralWorkListActionTypes.SEARCHED_REFERRALS:
      return {
        ...state,
        all: { ...state.all, ...mapKeys(action.payload.referrals, x => x.id) },
        pages: { ...state.pages, [action.payload.pageInfo.pageNumber]: { loading: false, ids: map(action.payload.referrals, x => x.id) } },
        pageInfo: action.payload.pageInfo,
        referralsForWorkList: (action.payload.pageInfo.pageNumber === 1) ? map(action.payload.referrals, x => x.id) : concat(state.referralsForWorkList, map(action.payload.referrals, x => x.id)),
      };
    /**
    * options for assign to form, TODO: this might need to improve
    */
    case ActionTypes.LOADING_ASSIGNMENT_OPTIONS:
      return {
        ...state,
        assignmentOptions: { ...state.assignmentOptions, loading: true, loaded: false },
      };
    case ActionTypes.ERROR_LOADING_ASSIGNMENT_OPTIONS:
      return {
        ...state,
        assignmentOptions: {
          ...state.assignmentOptions,
          loading: false,
          error: action.payload.message,
        },
      };
    case ActionTypes.LOADED_ASSIGNMENT_OPTIONS:
      return {
        ...state,
        assignmentOptions: {
          ...state.assignmentOptions,
          all: action.payload.assignmentOptions,
          loading: false,
          loaded: true,
          error: null,
        },
      };
    /**
    * getting referral source document data
    */
    case ActionTypes.LOADING_REFERRAL_SOURCE_DOCUMENT:
      return {
        ...state,
        currentSourceDocument: { ...state.currentSourceDocument, loading: true, loaded: false },
      };
    case ActionTypes.ERROR_LOADING_REFERRAL_SOURCE_DOCUMENT:
      return {
        ...state,
        currentSourceDocument: {
          ...state.currentSourceDocument,
          loading: false,
          error: action.payload.message,
        },
      };
    case ActionTypes.LOADED_REFERRAL_SOURCE_DOCUMENT:
      return {
        ...state,
        currentSourceDocument: {
          base64File: action.payload.document,
          loading: false,
          loaded: true,
          error: null,
        },
      };
    /**
    * Loading single referral
    */
    case ActionTypes.LOADING_REFERRAL:
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: true,
        },
      };
    case ActionTypes.LOADED_REFERRAL:
      return {
        ...state,
        current: {
          ...action.payload.referral,
          activities: {
            action: map(action.payload.actions, x => normaliseActivity(x)),
            note: map(action.payload.notes, x => normaliseActivity(x)),
            correspondence: map(action.payload.correspondence, x => normaliseActivity(x)),
            assignment: map(action.payload.assignments, x => normaliseActivity(x)),
            task: map(action.payload.tasks, x => normaliseActivity(x)),
            sortedActivities: map(action.payload.sortedActivities, x => normaliseSortedActivity(x)),
          },
        },
        meta: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case ActionTypes.ERROR_LOADING_REFERRAL:
      return {
        ...state,
        meta: {
          loading: false,
          loaded: false,
          error: action.payload.message,
        },
      };
    /**
     * Current referral Detail tab
     */
    case ActionTypes.LOADING_REFERRAL_REPORT:
      return {
        ...state,
        meta: {
          ...state.meta,
          loadingReports: true,
        },
      };
    case ActionTypes.LOADED_REFERRAL_REPORT:
      return {
        ...state,
        current: {
          ...state.current,
          reports: action.payload.reports,
        },
        meta: {
          ...state.meta,
          loadingReports: false,
          errorLoadingReports: null,
        },
      };
    case ActionTypes.ERROR_LOADING_REFERRAL_REPORT:
      return {
        ...state,
        meta: {
          ...state.meta,
          loadingReports: false,
          errorLoadingReports: action.payload.message,
        },
      };
    /**
     * Current referral Attachment tab
     */
    case ActionTypes.LOADING_REFERRAL_ATTACHMENTS:
      return {
        ...state,
        meta: {
          ...state.meta,
          loadingAttachments: true,
        },
      };
    case ActionTypes.LOADED_REFERRAL_ATTACHMENTS:
      return {
        ...state,
        current: {
          ...state.current,
          attachments: {
            ...mapKeys(action.payload.attachments, x => x.referralDocumentId),
          },
        },
        meta: {
          ...state.meta,
          loadingAttachments: false,
          errorLoadingAttachments: null,
        },
      };
    case ActionTypes.ERROR_LOADING_REFERRAL_ATTACHMENTS:
      return {
        ...state,
        meta: {
          ...state.meta,
          loadingAttachments: false,
          errorLoadingAttachments: action.payload.message,
        },
      };
    case ActionTypes.LOADING_REFERRAL_ATTACHMENT_FILE:
      return {
        ...state,
        current: {
          ...state.current,
          attachments: {
            ...state.current.attachments,
            [action.payload.referralDocumentId]: {
              ...state.current.attachments[action.payload.referralDocumentId],
              isPreviewLoading: true,
              error: false,
            },
          },
        },
      };
    case ActionTypes.ERROR_LOADING_REFERRAL_ATTACHMENT_FILE:
      return {
        ...state,
        current: {
          ...state.current,
          attachments: {
            ...state.current.attachments,
            [action.payload.referralDocumentId]: {
              ...state.current.attachments[action.payload.referralDocumentId],
              isPreviewLoading: false,
              error: action.payload.message,
            },
          },
        },
      };
    case ActionTypes.LOADED_REFERRAL_ATTACHMENT_FILE:
      return {
        ...state,
        current: {
          ...state.current,
          attachments: {
            ...state.current.attachments,
            [action.payload.referralDocumentId]: {
              ...state.current.attachments[action.payload.referralDocumentId],
              files: {
                ...state.current.attachments[action.payload.referralDocumentId].files,
                ...mapValues(state.current.attachments[action.payload.referralDocumentId].files, file => updateAttachmentFile(file, action.payload)),
              },
              isPreviewLoading: false,
            },
          },
        },
      };
      /**
     * Current referral Appointment tab
     */
    case ActionTypes.LOADING_REFERRAL_APPOINTMENTS:
      return {
        ...state,
        meta: {
          ...state.meta,
          loadingAppointments: true,
        },
      };
    case ActionTypes.LOADED_REFERRAL_APPOINTMENTS:
      return {
        ...state,
        current: {
          ...state.current,
          appointments: {
            ...mapKeys(action.payload.appointments, x => x.id),
          },
        },
        meta: {
          ...state.meta,
          loadingAppointments: false,
          errorLoadingAppointments: null,
          loadedAppointments: true,
        },
      };
    case ActionTypes.ERROR_LOADING_REFERRAL_APPOINTMENTS:
      return {
        ...state,
        meta: {
          ...state.meta,
          loadingAppointments: false,
          errorLoadingAppointments: action.payload.message,
          loadedAppointments: false,
        },
      };

    /**
     * Patient Identifier
     */
    case PatientActionTypes.SAVED_PATIENT_IDENTIFIER:
      if (state.current) {
        return {
          ...state,
          current: {
            ...state.current,
            patient: updatePatientIdentifiers(state.current.patient, action.payload.patientIdentifier),
          },
        };
      }
      return state;
    case PatientActionTypes.DELETED_PATIENT_IDENTIFIER:
      if (state.current) {
        return {
          ...state,
          current: {
            ...state.current,
            patient: removePatientIdentifiers(state.current.patient, action.payload.patientIdentifier),
          },
        };
      }
      return state;
    /**
     * Referral actives
     * Correspondence, Notes, action actives, print, assign to
     */
    case ActionTypes.SAVED_REFERRAL_ASSIGNED_USER:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.referralId]: { ...state.all[action.payload.referralId], assignedToUserId: action.payload.assignedToUserId },
        },
        current: {
          ...state.current,
          assignedToId: action.payload.assignedToId,
          assignmentType: action.payload.assignmentType,
          assignedToUserId: action.payload.assignedToUserId,
          assignedToRoleId: action.payload.assignedToRoleId,
          assignedToSpecialtyId: action.payload.assignedToSpecialtyId,
          assignedToDisplayName: action.payload.assignedToDisplayName,
          assignedToRoleName: action.payload.assignedToRoleName,
          ...(action.payload.patientIdentifier && {
            patient: {
              ...state.current.patient,
              patientIdentifiers: [...state.current.patient.patientIdentifiers, action.payload.patientIdentifier],
            },
          }),
          activities: state.current.assignedToId === action.payload.assignedToId
            ? state.current.activities
            : {
              ...state.current.activities,
              assignment: concat(normaliseActivity(action.payload), state.current.activities.assignment),
              sortedActivities: concat(normaliseSortedActivity({
                id: action.payload.id,
                type: "Assignment",
                sortDateTimeUtc: action.payload.assignedDateTimeUtc,
              }), state.current.activities.sortedActivities),
            },
        },
      };
    case ActionTypes.COMPLETED_REFERRAL:
    case ActionTypes.ARCHIVED_REFERRAL:
    case ActionTypes.REJECTED_REFERRAL:
    case ActionTypes.UNDONE_REFERRAL_ACTION:
    case ActionTypes.REQUESTED_MORE_INFO:
    case ActionTypes.ACCEPTED_REFERRAL:
    case ActionTypes.CANCELLED_REFERRAL:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.id]:
          {
            ...state.all[action.payload.id],
            triageCategory: action.payload.triageCategory,
            isUrgent: action.payload.isUrgent,
            referralStatus: action.payload.referralStatus,
          },
        },
        current: {
          ...state.current,
          triageCategory: action.payload.triageCategory,
          isUrgent: action.payload.isUrgent,
          referralStatus: action.payload.referralStatus,
          activities: updateActivityActions(state.current.activities, action.payload.action),
          availableActions: action.payload.availableActions,
        },
      };
    case ActionTypes.LOADED_PRINT_REFERRAL:
      return {
        ...state,
        current: {
          ...state.current,
          activities: updateActivityActions(state.current.activities, action.payload.action),
        },
      };
    case ActionTypes.ADDED_REFERRAL_NOTE:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.referralId]: { ...state.all[action.payload.referralId], hasNotes: true },
        },
        current: {
          ...state.current,
          activities: {
            ...state.current.activities,
            note: concat(normaliseActivity(action.payload), state.current.activities.note),
            sortedActivities: concat(normaliseSortedActivity({
              id: action.payload.id,
              type: "Note",
              sortDateTimeUtc: action.payload.addedDateTimeUtc,
            }), state.current.activities.sortedActivities),
          },
        },
      };
    case ActionTypes.SAVED_REFERRAL_NOTE:
      return {
        ...state,
        current: {
          ...state.current,
          activities: {
            ...state.current.activities,
            note: map(state.current.activities.note, x => (x.id === action.payload.id ? normaliseActivity(action.payload) : x)),
          },
        },
      };
    case ActionTypes.DELETED_REFERRAL_NOTE:
      const activityNotes = filter(state.current.activities.note, x => x.id !== action.payload.id);
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.referralId]: { ...state.all[action.payload.referralId], hasNotes: size(activityNotes) > 0 },
        },
        current: {
          ...state.current,
          activities: {
            ...state.current.activities,
            note: activityNotes,
          },
        },
      };
    case ActionTypes.ADDED_REFERRAL_CORRESPONDENCE:
      return {
        ...state,
        current: {
          ...state.current,
          activities: {
            ...state.current.activities,
            correspondence: concat(normaliseActivity(action.payload), state.current.activities.correspondence),
            sortedActivities: concat(normaliseSortedActivity({
              id: action.payload.id,
              type: "Correspondence",
              sortDateTimeUtc: action.payload.addedDateTimeUtc,
            }), state.current.activities.sortedActivities),
          },
        },
      };
    case ActionTypes.LOADED_REFERRAL_PDF_PREVIEW:
    case ActionTypes.ERROR_LOADING_REFERRAL_PDF_PREVIEW:
      return setCurrentPDFPreview(state, action.payload, false);
    case ActionTypes.LOADING_REFERRAL_PDF_PREVIEW:
      return setCurrentPDFPreview(state, action.payload, true);
    case ActionTypes.EDIT_MANUAL_REFERRAL:
    case ActionTypes.CREATE_MANUAL_REFERRAL:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.referral.id]: {
            ...state.all[action.payload.referral.id],
            id: action.payload.referral.id,
            referralDateUtc: action.payload.referral.referralDateUtc,
            referralStatus: action.payload.referral.referralStatus,
            assignedToUserId: action.payload.referral.assignedToUserId,
            referrerOrganisationName: action.payload.referral.referrerOrganisationName,
            patient: action.payload.referral.patient,
          },
        },
        current: {
          ...action.payload.referral,
          activities: {
            ...state.current?.activities,
            action: map(action.payload.actions, x => normaliseActivity(x)),
            assignment: map(action.payload.assignments, x => normaliseActivity(x)),
            sortedActivities: map(action.payload.sortedActivities, x => normaliseSortedActivity(x)),
          },
        },
        referralsForWorkList: includes(state.referralsForWorkList, action.payload.referral.id) ? state.referralsForWorkList : [action.payload.referral.id, ...state.referralsForWorkList],
        meta: {
          loading: false,
          loaded: true,
          error: null,
        },
        referralSourceDocumentFiles: referralSourceDocumentFilesState, // reset files object
      };
    case ActionTypes.LOADED_CURRENT_REFERRAL:
      return {
        ...state,
        current: action.payload.referral,
      };
    case ActionTypes.CREATING_SOURCE_DOCUMENT_FILE:
    case ExternalDocumentActionTypes.LOADING_CURRENT_SOURCE_DOCUMENT_FILE:
      return {
        ...state,
        referralSourceDocumentFiles: {
          ...state.referralSourceDocumentFiles,
          loading: true,
        },
      };
    case ActionTypes.CREATED_SOURCE_DOCUMENT_FILE:
    case ExternalDocumentActionTypes.LOADED_CURRENT_SOURCE_DOCUMENT_FILE:
      return {
        ...state,
        // support multiple later on
        referralSourceDocumentFiles: {
          loading: false,
          loaded: true,
          all: concat(
            [{ ...action.payload, base64File: action.payload?.fileData || action.payload.base64File }],
            filter(state.referralSourceDocumentFiles?.all, x => x.id !== action.payload?.id),
          ),
        },
      };
    case ActionTypes.CLEAR_SOURCE_DOCUMENT_FILES:
      return {
        ...state,
        referralSourceDocumentFiles: referralSourceDocumentFilesState,
      };
    case ActionTypes.LOADED_EXISTING_SOURCE_DOCUMENT_FILE:
      return loadExistingSourceDocument(state, action);
    case ActionTypes.LOADING_EXISTING_SOURCE_DOCUMENT_FILE:
      return {
        ...state,
        meta: {
          ...state.meta,
          loadingCurrentSourceDocument: true,
        },
      };
    case ActionTypes.ERROR_LOADING_EXISTING_SOURCE_DOCUMENT_FILE:
      return {
        ...state,
        meta: {
          ...state.meta,
          errorLoadingCurrentSourceDocument: action.payload.message,
        },
      };
    case ActionTypes.LOADING_REFERRAL_ACCESS_LOG:
      return {
        ...state,
        current: {
          ...state.current,
          referralAccessLog: {
            loading: true,
          },
        },
      };
    case ActionTypes.LOADED_REFERRAL_ACCESS_LOG:
      return {
        ...state,
        current: {
          ...state.current,
          referralAccessLog: {
            loading: false,
            log: action.payload.referralAccessLog,
            errorLoadingReferralAccessLog: null,
            loaded: true,
          },
        },
      };
    case ActionTypes.ERROR_LOADING_REFERRAL_ACCESS_LOG:
      return {
        ...state,
        current: {
          ...state.current,
          referralAccessLog: {
            loading: false,
            errorLoadingReferralAccessLog: action.payload.message,
            log: null,
            loaded: false,
          },
        },
      };
    case ActionTypes.RESET_CURRENT_REFERRAL:
      return {
        ...state,
        current: null,
      };
    case ActionTypes.CREATED_REFERRAL_TASK:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.referralId]: { ...state.all[action.payload.referralId], hasIncompleteTasks: true },
        },
        current: {
          ...state.current,
          activities: {
            ...state.current.activities,
            task: concat(normaliseActivity(action.payload), state.current.activities.task),
            sortedActivities: concat(normaliseSortedActivity({
              id: action.payload.id,
              type: "Task",
              sortDateTimeUtc: action.payload.addedDateTimeUtc,
            }), state.current.activities.sortedActivities),
          },
        },
      };
    case ActionTypes.SAVED_REFERRAL_TASK:
      return updateReferralTasks(state, action);
    case ActionTypes.DELETED_REFERRAL_TASK:
      return removeReferralTask(state, action);
    default:
      return state;
  }
};

export const getReferralById = curry(({ localState }, referralId) => {
  const referrals = get(localState, ["all"], {});
  const referral = find(referrals, x => x.id === referralId);
  return referral;
});

export const getAllReferrals = curry(({ localState }) => {
  const referrals = get(localState, ["all"], {});
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  return map(get(localState.pages, [pageNumber, "ids"], []), key => referrals[key]);
});

export const isPageLoading = curry(({ localState }) => {
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", pageNumber, "loading"], false);
});

export const isPageLoaded = curry(({ localState }) => {
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", pageNumber, "loading"], null) === false;
});

export const isReferralLoading = curry(({ localState }) => get(localState, ["meta", "loading"], false));

export const getReferralError = curry(({ localState }) => get(localState, ["meta", "error"], null));

export const getErrorMessage = curry(({ localState }) => {
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", pageNumber, "error"], null);
});

export const getReferralsForWorklist = curry(({ localState }) => {
  const referrals = get(localState, ["all"], {});
  const referralsForWorkList = map(localState.referralsForWorkList, key => referrals[key]);
  return referralsForWorkList;
});

export const getReferralSearchTerms = curry(({ localState }) => localState.searchParams);

export const getPageInfo = curry(({ localState }) => localState.pageInfo);

// assignment options
export const getAssignmentOptions = curry(({ localState }) => get(localState, ["assignmentOptions", "all"], []));

export const isAssignmentOptionsLoading = curry(({ localState }) => get(localState, ["assignmentOptions", "loading"]) === true);

export const getCurrentReferralAttachmentFiles = curry(({ localState }, referralId) => {
  const referral = get(localState, ["current"], false);
  if (referral && referral.id === referralId) {
    return flattenDeep(map(referral.attachments, x => values(x.files)));
  }
  return null;
});

export const getCurrentReferral = curry(({ localState }) => get(localState, ["current"], null));

export const getActivityByTypeAndId = curry(({ localState }, type, id) => {
  const referralActivities = get(localState, ["current", "activities"], null);
  if (referralActivities) {
    return { type: lowerCase(type), detail: find(referralActivities[lowerCase(type)], x => x.id === id) };
  }
  return null;
});

export const getCurrentReferralSortedActivities = curry(({ localState }) => get(localState, ["current", "activities", "sortedActivities"], null));

export const getCurrentReferralNotes = curry(({ localState }) => get(localState, ["current", "activities", "note"], null));

export const currentReferralReportIsLoading = curry(({ localState }) => get(localState, ["meta", "loadingReports"], false));

export const errorLoadingCurrentReferralReport = curry(({ localState }) => get(localState, ["meta", "errorLoadingReports"], false));

export const currentReferralAttachmentIsLoading = curry(({ localState }) => get(localState, ["meta", "loadingAttachments"], false));

export const errorLoadingCurrentReferralAttachments = curry(({ localState }) => get(localState, ["meta", "errorLoadingAttachments"], false));

export const currentReferralAppointmentsLoading = curry(({ localState }) => get(localState, ["meta", "loadingAppointments"], false));

export const errorLoadingCurrentReferralAppointments = curry(({ localState }) => get(localState, ["meta", "errorLoadingAppointments"], false));

export const getReferralPDFPreview = curry(({ localState }) => get(localState, ["current", "pdfPreview", "data"], null));

export const isReferralPDFPreviewLoading = curry(({ localState }) => get(localState, ["meta", "loadingPdfPreview"], false));

export const errorLoadingReferralPDFPreview = curry(({ localState }) => get(localState, ["meta", "errorLoadingPdfPreview"], false));

export const getCurrentReferralAppointments = curry(({ localState }) => get(localState, ["current", "appointments"], null));

export const getDefaultReferralSourceDocumentFile = curry(({ localState }) => {
  if (!localState.referralSourceDocumentFiles?.all) {
    return null;
  }

  return head(localState.referralSourceDocumentFiles.all);
});

export const getReferralAccessLog = curry(({ localState }) => get(localState, ["current", "referralAccessLog", "log"], null));

export const isReferralAccessLogLoading = curry(({ localState }) => get(localState, ["current", "referralAccessLog", "loading"], null));

export const isReferralSourceDocumentFileLoading = curry(({ localState }) => get(localState, ["referralSourceDocumentFiles", "loading"], false));

export const getCurrentReferralSourceDocumentFile = curry(({ localState }) => get(localState, ["current", "referralSourceDocument"], null));

export const isCurrentReferralSourceDocumentFileLoading = curry(({ localState }) => get(localState, ["meta", "loadingCurrentSourceDocument"], null));

export const errorLoadingCurrentReferralSourceDocumentFile = curry(({ localState }) => get(localState, ["meta", "errorLoadingCurrentSourceDocument"], null));
