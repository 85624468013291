import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { get, concat, find, uniqBy, sortBy, debounce, isEmpty } from "lodash";

import { fetchAssignmentOptions } from "app/main/referrals/actions/referrals.actions";
import { AssignedUserIcon, AssignedSpecialtyIcon } from "helpers/icon-finder";

import { AutoComplete, AutoCompleteLoading } from "components/inputs";
import IconComponent from "components/items/icon-component";

const pageSize = 50;

const getIcon = type => (type && type === "User" ? <AssignedUserIcon /> : <AssignedSpecialtyIcon />);

export default function AssignToUserSelector({
  isAssignmentField,
  onChange,
  normaliseOptions,
  isLoading,
  defaultOptions,
  ...other
}) {
  const { field: { value } } = other;

  const dispatch = useDispatch();

  const [options, setOptions] = useState(defaultOptions);
  const [loading, setLoading] = useState(!isEmpty(value));

  const assignmentType = get(value, "assignmentType", false);

  useEffect(() => {
    if (value) {
      setLoading(true);
      const match = find(options, x => x.assignedToId === value.assignedToId);
      if (!match) {
        setOptions(concat(value, options));
      }
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputChange = useCallback(debounce((event, val, action) => {
    if (action === "input") {
      dispatch(fetchAssignmentOptions(pageSize, val, isAssignmentField)).then(res => {
        const newOptions = res?.payload?.assignmentOptions;
        if (newOptions) {
          setOptions(uniqBy(concat(normaliseOptions(newOptions), options), "assignedToId"));
        }
      });
    }
  }, 300));

  const renderGroup = params => [
    <div key={params.key} className="flex items-center leading-4 py-8 px-16">
      <IconComponent icon={getIcon(params.group)} />
      <span className="ml-16">{params.group}</span>
    </div>,
    params.children,
  ];

  if (loading) {
    return <AutoCompleteLoading />;
  }

  return (
    <AutoComplete
      loading={isLoading}
      icon={getIcon(assignmentType)}
      options={sortBy(options, ["assignmentType"])}
      getOptionSelected={(o, val) => o.assignedToId === val.assignedToId}
      onInputChange={onInputChange}
      groupBy={option => option.assignmentType}
      renderGroup={renderGroup}
      {...other}
    />
  );
}
