/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { map, isEmpty } from "lodash";
import { TableCell, TableRow, TableHead, Table, TableBody, Typography } from "@material-ui/core";

import TabContent from "components/items/tab-content";
import { currentReferralAttachmentIsLoading, errorLoadingCurrentReferralAttachments } from "app/main/referrals/reducers/referrals.reducers";
import { getReferralAttachments } from "app/main/referrals/actions/referrals.actions";
import TextLink from "components/items/text-link";
import ReferralAttachmentPreviewDialog from "app/main/referrals/components/referral-attachment-preview-dialog";
import formatAttachmentType from "app/main/patients/helpers/format-attachment-type";
import { openDialog } from "app/store/actions/dialog.actions";

const renderTableHeader = () => (
  <TableRow>
    <TableCell>Attach Date</TableCell>
    <TableCell>Name</TableCell>
    <TableCell>Comments</TableCell>
    <TableCell>Size</TableCell>
    <TableCell>Type</TableCell>
  </TableRow>
);

export default ({
  referral,
}) => {
  const dispatch = useDispatch();
  const loadingCurrentAttachments = useSelector(currentReferralAttachmentIsLoading);
  const errorLoadingCurrentAttachments = useSelector(errorLoadingCurrentReferralAttachments);

  React.useEffect(() => {
    if (referral?.referralDocument.canViewAttachments) {
      dispatch(getReferralAttachments(referral.id));
    }
  }, []);

  const renderTableRow = ({ file, referralDocumentId }) => (
    <TableRow
      hover
      key={file.id}
    >
      <TableCell>{file.createdDate}</TableCell>
      <TableCell>
        <TextLink
          content={file.fileName}
          onClick={() => dispatch(openDialog({
            disableBackdropClick: false,
            children: (
              <ReferralAttachmentPreviewDialog
                title="Preview Attachment"
                file={file}
                referralId={referral.id}
                referralDocumentId={referralDocumentId}
              />
            ),
          }))}
        />
      </TableCell>
      <TableCell>{file.description}</TableCell>
      <TableCell>{file.fileSize}</TableCell>
      <TableCell>{formatAttachmentType(file.attachmentType)}</TableCell>
    </TableRow>
  );

  return (
    <TabContent
      withGutters
      accessDenied={!referral.referralDocument.canViewAttachments}
      loading={loadingCurrentAttachments}
      error={errorLoadingCurrentAttachments}
      empty={isEmpty(referral.attachments)}
      emptyTitle="No Attachments Available"
    >
      {map(referral.attachments, (attachment, key) => (
        <div key={key}>
          <Typography className="font-bold mx-16 mt-16">
            {attachment.documentType === "Original" ? attachment.documentType : "Update"} {attachment.documentType !== "Original" && attachment.documentVersion}
          </Typography>
          <Table className="min-w-xl" aria-labelledby="tableTitle">
            <TableHead>
              {renderTableHeader()}
            </TableHead>
            <TableBody>
              {attachment.files != null
              && map(attachment.files, file => renderTableRow({
                file,
                referralDocumentId: attachment.referralDocumentId,
              }))}
            </TableBody>
          </Table>
        </div>
      ))}
    </TabContent>
  );
};
