import React from "react";
import { connect } from "react-redux";
import { Field, getIn } from "formik";
import { Typography, Divider } from "@material-ui/core";

import { MedicationIcon, ChartIcon } from "helpers/icon-finder";
import { TextInput, NoteInput } from "components/inputs/text-input";
import CheckboxInput from "components/inputs/checkbox";
import { getCurrentMedication } from "app/main/patients/reducers/medications.reducers";
import { validateRequired, validateDateIsAfter } from "utils/validators";
import { DatePicker } from "components/inputs";
import Form from "components/form";
import DefaultItem from "components/items/default-item";
import { TODAY } from "utils/date";

// Medication
const medicationDescription = "Medication";
const medicationForm = "Form";
const medicationStrength = "Strength";
const medicationStartDate = "Start Date";
const medicationEndDate = "End Date";
const medicationNote = "Note";
// Directions
const medicationDoseAmount = "Dose Amount";
const medicationDoseUnit = "Dose Unit";
const medicationFrequency = "Frequency";
const medicationFrequencyQualifier = "Frequency Qualifier";
const medicationManualDirections = "Manual Directions";
const medicationWithFood = "With Food";
const medicationPrn = "Prn";
const validateDescription = validateRequired(medicationDescription);
const validateForm = validateRequired(medicationForm);
const validateStrength = validateRequired(medicationStrength);
const validateStartDate = validateRequired(medicationStartDate);
const validateEndDate = validateDateIsAfter(medicationEndDate, medicationStartDate, ["medication", "startDate"]);

class RecordMedicationForm extends React.PureComponent {
  render() {
    const {
      isEdit,
      initialValues,
      handleSubmit,
      ...other
    } = this.props;

    return (
      <Form
        contentProps={other}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        content={props => {
          // this is used to prevent show error when editing exiting medication which is past today
          const startDateIsTouched = getIn(props.touched, ["medication", "startDate"], false);
          const { medication: { isCurrent } } = props.values;

          const shouldStartDateValidated = startDateIsTouched || !isEdit;
          return (
            <>
              <DefaultItem
                labelClassName="w-160 h-80"
                label="Medication Status"
                content={(
                  <Field
                    name="medication.isCurrent"
                    label="Current"
                    component={CheckboxInput}
                    onChange={(value, change) => {
                      if (value === false) {
                        change("medication.endDate", TODAY);
                      } else {
                        change("medication.endDate", null);
                      }
                    }}
                  />
                )}
              />
              <Field
                name="medication.term"
                icon="mode_comment"
                label={medicationDescription}
                component={TextInput}
                validate={validateDescription}
              />
              <Field
                name="medication.form"
                component={TextInput}
                icon={<MedicationIcon />}
                label={medicationForm}
                validate={validateForm}
              />
              <Field
                name="medication.strength"
                component={TextInput}
                icon={<ChartIcon />}
                label={medicationStrength}
                validate={validateStrength}
              />
              <Field
                name="medication.startDate"
                label={medicationStartDate}
                component={DatePicker}
                validate={validateStartDate}
                isPast={shouldStartDateValidated}
              />
              <Field
                name="medication.endDate"
                label={medicationEndDate}
                component={DatePicker}
                disabled={isCurrent}
                validate={value => validateEndDate(value, props.values)}
              />
              <Field
                name="medication.note"
                label={medicationNote}
                component={NoteInput}
              />
              <Divider variant="middle" className="my-24" />
              <Typography className="font-bold">Directions</Typography>
              <Field
                name="medication.doseAmount"
                icon="mode_comment"
                label={medicationDoseAmount}
                component={TextInput}
              />
              <Field
                name="medication.doseUnit"
                icon="mode_comment"
                label={medicationDoseUnit}
                component={TextInput}
              />
              <Field
                name="medication.frequency"
                icon="mode_comment"
                label={medicationFrequency}
                component={TextInput}
              />
              <Field
                name="medication.frequencyQualifier"
                icon="mode_comment"
                label={medicationFrequencyQualifier}
                component={TextInput}
              />
              <Field
                name="medication.manualDirections"
                label={medicationManualDirections}
                component={NoteInput}
              />
              <Field
                name="medication.withFood"
                label={medicationWithFood}
                component={CheckboxInput}
              />
              <Field
                name="medication.prn"
                label={medicationPrn}
                component={CheckboxInput}
              />
            </>
          );
        }}
      />
    );
  }
}

export default connect(state => {
  const medication = getCurrentMedication(state);
  return ({
    initialValues: { medication },
  });
})(RecordMedicationForm);
