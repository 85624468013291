/* eslint-disable react/no-danger */
import React from "react";
import { includes } from "lodash";
import { CardMedia } from "@material-ui/core";
import AsyncComponent from "components/async-component";
import { EmptyStateComponent } from "components/items/pdf-preview";

const PDFPreview = AsyncComponent(() => import("components/items/pdf-preview"));

const PreviewContent = ({
  hasPreview,
  fileData,
  mimeType,
  pdfProps,
  imageProps,
  htmlProps,
}) => {
  if (!fileData) {
    return <EmptyStateComponent />;
  }

  if (hasPreview) {
    // render preview
    const base64Data = `data:${mimeType};base64,${fileData}`;
    const isPdf = includes(mimeType, "pdf");
    const isHtml = includes(mimeType, "html", "plain");
    if (isPdf) {
      return <PDFPreview file={base64Data} {...pdfProps} />;
    } if (isHtml) {
      return <div dangerouslySetInnerHTML={{ __html: fileData }} {...htmlProps} />;
    }
    return <CardMedia image={base64Data} {...imageProps} />;
  }

  return <EmptyStateComponent />;
};

export default PreviewContent;
