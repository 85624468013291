import { get, find, groupBy, map, isEmpty, filter, includes, sortBy } from "lodash";
import moment from "moment";
import curryConnector from "utils/connectors";

export const SYSTEM_CONFIGURATION_STATE_KEY = "systemconfiguration";

export const ActionTypes = {
  LOADING_SYSTEMCONFIGURATION: "LOADING_SYSTEMCONFIGURATION",
  ERROR_LOADING_SYSTEMCONFIGURATION: "ERROR_LOADING_SYSTEMCONFIGURATION",
  LOADED_SYSTEMCONFIGURATION: "LOADED_SYSTEMCONFIGURATION",
};

const initialState = {
  loading: false,
  loaded: false,
  error: null,
};

const curry = fn => curryConnector(fn, SYSTEM_CONFIGURATION_STATE_KEY);

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_SYSTEMCONFIGURATION:
      return { ...state, loading: true, loaded: false, error: false };
    case ActionTypes.ERROR_LOADING_SYSTEMCONFIGURATION:
      return { ...state, loading: false, loaded: false, error: true };
    case ActionTypes.LOADED_SYSTEMCONFIGURATION:
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        loadedDateTimeUtc: moment.utc(),
        ...action.payload,
        helperText: groupBy(action.payload.helperText, "section"),
      };
    default:
      return state || initialState;
  }
};

export const isSystemConfigurationLoading = curry(({ localState }) => get(localState, ["loading"], false) === true);

export const errorLoadingSystemConfiguration = curry(({ localState }) => get(localState, ["error"], false) === true);

export const isSystemConfigurationLoaded = curry(({ localState }) => get(localState, ["loaded"], null) === true);

export const getSystemConfigurationloadedDateTimeUtc = curry(({ localState }) => get(localState, ["loadedDateTimeUtc"], null));

export const getApiVersion = curry(({ localState }) => get(localState, ["apiVersion"], null));

export const getApiDescription = curry(({ localState }) => get(localState, ["apiDescription"], null));

export const getExternalHelpPage = curry(({ localState }) => get(localState, ["externalHelpPage"], null));

export const getAllEnrolmentStatus = curry(({ localState }) => get(localState, ["enrolmentStatusValues"], null));

export const getEncounterTypeValues = curry(({ localState }) => get(localState, ["encounterTypeValues"], null));

export const getContactMethodValues = curry(({ localState }) => get(localState, ["encounterContactMethodValues"], null));

export const getContactValues = curry(({ localState }) => get(localState, ["encounterContactValues"], null));

export const getLocationTypeValues = curry(({ localState }) => get(localState, ["encounterLocationValues"], null));

export const getAllTriageCategorySettings = curry(({ localState }) => localState.triageCategorySettings?.all);

export const getEnabledTriageCategorySettings = curry(({ localState }) => {
  const enabled = localState.triageCategorySettings?.enabled;
  const allSettings = localState.triageCategorySettings?.all;
  return filter(allSettings, setting => includes(enabled, setting.triageCategory));
});

export const getApplicationStatusValues = curry(({ localState }) => get(localState, ["applicationStatusValues"], null));

export const getPatientSettingValues = curry(({ localState }) => get(localState, ["patientSettingValues"], null));

export const getDecisionStatusValues = curry(({ localState }) => get(localState, ["decisionStatusValues"], null));

export const getSystemConfigByName = curry(({ localState }, name) => get(localState, name, null));

export const getDefaultAddressState = curry(({ localState }) => get(localState, ["defaultAddressState"], null));

export const getHelperTextBySectionName = curry(({ localState }, section) => {
  const group = get(localState, ["helperText", section], null);
  const keySet = {};
  map(group, x => {
    keySet[x.key] = x.text;
  });
  return keySet;
});

export const getSurveySettings = curry(({ localState }) => get(localState, ["surveySettings"], null));

export const getSurveyGroupSettings = curry(({ localState }) => get(localState, ["surveyGroupSettings"], null));

export const getSurveySettingsBySurveyType = curry(({ localState }, surveyType) => {
  const allSettings = get(localState, ["surveySettings"], null);
  return find(allSettings, setting => setting.surveyType === surveyType);
});

export const getRelationshipTypeValues = curry(({ localState }) => get(localState, ["relationshipTypeValues"], null));
export const getEnrolmentTypeValues = curry(({ localState }) => get(localState, ["enrolmentTypeValues"], null));
export const getReferralActionTypeValues = curry(({ localState }) => get(localState, ["referralActionTypes"], null));
export const getReferralWorkflowStateSettings = curry(({ localState }) => get(localState, ["referralWorkflowStateSettings"], null));
export const getReferralActionTypeSettings = curry(({ localState }) => get(localState, ["referralActionTypeSettings"], null));
export const getSexOptions = curry(({ localState }) => get(localState, ["sexOptions"], null));
// Identifier Settings
export const getPrimaryPatientIdentifier = curry(({ localState }) => get(localState, ["primaryPatientIdentifierSettings", "type"], null));
export const getPrimaryPatientIdentifierSettings = curry(({ localState }) => get(localState, ["primaryPatientIdentifierSettings"], null));
export const getPatientIdentifierTypeOptions = curry(({ localState }, filterAllowedOptions = false) => {
  const allOptions = localState?.primaryPatientIdentifierSettings?.options;
  const allowedTypes = localState?.primaryPatientIdentifierSettings?.displayPatientIdentifierTypes;

  if (!filterAllowedOptions || isEmpty(allowedTypes)) {
    return allOptions;
  }

  return filter(allOptions, x => includes(allowedTypes, x.value));
});

export const getPatientCardOptions = curry(({ localState }) => get(localState, ["patientCardTypeCodeValues"], null));
export const getDisplayNameForExternalSourceDocumentWorklist = curry(({ localState }) => get(localState, ["externalSourceDocumentWorklistDisplayName"], null));
export const getReferralWorklistSettings = curry(({ localState }) => get(localState, ["referralWorklistSettings"], null));
export const getOrgUnitSettings = curry(({ localState }, orgUnitType) => {
  const orgUnits = get(localState, ["orgUnitTypeSettings"], null);
  if (orgUnitType) {
    return find(orgUnits, x => x.orgUnitType === orgUnitType);
  }
  return orgUnits;
});
export const getAllRoles = curry(({ localState }) => get(localState, ["allRoles"], null));

export const getOverviewPanelSettings = curry(({ localState }) => {
  const settings = localState?.overviewPanelSettings;
  return sortBy(filter(settings, x => x.enabled === true), ["sortOrder"]);
});

export const getUrgencyTreatmentTypeSettings = curry(({ localState }) => get(localState, ["urgencyTreatmentTypeSettings"], null));

export const getEndorsementDecisionValues = curry(({ localState }) => get(localState, ["endorsementDecisionValues"], null));

export const getDecisionTemplateAcceptFormat = curry(({ localState }) => get(localState, ["decisionTemplateAcceptFormat"], null));

export const getIPABlankFormAcceptFormatAcceptFormat = curry(({ localState }) => get(localState, ["ipaBlankFormAcceptFormat"], null));

export const getChildOrgUnitSettings = curry(({ localState }, orgUnitType) => {
  const allOrgUnitTypeSettings = get(localState, ["orgUnitTypeSettings"], null);
  return filter(allOrgUnitTypeSettings, x => x.parentOrgUnitType === orgUnitType);
});
export const getSubSpecialtyLabel = curry(({ localState }) => get(localState, ["subSpecialtyDescription"], null));

export const getCodeSetTypes = curry(({ localState }) => get(localState, ["codeSetTypes"], null));

export const getExternalPatientSearchFields = curry(({ localState }) => get(localState, ["externalPatientSearchFields"], null));

export const showExternalPatientSearch = curry(({ localState }) => get(localState, ["enableExternalPatientSearch"], false));

export const enableFreeTextOnProviderInput = curry(({ localState }) => get(localState, ["enableFreeTextOnProviderInput"], false));

export const useExternalMedicationCostQuery = curry(({ localState }) => get(localState, ["useExternalMedicationCostQuery"], false));

export const getDecimalNumberRegex = curry(({ localState }) => get(localState, ["decimalNumberRegex"], null));

export const getDecimalPlacesRegex = curry(({ localState }) => get(localState, ["decimalPlacesRegex"], null));

export const getUseExternalDispensingQuery = curry(({ localState }) => get(localState, ["useExternalDispensingQuery"], false));
