import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { isEmpty } from "lodash";
import { List, Typography, Box } from "@material-ui/core";
import EmptyState from "components/items/empty-state";
import LoadingState from "components/items/loading-state";

const InfiniteLoadingList = ({
  loadFunction,
  data,
  loading,
  error,
  isEmptyStatus,
  renderItem,
  total,
  pageStart,
  pageLoaded,
}) => {
  React.useEffect(() => {
    loadFunction();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if ((isEmpty(data) && loading)) {
    return <LoadingState />;
  }

  if (!isEmpty(error)) {
    return <Typography className="font-bold">{error}</Typography>;
  }

  if (isEmptyStatus) {
    return <EmptyState title="No Records" />;
  }

  return (
    <InfiniteScroll
      pageStart={pageStart}
      loadMore={loadFunction}
      hasMore={data.length < total && !loading && !pageLoaded}
      useWindow={false}
    >
      <List className="w-full pt-0 h-full">
        {data.length > 0 && (
          data.map((item, index) => (
            <Box key={String(`item_${index}`)}>{renderItem(item)}</Box>
          ))
        )}
      </List>
      {loading && <LoadingState />}
    </InfiniteScroll>
  );
};

export default InfiniteLoadingList;
