import React from "react";
import { Field } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";

import Form from "components/form";
import { NoteInput } from "components/inputs/text-input";
import { onProvisionalApproval } from "app/main/applications/actions/applications.actions";

const detailsLabel = "Details";

const schema = Yup.object().shape({
  application: Yup.object().shape({
    provisionalApprovalDetails: Yup.string()
      .max(500, "Too long!")
      .nullable(),
  }),
});

class ProvisionalApprovalForm extends React.Component {
  handleSubmit = ({ application }, { setErrors }) => {
    const { id, orgUnitId } = this.props;

    this.props.onProvisionalApproval(id, orgUnitId, application).then(response => {
      if (response.error !== true) {
        this.props.onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  render() {
    const { initialValues, ...other } = this.props;

    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        contentProps={other}
        validationSchema={schema}
        content={() => (
          <Field
            name="application.provisionalApprovalDetails"
            component={NoteInput}
            label={detailsLabel}
            rows={8}
            maxLength={500}
          />
        )}
      />
    );
  }
}

const mapStateToProps = () => {
  const { application } = schema.cast();

  return ({
    initialValues: { application },
  });
};

export default connect(mapStateToProps, { onProvisionalApproval })(ProvisionalApprovalForm);
