import React from "react";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { map, isEmpty, get, isString, every } from "lodash";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  container: {
    borderTopWidth: 1,
    borderTopStyle: "dashed",
    borderTopColor: theme.palette.divider,
  },
  chips: {
    padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  chip: {
    marginTop: theme.spacing(1),
    height: "auto",
  },
  chipLabel: {
    whiteSpace: "normal",
  },
}));

const renderTag = (tags, classes) => (
  map(tags, (tag, index) => {
    if (isEmpty(tag)) return null; // empty array/object
    const label = get(tag, "label", isString(tag) ? tag : index.toString());
    const onDelete = get(tag, "onDelete", null);
    const onClick = get(tag, "onClick", null);
    return (
      <Chip
        className="mt-8"
        classes={{ root: classes.chip, label: classes.chipLabel }}
        key={label}
        size="small"
        label={label}
        onDelete={onDelete ? () => onDelete() : null}
        onClick={onClick ? () => onClick() : null}
      />
    );
  })
);

const FilterTags = ({
  tags,
  loading,
  showDivider = true,
  className,
}) => {
  const classes = useStyles();

  if (loading || isEmpty(tags) || every(tags, t => isEmpty(t))) {
    return null;
  }

  return (
    <div className={clsx("flex", showDivider && classes.container, className)}>
      <div className={classes.chips}>
        {renderTag(tags, classes)}
      </div>
    </div>
  );
};

export default FilterTags;
