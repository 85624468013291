/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { isEmpty, every, isString, find, isObject } from "lodash";
import moment from "moment";
import { Typography } from "@material-ui/core";

import { DateTimePicker, TextInput, NoteInput, CheckboxInput, DatePicker } from "components/inputs";
import Form from "components/form";
import { DoctorIcon, ProblemIcon, AssignedSpecialtyIcon, RemoveAccount, SubSpecialtyIcon } from "helpers/icon-finder";
import SpecialtySelector from "app/main/specialtyProfiles/components/specialty-selector";
import CodeSetValueSelector from "app/main/codeSet/components/code-set-value-selector";
import { getAllCodeSetValues } from "app/main/codeSet/reducers/codeSet.reducers";

import { createManualReferral, editManualReferral, clearReferralSourceDocumentFiles, getReferralSourceDocumentFilePreviewData } from "app/main/referrals/actions/referrals.actions";
import { getDefaultReferralSourceDocumentFile } from "app/main/referrals/reducers/referrals.reducers";
import HelperText from "components/inputs/components/helper-text";
import IconButton from "components/items/icon-button";
import PreviewContent from "components/items/preview-content";
import PanelContent from "components/items/panel-content";
import SubSpecialtySelector from "app/main/specialtyProfiles/components/sub-specialty-selector";
import Section from "components/items/section";

import ReferralFormComponent from "../referral-form-component";
import PatientSummaryCard from "../patient-summary-card";
import ReferralSourceDocumentInput from "./referralSourceDocumentInput";
import ProviderSelectorInput from "./provider-selector-input";

export default ({
  currentReferral,
  onSucceed,
  patient: presetPatient,
  sourceDocument,
  isEdit,
  schema,
  fieldLabels,
  fieldSettings,
  ...other
}) => {
  const dispatch = useDispatch();
  const codeSetValues = useSelector(getAllCodeSetValues);
  const codeSetValueProperties = codeSetValueId => find(codeSetValues, x => x.id === codeSetValueId)?.propertiesJson;
  const referralSourceDocumentFile = useSelector(getDefaultReferralSourceDocumentFile);
  const [previewLoading, setPreviewLoading] = useState(currentReferral?.referralSourceDocument);
  const [previewSourceDocument, setPreviewSourceDocument] = useState(sourceDocument);

  // consider 2 way of patient details
  // 1. from new patient or existing - presetPatient
  // 2. from edit - currentReferral.patient
  const patientDetails = presetPatient ?? currentReferral?.patient;

  useEffect(() => {
    if (currentReferral?.referralSourceDocument) {
      dispatch(getReferralSourceDocumentFilePreviewData(currentReferral.referralSourceDocument.id, currentReferral.referralSourceDocument.fileId)).finally(() => setPreviewLoading(false));
    }
  }, [currentReferral?.referralSourceDocument]);

  useEffect(() => {
    if (referralSourceDocumentFile) {
      setPreviewSourceDocument(referralSourceDocumentFile);
    }
  }, [referralSourceDocumentFile]);

  useEffect(() => () => dispatch(clearReferralSourceDocumentFiles()), []);

  const handleSubmit = ({ referral: data }, { setErrors }) => {
    let { referralExpiryDate } = data;

    if (referralExpiryDate) {
      referralExpiryDate = isString(data.referralExpiryDate) ? data.referralExpiryDate : moment(data.referralExpiryDate).format("YYYY-MM-DD");
    }

    let referralData = {
      ...data,
      patientId: presetPatient?.patientId ?? data.patientId,
      patient: presetPatient,
      nok: !every(data.nok, x => (x === null || x === undefined)) ? { ...data.nok, relationshipType: "NextOfKin" } : null,
      referredToServiceDescription: data.referredToSpecialty.label,
      assignToSpecialty: data.referredToSpecialty.value,
      referralSourceDocumentFileId: previewSourceDocument ? previewSourceDocument.id : data.referralSourceDocument?.fileId,
      referrerTimeZone: moment.tz.guess(),
      referralSourceDocumentFileName: previewSourceDocument ? previewSourceDocument.fileName : data.referralSourceDocument?.fileName,
      externalSourceDocumentId: previewSourceDocument?.externalSourceDocumentId,
      assignToSubSpecialty: data.assignToSubSpecialty?.value ?? data.assignToSubSpecialty,
      typeOfReferralCodeId: data.typeOfReferralCodeId?.value ?? data.typeOfReferralCodeId,
      referrerAdvisedPriorityCodeId: data.referrerAdvisedPriorityCodeId?.value ?? data.referrerAdvisedPriorityCodeId,
      referringLocationCodeId: data.referringLocationCodeId?.value ?? data.referringLocationCodeId,
      referringInternalLocationCodeId: data.referringInternalLocationCodeId?.value ?? data.referringInternalLocationCodeId,
      reasonForReferralCodeId: data.reasonForReferralCodeId?.value ?? data.reasonForReferralCodeId,
      claimTypeCodeId: data.claimTypeCodeId?.value ?? data.claimTypeCodeId,
      clinicTypeCodeId: data.clinicTypeCodeId?.value ?? data.clinicTypeCodeId,
      referrerFullName: data.referrerFullName?.value ?? data.referrerFullName,
      designationCodeId: data.designationCodeId?.value ?? data.designationCodeId,
      referralExpiryDate,
    };

    if (presetPatient) {
      referralData = {
        ...referralData,
        patient: {
          ...presetPatient,
          sex: presetPatient.sex?.value,
        },
        primaryPatientIdentifier: presetPatient.primaryPatientIdentifier,
      };
    }

    const apiCall = isEdit ? editManualReferral : createManualReferral;

    dispatch(apiCall(referralData)).then(response => {
      if (response.error !== true) {
        onSucceed(response.payload);
      } else {
        setErrors(response.payload);
      }
    });
  };

  let { referral } = schema.cast();

  referral = {
    ...referral,
    nok: patientDetails?.nextOfKin ?? {},
    referralSourceDocument: sourceDocument ?? {},
  };

  if (!isEmpty(currentReferral)) {
    const { patient, assignedToDisplayName,
      assignedToSpecialtyId, referralDateUtc, referralPeriod,
      reasonNote, presentingComplaintNote, relevantPatientHistoryNote,
      interpreterRequired, preferredLanguage, referrerFullName, referrerOrganisationName,
      id, referralDocumentId, referralAssignedToSubSpecialtyId, referralAssignedToSubSpecialtyName, referrerProviderNumber, referrerPhone } = currentReferral;

    referral = {
      ...referral,
      referralId: id,
      referralDocumentId,
      referralDateUtc,
      referralPeriod,
      reasonNote,
      presentingComplaintNote,
      relevantPatientHistoryNote,
      interpreterRequired,
      preferredLanguage,
      referrerFullName,
      referrerOrganisationName,
      referrerProviderNumber,
      referrerPhone,
      patientId: patient.patientId,
      referredToSpecialty: { value: assignedToSpecialtyId, label: assignedToDisplayName },
      referralSourceDocument: currentReferral.referralSourceDocument,
      assignToSubSpecialty: referralAssignedToSubSpecialtyId ? { value: referralAssignedToSubSpecialtyId, label: referralAssignedToSubSpecialtyName } : null,
      typeOfReferralCodeId: currentReferral.referralDocument.typeOfReferralCodeId,
      specialistConsultant: currentReferral.referralDocument.specialistConsultant,
      mbsConsent: currentReferral.referralDocument.mbsConsent,
      referrerAdvisedPriorityCodeId: currentReferral.referralDocument.referrerAdvisedPriorityCodeId,
      referringLocationCodeId: currentReferral.referralDocument.referringLocationCodeId,
      referringInternalLocationCodeId: currentReferral.referralDocument.referringInternalLocationCodeId,
      reasonForReferralCodeId: currentReferral.referralDocument.reasonForReferralCodeId,
      clinicTypeCodeId: currentReferral.referralDocument.clinicTypeCodeId,
      claimTypeCodeId: currentReferral.referralDocument.claimTypeCodeId,
      referralExpiryDate: currentReferral.referralDocument.referralExpiryDate,
      designationCodeId: currentReferral.referralDocument.designationCodeId,
    };
  }

  return (
    <ReferralFormComponent
      renderPreview={props => (
        <PreviewContent
          fileData={previewSourceDocument?.fileData || previewSourceDocument?.base64File || previewSourceDocument?.htmlPreview}
          mimeType={previewSourceDocument?.mimeType}
          pdfProps={{ paged: true, showPreviewControls: true, loading: previewLoading, ...props }}
          hasPreview
        />
      )}
    >
      <Form
        contentProps={{ ...other, submitLabel: isEdit ? "Save" : "Add" }}
        initialValues={{ referral }}
        onSubmit={handleSubmit}
        validationSchema={schema}
        renderHeaderContent={<PatientSummaryCard patient={patientDetails} />}
        variant="filled"
        content={({ values, setFieldValue, errors }) => (
          <>
            <Section withDivider>
              <Field
                name="referral.referralSourceDocument.fileName"
                component={ReferralSourceDocumentInput}
                label="Referral Source Document"
                onRemoveFile={() => {
                  setFieldValue("referral.referralSourceDocument.fileId", null);
                  setPreviewSourceDocument(null);
                }}
              />
            </Section>
            <Section withDivider>
              {/* // provider info */}
              {fieldSettings?.referrerFullName && (
                <Field
                  name="referral.referrerFullName"
                  component={ProviderSelectorInput}
                  icon={<DoctorIcon />}
                  label={fieldLabels.referrerFullName}
                  required={fieldSettings.referrerFullName.required}
                  onChange={(val, onChange) => {
                    if (val) {
                      // check the config if fields enabled
                      if (fieldSettings?.referrerProviderNumber && val?.providerNumber) {
                        onChange("referral.referrerProviderNumber", val.providerNumber);
                      }
                      if (fieldSettings?.referrerPhone && val?.contactNumber) {
                        onChange("referral.referrerPhone", val.contactNumber);
                      }
                    } else {
                      onChange("referral.referrerProviderNumber", null);
                      onChange("referral.referrerPhone", null);
                    }
                  }}
                />
              )}
              <PanelContent>
                {fieldSettings?.referrerProviderNumber && (
                  <Field
                    name="referral.referrerProviderNumber"
                    component={TextInput}
                    label={fieldLabels.referrerProviderNumber}
                    required={fieldSettings.referrerProviderNumber.required}
                  />
                )}
                {fieldSettings?.referrerPhone && (
                  <Field
                    name="referral.referrerPhone"
                    component={TextInput}
                    label={fieldLabels.referrerPhone}
                    required={fieldSettings.referrerPhone.required}
                  />
                )}
              </PanelContent>
              <PanelContent>
                {fieldSettings?.designation && (
                  <Field
                    name="referral.designationCodeId"
                    component={CodeSetValueSelector}
                    codeSetTypeCode={fieldSettings.designation.fieldName}
                    label={fieldLabels.designation}
                    required={fieldSettings.designation.required}
                  />
                )}
                {fieldSettings?.referrerOrganisationName && (
                  <Field
                    name="referral.referrerOrganisationName"
                    component={TextInput}
                    label={fieldLabels.referrerOrganisationName}
                    required={fieldSettings.referrerOrganisationName.required}
                  />
                )}
              </PanelContent>
              <PanelContent>
                {fieldSettings?.referringLocation && (
                  <Field
                    name="referral.referringLocationCodeId"
                    component={CodeSetValueSelector}
                    codeSetTypeCode={fieldSettings.referringLocation.fieldName}
                    label={fieldLabels.referringLocation}
                    required={fieldSettings.referringLocation.required}
                  />
                  )}
                {fieldSettings?.referringInternalLocation && (
                  <Field
                    name="referral.referringInternalLocationCodeId"
                    component={CodeSetValueSelector}
                    codeSetTypeCode={fieldSettings.referringInternalLocation.fieldName}
                    label={fieldLabels.referringInternalLocation}
                    required={fieldSettings.referringInternalLocation.required}
                  />
                )}
              </PanelContent>
            </Section>
            <Section withDivider>
              {fieldSettings?.mbsConsent && (
                <Field
                  name="referral.mbsConsent"
                  component={CheckboxInput}
                  label={fieldLabels.mbsConsent}
                  required={fieldSettings.mbsConsent.required}
                  helperText="Does the patient consent to being seen at an MBS clinic if available?"
                />
              )}
              <PanelContent>
                <Field
                  name="referral.referralDateUtc"
                  component={DateTimePicker}
                  label="Referral Date"
                  required
                  onChange={val => {
                    // If the type of referral has a duration setting, update the expiry to the new referral date + setting months
                    const typeOfReferralCodeId = isObject(values.referral?.typeOfReferralCodeId)
                      ? values.referral?.typeOfReferralCodeId?.value
                      : values.referral?.typeOfReferralCodeId;

                    const months = codeSetValueProperties(typeOfReferralCodeId)?.referralDurationMonths;
                    if (months) {
                      setFieldValue("referral.referralExpiryDate", moment(val).add(months, "M"));
                    }
                  }}
                />
                {fieldSettings?.referralPeriod && (
                  <Field
                    name="referral.referralPeriod"
                    component={TextInput}
                    label={fieldLabels.referralPeriod}
                    required={fieldSettings.referralPeriod.required}
                  />
                )}
              </PanelContent>
              <PanelContent>
                <Field
                  name="referral.referredToSpecialty"
                  component={SpecialtySelector}
                  disabled={isEdit}
                  label="Referred to Specialty"
                  required
                  icon={<AssignedSpecialtyIcon />}
                  onChange={() => setFieldValue("referral.assignToSubSpecialty", null)}
                />
                {values.referral.referredToSpecialty && (
                  <Field
                    name="referral.assignToSubSpecialty"
                    component={SubSpecialtySelector}
                    label={fieldLabels.assignToSubSpecialty}
                    icon={<SubSpecialtyIcon />}
                    specialtyProfileId={values.referral.referredToSpecialty?.value ?? values.referral.referredToSpecialty}
                  />
                )}
              </PanelContent>
              {fieldSettings?.specialistConsultant && (
                <Field
                  name="referral.specialistConsultant"
                  component={TextInput}
                  label={fieldLabels.specialistConsultant}
                  required={fieldSettings.specialistConsultant.required}
                />
              )}
              {fieldSettings?.reasonForReferralCode && (
                <Field
                  name="referral.reasonForReferralCodeId"
                  component={CodeSetValueSelector}
                  label={fieldLabels.reasonForReferralCode}
                  codeSetTypeCode={fieldSettings.reasonForReferralCode.fieldName}
                  required={fieldSettings.reasonForReferralCode.required}
                  icon="label_important"
                />
              )}
              {fieldSettings?.reasonNote && (
                <Field
                  name="referral.reasonNote"
                  component={NoteInput}
                  maxLength={500}
                  label={fieldLabels.reasonNote}
                  required={fieldSettings.reasonNote.required}
                />
              )}
              {fieldSettings?.presentingComplaintNote && (
              <Field
                name="referral.presentingComplaintNote"
                component={NoteInput}
                maxLength={500}
                icon={<ProblemIcon />}
                label={fieldLabels.presentingComplaintNote}
                required={fieldSettings.presentingComplaintNote.required}
              />
            )}
              {fieldSettings?.relevantPatientHistoryNote && (
              <Field
                name="referral.relevantPatientHistoryNote"
                component={NoteInput}
                maxLength={500}
                label={fieldLabels.relevantPatientHistoryNote}
                required={fieldSettings.relevantPatientHistoryNote.required}
              />
            )}
              {fieldSettings?.referrerAdvisedPriority && (
              <Field
                name="referral.referrerAdvisedPriorityCodeId"
                component={CodeSetValueSelector}
                codeSetTypeCode={fieldSettings.referrerAdvisedPriority.fieldName}
                label={fieldLabels.referrerAdvisedPriority}
                required={fieldSettings.referrerAdvisedPriority.required}
                icon="low_priority"
              />
            )}
            </Section>
            <Section withDivider>
              <PanelContent>
                {fieldSettings?.typeOfReferral && (
                  <Field
                    name="referral.typeOfReferralCodeId"
                    component={CodeSetValueSelector}
                    label={fieldLabels.typeOfReferral}
                    codeSetTypeCode={fieldSettings.typeOfReferral.fieldName}
                    required={fieldSettings.typeOfReferral.required}
                    onChange={val => {
                      const { referralDateUtc } = values.referral;
                      const months = val?.settings?.referralDurationMonths;

                      if (months && referralDateUtc) {
                        setFieldValue("referral.referralExpiryDate", moment(referralDateUtc).add(months, "M"));
                      } else {
                        setFieldValue("referral.referralExpiryDate", null);
                      }
                    }}
                  />
                )}
                {fieldSettings?.referralExpiry && (
                  <Field
                    name="referral.referralExpiryDate"
                    component={DatePicker}
                    label={fieldLabels.referralExpiry}
                    required={fieldSettings.referralExpiry.required}
                  />
                )}
              </PanelContent>
              <PanelContent>
                {fieldSettings?.clinicType && (
                  <Field
                    name="referral.clinicTypeCodeId"
                    component={CodeSetValueSelector}
                    codeSetTypeCode={fieldSettings.clinicType.fieldName}
                    label={fieldLabels.clinicType}
                    required={fieldSettings.clinicType.required}
                  />
                )}
                {fieldSettings?.claimType && (
                  <Field
                    name="referral.claimTypeCodeId"
                    component={CodeSetValueSelector}
                    codeSetTypeCode={fieldSettings.claimType.fieldName}
                    label={fieldLabels.claimType}
                    required={fieldSettings.claimType.required}
                  />
                )}
              </PanelContent>
            </Section>
            <Section>
              {fieldSettings?.interpreterRequired && (
              <div className="relative">
                <div className="flex flex-col sm:flex-row">
                  <Field
                    name="referral.interpreterRequired"
                    component={CheckboxInput}
                    onChange={value => {
                      if (value === false) {
                        setFieldValue("referral.preferredLanguage", null);
                      }
                    }}
                    label={fieldLabels.interpreterRequired}
                    showHelperText={false}
                  />
                  <Field
                    showIcon={false}
                    disabled={!values.referral.interpreterRequired}
                    name="referral.preferredLanguage"
                    component={TextInput}
                    label={fieldLabels.preferredLanguage}
                    required={values.referral.interpreterRequired === true}
                    showHelperText={false}
                  />
                </div>
                <div className="ml-32 absolute bottom-0">
                  <HelperText error helperText={errors?.referral?.interpreterRequired || errors?.referral?.interpreterRequired} />
                </div>
              </div>
              )}
              {fieldSettings?.nok && (
              <div>
                <Typography className="ml-32 font-bold" gutterBottom>{fieldLabels.nok}</Typography>
                <div className="relative">
                  <div className="flex sm:items-center">
                    <div className="flex-auto flex flex-col sm:flex-row">
                      <Field
                        name="referral.nok.givenName"
                        component={TextInput}
                        label="Given Name"
                        icon="people"
                        required={fieldSettings.nok.required}
                      />
                      <Field
                        name="referral.nok.familyName"
                        component={TextInput}
                        label="Family Name"
                        required={fieldSettings.nok.required}
                      />
                      <Field
                        name="referral.nok.contact"
                        component={TextInput}
                        label="Contact"
                        required={fieldSettings.nok.required}
                      />
                    </div>
                    <IconButton
                      title="Remove"
                      icon={<RemoveAccount />}
                      onClick={() => {
                        setFieldValue("referral.nok.givenName", null);
                        setFieldValue("referral.nok.familyName", null);
                        setFieldValue("referral.nok.contact", null);
                      }}
                    />
                  </div>
                  <div className="ml-32 absolute bottom-0">
                    <HelperText error helperText={errors?.referral?.nok?.isValid || errors?.referral?.nok} />
                  </div>
                </div>
              </div>
            )}
            </Section>
          </>
        )}
      />
    </ReferralFormComponent>
  );
};
