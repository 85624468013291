import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";

export const fetchProvidersResults = params => dispatch => {
  const searchParams = formatSearchParams(params);

  const url = `/providers?pageNumber=1&pageSize=50${searchParams}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "providers",
    },
  });
};
