import React from "react";
import { isEmpty } from "lodash";
import clsx from "clsx";

import DefaultPanelLayout from "components/items/default-panel-layout";
import getCandidateDiseaseStateDescription from "app/main/candidates/helpers/get-candidate-disease-state-description";
import TextLink from "components/items/text-link";
import { CareSummaryIcon } from "helpers/icon-finder";
import DefaultItem from "components/items/default-item";

class CareSummaryPanel extends React.PureComponent {
  renderCandidateDiseaseState = (diseaseStateType, diseaseState) => {
    const isPrimary = diseaseState === "Primary";
    const indicator = isPrimary ? " (P)" : " (S)";
    return (
      <TextLink
        className={clsx("mr-8")}
        content={`${getCandidateDiseaseStateDescription(diseaseStateType)}${indicator}`}
      />
    );
  };

  render() {
    const { candidate } = this.props;
    let DiseaseState = () => <TextLink content="None Recorded" />;

    if (!isEmpty(candidate)
    && (!isEmpty(candidate.ihd) || !isEmpty(candidate.hf) || !isEmpty(candidate.dm) || !isEmpty(candidate.copd))) {
      DiseaseState = () => (
        <div className="flex-row-container enable-shrink">
          {!isEmpty(candidate.ihd) && this.renderCandidateDiseaseState("IHD", candidate.ihd)}
          {!isEmpty(candidate.hf) && this.renderCandidateDiseaseState("HF", candidate.hf)}
          {!isEmpty(candidate.dm) && this.renderCandidateDiseaseState("DM", candidate.dm)}
          {!isEmpty(candidate.copd) && this.renderCandidateDiseaseState("COPD", candidate.copd)}
        </div>
      );
    }

    return (
      <DefaultPanelLayout
        title="Care Summary"
        icon={<CareSummaryIcon />}
      >
        <DefaultItem label="DISEASE STATE" content={<DiseaseState />} labelClassName="w-192" />
      </DefaultPanelLayout>
    );
  }
}

export default CareSummaryPanel;
