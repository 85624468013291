import React from "react";
import { Divider, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { map, isEmpty, compact } from "lodash";
import { matchPath } from "react-router";
import IconComponent from "components/items/icon-component";

export const Item = ({ withDivider, icon, label, ...other }) => (
  <>
    {withDivider && <Divider />}
    <MenuItem {...other}>
      <ListItemIcon>
        <IconComponent icon={icon} />
      </ListItemIcon>
      <ListItemText className="p-0" primary={label} />
    </MenuItem>
  </>
);

function SiteMenu({ menuItems, location, onClose }) {
  const items = compact(menuItems);
  return (
    map(items, item => {
      const isMatch = matchPath(location.pathname, {
        path: item.path,
        strict: true,
        exact: true,
      });

      return (
        <Item
          key={item.label}
          component={Link}
          to={{
            pathname: item.path,
            state: item.state,
          }}
          onClick={() => {
            if (item.onClick) {
              item.onClick();
            }

            onClose();
          }}
          selected={!isEmpty(isMatch)}
          icon={item.icon}
          label={item.label}
          withDivider={item.withDivider}
        />
      );
    })
  );
}

export default withRouter(SiteMenu);
