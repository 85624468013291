import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Snackbar, IconButton, Icon, SnackbarContent, Typography, Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { green, blue } from "@material-ui/core/colors";
import clsx from "clsx";
import { isEmpty, isString } from "lodash";

import * as Actions from "app/store/actions/message.actions";

/**
 * variant options
 * info / success / error / warning
 * Usage:
 * import { showMessage, hideMessage } 'app/settings/actions/message.actions';
 *
 * dispatch(showMessage({
      variant: 'success',
      message: 'message content',
      ...other Snackbar options ( more options see https://material-ui.com/api/snackbar/)
    }));
 */
const useStyles = makeStyles(theme => ({
  content: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.getContrastText(theme.palette.common.white),
  },
  info: {
    backgroundColor: blue[600],
    color: theme.palette.getContrastText(blue[600]),
  },
  success: {
    backgroundColor: green[600],
    color: theme.palette.getContrastText(green[600]),
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.getContrastText(theme.palette.error.dark),
  },
  warning: {
    backgroundColor: theme.palette.warning.dark,
    color: theme.palette.common.white,
  },
}));

const variantIcon = {
  success: "check_circle",
  warning: "warning",
  error: "error_outline",
  info: "info",
};

const Message = () => {
  const dispatch = useDispatch();
  const state = useSelector(({ message }) => message.state);
  const options = useSelector(({ message }) => message.options);
  const classes = useStyles();

  return (
    <Snackbar
      {...options}
      open={state}
      onClose={() => dispatch(Actions.hideMessage())}
    >
      <SnackbarContent
        className={clsx(classes.content, classes[options.variant])}
        message={(
          <Box className="flex items-center">
            {variantIcon[options.variant] && <Icon className="mr-8" color="inherit">{variantIcon[options.variant]}</Icon>}
            <Box>
              <Typography variant="body1" gutterBottom={!isEmpty(options.message)}>{options.title}</Typography>
              {isString(options.message)
                ? <Typography variant="body2">{options.message}</Typography>
                : options.message
              }
            </Box>
          </Box>
        )}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => dispatch(Actions.hideMessage())}
          >
            <Icon>close</Icon>
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default Message;
