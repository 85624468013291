/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, Chip } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import { isEmpty, debounce } from "lodash";

import ChatPanel from "components/items/chat-panel/index";
import DefaultListItem from "components/items/default-list-item";
import InfiniteLoadingList from "components/items/infinite-Loading-list";
import { PreferredLongDateTimeFormat } from "utils/date";
import formatDate from "helpers/format-date";
import DefaultButton from "components/items/default-button";
import LoadingState from "components/items/loading-state";
import TabContent from "components/items/tab-content";
import PreviewContent from "components/items/preview-content";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import ConfirmationDialog from "components/items/confirmation-dialog";
import { showMessage } from "app/store/actions/message.actions";
import CreateReferralForm from "app/main/referrals/components/create-referral-form";
import { searchReferralResults } from "app/main/referralWorklist/actions/referralWorklist.actions";
import { isReferralWorklistLoaded } from "app/main/referralWorklist/reducers/referralWorklist.reducers";
import { formatUtcDate } from "helpers/format-date-time";
import { openPdf } from "utils/download-from-api";
import PrintDialog from "components/items/print-dialog";
import withPermissions from "permissions/withPermissions";
import { DocumentWorklistIcon } from "helpers/icon-finder";

import { searchExternalSourceDocuments, setCurrentExternalSourceDocument, removeExternalSourceDocument, restoreExternalSourceDocument, fetchExternalSourceDocument } from "../actions/externalDocumentWorklist.actions";
import { getExternalSourceDocumentList, getPageInfo, getSearchParams, isPageLoading, getPageErrorMessage, getCurrentExternalSourceDocument, isCurrentExternalSourceDocumentLoading, EXTERNAL_DOCUMENT_PAGE_SIZE, isPageLoaded } from "../reducers/externalDocumentWorklist.reducers";
import Title from "../components/title";
import ExternalDocumentAdvancedFilter from "../components/external-document-advanced-filter";

const ExternalDocumentWorklistPage = ({
  forceLoad,
  hasPermissionReferralsCreate,
  hasPermissionExternalSourceDocumentPrint,
  hasPermissionExternalSourceDocumentRemove,
  hasPermissionExternalSourceDocumentRestore,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const documents = useSelector(getExternalSourceDocumentList);
  const pageInfo = useSelector(getPageInfo);
  const searchParams = useSelector(getSearchParams);
  const loading = useSelector(state => isPageLoading(state, pageInfo?.pageNumber));
  const loaded = useSelector(state => isPageLoaded(state, pageInfo?.pageNumber));
  const error = useSelector(state => getPageErrorMessage(state, pageInfo?.pageNumber));
  const isReferralListLoaded = useSelector(isReferralWorklistLoaded);

  const isCurrentLoading = useSelector(isCurrentExternalSourceDocumentLoading);
  const selectedDocument = useSelector(getCurrentExternalSourceDocument);
  const [printDialogOpen, setPrintDialogOpen] = useState(false);
  const [isSourceDocumentPrinting, setSourceDocumentPrinting] = useState(false);

  const onSearch = useCallback(debounce((params, pageNumber, pageSize, forceReLoad) => {
    dispatch(searchExternalSourceDocuments(pageNumber ?? 1, pageSize ?? EXTERNAL_DOCUMENT_PAGE_SIZE, params, forceReLoad));
  }, 300));

  useEffect(() => {
    // clear location search -> so we don't have to load the current document according to the url
    dispatch(setCurrentExternalSourceDocument(null));
    history.replace({ ...history.location, search: "" });
  }, []);

  const onClickItem = selectedItem => {
    dispatch(fetchExternalSourceDocument(selectedItem.externalSourceDocumentId));
    history.push(`/externalDocumentWorklist?id=${selectedItem.externalSourceDocumentId}`);
  };

  const onClose = () => dispatch(closeDialog());

  const goToSelectedReferral = id => history.push(`/referralWorklist?referralId=${id}`);

  const onCreateReferral = () => dispatch(openDialog({
    children: (
      <CreateReferralForm
        sourceDocument={selectedDocument}
        onSucceed={({ referral: { id } }) => {
          // make sure referrals are loaded
          if (isReferralListLoaded) {
            goToSelectedReferral(id);
          } else {
            dispatch(searchReferralResults(null, 1, 20, true)).then(() => goToSelectedReferral(id));
          }
        }}
      />
    ),
  }));

  const handlePrint = () => {
    setSourceDocumentPrinting(true);
    openPdf(`api/externalSourceDocuments/print?externalSourceDocumentId=${selectedDocument.externalSourceDocumentId}`).then(() => {
      setSourceDocumentPrinting(false);
      setPrintDialogOpen(false);
    });
  };

  const printConfirmationPopup = () => (
    <PrintDialog
      open={printDialogOpen}
      onPrint={handlePrint}
      loading={isSourceDocumentPrinting}
      onClose={() => setPrintDialogOpen(false)}
      onCancel={() => setPrintDialogOpen(false)}
      content={<Typography align="center">Please confirm you wish to print this document</Typography>}
    />
  );

  const removeOrRestorePopup = isRemove => {
    const confirmationMessage = isRemove ? "Please confirm you wish to remove this document"
      : "Please confirm you wish to restore this document";
    const apiCall = isRemove ? removeExternalSourceDocument : restoreExternalSourceDocument;

    dispatch(openDialog({
      maxWidth: "xs",
      fullScreen: false,
      children: (
        <ConfirmationDialog
          onCancel={onClose}
          onConfirm={() => {
            dispatch(apiCall(selectedDocument.externalSourceDocumentId)).then(response => {
              if (response.error === true) {
                dispatch(showMessage({ message: response.payload.exceptionMessage || "An error has occurred", variant: "error" }));
              }
            });
            onClose();
          }}
          title={<div className="text-center">{confirmationMessage}</div>}
          type="alert"
        />
      ),
    }));
  };

  const renderItem = item => {
    const { fileName, createdDate, id, status } = item;
    const isActive = !isEmpty(selectedDocument) && selectedDocument.fileName === fileName;
    const isRemoved = status === "Removed";
    return (
      <DefaultListItem
        id={id}
        onClick={isActive ? null : () => onClickItem(item)}
        active={isActive}
        content={(
          <>
            <Typography>{item.fileName}</Typography>
            <Typography variant="caption">{formatDate(createdDate)}</Typography>
            {isRemoved && <Chip size="small" label="Removed" className="ml-4" />}
          </>
      )}
      />
    );
  };

  const renderSidebarContent = () => (
    <InfiniteLoadingList
      data={documents}
      renderItem={item => renderItem(item)}
      loading={loading}
      error={error}
      isEmptyStatus={isEmpty(documents)}
      total={pageInfo.totalRecords}
      pageStart={pageInfo.pageNumber}
      pageLoaded={loaded}
      loadFunction={page => {
        const number = page === undefined ? 1 : pageInfo.pageNumber + 1;
        return onSearch(searchParams, number, pageInfo.pageSize, forceLoad);
      }}
    />
  );

  const renderHeader = () => {
    const { fileName, createdDate, status } = selectedDocument;
    const canCreateReferral = status !== "Removed" && hasPermissionReferralsCreate;
    const canRemoveDocument = status !== "Removed" && hasPermissionExternalSourceDocumentRemove;
    const canRestoreDocument = status === "Removed" && hasPermissionExternalSourceDocumentRestore;

    return (
      <Grid container className="py-24 px-16">
        <div className="flex-auto flex-row-container">
          <div className="enable-grow flex-auto">
            <Typography className="font-bold">{fileName}</Typography>
            <Typography>{formatUtcDate(createdDate, PreferredLongDateTimeFormat)}</Typography>
          </div>
          <div className="flex-row-container with-gutter">
            {canRemoveDocument && <DefaultButton tooltip="Remove Document" onClick={() => removeOrRestorePopup(true)} icon="delete" variant="outlined" /> }
            {canRestoreDocument && <DefaultButton tooltip="Restore Document" onClick={() => removeOrRestorePopup()} icon="autorenew" variant="outlined" /> }
            {hasPermissionExternalSourceDocumentPrint && <DefaultButton tooltip="Print Document" onClick={() => setPrintDialogOpen(true)} icon="print" variant="outlined" /> }
            {canCreateReferral && <DefaultButton label="Create referral" variant="outlined" onClick={onCreateReferral} />}
          </div>
        </div>
      </Grid>
    );
  };

  const renderContent = ({ width }) => {
    const pdfWidth = width > 960 ? 960 : width;

    if (isCurrentLoading) return <LoadingState />;

    return (
      <>
        {printConfirmationPopup()}
        <TabContent withGutters loading={isCurrentLoading} empty={!selectedDocument}>
          <PreviewContent
            fileData={selectedDocument?.fileData}
            mimeType={selectedDocument?.mimeType}
            pdfProps={{ width: pdfWidth }}
            hasPreview
          />
        </TabContent>
      </>
    );
  };

  return (
    <>
      <Title />
      <ChatPanel
        empty={isEmpty(selectedDocument)}
        renderSidebarHeader={() => (
          <ExternalDocumentAdvancedFilter
            total={pageInfo.totalRecords}
            initialValues={{ externalDocumentFilters: searchParams }}
            searchParams={searchParams}
            onSearch={onSearch}
          />
        )}
        renderSidebarContent={() => renderSidebarContent()}
        renderContent={({ width }) => renderContent({ width })}
        renderHeader={() => renderHeader()}
        emptyIcon={<DocumentWorklistIcon />}
        emptyTitle="Select a document to view details"
      />
    </>
  );
};

export default withPermissions(
  "ReferralsCreate",
  "ExternalSourceDocumentPrint",
  "ExternalSourceDocumentRemove",
  "ExternalSourceDocumentRestore",
)(ExternalDocumentWorklistPage);
