import React from "react";
import { InputLabel, Typography } from "@material-ui/core";
import checkIfEmpty from "helpers/check-if-empty";

const DisabledInput = ({ label, value }) => (
  <>
    <InputLabel shrink htmlFor={label}>{label}</InputLabel>
    <div className="MuiInputBase-root MuiInputBase-input mt-16 flex">
      <Typography>{checkIfEmpty(value) ? "N/A" : value}</Typography>
    </div>
  </>
);

export default DisabledInput;
