import React from "react";
import LoadingState from "components/items/loading-state";
import OrgUnitForm from "../components/orgUnit-form";

export default ({
  loading,
  orgUnit,
  formDisabled,
  onToggleFormStatus,
  orgUnitType,
}) => {
  if (loading) return <LoadingState />;

  return (
    <OrgUnitForm
      orgUnit={orgUnit}
      orgUnitType={orgUnitType}
      onCancel={() => onToggleFormStatus(true)}
      onSucceed={() => onToggleFormStatus(true)}
      disabled={formDisabled}
    />
  );
};
