import React from "react";
import { map, get } from "lodash";
import { IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { RecordIcon } from "helpers/icon-finder";
import ResponsiveTable, { TableCell, TableRow, TableBody } from "components/items/responsive-table";
import DefaultButton from "components/items/default-button";
import { addOrSaveMedicationHistoryProblem, recordNewMedicationProblem, editExistingMedicationHistoryProblem } from "app/main/patients/actions/medicationHistory.actions";
import RecordProblemForm from "app/main/patients/problems/components/record-problem-form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";

const PatientProblemTable = ({
  error, loading, isEmpty, medicationProblems, isCompleted, patientId, assessmentItemId, isEditable,
}) => {
  const dispatch = useDispatch();
  const disabled = isCompleted && !isEditable;

  const handleSubmit = ({ problem }, { setErrors }) => {
    const problemId = get(problem, "id", null);
    const medicationProblem = {
      assessmentItemId,
      problemJson: JSON.stringify(problem),
    };

    dispatch(addOrSaveMedicationHistoryProblem(patientId, problemId, medicationProblem)).then(res => {
      if (res.error !== true) {
        dispatch(closeDialog());
      } else {
        setErrors(res.payload);
      }
    });
  };

  const openRecordProblemForm = title => {
    dispatch(openDialog({
      children: (
        <RecordProblemForm
          title={title}
          handleSubmit={handleSubmit}
          isAssessment
        />
      ),
    }));
  };

  const onAdd = () => {
    dispatch(recordNewMedicationProblem());
    openRecordProblemForm("Add Problem");
  };

  const onEdit = problemId => {
    dispatch(editExistingMedicationHistoryProblem(patientId, problemId));
    openRecordProblemForm("Edit Problem");
  };

  const EditButton = ({ id }) => (
    <IconButton disabled={disabled} onClick={() => onEdit(id)}>
      <RecordIcon size={20} />
    </IconButton>
  );

  const renderTableHeader = () => (
    <TableRow>
      <TableCell>Problem</TableCell>
      <TableCell>Priority</TableCell>
      <TableCell>Notes</TableCell>
      <TableCell className="w-32" />
    </TableRow>
  );

  const renderTableRow = item => (
    <TableBody key={item.id}>
      <TableRow>
        <TableCell>{item.description}</TableCell>
        <TableCell>{item.priority}</TableCell>
        <TableCell>{item.note}</TableCell>
        <TableCell isEditButton><EditButton id={item.id} /></TableCell>
      </TableRow>
    </TableBody>

  );

  const renderTableFooter = () => <div className="flex"><DefaultButton size="small" variant="outlined" onClick={() => onAdd()} label="Add Problem" /></div>;

  return (
    <ResponsiveTable
      title="Add to problem list"
      disabled={disabled}
      isLoading={loading}
      isEmpty={isEmpty}
      error={error}
      renderTableContent={map(medicationProblems, m => renderTableRow(m))}
      renderTableHeader={renderTableHeader()}
      renderTableFooter={(!isCompleted || isEditable) && renderTableFooter()}
    />
  );
};

export default PatientProblemTable;
