import React, { useState } from "react";
import clsx from "clsx";
import { Hidden } from "@material-ui/core";
import { useDispatch } from "react-redux";

import IconButton from "components/items/icon-button";
import AttachmentIcon from "app/main/applications/components/attachment-icon";
import DefaultButton from "components/items/default-button";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import DialogPopup from "components/items/dialog-popup";
import ApplicationRemoveButton from "app/main/applicationWorklist/components/application-remove-button";
import AttachmentImagePreview from "./attachment-image-preview";
import { PreviewImageButton, PreviewPdfButton, DownloadButton } from "./attachment-action-buttons";

const AttachmentItem = ({
  isFirst,
  id,
  mimeType,
  children,
  title,
  subtitle,
  onRemove,
  canRemove,
  canDownload,
  onDownload,
  canPreviewPdf,
  onPreviewPdf,
  canPreviewImage,
  onPreviewImage,
  canViewLink,
  noDivider,
  onOpenLink,
  showIcon = true,
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loadingImageId: null, // it will take a bit to generate file data from api, so put a switch the image icon with a loading icon
    downloadingId: null,
    removingId: null,
    loadingPdfId: null,
  });

  const removeButton = () => (
    <ApplicationRemoveButton
      loading={state.removingId === id}
      onClick={() => {
        setState(prevState => ({ ...prevState, removingId: id }));
        Promise.resolve(onRemove()).then(() => {
          setState(prevState => ({ ...prevState, removingId: null }));
        });
      }}
    />
  );

  const openPreviewImage = attachment => dispatch(openDialog({
    disableBackdropClick: false,
    children: (
      <DialogPopup
        title={attachment.fileName}
        content={<AttachmentImagePreview mineType={attachment.mimeType} data={attachment.base64File} />}
        renderActions={(
          <div className="flex flex-1 justify-between items-center">
            {canRemove && onRemove ? <ApplicationRemoveButton onClick={() => { onRemove(); dispatch(closeDialog()); }} /> : <div />}
            <div className="flex-row-container with-gutter">
              {canDownload && onDownload && <DefaultButton onClick={onDownload} label="Download" size="medium" />}
              <DefaultButton color="default" onClick={() => dispatch(closeDialog())} label="Cancel" variant="text" size="medium" />
            </div>
          </div>
        )}
      />
    ),
  }));

  const linkButton = () => (
    <IconButton
      title="view link"
      iconSize={24}
      icon="open_in_new"
      onClick={onOpenLink}
    />
  );

  return (
    <div className={clsx("flex", !isFirst && !noDivider && "with-horizontal-divider")}>
      {showIcon && (
        <Hidden smDown>
          <AttachmentIcon className="w-40" type={mimeType || "link"} size={32} />
        </Hidden>
      )}
      <div className="flex-auto">
        <div className="flex-row-container">
          <div className="flex-1 enable-shrink">
            {title}
            {subtitle}
          </div>
          <div className="flex items-center">
            {canRemove && onRemove && removeButton()}
            {canDownload && onDownload && DownloadButton(() => {
              setState(prevState => ({ ...prevState, downloadingId: id }));
              Promise.resolve(onDownload()).then(() => setState(prevState => ({ ...prevState, downloadingId: null })));
            }, state.downloadingId === id)}
            {canPreviewPdf && onPreviewPdf && PreviewPdfButton(() => {
              setState(prevState => ({ ...prevState, loadingPdfId: id }));
              Promise.resolve(onPreviewPdf()).then(() => setState(prevState => ({ ...prevState, loadingPdfId: null })));
            }, state.loadingPdfId === id)}
            {canPreviewImage && onPreviewImage && PreviewImageButton(() => {
              setState(prevState => ({ ...prevState, loadingImageId: id }));
              onPreviewImage().then(res => {
                openPreviewImage(res.payload);
                setState(prevState => ({ ...prevState, loadingImageId: null }));
              });
            }, state.loadingImageId === id)}
            {canViewLink && onOpenLink && linkButton()}
          </div>
        </div>
        {children && <div className="mt-8">{children}</div>}
      </div>
    </div>
  );
};

export default AttachmentItem;
