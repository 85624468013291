import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { map, startCase } from "lodash";
import { AutoComplete, AutoCompleteLoading } from "components/inputs";
import { fetchAllUserRoles } from "app/main/users/actions/users.actions";
import { getAllRoles, areRolesLoading } from "app/main/users/reducers/users.reducers";
import { AssignedRoleIcon } from "helpers/icon-finder";

export default ({
  label,
  showIcon = true,
  ...other
}) => {
  const dispatch = useDispatch();
  const options = useSelector(getAllRoles);
  const loading = useSelector(areRolesLoading);

  React.useEffect(() => {
    dispatch(fetchAllUserRoles());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <AutoCompleteLoading label={label} />;

  return (
    <AutoComplete
      icon={showIcon && <AssignedRoleIcon />}
      label={label}
      options={map(options, x => ({ label: x.roleDescription ?? startCase(x.roleName), value: x.roleId }))}
      filterSelectedOptions
      getOptionSelected={(option, val) => option.value === val.value}
      {...other}
    />
  );
};
