import React from "react";
import { connect } from "react-redux";
import { Field, getIn } from "formik";
import { Typography, Grid } from "@material-ui/core";

import { TextInput, NoteInput } from "components/inputs/text-input";
import CheckboxInput from "components/inputs/checkbox";
import { getCurrentMedicationEntry } from "app/main/patients/reducers/medicationHistory.reducers";
import { addOrSaveMedicationHistoryEntry } from "app/main/patients/actions/medicationHistory.actions";

import { MedicationIcon, ChartIcon } from "helpers/icon-finder";
import { validateRequired, validateDateIsAfter } from "utils/validators";
import { DatePicker } from "components/inputs";
import Form from "components/form";
import { TODAY } from "utils/date";

const medicationDescription = "Medication";
const medicationForm = "Form";
const medicationStrength = "Strength";
const medicationStartDate = "Start Date";
const medicationEndDate = "End Date";
const medicationNote = "Note";
const validateDescription = validateRequired(medicationDescription);
const validateForm = validateRequired(medicationForm);
const validateStrength = validateRequired(medicationStrength);
const validateStartDate = validateRequired(medicationStartDate);
const validateEndDate = validateDateIsAfter(medicationEndDate, medicationStartDate, ["medicationEntry", "startDate"]);

class RecordMedicationHistoryForm extends React.PureComponent {
  handleSubmit = ({ medicationEntry }, { setErrors }) => {
    const { assessmentItemId, patientId } = this.props;
    const newEntry = {
      ...medicationEntry,
      assessmentItemId,
      actionDate: TODAY,
      term: medicationEntry.term,
    };

    this.props.addOrSaveMedicationHistoryEntry(patientId, newEntry).then(res => {
      if (res.error !== true) {
        this.props.onSucceed();
      } else {
        setErrors(res.payload);
      }
    });
  };

  render() {
    const {
      isEdit,
      initialValues,
      readOnly,
      ...other
    } = this.props;

    return (
      <Form
        contentProps={other}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        content={props => {
          // this is used to prevent show error when editing exiting medication which is past today
          const startDateIsTouched = getIn(props.touched, ["medicationEntry", "startDate"], false);
          const { medicationEntry: { isCurrent } } = props.values;
          const shouldStartDateValidated = startDateIsTouched || !isEdit;

          return (
            <>
              <Grid container direction="row" alignItems="center" style={{ minHeight: "80px" }}>
                <Grid item xs={3}>
                  <Typography className="font-bold">Medication Status</Typography>
                </Grid>
                <Grid item xs>
                  <Field
                    name="medicationEntry.isCurrent"
                    label="Current"
                    component={CheckboxInput}
                    disabled={readOnly}
                    onChange={(value, change) => {
                      if (value === false) {
                        change("medicationEntry.endDate", TODAY);
                      } else {
                        change("medicationEntry.endDate", null);
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Field
                name="medicationEntry.term"
                icon="mode_comment"
                label={medicationDescription}
                component={TextInput}
                validate={validateDescription}
                disabled={readOnly}
              />
              <Field
                name="medicationEntry.form"
                component={TextInput}
                icon={<MedicationIcon />}
                label={medicationForm}
                validate={validateForm}
                disabled={readOnly}
              />
              <Field
                name="medicationEntry.strength"
                component={TextInput}
                icon={<ChartIcon />}
                label={medicationStrength}
                validate={validateStrength}
                disabled={readOnly}
              />
              <Field
                name="medicationEntry.startDate"
                label={medicationStartDate}
                component={DatePicker}
                validate={validateStartDate}
                isPast={shouldStartDateValidated}
                disabled={readOnly}
              />
              <Field
                name="medicationEntry.endDate"
                label={medicationEndDate}
                component={DatePicker}
                disabled={isCurrent}
                validate={value => validateEndDate(value, props.values)}
              />
              <Field
                name="medicationEntry.note"
                label={medicationNote}
                component={NoteInput}
              />
            </>
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const medicationEntry = getCurrentMedicationEntry(state);

  return {
    initialValues: { medicationEntry },
    readOnly: ownProps.isEdit && medicationEntry.patientMedicationId !== null,
  };
};

export default connect(mapStateToProps, {
  addOrSaveMedicationHistoryEntry,
})(RecordMedicationHistoryForm);
