import React from "react";
import { CardMedia } from "@material-ui/core";
import { isEmpty } from "lodash";
import EmptyState from "components/items/empty-state";

export default ({
  mimeType,
  data, // base64
}) => {
  if (isEmpty(data)) {
    return <EmptyState title="No Preview Available" />;
  }

  return (
    <CardMedia
      style={{
        minHeight: 350,
        backgroundSize: "contain",
      }}
      image={`data:${mimeType};base64,${data}`}
    />
  );
};
