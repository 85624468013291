import React, { useState } from "react";
import { map } from "lodash";

import ResponsiveTable, { TableCell, TableRow, TableBody } from "components/items/responsive-table";
import DialogPopup from "components/items/dialog-popup";
import LoadingState from "components/items/loading-state";
import formatFilterDate from "helpers/format-filter-date";
import { DownloadIcon } from "helpers/icon-finder";
import { openPdf } from "utils/download-from-api";
import IconButton from "components/items/icon-button";

const AssessmentPrintHistory = ({
  loading,
  patientId,
  assessmentPrintHistory,
}) => {
  const [generatingPDF, setPDFState] = useState(false);

  const DownloadButton = ({ hasFile, assessmentPrintRecordId }) => (
    <IconButton
      color="primary"
      title="Download print history"
      disabled={!hasFile}
      loading={generatingPDF === assessmentPrintRecordId}
      icon={<DownloadIcon />}
      onClick={() => {
        setPDFState(assessmentPrintRecordId);
        openPdf(`api/patient/${patientId}/assessments/getAssessmentPrintRecordFile/${assessmentPrintRecordId}`).then(() => {
          setPDFState(null);
        });
      }}
    />
  );

  const renderTableHeader = () => (
    <TableRow>
      <TableCell>Printed By</TableCell>
      <TableCell>Printed On</TableCell>
      <TableCell>Download Print</TableCell>
    </TableRow>
  );

  const renderTableRow = item => (
    <TableBody key={item.id}>
      <TableRow>
        <TableCell title="Printed By">{item.printedByUser}</TableCell>
        <TableCell title="Printed On">{formatFilterDate(item.printedDateTimeUtc, "d-MMM-YYYY HH:mm")}</TableCell>
        <TableCell title="Download Print">
          <div className="h-48">
            <DownloadButton hasFile={item.hasFile} assessmentPrintRecordId={item.id} />
          </div>
        </TableCell>
      </TableRow>
    </TableBody>
  );

  const Content = () => {
    if (loading) {
      return <LoadingState />;
    }
    return (
      <ResponsiveTable
        renderTableHeader={renderTableHeader()}
        renderTableContent={map(assessmentPrintHistory, a => renderTableRow(a))}
      />
    );
  };

  return (
    <DialogPopup title="Print History" content={<Content />} />
  );
};

export default AssessmentPrintHistory;
