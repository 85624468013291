import React from "react";
import clsx from "clsx";
import { makeStyles, fade } from "@material-ui/core/styles";
import { Tabs, Tab, Drawer } from "@material-ui/core";
import { map } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { isMobileContentSidebarOpen } from "app/store/reducers/sidebars.reducer";
import { setMobileContentSidebarStatus } from "app/store/actions/sidebars.actions";

import SidebarTogglePortal from "portals/sidebarTogglePortal";
import IconButton from "components/items/icon-button";

const SIDE_BAR_MAX_WIDTH = 250;

const useStyles = makeStyles(theme => ({
  root: {
    width: SIDE_BAR_MAX_WIDTH,
    height: "100%",
    position: "relative",
  },
  content: {
    position: "absolute",
    width: "inherit",
  },
  listItem: {
    minHeight: 72,
    minWidth: "auto",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  wrapper: {
    flexDirection: "row",
    justifyContent: "flex-start",
    "& > svg": {
      flex: "0 0 auto",
      justifyContent: "flex-start",
      minWidth: 48,
      marginBottom: "0 !important",
    },
  },
  selectedListItem: {
    backgroundColor: fade(theme.palette.primary[200], 0.2),
  },
}));

const SideTabBar = ({
  routes,
  handleTabChange,
  currentTab,
  classes,
  drawerProps,
  isOpen,
  menuVariant,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const mobileContentSidebarOpen = useSelector(state => isMobileContentSidebarOpen(state));

  return (
    <>
      <SidebarTogglePortal>
        {!isOpen && (
          <div className="flex justify-center ml-8">
            <IconButton color="inherit" icon="subject" title="Open menu" onClick={() => dispatch(setMobileContentSidebarStatus(!mobileContentSidebarOpen))} />
          </div>
        )}
      </SidebarTogglePortal>
      <Drawer
        variant={menuVariant}
        open={menuVariant === "temporary" ? mobileContentSidebarOpen : true}
        classes={{ root: clsx(styles.root, classes?.root), paper: clsx(styles.content, classes?.content) }}
        ModalProps={{
          keepMounted: true,
          disablePortal: true,
          BackdropProps: { styles: { root: "absolute" } },
        }}
        {...drawerProps}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={currentTab}
          onChange={(_event, val) => {
            handleTabChange(val);
            dispatch(setMobileContentSidebarStatus(false));
          }}
        >
          {map(routes, item => (
            <Tab
              disableRipple
              key={item.value}
              icon={item.icon}
              value={item.value}
              label={item.label}
              classes={{ root: styles.listItem, wrapper: styles.wrapper, selected: styles.selectedListItem }}
            />
          ))}
        </Tabs>
      </Drawer>
    </>
  );
};

export default SideTabBar;
