import { get, mapKeys, map, find } from "lodash";
import curryConnector from "utils/connectors";

export const PATIENT_ACCESS_STATE_KEY = "patientAccess";
export const PATIENT_ACCESS_LOG_PAGE_SIZE = 25;

const curry = fn => curryConnector(fn, PATIENT_ACCESS_STATE_KEY);

export const ActionTypes = {
  LOADING_PATIENT_ACCESS_LOGS: "LOADING_PATIENT_ACCESS_LOGS",
  LOADED_PATIENT_ACCESS_LOGS: "LOADED_PATIENT_ACCESS_LOGS",
  ERROR_LOADING_PATIENT_ACCESS_LOGS: "ERROR_LOADING_PATIENT_ACCESS_LOGS",
  RESET_PATIENT_ACCESS_LOGS_FILTERS: "RESET_PATIENT_ACCESS_LOGS_FILTERS",
};

export const DefaultSearchParams = {
  name: null,
  accessedStartDate: null,
  accessedEndDate: null,
  sortBy: "AccessedDateDesc",
  userId: null,
};

const INITIAL_STATE = {
  all: null,
  pages: { loading: false, loaded: false, error: null, ids: null },
  searchParams: DefaultSearchParams,
  pageInfo: { pageNumber: 1, pageSize: PATIENT_ACCESS_LOG_PAGE_SIZE, totalRecords: 0 },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_PATIENT_ACCESS_LOGS:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: true, loaded: false, error: false } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.LOADED_PATIENT_ACCESS_LOGS:
      return {
        ...state,
        all: mapKeys(action.payload.logs, x => x.id),
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: false, error: false, loaded: true, ids: map(action.payload.logs, x => x.id) } },
        pageInfo: action.payload.pageInfo,
        searchParams: action.payload.searchParams,
      };
    case ActionTypes.ERROR_LOADING_PATIENT_ACCESS_LOGS:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: false, error: action.payload.message, loaded: false } },
      };
    case ActionTypes.RESET_PATIENT_ACCESS_LOGS_FILTERS:
      return { ...state, searchParams: INITIAL_STATE.searchParams };
    default:
      return state;
  }
};

export const getPatientAccessLogs = curry(({ localState }) => {
  const users = get(localState, ["all"], {});
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  const ids = get(localState.pages, [pageNumber, "ids"], []);
  return map(ids, x => find(users, y => y.id === x));
});

export const isPageLoading = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loading"], false);
});

export const isPageLoaded = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loaded"], false);
});

export const getErrorMessage = curry(({ localState }, id, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "error"], null);
});

export const getPageInfo = curry(({ localState }) => localState.pageInfo);

export const getSearchParams = curry(({ localState }) => localState.searchParams);
