import React from "react";

import MedicationListContainer from "app/main/patients/medications/containers/medication-list-container";
import MedicationList from "app/main/patients/medications/components/medication-list";
import { HeaderAction } from "app/main/patients/components/patient-components";
import PatientRecordPageContent from "app/main/patients/components/patient-record-page-content";

export default function PatientMedicationPage({ patientId }) {
  return (
    <MedicationListContainer
      patientId={patientId}
      renderContent={({ onRecordMedication, ...other }) => (
        <PatientRecordPageContent pageAction={onRecordMedication && <HeaderAction onClick={onRecordMedication} label="Add Medication" />}>
          <MedicationList {...other} />
        </PatientRecordPageContent>
      )}
    />
  );
}
