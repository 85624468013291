import React from "react";
import { Alert } from "@material-ui/lab";
import EmptyState from "components/items/empty-state";
import { PatientAgeLabel, NameLabel, PatientIdentifierLabel, AddressDetailsLabel, PatientSexLabel } from "app/main/patients/components/patient-details";
import DefaultButton from "components/items/default-button";
import PatientApplicationContainer from "app/main/applications/components/patient-application-container";

const ApplicationPatientSummaryCard = ({
  patient,
  orgUnitId,
}) => {
  if (!patient) return <Alert icon={false} className="justify-center" color="info"><EmptyState icon="account_circle" /></Alert>;

  const { displayNameFull, birthDate, patientIdentifiers, address, sex } = patient;
  const showEdit = false; // to enable edit patient

  return (
    <PatientApplicationContainer
      orgUnitId={orgUnitId}
      renderContent={({ onSearchExternalPatient }) => (
        <Alert
          icon={false}
          color="info"
          classes={{ action: "items-start p-8" }}
          action={showEdit && <DefaultButton label="Edit" variant="outlined" onClick={onSearchExternalPatient} />}
        >
          <div className="items-container px-8">
            <div className="flex-row-container with-gutter">
              <NameLabel name={displayNameFull} />
              <PatientAgeLabel birthDate={birthDate} formatDOB />
              <PatientSexLabel sex={sex} />
            </div>
            <PatientIdentifierLabel patientIdentifiers={patientIdentifiers} />
            <AddressDetailsLabel address={address} />
          </div>
        </Alert>
      )}
    />
  );
};

export default ApplicationPatientSummaryCard;
