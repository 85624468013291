import { green, amber, red } from "@material-ui/core/colors";

export default function getEnrolmentStatus(status) {
  switch (status) {
    case "Selected":
      return {
        label: "PENDING",
        color: amber[600],
      };
    case "Consented":
      return {
        label: "CONSENTED",
        color: green[600],
      };
    case "Withdrawn":
      return {
        label: "WITHDRAWN",
        color: red[600],
      };
    case "NotConsented":
      return {
        label: "NOT CONSENTED",
        color: red[600],
      };
    default:
      return {
        label: "",
        color: "transparent",
      };
  }
}
