import curryConnector from "utils/connectors";
import { head, get } from "lodash";
import { addYears, addMonths } from "date-fns";
import formatFilterDate from "helpers/format-filter-date";
import { getPatientLabel } from "utils/get-environment-variables";

export const WORKLIST_STATE_KEY = "worklist";

const curry = fn => curryConnector(fn, WORKLIST_STATE_KEY);

const patientLabel = getPatientLabel();

export const filterOptions = [
  { value: "myCurrentClients", label: `My Current ${patientLabel.plural}` },
  { value: "myCandidatesToContact", label: "My Candidates to Contact" },
  { value: "allClients", label: `All ${patientLabel.plural}` },
  { value: "callMeMaybe", label: "Consider Calling Again" }, /* So call me, maybe */
  { value: "ineligible", label: "Ineligible" },
  { value: "WelfareCheck", label: "My Welfare Check Members to Contact" },
  { value: "advancedFilter", label: "Advanced filter" },
];

export const defaultFilterOption = head(filterOptions);

export const ActionTypes = {
  SEARCHING_PATIENTS: "SEARCHING_PATIENTS",
  SEARCHED_PATIENTS: "SEARCHED_PATIENTS",
  ERROR_SEARCHING_PATIENTS: "ERROR_SEARCHING_PATIENTS",
  SET_WORKLIST_FILTERS: "SET_WORKLIST_FILTERS",
};

export const defaultSearchParams = {
  name: null,
  enrolmentType: "SalveoNursing",
  enrolmentStatus: ["Selected", "Consented"], // pending/selected, consented
  diseaseState: [],
  encounterStartDate: null,
  encounterEndDate: null,
  appointmentStartDate: null,
  appointmentEndDate: null,
  ineligibleProduct: false,
  assignedToUserId: null,
  assignedToMe: false,
  overdue: false,
  postcode: [],
  doNotContactAgain: false,
};

export const getPresetFilterParams = filterValue => {
  const filter = get(filterValue, "value", filterValue);
  switch (filter) {
    case "myCurrentClients":
      return {
        ...defaultSearchParams,
        enrolmentStatus: ["Consented"],
        assignedToMe: true,
      };
    case "myCandidatesToContact":
      return {
        ...defaultSearchParams,
        enrolmentStatus: ["Selected", "NotConsented"],
        assignedToMe: true,
      };
    case "callMeMaybe":
      return {
        ...defaultSearchParams,
        enrolmentStatus: ["NotConsented"], // not consented
        encounterStartDate: formatFilterDate(addYears(Date.now(), -2)), // start date = now - 2 year
        encounterEndDate: formatFilterDate(addMonths(Date.now(), -6)), //  end date = now - 6months
      };
    case "ineligible":
      return {
        ...defaultSearchParams,
        ineligibleProduct: true,
        doNotContactAgain: null,
      };
    case "WelfareCheck":
      return {
        ...defaultSearchParams,
        enrolmentStatus: null,
        enrolmentType: "WelfareCheck",
        assignedToMe: true,
      };
    default:
      return defaultSearchParams;
  }
};

const INITIAL_STATE = {
  loading: false,
  error: null,
  loaded: false,
  searchParams: getPresetFilterParams(defaultFilterOption),
  selectedId: null,
  pageNumber: 1,
  total: 0,
  presetFilter: defaultFilterOption,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SEARCHING_PATIENTS:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.ERROR_SEARCHING_PATIENTS:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case ActionTypes.SEARCHED_PATIENTS:
      return {
        ...state,
        loading: false,
        loaded: true,
        searchParams: action.payload.searchParams,
        total: action.payload.total,
        pageNumber: action.payload.pageNumber,
      };
    case ActionTypes.SET_WORKLIST_FILTERS:
      return {
        ...state,
        presetFilter: action.payload.filter,
      };
    default:
      return state || INITIAL_STATE;
  }
};

export const getWorklistFilter = curry(({ localState }) => localState.presetFilter);

export const isPatientWorklistLoading = curry(({ localState }) => localState.loading);

export const isPatientWorklistErrorLoading = curry(({ localState }) => localState.error);

export const getCurrentPatientId = curry(({ localState }) => localState.selectedId);

export const getTotalNumberOfPatient = curry(({ localState }) => localState.total);

export const getCurrentPageOfPatient = curry(({ localState }) => localState.pageNumber);

export const getPatientSearchParams = curry(({ localState }) => localState.searchParams);

export const bailOutOfLoadingPatients = curry(({ localState }, pageNumber) => {
  const { loading, total } = localState;
  if (loading || (pageNumber === 1 && total !== 0)) {
    return true;
  }
  return false;
});
