import React from "react";
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Typography, FormHelperText } from "@material-ui/core";
import { map, isString, isEmpty, get, isNull } from "lodash";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import FormContext from "components/FormContext";

const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: "row",
    minHeight: 56,
    flex: 1,
  },
  gutter: {
    paddingTop: theme.spacing(1),
  },
  extra: {
    paddingLeft: theme.spacing(1),
    zIndex: 1000,
  },
  helperText: {
    color: theme.palette.text.hint,
    display: "flex",
    minHeight: 20,
  },
}));

const RadioButtonsGroup = ({
  field,
  label,
  name,
  choices,
  isBoolean,
  hideError,
  form: { submitCount, errors, setFieldValue },
  onChange,
  displayRow,
  displayErrorAbove,
  allowUnCheck, // allow un-select
  className,
  spacing = 16,
  disabled,
  helperText,
}) => {
  const classes = useStyles();
  const ErrorComponent = () => submitCount > 0 && <FormHelperText error={!isEmpty(errors)}>{get(errors, field.name, null)}</FormHelperText>;

  return (
    <FormContext.Consumer>
      {({ formDisabled }) => (
        <FormControl component="fieldset" className={className}>
          {!hideError && displayErrorAbove && <ErrorComponent />}
          {label && <FormLabel component="legend">{label}</FormLabel>}
          <RadioGroup
            aria-label={label}
            name={name}
            value={String(field.value)}
            disabled={disabled || formDisabled}
            onChange={event => {
              if (isBoolean) {
                field.onChange(field.name)(event.target.value === "true");
              } else {
                field.onChange(field.name)(event.target.value);
              }

              if (onChange) {
                onChange(event.target.value, setFieldValue);
              }
            }}
            classes={{ root: clsx(displayRow && classes.container) }}
          >
            {map(choices, (choice, index) => (
              <FormControlLabel
                value={String(choice.value)}
                control={(
                  <Radio
                    onClick={event => {
                      if (allowUnCheck && !isNull(field.value)) {
                        field.onChange(field.name)(null);
                        if (onChange && event.target.value === field.value) {
                          onChange(null, setFieldValue);
                        }
                      }
                    }}
                  />
                )}
                classes={{ root: clsx((index !== 0 && !displayRow) && spacing ? `pt-${spacing}` : classes.gutter) }}
                label={(
                  <div className="flex flex-col">
                    <div className="flex items-center">
                      {isString(choice.label) ? <Typography variant="body1">{choice.label}</Typography> : choice.label}
                      {choice.renderExtraContent && <div className={classes.extra}>{choice.renderExtraContent}</div>}
                    </div>
                    {choice.description && <Typography variant="caption">{choice.description}</Typography>}
                  </div>
                )}
                key={index}
              />
            ))}
          </RadioGroup>
          {helperText && <FormHelperText className={`${classes.helperText} pt-${spacing}`}>{helperText}</FormHelperText>}
          {!hideError && !displayErrorAbove && <ErrorComponent />}
        </FormControl>
      )}
    </FormContext.Consumer>
  );
};

export default RadioButtonsGroup;
