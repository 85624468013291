import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AccessDeniedState } from "components/items/empty-state";
import { setUpPatientReferralDefaultSearchParams } from "app/main/referrals/actions/patientReferrals.actions";
import { isSystemConfigurationLoaded } from "app/auth/store/reducers/system-configuration";

import LoadingState from "components/items/loading-state";
import PatientReferralWorklistPage from "./patient-referral-worklist-page";

const hidePatientView = process.env.REACT_APP_HIDE_REFERRALS_PATIENT_VIEW === "true"; // temp, TODO replace with permission

const SecurePatientReferralWorklistPage = () => {
  const dispatch = useDispatch();
  const [isLoadingSettings, setLoadingSettings] = React.useState(true);
  const isSystemConfigLoaded = useSelector(isSystemConfigurationLoaded);

  useEffect(() => {
    if (isSystemConfigLoaded) {
      dispatch(setUpPatientReferralDefaultSearchParams()).then(() => setLoadingSettings(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoadingSettings) return <LoadingState />;

  if (hidePatientView) return <AccessDeniedState />;

  return <PatientReferralWorklistPage />;
};

export default SecurePatientReferralWorklistPage;
