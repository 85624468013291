import { RSAA } from "redux-api-middleware";
import { getBaseUrl } from "utils/get-environment-variables";

const BASE_URL = getBaseUrl();
const API_PREFIX = `${BASE_URL}api`;

export default (/* store */) => next => action => {
  const callAPI = action[RSAA];

  if (typeof callAPI === "undefined") {
    return next(action);
  }

  const { endpoint } = callAPI;

  if (endpoint.startsWith("http")) {
    // This is a full url (either http or https) don't touch it
    return next(action);
  }

  // append the api prefix onto the url
  return next({
    [RSAA]: { ...callAPI, endpoint: API_PREFIX + endpoint },
  });
};
