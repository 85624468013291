import React, { useRef, useState, useEffect } from "react";
import { Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    minHeight: 0,
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      overflow: "hidden",
      flexDirection: "row",
      "& > *": {
        flex: "0 1 50%",
      },
    },
  },
  contentContainer: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    overflowX: "hidden",
    overflowY: "auto",
    position: "relative",
    flex: "1 1 auto",
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

const ReferralForm = ({
  renderPreview,
  children,
}) => {
  const classes = useStyles();
  const ref = useRef();
  const [width, setWidth] = useState(0);

  const updateWidth = () => {
    setWidth(ref.current?.clientWidth / 2);
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  return (
    <div className={classes.root} ref={ref}>
      <Hidden smDown>
        {renderPreview && renderPreview({ width })}
      </Hidden>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ReferralForm;
