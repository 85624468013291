import { RSAA } from "redux-api-middleware";
import { padStart, get, pick } from "lodash";
import { getBaseUrl } from "utils/get-environment-variables";

import handleError from "./error-handler";
import { getError } from "utils/errorutil";
import { getAccessToken } from "app/auth/msalConfig";

const API_TIMEOUT = 3000;
const BASE_URL = getBaseUrl();
const API_PREFIX = `${BASE_URL}api`;

export const ApiActions = {
  FETCH_FROM_API: "Fetch From API",
  SAVE_TO_API: "Save to API",
  POST_TO_API: "Post to API",
  DELETE_FROM_API: "Delete from API",
};

function addOtherParams(res, otherParams) {
  if (res === undefined) {
    return otherParams;
  }

  return res
    .text()
    .then(response => (response
      ? { ...JSON.parse(response), ...otherParams }
      : { ...otherParams }));
}

function buildActionTypes(
  entityType,
  fetchingPrefix,
  fetchedPrefix,
  types,
  otherParams,
  loadMore = false,
) {
  let fetchedPrefixValue = fetchedPrefix;
  if (loadMore) fetchedPrefixValue += "_MORE";

  return [
    {
      type:
        types != null
          ? types[0]
          : `${fetchingPrefix}_${entityType.toUpperCase()}`,
      payload: (RSAAaction, state, response) => addOtherParams(response, otherParams),
    },
    {
      type:
        types != null
          ? types[1]
          : `${fetchedPrefixValue}_${entityType.toUpperCase()}`,
      payload: (RSAAaction, state, response) => addOtherParams(response, otherParams),
    },
    {
      type:
        types != null
          ? types[2]
          : `ERROR_${fetchingPrefix}_${entityType.toUpperCase()}`,
      payload: (RSAAaction, state, response) => {
        return addOtherParams(response, otherParams);
      },
      meta: (RSAAaction, state, response) => {
        return {
          status: response?.status,
          statusText: response?.statusText,
        }
      }
    },
  ];
}

function createApiMiddleware(
  actionConstant,
  defaultMethod,
  fetchingPrefix,
  fetchedPrefix,
) {
  return store => next => (action) => {
    const parameters = action[actionConstant];

    if (typeof parameters === "undefined") {
      return next(action);
    }

    const {
      entityType,
      types,
      method,
      body,
      endpoint,
      headers,
      bailout,
      loadMore,
      ...otherParams
    } = parameters;

    return store.dispatch({
      [RSAA]: {
        body,
        endpoint,
        headers,
        method: method || defaultMethod,
        bailout,
        types: buildActionTypes(
          entityType,
          fetchingPrefix,
          fetchedPrefix,
          types,
          otherParams,
          loadMore,
        ),
      },
    })
    .then(response => {
      if(response?.error){
        const responseError = getError(response);
        return {
          ...response,
          payload: {
            ...responseError,
          },
        };
      }
      return response;
    });
  };
}

function getTimezoneOffsetString() {
  const offset = new Date().getTimezoneOffset();
  return `${offset < 0 ? "+" : "-"}${padStart(
    parseInt(Math.abs(offset / 60), 10),
    2,
    "0",
  )}${padStart(parseInt(Math.abs(offset % 60), 10), 2, "0")}`;
}

async function addHeaders(callAPI) {
  const timezoneOffset = getTimezoneOffsetString();
  let bearerToken = await getAccessToken();
 
  // React Native appears to correctly be setting the Content-Type boundary however React Web doesn't set it.
  // This workaround removes the content type header to allow it ot be set automatically with multipart form data (Files)
  if (get(callAPI.headers, ["Content-Type"], null) === "multipart/form-data") {
    const filteredHeaders = pick(callAPI.headers, !"Content-Type");
    return {
      ...callAPI,
      headers: {
        Accept: "application/json",
        options: { timeout: API_TIMEOUT },
        "X-Client-Timezone-Offset": timezoneOffset,
        ...bearerToken && { Authorization: `Bearer ${bearerToken}` },
        ...filteredHeaders,
      },
    };
  }

  return {
    ...callAPI,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      options: { timeout: API_TIMEOUT },
      "X-Client-Timezone-Offset": timezoneOffset,
      ...bearerToken && { Authorization: `Bearer ${bearerToken}` },
      ...callAPI.headers,
    },
  };
}

export const fetchFromApi = createApiMiddleware(
  ApiActions.FETCH_FROM_API,
  "GET",
  "LOADING",
  "LOADED",
);
export const saveToApi = createApiMiddleware(
  ApiActions.SAVE_TO_API,
  "PUT",
  "SAVING",
  "SAVED",
);
export const postToApi = createApiMiddleware(
  ApiActions.POST_TO_API,
  "POST",
  "CREATING",
  "CREATED",
);
export const deleteFromApi = createApiMiddleware(
  ApiActions.DELETE_FROM_API,
  "DELETE",
  "DELETING",
  "DELETED",
);

export const makeApiCall = store => next => async (action) => {
  const callAPI = action[RSAA];

  if (typeof callAPI === "undefined") {
    return next(action);
  }

  const { endpoint, method, body, headers } = callAPI;

  if (!endpoint.startsWith(API_PREFIX)) {
    // This is a request to a external site - don't touch it
    return next(action);
  }

  const contentType = get(headers, ["Content-Type"], null);

  if (
    method !== "GET"
    && typeof body !== "string"
    && contentType !== "multipart/form-data"
  ) {
    callAPI.body = JSON.stringify(body);
  }

  const newCallAPI = await addHeaders(callAPI);
  return next({
    [RSAA]: {
      ...newCallAPI,
      fetch: async (...args) => {
        const res = await fetch(...args);

        if (!res.ok) {
          handleError(res, store);
        }

        return res;
      },
    },
  });
};