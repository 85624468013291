import React from "react";
import { map } from "lodash";
import ProcedureListItem from "app/main/patients/procedures/components/procedure-list-item";
import StateContainer from "components/layouts/default-state-container";

const ProcedureList = ({
  procedures,
  onEditProcedure,
  onRemoveProcedure,
  isEmpty,
  isError,
  isLoading,
}) => (
  <StateContainer loading={isLoading} error={isError} empty={isEmpty} emptyTitle="No Recorded Procedures">
    {map(procedures, (procedure, index) => (
      <ProcedureListItem
        item={procedure}
        key={procedure.id}
        isFirst={index === 0}
          // actions
        onEdit={onEditProcedure ? () => onEditProcedure(procedure.id) : null}
        onRemove={onRemoveProcedure ? () => onRemoveProcedure(procedure.id) : null}
      />
    ))}
  </StateContainer>
);

export default ProcedureList;
