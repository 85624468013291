import React from "react";
import { Field } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { isEmpty, get, every, some } from "lodash";
import AccountTieOutlineIcon from "mdi-react/AccountTieOutlineIcon";
import ThumbsUpDownIcon from "mdi-react/ThumbsUpDownIcon";

import Form from "components/form";
import { TextInput, TextAreaInput, SelectInput } from "components/inputs";
import { getDefaultAddressState, getHelperTextBySectionName } from "app/auth/store/reducers/system-configuration";
import HelperText from "components/inputs/components/helper-text";

import SpecialtySelector from "../../specialtyProfiles/components/specialty-selector";

const tooLong = "Too Long!";

const consultantSpecialtyLabel = "Consultant Specialty";
const otherSpecialtyLabel = "Other Specialty";
const givenNameLabel = "Given Name";
const familyNameLabel = "Family Name";
const contactNumberLabel = "Phone Number";
const emailLabel = "Email";

const suburbLabel = "City/Suburb";
const stateLabel = "State";
const postcodeLabel = "Postcode";
const roleDescriptionLabel = "Role";
const conflictOfInterestLabel = "Conflict Of Interest";

const contactMethods = [
  { value: "Email", label: "Email" },
  { value: "PostalAddress", label: "Post" },
];

const ApplicationPersonForm = ({
  handleSubmit,
  allowNameEditing = true,
  showSpecialtySelectors,
  consultantSpecialty,
  otherSpecialty,
  applicationPerson: person,
  showRoleDescription,
  ...other
}) => {
  const helperText = useSelector(state => getHelperTextBySectionName(state, "applicationPerson"));
  const defaultState = useSelector(state => getDefaultAddressState(state));
  const consultantSpecialtyId = get(consultantSpecialty, "id");
  const otherSpecialtyId = get(otherSpecialty, "id");

  const schema = Yup.object().shape({
    applicationPerson: Yup.object().shape({
      title: Yup.string()
        .max(50, tooLong)
        .nullable(),
      givenName: Yup.string()
        .max(100, tooLong)
        .trim().required(`${givenNameLabel} is required`)
        .nullable(),
      familyName: Yup.string()
        .max(100, tooLong)
        .trim().required(`${familyNameLabel} is required`)
        .nullable(),
      phone: Yup.string()
        // move these validations in test, only validate it when the value is not null
        // otherwise if the phone filed is touched, the value must be fill in
        // should improve the validation of the phone number in the future, e.g. use libphonenumber.PhoneNumberUtil
        .test("phone", "phone number is correct format", value => {
          if (value) {
            const yupSchema = Yup.string()
              .matches(/^\d+$/, "This does not look like a valid phone number.")
              .max(20, tooLong)
              .min(6, "This does not look like a valid phone number.");
            return yupSchema.isValidSync(value);
          }
          return true;
        })
        .nullable(),
      preferredContactMethod: Yup.string()
        .required("Please choose a contact method")
        .default("Email")
        .nullable(),
      email: Yup.string()
        .when("preferredContactMethod", {
          is: val => val === "Email",
          then: Yup.string().trim().required("Email is required"),
          otherwise: Yup.string().notRequired(),
        })
        .email("Invalid email address")
        .max(50, tooLong)
        .nullable(),
      addressLine1: Yup.string()
        .when("preferredContactMethod", {
          is: val => val === "PostalAddress",
          then: Yup.string().trim().required("Address is required"),
          otherwise: Yup.string().notRequired(),
        })
        .max(255, tooLong)
        .nullable(),
      addressLine2: Yup.string()
        .max(255, tooLong)
        .nullable(),
      suburb: Yup.string()
        .max(50, tooLong)
        .nullable(),
      postcode: Yup.string()
        .matches(/^\d+$/, { excludeEmptyString: true, message: "This does not look like a valid postcode." })
        .test("length", "This does not look like a valid postcode.", val => {
          if (!isEmpty(val) && val.length !== 4) {
            return false;
          }
          return true;
        })
        .nullable(),
      state: Yup.string()
        .max(50, tooLong)
        .nullable(),
      consultantSpecialtyId: Yup.string()
        .default(consultantSpecialtyId) // if consultantSpecialty passed being passed in
        .nullable(),
      otherSpecialtyId: Yup.string()
        .default(otherSpecialtyId) // if other specialty passed being passed in
        .nullable(),
      roleDescription: Yup.string()
        .max(100, "Too long!")
        .nullable(),
      address: Yup.string().when(["addressLine1", "suburb", "postcode", "state"], {
        is: (addressLine1, suburb, postcode, state) => {
          const arr = [addressLine1, suburb, postcode, state];
          if (!every(arr, x => isEmpty(x)) && some(arr, x => isEmpty(x))) {
            return true;
          }
          return false;
        },
        then: Yup.string().trim().required("Please complete the address"),
        otherwise: Yup.string(),
      }).default("").nullable(),
      conflictOfInterest: Yup.string()
        .max(500, tooLong)
        .trim().required(`${conflictOfInterestLabel} is required`)
        .nullable(),
    }),
  });

  let { applicationPerson } = schema.cast();

  // if person is pass in, e.g. main applicant
  if (!isEmpty(person)) {
    applicationPerson = {
      ...applicationPerson,
      ...person,
    };
  }

  return (
    <Form
      initialValues={{ applicationPerson }}
      onSubmit={handleSubmit}
      contentProps={other}
      validationSchema={schema}
      content={({ values, setFieldValue, errors }) => {
        const contact = values.applicationPerson.preferredContactMethod;
        const isEmail = contact === "Email";
        const isPost = contact === "PostalAddress";

        return (
          <>
            <div className="flex">
              <Field
                name="applicationPerson.title"
                component={TextInput}
                icon="person"
                label="Title"
                className="flex-1"
              />
              <Field
                name="applicationPerson.givenName"
                component={TextInput}
                label={givenNameLabel}
                readOnly={!allowNameEditing}
                className="flex-1"
                required
              />
              <Field
                name="applicationPerson.familyName"
                component={TextInput}
                label={familyNameLabel}
                readOnly={!allowNameEditing}
                className="flex-1"
                required
              />
            </div>
            <Field
              name="applicationPerson.phone"
              label={contactNumberLabel}
              component={TextInput}
              icon="phone"
              helperText={!showRoleDescription && helperText.phone}
            />
            <Field
              name="applicationPerson.preferredContactMethod"
              label="Communication Sent Via"
              component={SelectInput}
              options={contactMethods}
              helperText={helperText.preferredContactMethod}
              onChange={option => {
                if (option && option.value === "PostalAddress" && !values.applicationPerson.state) {
                  setFieldValue("applicationPerson.state", defaultState);
                }
              }}
              required
            />
            {isEmail && (
              <Field
                name="applicationPerson.email"
                label={emailLabel}
                component={TextInput}
                icon="email"
                required
              />
            )}
            {isPost && (
              <div className="relative">
                <Field
                  name="applicationPerson.addressLine1"
                  label="Street Line 1"
                  component={TextInput}
                  icon="person_pin"
                  required
                />
                <Field
                  name="applicationPerson.addressLine2"
                  label="Street Line 2"
                  component={TextInput}
                />
                <div className="flex">
                  <Field
                    name="applicationPerson.suburb"
                    label={suburbLabel}
                    component={TextInput}
                    required
                    className="flex-1"
                  />
                  <Field
                    name="applicationPerson.postcode"
                    label={postcodeLabel}
                    component={TextInput}
                    required
                    className="flex-1"
                  />
                  <Field
                    name="applicationPerson.state"
                    label={stateLabel}
                    component={TextInput}
                    required
                    className="flex-1"
                  />
                </div>
                <div className="ml-32 absolute bottom-0">
                  <HelperText error helperText={get(errors, ["applicationPerson", "address"], null)} />
                </div>
              </div>
            )}
            {showSpecialtySelectors
            && (
              <>
                <Field
                  name="applicationPerson.consultantSpecialtyId"
                  label={consultantSpecialtyLabel}
                  component={SpecialtySelector}
                  initialOptions={consultantSpecialty ? [consultantSpecialty] : null}
                  onChange={value => setFieldValue("applicationPerson.consultantSpecialtyId", value?.value)}
                />
                <Field
                  name="applicationPerson.otherSpecialtyId"
                  label={otherSpecialtyLabel}
                  component={SpecialtySelector}
                  helperText={helperText.otherSpecialty}
                  initialOptions={otherSpecialty ? [otherSpecialty] : null}
                  onChange={value => setFieldValue("applicationPerson.otherSpecialtyId", value?.value)}
                />
              </>
            )}
            {showRoleDescription && (
              <Field
                name="applicationPerson.roleDescription"
                label={roleDescriptionLabel}
                component={TextInput}
                icon={<AccountTieOutlineIcon />}
              />
            )}
            <Field
              name="applicationPerson.conflictOfInterest"
              label={conflictOfInterestLabel}
              component={TextAreaInput}
              icon={<ThumbsUpDownIcon />}
              maxLength={500}
              required
              helperText={helperText.conflictOfInterest}
            />
          </>
        );
      }}
    />
  );
};

export default ApplicationPersonForm;
