import React from "react";
import clsx from "clsx";
import { FormControl, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import IconComponent from "components/items/icon-component";
import HelperText from "./helper-text";

const useStyles = makeStyles(theme => ({
  root: (({ multiline, isCheckBox }) => ({
    display: "flex",
    minHeight: isCheckBox ? "auto" : 72,
    alignItems: multiline ? "flex-start" : "center",
  })),
  icon: (({ multiline }) => ({
    display: "flex",
    width: 32,
    flexShrink: 0,
    margin: multiline ? theme.spacing(2 + 1 / 2, 0, 0, 0) : theme.spacing(0, 0, 1, 0),
  })),
  container: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
  },
}));

/**
 * input field will contain icon, input, helper text, extra actions
 */
const BaseInputField = ({
  required,
  error,
  classes,
  helperText,
  children,
  icon,
  iconComponent,
  showIcon = true,
  multiline,
  renderExtraAction,
  showHelperText = true,
  isCheckBox = false,
  variant,
}) => {
  const displayIcon = showIcon || iconComponent || icon;
  const styles = useStyles({ multiline, isCheckBox });
  return (
    <Box className={clsx(styles.root, classes?.root)}>
      {displayIcon && (
        <Box className={clsx(styles.icon, classes?.icon)}>
          {iconComponent || <IconComponent icon={icon} size={24} />}
        </Box>
      )}
      <Box className={clsx(styles.container, classes?.container)}>
        <FormControl required={required} error={error} className={clsx("flex-auto", classes?.inputContainer)} variant={variant}>
          {children}
        </FormControl>
        {showHelperText && <HelperText error={error} helperText={helperText} />}
      </Box>
      {renderExtraAction && renderExtraAction}
    </Box>
  );
};

export default BaseInputField;
