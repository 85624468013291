import curryConnector from "utils/connectors";
import { get } from "lodash";

export const SIDEBAR_STATE_KEY = "sidebars";

const curry = fn => curryConnector(fn, SIDEBAR_STATE_KEY);

export const ActionTypes = {
  SET_MOBILE_CONTENT_SIDEBAR_STATUS: "SET_MOBILE_CONTENT_SIDEBAR_STATUS",
  SET_SIDEBAR_STATUS: "SET_SIDEBAR_STATUS",
};

const initialState = {
  open: false,
  mobileContentSidebarOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_MOBILE_CONTENT_SIDEBAR_STATUS:
      return {
        ...state,
        mobileContentSidebarOpen: action.payload,
      };
    case ActionTypes.SET_SIDEBAR_STATUS:
      return {
        ...state,
        open: action.payload,
      };
    default:
      return state;
  }
};

export const isMobileContentSidebarOpen = curry(({ localState }) => get(localState, ["mobileContentSidebarOpen"], false));
export const isSidebarOpen = curry(({ localState }) => get(localState, ["open"], false));
