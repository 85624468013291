import React from "react";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { find } from "lodash";
import { getAllTriageCategorySettings } from "app/auth/store/reducers/system-configuration";

export default function ReferralCategoryLabel(props) {
  const { triageCategory, isUrgent } = props;
  const triageCategorySettings = useSelector(state => getAllTriageCategorySettings(state));

  const category = find(triageCategorySettings, setting => setting.triageCategory === triageCategory);

  return (
    <Typography variant="caption" style={{ color: category?.color, pointerEvents: "none" }} className="font-bold">
      {category?.label}
      {isUrgent && " - URGENT"}
    </Typography>
  );
}
