import React from "react";
import { connect } from "react-redux";
import { isEmpty, xorBy } from "lodash";

import { Field } from "formik";

import { validateRequired, validateEmailFormat, validatePhoneFormat, validateMulti, validateMaxLength } from "utils/validators";
import { TextInput } from "components/inputs";
import Form from "components/form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { getUser, getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import withPermissions from "permissions/withPermissions";
import ConfirmationDialog from "components/items/confirmation-dialog";
import SubSpecialtySelector from "app/main/specialtyProfiles/components/sub-specialty-selector";
import SpecialtySelector from "app/main/specialtyProfiles/components/specialty-selector";

import { addNewUser, updateExistingUser } from "../actions/users.actions";
import { getCurrentUser } from "../reducers/users.reducers";
import UserRoleSelector from "./user-roles-selector";
import EndorsementTypeSelector from "./endorsement-type-selector";

const username = "Username";
const firstName = "First Name";
const lastName = "Last Name";
const phoneMobile = "Mobile Number";
const email = "Email";
const userRolesLabel = "Roles";
const specialtyProfileLabel = "Specialty";
const endorsementTypes = "Endorsement Types";
const position = "Position";
const employeeNumber = "Employee Number";

const validateUsername = value => validateRequired(username)(value) || validateMaxLength(username, 128)(value);
const validateFirstName = value => validateRequired(firstName)(value) || validateMaxLength(firstName, 128)(value);
const validateLastName = value => validateRequired(lastName)(value) || validateMaxLength(lastName, 128)(value);
const validateEmail = value => validateEmailFormat(email)(value) || validateMaxLength(email, 255)(value);
const validatePhone = value => validatePhoneFormat(phoneMobile)(value) || validateMaxLength(phoneMobile, 20)(value);
const validateRoles = value => validateRequired(userRolesLabel)(value) || validateMulti(userRolesLabel)(value);
const validatePosition = validateMaxLength(position, 128);
const validateEmployeeNumber = validateMaxLength(employeeNumber, 128);

class UserForm extends React.PureComponent {
  handleSubmit = ({ user }, { setErrors, setSubmitting }) => {
    const rolesHaveChanged = () => !isEmpty(xorBy(this.props.initialValues.user.userRoles, user.userRoles, x => `${x.orgUnitId}/${x.roleId}`));

    // if user is changing their own role, ask for confirmation
    if (this.props.currentAdminUser.userId === user.userId && rolesHaveChanged()) {
      this.props.openDialog({
        disableEscapeKeyDown: true, // consider to put this in default settings?
        maxWidth: "xs",
        children: (
          <ConfirmationDialog
            onCancel={() => {
              setSubmitting(false);
              this.props.closeDialog();
            }}
            onConfirm={() => {
              this.submitUserChange(user, setErrors, setSubmitting, true);
              this.props.closeDialog();
            }}
            title={<div className="text-center">Are you sure?</div>}
            content="Changing your roles may reduce your access level"
          />
        ),
      });
    } else {
      this.submitUserChange(user, setErrors, setSubmitting, false);
    }
  };

  submitUserChange(user, setErrors, setSubmitting, refreshOnSuccess) {
    const apiCall = this.props.isEdit ? this.props.updateExistingUser : this.props.addNewUser;

    apiCall(this.props.signedInOrgUnit?.id, user).then(responds => {
      if (responds.error !== true) {
        if (refreshOnSuccess) {
          window.location.href = "/";
        } else {
          this.props.onSucceed();
        }
      } else {
        setErrors(responds.payload);
      }
      setSubmitting(false);
    });
  }

  render() {
    const {
      roles,
      hasPermissionUsersMaintainDetail,
      hasPermissionUsersMaintainRoles,
      hasPermissionUsersMaintainSpecialtyProfile,
      signedInOrgUnit,
      initialValues,
      ...other
    } = this.props;

    const disabledFullUserDetail = other.disabled || !hasPermissionUsersMaintainDetail;

    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        isModal={false}
        {...other}
        content={({ values, setFieldValue }) => (
          <>
            <Field
              name="user.username"
              label={username}
              type="text"
              component={TextInput}
              validate={validateUsername}
              readOnly={disabledFullUserDetail}
            />
            <Field
              name="user.firstName"
              label={firstName}
              component={TextInput}
              validate={validateFirstName}
              readOnly={disabledFullUserDetail}
            />
            <Field
              name="user.lastName"
              component={TextInput}
              label={lastName}
              validate={validateLastName}
              readOnly={disabledFullUserDetail}
            />
            <Field
              name="user.phoneMobile"
              label={phoneMobile}
              component={TextInput}
              validate={validatePhone}
              readOnly={disabledFullUserDetail}
            />
            <Field
              name="user.email"
              component={TextInput}
              label={email}
              validate={validateEmail}
              readOnly={disabledFullUserDetail}
            />
            <Field
              name="user.employeeNumber"
              component={TextInput}
              label={employeeNumber}
              readOnly={disabledFullUserDetail}
              validate={validateEmployeeNumber}
            />
            <Field
              name="user.position"
              component={TextInput}
              label={position}
              readOnly={disabledFullUserDetail}
              validate={validatePosition}
            />
            <Field
              name="user.userRoles"
              component={UserRoleSelector}
              label={userRolesLabel}
              isDisabled={other.disabled || !hasPermissionUsersMaintainRoles}
              orgUnit={signedInOrgUnit}
              validate={validateRoles}
            />
            <Field
              name="user.endorsementTypes"
              component={EndorsementTypeSelector}
              label={endorsementTypes}
              isDisabled={other.disabled || !hasPermissionUsersMaintainRoles}
              orgUnit={signedInOrgUnit}
            />
            {hasPermissionUsersMaintainSpecialtyProfile && (
              <>
                <Field
                  name="user.specialtyProfileId"
                  component={SpecialtySelector}
                  label={specialtyProfileLabel}
                  initialOptions={initialValues.user.specialtyProfile ? [initialValues.user.specialtyProfile] : null}
                  onChange={value => {
                    setFieldValue("user.specialtyProfileId", value?.value);
                    setFieldValue("user.subSpecialtyId", null);
                  }}
                  showIcon={false}
                  disabled={disabledFullUserDetail}
                />
                {values.user.specialtyProfileId && (
                <Field
                  name="user.subSpecialtyId"
                  component={SubSpecialtySelector}
                  specialtyProfileId={values.user.specialtyProfileId}
                  onChange={value => setFieldValue("user.subSpecialtyId", value?.value)}
                  showIcon={false}
                  disabled={disabledFullUserDetail}
                />
                )}
              </>
            )}
          </>
        )}
      />
    );
  }
}

export default withPermissions("UsersMaintainDetail", "UsersMaintainRoles", "UsersMaintainSpecialtyProfile")(connect(
  (state, ownProps) => {
    const signedInOrgUnit = getSignedInOrgUnit(state);
    const user = ownProps.user || getCurrentUser(state);

    return ({
      initialValues: { user: { ...user, specialtyProfileId: user?.specialtyProfile?.id, subSpecialtyId: user?.subSpecialty?.id } },
      currentAdminUser: getUser(state),
      signedInOrgUnit,
    });
  },
  { addNewUser, updateExistingUser, openDialog, closeDialog },
)(UserForm));
