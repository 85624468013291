import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableHead, Typography, TableBody as NativeTableBody } from "@material-ui/core";
import LoadingState from "components/items/loading-state";

export const useStyles = makeStyles(theme => {
  const border = `1px solid ${theme.palette.divider}`;
  return ({
    container: {
      "& tr": {
        borderBottom: border,
        "&:first-child": {
          borderTop: border,
        },
      },
      "& thead tr": {
        borderTop: "none !important",
      },
      "& td, & th": {
        border: "none",
      },
      [theme.breakpoints.down("md")]: {
        "& table, thead, tbody, th, td, tr": {
          display: "block",
        },
        "& thead tr": {
          display: "none",
        },
        "& td": {
          position: "relative",
          paddingTop: theme.spacing(4),
          "&:before": {
            content: "attr(data-title)",
            position: "absolute",
            top: theme.spacing(1),
            width: 120,
            paddingRight: theme.spacing(2),
            whiteSpace: "nowrap",
            fontWeight: "bold",
            fontSize: "1.2rem",
          },
        },
        "& tbody": {
          margin: theme.spacing(1),
          borderLeft: border,
          borderRight: border,
        },
      },
    },
    header: {
      margin: theme.spacing(1, 2),
    },
    tableBody: ({ isDisabled }) => ({
      cursor: isDisabled ? "default" : "pointer",
      "&:hover": {
        backgroundColor: isDisabled ? "transparent" : theme.palette.action.hover,
      },
      "& tr:not(:last-child)": {
        borderBottom: 0,
      },
    }),
  });
});

const ResponsiveTable = ({
  isLoading,
  isEmpty,
  error,
  renderTableContent,
  renderTableHeader,
  renderTableFooter,
  title,
  subtitle,
  emptyMessage = "Nothing here yet.",
}) => {
  const classes = useStyles();
  const showHeader = title || subtitle;
  const showTable = !isLoading && !isEmpty && !error;

  let Content = () => null;
  if (isLoading) {
    Content = () => <LoadingState />;
  } else if (isEmpty) {
    Content = () => <Typography color="textSecondary">{emptyMessage} </Typography>;
  } else if (error) {
    Content = () => <Typography color="error">{error}</Typography>;
  }

  return (
    <div className={classes.container}>
      {showHeader && (
        <div className={classes.header}>
          {title && <Typography className="font-bold">{title}</Typography>}
          {subtitle && <Typography variant="body2">{subtitle}</Typography>}
        </div>
      )}
      <div className="px-16 py-8">
        <Content />
      </div>
      {showTable && (
      <Table>
        <TableHead>
          {renderTableHeader}
        </TableHead>
        {renderTableContent}
      </Table>
      )}
      {renderTableFooter && (
        <div className="p-16">
          {renderTableFooter}
        </div>
      )}
    </div>
  );
};

export default ResponsiveTable;

export { default as TableRow } from "./table-row";
export { default as TableCell } from "./table-cell";

export const TableBody = ({ disabled, onClick, ...other }) => {
  const isDisabled = disabled || !onClick;
  const classes = useStyles({ isDisabled });
  return (
    <NativeTableBody
      classes={{ root: classes.tableBody }}
      onClick={!disabled && onClick ? () => onClick() : () => null}
      {...other}
    />
  );
};
