import React from "react";
import { Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, get, find } from "lodash";
import * as Yup from "yup";
import StethoscopeIcon from "mdi-react/StethoscopeIcon";
import HospitalBuildingIcon from "mdi-react/HospitalBuildingIcon";
import Form from "components/form";
import { normalizePatient } from "app/main/patients/reducers/patients.reducers";
import { onSaveOrUpdatePatient, onSavePatient } from "app/main/applications/actions/applications.actions";
import { getCurrentApplication } from "app/main/applications/reducers/applications.reducers";
import { getPrimaryPatientIdentifierSettings } from "app/auth/store/reducers/system-configuration";

import { CollectionLocationSelector, PatientSettingSelector } from "./patient-application-form-selectors";
import ApplicationPatientSummaryCard from "./application-patient-summary-card";

const collectionLocationLabel = "Pharmacy Location";
const patientSettingLabel = "Patient Setting";

// this form will only do the create
const ExternalPatientApplicationForm = ({
  onSucceed,
  externalPatient,
  orgUnitId,
  ...other
}) => {
  const dispatch = useDispatch();
  const currentApplication = useSelector(getCurrentApplication);
  const IdentifierSettings = useSelector(getPrimaryPatientIdentifierSettings);
  const primaryPatientIdentifierTypeCode = IdentifierSettings?.type;

  const schema = Yup.object().shape({
    patient: Yup.object().shape({
      collectionLocationId: Yup.string().required(`${collectionLocationLabel} is required`).nullable(),
      setting: Yup.string().required(`${patientSettingLabel} is required`).nullable(),
    }),
  });

  const applicationId = get(currentApplication, "id");

  const handleSubmit = ({ patient }, { setErrors }) => {
    const newPatient = {
      ...patient,
      sex: patient.sex.charAt(0),
      patientId: currentApplication.patient?.patientId,
      orgUnitId,
    };

    const apiCall = applicationId ? onSaveOrUpdatePatient(orgUnitId, applicationId, newPatient) : onSavePatient(orgUnitId, newPatient);

    dispatch(apiCall).then(responds => {
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  let { patient } = schema.cast();

  if (!isEmpty(externalPatient)) {
    const primaryIdentifier = find(externalPatient.patientIdentifiers, x => x.patientIdentifierTypeCode === primaryPatientIdentifierTypeCode);

    patient = {
      ...normalizePatient(externalPatient),
      primaryPatientIdentifier: primaryIdentifier?.identifier,
      collectionLocationId: currentApplication?.collectionLocation?.id,
      setting: currentApplication?.setting,
    };
  }

  return (
    <Form
      initialValues={{ patient }}
      onSubmit={handleSubmit}
      contentProps={other}
      validationSchema={schema}
      renderHeaderContent={<ApplicationPatientSummaryCard patient={externalPatient} orgUnitId={orgUnitId} />}
      content={({ values, setFieldValue }) => (
        <>
          <Field
            name="patient.collectionLocationId"
            icon={<HospitalBuildingIcon />}
            component={CollectionLocationSelector}
            orgUnitId={orgUnitId}
            label={collectionLocationLabel}
            initialOptions={currentApplication?.collectionLocation ? [currentApplication?.collectionLocation] : null}
            onChange={value => setFieldValue("patient.collectionLocationId", value?.value)}
            required
          />
          <Field
            name="patient.setting"
            component={PatientSettingSelector}
            label={patientSettingLabel}
            icon={values.patient.setting === "Outpatient" ? <StethoscopeIcon /> : "hotel"}
            onChange={value => setFieldValue("patient.setting", value?.value)}
            required
          />
        </>
      )}
    />
  );
};

export default ExternalPatientApplicationForm;
