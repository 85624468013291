import React from "react";
import clsx from "clsx";

import { BookmarkIcon } from "helpers/icon-finder";
import StateContainer from "components/layouts/default-state-container";
import EmptyState from "./empty-state";

const TabContent = ({
  loading,
  empty,
  accessDenied,
  withGutters,
  children,
  error,
  ...other
}) => (
  <StateContainer
    accessDenied={accessDenied}
    loading={loading}
    empty={empty}
    error={error}
    accessDeniedState={<EmptyState icon={<BookmarkIcon />} title="Not Available" />}
    {...other}
  >
    <div className={clsx(withGutters && "p-16", "flex-auto")}>{children}</div>
  </StateContainer>
);

export default TabContent;
