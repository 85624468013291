import ReactDOM from "react-dom";

export default function PrimaryHeaderPortal(props) {
  const header = document.getElementById("primary-header");
  if (header === null) {
    return null;
  }
  return ReactDOM.createPortal(
    props.children,
    header,
  );
}
