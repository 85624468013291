import React from "react";
import { get } from "lodash";
import { ButtonBase, Icon, IconButton, Tooltip } from "@material-ui/core";
import BaseTextInput from "components/inputs/base-text-input";

class AttachmentUploadField extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.fileUpload.click();
  }

  render() {
    const {
      field,
      label,
      form: { touched, errors, status },
      icon,
      options,
      disabled,
      onChange,
      loading,
      ...other
    } = this.props;

    let fieldError = get(errors, field.name, null);
    const serverError = get(status, "apiErrors", null);
    if (serverError) {
      fieldError = serverError;
    }
    return (
      <>
        <ButtonBase disabled={loading || disabled} className="w-full" disableRipple onClick={() => this.handleClick()}>
          <BaseTextInput
            loading={loading}
            className="cursor-pointer flex-1"
            icon={icon}
            label={label}
            error={fieldError}
            touched={get(touched, field.name, null)}
            value={field.value || ""}
            renderExtraAction={(
              <Tooltip title="Choose a file">
                <IconButton component="label" disabled={loading || disabled} edge="end">
                  <Icon>more_horiz</Icon>
                </IconButton>
              </Tooltip>
            )}
            {...other}
          />
        </ButtonBase>
        <input
          type="file"
          capture="camera"
          ref={fileUpload => { this.fileUpload = fileUpload; }}
          style={{ display: "none" }}
          onChange={onChange}
        />
      </>
    );
  }
}
export default AttachmentUploadField;
