import React from "react";
import { Typography } from "@material-ui/core";

import { useDispatch } from "react-redux";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import withPermissions from "permissions/withPermissions";
import DefaultItem from "components/items/default-item";
import DefaultButton from "components/items/default-button";
import TextLink from "components/items/text-link";
import AssignUserForm from "app/main/applications/components/assign-user-form";

import { AssignedUserIcon } from "helpers/icon-finder";

const ApplicationAssignTo = ({
  hasPermissionIPACanAssignApplications,
  disabled,
  assignment,
  orgUnitId,
  applicationId,
}) => {
  const dispatch = useDispatch();

  const disabledEdit = disabled || !hasPermissionIPACanAssignApplications;

  const openForm = title => dispatch(openDialog({
    children: (
      <AssignUserForm
        title={title}
        applicationAssignment={assignment}
        orgUnitId={orgUnitId}
        id={applicationId}
        onSucceed={() => dispatch(closeDialog())}
      />
    ),
  }));

  if (assignment) {
    return (
      <DefaultItem
        icon={<AssignedUserIcon />}
        iconClassName="justify-center"
        content={(
          <div className="flex-row-container with-gutter">
            <Typography variant="caption">Assigned To </Typography>
            <TextLink
              disabled={disabledEdit}
              variant="caption"
              fontSize="small"
              content={assignment.assignedTo}
              onClick={() => openForm("Reassign User")}
            />
          </div>
          )}
      />
    );
  }

  return (
    <DefaultButton
      disabled={disabledEdit}
      label="Assign"
      icon={<AssignedUserIcon />}
      onClick={() => openForm("Assign User")}
      fab
    />
  );
};

export default withPermissions("IPACanAssignApplications")(ApplicationAssignTo);
