import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { map, find, size } from "lodash";

import { FileUploader } from "components/inputs";
import { uploadReferralSourceDocumentFile, clearReferralSourceDocumentFiles } from "app/main/referrals/actions/referrals.actions";
import { getReferralWorklistSettings } from "app/auth/store/reducers/system-configuration";
import { searchExternalSourceDocuments, fetchExternalSourceDocument } from "app/main/externalDocumentWorklist/actions/externalDocumentWorklist.actions";
import { getExternalSourceDocumentOptions, isPageLoading, getPageInfo, EXTERNAL_DOCUMENT_OPTIONS_PAGE_SIZE } from "app/main/externalDocumentWorklist/reducers/externalDocumentWorklist.reducers";
import withPermissions from "permissions/withPermissions";

const translateSourceDocumentOptions = options => {
  if (!options) return null;
  return map(options, x => ({
    value: x.fileName,
    label: `${x.fileName} - ${moment.utc(x.createdDate).local().format("DD-MMM-YYYY HH:mm")}`,
    tag: "EXT.",
  }));
};

const ReferralSourceDocumentInput = ({
  onRemoveFile,
  hasPermissionExternalSourceDocumentView,
  ...other
}) => {
  const dispatch = useDispatch();
  const referralWorklistSettings = useSelector(getReferralWorklistSettings);
  const documents = useSelector(getExternalSourceDocumentOptions);
  const loading = useSelector(isPageLoading);
  const pageInfo = useSelector(getPageInfo);
  const getExternalSourceDocumentId = val => find(documents, x => x.fileName === val)?.externalSourceDocumentId;

  return (
    <FileUploader
      isSelector={hasPermissionExternalSourceDocumentView}
      loadingMore={loading}
      onUpload={uploadReferralSourceDocumentFile}
      options={translateSourceDocumentOptions(documents)}
      onRemove={() => {
        if (onRemoveFile) {
          onRemoveFile();
        }
        dispatch(clearReferralSourceDocumentFiles());
      }}
      onSelectFileChange={val => dispatch(fetchExternalSourceDocument(getExternalSourceDocumentId(val)))}
      onOpen={() => dispatch(searchExternalSourceDocuments(1, EXTERNAL_DOCUMENT_OPTIONS_PAGE_SIZE, null, true, false))}
      onLoadMore={() => dispatch(searchExternalSourceDocuments(1, EXTERNAL_DOCUMENT_OPTIONS_PAGE_SIZE * (pageInfo.pageNumber + 1), null, true, false))}
      acceptTypes={referralWorklistSettings?.allowedSourceDocumentTypes}
      total={pageInfo?.totalRecords}
      counts={size(documents)}
      {...other}
    />
  );
};

export default withPermissions("ExternalSourceDocumentView")(ReferralSourceDocumentInput);
