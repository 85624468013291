import { mapKeys, map, get, find, flatten, filter, concat } from "lodash";
import curryConnector from "utils/connectors";

export const CODE_SET_STATE_KEY = "codeSet";
export const CODE_SET_VALUE_PAGE_SIZE = 50;
export const CODE_SET_VALUE_PAGE_SIZE_OPTIONS = [5, 10, 25, 50];

const curry = fn => curryConnector(fn, CODE_SET_STATE_KEY);

export const ActionTypes = {
  LOADING_CURRENT_CODE_SET_VALUES: "LOADING_CURRENT_CODE_SET_VALUES",
  LOADED_CURRENT_CODE_SET_VALUES: "LOADED_CURRENT_CODE_SET_VALUES",
  ERROR_LOADING_CURRENT_CODE_SET_VALUES: "ERROR_LOADING_CURRENT_CODE_SET_VALUES",

  LOADING_MULTIPLE_CODE_SET_VALUES: "LOADING_MULTIPLE_CODE_SET_VALUES",
  LOADED_MULTIPLE_CODE_SET_VALUES: "LOADED_MULTIPLE_CODE_SET_VALUES",
  ERROR_LOADING_MULTIPLE_CODE_SET_VALUES: "ERROR_LOADING_MULTIPLE_CODE_SET_VALUES",

  CREATED_CODE_SET_VALUE: "CREATED_CODE_SET_VALUE",
  SAVED_CODE_SET_VALUE: "SAVED_CODE_SET_VALUE",
};

export const DefaultSearchParams = {
  name: null,
  codeSetTypeId: null,
  codeSetTypeCode: null,
  showInactive: true,
};

const INITIAL_STATE = {
  all: {},
  loading: false,
  loaded: false,
  error: null,
  current: {
    codeSet: null,
    pages: {},
    pageInfo: { pageNumber: 1, pagesize: CODE_SET_VALUE_PAGE_SIZE, totalRecords: 0 },
    searchParams: DefaultSearchParams,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_CURRENT_CODE_SET_VALUES:
      return {
        ...state,
        current: {
          ...state.current,
          pages: { ...state.current.pages, [action.payload.pageNumber]: { loading: true, error: false } },
          pageInfo: { ...state.current.pageInfo, pageNumber: action.payload.pageNumber },
        },
      };
    case ActionTypes.ERROR_LOADING_CURRENT_CODE_SET_VALUES:
      return {
        ...state,
        current: {
          ...state.current,
          pages: { ...state.current.pages, [action.payload.pageNumber]: { loading: false, error: action.payload.message } },
          pageInfo: { ...state.current.pageInfo, pageNumber: action.payload.pageNumber },
        },
      };
    case ActionTypes.LOADED_CURRENT_CODE_SET_VALUES:
      return {
        ...state,
        current: {
          id: action.payload.id,
          codeSetTypeCode: action.payload.codeSetTypeCode,
          codeSetValues: { ...state.current.codeSetValues, ...mapKeys(action.payload.codeSetValues, x => x.id) },
          pages: { ...state.current.pages, [action.payload.pageInfo.pageNumber]: { loading: false, error: false, loaded: true, ids: map(action.payload.codeSetValues, x => x.id) } },
          loaded: true,
          error: null,
          pageInfo: action.payload.pageInfo,
          searchParams: action.payload.searchParams,
        },
      };
    case ActionTypes.LOADING_MULTIPLE_CODE_SET_VALUES:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.ERROR_LOADING_MULTIPLE_CODE_SET_VALUES:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload.message,
      };
    case ActionTypes.LOADED_MULTIPLE_CODE_SET_VALUES:
      return {
        ...state,
        all: { ...state.all, ...mapKeys(action.payload.codeSets, x => x.codeSetTypeCode) },
        loading: false,
        loaded: true,
        error: null,
      };

    case ActionTypes.CREATED_CODE_SET_VALUE:
    case ActionTypes.SAVED_COST_SET_VALUE:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.codeSetType]: {
            ...state.all[action.payload.codeSetType],
            codeSetValues: concat(filter(state.all[action.payload.codeSetType]?.codeSetValues, x => x.id !== action.payload.id), action.payload),
          },
        },
      };
    default:
      return state || INITIAL_STATE;
  }
};

export const getAllCodeSetTypes = curry(({ localState }) => localState.all);

export const getCodeSetValuesByTypeCode = curry(({ localState }, codeSetTypeCode) => {
  const codeSets = localState?.all;
  return codeSets ? codeSets[codeSetTypeCode] : null;
});

export const getCurrentCodeSet = curry(({ localState }) => {
  const codeSetValues = localState.current?.codeSetValues;
  const page = localState.current.pageInfo.pageNumber || 1;
  const ids = get(localState.current.pages, [page, "ids"], []);

  return {
    ...localState.current,
    codeSetValues: map(ids, x => find(codeSetValues, value => value.id === x)),
  };
});

export const isCurrentCodeSetLoading = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.current.pageInfo, ["pageNumber"], 1);
  return get(localState, ["current", "pages", number, "loading"], false);
});

export const isCurrentCodeSetLoaded = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.current.pageInfo, ["pageNumber"], 1);
  return get(localState, ["current", "pages", number, "loaded"], false);
});

export const getCurrentErrorMessage = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.current.pageInfo, ["pageNumber"], 1);
  return get(localState, ["current", "pages", number, "error"], false);
});

export const getCurrentCodeSetPageInfo = curry(({ localState }) => localState.current.pageInfo);

export const getCurrentSearchParams = curry(({ localState }) => localState.current.searchParams);

export const areCodeSetValuesLoaded = curry(({ localState }) => localState.loaded === true);

export const areCodeSetValuesLoading = curry(({ localState }) => localState.loading === true);

export const getAllCodeSetValues = curry(({ localState }) => {
  const all = localState?.all;
  return flatten(map(all, x => x.codeSetValues));
});
