import React, { Component } from "react";
import CreatableSelect from "react-select/creatable";
import { get, isEmpty } from "lodash";

import BaseTextInput from "./base-text-input";
import SelectStyles from "./styles";

const components = {
  DropdownIndicator: null,
};

const createOption = label => ({
  label,
  value: label,
});

export default class ChipInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      value: [],
    };
  }

  onChange = value => {
    const { onChange, field, form: { setFieldValue } } = this.props;
    // onClear
    if (isEmpty(value)) {
      this.setState({ inputValue: "", value: [] });
    }

    field.onChange(field.name)(value);
    if (onChange) {
      onChange(value, setFieldValue);
    }
  }

  handleInputChange = inputValue => {
    //  Handle change events on the input
    this.setState({ inputValue });
  };

  handleKeyDown = event => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    const updatedValue = [...value, createOption(inputValue)];

    if (event.key === "Enter" || event.key === "Tab") {
      this.setState({
        inputValue: "",
        value: updatedValue,
      });

      event.preventDefault();
      this.onChange(updatedValue);
    }
  };

  render() {
    const {
      field,
      form: { touched, errors },
      isMulti,
      label,
      icon,
      disabled,
      ...other
    } = this.props;

    const { inputValue, value } = this.state;

    return (
      <BaseTextInput
        inputComponent={CreatableSelect}
        inputProps={{
          components,
          styles: SelectStyles,
          placeholder: "",
          inputValue,
          isClearable: true,
          isMulti: isMulti || true,
          menuIsOpen: false,
          onChange: this.onChange,
          onInputChange: this.handleInputChange,
          onKeyDown: this.handleKeyDown,
          value: field.value || value,
        }}
        label={label}
        icon={icon}
        error={get(errors, field.name, null)}
        touched={get(touched, field.name, null)}
        disabled={disabled}
        value={field.value}
        {...other}
      />
    );
  }
}
