import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { useDispatch, useSelector } from "react-redux";
import { includes, find } from "lodash";

import { NoteIcon, RequestInfoIcon, TaskIcon } from "helpers/icon-finder";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import DefaultButton from "components/items/default-button";
import EditReferralNoteForm from "app/main/referrals/components/edit-referral-note-form";
import ReferralCorrespondenceForm from "app/main/referrals/components/referral-correspondence-form";
import ReferralRequestMoreInfoForm from "app/main/referrals/components/referral-request-more-info-form";
import { getUser } from "app/auth/store/reducers/user.reducer";
import withPermissions from "permissions/withPermissions";
import { getReferralActionTypeSettings, getReferralWorklistSettings } from "app/auth/store/reducers/system-configuration";
import ActivityLogItem from "components/items/activity-log/item";
import ReferralTaskForm from "app/main/referrals/components/referral-task-form";

import { archivedWorkflowState, completedWorkflowState, cancelledWorkflowState } from "../../helpers/workflow-state-names";
import { requestMoreInformationActionType } from "../../helpers/action-type-names";

const useStyles = makeStyles(theme => ({
  indicator: {
    width: 20,
    height: 20,
    borderStyle: "dashed",
  },
  connector: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: theme.palette.divider,
    width: 0,
  },
}));

const ReferralActivityLogHeader = ({
  hasPermissionReferralCorrespondenceCreate,
  hasPermissionReferralNotesCreate,
  hasPermissionReferralTasksCreate,
  referral: { referralStatus, canReplyToReferrer, availableActions, referrerFullName, id },
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector(getUser);
  const theme = useTheme();
  const displayLabel = useMediaQuery(theme.breakpoints.up("sm"));
  const referralWorklistSettings = useSelector(getReferralWorklistSettings);

  const showButtons = referralStatus !== completedWorkflowState && referralStatus !== archivedWorkflowState;

  const onClose = () => dispatch(closeDialog());
  const referralActionTypes = useSelector(getReferralActionTypeSettings);
  const referralActionTypeSettings = find(referralActionTypes, x => x.actionType === requestMoreInformationActionType);
  const requestMoreInformationLabel = referralActionTypeSettings?.nameSingle;
  const isCancelled = referralStatus === cancelledWorkflowState;

  const showRequestForMoreInformationButton = hasPermissionReferralCorrespondenceCreate && includes(availableActions, requestMoreInformationActionType);
  const showReplyToReferrerButton = canReplyToReferrer && referralWorklistSettings?.outboundReferralNotificationsEnabled === true;
  const showAddNoteButton = hasPermissionReferralNotesCreate && !isCancelled;
  const showAddTaskButton = hasPermissionReferralTasksCreate;

  return (
    <ActivityLogItem
      date="Today"
      user={user.name}
      textColor={grey[400]}
      classes={{ content: "flex flex-row items-center", indicator: classes.indicator, connector: classes.connector }}
      content={showButtons ? (
        <div className="flex-row-container with-gutter">
          {showRequestForMoreInformationButton && (
            <DefaultButton
              tooltip={requestMoreInformationLabel}
              size="medium"
              label={displayLabel ? requestMoreInformationLabel : null}
              icon={<RequestInfoIcon />}
              onClick={() => dispatch(openDialog({
                children: (
                  <ReferralRequestMoreInfoForm
                    title={requestMoreInformationLabel}
                    message={referralActionTypeSettings?.warningMessage}
                    onSucceed={onClose}
                    referralId={id}
                  />
                ),
              }))}
            />
          )}
          {showReplyToReferrerButton && (
            <DefaultButton
              tooltip="Reply to the Referrer"
              size="medium"
              variant="outlined"
              label={displayLabel ? "Reply to the Referrer" : null}
              icon="reply"
              onClick={() => dispatch(openDialog({
                children: (
                  <ReferralCorrespondenceForm
                    title="Reply to the Referrer"
                    onSucceed={onClose}
                    referralId={id}
                    referrerFullName={referrerFullName}
                  />
                ),
              }))}
            />
          )}
          {showAddNoteButton && (
            <DefaultButton
              tooltip="Add a note"
              size="medium"
              variant="outlined"
              label={displayLabel ? "Add a note" : null}
              icon={<NoteIcon />}
              onClick={() => dispatch(openDialog({
                children: (
                  <EditReferralNoteForm
                    title="Notes"
                    onSucceed={onClose}
                    referralId={id}
                  />
                ),
              }))}
            />
          )}
          {showAddTaskButton && (
            <DefaultButton
              tooltip="Add a task"
              size="medium"
              variant="outlined"
              label={displayLabel ? "Add a task" : null}
              icon={<TaskIcon />}
              onClick={() => dispatch(openDialog({
                children: (
                  <ReferralTaskForm
                    title="Task"
                    onSucceed={onClose}
                    referralId={id}
                  />
                ),
              }))}
            />
          )}
        </div>
      ) : null}
    />
  );
};

export default withPermissions("ReferralNotesCreate", "ReferralCorrespondenceCreate", "ReferralTasksCreate")(ReferralActivityLogHeader);
