import React from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { isCurrentUserLoading } from "app/auth/store/reducers/user.reducer";
import { AccessDeniedState } from "components/items/empty-state";
import LoadingState from "components/items/loading-state";
import withPermissions from "permissions/withPermissions";
import ExternalDocumentWorklistPage from "./external-document-worklist-page";

const SecureExternalDocumentWorklistPage = ({ location, hasPermissionExternalSourceDocumentView }) => {
  const loading = useSelector(isCurrentUserLoading);
  const forceLoad = get(location, ["state", "forceLoad"], false);

  if (loading) return <LoadingState />;

  if (!hasPermissionExternalSourceDocumentView) return <AccessDeniedState />;

  return <ExternalDocumentWorklistPage forceLoad={forceLoad} />;
};

export default withPermissions("ExternalSourceDocumentView")(SecureExternalDocumentWorklistPage);
