import React from "react";
import { map } from "lodash";
import MedicationsListItem from "app/main/patients/medications/components/medication-list-item";
import StateContainer from "components/layouts/default-state-container";

const MedicationsList = ({
  medications,
  onEditMedication,
  onRemoveMedication,
  isEmpty,
  isError,
  isLoading,
}) => (
  <StateContainer loading={isLoading} error={isError} empty={isEmpty} emptyTitle="No Recorded Medications">
    {map(medications, (medication, index) => (
      <MedicationsListItem
        item={medication}
        key={medication.id}
        isFirst={index === 0}
        onEdit={onEditMedication ? () => onEditMedication(medication.id) : null}
        onRemove={onRemoveMedication ? () => onRemoveMedication(medication.id) : null}
      />
    ))}
  </StateContainer>
);

export default MedicationsList;
