import moment from "moment-timezone";

import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import { ActionTypes, bailOutOfLoadingPatients } from "../reducers/worklist.reducers";

export const setWorkListFilter = filter => dispatch => dispatch({
  type: ActionTypes.SET_WORKLIST_FILTERS,
  payload: { filter },
});

export const searchPatients = (searchParams, pageNumber = 1, pageSize = 20, forceLoad = false) => (dispatch, getState) => {
  const params = formatSearchParams(searchParams);
  const timeZone = moment.tz.guess();
  const url = `/patientSummary?pageNumber=${pageNumber}&pageSize=${pageSize}&timeZone=${timeZone}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: !forceLoad && bailOutOfLoadingPatients(getState(), pageNumber),
      types: [
        ActionTypes.SEARCHING_PATIENTS,
        ActionTypes.SEARCHED_PATIENTS,
        ActionTypes.ERROR_SEARCHING_PATIENTS,
      ],
      pageNumber,
    },
  });
};
