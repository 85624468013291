import UserNewPage from "./pages/user-new-page";
import UserEditPage from "./pages/user-edit-page";

const UserConfig = {
  routes: [
    {
      path: "/admin/users/new",
      component: UserNewPage,
      exact: true,
      title: "Add User",
      backUrl: "/admin",
      search: "?tab=users",
      state: { forceLoad: true },
      strict: true,
    },
    {
      path: "/admin/users/:id",
      component: UserEditPage,
      exact: true,
      title: "Edit User",
      backUrl: "/admin",
      search: "?tab=users",
      strict: true,
    },
  ],
};

export default UserConfig;
