import React from "react";
import TextLink from "components/items/text-link";

export default function EmailDetailsLabel(props) {
  const { email } = props;
  if (!email) return null;

  if (typeof email === "string") {
    return <TextLink icon="mail" content={email} />;
  }

  return (
    <TextLink icon="mail">
      {email}
    </TextLink>
  );
}
