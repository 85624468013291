import React from "react";
import { map } from "lodash";
import InterventionListItem from "app/main/patients/interventions/components/intervention-list-item";
import StateContainer from "components/layouts/default-state-container";

const InterventionList = ({
  interventions,
  onEditIntervention,
  onRemoveIntervention,
  onResolveIntervention,
  onRenewIntervention,
  isEmpty,
  isError,
  isLoading,
}) => (
  <StateContainer loading={isLoading} error={isError} empty={isEmpty} emptyTitle="No Recorded Interventions">
    {map(interventions, (intervention, index) => (
      <InterventionListItem
        item={intervention}
        key={intervention.id}
        isFirst={index === 0}
          // actions
        onEdit={onEditIntervention ? () => onEditIntervention(intervention.id) : null}
        onRemove={onRemoveIntervention ? () => onRemoveIntervention(intervention.id) : null}
        onResolve={onResolveIntervention && !intervention.resolvedDateTimeUtc ? () => onResolveIntervention(intervention.id) : null}
        onRenew={onRenewIntervention && intervention.resolvedDateTimeUtc ? () => onRenewIntervention(intervention.id) : null}
      />
    ))}
  </StateContainer>
);

export default InterventionList;
