import React from "react";
import { ButtonBase, Icon, IconButton, Tooltip } from "@material-ui/core";
import BaseTextInput from "components/inputs/base-text-input";
import DownloadIcon from "mdi-react/DownloadIcon";

class AttachmentUploadButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.fileUpload.click();
  }

  render() {
    const {
      label,
      icon,
      disabled,
      onChange,
      currentAttachment,
      loading,
      onDownloadFile,
      orgUnitId,
      onRemove,
      acceptFormat,
      ...other
    } = this.props;

    return (
      <div className="mt-16 mr-16 ml-16">
        <div className="flex-row-container with-gutter w-full">
          <ButtonBase disabled={loading || disabled} className="flex-1" disableRipple onClick={() => this.handleClick()}>
            <BaseTextInput
              loading={loading}
              className="cursor-pointer flex-1"
              icon={icon}
              label={label}
              value={currentAttachment?.fileName || ""}
              {...other}
            />
          </ButtonBase>
          <>
            <Tooltip title="Choose a file">
              <IconButton component="label" onClick={() => this.handleClick()} disabled={loading || disabled} edge="end">
                <Icon>more_horiz</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Download file">
              <IconButton component="label" disabled={loading || disabled} edge="end" onClick={() => onDownloadFile(orgUnitId)}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remove file">
              <IconButton component="label" disabled={loading || disabled} edge="end" onClick={() => onRemove(orgUnitId)}>
                <Icon>delete</Icon>
              </IconButton>
            </Tooltip>
          </>
        </div>
        <input
          type="file"
          capture="camera"
          ref={fileUpload => { this.fileUpload = fileUpload; }}
          style={{ display: "none" }}
          accept={acceptFormat}
          onChange={onChange}
        />
      </div>
    );
  }
}
export default AttachmentUploadButton;
