import moment from "moment";

export function toReadableAge(age) {
  return `${age + (age === 1 ? " Year" : " Years")} Old`;
}

export function toDOBFormat(birthDateString) {
  return moment(birthDateString).format("DD-MMM-YYYY");
}

export function calculateAge(birthDateString) {
  const now = moment();
  const yearDiff = moment.duration(now - moment(birthDateString, "YYYY-MM-DD")).as("years");
  const age = Math.floor(yearDiff);
  return (age);
}
