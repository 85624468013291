import React from "react";
import AutoComplete from "./auto-complete";
import CreateAutoComplete from "./creatable-auto-complete";

export default ({
  isCreatable,
  createLabel,
  ...other
}) => {
  if (isCreatable) return <CreateAutoComplete createLabel={createLabel} {...other} />;
  return <AutoComplete {...other} />;
};

export { default as AutoCompleteLoading } from "../components/loader";
