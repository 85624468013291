import React from "react";
import { Typography } from "@material-ui/core";
import IconButton from "components/items/icon-button"
import ActivityLogItem from "components/items/activity-log/item";

// TODO - MoreInformationRequested
const CorrespondenceItem = ({ activity }) => (
  <ActivityLogItem
    icon="reply"
    title={activity.subject}
    content={activity.message}
    date={activity.createdDateLocalised}
    user={activity.addedBy}
    renderPopupContent={({ close }) => (
      <>
        <div className="flex justify-between items-center">
          <Typography className="font-bold">{activity.subject}</Typography>
          <div className="flex items-center justify-end">
            <div className="ml-16">
              <IconButton title="Close" onClick={close} icon="close" />
            </div>
          </div>
        </div>
        <Typography variant="caption">Added by {activity.addedBy} at {activity.createdDateLocalised}</Typography>
        <div className="pt-16">
          {activity.message}
        </div>
      </>
    )}
  />
);

export default CorrespondenceItem;
