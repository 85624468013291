import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { debounce, map, size, trim, sortBy, startsWith, includes } from "lodash";
import { Typography, Divider } from "@material-ui/core";
import { AutoComplete } from "components/inputs";
import { searchDrugs } from "app/main/applications/actions/applications.actions";
import { Alert } from "@material-ui/lab";
import { find, isEmpty } from "lodash";

const normaliseOptions = drugs => map(drugs, d => ({
  label: d.drugFullName,
  value: d,
}));

export default function DrugSelector({ required, isCreatable = true, initialDrugOptions, orgUnitId, onChange, ...other }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [options, setOptions] = useState(initialDrugOptions ? normaliseOptions(initialDrugOptions) : []);
  const [isOpen, setOpen] = useState(!!initialDrugOptions);

  const onInputChange = useCallback(debounce(async (event, val, action) => {
    if (action !== "input" || size(trim(val)) < 2) {
      return;
    }

    setLoading(true);

    const res = await dispatch(searchDrugs(orgUnitId, { searchString: val }));

    setOptions(res.error ? [] : normaliseOptions(res.payload));
    setApiError(res.error ? "Error loading medications" : null);
    setLoading(false);
    if (initialDrugOptions) {
      setOpen(true);
    }
  }, 300));

  const onSelectChange = value => {
    if (onChange) {
      onChange(value);
    }
    setOpen(false);
    setLoading(false);
  };

  const drugSorter = (allOptions, inputValue) => sortBy(allOptions, [option => !startsWith(option.value.drugFullName, inputValue), option => !includes(option.value.drugFullName, inputValue)]);

  return (
    <>
      <AutoComplete
        isCreatable={isCreatable}
        loading={loading}
        options={options}
        onInputChange={onInputChange}
        onBlur={() => setOpen(false)}
        required={required}
        renderOption={({ label, value }) => {
          // If multiple results come back with the same form name, e.g., "injection" for morphine, then show the full name
          var singularFormNameInResults = isEmpty(find(options, x => x.value.formName === value?.formName // Check that the short name is the same
            && x.value.formId !== value?.formId // Check that they're different forms
            && x.value.drugId === value?.drugId // Ensure it's only on the same drug
          ));
          // True => Show formName
          // False => show fullFormName

          return (value ? (
          <div className="flex flex-col flex-1">
            <Typography className="font-bold">{value.drugFullName}</Typography>
            <Typography variant="caption" color="textSecondary">{(singularFormNameInResults) ? value.formName : value.fullFormName}</Typography>
            <Divider />
          </div>
        ) : label)}}
        onChange={onSelectChange}
        {...other}
        onOpen={() => setOpen(true)}
        open={isOpen}
        ignoreInputMatchingFilter
        onClose={() => setOptions([])}
        sorter={drugSorter}
      />
      {apiError && <Alert severity="warning">{apiError}</Alert>}
    </>
  );
}
