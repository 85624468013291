import React from "react";

import FileDocumentBoxOutlineIcon from "mdi-react/FileDocumentBoxOutlineIcon";
import FilePDFBoxOutlineIcon from "mdi-react/FilePdfBoxOutlineIcon";
import FileWordBoxOutlineIcon from "mdi-react/FileWordBoxOutlineIcon";
import ImageOutlineIcon from "mdi-react/ImageOutlineIcon";
import LinkBoxVariantOutlineIcon from "mdi-react/LinkBoxVariantOutlineIcon";
import FileExcelBoxOutline from "mdi-react/FileExcelBoxOutlineIcon";

const AttachmentIcon = ({
  type,
  className,
  ...other
}) => {
  let IconComponent = null;
  switch (type) {
    case "application/pdf":
      IconComponent = () => <FilePDFBoxOutlineIcon {...other} />;
      break;
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      IconComponent = () => <FileWordBoxOutlineIcon {...other} />;
      break;
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      IconComponent = () => <FileExcelBoxOutline {...other} />;
      break;
    case "image/jpeg":
    case "image/png":
    case "image/bmp":
    case "image/tiff":
      IconComponent = () => <ImageOutlineIcon {...other} />;
      break;
    case "link":
      IconComponent = () => <LinkBoxVariantOutlineIcon {...other} />;
      break;
    default:
      IconComponent = () => <FileDocumentBoxOutlineIcon {...other} />;
      break;
  }
  return (
    <div className={className}>
      <IconComponent />
    </div>
  );
};

export default AttachmentIcon;
