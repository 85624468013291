import React from "react";
import DefaultButton from "components/items/default-button";
import withPermissions from "permissions/withPermissions";
import useAddPatientReferral from "app/main/patientReferralWorklist/hooks/useAddPatientReferral";
import ActiveReferralPanel from "./active-referral-panel";

const ActiveReferralListPanel = ({
  hasPermissionReferralsCreate,
  patientId,
  fetchAllPatientReferrals,
}) => {
  const onCreatedPatientReferral = () => fetchAllPatientReferrals(patientId);

  const { openAddReferralForm } = useAddPatientReferral({ patientId, onSucceed: onCreatedPatientReferral });

  return (
    <ActiveReferralPanel
      patientId={patientId}
      headerRightContent={hasPermissionReferralsCreate && (
      <DefaultButton
        fab
        label="Add Referral"
        onClick={openAddReferralForm}
      />
      )}
    />
  );
};

export default withPermissions("ReferralsCreate")(ActiveReferralListPanel);
