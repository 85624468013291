import moment from "moment";
import { isEmpty, keys } from "lodash";
import { parseDate } from "utils/date-parsing";
import { DateFormatTypes, PreferredDateFormat } from "utils/date";

export default function formatDate(input, format) {
  if (isEmpty(input)) {
    return "";
  }

  let formattedDate = input;

  if (!moment.isMoment(formattedDate)) {
    formattedDate = parseDate(formattedDate.date || formattedDate);
  }

  if (!formattedDate.isValid()) {
    return "";
  }

  if (!isEmpty(format) && keys(DateFormatTypes).some(x => DateFormatTypes[x] === format)) {
    return formattedDate.format(format);
  }

  return formattedDate.format(PreferredDateFormat);
}
