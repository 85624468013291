import React from "react";
import ScreenHeader from "components/items/screen-header";

const CodeSetPageHeader = () => (
  <ScreenHeader
    title="Code Sets"
  />
);

export default CodeSetPageHeader;
