import React, { useState } from "react";
import { green, blueGrey } from "@material-ui/core/colors";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  button: {
    padding: theme.spacing(1 / 2),
  },
  continue: {
    color: green[500],
  },
  cease: {
    color: blueGrey[900],
  },
}));

const AssessmentRadioButtons = ({
  handleRadioButtonChange,
  id,
  name,
  label,
  actionType,
  readOnly,
}) => {
  const [actionTypeDefault, setActionTypeDefault] = useState(null);
  const classes = useStyles();

  return (
    <RadioGroup
      className="px-16 py-8 flex-row"
      aria-label={label}
      name={name}
      onChange={event => {
        if (actionType === "Start") { setActionTypeDefault(actionType); }
        handleRadioButtonChange(id, event.target.value);
      }}
      value={actionType}
    >
      <FormControlLabel
        value={(actionTypeDefault === "Start" || actionType === "Start") ? "Start" : "Continue"}
        control={<Radio color="default" classes={{ root: classes.button, checked: classes.continue }} />}
        label={(actionTypeDefault === "Start" || actionType === "Start") ? "Start" : "Continue"}
        labelPlacement="end"
        disabled={readOnly}
      />
      <FormControlLabel
        value="Cease"
        control={<Radio color="default" classes={{ root: classes.button, checked: classes.cease }} />}
        label="Cease"
        labelPlacement="end"
        disabled={readOnly}
      />
    </RadioGroup>
  );
};

export default AssessmentRadioButtons;
