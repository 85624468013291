import React from "react";
import { connect } from "react-redux";
import { isEmpty, map, lowerCase } from "lodash";
import clsx from "clsx";
import { Hidden } from "@material-ui/core";

import DefaultPanelLayout from "components/items/default-panel-layout";
import EmptyState, { NoneRecordedState } from "components/items/empty-state";
import ApprovalRecommendationAssignmentForm from "app/main/applications/components/approval-recommendation/assignment-form";
import ApprovalRecommendationCommentForm from "app/main/applications/components/approval-recommendation/comment-form";
import { ApplicationItemWithWidth } from "app/main/applications/components/application-item";
import ApplicationNoteContent from "app/main/applications/components/application-note-content";
import { showMessage } from "app/store/actions/message.actions";
import { onRemoveApprovalRecommendation } from "app/main/applications/actions/applications.actions";
import { getUser } from "app/auth/store/reducers/user.reducer";
import ApplicationRemoveButton from "./application-remove-button";
import ApplicationEditButton from "./application-edit-button";

const sectionTitle = plural => `Approval Recommendation${plural ? "s" : ""}`;

class ApprovalRecommendationPanel extends React.Component {
  onClose = () => this.props.closeDialog();

  onSucceed = () => {
    this.onClose();
  }

  openApprovalRecommendationAssignmentForm = approvalRecommendation => {
    this.props.openDialog({
      children: (
        <ApprovalRecommendationAssignmentForm
          onSucceed={this.onSucceed}
          title="Assign To"
          approvalRecommendation={approvalRecommendation}
          {...this.props}
        />
      ),
    });
  };

  openApprovalRecommendationCommentForm = approvalRecommendation => {
    const isNew = !approvalRecommendation;

    this.props.openDialog({
      children: (
        <ApprovalRecommendationCommentForm
          onSucceed={this.onSucceed}
          title={`${isNew ? "New" : "Edit"} Comment`}
          approvalRecommendation={approvalRecommendation}
          {...this.props}
        />
      ),
    });
  };

  onRemove = recommendationId => {
    const { id: applicationId, orgUnitId } = this.props;
    const title = lowerCase(sectionTitle());
    this.props.onRemoveApprovalRecommendation(applicationId, recommendationId, orgUnitId).then(response => {
      if (response.error === true) {
        this.props.showMessage({ message: response.payload.exceptionMessage || `Error occurred while deleting the ${title}`, variant: "error" });
      } else {
        this.props.showMessage({ message: `Successfully removed the ${title}`, variant: "success" });
      }
    });
  };

  render() {
    const { approvalRecommendations, canView, canEdit, canDelete, canBeAssigned, currentUser } = this.props;
    const EditActions = ({ item }) => (
      <>
        {canDelete && <ApplicationRemoveButton onClick={() => this.onRemove(item.id)} />}
        {canBeAssigned && currentUser.userId === item.assignedToUserId && <ApplicationEditButton onClick={() => this.openApprovalRecommendationCommentForm(item)} />}
      </>
    );

    return (
      <DefaultPanelLayout
        title={sectionTitle(true)}
        icon="assignment_ind"
        isEmpty={isEmpty(approvalRecommendations)}
        accessDenied={!canView}
        emptyState={canEdit
          ? <EmptyState icon="assignment_ind" subtitle="Assign User" onClick={() => this.openApprovalRecommendationAssignmentForm()} />
          : <NoneRecordedState />}
        headerActions={canEdit
          && [
            {
              label: "Add User",
              icon: "add",
              onClick: () => this.openApprovalRecommendationAssignmentForm(),
            },
          ]}
      >
        {map(approvalRecommendations, (x, index) => (
          <div key={x.id} className={clsx(index !== 0 && "mt-16 with-horizontal-divider")}>
            <Hidden mdUp>
              <div className="flex justify-end mb-8"><EditActions item={x} /></div>
            </Hidden>
            <div className="flex-row-container">
              <div className="flex-1">
                <ApplicationItemWithWidth icon="person" label="Assigned To" content={x.assignedTo} />
                <ApplicationItemWithWidth icon="comment" label="Comment" content={x.canViewComment ? (x.comment || "No Comment Yet") : "[Cannot View This Comment]"} />
              </div>
              <Hidden smDown>
                <EditActions item={x} />
              </Hidden>
            </div>
            {x.notes && (
              <div className="mt-16">
                <ApplicationNoteContent>{x.notes}</ApplicationNoteContent>
              </div>
            )}
          </div>
        ))}
      </DefaultPanelLayout>
    );
  }
}

export default connect(
  state => ({
    currentUser: getUser(state),
  }),
  {
    showMessage,
    onRemoveApprovalRecommendation,
  },
)(ApprovalRecommendationPanel);
