import React from "react";
import { Typography } from "@material-ui/core";
import { isEmpty } from "lodash";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: "inherit",
    fontSize: "inherit",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    margin: 0,
  },
}));

const ApplicationNoteContent = ({
  variant,
  label,
  children,
}) => {
  const classes = useStyles();

  if (isEmpty(children)) return null;

  return (
    <pre className={classes.root}>
      {label && <Typography variant="caption">{label}</Typography>}
      <Typography variant={variant}>
        {children}
      </Typography>
    </pre>
  );
};

export default ApplicationNoteContent;
