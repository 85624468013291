import React from "react";
import { Typography, Divider, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    padding: theme.spacing(0, 2),
  },
  content: ({ helperText }) => ({
    padding: theme.spacing(0, 2),
    marginBottom: helperText ? 0 : theme.spacing(2),
  }),
  helperText: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.hint,
  },
}));

const FilterSection = ({
  label,
  withDivider,
  children,
  helperText,
  classes,
  helperTextPosition = "bottom",
}) => {
  const styles = useStyles({ helperText });
  const HelperTextComponent = () => (
    <>
      <div className="mr-8 flex items-center"><Icon fontSize="small" color="inherit">info</Icon></div>
      <Typography variant="caption" color="textSecondary">{helperText}</Typography>
    </>
  );
  return (
    <div className={clsx(styles.root, get(classes, "root", null))}>
      {helperText && helperTextPosition === "top" && (
        <div className={clsx(styles.helperText, get(classes, "helperText", null))}>
          <HelperTextComponent />
        </div>
      )}
      <div className={clsx(styles.container, get(classes, "container", null))}>
        {label && (
          <div className={clsx(styles.header, get(classes, "header", null))}>
            {label && <Typography className="font-black" gutterBottom>{label}</Typography>}
          </div>
        )}
        <div className={clsx(styles.content, get(classes, "content", null))}>
          {children}
        </div>
      </div>
      {helperText && helperTextPosition === "bottom" && (
        <div className={clsx(styles.helperText, "with-horizontal-divider", get(classes, "helperText", null))}>
          <HelperTextComponent />
        </div>
      )}
      {withDivider && <div className="mb-16"><Divider /></div>}
    </div>
  );
};

export default FilterSection;
