import { format } from "date-fns";
import React from "react";
import { Field } from "formik";
import { connect } from "react-redux";
import { DatePicker } from "components/inputs";
import { NoteInput } from "components/inputs/text-input";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import Form from "components/form";
import { onSaveOrUpdateReportBack } from "app/main/applications/actions/applications.actions";

const dueDate = "Due Date";
const notes = "Notes";

const schema = Yup.object().shape({
  reportBack: Yup.object().shape({
    dueDate: Yup.date()
      .default(() => format(Date.now(), "yyyy-MM-dd"))
      .required(`${dueDate} is required`)
      .nullable(),
    notes: Yup.string()
      .max(4000, "Too long!")
      .nullable(),
  }),
});

class ReportBackForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = ({ reportBack }, { setErrors }) => {
    const { applicationId, orgUnitId } = this.props;

    this.props.onSaveOrUpdateReportBack(applicationId, orgUnitId, reportBack).then(response => {
      if (response.error !== true) {
        this.props.onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  }

  render() {
    const { initialValues, ...other } = this.props;

    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        contentProps={other}
        validationSchema={schema}
        content={() => (
          <>
            <Field
              name="reportBack.dueDate"
              component={DatePicker}
              label={dueDate}
              required
            />
            <Field
              name="reportBack.notes"
              component={NoteInput}
              label={notes}
              maxLength={4000}
            />
          </>
        )}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reportBack: currentReportBack, id } = ownProps;
  let { reportBack } = schema.cast();

  if (!isEmpty(currentReportBack)) {
    reportBack = {
      ...currentReportBack,
    };
  }
  return ({
    initialValues: { reportBack },
    applicationId: id,
  });
};

export default connect(mapStateToProps, { onSaveOrUpdateReportBack })(ReportBackForm);
