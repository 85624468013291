import React from "react";

import EarHearingIcon from "mdi-react/EarHearingIcon";
import ShoePrintIcon from "mdi-react/ShoePrintIcon";
import FormatListChecksIcon from "mdi-react/FormatListChecksIcon";
import PhysicalIcon from "mdi-react/HumanMaleHeightIcon";
import HomeSafetyIcon from "mdi-react/HomeAlertIcon";
import FootPracticeIcon from "mdi-react/FootPrintIcon";
import BehaviourIcon from "mdi-react/FaceRecognitionIcon";
import CareProviderIcon from "mdi-react/HospitalMarkerIcon";
import ToothIcon from "mdi-react/ToothIcon";
import GlassWineIcon from "mdi-react/GlassWineIcon";
import WalkIcon from "mdi-react/WalkIcon";
import NeedleIcon from "mdi-react/NeedleIcon";
import FormatListNumberedIcon from "mdi-react/FormatListNumberedIcon";
import InfoIcon from "mdi-react/InformationIcon";
import PowerSleepIcon from "mdi-react/PowerSleepIcon";
import HumanHandsdownIcon from "mdi-react/HumanHandsdownIcon";
import LightbulbOnOutlineIcon from "mdi-react/LightbulbOnOutlineIcon";
import InformationVariantIcon from "mdi-react/InformationVariantIcon";
import MedicalBagIcon from "mdi-react/MedicalBagIcon";
import PillIcon from "mdi-react/PillIcon";

import { AllergyIcon, ImmunisationIcon } from "helpers/icon-finder";

import { ReactComponent as CardioIcon } from "../../assets/heart-pulse.svg";
import { ReactComponent as CoreInformationIcon } from "../../assets/coreInformation.svg";
import { ReactComponent as EyeOutlineIcon } from "../../assets/eye.svg";
import { ReactComponent as PreventativeIcon } from "../../assets/preventativeCare.svg";
import { ReactComponent as MedicalEquipmentIcon } from "../../assets/durableMedicalEquipment.svg";
import { ReactComponent as RespiratoryIcon } from "../../assets/respiratoryHealth.svg";
import { ReactComponent as VanishIcon } from "../../assets/skin.svg";
import { ReactComponent as PainIcon } from "../../assets/pain.svg";
import { ReactComponent as ProcedureIcon } from "../../assets/proceduralHistory.svg";
import { ReactComponent as MedicalHistoryIcon } from "../../assets/medicalHistory.svg";
import { ReactComponent as NutritionIcon } from "../../assets/nutritionalRisk.svg";
import { ReactComponent as DiabetesIcon } from "../../assets/diabetes.svg";
import { ReactComponent as DomesticViolenceIcon } from "../../assets/domesticViolence.svg";
import { ReactComponent as CognitiveIcon } from "../../assets/cognitiveAssessment.svg";
import { ReactComponent as MusculoskeletalIcon } from "../../assets/musculoskeletal.svg";
import { ReactComponent as DepressionIcon } from "../../assets/behaviour.svg";
import { ReactComponent as GenitourinaryIcon } from "../../assets/genitourinary.svg";
import { ReactComponent as GastrointestinalIcon } from "../../assets/gastrointestinal.svg";

function getSurveyItemIcon(surveyType) {
  if (!surveyType) {
    return null;
  }

  switch (surveyType) {
    case ("CurrentActivity"):
      return <WalkIcon />;
    case ("CoreInformation"):
      return <CoreInformationIcon />;
    case ("Cognitive"):
      return <CognitiveIcon />;
    case ("Vision"):
      return <EyeOutlineIcon />;
    case ("DomesticViolence"):
      return <DomesticViolenceIcon />;
    case ("Dental"):
      return <ToothIcon />;
    case ("Hearing"):
      return <EarHearingIcon />;
    case ("FootPractice"):
      return <FootPracticeIcon />;
    case ("Skin"):
      return <VanishIcon />;
    case ("foot"):
      return <ShoePrintIcon />;
    case ("Depression"):
      return <DepressionIcon />;
    case ("Substance"):
      return <GlassWineIcon />;
    case ("Behaviour"):
      return <BehaviourIcon />;
    case ("PreventativeCare"):
      return <PreventativeIcon />;
    case ("HomeSafety"):
      return <HomeSafetyIcon />;
    case ("Physical"):
      return <PhysicalIcon />;
    case ("OtherTreatments"):
      return <NeedleIcon />;
    case ("Musculoskeletal"):
      return <MusculoskeletalIcon />;
    case ("DurableMedicalEquipment"):
      return <MedicalEquipmentIcon />;
    case ("Respiratory"):
      return <RespiratoryIcon />;
    case ("Sleep"):
      return <PowerSleepIcon />;
    case ("Pain"):
      return <PainIcon />;
    case ("Cardiovascular"):
      return <CardioIcon />;
    case ("ProceduralHistory"):
      return <ProcedureIcon />;
    case ("MedicalHistory"):
      return <MedicalHistoryIcon />;
    case ("Nutrition"):
      return <NutritionIcon />;
    case ("General"):
      return <InfoIcon />;
    case ("CareProviders"):
      return <CareProviderIcon />;
    case ("Diabetes"):
      return <DiabetesIcon />;
    case ("Genitourinary"):
      return <GenitourinaryIcon />;
    case ("Gastrointestinal"):
      return <GastrointestinalIcon />;
    case ("Conditions"):
      return <FormatListNumberedIcon />;
    // group
    case ("BodySystems"):
      return <HumanHandsdownIcon />;
    case ("OtherPersonalAssessments"):
      return <LightbulbOnOutlineIcon />;
    case ("GeneralAssessments"):
      return <InformationVariantIcon />;
    case ("CoreMedicalRecord"):
      return <MedicalBagIcon />;
    case ("MedicationHistory"):
      return <PillIcon />;
    case "Allergies":
      return <AllergyIcon />;
    case "Immunisations":
      return <ImmunisationIcon />;
    default:
      return <FormatListChecksIcon />;
  }
}

export default getSurveyItemIcon;
