import React from "react";
import PropTypes from "prop-types";
import SortCalendarAscendingIcon from "mdi-react/SortCalendarAscendingIcon";
import SortCalendarDescendingIcon from "mdi-react/SortCalendarDescendingIcon";
import SortNumericDescendingIcon from "mdi-react/SortNumericDescendingIcon";
import SortNumericAscendingIcon from "mdi-react/SortNumericAscendingIcon";

import IconButton from "components/items/icon-button";
import { DescendingIcon, AscendingIcon } from "helpers/icon-finder";

const getIcon = type => {
  switch (type) {
    case "date":
      return ({
        des: <SortCalendarDescendingIcon />,
        asc: <SortCalendarAscendingIcon />,
      });
    case "number":
      return ({
        des: <SortNumericDescendingIcon />,
        asc: <SortNumericAscendingIcon />,
      });
    default:
      return ({
        des: <DescendingIcon />,
        asc: <AscendingIcon />,
      });
  }
};

const FilterSort = ({
  sortOrder,
  sortType,
  onClick,
  sortDescendingLabel,
  sortAscending,
  isLoading,
}) => {
  const title = sortOrder === "desc" ? sortDescendingLabel : sortAscending;
  const displayIcon = getIcon(sortType);
  const icon = sortOrder === "desc" ? displayIcon.des : displayIcon.asc;

  return <IconButton title={title} icon={icon} onClick={onClick} loading={isLoading} />;
};

FilterSort.prototype = {
  sortOrder: PropTypes.oneOf(["desc", "asc"]),
  sortType: PropTypes.oneOf(["default", "date", "number"]),
  onClick: PropTypes.func.isRequired,
  sortDescendingLabel: PropTypes.string,
  sortAscending: PropTypes.string,
  isLoading: PropTypes.bool,
};

FilterSort.defaultProps = {
  sortDescendingLabel: "Sort Descending",
  sortAscending: "Sort Ascending",
};

export default FilterSort;
