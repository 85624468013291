import React from "react";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

const PopupMenu = ({
  id,
  trigger,
  content,
}) => (
  <PopupState variant="popover" popupId={id || "popup-menu"}>
    {popupState => (
      <>
        {trigger({ ...bindTrigger(popupState) }, popupState)}
        {content({
          ...bindMenu(popupState),
          close: popupState.close,
        })}
      </>
    )}
  </PopupState>
);

export default PopupMenu;
