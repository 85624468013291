import { toString } from "lodash";
import formatFilterDate from "helpers/format-filter-date";

export default function translateReferralSearchParams(params) {
  let { status, category, assignedToSpecialtyId, assignedToUserId, assignedToRoleId, assignedToSubSpecialtyId, daysSinceLastStatusChange, taskAssignedToUserId, createdByUserId } = params;
  const { startDate, endDate, unreadAddendum, isUrgent, showOldestFirst, allStatus, allCategory, onlyShowUnassigned, taskStatus, term } = params;

  assignedToSpecialtyId = assignedToSpecialtyId?.value ?? assignedToSpecialtyId;
  assignedToUserId = assignedToUserId?.value ?? assignedToUserId;
  assignedToRoleId = assignedToRoleId?.value ?? assignedToRoleId;
  assignedToSubSpecialtyId = assignedToSubSpecialtyId?.value ?? assignedToSubSpecialtyId;
  taskAssignedToUserId = taskAssignedToUserId?.value ?? taskAssignedToUserId;
  createdByUserId = createdByUserId?.value ?? createdByUserId;

  daysSinceLastStatusChange = toString(daysSinceLastStatusChange);

  if (allStatus) {
    status = [];
  }

  if (allCategory) {
    category = [];
  }

  return ({
    status,
    category,
    startDate: formatFilterDate(startDate),
    endDate: formatFilterDate(endDate),
    showOldestFirst,
    unreadAddendum,
    isUrgent,
    onlyShowUnassigned,
    assignedToSpecialtyId,
    assignedToUserId,
    assignedToRoleId,
    daysSinceLastStatusChange,
    assignedToSubSpecialtyId,
    taskStatus,
    taskAssignedToUserId,
    createdByUserId,
    term,
  });
}
