import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { map, indexOf } from "lodash";
import clsx from "clsx";
import SquareRoundedIcon from "mdi-react/SquareRoundedIcon";
import SquareRoundedOutlineIcon from "mdi-react/SquareRoundedOutlineIcon";
import { useDispatch } from "react-redux";
import { Container, Draggable } from "react-smooth-dnd";
import { Typography, Button, InputBase } from "@material-ui/core";

import IconComponent from "components/items/icon-component";
import Form from "components/form";
import { editEndorsementTypeConfiguration } from "app/main/orgUnits/actions/orgUnit.actions";
import { showMessage } from "app/store/actions/message.actions";
import applyDrag from "helpers/apply-drag";

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2),
  },
  listItem: {
    display: "flex",
    margin: theme.spacing(0.3),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  button: {
    display: "flex",
  },
  activeButton: {
    color: theme.palette.success.main,
  },
  columnDragHandle: {
    cursor: "move",
    padding: theme.spacing(0, 1),
  },
  disabledColumnDragHandle: {
    pointerEvents: "none",
    color: theme.palette.divider,
  },
}));

const ToggleButton = ({ label, active, ...other }) => {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      size="small"
      startIcon={(
        <div className={clsx(classes.button, active && classes.activeButton)}>
          <IconComponent icon={active ? <SquareRoundedIcon /> : <SquareRoundedOutlineIcon />} />
        </div>
      )}
      {...other}
    >
      {label}
    </Button>
  );
};

const OrgUnitEndorsementTypeListItem = ({
  initialFields,
  orgUnit,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [fieldItems, setFieldItems] = useState(initialFields);

  const handleSubmit = (data, { setSubmitting }) => {
    const reorderedList = map(data, x => ({ ...x, order: indexOf(data, x) }));
    dispatch(editEndorsementTypeConfiguration(orgUnit.id, reorderedList)).then(res => {
      setSubmitting(false);

      if (!res.error) {
        dispatch(showMessage({ variant: "success", message: "Successfully updated the endorsement types for this site." }));
      }
    });
  };

  const renderItem = (endorsementType, index, setFieldValue) => (
    <Draggable key={endorsementType.endorsedByType}>
      <div className={classes.listItem}>
        <div className="flex flex-1">
          <Typography className="font-bold">{endorsementType.endorsedByTypeLabel}</Typography>
        </div>
        <div className="flex-row-container with-gutter">
          <ToggleButton
            label="Enabled"
            active={endorsementType.enabled}
            onClick={() => setFieldValue(index, { ...endorsementType, enabled: !endorsementType.enabled })}
          />
          <ToggleButton
            label="Automatically Added"
            active={endorsementType.automaticallyAdded}
            disabled={!endorsementType.enabled}
            onClick={() => setFieldValue(index, { ...endorsementType, automaticallyAdded: !endorsementType.automaticallyAdded })}
          />
          <Typography className="font-bold" style={{ color: endorsementType.enabled && endorsementType.automaticallyAdded ? "initial" : "grey" }}>Cost Threshold:</Typography>
          <InputBase
            type="number"
            style={{ padding: "4px", border: endorsementType.enabled && endorsementType.automaticallyAdded ? "1px solid black" : "1px solid grey" }}
            disabled={!endorsementType.automaticallyAdded}
            value={endorsementType.costThreshold || ""}
            onChange={event => setFieldValue(index, { ...endorsementType, costThreshold: event.target.value })}
          />
          <span className={clsx("column-drag-handle", classes.columnDragHandle, !endorsementType.enabled && classes.disabledColumnDragHandle)}>&#x2630;</span>
        </div>
      </div>
    </Draggable>
  );

  return (
    <Form
      onSubmit={handleSubmit}
      isModal={false}
      classes={{ content: classes.content }}
      showCancelButton={false}
      initialValues={fieldItems}
      content={({ values, setFieldValue }) => (
        <Container lockAxis="y" dragHandleSelector=".column-drag-handle" onDrop={e => setFieldItems(applyDrag(values, e))}>
          {map(values, (endorsementType, index) => renderItem(endorsementType, index, setFieldValue))}
        </Container>
      )}
    />
  );
};

export default OrgUnitEndorsementTypeListItem;
