/* eslint-disable react/no-danger */
import React, { useState } from "react";
import { map, indexOf, find } from "lodash";
import { Breadcrumbs, Button, Divider } from "@material-ui/core";

const ReferralReportPanel = ({
  reports,
}) => {
  const latestVersion = find(reports, report => report.isLatestVersion);

  const [selected, setSelected] = useState(indexOf(reports, latestVersion));
  const [selectedReport, setSelectedReport] = useState(latestVersion);

  return (
    <>
      <Breadcrumbs separator="|" aria-label="breadcrumb">
        {map(reports, (report, index) => {
          const isSelected = index === selected;
          return (
            <Button
              key={`${report.type}-${report.documentVersion}`}
              onClick={() => { setSelected(index); setSelectedReport(report); }}
              color={isSelected ? "secondary" : "default"}
              size="medium"
            >
              {report.type === "Original" ? report.type : "Update"} {report.type !== "Original" && report.documentVersion}
            </Button>
          );
        })}
      </Breadcrumbs>
      <Divider className="my-8" />
      <div dangerouslySetInnerHTML={{ __html: selectedReport.printableViewContent }} />
    </>
  );
};

export default ReferralReportPanel;
