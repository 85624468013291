import { makeStyles } from "@material-ui/styles";

const clearButtonStyles = theme => ({
  root: {
    zIndex: 99,
    position: "absolute",
    right: theme.spacing(4),
    display: "flex",
  },
});

const selectInputStyles = () => ({
  root: {
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
});

const filledInputStyles = theme => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[100],
    minWidth: 120,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    "&:before, &:after": {
      display: "none",
    },
  },
  error: {
    backgroundColor: "#fff5f5",
    boxShadow: "0 0 0 2px #ff6b81",
    "&:hover": {
      backgroundColor: "#ffecec",
    },
    "&$focused": {
      backgroundColor: "#ffecec",
    },
  },
  focused: {},
  disabled: {
    "& *": {
      color: "inherit",
    },
  },
  adornedEnd: {
    paddingRight: 0,
  },
});

// eslint-disable-next-line import/prefer-default-export
export const textFieldHook = {
  useSelectInputStyles: makeStyles(() => selectInputStyles()),
  useClearButtonStyles: makeStyles(theme => clearButtonStyles(theme)),
  useFilledInputStyles: makeStyles(theme => filledInputStyles(theme)),
};
