import { startCase, isEmpty } from "lodash";

export default function formatAssignmentOptions({
  assignedToId,
  assignmentType,
  assignedToUserId,
  assignedToSpecialtyId,
  assignedToRoleId,
  assignedToDisplayName,
  assignedToRoleName,
}) {
  let displayLabel = assignedToDisplayName;

  if (!isEmpty(assignedToRoleName)) {
    displayLabel = `${displayLabel}${assignedToRoleName && ` (${startCase(assignedToRoleName)})`}`;
  }

  return {
    value: assignedToId,
    label: displayLabel,
    assignmentType,
    assignedToUserId,
    assignedToSpecialtyId,
    assignedToRoleId,
    assignedToRoleName,
    assignedToDisplayName: displayLabel,
    assignedToId,
  };
}
