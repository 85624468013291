import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import { connect } from "react-redux";
import withPermissions from "permissions/withPermissions";
import clsx from "clsx";
import { includes, isEmpty, find } from "lodash";

import ReferralCategoryLabel from "app/main/referrals/components/referral-category-label";
import DefaultButton from "components/items/default-button";
import AcceptReferralForm from "app/main/referrals/components/accept-referral-form/index";
import RejectReferralForm from "app/main/referrals/components/reject-referral-form";
import CompleteReferralForm from "app/main/referrals/components/complete-referral-form";
import ArchiveReferralForm from "app/main/referrals/components/archive-referral-form";
import UndoReferralDialog from "app/main/referrals/components/undo-referral-dialog";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import ReferralRequestMoreInfoForm from "app/main/referrals/components/referral-request-more-info-form";
import TextLink from "components/items/text-link";

import ItemStatus from "components/items/item-status";
import { getReferralActionTypeValues, getReferralWorkflowStateSettings, getReferralActionTypeSettings } from "app/auth/store/reducers/system-configuration";
import { blue } from "@material-ui/core/colors";
import { rejectedWorkflowState, acceptedWorkflowState, archivedWorkflowState, requestedWorkflowState, completedWorkflowState, cancelledWorkflowState } from "../helpers/workflow-state-names";
import { acceptActionType, rejectActionType, completeActionType, archiveActionType, requestMoreInformationActionType } from "../helpers/action-type-names";

class ReferralWorkflowPanel extends Component {
  onClose = () => {
    this.props.closeDialog();
  };

  getReferralActionSettings = actionType => find(this.props.referralActionTypeSettings, x => x.actionType === actionType)

  openActionForm = ({ actionType, component: FormComponent, submitLabel, ...other }) => (
    this.props.openDialog({
      children: (
        <FormComponent
          referralId={this.props.referral.id}
          onSucceed={this.onClose}
          title={this.getReferralActionFormTitle(actionType)}
          submitLabel={submitLabel ?? "Submit"}
          message={this.getReferralActionSettings(actionType)?.warningMessage}
          {...other}
        />
      ),
    })
  )

  actionButton = ({ actionType, settings, ...other }) => <DefaultButton icon={settings?.icon} style={{ backgroundColor: settings?.color }} label={this.getReferralActionLabel(actionType)} {...other} />

  handleAcceptPopup = () => this.openActionForm({ actionType: acceptActionType, component: AcceptReferralForm, submitLabel: "Save & Accept", referral: this.props.referral });

  handleRejectPopup = () => this.openActionForm({ actionType: rejectActionType, component: RejectReferralForm });

  handleCompletePopup = () => this.openActionForm({ actionType: completeActionType, component: CompleteReferralForm });

  handleArchivePopup = () => this.openActionForm({ actionType: archiveActionType, component: ArchiveReferralForm });

  handleUndoPopup = referral => {
    this.props.openDialog({
      children: <UndoReferralDialog onSucceed={this.onClose} referral={referral} />,
    });
  };

  getReferralActionLabel = actionType => {
    const referralActionSettings = this.getReferralActionSettings(actionType);
    return referralActionSettings ? referralActionSettings.nameSingle : actionType;
  }

  getReferralActionedName = actionType => {
    const referralActionSettings = this.getReferralActionSettings(actionType);
    return referralActionSettings ? referralActionSettings.actionedName : actionType;
  }

  getReferralActionFormTitle = actionType => {
    const referralActionSettings = this.getReferralActionSettings(actionType);
    return referralActionSettings ? referralActionSettings.formTitle : actionType;
  }

  getWorkflowStateSettings = stateName => find(this.props.referralWorkflowSettings, x => x.workflowState === stateName) || { icon: "lens", color: blue[600], label: "" }

  getActionTypeSettings = actionType => find(this.props.referralActionTypeSettings, x => x.actionType === actionType) || { icon: "lens", color: blue[600], label: "" }

  render() {
    const {
      referral,
      hasPermissionReferralsAccept,
      hasPermissionReferralsReject,
      hasPermissionReferralsComplete,
      hasPermissionReferralsArchive,
      hasPermissionReferralsUndo,
      hasPermissionReferralCorrespondenceCreate,
    } = this.props;
    const { referralStatus, availableActions } = referral;

    let referralStatusConfig = this.getWorkflowStateSettings(referralStatus);

    if (referralStatus === archivedWorkflowState) {
      referralStatusConfig = this.getWorkflowStateSettings(rejectedWorkflowState);
    } else if (referralStatus === completedWorkflowState) {
      referralStatusConfig = this.getWorkflowStateSettings(acceptedWorkflowState);
    }

    let Content = null;
    const accept = this.getActionTypeSettings(acceptActionType);
    const reject = this.getActionTypeSettings(rejectActionType);
    const complete = this.getActionTypeSettings(completeActionType);
    const archive = this.getActionTypeSettings(archiveActionType);

    const ActionButtonGroup = () => {
      if (hasPermissionReferralsAccept || hasPermissionReferralsReject) {
        return (
          <div className="flex items-center mt-8">
            {hasPermissionReferralsAccept && includes(availableActions, acceptActionType)
              && this.actionButton({ actionType: acceptActionType, settings: accept, onClick: this.handleAcceptPopup })}
            {hasPermissionReferralsReject && includes(availableActions, rejectActionType)
              && this.actionButton({ actionType: rejectActionType, settings: reject, onClick: this.handleRejectPopup, className: clsx(hasPermissionReferralsAccept && "ml-16") })}
          </div>
        );
      }
      return null;
    };

    if (includes(availableActions, requestMoreInformationActionType)) {
      Content = () => (
        <>
          <ActionButtonGroup />
          {hasPermissionReferralCorrespondenceCreate
          && (
            <div className="mt-8">
              <TextLink
                content={this.getReferralActionLabel(requestMoreInformationActionType)}
                variant="caption"
                fontSize="small"
                onClick={() => this.openActionForm({ actionType: requestMoreInformationActionType, component: ReferralRequestMoreInfoForm })}
              />
            </div>
          )}
        </>
      );
    } else if (includes(availableActions, completeActionType)) {
      Content = () => (
        <>
          <ReferralCategoryLabel triageCategory={referral.triageCategory} isUrgent={referral.isUrgent} />
          {hasPermissionReferralsComplete && this.actionButton({ actionType: completeActionType, settings: complete, onClick: this.handleCompletePopup, className: "mt-8" })}
        </>
      );
    } else if (referralStatus === rejectedWorkflowState && includes(availableActions, archiveActionType)) {
      Content = () => (
        hasPermissionReferralsArchive && this.actionButton({ actionType: archiveActionType, settings: archive, onClick: this.handleArchivePopup, className: "mt-8" })
      );
    } else if (isEmpty(availableActions) && referralStatus === completedWorkflowState) {
      Content = () => (
        <ItemStatus icon={complete.icon} label={this.getReferralActionedName(completeActionType).toUpperCase()} color={complete.color} />
      );
    } else if (isEmpty(availableActions) && referralStatus === archivedWorkflowState) {
      Content = () => (
        <ItemStatus icon={archive.icon} label={this.getReferralActionedName(archiveActionType).toUpperCase()} color={archive.color} />
      );
    } else {
      Content = () => <ActionButtonGroup />;
    }

    // render Undo button when the referral status is not requested and user has permission.
    const renderUndoButton = referralStatus !== requestedWorkflowState && referralStatus !== cancelledWorkflowState && hasPermissionReferralsUndo;

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        className="px-16 py-8 h-full"
        style={{ backgroundColor: fade(referralStatusConfig.color, 0.1) }}
      >
        <ItemStatus icon={referralStatusConfig.icon} label={referralStatusConfig.label.toUpperCase()} color={referralStatusConfig.color} />
        {/* render content base on status */}
        <Content />
        {renderUndoButton
        && (
        <div className="mt-8">
          <TextLink
            content="UNDO"
            variant="caption"
            fontSize="small"
            onClick={() => this.handleUndoPopup(referral)}
          />
        </div>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  referralActionTypes: getReferralActionTypeValues(state),
  referralWorkflowSettings: getReferralWorkflowStateSettings(state),
  referralActionTypeSettings: getReferralActionTypeSettings(state),
});

export default withPermissions(
  "ReferralsAccept", "ReferralsReject", "ReferralsComplete", "ReferralsArchive", "ReferralsUndo", "ReferralCorrespondenceCreate",
)(connect(mapStateToProps, { openDialog, closeDialog, getReferralActionTypeValues, getReferralWorkflowStateSettings, getReferralActionTypeSettings })(ReferralWorkflowPanel));
