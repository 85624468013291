import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { map, isEmpty, filter, includes } from "lodash";
import { Typography } from "@material-ui/core";
import CardTextOutlineIcon from "mdi-react/CardTextOutlineIcon";
import CardOffOutlineIcon from "mdi-react/CardOffOutlineIcon";
import moment from "moment";

import { PanelHeaderAction } from "components/items/chat-panel";
import MultipleActionButtons, { ActionButton, TriggerButton } from "components/items/multiple-action-buttons";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import PatientCardFrom from "app/main/patients/components/patient-card-form";
import { getCurrentPatient, isCurrentPatientLoading } from "app/main/patients/reducers/patients.reducers";
import PatientContextListItem, { ItemContent } from "app/main/patients/components/patient-context-list-item";
import { ContextItem } from "components/items/default-item";
import formatDate from "helpers/format-date";
import { removePatientCard, removePatientIdentifier } from "app/main/patients/actions/patients.actions";
import DefaultPanelLayout from "components/items/default-panel-layout";
import PatientIdentifierForm from "app/main/patients/components/patient-identifier-form";
import { getPatientIdentifierTypeOptions } from "app/auth/store/reducers/system-configuration";
import withPermissions from "permissions/withPermissions";

const checkIsExpiryDate = date => moment(date).isBefore(moment(), "day");

function PatientCardIdentifierPage({ patientId, hasPermissionPatientsIdentifiersUpdate, hasPermissionPatientsCardsUpdate }) {
  const dispatch = useDispatch();
  const loading = useSelector(isCurrentPatientLoading);
  const patient = useSelector(getCurrentPatient);
  const allPatientIdentifierTypes = useSelector(getPatientIdentifierTypeOptions);
  // at the moment, cards are in identifier list

  const allowedPatientIdentifierTypes = filter(patient?.patientIdentifiers, x => includes(map(allPatientIdentifierTypes, type => type.value), x.patientIdentifierTypeCode));

  const onClose = () => dispatch(closeDialog());

  const handleOpenCardForm = card => dispatch(openDialog({
    children: (
      <PatientCardFrom
        title={isEmpty(card) ? "Add Card" : "Edit Card"}
        patientId={patientId}
        onCancel={onClose}
        onSucceed={onClose}
        patientCard={card}
      />
    ),
  }));

  const handleOpenPatientIdentifierForm = identifier => dispatch(openDialog({
    children: (
      <PatientIdentifierForm
        patientIdentifier={identifier}
        patientId={patientId}
        onCancel={() => dispatch(closeDialog())}
        onSucceed={() => dispatch(closeDialog())}
        showIsDeprecated
      />
    ),
  }));

  return (
    <>
      {(hasPermissionPatientsIdentifiersUpdate || hasPermissionPatientsCardsUpdate) && (
      <PanelHeaderAction>
        <MultipleActionButtons renderTrigger={triggerProps => <TriggerButton {...triggerProps} />}>
          {hasPermissionPatientsIdentifiersUpdate && <ActionButton label="Add Identifier" icon={<CardTextOutlineIcon />} onClick={handleOpenPatientIdentifierForm} />}
          {hasPermissionPatientsCardsUpdate && <ActionButton label="Add Card" icon="payment" onClick={handleOpenCardForm} />}
        </MultipleActionButtons>
      </PanelHeaderAction>
      )}
      <DefaultPanelLayout title="Identifiers" isLoading={loading} isEmpty={isEmpty(allowedPatientIdentifierTypes)} emptyTitle="No Recorded Identifiers">
        {map(allowedPatientIdentifierTypes, (x, index) => (
          <PatientContextListItem
            isFirst={index === 0}
            key={x.patientIdentifierId}
            title={x.label}
            onEdit={hasPermissionPatientsIdentifiersUpdate ? () => handleOpenPatientIdentifierForm(x) : null}
            onRemove={hasPermissionPatientsIdentifiersUpdate ? () => dispatch(removePatientIdentifier(patientId, x.patientIdentifierId)).then(() => dispatch(closeDialog())) : null}
            renderItemContent={() => (
              <ItemContent
                title={x.label}
                icon={x.isDeprecated ? <CardOffOutlineIcon /> : <CardTextOutlineIcon />}
                content={<Typography variant="caption" color={x.isDeprecated ? "textSecondary" : "textPrimary"}>{x.identifier}</Typography>}
                status={x.isDeprecated && <Typography variant="caption" color="textSecondary">No longer in use</Typography>}
              />
            )}
            renderPreviewContent={() => (
              <ContextItem
                labelClassName="w-128"
                label="Identifier"
                content={(
                  <Typography color={x.isDeprecated ? "textSecondary" : "textPrimary"}>
                    {x.identifier}
                    {x.isDeprecated && <Typography variant="caption" color="textSecondary"> - no longer in use</Typography>}
                  </Typography>
                  )}
              />
            )}
          />
        ))}
      </DefaultPanelLayout>
      <DefaultPanelLayout title="Cards" isLoading={loading} isEmpty={isEmpty(patient?.patientCards)} emptyTitle="No Recorded Cards" isLast>
        {map(patient?.patientCards, (card, index) => {
          const isExpired = checkIsExpiryDate(card.expiryDate);

          return (
            <PatientContextListItem
              isFirst={index === 0}
              key={card.patientCardId}
              title={card.patientCardTypeDescription}
              onEdit={hasPermissionPatientsCardsUpdate ? () => handleOpenCardForm(card) : null}
              onRemove={hasPermissionPatientsCardsUpdate ? () => dispatch(removePatientCard(patientId, card.patientCardId)).then(() => dispatch(closeDialog())) : null}
              renderItemContent={() => (
                <ItemContent
                  title={card.patientCardTypeDescription}
                  icon="payment"
                  content={(
                    <Typography variant="caption">{card.patientCard} {card?.expiryDate && formatDate(card.expiryDate)} {isExpired && <span className="text-red-700 font-bold">EXPIRED</span>}</Typography>
                    )}
                />
              )}
              renderPreviewContent={() => (
                <>
                  <ContextItem labelClassName="w-128" label="Card Type" content={card.patientCardTypeDescription} />
                  <ContextItem labelClassName="w-128" label="Card Number" content={card.patientCard} />
                  <ContextItem labelClassName="w-128" label="Expiry Date" content={formatDate(card.expiryDate)} />
                </>
              )}
            />
          );
        })}
      </DefaultPanelLayout>
    </>
  );
}

export default withPermissions(
  "PatientsIdentifiersUpdate",
  "PatientsCardsUpdate",
)(PatientCardIdentifierPage);
