import React, { useState } from "react";
import { IconButton, Icon, Menu, MenuItem, ListItemIcon, Paper, CircularProgress, Badge } from "@material-ui/core";
import PropTypes from "prop-types";
import { map, some } from "lodash";
import PopupMenu from "components/items/popup-menu";
import IconComponent from "components/items/icon-component";
import checkIfEmpty from "helpers/check-if-empty";
import DrawerFilterContent from "./drawer-filter-content";
import AccordionFilterContent from "./accordion-filter-content";

import FilterSearch from "./filter-search";

const AdvancedFilter = ({
  content,
  searchValue,
  onSearch,
  onSearchChange,
  renderPresetFilter,
  renderFilterTags,
  renderFilterInfo,
  searchPlaceholder,
  renderActions,
  renderExtraActions,
  filterContentAnchor = "left",
  withPaperWrapper = true,
  hasFilterValue,
  component: WrapperComponent = withPaperWrapper ? Paper : React.Fragment,
  searchInputProps,
  variant = "drawer",
}) => {
  const [filterIsOpen, setFilterStatus] = useState(false);
  const [currentLoading, setCurrentLoading] = useState(null);
  const hasExtraActions = renderExtraActions && some(renderExtraActions, x => !checkIfEmpty(x));

  const onClose = () => setFilterStatus(false);

  const presetFilter = () => {
    if (!renderPresetFilter) return null;
    return renderPresetFilter({
      openAdvancedFilter: () => setFilterStatus(true),
      closeAdvancedFilter: () => setFilterStatus(false),
    });
  };

  return (
    <>
      {renderPresetFilter && presetFilter()}
      <WrapperComponent>
        <div className="flex items-center flex-auto">
          <FilterSearch
            value={searchValue}
            onSearch={onSearch}
            onChange={onSearchChange}
            searchPlaceholder={searchPlaceholder}
            {...searchInputProps}
          />
          {renderActions && renderActions}
          {content && (
          <>
            <div className="flex items-center">
              <IconButton onClick={() => setFilterStatus(!filterIsOpen)} color={filterIsOpen ? "primary" : "default"}>
                <Badge variant="dot" color="primary" invisible={!hasFilterValue}>
                  <Icon fontSize="small">filter_list</Icon>
                </Badge>
              </IconButton>
              {hasExtraActions
                && (
                  <PopupMenu
                    trigger={popupState => (
                      <IconButton {...popupState}>
                        <Icon fontSize="small">more_vert</Icon>
                      </IconButton>
                    )}
                    content={({ close, ...other }) => (
                      <Menu {...other}>
                        {map(renderExtraActions, action => {
                          if (!action) return null;
                          const isLoading = currentLoading === action.label;
                          return (
                            <MenuItem
                              key={action.label}
                              component="button"
                              disabled={isLoading}
                              onClick={() => {
                                setCurrentLoading(action.label);
                                Promise.resolve(action.onClick()).then(status => {
                                  setCurrentLoading(null);
                                  if (status) {
                                    close();
                                  }
                                });
                              }}
                            >
                              <ListItemIcon>
                                {isLoading
                                  ? <CircularProgress size={20} />
                                  : (
                                    <Badge
                                      color="primary"
                                      variant="dot"
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      badgeContent={action?.selected ? 1 : 0}
                                    >
                                      <IconComponent icon={action?.icon} />
                                    </Badge>
                                  )}
                              </ListItemIcon>
                              {action.label}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    )}
                  />
                )}
            </div>
          </>
          )}
        </div>
        {renderFilterTags && (
          <div role="presentation" onClick={() => setFilterStatus(true)}>
            {renderFilterTags}
          </div>
        )}
      </WrapperComponent>
      {renderFilterInfo && <div className="mt-8">{renderFilterInfo}</div>}
      {content && variant === "drawer" && <DrawerFilterContent open={filterIsOpen} anchor={filterContentAnchor} content={content(onClose)} />}
      {content && variant === "accordion" && <AccordionFilterContent expanded={filterIsOpen} content={content(onClose)} />}
    </>
  );
};

AdvancedFilter.prototype = {
  variant: PropTypes.oneOf(["drawer", "accordion"]),
  content: PropTypes.element,
  renderPresetFilter: PropTypes.func,
  renderExtraActions: PropTypes.PropTypes.shape({
    icon: PropTypes.element,
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  renderActions: PropTypes.func,
};

export default AdvancedFilter;

export { default as FilterSection } from "./filter-section";
export { default as FilterForm } from "./filter-form";
export { default as FilterTags } from "./filter-tags";
export { default as FilterSearch } from "./filter-search";
export { default as FilterSort } from "./filter-sort";
