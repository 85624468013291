export default function getCandidateDiseaseStateDescription(diseaseStateType) {
  switch (diseaseStateType) {
    case "IHD":
      return "Ischemic Heart Disease";
    case "HF":
      return "Heart Failure";
    case "DM":
      return "Diabetes Mellitus";
    case "COPD":
      return "Chronic Obstructive Pulmonary Disease";
    default:
      return null;
  }
}
