import React from "react";
import { IconButton as MuiIconButton, Tooltip, CircularProgress } from "@material-ui/core";
import IconComponent from "./icon-component";

const IconButton = ({ title, onClick, icon, size, iconSize = 20, children, loading, ...other }) => (
  <Tooltip title={title}>
    <div>
      <MuiIconButton aria-label={title} size={size} onClick={onClick} {...other}>
        {loading ? <CircularProgress size={iconSize} /> : (
          <>
            <IconComponent icon={icon} size={iconSize} />
            {children}
          </>
        )}
      </MuiIconButton>
    </div>
  </Tooltip>
);

export default IconButton;
