import React from "react";
import { map } from "lodash";

import ImmunisationsListItem from "app/main/patients/immunisations/components/immunisation-list-item";
import StateContainer from "components/layouts/default-state-container";

const ImmunisationsList = ({
  immunisations,
  onEditImmunisation,
  onRemoveImmunisation,
  isEmpty,
  isError,
  isLoading,
}) => (
  <StateContainer loading={isLoading} error={isError} empty={isEmpty} emptyTitle="No Recorded Immunisations">
    {map(immunisations, (immunisation, index) => (
      <ImmunisationsListItem
        item={immunisation}
        key={immunisation.id}
        isFirst={index === 0}
          // actions
        onEdit={onEditImmunisation ? () => onEditImmunisation(immunisation.id) : null}
        onRemove={onRemoveImmunisation ? () => onRemoveImmunisation(immunisation.id) : null}
      />
    ))}
  </StateContainer>
);

export default ImmunisationsList;
