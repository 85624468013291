import { useEffect } from "react";
import { fetchOrgUnitFormFields } from "app/main/orgUnits/actions/orgUnit.actions";
import { useSelector, useDispatch } from "react-redux";
import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import { getCurrentOrgUnitFormFieldConfig, areCurrentOrgUnitFormFieldConfigLoading } from "app/main/orgUnits/reducers/orgUnit.reducers";

const useFormFieldConfig = formType => {
  const dispatch = useDispatch();
  const orgUnitId = useSelector(getSignedInOrgUnit)?.id;
  const loadingFieldSettings = useSelector(state => areCurrentOrgUnitFormFieldConfigLoading(state, orgUnitId));
  const fieldSettings = useSelector(state => getCurrentOrgUnitFormFieldConfig(state, orgUnitId, formType));

  useEffect(() => {
    dispatch(fetchOrgUnitFormFields(orgUnitId, formType));
  }, [formType, orgUnitId, dispatch]);

  return { fieldSettings, loadingFieldSettings };
};

export default useFormFieldConfig;
