import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import PatientContactForm from "app/main/patients/components/patient-contact-form";
import PopupLink from "components/items/popup-link";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { getCurrentPatientNextOfKin } from "app/main/patients/reducers/patients.reducers";
import withPermissions from "permissions/withPermissions";
import DefaultItem from "components/items/default-item";
import { AccountOutLineIcon, PhoneIcon } from "helpers/icon-finder";
import { setCurrentContact } from "app/main/patients/actions/contacts.actions";

const NextOfKinPopupContent = ({
  givenName,
  familyName,
  contact,
}) => (
  <div>
    <DefaultItem icon={<AccountOutLineIcon />} content={`${givenName} ${familyName}`} />
    <DefaultItem className="mt-16" icon={<PhoneIcon />} content={contact} />
  </div>
);

const NextOfKin = ({
  patientId,
  hasPermissionPatientsNextOfKinUpdate,
  disabled,
}) => {
  const dispatch = useDispatch();
  const nextOfKin = useSelector(getCurrentPatientNextOfKin);
  const enableEdit = !disabled && !isEmpty(patientId) && hasPermissionPatientsNextOfKinUpdate;

  const showDialog = () => {
    if (!isEmpty(nextOfKin)) {
      dispatch(setCurrentContact(nextOfKin));
    } else {
      dispatch(setCurrentContact());
    }

    dispatch(openDialog({
      children: (
        <PatientContactForm
          onSucceed={() => dispatch(closeDialog())}
          title="Next of Kin"
          patientId={patientId}
          isNOK
        />
      ),
    }));
  };

  return (
    <PopupLink
      onClick={enableEdit ? () => showDialog() : null}
      label="NOK"
      content={!isEmpty(nextOfKin) ? <NextOfKinPopupContent givenName={nextOfKin.givenName} familyName={nextOfKin.familyName} contact={nextOfKin.contact} /> : null}
    />
  );
};

export default withPermissions("PatientsNextOfKinUpdate")(NextOfKin);
