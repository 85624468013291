import React, { useState, useEffect } from "react";
import { Field } from "formik";
import { Typography } from "@material-ui/core";
import TextBoxSearchOutlineIcon from "mdi-react/TextBoxSearchOutlineIcon";
import { find } from "lodash";

import AdvancedFilter, { FilterForm, FilterTags, FilterSection } from "components/items/advanced-filter";
import { TextInput, SelectInput, CheckboxInput } from "components/inputs";
import { AscendingIcon, DescendingIcon } from "helpers/icon-finder";

import { defaultSearchParams } from "../reducers/externalDocumentWorklist.reducers";

const sortOptions = [
  { label: "Created Date - Oldest First", value: "CreatedDateAsc", icon: <AscendingIcon /> },
  { label: "Created Date - Newest First", value: "CreatedDateDesc", icon: <DescendingIcon /> },
];

const ExternalDocumentAdvancedFilter = ({
  onSearch,
  initialValues,
  searchParams,
  total,
}) => {
  const [searchTags, setSearchTags] = useState(null);
  const searchPlaceholder = "Search by file name";

  const updateSearchTags = () => {
    const tags = [
      searchParams.sortBy === "CreatedDateAsc" && "Sort by created date ascending",
      searchParams.showRemovedOnly && "Show removed only",
    ];

    setSearchTags(tags);
  };

  useEffect(() => {
    updateSearchTags();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const onSearchText = searchTerm => onSearch({ ...searchParams, name: searchTerm }, null, null, true);
  const handleSubmit = ({ externalDocumentFilters }) => onSearch(externalDocumentFilters, 1, null, true);
  const handleReset = () => onSearch(defaultSearchParams, 1, null, true);

  return (
    <AdvancedFilter
      renderFilterInfo={<Typography variant="caption">Total Results: {total}</Typography>}
      onSearch={searchText => onSearchText(searchText)}
      searchPlaceholder={searchPlaceholder}
      searchValue={searchParams.name}
      renderFilterTags={<FilterTags tags={searchTags} />}
      content={onClose => (
        <FilterForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onClose={onClose}
          onReset={handleReset}
          filters={({ values }) => {
            const sortByIcon = find(sortOptions, x => x.value === values.externalDocumentFilters.sortBy)?.icon;
            return (
              <div>
                <FilterSection withDivider>
                  <Field
                    label={searchPlaceholder}
                    name="externalDocumentFilters.name"
                    component={TextInput}
                    icon={<TextBoxSearchOutlineIcon />}
                  />
                </FilterSection>
                <FilterSection withDivider label="Sort By">
                  <Field
                    name="externalDocumentFilters.sortBy"
                    icon={sortByIcon}
                    component={SelectInput}
                    options={sortOptions}
                  />
                </FilterSection>
                <FilterSection withDivider>
                  <Field
                    name="externalDocumentFilters.showRemovedOnly"
                    label="Show Removed Only"
                    component={CheckboxInput}
                    showIcon={false}
                  />
                </FilterSection>
              </div>
            );
          }}
        />
      )}
    />
  );
};

export default ExternalDocumentAdvancedFilter;
