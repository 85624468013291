import React from "react";
import { Card, CardContent, Typography, Grow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { InteractionRequiredAuthError, InteractionStatus, InteractionType } from "@azure/msal-browser";
import DefaultButton from "components/items/default-button";

import { getLogo, getLoginMessage } from "utils/get-environment-variables";
import { Alert } from "@material-ui/lab";

const LOGO = getLogo();
const LoginMessage = getLoginMessage();
const logoPath = LOGO ? `${process.env.PUBLIC_URL}/assets/logo/${LOGO}` : "";

// flex flex-col items-center justify-center py-24 px-32
const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.contrastText,
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
  },
  card: {
    maxWidth: 500,
    width: "100%",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3, 4),
  },
  contentContainer: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
  imageContainer: {
    maxWidth: 256,
  },
  image: {
    width: 230,
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

function AzureLoginPage({ error, login, inProgress }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="flex flex-col items-center justify-center w-full">
        <Grow in>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              {LOGO && <div className={classes.imageContainer}><img src={logoPath} alt="logo" className={classes.image} /></div>}
              <div className="mt-16 mb-32">
                <Typography variant="h6">{LoginMessage || "LOGIN TO YOUR ACCOUNT"}</Typography>
              </div>
              <DefaultButton
                onClick={() => login(InteractionType.Redirect)}
                loading={inProgress === InteractionStatus.Login || inProgress === InteractionStatus.SsoSilent}
                label="Login"
                size="large"
              />
              {/* InteractionRequired errors should be ignored */}
              {error && !(error instanceof InteractionRequiredAuthError) && (
                <Alert severity="error">Error logging in. {error.message}</Alert>
              )}
            </CardContent>
          </Card>
        </Grow>
      </div>
    </div>
  );
}

export default AzureLoginPage;
