import React from "react";
import Typography from "@material-ui/core/Typography";
import { calculateAge, toDOBFormat, toReadableAge } from "app/main/patients/helpers/format-dob";

export default function PatientAgeLabel({
  birthDate,
  variant,
  formatDOB,
  className,
  ...other
}) {
  if (!birthDate) return null;

  const age = calculateAge(birthDate);
  return (
    <Typography variant={variant || "body1"} className={className || "font-bold"} {...other}>
      {formatDOB ? toDOBFormat(birthDate) : toReadableAge(age)}
    </Typography>
  );
}
