import { green, grey, lightGreen, blue, red } from "@material-ui/core/colors";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import CheckboxBlankCircleIcon from "mdi-react/CheckboxBlankCircleIcon";
import CheckboxBlankCircleOutlineIcon from "mdi-react/CheckboxBlankCircleOutlineIcon";
import CheckboxMarkedCircleOutlineIcon from "mdi-react/CheckboxMarkedCircleOutlineIcon";
import CircleSlice3Icon from "mdi-react/CircleSlice3Icon";
import PenOffIcon from "mdi-react/PenOffIcon";

export default function getAssessmentStatus({ status, isGroup }) {
  switch (status) {
    case "NotStarted":
      return {
        color: grey[400],
        label: "Not Started",
        icon: CheckboxBlankCircleOutlineIcon,
      };
    case "InProgress":
      return {
        color: blue[600],
        label: "In Progress",
        icon: isGroup ? CircleSlice3Icon : CheckboxBlankCircleIcon,
      };
    case "Completed":
      return {
        color: lightGreen[600],
        label: "Completed",
        icon: isGroup ? CheckboxMarkedCircleOutlineIcon : CheckboxBlankCircleIcon,
      };
    case "NotRequired":
      return {
        color: red[600],
        label: "Not Required",
        icon: PenOffIcon,
      };
    case "Final":
      return {
        color: green[600],
        label: "Final",
        icon: CheckCircleIcon,
      };
    default:
      return {
        color: grey[600],
        icon: CheckboxBlankCircleOutlineIcon,
        label: "",
      };
  }
}
