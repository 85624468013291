import { combineReducers } from "redux";
import user from "./user.reducer";

export const AUTH_STATE_KEY = "auth";

const authReducers = combineReducers({
  user,
});

export default authReducers;
