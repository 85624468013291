import React from "react";
import ApplicationItem from "app/main/applications/components/application-item";

export default function renderContactInformation(person) {
  const icon = person.preferredContactMethod === "PostalAddress" ? "person_pin" : "email";
  const content = person.preferredContactMethod === "PostalAddress"
    ? person.address
    : person.email;

  return (
    <ApplicationItem icon={icon} content={content} className="pr-8" />
  );
}
