import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import PagingTable from "components/items/paging-table";
import formatDate from "helpers/format-date";
import { connect } from "react-redux";
import { fetchCandidateResults } from "app/main/candidates/actions/candidates.actions";
import { getAllCandidates, isPageLoading, getErrorMessage, getPageInfo, getCandidateSearchTerms, CANDIDATES_PAGE_SIZE_OPTIONS } from "app/main/candidates/reducers/candidates.reducers";

class CandidatesTable extends Component {
  constructor(props) {
    super(props);
    this.props.fetchCandidateResults(1, 50, null);
  }

  onChangeRowsPerPage = pageSize => {
    const { searchParams } = this.props;
    this.props.fetchCandidateResults(1, pageSize, searchParams);
  };

  onChangePage = pageNumber => {
    const { pageInfo, searchParams } = this.props;
    this.props.fetchCandidateResults(pageNumber + 1, pageInfo.pageSize, searchParams);
  };

  renderTableHeader = () => (
    <TableRow>
      <TableCell>memberNo</TableCell>
      <TableCell align="right">First Name</TableCell>
      <TableCell align="right">Surname</TableCell>
      <TableCell align="right">BirthDate</TableCell>
      <TableCell align="right">personPhNo</TableCell>
      <TableCell align="right">memberEmailAddress</TableCell>
    </TableRow>
  )

  renderTableRow = item => (
    <TableRow key={item.id}>
      <TableCell component="th" scope="row">{item.memberNo}</TableCell>
      <TableCell align="right">{item.firstname}</TableCell>
      <TableCell align="right">{item.surname}</TableCell>
      <TableCell align="right">{formatDate(item.birthDate)}</TableCell>
      <TableCell align="right">{item.personPhNo}</TableCell>
      <TableCell align="right">{item.memberEmailAddress}</TableCell>
    </TableRow>
  )

  render() {
    const { loading, error, candidates, pageInfo } = this.props;

    return (
      <PagingTable
        loading={loading}
        error={error}
        data={candidates}
        rowsPerPage={pageInfo.pageSize}
        pageSizeOptions={CANDIDATES_PAGE_SIZE_OPTIONS}
        page={pageInfo.pageNumber - 1}
        count={pageInfo.totalRecords}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        onChangePage={this.onChangePage}
        emptyMessage="No Candidates Recorded"
        tableHead={() => this.renderTableHeader()}
        tableRow={item => this.renderTableRow(item)}
      />
    );
  }
}

const mapStateToProps = state => ({
  candidates: getAllCandidates(state),
  loading: isPageLoading(state),
  error: getErrorMessage(state),
  pageInfo: getPageInfo(state),
  searchParams: getCandidateSearchTerms(state),
});

export default connect(
  mapStateToProps,
  {
    fetchCandidateResults,
  },
)(CandidatesTable);
