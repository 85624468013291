import React from "react";

import ImmunisationListContainer from "app/main/patients/immunisations/containers/immunisation-list-container";
import ImmunisationList from "app/main/patients/immunisations/components/immunisation-list";
import { HeaderAction } from "app/main/patients/components/patient-components";
import PatientRecordPageContent from "app/main/patients/components/patient-record-page-content";

export default function PatientImmunisationPage({ patientId }) {
  return (
    <ImmunisationListContainer
      patientId={patientId}
      renderContent={({ onRecordImmunisation, ...other }) => (
        <PatientRecordPageContent pageAction={onRecordImmunisation && <HeaderAction onClick={onRecordImmunisation} label="Add Immunisation" />}>
          <ImmunisationList {...other} />
        </PatientRecordPageContent>
      )}
    />
  );
}
