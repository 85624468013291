import React from "react";
import { Typography } from "@material-ui/core";
import { map, get } from "lodash";
import CircleSmallIcon from "mdi-react/CircleSmallIcon";
import { useSelector, useDispatch } from "react-redux";

import DefaultPanelLayout from "components/items/default-panel-layout";
import EmptyState from "components/items/empty-state";
import { PatientDetailsIcon } from "helpers/icon-finder";
import PatientAgeLabel from "app/main/patients/components/patient-details/details/age-label";
import ApplicationPanelContent from "app/main/applications/components/application-panel-content";
import PatientApplicationContainer from "app/main/applications/components/patient-application-container";
import { ApplicationItemWithWidth } from "app/main/applications/components/application-item";
import { isSectionCompleted } from "app/main/applications/reducers/applications.reducers";
import { openDialog } from "app/store/actions/dialog.actions";
import TreatmentRequestForm from "app/main/applications/components/treatment-request-form";

const PatientPanel = ({
  closeDialog,
  onSaveApplication,
  patient,
  canView,
  canEdit,
  canCreate,
  collectionLocation,
  status,
  patientSetting,
  orgUnitId,
  openTreatmentFormOnSuccess,
  ...other
}) => {
  const showIsRequired = useSelector(state => isSectionCompleted(state, "patient"));
  const dispatch = useDispatch();
  const onClose = () => closeDialog();

  const openTreatmentDialog = title => dispatch(openDialog({
    maxWidth: "md",
    children: (
      <TreatmentRequestForm
        onSucceed={() => {
          onClose();
        }}
        orgUnitId={orgUnitId}
        title={title}
        {...other}
      />
    ),
  }));

  return (
    <PatientApplicationContainer
      patient={patient}
      orgUnitId={orgUnitId}
      onSucceed={() => {
        onSaveApplication();
        onClose();

        if (openTreatmentFormOnSuccess) {
          openTreatmentDialog("Add Treatment Details");
        }
      }}
      renderContent={({
        patientLabel,
        onCreateApplicationPatient,
        onUpdateApplicationPatient,
      }) => (
        <DefaultPanelLayout
          title={patientLabel.single}
          icon={<PatientDetailsIcon />}
          isEmpty={!patient}
          emptyState={canEdit || (status === "New" && canCreate)
            ? <EmptyState icon={<PatientDetailsIcon />} subtitle={`Add ${patientLabel.single}`} onClick={() => onCreateApplicationPatient()} />
            : <EmptyState icon={<PatientDetailsIcon />} subtitle="Unable to add patient details to application" />}
          accessDenied={!canView}
          headerActions={(canEdit || (status === "New" && canCreate))
            && (!patient
              ? [
                {
                  label: `Add ${patientLabel.single} Details`,
                  icon: "add",
                  onClick: () => onCreateApplicationPatient(),
                },
              ] : [
                {
                  label: "Edit",
                  icon: "edit",
                  onClick: () => onUpdateApplicationPatient(patient),
                },
              ])}
          status={showIsRequired && <Typography color="error">*</Typography>}
        >
          {patient
          && (
            <>
              <Typography variant="h6" className="font-bold" color="primary">{patient.displayNameFull}</Typography>
              <ApplicationPanelContent>
                <div>
                  {map(patient.patientIdentifiers, x => (
                    <ApplicationItemWithWidth
                      key={x.identifier}
                      label={x.label}
                      content={x.identifier}
                    />
                  ))}
                  <ApplicationItemWithWidth
                    label="DOB"
                    content={patient.birthDate
                      ? (
                        <div className="flex-row-container">
                          <PatientAgeLabel formatDOB variant="body1" birthDate={patient.birthDate} className="font-normal" />
                          <CircleSmallIcon />
                          <PatientAgeLabel variant="body1" birthDate={patient.birthDate} className="font-normal" />
                        </div>
                      ) : "Not Recorded"}
                  />
                  <ApplicationItemWithWidth
                    label="Address"
                    content={patient.address}
                  />
                </div>
                <div>
                  <ApplicationItemWithWidth label="Pharmacy Location" content={get(collectionLocation, "name", null)} />
                  <ApplicationItemWithWidth label="Setting" content={patientSetting} />
                </div>
              </ApplicationPanelContent>
            </>
          )}
        </DefaultPanelLayout>
      )}
    />
  );
};

export default PatientPanel;
