import React from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 3),
    minHeight: 100,
    opacity: 0.6,
    backgroundColor: theme.palette.grey[100],
    borderStyle: "solid",
    borderColor: "transparent",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    "&.with-color": {
      opacity: 1,
      backgroundColor: fade(theme.palette.primary.light, 0.2),
    },
  },
  message: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  action: {
    alignItems: "flex-start",
  },
}));

const PatientSearchCard = ({ children, withColor, classes, ...other }) => {
  const styles = useStyles();

  return (
    <Alert
      icon={false}
      classes={{
        root: clsx(styles.root, withColor && "with-color", classes?.root),
        action: styles.action,
        message: styles.message,
      }}
      {...other}
    >
      {children}
    </Alert>
  );
};

export default PatientSearchCard;
