import { ApiActions } from "middleware/call-api";
import { isEmpty } from "lodash";
import { ActionTypes, isAssignmentOptionsLoading } from "../reducers/referrals.reducers";

export const resetCurrentReferral = () => ({
  type: ActionTypes.RESET_CURRENT_REFERRAL,
});

export const fetchReferral = referralId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referrals?referralId=${referralId}`,
    method: "GET",
    types: [
      ActionTypes.LOADING_REFERRAL,
      ActionTypes.LOADED_REFERRAL,
      ActionTypes.ERROR_LOADING_REFERRAL,
    ],
  },
});

export const getReferralReport = referralId => ({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referrals/GetReport?referralId=${referralId}`,
    method: "GET",
    types: [
      ActionTypes.LOADING_REFERRAL_REPORT,
      ActionTypes.LOADED_REFERRAL_REPORT,
      ActionTypes.ERROR_LOADING_REFERRAL_REPORT,
    ],
  },
});

export const printReferralReport = referralId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referrals/Print?referralId=${referralId}`,
    method: "GET",
    types: [
      "ActionTypes.LOADING_PRINT_REFERRAL",
      ActionTypes.LOADED_PRINT_REFERRAL,
      "ActionTypes.ERROR_LOADING_PRINT_REFERRAL",
    ],
  },
});

export const rejectReferralAction = rejectReferral => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: "/referrals/reject",
    body: { rejectReferral },
    entityType: "rejectReferral",
    types: [
      "REJECTING_REFERRAL",
      ActionTypes.REJECTED_REFERRAL,
      "FAILED_TO_REJECT_REFERRAL",
    ],
  },
});

export const addReferralCorrespondence = referralCorrespondence => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: "/referrals/addCorrespondence",
    body: { referralCorrespondence },
    entityType: "referralCorrespondence",
    types: [
      "ADDING_REFERRAL_CORRESPONDENCE",
      ActionTypes.ADDED_REFERRAL_CORRESPONDENCE,
      "FAILED_TO_ADD_REFERRAL_CORRESPONDENCE",
    ],
  },
});

export const completeReferralAction = completeReferral => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: "/referrals/complete",
    body: { completeReferral },
    entityType: "completeReferral",
    types: [
      "COMPLETING_REFERRAL",
      ActionTypes.COMPLETED_REFERRAL,
      "FAILED_TO_COMPLETE_REFERRAL",
    ],
  },
});

export const archiveReferralAction = archiveReferral => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: "/referrals/archive",
    body: { archiveReferral },
    entityType: "archiveReferral",
    types: [
      "ARCHIVING_REFERRAL",
      ActionTypes.ARCHIVED_REFERRAL,
      "FAILED_TO_ARCHIVE_REFERRAL",
    ],
  },
});

export const cancelReferralAction = cancelReferral => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: "/referrals/cancel",
    body: { cancelReferral },
    entityType: "cancelReferral",
    types: [
      "CANCELLINING_REFERRAL",
      ActionTypes.CANCELLED_REFERRAL,
      "FAILED_TO_CANCEL_REFERRAL",
    ],
  },
});

export const undoReferralAction = undoReferral => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: "/referrals/undo",
    body: { undoReferral },
    entityType: "undoReferral",
    types: [
      "UNDOING_REFERRAL_ACTION",
      ActionTypes.UNDONE_REFERRAL_ACTION,
      "FAILED_TO_UNDO_REFERRAL_ACTION",
    ],
  },
});

export const addReferralNote = (referralId, referralNote) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: "/referrals/addNote",
    body: { referralNote },
    entityType: "referralNote",
    types: [
      "ADDING_REFERRAL_NOTE",
      ActionTypes.ADDED_REFERRAL_NOTE,
      "FAILED_TO_ADD_REFERRAL_NOTE",
    ],
    referralId,
  },
});

export const editReferralNote = (referralId, referralNote) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: "/referrals/editNote",
    body: { referralNote },
    entityType: "REFERRAL_NOTE",
    referralId,
  },
});

export const removeReferralNote = (referralId, referralNoteId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/referrals/${referralId}/removeNote/${referralNoteId}`,
    entityType: "REFERRAL_NOTE",
  },
});

export const acceptReferralAction = acceptReferral => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: "/referrals/accept",
    body: { acceptReferral },
    entityType: "acceptReferral",
    types: [
      "ACCEPTING_REFERRAL",
      ActionTypes.ACCEPTED_REFERRAL,
      "FAILED_TO_ACCEPT_REFERRAL",
    ],
  },
});

export const saveReferralAssignedUser = assignReferral => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: "/referrals/assign",
    body: { assignReferral },
    types: ["SAVING_REFERRAL_ASSIGNED_USER", ActionTypes.SAVED_REFERRAL_ASSIGNED_USER, "ERROR_SAVING_REFERRAL_ASSIGNED_USER"],
    referralId: assignReferral.referralId,
    assignmentType: assignReferral.assignmentType,
  },
});

export const requestMoreInfoReferralAction = requestMoreInfo => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: "/referrals/requestMoreInformation",
    body: { requestMoreInformation: requestMoreInfo },
    entityType: "requestMoreInfo",
    types: [
      "SENDING_REQUEST_MORE_INFO",
      ActionTypes.REQUESTED_MORE_INFO,
      "FAILED_TO_REQUEST_MORE_INFO",
    ],
  },
});

export const getReferralAttachments = referralId => ({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referrals/GetAttachments?referralId=${referralId}`,
    method: "GET",
    entityType: "REFERRAL_ATTACHMENTS",
  },
});

export const getReferralAppointments = referralId => ({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referrals/GetAppointments?referralId=${referralId}`,
    method: "GET",
    entityType: "REFERRAL_APPOINTMENTS",
  },
});

export const fetchPreviewAttachment = (referralId, referralDocumentId, fileId) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referrals/PreviewAttachment?fileId=${fileId}&referralId=${referralId}`,
    method: "GET",
    types: [ActionTypes.LOADING_REFERRAL_ATTACHMENT_FILE, ActionTypes.LOADED_REFERRAL_ATTACHMENT_FILE, ActionTypes.ERROR_LOADING_REFERRAL_ATTACHMENT_FILE],
    fileId,
    referralId,
    referralDocumentId,
  },
});

export const fetchAssignmentOptions = (pageSize = 50, searchParams, isAssignment = false) => (dispatch, getState) => {
  let url = `/referrals/assignmentOptions?pageSize=${pageSize}&isAssignment=${isAssignment}`;

  if (!isEmpty(searchParams)) {
    url += `&name=${searchParams}`;
  }

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "ASSIGNMENT_OPTIONS",
      bailout: isAssignmentOptionsLoading(getState()),
    },
  });
};

export const fetchPDFPreview = referralId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referrals/printPreview?referralId=${referralId}`,
    method: "GET",
    entityType: "REFERRAL_PDF_PREVIEW",
    referralId,
  },
});

export const createManualReferral = createReferral => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: "/referrals/create",
    body: { createReferral },
    types: ["CREATING_MANUAL_REFERRAL", ActionTypes.CREATE_MANUAL_REFERRAL, "ERROR_CREATING_MANUAL_REFERRAL"],
    patientId: createReferral.patientId,
  },
});

export const editManualReferral = editReferral => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: "/referrals/edit",
    body: { editReferral },
    types: ["EDITING_MANUAL_REFERRAL", ActionTypes.EDIT_MANUAL_REFERRAL, "ERROR_EDITING_MANUAL_REFERRAL"],
  },
});

export const uploadReferralSourceDocumentFile = formData => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: "/referrals/uploadReferralSourceDocumentFile",
    headers: { "Content-Type": "multipart/form-data" },
    body: formData,
    entityType: "SOURCE_DOCUMENT_FILE",
  },
});

export const clearReferralSourceDocumentFiles = () => ({
  type: ActionTypes.CLEAR_SOURCE_DOCUMENT_FILES,
});

export const getReferralSourceDocumentFilePreviewData = (sourceDocumentId, fileId) => dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referrals/GetReferralSourceDocumentFileData?fileId=${fileId}`,
    method: "GET",
    entityType: "EXISTING_SOURCE_DOCUMENT_FILE",
    sourceDocumentId,
  },
});

export const getReferralAccessLog = referralId => dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referrals/GetReferralAccessLog?referralId=${referralId}`,
    method: "GET",
    types: [
      ActionTypes.LOADING_REFERRAL_ACCESS_LOG,
      ActionTypes.LOADED_REFERRAL_ACCESS_LOG,
      ActionTypes.ERROR_LOADING_REFERRAL_ACCESS_LOG,
    ],
  },
});

export const addReferralTask = (referralId, referralTask) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/referrals/${referralId}/addTask`,
    body: { referralTask },
    entityType: "REFERRAL_TASK",
    referralId,
  },
});

export const editReferralTask = (referralId, referralTask) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/referrals/${referralId}/editTask`,
    body: { referralTask },
    entityType: "REFERRAL_TASK",
    referralId,
  },
});

export const removeReferralTask = (referralId, referralTaskId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/referrals/${referralId}/removeTask/${referralTaskId}`,
    entityType: "REFERRAL_TASK",
    referralId,
    referralTaskId,
  },
});
