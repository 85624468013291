import React from "react";
import { startCase } from "lodash";
import { withTheme } from "@material-ui/core/styles";

import { AssignedUserIcon, AssignedSpecialtyIcon } from "helpers/icon-finder";
import ActivityLogItem from "components/items/activity-log/item";

const GetContentMessage = activity => {
  if (!activity.assignedToUser && !activity.assignedToRoleName && !activity.assignedToSpecialtyName) {
    return "Marked as Unassigned";
  }

  const assignedString = activity.assignedBy ? "Assigned to " : "Auto-Assigned to ";
  const roleString = activity.assignedToRoleName ? ` (${startCase(activity.assignedToRoleName)})` : "";
  return assignedString + activity.assignedToDisplayName + roleString;
};

const getIcon = (activity, iconColor) => {
  if (!activity.assignedToUser && !activity.assignedToRoleName && !activity.assignedToSpecialtyName) {
    return "remove";
  }
  return activity.assignedToSpecialtyName ? <AssignedSpecialtyIcon color={iconColor} /> : <AssignedUserIcon color={iconColor} />;
};

const AssignmentItem = ({ activity, theme: { palette: { primary } } }) => (
  <ActivityLogItem
    icon={getIcon(activity, primary[500])}
    title={GetContentMessage(activity)}
    date={activity.createdDateLocalised}
    user={activity.assignedBy}
  />
);

export default withTheme(AssignmentItem);
