import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Tabs, Tab } from "@material-ui/core";
import AccountMultipleOutlineIcon from "mdi-react/AccountMultipleOutlineIcon";
import { map, findIndex } from "lodash";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetCurrentPatient } from "app/main/patients/actions/patients.actions";
import { resetCurrentReferral } from "app/main/referrals/actions/referrals.actions";
import { ReferralWorklistIcon } from "helpers/icon-finder";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    minHeight: 32,
  },
  button: {
    flex: "1 1 auto",
    zIndex: 1,
    minHeight: 32,

    "&.Mui-selected": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  },
  indicator: {
    height: "100%",
    zIndex: 0,
    backgroundColor: theme.palette.primary.main,
  },
}));

const options = [
  { label: "Referral View", value: "/referralWorklist", icon: <ReferralWorklistIcon size={20} /> },
  { label: "Patient View", value: "/patientReferralWorklist", icon: <AccountMultipleOutlineIcon size={20} /> },
];

const PatientReferralViewSelector = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const { pathname } = history.location;
    const selectedIndex = findIndex(options, x => x.value === pathname);
    if (selectedIndex) {
      setSelected(selectedIndex);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (_event, val) => {
    const index = findIndex(options, x => x.value === val);
    setSelected(index);
    dispatch(resetCurrentPatient());
    dispatch(resetCurrentReferral());
    history.push({
      pathname: val,
      state: { forceLoad: true },
    });
  };

  return (
    <Tabs
      value={selected}
      onChange={handleChange}
      className={classes.root}
      classes={{ root: classes.root, indicator: classes.indicator }}
    >
      {map(options, (x, index) => (
        <Tooltip title={x.label} key={x.label}>
          <Tab
            value={x.value}
            selected={selected === index}
            classes={{ root: classes.button }}
            icon={x.icon}
            disableRipple
          />
        </Tooltip>
      ))}
    </Tabs>
  );
};

export default PatientReferralViewSelector;
