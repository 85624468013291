import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import TabContent from "components/items/tab-content";
import { currentReferralAppointmentsLoading, errorLoadingCurrentReferralAppointments } from "app/main/referrals/reducers/referrals.reducers";
import { getReferralAppointments } from "app/main/referrals/actions/referrals.actions";
import AppointmentList from "app/main/patients/appointments/components/appointment-list";

// TODO need to view the appointments and check its styles
export default ({
  referral,
}) => {
  const dispatch = useDispatch();
  const loadingCurrentAppointments = useSelector(currentReferralAppointmentsLoading);
  const errorLoadingCurrentAppointments = useSelector(errorLoadingCurrentReferralAppointments);

  React.useEffect(() => {
    dispatch(getReferralAppointments(referral.id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TabContent withGutters>
      <AppointmentList
        appointments={referral.appointments}
        stateProps={{
          isLoading: loadingCurrentAppointments,
          isEmpty: isEmpty(referral.appointments),
          error: errorLoadingCurrentAppointments,
        }}
      />
    </TabContent>
  );
};
