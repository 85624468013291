import React from "react";
import { map, isEmpty, find, filter } from "lodash";
import InfoCard from "app/main/applicationWorklist/components/info-card";

const RestrictionDisplay = props => {
  const { drugResultJson, strengthName } = props;

  const json = JSON.parse(drugResultJson);

  const getRestrictions = () => {
    const strength = find(json.strengths, x => x.name === strengthName);
    const hasStrength = !isEmpty(strength);
    return filter(json.restrictions, restriction => restriction.strengthId === (hasStrength ? strength.strengthId : null));
  };

  const restrictions = getRestrictions();

  if (isEmpty(restrictions)) return null;

  const getRestrictionSeverity = restrictionLevelType => {
    switch (restrictionLevelType) {
      case "Green": return "success";
      case "Amber": return "warning";
      case "Red": return "error";
      case "Stop": return "error";
      case "Grey": return "error";
      case "Black": return "error";
      default: return null;
    }
  };

  const getRestrictionIcon = restrictionLevelType => {
    switch (restrictionLevelType) {
      case "Green": return "check_circle";
      case "Amber": return "info";
      case "Red": return "error_outline";
      case "Stop": return "error_outline";
      case "Grey": return "cancel";
      case "Black": return "cancel";
      default: return "check_circle";
    }
  };

  const getRestrictionSeverityFromDescription = restrictionLevel => {
    switch (restrictionLevel) {
      case "Restricted": return "warning";
      case "Unrestricted": return "success";
      case "Non-Formulary": return "error";
      case "Highly Restricted": return "error";
      default: return "warning";
    }
  };

  return (
    <div className="items-container pl-32 pr-16 w-auto">
      {map(restrictions, (restriction, index) => (
        <InfoCard
          key={index}
          icon={getRestrictionIcon(restriction?.restrictionLevelType)}
          severity={getRestrictionSeverity(restriction?.restrictionLevelType)
          ?? getRestrictionSeverityFromDescription(restriction?.restrictionLevel ?? restriction?.restrictionLevelDescription ?? restriction.restrictionLevelType)}
          headline={(restriction?.appliesToTradeProductName ? `Restriction on brand product: ${restriction?.appliesToTradeProductName}` : "")}
          title={restriction?.restrictionLevelDescription ?? restriction?.restrictionLevelType}
        >
          {(restriction.stampedRestrictionHtml || restriction.customText)
            && (
              // eslint-disable-next-line react/no-danger
              <div key={restriction.stampedRestrictionHtml} dangerouslySetInnerHTML={{ __html: isEmpty(restriction.stampedRestrictionHtml) ? restriction.customText : restriction.stampedRestrictionHtml }} />
            )}
        </InfoCard>
      ))}
    </div>
  );
};

export default RestrictionDisplay;
