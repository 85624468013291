import React from "react";
import { Typography } from "@material-ui/core";
import { map, find } from "lodash";
import { useSelector } from "react-redux";

import DefaultButton from "components/items/default-button";
import EncounterTypeConsentStatus from "app/main/patients/encounters/components/encounter-type-consent-status";
import getEncounterTypeIcon from "app/main/patients/helpers/get-encounter-type-icon";
import { encounterSubjectDisplay } from "app/main/patients/helpers/encounter-subject-display";
import { ContextNoteItem, ContextItem } from "components/items/default-item";
import { AssignedUserIcon } from "helpers/icon-finder";
import normaliseDateTime from "app/main/patients/helpers/normalise-date-time";
import PatientContextListItem, { ItemContent } from "app/main/patients/components/patient-context-list-item";
import { DateItem } from "app/main/patients/components/patient-components";
import { getLocationTypeValues } from "app/auth/store/reducers/system-configuration";

import EncounterStatus from "./encounter-status";

const EncounterListItem = ({
  assignedBy,
  type,
  classes,
  item: {
    encounterType,
    startDateTimeUtc,
    reason,
    status,
    consented,
    location,
    details,
    welfareQuestionnaire,
  },
  onRecord,
  recordButtonTitle,
  onCreateAssessment,
  ...other
}) => {
  const encounterIcon = type === "appointment" ? "events" : getEncounterTypeIcon({ type: encounterType });
  const encounterDate = normaliseDateTime(startDateTimeUtc, type === "appointment");
  const title = encounterSubjectDisplay(encounterType, reason);
  const locations = useSelector(getLocationTypeValues);

  let onProcess = null;

  if ((encounterType === "InitialAssessment" || encounterType === "FollowUpAssessment")) {
    if (onCreateAssessment) {
      onProcess = () => onCreateAssessment();
    }
  } else if (onRecord) {
    onProcess = () => onRecord();
  }

  return (
    <PatientContextListItem
      title={title}
      {...other}
      renderItemContent={() => (
        <ItemContent
          title={title}
          icon={encounterIcon}
          status={<EncounterStatus status={status} type={encounterType} isOverdue={encounterDate.isOverdue} />}
          content={(
            <>
              <EncounterTypeConsentStatus status={status} consented={consented} />
              <DateItem date={encounterDate} name={assignedBy} />
            </>
          )}
        />
      )}
      renderPreviewContent={({ close }) => (
        <>
          <EncounterTypeConsentStatus status={status} consented={consented} />
          <EncounterStatus status={status} type={type} isOverdue={type === "encounter" && encounterDate.isOverdue} />
          <ContextItem icon="date_range" content={encounterDate.dateTime} />
          <ContextItem icon={<AssignedUserIcon />} content={assignedBy} />
          <ContextItem icon="location_on" content={find(locations, x => x.value === location)?.label ?? ""} />
          <ContextNoteItem content={details} />
          {welfareQuestionnaire && (
          <div className="items-container with-horizontal-divider">
            {map(welfareQuestionnaire, x => (
              <Typography variant="body2" key={x.questionType}>{x.question} <span className="font-bold">{x.answer === true ? "Yes" : "No"}</span></Typography>
            ))}
          </div>
          )}
          {onProcess && (
            <DefaultButton
              className="w-full"
              variant="outlined"
              onClick={() => { onProcess(); close(); }}
              label={recordButtonTitle}
            />
          )}
        </>
      )}
    />
  );
};

export default EncounterListItem;
