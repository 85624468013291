import { getPatientLabel } from "utils/get-environment-variables";

import PatientsIndexPage from "./pages/patient/patients-index-page";
import PatientNewPage from "./pages/patient/patient-new-page";
import PatientEditPage from "./pages/patient/patient-edit-page";
import PatientRecordPage from "./pages/patient-record-page";
import PatientSurveyPage from "./pages/patient/patient-survey-page";
import PatientReferralPage from "./pages/patient/patient-referral-page";

const patientLabel = getPatientLabel();

export const PatientsRouteConfig = {
  routes: [
    {
      path: "/patients",
      component: PatientsIndexPage,
      exact: true,
      title: patientLabel.plural,
      isIndexPage: true,
    },
    {
      path: "/admin/patients/new",
      component: PatientNewPage,
      exact: true,
      backUrl: "/admin",
      title: `Add ${patientLabel.single}`,
      search: "?tab=patients",
      state: { forceLoad: true },
    },
    {
      path: "/admin/patients/:id",
      component: PatientEditPage,
      exact: true,
      backUrl: "/admin",
      title: `Edit ${patientLabel.single}`,
      search: "?tab=patients",
    },
    {
      path: "/patients/:patientId",
      component: PatientRecordPage,
      exact: true,
    },
    {
      path: "/patients/:patientId/assessments/:assessmentId",
      component: PatientSurveyPage,
      exact: true,
    },
    {
      path: "/patients/:patientId/referrals/:referralId",
      component: PatientReferralPage,
      exact: true,
    },
  ],
};

export default PatientsRouteConfig;
