import React from "react";
import { Field } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Form from "components/form";
import { onSaveOrUpdateApprovalRecommendation } from "app/main/applications/actions/applications.actions";
import { TextAreaInput } from "components/inputs/text-input";

const commentLabel = "Comment";
const tooLong = "Too Long!";

const schema = Yup.object().shape({
  approvalRecommendation: Yup.object().shape({
    comment: Yup.string()
      .max(2000, tooLong)
      .nullable(),
  }),
});

const ApprovalRecommendationCommentForm = ({
  id,
  onSucceed,
  recommendationComment,
  approvalRecommendation: initialValues,
  orgUnitId,
  ...other
}) => {
  const dispatch = useDispatch();

  const handleSubmit = (data, { setErrors }) => {
    dispatch(onSaveOrUpdateApprovalRecommendation(id, data.approvalRecommendation, orgUnitId)).then(response => {
      if (response.error !== true) {
        onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  let { approvalRecommendation } = schema.cast();

  approvalRecommendation = {
    comment: initialValues.comment,
    id: initialValues.id,
  };

  return (
    <Form
      initialValues={{ approvalRecommendation }}
      onSubmit={handleSubmit}
      contentProps={other}
      validationSchema={schema}
      content={() => (
        <Field
          name="approvalRecommendation.comment"
          component={TextAreaInput}
          label={commentLabel}
          maxLength={2000}
          icon="comment"
          rows="10"
        />
      )}
    />
  );
};

export default ApprovalRecommendationCommentForm;