import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchOrgUnitsResults } from "app/main/orgUnits/actions/orgUnit.actions";
import { getOrgUnitPageInfo, getOrgUnitSearchParams } from "app/main/orgUnits/reducers/orgUnit.reducers";
import AdvancedFilter from "components/items/advanced-filter";

const OrgUnitSearch = props => {
  const dispatch = useDispatch();
  const searchParams = useSelector(getOrgUnitSearchParams);
  const pageInfo = useSelector(getOrgUnitPageInfo);
  const { orgUnitId } = props;
  const onSearchText = val => dispatch(fetchOrgUnitsResults(orgUnitId, pageInfo.pageNumber, pageInfo.pageSize, { ...searchParams, name: val, currentOrgUnitId: orgUnitId }));

  return (
    <AdvancedFilter
      onSearch={onSearchText}
      searchValue={searchParams?.name ?? ""}
      withPaperWrapper={false}
    />
  );
};

export default OrgUnitSearch;
