import React from "react";
import { Icon, withStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  urgentColor: {
    color: theme.palette.error.dark,
  },
});

function UrgentStatus(props) {
  const { classes, labelOnly, iconOnly, labelClassName, iconClassName } = props;

  return (
    <div className={classes.root}>
      {!labelOnly && <Icon className={clsx(classes.urgentColor, iconClassName)} fontSize="small">warning</Icon>}
      {!iconOnly && <Typography className={clsx(classes.urgentColor, labelClassName)} variant="caption">URGENT</Typography>}
    </div>
  );
}

export default withStyles(styles)(UrgentStatus);
