export const primary = {
  50: "#f1eaf4",
  100: "#dccbe4",
  200: "#c4a8d2",
  300: "#ac85c0",
  400: "#9b6ab3",
  500: "#8950a5",
  600: "#81499d",
  700: "#764093",
  800: "#6c378a",
  900: "#592779",
  A100: "#e4bcff",
  A200: "#cf89ff",
  A400: "#ba56ff",
  A700: "#b03cff",
  contrastText: "#fff",
};

export const secondary = {
  light: "#d0b7dc",
  main: "#a16fb9",
  dark: "#8552a2",
  contrastText: "#fff",
};

export const logo = {
  header: `${process.env.PUBLIC_URL}/assets/logo/wa/wa-logo.svg`,
  menu: `${process.env.PUBLIC_URL}/assets/logo/wa/wa-logo-color.png`,
  icon: `${process.env.PUBLIC_URL}/assets/logo/wa/logo.png`,
  favicon: `${process.env.PUBLIC_URL}/assets/logo/wa/favicon.ico`,
};
