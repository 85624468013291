import React from "react";
import clsx from "clsx";
import { Drawer, IconButton, Icon } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import SidebarTogglePortal from "portals/sidebarTogglePortal";
import { drawer, header } from "components/styles/base";
import { isMobileContentSidebarOpen } from "app/store/reducers/sidebars.reducer";
import { setMobileContentSidebarStatus } from "app/store/actions/sidebars.actions";

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: drawer.width,
    maxWidth: "100%",
    overflow: "hidden",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRight: "none",
    top: header.height,
    [theme.breakpoints.up("lg")]: {
      position: "relative",
      top: 0,
      marginRight: theme.spacing(1),
    },
  },
  header: {
    backgroundColor: theme.palette.background.control,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: `calc(100vh - ${header.height}px)`,
  },
}));

const Sidebar = ({
  menuVariant,
  renderStaticSideBar,
  renderSidebarHeader,
  renderSidebarContent,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mobileContentSidebarOpen = useSelector(state => isMobileContentSidebarOpen(state));
  const open = useSelector(state => state.sidebars?.open);

  if (renderStaticSideBar) {
    return renderStaticSideBar();
  }

  return (
    <>
      <SidebarTogglePortal>
        {!open && (
          <div className="flex justify-center ml-8">
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="end"
              onClick={() => dispatch(setMobileContentSidebarStatus(!mobileContentSidebarOpen))}
            >
              <Icon>subject</Icon>
            </IconButton>
          </div>
        )}
      </SidebarTogglePortal>
      <Drawer
        variant={menuVariant}
        anchor="left"
        open={menuVariant === "temporary" ? mobileContentSidebarOpen : true}
        onClose={() => dispatch(setMobileContentSidebarStatus(false))}
        classes={{ paper: classes.drawerPaper }}
        ModalProps={{
          keepMounted: true,
          disablePortal: true,
          BackdropProps: { classes: { root: "absolute" } },
        }}
      >
        <div className={classes.content}>
          {renderSidebarHeader && (
            <div className={clsx("p-16", classes.header)}>{renderSidebarHeader()}</div>
          )}
          <div className="overflow-y-auto flex-1">
            {renderSidebarContent()}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Sidebar;
