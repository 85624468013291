import { get, concat, find, orderBy, filter } from "lodash";
import curryConnector from "utils/connectors";

export const MEDICATIONS_STATE_KEY = "medications";

const curry = fn => curryConnector(fn, MEDICATIONS_STATE_KEY);

export const ActionTypes = {
  LOADING_MEDICATIONS: "LOADING_MEDICATIONS",
  LOADED_MEDICATIONS: "LOADED_MEDICATIONS",
  ERROR_LOADING_MEDICATIONS: "ERROR_LOADING_MEDICATIONS",

  LOADING_CURRENT_MEDICATION: "LOADING_CURRENT_MEDICATION",
  ERROR_LOADING_CURRENT_MEDICATION: "ERROR_LOADING_CURRENT_MEDICATION",
  LOADED_CURRENT_MEDICATION: "LOADED_CURRENT_MEDICATION",

  SAVED_MEDICATION: "SAVED_MEDICATION",
  CREATED_MEDICATION: "CREATED_MEDICATION",
  DELETED_MEDICATION: "DELETED_MEDICATION",
};

const INITIAL_STATE = {
  all: {},
  current: null,
};

const addOrUpdateMedication = (state, action) => {
  const { payload: { medication, patientId } } = action;
  let medications = get(state.all, [patientId, "medications"], []);
  medications = orderBy(concat(filter(medications, x => x.id !== medication.id), [medication]), [m => m.startDate || ""], ["desc"]);

  return {
    ...state,
    current: medication,
    all: { ...state.all, [patientId]: { ...state.all[patientId], medications } },
  };
};

const removeMedication = (state, action) => {
  const { payload: { medication, patientId } } = action;
  let medications = get(state.all, [patientId, "medications"], []);
  medications = orderBy(filter(medications, x => x.id !== medication.id), [m => m.startDate || ""], ["desc"]);

  return {
    ...state,
    all: { ...state.all, [patientId]: { ...state.all[patientId], medications } },
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_MEDICATIONS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: true } },
      };
    case ActionTypes.ERROR_LOADING_MEDICATIONS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: false, error: action.payload.message } },
      };
    case ActionTypes.LOADED_MEDICATIONS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]:
            {
              ...state.all[action.payload.patientId],
              loading: false,
              medications: orderBy(action.payload.medications, "startDate", "desc"),
            },
        },
      };
    case ActionTypes.LOADING_CURRENT_MEDICATION:
      return {
        ...state,
        current: {
          ...state.current,
          loading: true,
        },
      };
    case ActionTypes.ERROR_LOADING_CURRENT_MEDICATION:
      return {
        ...state,
        current: {
          ...state.current,
          error: action.error,
        },
      };
    case ActionTypes.LOADED_CURRENT_MEDICATION:
      return {
        ...state,
        current: action.payload.medication,
      };
    case ActionTypes.SAVED_MEDICATION:
    case ActionTypes.CREATED_MEDICATION:
      return addOrUpdateMedication(state, action);
    case ActionTypes.DELETED_MEDICATION:
      return removeMedication(state, action);
    case ActionTypes.SET_CURRENT_MEDICATION:
      return {
        ...state,
        current: action.payload.medication,
      };
    default:
      return state;
  }
};

export const areMedicationsLoading = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loading"], false));

export const areMedicationsLoaded = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loaded"], false));

export const medicationsLoadingError = curry(({ localState }, patientId) => get(localState, ["all", patientId, "error"], null));

export const getMedicationsByPatientId = curry(({ localState }, patientId) => get(localState, ["all", patientId, "medications"], []));

export const getMedicationById = curry(({ localState }, patientId, medicationId) => {
  const medications = get(localState, ["all", patientId, "medications"], []);
  return find(medications, x => x.id === medicationId) || {};
});

export const getCurrentMedication = curry(({ localState }) => get(localState, ["current"], null));

export const isCurrentMedicationLoading = curry(({ localState }) => get(localState, ["current", "loading"], false));

export const getErrorMessage = curry(({ localState }, patientId) => get(localState, ["all", patientId, "error"], null));
