import { blue, orange, green, red } from "@material-ui/core/colors";
import { startCase } from "lodash";

export default function getReportBackStatus(status) {
  switch (status) {
    case "NotReceived":
      return {
        icon: "clear",
        color: red[600],
        label: "NOT RECEIVED",
      };
    case "Received":
      return {
        icon: "check",
        color: green[600],
        label: "RECEIVED",
      };
    case "Overdue":
      return {
        icon: "access_time",
        color: orange[600],
        label: "OVERDUE",
      };
    default:
      return {
        icon: "lens",
        color: blue[600],
        label: startCase(status),
      };
  }
}
