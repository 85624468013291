import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, FieldArray } from "formik";
import { map, includes, concat, size, find, isEmpty, isEqual, trim, filter } from "lodash";
import { Divider } from "@material-ui/core";

import AdvancedFilter, { FilterForm, FilterSection } from "components/items/advanced-filter";
import { TextInput, CheckboxInput, Checkbox, SelectInput } from "components/inputs";
import DateRange from "components/inputs/date-range";
import { AscendingIcon, DescendingIcon } from "helpers/icon-finder";
import formatFilterDate from "helpers/format-filter-date";

import { fetchPatientMergeLogResults, resetPatientMergeLogsFilters } from "../actions/patientMerge.actions";
import { getPageInfo, getSearchParams, DefaultSearchParams } from "../reducers/patientMerge.reducers";

const statusOptions = [
  { value: "New", label: "New" },
  { value: "Failed", label: "Failed" },
  { value: "InProgress", label: "In Progress" },
  { value: "Completed", label: "Completed" },
  { value: "LoggedOnly", label: "Logged Only" },
];

const sortOptions = [
  { label: "Created Date - Oldest First", value: "CreatedDateAsc", icon: <AscendingIcon /> },
  { label: "Created Date - Newest First", value: "CreatedDateDesc", icon: <DescendingIcon /> },
];

export default () => {
  const dispatch = useDispatch();
  const pageInfo = useSelector(getPageInfo);
  const params = useSelector(getSearchParams);
  const { pageSize } = pageInfo;

  const onSearch = searchText => {
    dispatch(fetchPatientMergeLogResults(1, pageSize, { ...params, name: trim(searchText) }));
  };

  const searchPatientMergeLogs = searchParams => dispatch(fetchPatientMergeLogResults(1, pageSize, searchParams));

  const handleSubmit = ({ searchParams }) => {
    const { status, createdStartDate, createdEndDate } = searchParams;

    const newSearchParams = {
      ...searchParams,
      status: searchParams.allStatus ? [] : map(status, x => x.value),
      createdStartDate: formatFilterDate(createdStartDate),
      createdEndDate: formatFilterDate(createdEndDate),
    };

    searchPatientMergeLogs(newSearchParams);
  };

  const handleReset = () => {
    dispatch(resetPatientMergeLogsFilters()).then(searchPatientMergeLogs(DefaultSearchParams));
  };

  let searchParams = params;
  let { status } = searchParams;
  let allStatus = true;

  if (!isEmpty(status) && size(status) !== size(statusOptions)) {
    allStatus = false;
    status = map(searchParams.status, x => find(statusOptions, option => option.value === x));
  } else {
    status = statusOptions;
  }

  searchParams = {
    ...searchParams,
    allStatus,
    status,
    createdDateRange: [searchParams.createdStartDate, searchParams.createdEndDate],
  };

  return (
    <AdvancedFilter
      onSearch={onSearch}
      searchValue={params?.name}
      filterContentAnchor="right"
      withPaperWrapper={false}
      hasFilterValue={!isEqual(DefaultSearchParams, params)}
      content={onClose => (
        <FilterForm
          initialValues={{ searchParams }}
          onSubmit={handleSubmit}
          onReset={handleReset}
          onClose={onClose}
          filters={({ setFieldValue, values }) => {
            const sortByIcon = find(sortOptions, x => x.value === values.searchParams.sortBy)?.icon;
            return (
              <div>
                <FilterSection withDivider>
                  <Field
                    label="Name"
                    name="searchParams.name"
                    component={TextInput}
                    icon="search"
                  />
                </FilterSection>
                <FilterSection withDivider label="Sort by">
                  <Field
                    name="searchParams.sortBy"
                    component={SelectInput}
                    options={sortOptions}
                    icon={sortByIcon}
                  />
                </FilterSection>
                <FilterSection withDivider label="Created Date">
                  <Field
                    name="searchParams.createdDateRange"
                    component={DateRange}
                    onChange={value => {
                      if (value) {
                        setFieldValue("searchParams.createdStartDate", value[0]);
                        setFieldValue("searchParams.createdEndDate", value[1]);
                      } else {
                        setFieldValue("searchParams.createdStartDate", null);
                        setFieldValue("searchParams.createdEndDate", null);
                      }
                    }}
                  />
                </FilterSection>
                <FilterSection label="Status" withDivider>
                  <Field
                    name="searchParams.allStatus"
                    label="All"
                    component={CheckboxInput}
                    showIcon={false}
                    onChange={value => {
                      if (value === true) {
                        setFieldValue("searchParams.status", map(statusOptions, x => x));
                      } else {
                        setFieldValue("searchParams.status", []);
                      }
                    }}
                  />
                  <Divider />
                  <FieldArray
                    name="searchParams.status"
                    render={() => (
                      <div className="checkboxGroups">
                        {map(statusOptions, s => (
                          <Checkbox
                            name="searchParams.status"
                            label={s.label}
                            key={s.value}
                            checked={includes(values.searchParams.status, s)}
                            showIcon={false}
                            onChange={e => {
                              const updatedStatuses = e.target.checked ? concat(values.searchParams.status, s) : filter(values.searchParams.status, x => x !== s);
                              setFieldValue("searchParams.status", updatedStatuses);
                              setFieldValue("searchParams.allStatus", size(updatedStatuses) === size(statusOptions));
                            }}
                          />
                        ))}
                      </div>
                    )}
                  />
                </FilterSection>
                <FilterSection>
                  <Field
                    name="searchParams.showManualOnly"
                    label="Show manual only"
                    component={CheckboxInput}
                    showIcon={false}
                  />
                </FilterSection>
              </div>
            );
          }}
        />
      )}
    />
  );
};
