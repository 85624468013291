import React from "react";
import { TableCell, TableRow, Chip } from "@material-ui/core";

export const OrgUnitHeader = () => (
  <TableRow>
    <TableCell>Name</TableCell>
  </TableRow>
);

export const OrgUnitListItem = prop => {
  const { orgUnit, parentOrgUnitId, parentOrgUnitType, history, signedInOrgUnit } = prop;

  const goToOrgUnitOverviewPage = () => {
    const includeFromUrl = (parentOrgUnitType !== "Org" && (orgUnit.orgUnitType !== signedInOrgUnit?.orgUnitType));

    history.push({
      pathname: `/admin/org-units/${orgUnit.orgUnitType}/${orgUnit.id}`,
      fromUrl: includeFromUrl ? `/admin/org-units/${parentOrgUnitType}/${parentOrgUnitId}?tab=orgUnits` : null,
    });
  };

  return (
    <TableRow
      hover
      key={orgUnit.id}
      onClick={() => (prop.onClick ? prop.onClick() : goToOrgUnitOverviewPage())}
    >
      <TableCell>
        {orgUnit.name}
        {orgUnit.isDeprecated && (
        <Chip variant="outlined" size="small" label="Deprecated" className="mx-8" />
        )}
      </TableCell>
    </TableRow>
  );
};
