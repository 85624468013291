import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import DefaultPageLayout from "components/layouts/default-page-layout";
import ScreenHeader from "components/items/screen-header";
import withPermissions from "permissions/withPermissions";
import DefaultButton from "components/items/default-button";
import useSetUrl from "app/main/admin/hooks/useSetUrl";
import OrgUnitPrimaryHeader from "app/main/orgUnits/components/orgUnit-primary-header";
import SpecialtyProfileForm from "../components/specialtyProfile-form";
import { getCurrentSpecialtyProfile, isCurrentSpecialtyProfileLoading } from "../reducers/specialtyProfiles.reducers";
import { getSpecialtyProfile, resetCurrentSpecialtyProfile } from "../actions/specialtyProfiles.actions";

const SpecialtyProfileEditPage = ({
  match: { params: { id, specialtyProfileId } },
  hasPermissionSpecialtyProfileUpdate,
  route,
}) => {
  const dispatch = useDispatch();
  const current = useSelector(getCurrentSpecialtyProfile);
  const loading = useSelector(isCurrentSpecialtyProfileLoading);
  const [disabled, setDisabled] = useState(true);

  const { goBackWithState } = useSetUrl(route);

  useEffect(() => {
    if (!loading && isEmpty(current)) {
      dispatch(getSpecialtyProfile(id, specialtyProfileId));
    }
    return () => dispatch(resetCurrentSpecialtyProfile());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <OrgUnitPrimaryHeader orgUnitId={id} />
      <DefaultPageLayout
        loading={loading}
        accessDenied={!hasPermissionSpecialtyProfileUpdate}
        header={(
          <ScreenHeader
            title="Specialty Profile"
            renderActions={(
              <>
                {hasPermissionSpecialtyProfileUpdate && (
                <DefaultButton label="Edit" variant="outlined" color="inherit" icon="edit" onClick={() => setDisabled(false)} disabled={!disabled} />)}
              </>
            )}
          />
        )}
        content={(
          <SpecialtyProfileForm
            specialtyProfile={current}
            isEdit
            disabled={disabled}
            onCancel={() => setDisabled(true)}
            onSucceed={goBackWithState}
          />
        )}
      />
    </>
  );
};

export default withPermissions("SpecialtyProfileUpdate")(SpecialtyProfileEditPage);
