import React from "react";

import ConditionListContainer from "app/main/patients/conditions/containers/condition-list-container";
import ConditionList from "app/main/patients/conditions/components/condition-list";
import { HeaderAction } from "app/main/patients/components/patient-components";
import PatientRecordPageContent from "app/main/patients/components/patient-record-page-content";

export default function PatientConditionPage({ patientId }) {
  return (
    <ConditionListContainer
      patientId={patientId}
      renderContent={({ onRecordCondition, ...other }) => (
        <PatientRecordPageContent pageAction={onRecordCondition && <HeaderAction onClick={onRecordCondition} label="Add Medical Condition" />}>
          <ConditionList {...other} />
        </PatientRecordPageContent>
      )}
    />
  );
}
