import React from "react";
import { Typography} from "@material-ui/core";
import { MedicationIcon, ChartIcon } from "helpers/icon-finder";
import normaliseDateTime from "app/main/patients/helpers/normalise-date-time";
import { IsCurrentStatus } from "app/main/patients/components/patient-components";
import PatientContextListItem, { ItemContent } from "app/main/patients/components/patient-context-list-item";
import { ContextNoteItem, ContextItem } from "components/items/default-item";

const MedicationListItem = ({
  item: {
    term,
    form,
    note,
    isCurrent,
    strength,
    startDate,
    endDate,
  },
  ...other
}) => {
  const status = isCurrent === true ? "Current" : "Not Current";
  const medicationStartDate = normaliseDateTime(startDate);
  const medicationEndDate = normaliseDateTime(endDate);

  return (
    <PatientContextListItem
      title={term}
      {...other}
      renderItemContent={() => (
        <ItemContent
          title={term}
          icon={<MedicationIcon />}
          status={<IsCurrentStatus status={status} />}
          content={(
            <div className="flex flex-col">
              <Typography variant="caption">{form} {strength}</Typography>
              <Typography variant="caption">{`${medicationStartDate.date} ${!isCurrent ? `to ${medicationEndDate.date}` : ""}`}</Typography>
            </div>
          )}
        />
      )}
      renderPreviewContent={() => (
        <>
          <IsCurrentStatus status={status} />
          <ContextItem icon={<MedicationIcon />} content={form} />
          <ContextItem icon={<ChartIcon />} content={strength} />
          <ContextNoteItem content={note} />
        </>
      )}
    />
  );
};

export default MedicationListItem;
