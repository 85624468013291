import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { map, find, isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import Pagination from "@material-ui/lab/Pagination";

import { fetchPatientReferrals, resetPatientActiveReferralSearch } from "app/main/referrals/actions/patientReferrals.actions";
import { getReferralsForPatient, getPatientReferralPageInfo, REFERRALS_PAGE_SIZE, isPageLoading, getPatientReferralSearchParams } from "app/main/referrals/reducers/patientReferrals.reducers";
import { fetchReferral } from "app/main/referrals/actions/referrals.actions";
import normaliseDateTime from "app/main/patients/helpers/normalise-date-time";
import { ListItem } from "app/main/patients/components/patient-context-list-item";
import ItemStatus from "components/items/item-status";
import ReferralCategoryLabel from "app/main/referrals/components/referral-category-label";
import StateContainer from "components/layouts/default-state-container";
import PatientReferralListAdvancedFilter from "app/main/patients/referrals/components/patient-referral-list-advanced-filter";
import IconButton from "components/items/icon-button";
import DefaultItem from "components/items/default-item";
import useReferralSearch from "app/main/referralWorklist/hooks/useReferralSearch";
import { getReferralWorklistSettings } from "app/auth/store/reducers/system-configuration";

export default function PatientReferralsPage({ patientId, fromUrl, classes, showLoadReferrals = true }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const patientReferrals = useSelector(state => getReferralsForPatient(state, patientId));
  const isLoading = useSelector(state => isPageLoading(state, patientId));
  const pageInfo = useSelector(state => getPatientReferralPageInfo(state, patientId));
  const params = useSelector(getPatientReferralSearchParams);
  const referralWorklistSettings = useSelector(getReferralWorklistSettings);
  const defaultSorOrder = referralWorklistSettings?.worklistSortOrder;

  const { searchParams, onSearch, referralWorkflowSettings, ...otherSearchProps } = useReferralSearch({ params, searchAPI: fetchPatientReferrals });

  const onSearchPatientReferrals = (newParams, page, forceLoad) => dispatch(fetchPatientReferrals({ ...newParams, patientId }, page, forceLoad));

  useEffect(() => {
    if (showLoadReferrals) {
      onSearchPatientReferrals({ ...searchParams, showOldestFirst: defaultSorOrder === "asc" }, 1);
    }
    return () => dispatch(resetPatientActiveReferralSearch());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoadReferrals]);

  const handleChangePage = (event, page) => {
    if (page !== pageInfo.pageNumber) {
      onSearchPatientReferrals(searchParams, page, true);
    }
  };

  return (
    <div className={clsx("flex flex-col h-full justify-between", classes?.root)}>
      <div className={clsx(classes?.filter)}>
        <PatientReferralListAdvancedFilter
          total={pageInfo?.totalRecords}
          pageNumber={pageInfo?.pageNumber}
          pageSize={REFERRALS_PAGE_SIZE}
          onSearch={onSearch}
          onPageChange={handleChangePage}
          searchParams={searchParams}
          patientId={patientId}
          referralWorkflowSettings={referralWorkflowSettings}
          {...otherSearchProps}
        />
      </div>
      <div className={clsx("py-16 flex-auto", classes?.content)}>
        <StateContainer loading={isLoading} empty={isEmpty(patientReferrals)} emptyTitle="No Recorded Referrals">
          <div className="flex-auto">
            {map(patientReferrals, (referral, index) => {
              const { id, referralStatus, referralDateUtc, triageCategory, isUrgent, referredToServiceDescription, referrerFullName } = referral;
              const referralDate = normaliseDateTime(referralDateUtc);
              const status = find(referralWorkflowSettings, x => x.workflowState === referralStatus);

              return (
                <ListItem
                  key={id}
                  isFirst={index === 0}
                  onClick={() => {
                    dispatch(fetchReferral(id))
                      .then(() => history.push({
                        pathname: `/patients/${patientId}/referrals/${id}`,
                        fromUrl: fromUrl ?? `${history.location.pathname}?tab=referrals`,
                      }));
                  }}
                >
                  <div className="flex justify-between flex-auto">
                    <div className="flex-row-container flex-auto">
                      <div className="flex flex-col sm:w-320 w-full">
                        <DefaultItem label="For" content={<Typography variant="body2">{referredToServiceDescription}</Typography>} />
                        <DefaultItem label="To" content={<Typography variant="body2">{referrerFullName}</Typography>} />
                      </div>
                      <div className="flex-auto flex sm:justify-center">
                        <Typography variant="caption">{`${referralDate.date} ${referralDate.time}`}</Typography>
                      </div>
                      <div className="flex-auto flex flex-col items-end w-192">
                        <ItemStatus label={status?.label ?? referralStatus} color={status?.color} icon={status?.icon} />
                        <ReferralCategoryLabel triageCategory={triageCategory} isUrgent={isUrgent} />
                      </div>
                    </div>
                    <IconButton title="Go to Referral" icon="arrow_forward" />
                  </div>
                </ListItem>
              );
            })}
          </div>
        </StateContainer>
      </div>
      <div className={clsx("flex items-center justify-end", classes?.paging)}>
        <Typography variant="caption" color="textSecondary">Total results: {pageInfo?.totalRecords}</Typography>
        <Pagination
          count={Math.ceil(pageInfo?.totalRecords / pageInfo?.pageSize) ?? 1}
          page={pageInfo?.pageNumber ?? 1}
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
}
