import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { getPatientLabel } from "utils/get-environment-variables";
import PatientApplicationForm from "app/main/applications/components/patient-application-form";
import { showExternalPatientSearch } from "app/auth/store/reducers/system-configuration";
import PatientSearchForm from "app/main/patients/components/external-patient-search-button/patient-search-form";
import ExternalPatientApplicationForm from "app/main/applications/components/external-patient-application-form";

const patientLabel = getPatientLabel();

const PatientApplicationContainer = ({
  renderContent,
  ...other
}) => {
  const dispatch = useDispatch();
  const showExternalSearch = useSelector(showExternalPatientSearch);

  const openPatientForm = title => dispatch(openDialog({
    maxWidth: "sm",
    children: (
      <PatientApplicationForm
        title={title}
        {...other}
      />
    ),
  }));

  const openExternalPatientApplicationForm = (title, patient) => dispatch(openDialog({
    maxWidth: "sm",
    children: (
      <ExternalPatientApplicationForm
        title={title}
        externalPatient={patient}
        {...other}
      />
    ),
  }));

  const openPatientSearchFrom = () => {
    dispatch(openDialog({
      maxWidth: "md",
      children: (
        <PatientSearchForm
          title={`Search ${patientLabel.plural}`}
          onClose={() => dispatch(closeDialog())}
          onContinue={patient => openExternalPatientApplicationForm(`Create ${patientLabel.single}`, patient)}
        />
      ),
    }));
  };

  const onCreate = () => (showExternalSearch ? openPatientSearchFrom() : openPatientForm(`New Application - ${patientLabel.single} Details`));

  const onUpdate = patient => {
    const editTitle = `Edit ${patientLabel.single}`;
    if (showExternalSearch) {
      return openExternalPatientApplicationForm(editTitle, patient);
    }
    return openPatientForm(editTitle, patient);
  };

  return (
    renderContent({
      patientLabel,
      onCreateApplicationPatient: onCreate,
      onUpdateApplicationPatient: onUpdate,
      onSearchExternalPatient: openPatientSearchFrom,
    })
  );
};

export default PatientApplicationContainer;
