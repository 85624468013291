import React from "react";
import { connect } from "react-redux";
import clsx from "clsx";

import { map, isEmpty, lowerCase } from "lodash";
import { Typography } from "@material-ui/core";

import DefaultPanelLayout from "components/items/default-panel-layout";
import EmptyState, { NoneRecordedState } from "components/items/empty-state";
import AppealForm from "app/main/applications/components/application-appeal-form";
import formatDate from "helpers/format-date";
import { onSaveOrUpdateApplicationAppeal, onRemoveApplicationAppeal } from "app/main/applications/actions/applications.actions";
import { NoteIcon, StatusIcon } from "helpers/icon-finder";
import { showMessage } from "app/store/actions/message.actions";
import { ApplicationItemWithWidth, ApplicationItemTextArea } from "app/main/applications/components/application-item";

import ApplicationRemoveButton from "./application-remove-button";
import ApplicationEditButton from "./application-edit-button";

const sectionTitle = plural => `Application Appeal${plural ? "s" : ""}`;

class ApplicationAppealPanel extends React.Component {
  onSucceed = () => {
    this.props.closeDialog();
  }

  handleSubmit = ({ appeal }, { setErrors }) => {
    const { id: applicationId, orgUnitId } = this.props;
    const appealData = {
      ...appeal,
      appealedByUserId: appeal.appealedBy.value,
    };

    this.props.onSaveOrUpdateApplicationAppeal(applicationId, orgUnitId, appealData).then(response => {
      if (response.error !== true) {
        this.onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  }

  onRemove = appealId => {
    const { id: applicationId, orgUnitId } = this.props;
    const title = lowerCase(sectionTitle());
    this.props.onRemoveApplicationAppeal(applicationId, appealId, orgUnitId).then(response => {
      if (response.error === true) {
        this.props.showMessage({ message: response.payload.exceptionMessage || `Error occurred while deleting the ${title}`, variant: "error" });
      } else {
        this.props.showMessage({ message: `Successfully removed the ${title}`, variant: "success" });
      }
    });
  };

  openAppeal = applicationAppeal => {
    const isNew = !applicationAppeal;
    this.props.openDialog({
      children: (
        <AppealForm
          onSucceed={this.onSucceed}
          handleSubmit={this.handleSubmit}
          applicationAppeal={applicationAppeal}
          title={`${isNew ? "New" : "Edit"} ${sectionTitle()}`}
          {...this.props}
        />
      ),
    });
  }

  render() {
    const {
      appeals,
      canView,
      canEdit,
    } = this.props;

    let emptyMessage = "Add Appeal";
    if (!canEdit) {
      emptyMessage = "Unable to add appeal to application";
    }

    return (
      <DefaultPanelLayout
        title="Appeals"
        icon="gavel"
        isEmpty={isEmpty(appeals)}
        accessDenied={!canView}
        emptyState={canEdit
          ? <EmptyState icon="gavel" subtitle={emptyMessage} onClick={canEdit ? () => this.openAppeal() : null} />
          : <NoneRecordedState />}
        headerActions={canEdit
            && [
              {
                label: "Add Appeal",
                icon: "add",
                onClick: () => this.openAppeal(),
              },
            ]}
      >
        {map(appeals, (x, index) => (
          <div key={x.id} className={clsx(index !== 0 && "mt-16 with-horizontal-divider")}>
            {canEdit && (
              <div className="flex items-center justify-between">
                <ApplicationItemWithWidth icon={<StatusIcon />} className="font-bold" label="Status" content={<Typography className="font-bold">{x.status}</Typography>} />
                <div className="flex">
                  <ApplicationRemoveButton onClick={() => this.onRemove(x.id)} />
                  <ApplicationEditButton onClick={() => this.openAppeal(x)} />
                </div>
              </div>
            )}
            <div className="items-container">
              <ApplicationItemWithWidth icon="date_range" label="Date" content={formatDate(x.appealDate)} />
              <ApplicationItemWithWidth icon="person" label="Appealed By" content={x.appealedBy.label} />
              <ApplicationItemTextArea icon="list_alt" label="Outcome" content={x.outcome} />
              <ApplicationItemTextArea icon={<NoteIcon />} label="Notes" content={x.notes} />
            </div>
          </div>
        ))}
      </DefaultPanelLayout>
    );
  }
}

export default connect((state, props) => {
  const { applicationAppeals } = props;

  const appeals = map(applicationAppeals, x => ({
    ...x,
    appealedBy: {
      value: x.appealedByUserId,
      label: x.appealedBy,
    },
  }));

  return {
    appeals,
  };
},
{
  onSaveOrUpdateApplicationAppeal,
  onRemoveApplicationAppeal,
  showMessage,
})(ApplicationAppealPanel);
