import React from "react";
import { Button, Typography, CircularProgress, Dialog, DialogActions, DialogContent, Fade } from "@material-ui/core";
import IconButton from "components/items/icon-button";

const PrintDialog = ({
  title = "Confirm and Print",
  loadingMessage = "Generating Print...",
  content,
  onClose,
  onPrint,
  onCancel,
  loading,
  printLabel = "Print",
  open,
}) => {
  let dialogContent = () => content;

  if (loading) {
    dialogContent = () => (
      <>
        <Typography align="center">{loadingMessage}</Typography>
        <div className="flex items-center flex-col w-320 py-16">
          <CircularProgress />
        </div>
      </>
    );
  }

  return (
    <Dialog open={open} maxWidth="xs">
      <div className="flex justify-between items-center px-16 py-8">
        <Typography variant="h6">{title}</Typography>
        <IconButton edge="end" onClick={onClose} icon="clear" title="Close" />
      </div>
      <DialogContent className="p-24">
        {dialogContent()}
      </DialogContent>
      {!loading && (
      <Fade in={!loading}>
        <DialogActions>
          <Button onClick={onPrint} color="secondary" className="flex-1" variant="contained">
            {printLabel}
          </Button>
          <Button onClick={onCancel} className="flex-1">
            Cancel
          </Button>
        </DialogActions>
      </Fade>
      )}
    </Dialog>
  );
};

export default PrintDialog;
