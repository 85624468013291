import { get, mapKeys, map, find } from "lodash";
import curryConnector from "utils/connectors";

export const PATIENT_MERGE_STATE_KEY = "patientMerge";
export const PATIENT_MERGE_LOGS_PAGE_SIZE = 25;

const curry = fn => curryConnector(fn, PATIENT_MERGE_STATE_KEY);

export const ActionTypes = {
  LOADING_PATIENT_MERGE_LOGS: "LOADING_PATIENT_MERGE_LOGS",
  LOADED_PATIENT_MERGE_LOGS: "LOADED_PATIENT_MERGE_LOGS",
  ERROR_LOADING_PATIENT_MERGE_LOGS: "ERROR_LOADING_PATIENT_MERGE_LOGS",

  CREATING_PATIENT_MERGE: "CREATING_PATIENT_MERGE",
  CREATED_PATIENT_MERGE: "CREATED_PATIENT_MERGE",
  ERROR_CREATING_PATIENT_MERGE: "ERROR_CREATING_PATIENT_MERGE",

  LOADING_CURRENT_PATIENT_MERGE_LOG: "LOADING_CURRENT_PATIENT_MERGE_LOG",
  LOADED_CURRENT_PATIENT_MERGE_LOG: "LOADED_CURRENT_PATIENT_MERGE_LOG",
  ERROR_LOADING_CURRENT_PATIENT_MERGE_LOG: "ERROR_LOADING_CURRENT_PATIENT_MERGE_LOG",
  RESET_CURRENT_PATIENT_MERGE_LOG: "RESET_CURRENT_PATIENT_MERGE_LOG",
  RESET_PATIENT_MERGE_LOGS_FILTERS: "RESET_PATIENT_MERGE_LOGS_FILTERS",
};

export const DefaultSearchParams = {
  name: null,
  showManualOnly: false,
  status: null,
  createdStartDate: null,
  createdEndDate: null,
  sortBy: "CreatedDateDesc",
};

const INITIAL_STATE = {
  all: null,
  pages: { loading: false, loaded: false, error: null, ids: null },
  searchParams: DefaultSearchParams,
  pageInfo: { pageNumber: 1, pageSize: PATIENT_MERGE_LOGS_PAGE_SIZE, totalRecords: 0 },
  current: { loading: false, loaded: false, error: null, log: {} },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_PATIENT_MERGE_LOGS:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: true, loaded: false, error: false } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.LOADED_PATIENT_MERGE_LOGS:
      return {
        ...state,
        all: mapKeys(action.payload.logs, x => x.id),
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: false, error: false, loaded: true, ids: map(action.payload.logs, x => x.id) } },
        pageInfo: action.payload.pageInfo,
        searchParams: action.payload.searchParams,
      };
    case ActionTypes.ERROR_LOADING_PATIENT_MERGE_LOGS:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: false, error: action.payload.message, loaded: false } },
      };
    case ActionTypes.CREATED_PATIENT_MERGE:
      return {
        ...state,
        all: { ...state.all, [action.payload.log.id]: action.payload.log }, // TODO
        pages: { ...state.pages, 1: { ...state.pages[1], ids: [action.payload.log.id, ...state.pages[1].ids] } },
      };
    case ActionTypes.LOADING_CURRENT_PATIENT_MERGE_LOG:
      return { ...state, current: {...state.current, loading: true } };
    case ActionTypes.LOADED_CURRENT_PATIENT_MERGE_LOG:
      return {
        ...state,
        current: {
          ...state.current,
          loading: false,
          laded: true,
          log: { ...action.payload.log, logEntry: action.payload.logEntry },
        },
      };
    case ActionTypes.ERROR_LOADING_CURRENT_PATIENT_MERGE_LOG:
      return { ...state, current: {...state.current, loading: false, loaded: false, error: action.payload.message } };
    case ActionTypes.RESET_PATIENT_MERGE_LOGS_FILTERS:
      return { ...state, searchParams: INITIAL_STATE.searchParams };
    case ActionTypes.RESET_CURRENT_PATIENT_MERGE_LOG:
      return { ...state, current: INITIAL_STATE.current };
    default:
      return state;
  }
};

export const getPatientMergeLogs = curry(({ localState }) => {
  const users = get(localState, ["all"], {});
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  const ids = get(localState.pages, [pageNumber, "ids"], []);
  return map(ids, x => find(users, y => y.id === x));
});

export const isPageLoading = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loading"], false);
});

export const isPageLoaded = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loaded"], false);
});

export const getErrorMessage = curry(({ localState }, id, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "error"], null);
});

export const getPageInfo = curry(({ localState }) => localState.pageInfo);

export const getSearchParams = curry(({ localState }) => localState.searchParams);

export const isCurrentLoading = curry(({ localState }) => localState.current.loading === true);

export const getCurrent = curry(({ localState }) => localState.current.log);
