import React from "react";
import { useDispatch, useSelector } from "react-redux";

import PatientReferralsList from "app/main/patients/referrals/components/patient-referrals-list";
import { HeaderAction } from "app/main/patients/components/patient-components";
import withPermissions from "permissions/withPermissions";
import { fetchPatientReferrals } from "app/main/referrals/actions/patientReferrals.actions";
import { getPatientReferralSearchParams } from "app/main/referrals/reducers/patientReferrals.reducers";
import useAddPatientReferral from "app/main/patientReferralWorklist/hooks/useAddPatientReferral";
import PatientRecordPageContent from "app/main/patients/components/patient-record-page-content";

function PatientReferralsPage({ patientId, hasPermissionReferralsCreate }) {
  const dispatch = useDispatch();
  const searchParams = useSelector(getPatientReferralSearchParams);

  const onSucceed = () => {
    dispatch(fetchPatientReferrals({ ...searchParams, patientId }, 1, true));
  };

  const { openAddReferralForm } = useAddPatientReferral({ patientId, onSucceed });

  return (
    <PatientRecordPageContent pageAction={hasPermissionReferralsCreate && <HeaderAction onClick={openAddReferralForm} label="Add Referral" />}>
      <PatientReferralsList
        patientId={patientId}
        classes={{ filter: "px-16 pt-16", paging: "pr-8 pb-16" }}
      />
    </PatientRecordPageContent>
  );
}

export default withPermissions("ReferralsCreate")(PatientReferralsPage);
