import curryConnector from "utils/connectors";

export const PROVIDER_STATE_KEY = "providers";

const curry = fn => curryConnector(fn, PROVIDER_STATE_KEY);

export const ActionTypes = {
  LOADING_PROVIDERS: "LOADING_PROVIDERS",
  LOADED_PROVIDERS: "LOADED_PROVIDERS",
  ERROR_LOADING_PROVIDERS: "ERROR_LOADING_PROVIDERS",
};

const initialState = {
  all: null,
  loading: false,
  loaded: false,
  error: null,

};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_PROVIDERS:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.LOADED_PROVIDERS:
      return {
        ...state,
        loading: false,
        loaded: true,
        all: action.payload,
      };
    case ActionTypes.ERROR_LOADING_PROVIDERS:
      return {
        ...state,
        loading: false,
        loaded: false,
        all: null,
        error: action.payload.message,
      };
    default:
      return state;
  }
};

export const areProvidersLoading = curry(({ localState }) => localState.loading === true);

export const areProvidersLoaded = curry(({ localState }) => localState.loaded === true);

export const getProviders = curry(({ localState }) => localState.all);
