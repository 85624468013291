import React from "react";
import { map } from "lodash";

import ProblemListItem from "app/main/patients/problems/components/problem-list-item";
import StateContainer from "components/layouts/default-state-container";

const ProblemList = ({
  problems,
  onEditProblem,
  onRemoveProblem,
  onResolveProblem,
  onRenewProblem,
  isEmpty,
  isError,
  isLoading,
}) => (
  <StateContainer loading={isLoading} error={isError} empty={isEmpty} emptyTitle="No Recorded Problems">
    {map(problems, (problem, index) => (
      <ProblemListItem
        item={problem}
        key={problem.id}
        isFirst={index === 0}
          // actions
        onEdit={onEditProblem ? () => onEditProblem(problem.id) : null}
        onRemove={onRemoveProblem ? () => onRemoveProblem(problem.id) : null}
        onResolve={onResolveProblem && !problem.resolvedDateTimeUtc ? () => onResolveProblem(problem.id) : null}
        onRenew={onRenewProblem && problem.resolvedDateTimeUtc ? () => onRenewProblem(problem.id) : null}
      />
    ))}
  </StateContainer>
);
export default ProblemList;
