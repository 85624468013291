import React from "react";
import { connect } from "react-redux";
import { Field } from "formik";

import { TextInput, NoteInput } from "components/inputs/text-input";
import { getCurrentProcedure } from "app/main/patients/reducers/procedures.reducers";
import { recordProcedure, editProcedure } from "app/main/patients/actions/procedures.actions";
import { validateRequired } from "utils/validators";
import { DatePicker } from "components/inputs";
import Form from "components/form";
import { ConditionIcon } from "helpers/icon-finder";

const procedureDescription = "Procedure Description";
const details = "Procedure Details";
const datePerformed = "Date Performed";
const relatedCondition = "Related Condition";
const validateDescription = validateRequired(procedureDescription);
const validateDetails = validateRequired(details);
const validateDatePerformed = validateRequired(datePerformed);

class RecordProcedureForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = ({ procedure }, { setErrors }) => {
    const newProcedure = { ...procedure };
    const apiCall = this.props.isEdit ? this.props.editProcedure : this.props.recordProcedure;

    apiCall(newProcedure).then(responds => {
      if (responds.error !== true) {
        this.props.onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  render() {
    const { initialValues, ...other } = this.props;

    return (
      <Form
        contentProps={other}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        content={() => (
          <>
            <Field
              name="procedure.procedure"
              icon="mode_comment"
              label={procedureDescription}
              component={TextInput}
              validate={validateDescription}
            />
            <Field
              name="procedure.datePerformed"
              label={datePerformed}
              component={DatePicker}
              validate={validateDatePerformed}
            />
            <Field
              name="procedure.relatedCondition"
              label={relatedCondition}
              component={TextInput}
              icon={<ConditionIcon />}
            />
            <Field
              name="procedure.notes"
              label={details}
              component={NoteInput}
              validate={validateDetails}
            />
          </>
        )}
      />
    );
  }
}

export default connect(state => {
  const procedure = getCurrentProcedure(state);

  return ({
    initialValues: { procedure },
  });
}, {
  recordProcedure,
  editProcedure,
})(RecordProcedureForm);
