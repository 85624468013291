import React from "react";
import { Alert } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { Field } from "formik";
import { NoteInput } from "components/inputs/text-input";
import { completeReferralAction } from "app/main/referrals/actions/referrals.actions";
import { validateMaxLength } from "utils/validators";
import Form from "components/form";

const messageLabel = "Message";
const validateMessage = validateMaxLength(messageLabel, 1000);

const CompleteReferralForm = ({
  referralId,
  onSucceed,
  message,
  ...other
}) => {
  const dispatch = useDispatch();

  const handleSubmit = ({ referral }, { setErrors }) => {
    const completeReferral = {
      ...referral,
      referralId,
    };

    dispatch(completeReferralAction(completeReferral)).then(responds => {
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  return (
    <Form
      initialValues={{ referral: { note: "" } }}
      onSubmit={handleSubmit}
      contentProps={other}
      variant="filled"
      content={() => (
        <>
          {message && <Alert severity="warning">{message}</Alert>}
          <Field
            name="referral.note"
            label={messageLabel}
            component={NoteInput}
            validate={validateMessage}
            autoFocus
          />
        </>
      )}
    />
  );
};

export default CompleteReferralForm;
