import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import { map, find, startCase } from "lodash";
import SquareIcon from "mdi-react/SquareIcon";

import getSeverityColour from "app/main/patients/helpers/get-severity-colour";
import DefaultItem from "components/items/default-item";

const IconContainerComponent = ({ isFilled, fillColor, baseColor }) => (
  <SquareIcon size={20} style={{ color: isFilled ? fillColor : baseColor }} />
);

const SeverityRating = ({ ratings, severity, theme: { palette: { background } }, contentOnly }) => {
  if (!severity) return null;
  const currentSeverity = find(ratings, rating => rating.label === severity);
  const fillColor = getSeverityColour(severity).color;
  const baseColor = background.main;

  return (
    <DefaultItem
      icon={contentOnly ? "" : "report_allergy"}
      content={(
        <div className="flex items-center">
          <div className="flex mr-8">
            {map(ratings, x => (
              <IconContainerComponent key={x.value} isFilled={x.value <= currentSeverity.value} fillColor={fillColor} baseColor={baseColor} />
            ))}
          </div>
          <Typography variant="caption">[{startCase(severity)}]</Typography>
        </div>
      )}
    />
  );
};

SeverityRating.propTypes = {
  severity: PropTypes.string,
};

export default withTheme(SeverityRating);
