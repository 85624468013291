import React, { useState, useRef, useEffect, useContext } from "react";
import { Typography } from "@material-ui/core";
import { get } from "lodash";
import { useLocation } from "react-router-dom";

import ReferralPageContent from "app/main/referrals/components/referral-page-content";
import PanelContent from "components/items/chat-panel/content";
import PrimaryHeaderPortal from "portals/primaryHeaderPortal";
import normaliseDateTime from "app/main/patients/helpers/normalise-date-time";
import LoadingState from "components/items/loading-state";
import AppContext from "app/AppContext";

export default function PatientReferralPage({ match: { params: { referralId } } }) {
  const [width, setWidth] = useState(0);
  const contentRef = useRef();
  const appContext = useContext(AppContext);
  const { setBackURL } = appContext;
  const location = useLocation();

  const updateWidth = () => {
    setWidth(get(contentRef, ["current", "clientWidth"], 0));
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  useEffect(() => {
    if (location.fromUrl) {
      setBackURL(location.fromUrl);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={contentRef} className="h-full">
      <ReferralPageContent
        referralId={referralId}
        renderContent={({ loading, header, content, currentReferral }) => {
          if (loading) {
            return <LoadingState />;
          }
          const referralDate = normaliseDateTime(currentReferral?.referralDateUtc);
          return (
            <>
              <PrimaryHeaderPortal>
                <Typography>{referralDate?.dateTime}</Typography>
              </PrimaryHeaderPortal>
              <PanelContent
                renderHeader={() => header({ showMedicalRecordButton: false })}
                renderContent={() => content({ width })}
              />
            </>
          );
        }}
      />
    </div>
  );
}
