import React, { createRef } from "react";
import { withTheme } from "@material-ui/core/styles";
import { Typography, withStyles, AppBar, Toolbar } from "@material-ui/core";
import clsx from "clsx";
import { size, has, forEach, capitalize } from "lodash";
import * as Survey from "survey-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "survey-react/survey.css";
import { showMessage } from "app/store/actions/message.actions";
import EmptyState from "components/items/empty-state";
import { onSurveyComplete, onSurveyChange, fetchLastSurveyResults, clearSurveyResults, setSurveyNotRequired, setSurveyRequired, setSurveyInProgress, printAssessmentItems, fetchAssessmentPrintHistory } from "app/main/patients/actions/assessments.actions";
import DefaultButton from "components/items/default-button";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import ConfirmationDialog from "components/items/confirmation-dialog";

import "components/styles/survey.css";

const surveyControls = ["Allergies", "Conditions", "Procedures", "Vaccinations", "SocialAndEmergencyContacts"];

const messageProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
};

const styles = theme => ({
  header: {
    backgroundColor: theme.palette.background.control,
    flexWrap: "wrap",
  },
  headerActions: {
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
});

const SurveyActionButton = ({ label, onClick, ...other }) => (
  <DefaultButton size="small" variant="outlined" onClick={onClick} label={label} {...other} />
);

class PatientSurvey extends React.Component {
  constructor(props) {
    super(props);

    this.onComplete = this.onComplete.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);
    this.throttleValueChange = this.throttleValueChange.bind(this);
    this.onMatrixAllowRemoveRow = this.onMatrixAllowRemoveRow.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    // applying theme
    this.setSurveyTheme();
    this.state = {
      readonly: this.props.isCompleted,
      currentPage: 0,
    };
    this.timer = createRef(null);
  }

  componentDidMount() {
    // detect route changes,
    // timer will stop if user navigate to different route
    // we have to make sure the timer stop to prevent error cause by auto save
    // e.g. as by then there won't be assessmentId
    // note: if user type quickly in input and click the back button changes wont be saved
    this.routeChanges = this.props.history.listen(() => {
      this.stopTimer();
    });
  }

  componentWillUnmount() {
    this.routeChanges();
  }

  stopTimer = () => {
    clearTimeout(this.timer.current);
  }

  setDisplayMode = readonly => {
    this.setState({ readonly });
  };

  setPageNumber = currentPage => {
    this.setState({ currentPage });
  };

  preFillFromLastSurvey = () => {
    const { status } = this.props;
    if (status === "InProgress") {
      this.props.openDialog({
        children: (
          <ConfirmationDialog
            type="warning"
            title="Are you sure?"
            content="Any information you have entered in this form will be overwritten with the information from the previous assessment"
            onConfirm={() => {
              this.getPreFillData();
              this.props.closeDialog();
            }}
            onCancel={() => this.props.closeDialog()}
          />
        ),
      });
    } else {
      this.getPreFillData();
    }
  }

  clearAllSurveyAnswers = () => {
    const { patientId, assessmentId, id, surveyType } = this.props;

    this.props.openDialog({
      children: (
        <ConfirmationDialog
          type="warning"
          title="Are you sure?"
          content="Any information you have entered in this form will deleted"
          onConfirm={() => {
            this.props.clearSurveyResults(patientId, {
              assessmentId,
              id,
              surveyType,
            });
            this.props.closeDialog();
            this.setPageNumber(0);
          }}
          onCancel={() => this.props.closeDialog()}
        />
      ),
    });
  }

  updateSurveyStatus = (apiCall, content, readonly) => {
    const { patientId, id } = this.props;
    this.props.openDialog({
      children: (
        <ConfirmationDialog
          type="warning"
          title="Are you sure?"
          content={content}
          onConfirm={() => {
            apiCall(patientId, { id });
            this.setDisplayMode(readonly);
            this.props.closeDialog();
          }}
          onCancel={() => this.props.closeDialog()}
        />
      ),
    });
  }

  printSurvey = () => {
    const { patientId, assessmentId, title, surveyType } = this.props;
    this.props.openDialog({
      children: (
        <ConfirmationDialog
          type="warning"
          title={`Print ${title}`}
          content="Are you sure you want to print this assessment?"
          confirmLabel="Print"
          onConfirm={() => {
            this.props.printAssessmentItems({ patientId, currentAssessmentId: assessmentId, surveys: { [surveyType]: true } });
            // Update the print history for this assessment
            this.props.fetchAssessmentPrintHistory({ patientId, assessmentId });
            this.props.closeDialog();
          }}
          onCancel={() => this.props.closeDialog()}
        />
      ),
    });
  }

  surveyNotRequired = () => {
    this.updateSurveyStatus(this.props.setSurveyNotRequired, "This will make the assessment item not required", true);
  }

  surveyRequired = () => {
    this.updateSurveyStatus(this.props.setSurveyRequired, "This will make the current assessment item required", false);
  }

  surveyInProgress = () => {
    this.updateSurveyStatus(this.props.setSurveyInProgress, "This will set the current assessment item back to in progress", false);
  }

  getPreFillData = () => {
    const { patientId, id, surveyType } = this.props;
    this.props.fetchLastSurveyResults(patientId, id, surveyType);
  }

  // callback event to control visibility of "remove" button in matrix
  onMatrixAllowRemoveRow = (survey, options) => {
    forEach(surveyControls, value => {
      if (has(this.props.resultsJson, value)) {
        // eslint-disable-next-line no-param-reassign
        options.allow = options.rowIndex > size(this.props.resultsJson[value]) - 1;
      }
    });
  }

  displayErrorMessage = response => {
    const { exceptionMessage, innerException } = response.payload;
    this.props.showMessage({
      message: `${capitalize(exceptionMessage)} ${innerException ? `: ${innerException.exceptionMessage}` : ""}`,
      variant: "error",
      ...messageProps,
    });
  }

  // Define a callback methods on survey complete
  onComplete = survey => {
    const { patientId, assessmentId, id } = this.props;
    // clear auto complete awaiting time,
    // to prevent survey being saved again after it has been completed
    this.stopTimer();
    this.props.onSurveyComplete(patientId, {
      id,
      assessmentId,
      surveyType: this.props.surveyType,
      resultsJson: JSON.stringify(survey.data),
    }).then(response => {
      if (response.error) {
        this.displayErrorMessage(response);
      } else {
        this.props.showMessage({
          message: "Assessment Completed",
          variant: "success",
          autoHideDuration: 5000,
          ...messageProps,
        });
      }
    });
  };

  onValueChanged = (survey, options) => {
    if (!options.question.expression) { // don't post json if the value changed is the result of an expression
      this.throttleValueChange(survey.data);
    }
  };

  throttleValueChange = data => {
    const { patientId, assessmentId, id } = this.props;
    this.stopTimer();
    this.timer.current = setTimeout(() => (
      this.props.onSurveyChange(patientId, {
        assessmentId,
        id,
        surveyType: this.props.surveyType,
        resultsJson: JSON.stringify(data),
      }).then(response => {
        if (response.error) {
          this.displayErrorMessage(response);
        }
      })
    ), 1000);
  };

  setSurveyTheme = () => {
    const {
      theme: {
        palette: { primary, text, background },
      },
    } = this.props;

    const defaultThemeColors = Survey.StylesManager.ThemeColors.default;
    defaultThemeColors["$main-color"] = primary.dark;
    defaultThemeColors["$main-hover-color"] = primary.dark;
    defaultThemeColors["$text-color"] = text.primary;
    defaultThemeColors["$header-color"] = text.secondary;
    defaultThemeColors["$header-background-color"] = background.paper;
    Survey.StylesManager.applyTheme();
  };

  render() {
    const { surveyJson: json, resultsJson: results, allowPreFillFromLast, allowNotRequired, isCompleted, title, classes, status, canPreFill, hasPermissionAssessmentsPrint } = this.props;
    const { readonly, currentPage } = this.state;
    // Create the model and pass it into react Survey component
    const model = new Survey.Model(json);
    // double check for empty json
    if (model.isEmpty) {
      return <EmptyState title="No json yet" />;
    }
    // The Survey.Model.data contains the Wordlist values for the cognitive assessment before the results are added to it.
    // Combine them with the results if they are already in the model, so they are not overridden.
    if (results) {
      if (has(model.data, "WordLists")) {
        const resultsAndDefaultProps = { ...results, WordLists: model.data.WordLists };
        model.data = resultsAndDefaultProps;
      } else {
        model.data = results;
      }
    }
    const showEditButton = readonly && status !== "NotRequired";
    const showPreFillButton = !isCompleted && allowPreFillFromLast && canPreFill;
    const showClearButton = !readonly && !isCompleted;
    const showNotRequiredButton = !isCompleted && allowNotRequired;
    const showRequiredButton = status === "NotRequired" && allowNotRequired;
    const showPrintButton = isCompleted && readonly && hasPermissionAssessmentsPrint;

    return (
      <>
        <AppBar position="sticky" elevation={2}>
          <Toolbar disableGutters className={clsx(classes.header, "flex items-center justify-between px-16")}>
            <Typography variant="h6" color="primary">{title}</Typography>
            <div className={clsx("flex justify-end", classes.headerActions)}>
              {showEditButton
              && <SurveyActionButton label="Enable Edit" onClick={() => this.surveyInProgress()} />}
              {showPreFillButton
              && <SurveyActionButton label="Prefill From Last" onClick={() => this.preFillFromLastSurvey()} />}
              {showClearButton
              && <SurveyActionButton label="Clear All Answers" onClick={() => this.clearAllSurveyAnswers()} />}
              {showNotRequiredButton
              && <SurveyActionButton onClick={() => this.surveyNotRequired()} label="Not Required" />}
              {showRequiredButton
              && <SurveyActionButton onClick={() => this.surveyRequired()} label="Required" />}
              {showPrintButton
              && <SurveyActionButton onClick={() => this.printSurvey()} label="Print" />}
            </div>
          </Toolbar>
        </AppBar>
        <Survey.Survey
          model={model}
          showCompletedPage={false}
          currentPageNo={currentPage}
          mode={readonly ? "display" : "edit"}
          showQuestionNumbers="off"
          showProgressBar={size(model.pages) <= 1 ? "" : "top"}
          onComplete={this.onComplete}
          onValueChanged={this.onValueChanged}
          onMatrixAllowRemoveRow={this.onMatrixAllowRemoveRow}
          showPageTitles={model.pageCount > 1}
        />
      </>
    );
  }
}

export default withTheme(connect(null, {
  setSurveyInProgress,
  setSurveyNotRequired,
  setSurveyRequired,
  onSurveyComplete,
  onSurveyChange,
  fetchLastSurveyResults,
  clearSurveyResults,
  printAssessmentItems,
  fetchAssessmentPrintHistory,
  openDialog,
  closeDialog,
  showMessage,
})(withStyles(styles)(withRouter(PatientSurvey))));
