import React from "react";
import { Typography } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";

function encounterTypeConsentStatus(props) {
  const { status, consented } = props;
  if (status === "Completed" && consented != null) {
    return (
      <Typography
        variant="caption"
        className="px-4"
        style={{ color: consented ? green[600] : red[600], fontWeight: "bold" }}
      >
        ({consented ? "CONSENTED" : "NOT CONSENTED"})
      </Typography>
    );
  }
  return null;
}

export default encounterTypeConsentStatus;
