export default function getPatientIdentifierLength(patientIdentifierCode) {
  switch (patientIdentifierCode) {
    case "IHI":
      return 9;
    case "PT":
    case "THCI":
      return 9;
    case "SLHMN":
    case "Member No":
      return 8;
    case "MC":
      return 11;
    default:
      return undefined;
  }
}
