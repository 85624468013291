export function getLinkLabelPrefix(status, encounterType = null) {
  switch (status) {
    case "Booked":
      switch (encounterType) {
        case "ConsentCall":
          return "Record Call";
        case "ConsentVisit":
          return "Record visit";
        case "InitialAssessment":
        case "FollowUpAssessment":
        default:
          return "Record Encounter";
      }
    case "InProgress":
      switch (encounterType) {
        case "ConsentCall":
          return "Continue Consent Call";
        case "ConsentVisit":
          return "Continue Consent Visit";
        case "InitialAssessment":
          return "Continue Initial Assessment";
        case "FollowUpAssessment":
          return "Continue Assessment";
        default:
          return "Continue";
      }
    case "Completed":
      return "view";
    default:
      return null;
  }
}

export function getEncounterEditTitle(encounterType) {
  switch (encounterType) {
    case "ConsentCall":
      return "Edit Call";
    case "ConsentVisit":
      return "Edit visit";
    default:
      return "Edit Encounter";
  }
}

export function getEncounterAssessmentButtonTitle(assessmentStatus) {
  switch (assessmentStatus) {
    case "InProgress":
      return "Continue";
    case "Completed":
      return "View";
    default:
      return "Create Assessment";
  }
}
