import { ApiActions } from "middleware/call-api";
import { ActionTypes,
  areProceduresLoading,
  areProceduresLoaded,
  getProcedureById } from "../reducers/procedures.reducers";

export const setCurrentProcedure = procedure => ({
  type: ActionTypes.LOADED_CURRENT_PROCEDURE,
  payload: { procedure },
});

export const fetchPatientProcedures = patientId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/procedures`,
    bailout: state => areProceduresLoaded(state, patientId) || areProceduresLoading(state, patientId),
    entityType: "procedures",
    patientId,
  },
});

export const fetchProceduresById = (patientId, procedureId) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/procedures/${procedureId}`,
    types: [ActionTypes.LOADING_CURRENT_PROCEDURE, ActionTypes.LOADED_CURRENT_PROCEDURE, ActionTypes.ERROR_LOADING_CURRENT_PROCEDURE],
    patientId,
    procedureId,
  },
});

/** * Add Procedure */
export const recordNewProcedure = patientId => setCurrentProcedure({
  id: null,
  patientId,
  notes: null,
  datePerformed: null,
  procedure: null,
});

export const recordProcedure = procedure => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${procedure.patientId}/procedures/new`,
    body: { procedure },
    entityType: "procedure",
    patientId: procedure.patientId,
  },
});

export const editExistingProcedure = (patientId, procedureId) => (dispatch, getState) => {
  const procedure = getProcedureById(getState(), patientId, procedureId);
  dispatch(setCurrentProcedure(procedure));
};

export const editProcedure = procedure => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/patient/${procedure.patientId}/procedures/edit`,
    body: { procedure },
    entityType: "procedure",
    patientId: procedure.patientId,
  },
});

export const removeProcedure = (patientId, procedureId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/patient/${patientId}/procedures/remove`,
    body: { procedure: { id: procedureId, patientId } },
    entityType: "procedure",
    patientId,
    procedureId,
  },
});
