import { useSelector, useDispatch } from "react-redux";
import { map, isEmpty, size, filter } from "lodash";
import PropTypes from "prop-types";

import { getReferralWorklistFilter } from "app/main/referralWorklist/reducers/referralWorklist.reducers";
import { getReferralWorkflowStateSettings, getEnabledTriageCategorySettings } from "app/auth/store/reducers/system-configuration";
import { updateReferralWorkListFilterTags } from "app/main/referralWorklist/actions/referralWorklist.actions";

const useReferralSearch = ({
  params,
  searchAPI,
  exportAPI,
}) => {
  const dispatch = useDispatch();
  const triageCategorySettings = useSelector(getEnabledTriageCategorySettings);
  const triageCategoryChoices = map(triageCategorySettings, x => x.triageCategory);
  const referralWorkflowSettings = useSelector(getReferralWorkflowStateSettings);
  const referralStatusChoices = map(filter(referralWorkflowSettings, x => x.isEnabled === true), setting => setting.workflowState);
  const presetFilter = useSelector(getReferralWorklistFilter);

  const getParameters = parameters => {
    if (!parameters) {
      return {
        searchParams: null,
        initialValues: null,
      };
    }

    let { status, category } = parameters;

    let allStatus = true;
    let allCategory = true;

    if (!isEmpty(status) && size(status) !== size(referralStatusChoices)) {
      allStatus = false;
    } else {
      status = map(referralStatusChoices, s => s);
    }

    if (!isEmpty(category) && size(category) !== size(triageCategoryChoices)) {
      allCategory = false;
    } else {
      category = map(triageCategoryChoices, c => c);
    }

    const referralFilters = {
      ...parameters,
      status,
      category,
      dateRange: [params.startDate, params.endDate],
    };

    return ({
      searchParams: {
        ...referralFilters,
        status: allStatus ? [] : status,
        category: allCategory ? [] : category,
      },
      initialValues: {
        referralFilters: {
          ...referralFilters,
          allStatus,
          allCategory,
        },
      },
    });
  };

  const onSearch = (newParams, page, pageSize, reload = false) => new Promise(resolve => {
    const { searchParams } = getParameters(newParams);

    setTimeout(() => {
      resolve(dispatch(searchAPI(searchParams, page || 1, pageSize || 14, reload))
        .then(res => {
          if (res) {
            dispatch(updateReferralWorkListFilterTags(res.payload?.searchParams));
          }
        }));
    }, 500);
  });

  const onExport = () => {
    if (!exportAPI) return null;
    const { searchParams } = getParameters(params);
    return dispatch(exportAPI(searchParams));
  };

  const { searchParams, initialValues } = getParameters(params);

  return ({
    searchParams,
    initialValues,
    referralStatusChoices,
    triageCategoryChoices,
    presetFilter,
    referralWorkflowSettings,
    triageCategorySettings,
    onSearch,
    onExport,
  });
};

useReferralSearch.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchAPI: PropTypes.func.isRequired,
  exportAPI: PropTypes.func,
};

export default useReferralSearch;
