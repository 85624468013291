import moment from "moment";
import { isEmpty } from "lodash";
import { PreferredLongDateTimeFormat, PreferredDateFormat } from "utils/date";
import formatTime from "helpers/format-time";

export default function normaliseDateTime(utcDate, calculateOverDue) {
  if (isEmpty(utcDate)) {
    return "";
  }
  // convert utc to local
  const date = moment.utc(utcDate).local();

  let label = date.format(PreferredLongDateTimeFormat);
  let isOverdue = false;
  let isToday = false;

  const today = moment().startOf("day");
  const time = formatTime(date);

  if (date.isSame(today, "d")) {
    isToday = true;
    label = `Today at ${time}`;
  }

  if (date.isBefore(moment()) && calculateOverDue) {
    isOverdue = true;
  }

  return {
    dateTime: label,
    date: date.format(PreferredDateFormat),
    time,
    isToday,
    isOverdue,
  };
}
