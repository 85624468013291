import React from "react";
import LoadingState from "components/items/loading-state";
import EmptyState, { AccessDeniedState } from "components/items/empty-state";
import ErrorState from "components/items/error-state";

/**
 * render loading, empty and error state
 */
const DefaultStateContainer = ({
  loading, error, empty, children, emptyTitle, emptyState, loadingState, accessDenied, accessDeniedState,
}) => {
  let content = () => null;

  if (loading) {
    content = () => (loadingState || <LoadingState />);
  } else if (accessDenied) {
    content = () => (accessDeniedState || <AccessDeniedState />);
  } else if (empty) {
    content = () => (emptyState || <EmptyState title={emptyTitle || "Nothing here yet"} />);
  } else if (error) {
    content = () => <div className="flex-auto flex justify-center items-center"><ErrorState title={error} /></div>;
  } else {
    content = () => children;
  }

  return content();
};

export default DefaultStateContainer;
