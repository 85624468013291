import React from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { upperCase, isEmpty, get, startCase, size } from "lodash";
import { Typography } from "@material-ui/core";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import DoctorIcon from "mdi-react/DoctorIcon";

import DefaultPanelLayout from "components/items/default-panel-layout";
import DefaultButton from "components/items/default-button";
import EmptyState, { AccessDeniedState, NoneRecordedState } from "components/items/empty-state";
import ApplicationPanelContent from "app/main/applications/components/application-panel-content";
import ApplicationPersonForm from "app/main/applications/components/application-person-form";
import { onSaveOrUpdateApplicant, onSaveOrUpdateConsultant, onRemoveConsultant } from "app/main/applications/actions/applications.actions";
import { getUser } from "app/auth/store/reducers/user.reducer";
import ApplicationItem, { ApplicationItemTextArea } from "app/main/applications/components/application-item";
import { showMessage } from "app/store/actions/message.actions";
import renderContactInformation from "app/main/applications/helpers/render-application-person-contact-info";
import { AssignedSpecialtyIcon } from "helpers/icon-finder";
import { isSectionCompleted } from "app/main/applications/reducers/applications.reducers";

import ApplicationRemoveButton from "./application-remove-button";
import ApplicationEditButton from "./application-edit-button";

class ApplicantConsultantPanel extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onRemoveConsultant = consultantId => {
    const { id, orgUnitId } = this.props;
    this.props.onRemoveConsultant(id, consultantId, orgUnitId).then(response => {
      if (response.error === true) {
        this.props.showMessage({ message: response.payload.exceptionMessage || "Error occurred while deleting the consultant", variant: "error" });
      } else {
        this.props.showMessage({ message: "Successfully removed the consultant", variant: "success" });
      }
    });
  }

  handleSubmit = apiCall => ({ applicationPerson }, { setErrors }) => {
    const { id } = this.props;

    let data = applicationPerson;
    data = {
      ...data,
      personId: data.id,
    };

    apiCall(id, this.props.orgUnitId, data).then(response => {
      if (response.error !== true) {
        this.props.closeDialog();
      } else {
        setErrors(response.payload);
      }
    });
  }

  renderSpecialties = (consultantSpecialty, otherSpecialty) => {
    let specialties = consultantSpecialty?.name ?? "";
    if (otherSpecialty?.name) {
      specialties += isEmpty(specialties) ? "" : " , ";
      specialties += otherSpecialty?.name;
    }
    return specialties;
  }

  openApplicationPerson = (type, person) => {
    const isNew = !person;
    const { canSuperEdit, currentUser, consultantSpecialty, otherSpecialty, consultant, applicant } = this.props;
    let applicationPerson = person;

    const isApplicant = type === "Applicant";
    const preFillData = isNew && isApplicant;

    if (preFillData) {
      applicationPerson = {
        ...person,
        givenName: currentUser.firstName,
        familyName: currentUser.lastName,
        email: currentUser.email,
        userId: currentUser.userId,
        consultantSpecialty,
        otherSpecialty,
      };
    }

    let showSpecialtySelectors = true;

    if (isApplicant && !isEmpty(consultant) && consultant.id !== applicant.id) {
      showSpecialtySelectors = false;
    }

    this.props.openDialog({
      maxWidth: "md",
      children: (
        <ApplicationPersonForm
          onSucceed={this.props.closeDialog()}
          title={`${isNew ? "Add" : "Edit"}`}
          handleSubmit={this.handleSubmit(isApplicant ? this.props.onSaveOrUpdateApplicant : this.props.onSaveOrUpdateConsultant)}
          applicationPerson={applicationPerson}
          allowNameEditing={isApplicant ? canSuperEdit : true}
          showSpecialtySelectors={showSpecialtySelectors}
          consultantSpecialty={consultantSpecialty}
          otherSpecialty={otherSpecialty}
        />
      ),
    });
  };

  renderContent = ({ person, title, type, onRemove, showApplicantSpecialty }) => {
    const { width, canView, canEdit, applicant, consultant, consultantSpecialty, otherSpecialty, status } = this.props;
    if (isEmpty(person) || (type === "Consultant" && get(applicant, "id", false) === get(consultant, "id", false))) {
      if (!canView) {
        return <AccessDeniedState />;
      }
      if (canView && !canEdit) {
        return <NoneRecordedState />;
      }

      let emptyMessage = `Add ${type}`;
      if (type === "Consultant") {
        emptyMessage = "Are you submitting this application on behalf of somebody else?";
      }

      return (
        <div className="flex justify-center h-full">
          {canEdit
            ? <EmptyState icon="person" subtitle={emptyMessage} onClick={() => this.openApplicationPerson(type)} />
            : <EmptyState icon="person" subtitle={`Unable to add ${type} to an application with status of ${startCase(status)}`} />}
        </div>
      );
    }
    const { conflictOfInterest } = person;
    const displayConflictOfInterestAsCol = size(conflictOfInterest) > 20;
    return (
      <>
        <div className={clsx(
          "flex items-center justify-between min-h-48",
          type === "Applicant" && !isWidthDown("sm", width) && "mr-16",
        )}
        >
          <Typography className="font-bold">{upperCase(title)}</Typography>
          <div className="flex justify-end items-center">
            {canEdit && onRemove
              && <ApplicationRemoveButton onClick={() => onRemove(person.id)} triggerComponent={<DefaultButton />} />}
            {canEdit
              && <ApplicationEditButton onClick={() => this.openApplicationPerson(type, person)} />}
          </div>
        </div>
        <ApplicationItem icon={<DoctorIcon />} content={`${person.title ? `${person.title} ` : ""}${person.givenName} ${person.familyName}`} />
        <ApplicationItem icon={AssignedSpecialtyIcon} content={showApplicantSpecialty && (consultantSpecialty || otherSpecialty) && this.renderSpecialties(consultantSpecialty, otherSpecialty)} />
        <ApplicationItem icon="phone" content={person.phone} />
        {renderContactInformation(person)}
        <ApplicationItemTextArea
          label="Conflict Of Interest"
          content={conflictOfInterest}
          showRequiredLabel={!(person.conflictOfInterest && person.conflictOfInterest.trim().length > 0)}
          className={clsx("with-horizontal-divider mr-16", displayConflictOfInterestAsCol && "flex-col")}
        />
      </>
    );
  }

  render() {
    const { applicant, consultant, canView, showIsRequired, canEdit } = this.props;
    const isNewApplicant = isEmpty(applicant);

    let showApplicantSpecialty = false;

    if (isEmpty(applicant) || isEmpty(consultant)) {
      showApplicantSpecialty = true;
    } else {
      showApplicantSpecialty = applicant.id === consultant.id;
    }

    return (
      <DefaultPanelLayout
        accessDenied={!canView}
        icon="person"
        title="Applicant"
        headerActions={(canEdit && isNewApplicant)
        && ([
          {
            label: "Add Applicant",
            icon: "add",
            onClick: () => this.openApplicationPerson("Applicant"),
          },
        ])}
        status={showIsRequired && <Typography color="error">*</Typography>}
      >
        <ApplicationPanelContent>
          {this.renderContent({
            person: applicant,
            title: "Application by",
            type: "Applicant",
            showApplicantSpecialty,
          })}
          {!isNewApplicant && this.renderContent({
            person: consultant,
            title: "On behalf of ",
            type: "Consultant",
            onRemove: this.onRemoveConsultant,
            showApplicantSpecialty: !showApplicantSpecialty,
          })}
        </ApplicationPanelContent>
      </DefaultPanelLayout>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: getUser(state),
  showIsRequired: isSectionCompleted(state, "applicant"),
});

export default connect(
  mapStateToProps,
  {
    onSaveOrUpdateApplicant,
    onSaveOrUpdateConsultant,
    onRemoveConsultant,
    showMessage,
  },
)(withWidth()(ApplicantConsultantPanel));
