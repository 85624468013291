export default function getWelfareQuestion(question) {
  switch (question) {
    case "livesAlone":
      return "Do you live alone?";
    case "hasSupport":
      return "Do you have enough support during from family/friends while outings are restricted?";
    case "hasMedicationSupplies":
      return "Do you have enough medication for one month?";
    case "hasPrescriptionHelp":
      return "Are you able to have someone fill and collect your prescriptions?";
    case "hasEnoughFood":
      return "Do you have enough food in your home?";
    case "hasInternetAccess":
      return "Do you have internet access?";
    default:
      return null;
  }
}
