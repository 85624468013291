import React from "react";
import { Field } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Form from "components/form";
import { UserSelector } from "components/inputs";
import { onAssignApprovalRecommendation } from "app/main/applications/actions/applications.actions";

const assignedTo = "Assigned To";

const schema = Yup.object().shape({
  assignment: Yup.object().shape({
    assignedToUserId: Yup.string().required("Assign to user is required").nullable(),
  }),
});

const ApprovalRecommendationAssignmentForm = ({
  id,
  onSucceed,
  approvalRecommendation,
  orgUnitId,
  ...other
}) => {
  const dispatch = useDispatch();

  const handleSubmit = (data, { setErrors }) => {
    const { assignedToUserId } = data.assignment;

    dispatch(onAssignApprovalRecommendation(id, assignedToUserId.value, orgUnitId)).then(response => {
      if (response.error !== true) {
        onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  const { assignment } = schema.cast();

  return (
    <Form
      initialValues={{ assignment }}
      onSubmit={handleSubmit}
      contentProps={other}
      validationSchema={schema}
      content={() => (
        <Field
          name="assignment.assignedToUserId"
          component={UserSelector}
          orgUnitId={orgUnitId}
          userHasPermission="IPAApprovalRecommendationAssignee"
          label={assignedTo}
          icon="person"
        />
      )}
    />
  );
};

export default ApprovalRecommendationAssignmentForm;
