import React from "react";
import { connect } from "react-redux";
import { Field } from "formik";

import { TextInput, NoteInput } from "components/inputs/text-input";
import CheckboxInput from "components/inputs/checkbox";
import { Typography, Grid } from "@material-ui/core";
import SelectInput from "components/inputs/select-input";
import { getCurrentAllergy } from "app/main/patients/reducers/allergies.reducers";
import { recordAllergy, editAllergy } from "app/main/patients/actions/allergies.actions";
import { validateRequired } from "utils/validators";
import Form from "components/form";
import CodedTermSelector from "app/main/codedTerms/components/coded-term-selector";
import { fetchAllergyTermResults } from "app/main/codedTerms/actions/coded-term.actions";

const severityOptions = [
  { value: "Minor", label: "Minor" },
  { value: "Moderate", label: "Moderate" },
  { value: "Significant", label: "Significant" },
  { value: "Severe", label: "Severe" },
];

const allergyDescription = "Allergy";
const allergyReaction = "Reaction";
const details = "Allergy Details";
const severity = "Severity";
const validateDescription = validateRequired(allergyDescription);
const validateReaction = validateRequired(allergyReaction);

class RecordAllergyForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = ({ allergy }, { setErrors }) => {
    const apiCall = this.props.isEdit ? this.props.editAllergy : this.props.recordAllergy;

    apiCall(allergy).then(responds => {
      if (responds.error !== true) {
        this.props.onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  render() {
    const { initialValues, ...other } = this.props;
    return (
      <Form
        contentProps={other}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        content={({ setFieldValue, values }) => (
          <>
            <Grid container direction="row" alignItems="center" style={{ minHeight: "80px" }}>
              <Grid item xs={3}>
                <Typography className="font-bold">Allergy Status</Typography>
              </Grid>
              <Grid item xs>
                <Field
                  name="allergy.isCurrent"
                  label="Current"
                  component={CheckboxInput}
                />
              </Grid>
            </Grid>
            <Field
              name="allergy.allergyTerm"
              component={CodedTermSelector}
              label={allergyDescription}
              onChange={codedTerm => {
                const manuallyAdded = codedTerm?.inputValue != null;
                setFieldValue("allergy.codedTerm", manuallyAdded ? null : codedTerm);
                setFieldValue("allergy.allergyTerm", manuallyAdded ? codedTerm?.inputValue : codedTerm?.term);
              }}
              maxLength={120}
              apiCall={fetchAllergyTermResults}
              validate={validateDescription}
            />
            <Field
              name="allergy.severity"
              component={SelectInput}
              icon="report_allergy"
              label={severity}
              options={severityOptions}
            />
            <Field
              name="allergy.reaction"
              icon="report_problem"
              label={allergyReaction}
              component={TextInput}
              validate={validateReaction}
            />
            <Field
              name="allergy.note"
              label={details}
              component={NoteInput}
            />
          </>
        )}
      />
    );
  }
}

export default connect(state => {
  const allergy = getCurrentAllergy(state);
  return ({
    initialValues: { allergy },
  });
}, {
  recordAllergy,
  editAllergy,
})(RecordAllergyForm);
