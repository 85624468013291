import React, { useState } from "react";
import { map } from "lodash";
import { useSelector, useDispatch } from "react-redux";

import { AutoComplete } from "components/inputs";
import { getPatientSettingValues } from "app/auth/store/reducers/system-configuration";
import { fetchOrgUnitsForSelector } from "app/main/orgUnits/actions/orgUnit.actions";

export const CollectionLocationSelector = ({
  orgUnitId,
  initialOptions = [],
  ...others
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(initialOptions || []);
  return (
    <AutoComplete
      options={map(options, x => ({ label: x.name, value: x.id }))}
      loading={loading}
      onOpen={() => {
        setLoading(true);
        dispatch(fetchOrgUnitsForSelector({ orgUnitTypes: ["Pharmacy"], currentOrgUnitId: orgUnitId, showDeprecated: false })).then(response => {
          setOptions(response.error ? [] : response.payload.orgUnits);
          setLoading(false);
        });
      }}
      filterSelectedOptions
      getOptionSelected={(option, val) => option.value === val.value}
      filterOption={{ matchFrom: "start" }}
      {...others}
    />
  );
};

export const PatientSettingSelector = ({
  ...others
}) => (
  <AutoComplete
    options={useSelector(state => getPatientSettingValues(state))}
    {...others}
  />
);
