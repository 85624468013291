import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { renderRoutes } from "react-router-config";
import { useSelector } from "react-redux";

import Dialog from "components/items/dialog";
import Message from "components/items/message";

import AppHeader from "./app-header";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  content: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    zIndex: 2,
    position: "relative",
    overflow: "auto",
  },
}));

export default () => {
  const classes = useStyles();
  const routes = useSelector(state => state.routes);

  return (
    <div className={classes.root}>
      <AppHeader />
      <div className={classes.content}>
        {renderRoutes(routes)}
      </div>
      <Dialog />
      {/* <ConfirmationDialog /> TODO */}
      <Message />
    </div>
  );
};
