import React from "react";
import AccountQuestionIcon from "mdi-react/AccountQuestionIcon";
import CalendarQuestionIcon from "mdi-react/CalendarQuestionIcon";
import CalendarRemoveIcon from "mdi-react/CalendarRemoveIcon";
import CalendarEditIcon from "mdi-react/CalendarEditIcon";

import { ContextNoteItem, ContextItem } from "components/items/default-item";
import { ConditionIcon } from "helpers/icon-finder";
import { IsCurrentStatus } from "app/main/patients/components/patient-components";
import normaliseDateTime from "app/main/patients/helpers/normalise-date-time";
import SeverityRating from "app/main/patients/components/severity-rating";
import PatientContextListItem, { ItemContent } from "app/main/patients/components/patient-context-list-item";

const ratings = [
  { value: 1, label: "NotSpecified" },
  { value: 2, label: "Mild" },
  { value: 3, label: "Moderate" },
  { value: 4, label: "Severe" },
];

const ConditionListItem = ({
  item: {
    condition,
    notes,
    severity,
    isCurrent,
    status,
    reportedDate,
    onsetDate,
    endDate,
  },
  ...other
}) => {
  const conditionReportedDate = normaliseDateTime(reportedDate);
  const conditionOnsetDate = normaliseDateTime(onsetDate);
  const conditionEndDate = normaliseDateTime(endDate);

  return (
    <PatientContextListItem
      title={condition}
      {...other}
      renderItemContent={() =>(
        <ItemContent
          title={condition}
          icon={<ConditionIcon />}
          status={<IsCurrentStatus status={isCurrent === true ? "Current" : "Resolved"} />}
          content={(
            <>
              <SeverityRating ratings={ratings} severity={severity} contentOnly />
              {conditionReportedDate.date}
            </>
          )}
        />
      )}
      renderPreviewContent={() => (
        <>
          <SeverityRating ratings={ratings} severity={severity} contentOnly />
          <ContextItem icon={<AccountQuestionIcon />} content={status} />
          <ContextItem icon={<CalendarQuestionIcon />} content={conditionOnsetDate.date} />
          <ContextItem icon={<CalendarEditIcon />} content={conditionReportedDate.date} />
          <ContextItem icon={<CalendarRemoveIcon />} content={conditionEndDate.date} />
          <ContextNoteItem content={notes} />
        </>
      )}
    />
  );
};

export default ConditionListItem;
