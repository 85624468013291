import React, { useState } from "react";
import { MenuItem, Typography, CircularProgress } from "@material-ui/core";
import { isEmpty, includes } from "lodash";

import { Select } from "components/inputs";
import UploadButton from "./upload-button";

const FileSelectInput = ({
  options,
  onRemove,
  onFileUpload,
  onFileSelect,
  value,
  error,
  touched,
  label,
  icon,
  loading,
  onOpen,
  acceptTypes,
  onLoadMore,
  counts,
  total,
  loadingMore,
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    const closeEvent = ["escapeKeyDown", "backdropClick", "tabKeyDown"];
    if (includes(closeEvent, reason)) {
      setOpen(false);
    }
  };

  const menuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
    onClose: handleClose,
  };

  const handleOnOpen = () => {
    if (onOpen) {
      Promise.resolve(onOpen()).then(() => setOpen(true));
    } else {
      setOpen(true);
    }
  };

  const onChange = event => {
    onFileSelect(event);

    if (event.target?.value) {
      setOpen(false);
    }
  };

  const renderExtraOptions = () => {
    if (isEmpty(options)) return null;

    return (
      <MenuItem
        component="button"
        className="flex justify-center items-center w-full"
        onClick={onLoadMore}
        disabled={counts >= total || loadingMore}
      >
        {total ? (
          <div className="absolute left-0 ml-16">
            <Typography variant="caption">{`${counts}/${total}`}</Typography>
          </div>
        ) : <div />}
        {loadingMore ? <CircularProgress size={20} /> : <Typography>Load More</Typography>}
      </MenuItem>
    );
  };

  return (
    <Select
      icon={icon}
      label={label}
      error={error}
      touched={touched}
      value={value}
      options={options}
      onClearSelected={onRemove}
      onChange={onChange}
      endAdornment={<UploadButton disabled={loading} onChange={onFileUpload} acceptTypes={acceptTypes} />}
      onOpen={handleOnOpen}
      open={open}
      extraOptions={renderExtraOptions()}
      MenuProps={menuProps}
    />
  );
};

export default FileSelectInput;
