import { ApiActions } from "middleware/call-api";

import formatSearchParams from "helpers/format-search-params";
import { ActionTypes, bailOutOfLoadingCandidates } from "../reducers/candidateWorklist.reducers";

export const searchCandidates = (searchParams, pageNumber = 1, pageSize = 20, forceLoad = false) => (dispatch, getState) => {
  const params = formatSearchParams(searchParams);
  const url = `/candidates?pageNumber=${pageNumber}&pageSize=${pageSize}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: !forceLoad && bailOutOfLoadingCandidates(getState(), pageNumber),
      types: [
        ActionTypes.SEARCHING_CANDIDATES,
        ActionTypes.SEARCHED_CANDIDATES,
        ActionTypes.ERROR_SEARCHING_CANDIDATES,
      ],
      pageNumber,
    },
  });
};
