import React from "react";
import { Field } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Typography } from "@material-ui/core";

import Form from "components/form";
import { NoteInput } from "components/inputs/text-input";
import { RequestInfoIcon } from "helpers/icon-finder";

const reasonLabel = "Reason";

const schema = Yup.object().shape({
  applicationStatus: Yup.object().shape({
    reason: Yup.string()
      .max(500, "Too Long!")
      .nullable(),
  }),
});

const UpdateApplicationStatusForm = ({
  applicationId,
  onSucceed,
  changeStatus,
  submitLabel,
  onSubmit,
  helperText,
  orgUnitId,
  ...other
}) => {
  const dispatch = useDispatch();
  const { applicationStatus } = schema.cast();

  const handleSubmit = (data, { setErrors }) => {
    dispatch(onSubmit(applicationId, data.applicationStatus, orgUnitId)).then(response => {
      if (response.error !== true) {
        onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ applicationStatus }}
      validationSchema={schema}
      contentProps={{ ...other, submitLabel: submitLabel || "Submit" }}
      content={() => (
        <>
          {helperText && <Typography className="ml-24">{helperText}</Typography>}
          <Field
            name="applicationStatus.reason"
            component={NoteInput}
            label={reasonLabel}
            icon={<RequestInfoIcon />}
            maxLength={500}
          />
        </>
      )}
    />
  );
};

export default UpdateApplicationStatusForm;
