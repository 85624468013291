import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, findIndex, size, includes } from "lodash";
import { Typography, IconButton, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DownloadIcon from "mdi-react/DownloadOutlineIcon";
import PDFIcon from "mdi-react/FilePdfBoxOutlineIcon";

import { getCurrentReferralAttachmentFiles } from "app/main/referrals/reducers/referrals.reducers";
import DialogPopup from "components/items/dialog-popup";
import DefaultButton from "components/items/default-button";
import LoadingState from "components/items/loading-state";
import downloadFromApi, { openPdf } from "utils/download-from-api";
import { fetchPreviewAttachment } from "app/main/referrals/actions/referrals.actions";
import { setDialogOptions, closeDialog } from "app/store/actions/dialog.actions";
import PreviewContent from "components/items/preview-content";
import { EmptyStateComponent } from "components/items/pdf-preview";

const useStyles = makeStyles(() => ({
  imageStyle: {
    minHeight: "100%",
    backgroundSize: "contain",
  },
  content: {
    height: "80vh",
  },
}));

const downloadAttachment = (fileId, referralId) => {
  downloadFromApi(`api/referrals/download?fileId=${fileId}&referralId=${referralId}`);
};

const ReferralAttachmentPreviewDialog = ({
  referralId,
  referralDocumentId,
  file,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const contentRef = useRef();
  const currentAttachmentFiles = useSelector(state => getCurrentReferralAttachmentFiles(state, referralId, referralDocumentId));

  const [currentFile, setCurrentFile] = useState(file);
  const [loading, setLoading] = useState(true);
  const [attachment, setAttachment] = useState(); // the attachment with their preview content
  const { hasPreview, fileScanStatus, id, fileName } = currentFile;

  const currentIndex = findIndex(currentAttachmentFiles, x => x.id === currentFile.id);
  const totalAttachments = size(currentAttachmentFiles);
  const isFirst = currentIndex === 0;
  const isLast = currentIndex + 1 === totalAttachments;
  const isPDF = includes(currentFile.mimeType, "pdf");

  const fetchPreview = (fileId, scanStatus, preview) => {
    if (scanStatus === "Clean" && preview) {
      dispatch(fetchPreviewAttachment(referralId, referralDocumentId, fileId)).then(response => {
        setLoading(false);
        if (!response.error) {
          setAttachment(response.payload);
        }
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPreview(id, fileScanStatus, hasPreview);
    dispatch(setDialogOptions({ maxWidth: "md" }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPreviousFile = () => {
    const previousFile = currentAttachmentFiles[currentIndex - 1];
    setLoading(true);
    fetchPreview(previousFile.id, previousFile.fileScanStatus, previousFile.hasPreview);
    setCurrentFile(previousFile);
  };

  const getNextFile = () => {
    const nextFile = currentAttachmentFiles[currentIndex + 1];
    setLoading(true);
    fetchPreview(nextFile.id, nextFile.fileScanStatus, nextFile.hasPreview);
    setCurrentFile(nextFile);
  };

  const Content = () => {
    if (loading) {
      return <LoadingState />;
    }

    if (fileScanStatus === "Clean") {
      return (
        <PreviewContent
          fileData={attachment?.base64File || attachment?.htmlPreview}
          mimeType={attachment?.mimeType}
          pdfProps={{ width: get(contentRef, ["current", "clientWidth"], 0) }}
          imageProps={{ classes: { root: classes.imageStyle } }}
          hasPreview={hasPreview}
        />
      );
    }

    if (fileScanStatus === "Pending") {
      return <EmptyStateComponent title="Malware scanning pending. Please check back again later." />;
    }

    if (fileScanStatus === "VirusDetected") {
      return <EmptyStateComponent title="Potential malicious content detected. File not available for download." />;
    }

    return null;
  };

  return (
    <DialogPopup
      classes={{ content: classes.content }}
      title={fileName}
      content={<div ref={contentRef} className="w-full h-full"><Content /></div>}
      renderHeaderActions={(
        <>
          <Typography className="mr-8" variant="caption">Attachment</Typography>
          <Typography className="mr-8" variant="caption">{`${currentIndex + 1} / ${totalAttachments}`}</Typography>
          <IconButton color="inherit" disabled={isFirst} onClick={() => getPreviousFile()}><Icon fontSize="small">arrow_back</Icon></IconButton>
          <IconButton color="inherit" disabled={isLast} onClick={() => getNextFile()}><Icon fontSize="small">arrow_forward</Icon></IconButton>
        </>
      )}
      renderActions={(
        <div className="flex items-center justify-between flex-1">
          {fileScanStatus === "Clean"
            && (
              <div className="flex">
                {isPDF && (
                  <DefaultButton
                    className="mr-8"
                    color="secondary"
                    onClick={() => {
                      openPdf(`api/referrals/download?fileId=${id}&referralId=${referralId}`);
                    }}
                    label="Open in browser"
                    size="medium"
                    icon={<PDFIcon />}
                  />
                )}
                <DefaultButton onClick={() => downloadAttachment(id, referralId)} label="Download" size="medium" icon={<DownloadIcon />} />
              </div>
            )}
          <DefaultButton color="default" onClick={() => dispatch(closeDialog())} label="Close" size="medium" variant="text" />
        </div>
      )}
    />
  );
};

export default ReferralAttachmentPreviewDialog;
