import React from "react";
import { Typography, Hidden, Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
import AccountDetailsIcon from "mdi-react/AccountDetailsIcon";

import TextLink from "components/items/text-link";
import PopupMenu from "components/items/popup-menu";
import DefaultButton from "components/items/default-button";

const useStyles = makeStyles(() => ({
  contentContainer: {
    flex: "1 1 auto",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    width: "100%",
  },
}));

export const NameLabel = ({ name, ...other }) => <TextLink variant="h6" content={name} {...other} />;

export const TextLabel = ({ content, ...other }) => {
  if (isEmpty(content)) return null;
  return (
    <Typography color="textSecondary" {...other}>{content}</Typography>
  );
};

const PatientDetails = ({
  renderLeftContent,
  renderContent,
  renderExtraContent,
  renderRightContent,
}) => {
  const classes = useStyles();
  const ExtraDetails = () => renderExtraContent;

  return (
    <div className="flex flex-1 items-center">
      {/* avatar */}
      {renderLeftContent && (
        <Hidden smDown>
          <div className="px-24 justify-center items-center">
            {renderLeftContent}
          </div>
        </Hidden>
      )}
      {/* details */}
      <div className={classes.contentContainer}>
        {renderContent && renderContent}
        {renderExtraContent && (
          <>
            <Hidden smDown>
              <ExtraDetails />
            </Hidden>
            <Hidden mdUp>
              <PopupMenu
                trigger={popupState => (
                  <div>
                    <DefaultButton
                      {...popupState}
                      variant="text"
                      label="Details"
                      icon={<AccountDetailsIcon />}
                    />
                  </div>
                )}
                content={({ close, ...other }) => (
                  <Popover
                    {...other}
                  >
                    <div className="pt-8 px-16 pb-16">
                      <ExtraDetails />
                    </div>
                  </Popover>
                )}
              />
            </Hidden>
          </>
        )}
      </div>
      {renderRightContent && <div className={classes.rightContent}>{renderRightContent}</div>}
    </div>
  );
};

export default PatientDetails;

export { default as AddressDetailsLabel } from "./details/address-details-label";
export { default as PatientAgeLabel } from "./details/age-label";
export { default as AssignedUser } from "./details/assigned-user";
export { default as PatientConsentStatus } from "./details/consent-status";
export { default as NextOfKin } from "./details/next-of-kin";
export { default as ContactDetailsLabel } from "./details/contact-details-label";
export { default as EmailLabel } from "./details/email-label";
export { default as PatientIdentifierLabel } from "./details/patient-identifier-label";
export { default as PatientSummary } from "./details/summary";
export { default as PatientSexLabel } from "./details/sex-label";
