/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Drawer, Grid, Tab, Tabs, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme, fade } from "@material-ui/core/styles";
import { map, compact, isEmpty, find } from "lodash";
import { useHistory, useLocation } from "react-router-dom";

import PatientHeader from "app/main/patients/components/patient-header";
import ChatPanel from "components/items/chat-panel";
import { getCurrentPatient, getPatientsSummaryById } from "app/main/patients/reducers/patients.reducers";
import { getExistingPatientById } from "app/main/patients/actions/patients.actions";
import { fetchPatientAssessments } from "app/main/patients/actions/assessments.actions";
import { InterventionIcon, ProblemIcon, EncounterIcon, ProcedureIcon, AllergyIcon, AssessmentIcon, SummaryIcon, ImmunisationIcon, ConditionIcon, MedicationIcon, ContactsIcon, BookmarkIcon, AppointmentIcon, PatientDetailsIcon, CardsIcon } from "helpers/icon-finder";
import withPermissions from "permissions/withPermissions";
import PatientSummaryHeader from "app/main/patients/components/patient-summary-header";
import AppContext from "app/AppContext";

import PatientSummaryPage from "app/main/patients/pages/patient/patient-summary-page";
import PatientEncounterPage from "app/main/patients/pages/patient/patient-encounter-page";
import PatientProblemPage from "app/main/patients/pages/patient/patient-problem-page";
import PatientInterventionPage from "app/main/patients/pages/patient/patient-intervention-page";
import PatientProceduresPage from "app/main/patients/pages/patient/patient-procedures-page";
import PatientAssessmentsPage from "app/main/patients/pages/patient/patient-assessments-page";
import PatientAllergyPage from "app/main/patients/pages/patient/patient-allergy-page";
import PatientImmunisationPage from "app/main/patients/pages/patient/patient-immunisation-page";
import PatientConditionPage from "app/main/patients/pages/patient/patient-condition-page";
import PatientMedicationsPage from "app/main/patients/pages/patient/patient-medication-page";
import PatientContactPage from "app/main/patients/pages/patient/patient-contact-page";
import PatientAppointmentPage from "app/main/patients/pages/patient/patient-appointment-page";
import PatientReferralsPage from "app/main/patients/pages/patient/patient-referrals-page";
import PatientDetailsPage from "app/main/patients/pages/patient/patient-details-page";
import PatientCardIdentifierPage from "app/main/patients/pages/patient/patient-card-identifier-page";

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 72,
    position: "relative",
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("lg")]: {
      width: 250,
    },
  },
  listItem: {
    minHeight: 72,
    minWidth: "auto",
  },
  wrapper: {
    flexDirection: "row",
    justifyContent: "flex-start",
    textAlign: "left",
    "& > svg": {
      display: "flex",
      justifyContent: "center",
      marginRight: 0,
      flex: "1 1 auto",
      minWidth: "auto",
      margin: 0,
      [theme.breakpoints.up("lg")]: {
        flex: "0 0 auto",
        justifyContent: "flex-start",
        minWidth: 56,
      },
    },
  },
  selectedListItem: {
    backgroundColor: fade(theme.palette.primary[200], 0.2),
  },
}));

const PatientRecordContent = ({
  patientId,
  routePrefix,
  hasPermissionEncountersView,
  hasPermissionContactsView,
  hasPermissionInterventionsView,
  hasPermissionProblemsView,
  hasPermissionProceduresView,
  hasPermissionImmunisationsView,
  hasPermissionAllergiesView,
  hasPermissionConditionsView,
  hasPermissionMedicationsView,
  hasPermissionAssessmentsView,
  hasPermissionReferralsView,
  hasPermissionPatientRecordAppointmentsView,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const tab = query.get("tab");

  const [index, setIndex] = useState(0);
  const displayLabel = useMediaQuery(theme.breakpoints.up("lg"));
  const patient = useSelector(getCurrentPatient);
  const summary = useSelector(state => getPatientsSummaryById(state, patient.patientId));

  const appContext = useContext(AppContext);
  const { setBackURL } = appContext;

  let routes = compact([
    { label: "Overview", icon: <SummaryIcon />, value: "summary", component: props => <PatientSummaryPage {...props} /> },
    { label: "Details", icon: <PatientDetailsIcon />, value: "details", component: props => <PatientDetailsPage {...props} /> },
    { label: "Identifiers & Cards", icon: <CardsIcon />, value: "cards-identifiers", component: props => <PatientCardIdentifierPage {...props} /> },
    hasPermissionEncountersView && { label: "Encounters", icon: <EncounterIcon />, value: "encounters", component: props => <PatientEncounterPage {...props} /> },
    hasPermissionPatientRecordAppointmentsView && { label: "Appointments", icon: <AppointmentIcon />, value: "appointments", component: props => <PatientAppointmentPage {...props} /> },
    hasPermissionContactsView && { label: "Contacts", icon: <ContactsIcon />, value: "contacts", component: props => <PatientContactPage {...props} /> },
    hasPermissionInterventionsView && { label: "Interventions", icon: <InterventionIcon />, value: "interventions", component: props => <PatientInterventionPage {...props} /> },
    hasPermissionProblemsView && { label: "Problems", icon: <ProblemIcon />, value: "problems", component: props => <PatientProblemPage {...props} /> },
    hasPermissionProceduresView && { label: "Procedures", icon: <ProcedureIcon />, value: "procedures", component: props => <PatientProceduresPage {...props} /> },
    hasPermissionImmunisationsView && { label: "Immunisations", icon: <ImmunisationIcon />, value: "immunisations", component: props => <PatientImmunisationPage {...props} /> },
    hasPermissionAllergiesView && { label: "Allergies", icon: <AllergyIcon />, value: "allergies", component: props => <PatientAllergyPage {...props} /> },
    hasPermissionConditionsView && { label: "Medical Conditions", icon: <ConditionIcon />, value: "conditions", component: props => <PatientConditionPage {...props} /> },
    hasPermissionMedicationsView && { label: "Medications", icon: <MedicationIcon />, value: "medications", component: props => <PatientMedicationsPage {...props} /> },
    hasPermissionAssessmentsView && { label: "Assessments", icon: <AssessmentIcon />, value: "assessments", component: props => <PatientAssessmentsPage {...props} /> },
    hasPermissionReferralsView && { label: "Referrals", icon: <BookmarkIcon />, value: "referrals", component: props => <PatientReferralsPage {...props} /> },
  ]);

  routes = map(routes, (r, key) => ({ ...r, key }));
  const selectedItem = routes[index];

  useEffect(() => {
    const forceLoad = patient.patientId !== patientId || location.state?.forceLoad === true;
    dispatch(getExistingPatientById(patientId, forceLoad));

    if (hasPermissionAssessmentsView) {
      dispatch(fetchPatientAssessments(patientId));
    }
  }, [patientId]);

  useEffect(() => {
    if (tab) {
      const selectedTab = find(routes, x => x.value === tab);
      setIndex(selectedTab ? selectedTab?.key : 0);
    }
  }, [tab]);

  useEffect(() => {
    if (location.fromUrl) {
      setBackURL(location.fromUrl);
    } else if (routePrefix) {
      setBackURL(routePrefix);
    }
  }, []);

  const renderHeader = () => (
    <Grid container className="py-24 px-16">
      <PatientHeader patient={patient} summary={summary} />
    </Grid>
  );

  const handleTabChange = (_event, val) => {
    setIndex(val);
    query.delete("tab");
    history.replace({
      search: query.toString(),
    });
  };

  const renderStaticSideBar = () => (
    <Drawer
      className="mr-8"
      variant="permanent"
      classes={{ paper: classes.drawer }}
      ModalProps={{
        keepMounted: true,
        disablePortal: true,
        BackdropProps: { classes: { root: "absolute" } },
      }}
    >
      <Tabs
        orientation="vertical"
        classes={{ scroller: "overflow-y-auto overflow-x-hidden flex-1" }}
        value={index}
        onChange={handleTabChange}
      >
        {map(routes, item => (
          <Tab
            key={item.key}
            icon={item.icon}
            label={displayLabel ? item.label : null}
            classes={{ root: classes.listItem, wrapper: classes.wrapper, selected: classes.selectedListItem }}
          />
        ))}
      </Tabs>
    </Drawer>
  );

  const renderContent = () => map(routes, x => {
    if (x.key !== index) return undefined;
    return <React.Fragment key={x.key}>{x.component({ patientId })}</React.Fragment>;
  });

  return (
    <>
      <PatientSummaryHeader patient={patient} />
      <ChatPanel
        headerElevation={0}
        empty={isEmpty(selectedItem)}
        renderContent={() => renderContent()}
        renderHeader={() => renderHeader()}
        renderStaticSideBar={renderStaticSideBar}
      />
    </>
  );
};

const mapStateToProps = state => {
  const patient = getCurrentPatient(state);

  return {
    patient,
    summary: getPatientsSummaryById(state, patient.patientId),
  };
};

export default withPermissions(
  "AllergiesView",
  "ConditionsView",
  "ProblemsView",
  "InterventionsView",
  "ProceduresView",
  "ImmunisationsView",
  "MedicationsView",
  "ContactsView",
  "EncountersView",
  "AssessmentsView",
  "ReferralsView",
  "AppointmentsView",
  "PatientRecordAppointmentsView",
)(connect(mapStateToProps, {})(PatientRecordContent));
