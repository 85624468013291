import React from "react";
import PropTypes from "prop-types";
import MultipleActionButtons, { ActionButton, TriggerButton } from "components/items/multiple-action-buttons";

const EncounterFab = ({
  patientId,
  onScheduleAppointment,
  onRecordEncounter,
}) => {
  if (!onScheduleAppointment && !onRecordEncounter) return null;

  return (
    <MultipleActionButtons
      renderTrigger={triggerProps => <TriggerButton {...triggerProps} label="Add Encounter" />}
    >
      {onScheduleAppointment && <ActionButton label="Schedule" icon="schedule" onClick={() => onScheduleAppointment(patientId)} />}
      {onRecordEncounter && <ActionButton label="Record" icon="add" onClick={() => onRecordEncounter(patientId)} />}
    </MultipleActionButtons>
  );
};

EncounterFab.propTypes = {
  onScheduleAppointment: PropTypes.func,
  onRecordEncounter: PropTypes.func,
};

export default EncounterFab;
