import React from "react";
import { ListItem, Box, Icon } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles, fade } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  listItem: {
    borderLeftWidth: theme.shape.borderRadius,
    borderLeftStyle: "solid",
    borderLeftColor: "transparent",
    transition: "border 0.5s ease, backgroundColor 0.2s",
    borderBottomWidth: 1,
    borderBottomColor: fade(theme.palette.divider, 0.1),
    borderBottomStyle: "solid",
    padding: theme.spacing(2, 1, 2, 1 * 1.5),
  },
  active: ({ color }) => ({
    borderLeftColor: color || theme.palette.primary.main,
    backgroundColor: fade(color || theme.palette.primary.main, 0.1),
  }),
}));

function DefaultListItem({ active, onClick, content, highlight, disabled, color, classes }) {
  const styles = useStyles({ color });

  return (
    <ListItem
      button
      className={clsx(styles.listItem, (active || highlight) && styles.active, classes?.root)}
      onClick={onClick}
      disabled={disabled}
    >
      <Box className="flex justify-between items-center w-full">
        <Box className="flex-auto">{content}</Box>
        <Box className="w-24">{active && <Icon color="disabled">keyboard_arrow_right</Icon>}</Box>
      </Box>
    </ListItem>
  );
}

export default DefaultListItem;
