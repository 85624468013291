import React from "react";
import { useDispatch } from "react-redux";
import { Field } from "formik";
import { Alert } from "@material-ui/lab";
import * as Yup from "yup";

import { NoteInput } from "components/inputs/text-input";
import { rejectReferralAction } from "app/main/referrals/actions/referrals.actions";
import Form from "components/form";
import CodeSetValueSelector from "app/main/codeSet/components/code-set-value-selector";

const rejectReasonCode = "Reason for Rejection";
const reason = "Details";

const schema = Yup.object().shape({
  referral: Yup.object().shape({
    rejectReasonCodeId: Yup.string()
      .required(`${rejectReasonCode} is required`)
      .nullable(),
    cancelReason: Yup.string()
      .max(2000, `${reason} exceeds maximum length of 2000 characters`)
      .nullable(),
  }),
});

const RejectReferralForm = ({
  referralId,
  message,
  onSucceed,
  ...other
}) => {
  const dispatch = useDispatch();

  const handleSubmit = ({ referral }, { setSubmitting, setErrors }) => {
    const rejectReferral = {
      referralId,
      rejectReasonCodeId: referral.rejectReasonCodeId.value,
      reason: referral.reason,
    };

    dispatch(rejectReferralAction(rejectReferral)).then(responds => {
      setSubmitting(false);
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  const initialValues = schema.cast();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      contentProps={other}
      variant="filled"
      content={() => (
        <>
          {message && <Alert severity="warning">{message}</Alert>}
          <Field
            name="referral.rejectReasonCodeId"
            component={CodeSetValueSelector}
            label={rejectReasonCode}
            icon="clear"
            codeSetTypeCode="ReasonForRejection"
            required
          />
          <Field
            name="referral.reason"
            component={NoteInput}
            label={reason}
            icon="message"
            maxLength={2000}
          />
        </>
      )}
    />
  );
};

export default RejectReferralForm;
