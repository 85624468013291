import React from "react";
import { Timeline } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const breakpoint = "md";

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 0,
    "& > *:not(:first-child)": {
      borderTop: `1px solid ${theme.palette.divider}`,
      paddingTop: theme.spacing(2),
      [theme.breakpoints.up(breakpoint)]: {
        borderTop: "none",
        paddingTop: 0,
      },
    },
  },
}));

const ActivityLog = ({
  children,
}) => {
  const classes = useStyles();
  return (
    <Timeline className={classes.root}>
      {children}
    </Timeline>
  );
};

export default ActivityLog;

export { default as ActivityLogItem } from "./item";
