import React from "react";
import PropTypes from "prop-types";

/**
 * @param {top} top logo to the top
 * @param {left} left logo to the left
 * @param {width} width the width of the logo, in general either width or height given would be enough
 * @param {height} heigh the height of the logo
 * @param {variant} variant can either be default or color, default value is "default" -> color white
 * @param {showText} showText whether to display "HealthCare Software" or not
 */
const HCSLogo = ({ variant, showText, width, height, top, left }) => {
  let textColor = "#6d6e6e";
  let logoColor = "#b0bc22";

  if (variant === "default") {
    logoColor = "#ffffff";
    textColor = "#ffffff";
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`-${left} -${top} 344 134`} width={width} height={height}>
      {showText && (
        <g fill={textColor}>
          <path d="M215.33,38.48H202.06l-2.24,10h-1.69l4.59-21h1.87l-2,9h12.71l2-9h2l-4.59,21h-1.65Z" />
          <path d="M228.17,33.18c4.84-.09,7,4.25,6.1,8.3h-12.6c-1.29,5.17,6,8,9.9,4.4l.6,1.2C219,55.45,214.83,34.16,228.17,33.18Zm4.8,6.6c.06-7.33-10.51-5.85-11.2.7h11.1A1.7,1.7,0,0,0,233,39.78Z" />
          <path d="M250.51,33.48h1.61l-3.32,15h-1.61l.5-2.31c-3.23,4.6-11.3,2.87-11-3.53C236.1,35,245.44,29.41,250,36Zm-7.44,13.67c5.5.17,5.84-6.3,6.7-10.1C243.31,27.26,232.21,45.5,243.07,47.15Z" />
          <path d="M257.8,27.48h1.63l-4.59,21h-1.63Z" />
          <path d="M260.27,45.38l2.3-10.9h-2.5l.3-1h2.53l.88-4h1.56l-.88,4h3l-.3,1h-3l-2.3,10.7c-.71,2,1.78,2.53,3,1.4l.3,1.3c-1.73,1.33-5.25.76-5.1-1.8C260.17,46,260.17,45.68,260.27,45.38Z" />
          <path d="M279.58,38.19c1.37-5.35-6.23-4.12-8.36-1.13l-2.55,11.42H267l4.58-21h1.63l-1.73,8.06c2.3-3,9.8-4.23,9.89,1,.08.39-2.42,11.19-2.55,11.92h-1.63Z" />
          <path d="M296,27.35a8.93,8.93,0,0,1,8.1,4.4l-1.7.8c-4.92-7.6-16.13-2.46-15.9,7.1-.31,7,8.33,9.74,13.2,5.1l1.3,1C283.13,58.29,277.94,28.17,296,27.35Z" />
          <path d="M317.81,33.48h1.61l-3.32,15h-1.61l.5-2.31c-3.23,4.6-11.3,2.87-11-3.53C303.4,35,312.74,29.41,317.31,36Zm-7.54,13.67c5.5.17,5.84-6.3,6.7-10.1C310.51,27.26,299.41,45.5,310.27,47.15Z" />
          <path d="M323.85,33.48h1.62l-.6,2.43a6.62,6.62,0,0,1,5.67-2.73l-.4,1.72c-7-1.46-6.77,9.56-8.11,13.58h-1.62Z" />
          <path d="M338.57,33.18c4.84-.09,7,4.25,6.1,8.3h-12.6c-1.29,5.17,6,8,9.9,4.4l.6,1.2C329.35,55.45,325.31,34.19,338.57,33.18Zm4.8,6.6c.06-7.33-10.51-5.85-11.2.7h11.1A1.7,1.7,0,0,0,343.37,39.78Z" />
          <path d="M193.47,67.65c2.23,4.43,12.29,4.38,11.9-1.2-.44-4.88-10.44-3.85-10.2-9.5-.19-6.5,11.2-7.33,14-2.5l-1.3,1.2c-2.24-3.88-10.56-3.72-10.8,1.1.55,4.48,10.67,3.85,10.3,9.4.68,7.11-12.29,8.1-15.2,2.7Z" />
          <path d="M218.37,56.85c10.42-.06,7.17,16-1.6,15.6C206.4,72.53,209.53,56.42,218.37,56.85Zm-.1,1.4c-6.51-.44-9.55,12.67-1.6,12.7C223.13,71.39,226.35,58.32,218.27,58.25Z" />
          <path d="M230.2,58.48h-2.53l.3-1h2.5c.2-4.13,3.34-7.78,7.2-5.6l-.7,1.2c-3-1.66-4.74,1.54-4.9,4.4h3l-.3,1h-2.91l-3.11,14h-1.66Z" />
          <path d="M235.17,69.18l2.3-10.7H235l.3-1h2.53l.88-4h1.56l-.88,4h3l-.3,1h-3c.26,1.79-5.61,15.74.7,11.9l.3,1.3c-1.73,1.33-5.25.76-5.1-1.8C235.07,69.88,235.17,69.48,235.17,69.18Z" />
          <path d="M253.57,59.38l-7,13.1h-1.49l-1.62-15h2.2l1,11.84,6.57-11.84h1.32l1.31,11.84,6.39-11.84H264l-7.61,14H255Z" />
          <path d="M278.11,57.48h1.61l-3.32,15h-1.61l.5-2.31c-3.23,4.6-11.3,2.87-11-3.53C263.7,59,273,53.41,277.61,60Zm-7.54,13.57c5.5.17,5.84-6.3,6.7-10.1C270.81,51.16,259.71,69.4,270.57,71.05Z" />
          <path d="M284.06,57.48h1.61l-.6,2.42a6.58,6.58,0,0,1,5.64-2.72l-.41,1.71c-7.12-1.38-6.55,9.5-7.85,13.59h-1.61Z" />
          <path d="M298.87,57.18c4.84-.09,7,4.25,6.1,8.3h-12.6c-1.29,5.17,6,8,9.9,4.4l.6,1.2C289.65,79.45,285.61,58.19,298.87,57.18Zm4.8,6.6c.06-7.33-10.51-5.85-11.2.7h11.1A1.7,1.7,0,0,0,303.67,63.78Z" />
        </g>
      )}
      <g fill={logoColor}>
        <path d="M39.73,66.26a2,2,0,0,1-1.11.3,1.93,1.93,0,0,1-1-.3,1.51,1.51,0,0,1-.7-1.4c.1-.7.3-1.51.4-2.21l6.62-26.59c.2-1,.4-1.7.5-2.3a15,15,0,0,0,.1-2.31,11.86,11.86,0,0,0-.5-3.11,3.26,3.26,0,0,0-2.1-1.51,11.37,11.37,0,0,0-2.21-.3q-5.11,0-11.44,4.52A41.7,41.7,0,0,0,17.76,41.48L13.84,46.7l9-40.23-17,2-.4,2h6.12a2,2,0,0,1,1.4.5,2.5,2.5,0,0,1,.5,2.21L0,72.48H7.93l3-12.54c1.5-6.42,4.91-12.74,9.83-18.86,4.91-5.92,9-8.93,12.24-8.93a3,3,0,0,1,1.6.5,2.37,2.37,0,0,1,.8,1,4.87,4.87,0,0,1,.2,1.5,13.71,13.71,0,0,1-.4,3.41L28.79,64.26c-.3,1.1-.5,2.2-.7,3.11s-.2,1.8-.2,2.6a4.46,4.46,0,0,0,.7,2.31,2,2,0,0,0,1.61,1,2.54,2.54,0,0,0,.7-.2l14.45-7.42L44.64,64Z" />
        <path d="M72.57,32.75a12.62,12.62,0,0,1,11.2,6.1l5.2-4c-3-5.1-8.4-8.3-16.6-8.3-14.1,0-25,12.1-24.9,26.8,0,12.5,8.4,19.9,20.4,19.9a21,21,0,0,0,15.8-6.8l-4-4.6a14.92,14.92,0,0,1-11.2,5.2c-7.8,0-13.9-4.7-13.9-13.8C54.57,42.55,61.47,32.75,72.57,32.75Z" />
        <path d="M109.57,46.25c-5-2-9.5-3.7-9.5-7.2,0-3.8,3.6-6.8,9.6-6.8,5.7,0,11.3,2.9,13.3,6.2l4-4.5c-2.9-4.1-9.4-7.3-16.9-7.3-10.8,0-16.8,6.3-16.8,13.5,0,6.8,7.2,9.5,13.5,12,5.1,2,9.8,3.8,9.8,7.6,0,4-3.9,7.8-10.2,7.8-6.6,0-12.4-3.4-14.8-6.9l-4.4,4.8c4.1,5,10.8,7.8,18.8,7.8,11.2,0,17.5-6.9,17.5-14.5C123.47,51.45,116,48.65,109.57,46.25Z" />
        <path d="M269.34,0c-47,1.43-80.34,17.64-103.17,38.12a63.62,63.62,0,0,0-17.65-24.63A63.66,63.66,0,0,0,125.64.5l-.8,3a1.58,1.58,0,0,1,.4.1,63.78,63.78,0,0,1,23.28,14,60.21,60.21,0,0,1,15.22,22.78,138.88,138.88,0,0,0-28.11,37.18c-13.09,25.18-14.29,47.07-14.29,54.56v2.7l1.2-.1s-.1-.9-.1-2.6c0-7.29,1.3-29.08,14.19-54a138.44,138.44,0,0,1,27.54-36.57,73.61,73.61,0,0,1,4,24.88,108.5,108.5,0,0,1-2.7,23.48l3,.8a108.15,108.15,0,0,0,2.8-24.09,75.67,75.67,0,0,0-4.68-27.36C189.26,18.86,222.4,2.64,269.34,1.2Z" fill={logoColor} stroke={logoColor} strokeWidth="2" />
      </g>
    </svg>
  );
};

HCSLogo.propTypes = {
  variant: PropTypes.oneOf(["default", "color"]),
  showText: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  top: PropTypes.number,
  left: PropTypes.number,
};

HCSLogo.defaultProps = {
  variant: "default",
  showText: true,
  height: "100%",
  width: "100%",
  top: 0,
  left: 0,
};

export default HCSLogo;
