import { grey, red, yellow, orange, deepOrange } from "@material-ui/core/colors";

export default function getSeverityColour(severity) {
  switch (severity) {
    case "Minor":
      return {
        color: yellow[600],
      };
    case "Mild":
      return {
        color: yellow[600],
      };
    case "Moderate":
      return {
        color: orange[600],
      };
    case "Significant":
      return {
        color: deepOrange[600],
      };
    case "Severe":
      return {
        color: red[600],
      };
    default:
      return {
        color: grey[600],
      };
  }
}
