import store from "app/store";
import * as Actions from "app/store/actions";
import { getBaseUrl } from "utils/get-environment-variables";
import { getAccessToken } from "app/auth/msalConfig";

const baseUrl = getBaseUrl();

export const fetchFromApi = async apiUrl => {
  const token = await getAccessToken();

  return fetch(apiUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

const generateFileName = response => {
  let filename = "";

  const disposition = response.headers.get("content-disposition");
  if (disposition && disposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }

  return filename;
};

export const openPdf = async apiUrl => {
  let filename = "";

  const response = await fetchFromApi(`${baseUrl}${apiUrl}`);
  if (response.ok === false) {
    store.dispatch(Actions.showMessage({ message: response.statusText, variant: "error" }));
    return false;
  }
  filename = generateFileName(response);
  const blob = await response.blob();
  if (navigator.msSaveBlob) {
    return window.navigator.msSaveOrOpenBlob(blob, filename);
  }
  const fileURL = URL.createObjectURL(blob);
  return window.open(fileURL);
};

export default async function downloadFromApi(apiUrl) {
  // eslint-disable-next-line global-require
  const fileDownload = require("js-file-download");
  let filename = "";

  const response = await fetchFromApi(`${baseUrl}${apiUrl}`);
  if (response.ok === false) {
    store.dispatch(Actions.showMessage({ message: response.statusText, variant: "error" }));
    return response;
  }
  filename = generateFileName(response);
  const blob = await response.blob();
  fileDownload(blob, filename);
  return response;
}
