import { isEmpty } from "lodash";
import moment from "moment";
import { AcceptedDateFormats } from "./date";

export function parseDate(input) {
  if (!isEmpty(input)) {
    if (moment.isMoment(input)) {
      return input;
    }

    return moment(input, AcceptedDateFormats, true);
  }

  return null;
}

export default parseDate;

export function getBirthDate(dateOfBirth) {
  return parseDate(dateOfBirth);
}
