export default function formatAttachmentType(type) {
  switch (type) {
    case "Attachment":
      return "Attachment";
    case "ScannedDocument":
      return "Scanned Document";
    case "DiagnosticReport":
      return "Diagnostic Report";
    default:
      return null;
  }
}
