import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import { getRecentEncounters, getEncountersByPatientId, areEncountersLoading, getErrorMessage } from "app/main/patients/reducers/encounters.reducers";
import { fetchPatientEncounters, editExistingEncounter, recordNewEncounter, removeEncounter } from "app/main/patients/actions/encounters.actions";
import RecordEncounterForm from "app/main/patients/encounters/components/record-encounter-form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import withPermissions from "permissions/withPermissions";

class EncounterListContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    const { patientId } = this.props;
    if (patientId) {
      this.props.fetchPatientEncounters(patientId);
    }
    this.onRecordEncounter = this.onRecordEncounter.bind(this);
    this.onEditEncounter = this.onEditEncounter.bind(this);
  }

  onClose = () => this.props.closeDialog();

  openRecordEncounterForm = (title, isEdit) => {
    this.props.openDialog({
      children: <RecordEncounterForm title={title} onSucceed={this.onClose} isEdit={isEdit} patientId={this.props.patientId} />,
    });
  }

  onRecordEncounter = () => {
    this.props.recordNewEncounter(this.props.patientId, this.props.participantUserId);
    this.openRecordEncounterForm("Add Encounter");
  };

  onEditEncounter = (encounterId, title) => {
    this.props.editExistingEncounter(this.props.patientId, encounterId);
    this.openRecordEncounterForm(title, true);
  };

  onRemoveEncounter = encounterId => {
    this.props.removeEncounter(this.props.patientId, encounterId);
    this.onClose();
  };

  render() {
    const {
      loading,
      error,
      encounters,
      renderContent,
      hasPermissionEncountersCreate,
      hasPermissionEncountersUpdate,
      hasPermissionEncountersDelete,
    } = this.props;

    return (
      renderContent({
        encounters,
        onRecordEncounter: hasPermissionEncountersCreate ? this.onRecordEncounter : null,
        onEditEncounter: hasPermissionEncountersUpdate ? this.onEditEncounter : null,
        onRemoveEncounter: hasPermissionEncountersDelete ? this.onRemoveEncounter : null,
        isLoading: loading,
        isError: error,
        isEmpty: isEmpty(encounters),
      })
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { patientId } = ownProps;
  const encounters = ownProps.numberOfItems ? getRecentEncounters(state, patientId, ownProps.numberOfItems) : getEncountersByPatientId(state, patientId);

  return {
    encounters,
    error: getErrorMessage(state, patientId),
    loading: areEncountersLoading(state, patientId),
  };
};

export default withPermissions(
  "EncountersDelete",
  "EncountersUpdate",
  "EncountersCreate",
)(connect(
  mapStateToProps,
  {
    openDialog,
    closeDialog,
    fetchPatientEncounters,
    recordNewEncounter,
    editExistingEncounter,
    removeEncounter,
  },
)(EncounterListContainer));
