import React from "react";
import { connect } from "react-redux";
import { isEmpty, map, lowerCase } from "lodash";
import clsx from "clsx";
import { Hidden, Typography } from "@material-ui/core";
import PrescriptionIcon from "mdi-react/PrescriptionIcon";

import DefaultPanelLayout from "components/items/default-panel-layout";
import EmptyState, { NoneRecordedState } from "components/items/empty-state";
import ExpiredPrescriptionForm from "app/main/applications/components/expired-prescription-form";
import { ApplicationItemWithWidth } from "app/main/applications/components/application-item";
import ApplicationPanelContent from "app/main/applications/components/application-panel-content";
import formatDate from "helpers/format-date";
import ApplicationNoteContent from "app/main/applications/components/application-note-content";
import { showMessage } from "app/store/actions/message.actions";
import { onRemoveExpiredPrescriptionPresentation } from "app/main/applications/actions/applications.actions";
import { getHelperTextBySectionName } from "app/auth/store/reducers/system-configuration";
import ApplicationRemoveButton from "./application-remove-button";
import ApplicationEditButton from "./application-edit-button";

const sectionTitle = plural => `Expired Approved Prescription Presentation${plural ? "s" : ""}`;

class ExpiredPrescriptionPanel extends React.Component {
  onClose = () => this.props.closeDialog();

  onSucceed = () => {
    this.onClose();
  }

  openExpiredPrescriptionForm = prescription => {
    const isNew = !prescription;

    this.props.openDialog({
      children: (
        <ExpiredPrescriptionForm
          onSucceed={this.onSucceed}
          title={`${isNew ? "New" : "Edit"} ${sectionTitle()}`}
          prescription={prescription}
          {...this.props}
        />
      ),
    });
  };

  onRemove = prescriptionId => {
    const { id: applicationId, orgUnitId } = this.props;
    const title = lowerCase(sectionTitle());
    this.props.onRemoveExpiredPrescriptionPresentation(applicationId, prescriptionId, orgUnitId).then(response => {
      if (response.error === true) {
        this.props.showMessage({ message: response.payload.exceptionMessage || `Error occurred while deleting the ${title}`, variant: "error" });
      } else {
        this.props.showMessage({ message: `Successfully removed the ${title}`, variant: "success" });
      }
    });
  };

  render() {
    const { expiredPrescriptionPresentations, canView, canEdit, helperText } = this.props;
    const EditActions = ({ item }) => (
      canEdit
      && (
      <>
        <ApplicationRemoveButton onClick={() => this.onRemove(item.id)} />
        <ApplicationEditButton onClick={() => this.openExpiredPrescriptionForm(item)} />
      </>
      )
    );

    return (
      <DefaultPanelLayout
        title={sectionTitle(true)}
        icon={<PrescriptionIcon />}
        isEmpty={isEmpty(expiredPrescriptionPresentations)}
        accessDenied={!canView}
        emptyState={canEdit
          ? <EmptyState icon={<PrescriptionIcon />} subtitle={`Add ${lowerCase(sectionTitle())}`} onClick={() => this.openExpiredPrescriptionForm()} />
          : <NoneRecordedState />}
        headerActions={canEdit
          && [
            {
              label: "Add",
              icon: "add",
              onClick: () => this.openExpiredPrescriptionForm(),
            },
          ]}
        subtitle={<Typography variant="caption" color="textSecondary">{helperText.expiredPrescriptionPresentationPanel}</Typography>}
      >
        {map(expiredPrescriptionPresentations, (x, index) => {
          const { canEdit: canEditThisExpiredPrescriptionPresentations } = x;

          return (
            <div key={x.id} className={clsx(index !== 0 && "mt-16 with-horizontal-divider")}>
              {canEditThisExpiredPrescriptionPresentations
              && (
                <Hidden mdUp>
                  <div className="flex justify-end mb-8"><EditActions item={x} /></div>
                </Hidden>
              )}
              <div className="flex justify-between items-center">
                <div className="flex-1">
                  <ApplicationPanelContent>
                    <ApplicationItemWithWidth icon="date_range" label="Date" content={formatDate(x.presentationDate)} />
                    <ApplicationItemWithWidth icon="person" label="Pharmacist" content={x.pharmacistName} />
                  </ApplicationPanelContent>
                </div>
                {canEditThisExpiredPrescriptionPresentations
                && (
                  <Hidden smDown>
                    <EditActions item={x} />
                  </Hidden>
                )}
              </div>
              <div className="mt-16">
                <ApplicationNoteContent>{x.notes}</ApplicationNoteContent>
              </div>
            </div>
          );
        })}
      </DefaultPanelLayout>
    );
  }
}

const mapStateToProps = state => ({
  helperText: getHelperTextBySectionName(state, "expiredPrescriptionPresentation"),
});

export default connect(
  mapStateToProps,
  {
    showMessage,
    onRemoveExpiredPrescriptionPresentation,
  },
)(ExpiredPrescriptionPanel);
