import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { getContactsByPatientId, areContactsLoading, getErrorMessage } from "app/main/patients/reducers/contacts.reducers";
import { fetchPatientContacts, editExistingContact, recordNewContact, removeContact, fetchContactsById } from "app/main/patients/actions/contacts.actions";
import PatientContactForm from "app/main/patients/components/patient-contact-form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import withPermissions from "permissions/withPermissions";

const ContactListContainer = ({
  error,
  loading,
  contacts,
  patientId,
  renderContent,
  hasPermissionContactsCreate,
  hasPermissionContactsUpdate,
  hasPermissionContactsDelete,
}) => {
  const dispatch = useDispatch();
  const onClose = () => dispatch(closeDialog());

  useEffect(() => {
    if (patientId) {
      dispatch(fetchPatientContacts(patientId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showDialog = title => {
    dispatch(openDialog({
      children: (
        <PatientContactForm
          onSucceed={onClose}
          title={title}
          patientId={patientId}
        />
      ),
    }));
  };

  const onRecordContact = () => {
    dispatch(recordNewContact());
    showDialog("Add Contact");
  };

  // eslint-disable-next-line no-shadow
  const onEditContact = contactId => {
    dispatch(editExistingContact(patientId, contactId));
    showDialog("Edit Contact");
  };

  // eslint-disable-next-line no-shadow
  const onRemoveContact = contactId => {
    dispatch(removeContact(patientId, contactId)).then(response => {
      if (response.error !== true) {
        onClose();
      }
    });
    onClose();
  };

  return (
    renderContent({
      contacts,
      onRecordContact: hasPermissionContactsCreate ? onRecordContact : null,
      onEditContact: hasPermissionContactsUpdate ? onEditContact : null,
      onRemoveContact: hasPermissionContactsDelete ? onRemoveContact : null,
      isLoading: loading,
      isError: error,
      isEmpty: isEmpty(contacts),
    })
  );
};

const mapStateToProps = (state, ownProps) => {
  const { patientId } = ownProps;
  const contacts = getContactsByPatientId(state, patientId);
  return {
    contacts,
    error: getErrorMessage(state, patientId),
    loading: areContactsLoading(state, patientId),
  };
};

export default withPermissions(
  "ContactsDelete",
  "ContactsUpdate",
  "ContactsCreate",
)(connect(
  mapStateToProps,
  {
    openDialog,
    closeDialog,
    fetchContactsById,
    fetchPatientContacts,
    recordNewContact,
    editExistingContact,
    removeContact,
  },
)(ContactListContainer));
