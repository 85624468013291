import React from "react";
import { Tabs, Tab, Hidden, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { map } from "lodash";
import clsx from "clsx";

import { actionPanel } from "components/styles/base";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  leftContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      borderBottomWidth: 0,
      maxWidth: `calc(100% - ${actionPanel.width}px)`,
    },
  },
  tabs: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  content: {
    padding: theme.spacing(0, 1),
    height: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tab: {
    position: "relative",
    fontWeight: 700,
    "&:after": {
      content: "''",
      width: 6,
      height: 6,
      borderRadius: 3,
      left: -8,
      top: 0,
      display: "block",
      position: "absolute",
      backgroundColor: theme.palette.primary.dark,
    },
  },
  tabLabel: {
    color: theme.palette.text.primary,
    fontWeight: 700,
  },
  actions: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    "& > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(1 / 2),
      },
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: actionPanel.width,
    },
  },
  tabsFlexContainer: {
    flexWrap: "wrap",
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
    },
  },
}));

export const DefaultTabBar = ({
  tabs,
  currentTab,
  onChange,
  rightContent,
  content,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.leftContent}>
          <Tabs
            value={currentTab}
            onChange={onChange}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: classes.tabs, flexContainer: classes.tabsFlexContainer }}
          >
            {map(tabs, (tab, index) => (
              <Tab
                key={index}
                disableRipple
                label={tab.label}
                classes={{ labelIcon: clsx(classes.tabLabel, tab.unread && classes.tab) }}
              />
            ))}
          </Tabs>
          {content && <Box className={classes.content}>{content}</Box>}
        </Box>
        {rightContent && (
          <Hidden xsDown>
            <div className={classes.actions}>{rightContent}</div>
          </Hidden>
        )}
      </Box>
      {rightContent && (
        <Hidden smUp>
          <Box className="my-8 w-full">
            <div className={classes.actions}>{rightContent}</div>
          </Box>
        </Hidden>
      )}
    </Box>
  );
};

export default DefaultTabBar;
