import React, { useState, useEffect } from "react";
import { Input, IconButton, Icon, ClickAwayListener, FilledInput } from "@material-ui/core";
import { LocalizationProvider, DesktopDatePicker as MuiDatePicker, DesktopDateTimePicker as MuiDateTimePicker } from "@material-ui/pickers";
import { isEmpty, get } from "lodash";
import MomentUtils from "@material-ui/pickers/adapter/moment";
import moment from "moment";

import { PreferredDateFormat, PreferredDateTimeFormat, DateInputDisplayFormat, DateTimeInputDisplayFormat } from "utils/date";
import BaseTextField from "components/inputs/components/base-text-field";
import { textFieldHook } from "components/inputs/components/useInput";

// knowing issue, invalidate date issue won't prevent form from submitting unless is define in upper level
// any error that being set here will get overwrite by upper level validation
const DateInputComponent = ({
  touched,
  error,
  name,
  inputComponent,
  component: InputComponent = inputComponent,
  disabled,
  onClear,
  label,
  displayValue,
  value,
  icon = "date_range",
  onClose,
  required,
  helperText,
  showIcon = true,
  ...other
}) => {
  const [open, setOpen] = useState(false);
  const inputStyles = textFieldHook.useFilledInputStyles();

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)} touchEvent={false}>
      <div className="flex-1">
        <LocalizationProvider dateAdapter={MomentUtils}>
          <BaseTextField
            label={label}
            error={error}
            touched={touched}
            helperText={helperText}
            value={displayValue}
            disabled={disabled}
            required={required}
            icon={showIcon && icon}
            showIcon={showIcon}
            inputComponent={({ formFieldVariant }) => {
              return (
                <InputComponent
                  open={open}
                  onClose={handleOnClose}
                  helperText=""
                  inputVariant={formFieldVariant === "filled" ? "filled" : "standard"}
                  value={value || null}
                  {...other}
                  renderInput={({ inputRef, inputProps }) => {
                    const onFocus = () => {
                      inputProps.onFocus();
                      handleOnClose();
                    };

                    const fieldInputProps = {
                      ref: inputRef,
                      onFocus,
                      endAdornment: (
                        <div className="flex items-center absolute right-0 bottom-0">
                          {onClear && value && (
                          <IconButton onClick={onClear}>
                            <Icon fontSize="small">clear</Icon>
                          </IconButton>
                          )}
                          <IconButton onClick={() => setOpen(!open)}>
                            <Icon fontSize="small">event</Icon>
                          </IconButton>
                        </div>
                      ),
                    };

                    return (
                      formFieldVariant === "filled"
                        ? <FilledInput disableUnderline classes={inputStyles} {...inputProps} {...fieldInputProps} />
                        : <Input {...inputProps} {...fieldInputProps} />
                    );
                  }}
                />
              );
            }}
          />
        </LocalizationProvider>
      </div>
    </ClickAwayListener>
  );
};

const DateInputField = ({
  isPast,
  isFuture,
  onChange,
  dateFormat,
  displayFormat,
  field: { name, value },
  form: { touched, errors, setFieldValue, setFieldTouched },
  maxDate = new Date("2099-12-31"),
  minDate = new Date("1900-01-01"),
  placeholder,
  ...other
}) => {
  const error = get(errors, name, null);
  const isTouched = get(touched, name, false);
  const [date, setDate] = useState();
  const [fieldError, setError] = useState(); // handle date picker inner error message

  // handle value for date picker (must be a validate date)
  useEffect(() => {
    setDate(value ? new Date(value) : null);
  }, [value]);

  const handleInputTouch = () => {
    if (!isTouched) {
      setFieldTouched(name);
    }
  };

  const handleError = dateError => {
    if (dateError) {
      let reason = dateError;
      switch (dateError) {
        case "invalidDate":
          reason = `Invalid date format, ${placeholder}`;
          break;
        case "disablePast":
          reason = "Values in the past are not allowed";
          break;
        case "maxDate":
          reason = `Date should not be after ${moment(maxDate).format("L")}`;
          break;
        case "minDate":
          reason = `Date should not be before ${moment(minDate).format("L")}`;
          break;
        case "disableFuture":
          reason = "Values in the future are not allowed";
          break;
        default:
          break;
      }

      setError(reason);
    } else {
      setError(null);
    }
  };

  const handleOnClear = () => {
    setDate(null);
    setFieldValue(name, null);
  };

  const handleDateChange = val => {
    handleInputTouch();
    const isValidDate = moment(val).isValid();

    let newDate = isValidDate || isEmpty(val) ? val : new Date(val);
    setDate(newDate);

    if (!val) {
      setFieldValue(name, null);
    }

    // only set to date string when date is validated
    if (isValidDate) {
      if (dateFormat) {
        newDate = moment(val).format(dateFormat);
      }
      setFieldValue(name, newDate);
    }

    if (onChange) {
      onChange(newDate, setFieldValue);
    }
  };

  return (
    <DateInputComponent
      error={fieldError || error}
      touched={isTouched}
      value={date}
      name={name}
      onChange={handleDateChange}
      onClose={handleInputTouch}
      disableFuture={isPast}
      disablePast={isFuture}
      onError={handleError}
      onClear={handleOnClear}
      displayValue={date ? moment(date).format(displayFormat) : null}
      placeholder={placeholder}
      {...other}
    />
  );
};

export const DatePicker = ({
  format = DateInputDisplayFormat, // input format
  displayFormat = PreferredDateFormat,
  dateFormat = "YYYY-MM-DD", // data format
  ...other
}) => (
  <DateInputField
    inputComponent={MuiDatePicker}
    inputFormat={format}
    placeholder={format}
    dateFormat={dateFormat}
    displayFormat={displayFormat}
    {...other}
  />
);

export const DateTimePicker = ({
  format = DateTimeInputDisplayFormat,
  displayFormat = PreferredDateTimeFormat,
  // dateFormat = "YYYY-MM-DD HH:mm",
  ...other
}) => (
  <DateInputField
    inputComponent={MuiDateTimePicker}
    inputFormat={format}
    placeholder={format}
    // dateFormat={dateFormat}
    displayFormat={displayFormat}
    ampm={false}
    {...other}
  />
);
