import React from "react";
import * as Yup from "yup";
import { Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { find, compact } from "lodash";

import Form from "components/form";
import PatientSelector from "app/main/patients/components/patient-selector";
import PatientForm from "app/main/patients/components/patient-form";
import AddReferralForm from "app/main/referrals/components/add-referral-form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { createNewPatient } from "app/main/patients/actions/patients.actions";
import { getAllPatients } from "app/main/patients/reducers/patients.reducers";
import { getSexOptions, getPrimaryPatientIdentifier } from "app/auth/store/reducers/system-configuration";
import { fetchPatientNOK } from "app/main/patients/actions/contacts.actions";
import { clearReferralSourceDocumentFiles } from "app/main/referrals/actions/referrals.actions";
import DefaultButton from "components/items/default-button";
import { getPatientLabel } from "utils/get-environment-variables";
import PatientSearchButton from "app/main/patients/components/external-patient-search-button";

const patientLabel = getPatientLabel();

export default ({
  sourceDocument,
  onSucceed,
  ...other
}) => {
  const dispatch = useDispatch();
  const patients = useSelector(getAllPatients);
  const sexOptions = useSelector(getSexOptions);
  const primaryPatientIdentifier = useSelector(getPrimaryPatientIdentifier);

  const translatePatientDetails = patient => (
    {
      ...patient,
      nextOfKin: patient.nextOfKin ?? null,
      sex: find(sexOptions, x => x.value === patient.sex || x.label === patient.sex) ?? null,
    }
  );

  const schema = Yup.object().shape({
    patient: Yup.string()
      .required("Enter Name, Identifier or Medicare card to search")
      .nullable(),
  });

  const onClose = () => dispatch(closeDialog());

  const onOpenReferralForm = data => {
    dispatch(clearReferralSourceDocumentFiles());
    dispatch(openDialog({
      maxWidth: "xl",
      children: (
        <AddReferralForm
          title="Add Referral Details"
          onCancel={onClose}
          onSucceed={props => {
            onClose();
            if (onSucceed) {
              onSucceed(props);
            }
          }}
          patient={translatePatientDetails(data)}
          sourceDocument={sourceDocument}
        />
      ),
    }));
  };

  const handleCreatePatient = ({ patient: newPatient }) => {
    onOpenReferralForm({
      ...newPatient,
      patientIdentifiers: compact([
        {
          identifier: newPatient.primaryPatientIdentifier,
          patientIdentifierTypeCode: primaryPatientIdentifier,
        },
        newPatient.medicareCard ? {
          identifier: newPatient.medicareCard,
          patientIdentifierTypeCode: "MC",
        } : null,
        newPatient.dva ? {
          identifier: newPatient.dva,
          patientIdentifierTypeCode: "DVA",
        } : null,
      ]),
    });
  };

  const openPatientForm = props => {
    dispatch(createNewPatient());
    dispatch(openDialog({
      maxWidth: "md",
      children: (
        <PatientForm
          isModal
          title={`Create an new ${patientLabel.single}`}
          onCancel={onClose}
          onSucceed={onClose}
          handleSubmit={handleCreatePatient}
          submitLabel="Continue"
          showNOK
          showHealthSection
          showPrimaryIdentifier
          {...props}
        />
      ),
    }));
  };

  const handleSubmit = ({ patient }) => {
    // else open add-referral-form, pass patient value
    let existingPatient = find(patients, p => p.patientId === patient.value);

    dispatch(fetchPatientNOK(existingPatient.patientId)).then(response => {
      if (!response.error) {
        existingPatient = { ...existingPatient, nextOfKin: response.payload.contact };
        onOpenReferralForm(existingPatient);
      }
    }).finally(() => onOpenReferralForm(existingPatient));
  };

  return (
    <Form
      contentProps={{ ...other, title: `Select ${patientLabel.single}`, submitLabel: "Continue" }}
      initialValues={schema.cast()}
      validationSchema={schema}
      onSubmit={handleSubmit}
      extraActions={(
        <div className="flex-row-container with-gutter">
          <PatientSearchButton
            onContinue={patient => onOpenReferralForm(patient)}
            buttonProps={{ variant: "text" }}
          />
          <DefaultButton
            label={`New ${patientLabel.single}`}
            icon="add"
            variant="text"
            onClick={openPatientForm}
          />
        </div>
      )}
      content={() => (
        <Field
          name="patient"
          component={PatientSelector}
          label={patientLabel.single}
          icon="account_circle"
          required
          autoFocus
        />
      )}
    />
  );
};
