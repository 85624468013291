export default function getPatientIdentifierLabel(patientIdentifierCode) {
  switch (patientIdentifierCode) {
    case "MC":
      return "MC";
    case "PersonId":
      return "Person ID";
    case "CCSID":
      return "CCS ID";
    default:
      return patientIdentifierCode;
  }
}
