import React from "react";
import { connect } from "react-redux";
import clsx from "clsx";

import { map, isEmpty, includes, filter, get } from "lodash";
import { Typography, Chip } from "@material-ui/core";

import { showMessage } from "app/store/actions/message.actions";
import DefaultPanelLayout from "components/items/default-panel-layout";
import EmptyState, { NoneRecordedState } from "components/items/empty-state";
import ResourceForm from "app/main/applications/components/resource-form";
import formatDate from "helpers/format-date";
import { onAddApplicationAttachment, onAddExternalResourceLink, removeExternalResourceLink } from "app/main/applications/actions/applications.actions";
import ApplicationAttachmentContainer from "app/main/applications/components/application-attachment-container";
import ApplicationNoteContent from "app/main/applications/components/application-note-content";
import { getHelperTextBySectionName } from "app/auth/store/reducers/system-configuration";
import MultipleActionButtons, { ActionButton, TriggerButton } from "components/items/multiple-action-buttons";
import { AttachmentItem } from "app/main/applications/components/attachment";

class ResourcePanel extends React.Component {
  onRemoveExternalResourceLink = linkId => {
    this.props.closeDialog();
    return this.props.removeExternalResourceLink(this.props.id, linkId, this.props.orgUnitId).then(response => {
      if (response.error === true) {
        this.props.showMessage({
          message: response.payload.exceptionMessage || response.payload.message,
          variant: "error",
        });
      }
    });
  }

  handleSubmit = ({ resource }, { setSubmitting }, attachedFile) => {
    const { id: applicationId, orgUnitId } = this.props;
    const { attachment, description, internalUse } = resource;
    const isAttachment = get(attachment, "fileName", false);

    const apiCall = isAttachment
      ? this.props.onAddApplicationAttachment(applicationId, orgUnitId,
        { fileId: attachedFile.id,
          fileName: attachedFile.fileName,
          mimeType: attachedFile.mimeType,
          description,
          internalUse,
          fileScanStatus: attachedFile.fileScanStatus,
          scannedDateTimeUtc: attachedFile.scannedDateTimeUtc,
          fileScanMessage: attachedFile.fileScanMessage })
      : this.props.onAddExternalResourceLink(applicationId, orgUnitId, { resourceUri: attachment.link, description, internalUse });

    apiCall.then(response => {
      setSubmitting(false);

      if (response.error !== true) {
        this.props.closeDialog();
      }
    });
  }

  onAddResource = resourceType => {
    this.props.openDialog({
      children: (
        <ResourceForm
          handleSubmit={this.handleSubmit}
          title={resourceType === "attachment" ? "Add Attachment" : "Add Link"}
          resourceType={resourceType}
          showInternalUseCheck={this.props.canViewInternalSource}
          {...this.props}
        />
      ),
    });
  }

  render() {
    const {
      attachments,
      canView,
      canEdit,
      canRemove,
      canPreview,
      canDownload,
      helperText,
      orgUnitId,
    } = this.props;

    const showRemoveButton = canRemove;
    const showDownloadButton = canDownload;
    const showPreviewButton = canPreview;
    const showViewURLButton = canView; // temp

    const resourceAttachments = filter(attachments, x => x.attachmentType !== "ReportBack");

    let emptyMessage = "Add Supporting Evidence";
    if (!canEdit) {
      emptyMessage = "Unable to add supporting evidence to application";
    }

    return (
      <DefaultPanelLayout
        title="Supporting Evidence"
        icon="attach_file"
        isEmpty={isEmpty(resourceAttachments)}
        emptyState={canEdit
          ? <EmptyState icon="attach_file" subtitle={emptyMessage} onClick={canEdit ? () => this.onAddResource() : null} />
          : <NoneRecordedState />}
        accessDenied={!canView}
        headerRightContent={canEdit && (
          <MultipleActionButtons
            renderTrigger={triggerProps => <TriggerButton {...triggerProps} label="Add Supporting Evidence" />}
          >
            <ActionButton label="Attachment" icon="attachment" onClick={() => this.onAddResource("attachment")} />
            <ActionButton label="Link" icon="link" onClick={() => this.onAddResource("link")} />
          </MultipleActionButtons>
        )}
        subtitle={<Typography variant="caption" color="textSecondary" dangerouslySetInnerHTML={{ __html: helperText.resources }} />}
      >
        <div className="items-container">
          <ApplicationAttachmentContainer
            applicationId={this.props.id}
            orgUnitId={orgUnitId}
            renderContent={({
              previewPDF,
              downloadFile,
              removeFile,
              fetchImageFileData,
            }) => (
              map(resourceAttachments, (attachment, index) => (
                <React.Fragment key={attachment.id}>
                  <AttachmentItem
                    id={attachment.id}
                    mimeType={attachment.mimeType}
                    isFirst={index === 0}
                    title={(
                      <div className="flex-row-container">
                        <Typography className={clsx("font-bold enable-shrink", attachment.internalUse && "mr-8")}>{attachment.fileName || attachment.resourceUri}</Typography>
                        {attachment.internalUse && <Chip label="Internal" variant="outlined" color="primary" size="small" />}
                      </div>
                    )}
                    subtitle={<Typography variant="caption">Added by {attachment.addedBy} on {formatDate(attachment.addedDateTimeUtc)}</Typography>}
                    canRemove={showRemoveButton && canEdit && attachment.canEdit}
                    onRemove={() => (attachment.mimeType ? removeFile(attachment.id, attachment.fileId) : this.onRemoveExternalResourceLink(attachment.id))}
                    canDownload={showDownloadButton && attachment.mimeType}
                    onDownload={() => downloadFile(attachment.id)}
                    canPreviewPdf={showDownloadButton && attachment?.mimeType === "application/pdf"}
                    onPreviewPdf={() => previewPDF(attachment.id)}
                    canPreviewImage={showPreviewButton && includes(attachment.mimeType, "image")}
                    onPreviewImage={() => fetchImageFileData(attachment.id)}
                    canViewLink={showViewURLButton && attachment.resourceUri}
                    onOpenLink={() => window.open(attachment.resourceUri.match(/^http[s]?:\/\//) ? attachment.resourceUri : `//${attachment.resourceUri}`)}
                  >
                    <ApplicationNoteContent>{attachment.description}</ApplicationNoteContent>
                  </AttachmentItem>
                </React.Fragment>
              ))
            )}
          />
        </div>
      </DefaultPanelLayout>
    );
  }
}

const mapStateToProps = state => ({
  helperText: getHelperTextBySectionName(state, "resources"),
});

export default connect(
  mapStateToProps,
  {
    showMessage,
    onAddApplicationAttachment,
    onAddExternalResourceLink,
    removeExternalResourceLink,
  },
)(ResourcePanel);
