import { getIPAWorklistTitle } from "utils/get-environment-variables";
import SecureApplicationWorklistPage from "./pages/secure-application-worklist-page";

const worklistTitle = getIPAWorklistTitle();

const ApplicationWorklistConfig = {
  routes: [
    {
      title: worklistTitle?.plural, // Application Worklist
      path: "/applications",
      component: SecureApplicationWorklistPage,
      isIndexPage: true,
      routes: [
        {
          path: "/applications?applicationId=:applicationId",
          component: SecureApplicationWorklistPage,
          exact: true,
          isIndexPage: true,
        },
        {
          path: "/applications?new",
          component: SecureApplicationWorklistPage,
          exact: true,
          isIndexPage: true,
        },
      ],
    },
  ],
};

export default ApplicationWorklistConfig;
