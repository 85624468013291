import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import { getImmunisationsByPatientId, areImmunisationsLoading, getErrorMessage } from "app/main/patients/reducers/immunisations.reducers";
import { fetchPatientImmunisations, editExistingImmunisation, recordNewImmunisation, removeImmunisation, fetchImmunisationsById } from "app/main/patients/actions/immunisations.actions";
import RecordImmunisationForm from "app/main/patients/immunisations/components/record-immunisation-form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import withPermissions from "permissions/withPermissions";

class ImmunisationListContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    const { patientId } = this.props;
    if (patientId) {
      this.props.fetchPatientImmunisations(patientId);
    }
    this.onRecordImmunisation = this.onRecordImmunisation.bind(this);
    this.onEditImmunisation = this.onEditImmunisation.bind(this);
  }

  onClose = () => this.props.closeDialog();

  openRecordImmunisationForm = (title, isEdit) => {
    this.props.openDialog({
      children: <RecordImmunisationForm
        title={title}
        onSucceed={this.onClose}
        isEdit={isEdit}
        assignedToUserId={this.props.assignedToUserId}
      />,
    });
  }

  onRecordImmunisation = () => {
    this.props.recordNewImmunisation(this.props.patientId);
    this.openRecordImmunisationForm("Add Immunisation");
  };

  onEditImmunisation = immunisationId => {
    this.props.editExistingImmunisation(this.props.patientId, immunisationId);
    this.openRecordImmunisationForm("Edit Immunisation", true);
  };

  onRemoveImmunisation = immunisationId => {
    this.props.removeImmunisation(this.props.patientId, immunisationId);
    this.onClose();
  };

  render() {
    const {
      loading,
      error,
      immunisations,
      renderContent,
      hasPermissionImmunisationsCreate,
      hasPermissionImmunisationsUpdate,
      hasPermissionImmunisationsDelete,
    } = this.props;

    return (
      renderContent({
        immunisations,
        onRecordImmunisation: hasPermissionImmunisationsCreate ? this.onRecordImmunisation : null,
        onEditImmunisation: hasPermissionImmunisationsUpdate ? this.onEditImmunisation : null,
        onRemoveImmunisation: hasPermissionImmunisationsDelete ? this.onRemoveImmunisation : null,
        isLoading: loading,
        isError: error,
        isEmpty: isEmpty(immunisations),
      })
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { patientId } = ownProps;
  const immunisations = getImmunisationsByPatientId(state, patientId);

  return {
    immunisations,
    error: getErrorMessage(state, patientId),
    loading: areImmunisationsLoading(state, patientId),
  };
};

export default withPermissions(
  "ImmunisationsDelete",
  "ImmunisationsUpdate",
  "ImmunisationsCreate",
)(connect(
  mapStateToProps,
  {
    openDialog,
    closeDialog,
    fetchImmunisationsById,
    fetchPatientImmunisations,
    recordNewImmunisation,
    editExistingImmunisation,
    removeImmunisation,
  },
)(ImmunisationListContainer));
