import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";

import IconComponent from "components/items/icon-component";

const useStyles = makeStyles(theme => ({
  root: ({ color, backgroundColor }) => ({
    display: "flex",
    alignItems: "center",
    color,
    backgroundColor,
    borderRadius: theme.shape.borderRadius,
    padding: backgroundColor ? theme.spacing(0, 1) : 0,
  }),
  leftIcon: {
    marginRight: theme.spacing(1),
    display: "flex",
  },
  text: ({ color, backgroundColor, size }) => ({
    fontSize: size,
    color: backgroundColor ? theme.palette.getContrastText(backgroundColor) : color,
    fontWeight: "bold",
  }),
}));

const ItemStatus = ({
  icon,
  label,
  color,
  size,
  backgroundColor,
  textProps,
  ...other
}) => {
  const classes = useStyles({ color, backgroundColor });
  return (
    <div className={classes.root} {...other}>
      {icon
        && (
        <div className={classes.leftIcon}>
          <IconComponent icon={icon} size={size} color={color} />
        </div>
        )}
      <Typography className={classes.text} variant="caption" {...textProps}>{label}</Typography>
    </div>
  );
};

ItemStatus.prototype = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
  textProps: PropTypes.objectOf(PropTypes.object),
};

ItemStatus.defaultProps = {
  size: 20,
};

export default ItemStatus;
