import React, { useState } from "react";
import { isEmpty, map, get, filter } from "lodash";
import { makeStyles, fade } from "@material-ui/core/styles";
import { Typography, IconButton, CircularProgress, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment-timezone";
import FilePDFOutlineIcon from "mdi-react/FilePdfOutlineIcon";

import withPermissions from "permissions/withPermissions";
import ItemStatus from "components/items/item-status";
import PatientAgeLabel from "app/main/patients/components/patient-details/details/age-label";
import { PatientDetailsIcon } from "helpers/icon-finder";
import ContentWithDivider from "app/main/applications/components/content-with-divider";
import downloadFromApi from "utils/download-from-api";
import UrgentIcon from "app/main/applications/components/urgent-icon";
import { actionPanel } from "components/styles/base";
import { getIPAWorklistTitle } from "utils/get-environment-variables";

const worklistTitle = getIPAWorklistTitle();

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap",

    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
      flexWrap: "noWrap",
    },
  },
  contentContainer: ({ applicationStatusColor }) => ({
    flex: "1 1 auto",
    padding: `${theme.spacing(2)}px`,
    backgroundColor: applicationStatusColor ? fade(applicationStatusColor, 0.1) : "transparent",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  }),
  patientContainer: {
    width: "100%",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      width: actionPanel.width,
      padding: 0,
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  patient: {
    width: "inherit",
  },
}));

const ApplicationHeader = ({
  hasPermissionPatientsView,
  application,
  applicationStatus,
  applicationStatusColor,
  patientSetting,
}) => {
  const classes = useStyles({ applicationStatusColor });
  const [generatingPDF, setPDFState] = useState(false);
  const { patient, requestedMedication, statusFlags: { canExportPdf }, displayProvisionalApprovalLabel, isReportBackApplication } = application;
  const timeZone = moment.tz.guess();
  const hidePDFButton = !canExportPdf;
  const collectionLocation = get(application, ["collectionLocation", "name"], null);

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className="flex-1">
          <Typography variant="h6" className="font-bold">{`${worklistTitle.single} ${isReportBackApplication ? " - Report Back" : ""}`}</Typography>
          <div className="flex-row-container mt-4">
            <div className="flex items-center mr-16">
              <UrgentIcon show={application.urgentApproval} />
              <ItemStatus
                color={applicationStatusColor}
                label={displayProvisionalApprovalLabel ? `${applicationStatus} (Provisionally Approved)` : applicationStatus}
                textProps={{ variant: "body1" }}
              />
            </div>
            <div className="enable-shrink">
              <ContentWithDivider contents={[{ label: get(requestedMedication, "drug", null), className: "font-bold", color: "primary", variant: "body2" }, get(application, ["indication", "indicationTerm"], null)]} />
            </div>
          </div>
          <div className={clsx((collectionLocation || patientSetting) && "mt-4")}>
            <ContentWithDivider contents={[collectionLocation, patientSetting]} />
          </div>
        </div>
        <div className="flex flex-col items-end justify-between w-128">
          {hidePDFButton
            ? <div className="h-48" />
            : (
              <Tooltip title="Export to PDF">
                <IconButton
                  color="primary"
                  onClick={() => {
                    setPDFState(true);
                    downloadFromApi(`api/applications/${application.orgUnitId}/${application.id}/createPdf?timeZone=${timeZone}`).then(() => {
                      setPDFState(false);
                    });
                  }}
                >
                  {generatingPDF ? <CircularProgress size={24} /> : <FilePDFOutlineIcon />}
                </IconButton>
              </Tooltip>
            )}
          {application.status !== "New" && <Typography variant="body2">IPA# {application.applicationNumber}</Typography>}
        </div>
      </div>
      <div className={classes.patientContainer}>
        <div className={classes.patient}>
          {isEmpty(patient) || !hasPermissionPatientsView
            ? <PatientDetailsIcon size={48} />
            : (
              <>
                <Typography className="font-bold" color="primary">{patient.displayNameFull}</Typography>
                {patient.birthDate && <PatientAgeLabel birthDate={patient.birthDate} formatDOB />}
                {patient.patientIdentifiers && (
                  <div className="mt-8">
                    {map(filter(patient.patientIdentifiers, patientIdentifier => patientIdentifier.identifier != null), (x, index) => (
                      <div key={x.identifier} className={clsx("flex items-center justify-center", index !== 0 && "mt-8")}>
                        <Typography variant="body1" className="font-bold">{x.label}</Typography>
                        <Typography variant="body1" className="ml-8">{x.identifier}</Typography>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default withPermissions("PatientsView")(ApplicationHeader);
