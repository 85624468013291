import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import { isPageLoading, isPageLoaded, isCurrentLoading, isOrgUnitsSelectorLoading, isOrgUnitsSelectorLoaded, areEndorsementTypesLoading, areEndorsementTypesLoaded, areCurrentOrgUnitFormFieldConfigLoading } from "app/main/orgUnits/reducers/orgUnit.reducers";
import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";

export const fetchOrgUnitsForSelector = (searchParams, forceLoad = true) => async (dispatch, getState) => {
  const params = formatSearchParams(searchParams);
  const pageNumber = 1;
  const orgUnitId = getSignedInOrgUnit(getState())?.id;
  const url = `/orgUnits/${orgUnitId}?pageNumber=${pageNumber}&pageSize=999${params}`;
  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "ORG_UNIT_SELECTOR",
      bailout: state => isOrgUnitsSelectorLoading(state) || (!forceLoad && isOrgUnitsSelectorLoaded(state)),
    },
  });
};

export const fetchOrgUnitsResults = (orgUnitId, pageNumber, pageSize, searchParams, forceLoad = true) => dispatch => {
  const params = formatSearchParams(searchParams);
  const url = `/orgUnits/${orgUnitId}?pageNumber=${pageNumber}&pageSize=${pageSize}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "ORG_UNITS",
      pageNumber,
      bailout: state => isPageLoading(state, pageNumber) || (!forceLoad && isPageLoaded(state, pageNumber)),
    },
  });
};

export const fetchOrgUnitById = orgUnitId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/orgUnits/${orgUnitId}/GetDetail`,
    entityType: "CURRENT_ORG_UNIT",
    bailout: state => isCurrentLoading(state),
  },
});

export const addOrgUnit = (orgUnitId, orgUnit) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/${orgUnit.orgUnitType}`,
    body: { orgUnit },
    entityType: "ORG_UNIT",
  },
});

export const editOrgUnit = (orgUnitId, orgUnit) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/${orgUnit.orgUnitType}`,
    body: { orgUnit },
    entityType: "ORG_UNIT",
  },
});

export const deprecateOrgUnit = orgUnitId => ({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/deprecate`,
    entityType: "DEPRECATE_ORG_UNIT",
  },
});

export const restoreOrgUnit = orgUnitId => ({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/restore`,
    entityType: "RESTORE_ORG_UNIT",
  },
});

export const editOrgUnitFormFields = (orgUnitId, formType, formFieldConfiguration) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/editFormField/${formType}`,
    body: { formFieldConfiguration },
    entityType: "ORG_UNIT_FORM_FIELD_CONFIG",
  },
});

export const fetchOrgUnitFormFields = (orgUnitId, formType) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/orgUnits/${orgUnitId}/getFormFieldConfigs/${formType}`,
    entityType: "CURRENT_ORG_UNIT_FORM_FIELD_CONFIG",
    orgUnitId,
    formType,
    bailout: state => areCurrentOrgUnitFormFieldConfigLoading(state, orgUnitId),
  },
});

export const fetchEndorsementTypeConfiguration = orgUnitId => dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/orgUnits/${orgUnitId}/getEndorsementTypeConfiguration`,
    entityType: "ENDORSEMENT_TYPE_CONFIGURATION",
    orgUnitId,
    bailout: state => areEndorsementTypesLoading(state, orgUnitId) || areEndorsementTypesLoaded(state, orgUnitId),
  },
});

export const editEndorsementTypeConfiguration = (orgUnitId, endorsementTypeConfiguration) => dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/editEndorsementTypeConfiguration`,
    body: { endorsementTypeConfiguration },
    entityType: "ENDORSEMENT_TYPE_CONFIGURATION",
    orgUnitId,
  },
});

export const uploadDecisionLetterTemplate = (orgUnitId, formData) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/uploadDecisionLetterTemplate`,
    headers: { "Content-Type": "multipart/form-data" },
    body: formData,
    entityType: "DECISION_LETTER_TEMPLATE",
  },
});

export const removeDecisionLetterTemplate = orgUnitId => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/orgUnits/${orgUnitId}/removeDecisionLetterTemplate`,
    entityType: "DECISION_LETTER_TEMPLATE",
  },
});

export const uploadIPABlankForm = (orgUnitId, formData) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/uploadIPABlankForm`,
    headers: { "Content-Type": "multipart/form-data" },
    body: formData,
    entityType: "IPA_BLANK_FORM",
  },
});

export const removeIPABlankForm = orgUnitId => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/orgUnits/${orgUnitId}/removeIPABlankForm`,
    entityType: "IPA_BLANK_FORM",
  },
});

export const fetchSubFormularies = orgUnitId => dispatch => {
  const url = `/siteConfiguration/SubFormularies/${orgUnitId}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "SUB_FORMULARIES",
    },
  });
};
