import React from "react";
import PatientRecordContent from "app/main/patients/components/patient-record-content";

const PatientReferralRecordPage = ({
  match: { params: { patientId } },
  ...other
}) => (
  <PatientRecordContent routePrefix={`/patientReferralWorklist?patientId=${patientId}`} patientId={patientId} {...other} />
);

export default PatientReferralRecordPage;
