import React from "react";

import ContactListContainer from "app/main/patients/contacts/containers/contact-list-container";
import ContactList from "app/main/patients/contacts/components/contact-list";
import { HeaderAction } from "app/main/patients/components/patient-components";
import PatientRecordPageContent from "app/main/patients/components/patient-record-page-content";

export default function PatientContactPage({ patientId }) {
  return (
    <ContactListContainer
      patientId={patientId}
      renderContent={({ onRecordContact, ...other }) => (
        <PatientRecordPageContent pageAction={onRecordContact && <HeaderAction onClick={onRecordContact} label="Add Contact" />}>
          <ContactList {...other} />
        </PatientRecordPageContent>
      )}
    />
  );
}
