import React from "react";
import ScreenHeader from "components/items/screen-header";
import withPermissions from "permissions/withPermissions";
import { getPatientLabel } from "utils/get-environment-variables";

import PatientsSearch from "./patients-search";

const PatientsPageHeader = ({
  hasPermissionPatientsCreate,
  hasPermissionPatientsMaintainReadonly,
  history,
  title = "Patients",
}) => (
  <ScreenHeader
    title={title}
    renderSearch={<PatientsSearch />}
    action={
      !hasPermissionPatientsMaintainReadonly && hasPermissionPatientsCreate && {
        label: `add a ${getPatientLabel().single}`,
        onClick: () => history.push("/admin/patients/new"),
      }
    }
  />
);

export default withPermissions("PatientsCreate", "PatientsMaintainReadonly")(PatientsPageHeader);
