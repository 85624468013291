import React from "react";
import { connect } from "react-redux";
import { Field } from "formik";

import { TextInput, NoteInput } from "components/inputs/text-input";
import SelectInput from "components/inputs/select-input";
import { getCurrentIntervention } from "app/main/patients/reducers/interventions.reducers";
import { getCurrentMedicationIntervention } from "app/main/patients/reducers/medicationHistory.reducers";
import { validateRequired } from "utils/validators";
import Form from "components/form";
import { PriorityIcon } from "helpers/icon-finder";

const priorityOptions = [
  { value: "Low", label: "Low" },
  { value: "Normal", label: "Normal" },
  { value: "High", label: "High" },
];

const interventionDescription = "Intervention Description";
const details = "Intervention Details";
const priority = "Priority";

const validateDescription = validateRequired(interventionDescription);
const validateDetails = validateRequired(details);

class RecordInterventionForm extends React.PureComponent {
  render() {
    const {
      initialValues,
      handleSubmit,
      ...other
    } = this.props;

    return (
      <Form
        contentProps={other}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        content={() => (
          <>
            <Field
              name="intervention.description"
              icon="mode_comment"
              label={interventionDescription}
              component={TextInput}
              validate={validateDescription}
            />
            <Field
              name="intervention.priority"
              component={SelectInput}
              icon={<PriorityIcon />}
              label={priority}
              options={priorityOptions}
            />
            <Field
              name="intervention.note"
              label={details}
              component={NoteInput}
              validate={validateDetails}
            />
          </>
        )}
      />
    );
  }
}

export default connect((state, ownProps) => {
  const intervention = ownProps.isAssessment ? getCurrentMedicationIntervention(state) : getCurrentIntervention(state);

  return ({
    initialValues: { intervention },
  });
}, {})(RecordInterventionForm);
