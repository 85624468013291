import React from "react";
import { Paper, Select, MenuItem, Typography, Grid, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { map, get } from "lodash";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";

const useStyles = makeStyles(theme => ({
  selectContainer: {
    "&:before": {
      border: "none !important",
    },
    "&:after": {
      border: "none",
    },
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: 8,
  },
  select: {
    "&:focus": {
      borderRadius: 8,
    },
  },
  divider: {
    minHeight: 24,
  },
}));

function DefaultSelectionFilter({ options, filterValue, onChange, renderExtraOption, renderExtraActions }) {
  const classes = useStyles();

  return (
    <Paper className="flex items-center" elevation={1}>
      <Select
        value={get(filterValue, "value", filterValue)}
        onChange={event => onChange(event.target.value)}
        className={clsx(classes.selectContainer, "flex-1")}
        classes={{ selectMenu: "pl-16", select: classes.select, icon: "mr-8" }}
        IconComponent={ChevronDownIcon}
      >
        {map(options, option => (
          <MenuItem value={option.value} key={option.value}>
            <Grid container alignItems="center">
              {option.title && (
              <Typography className="font-bold mr-4" variant="body1">
                {option.title}
              </Typography>
              )}
              <Typography variant="body1">{option.label}</Typography>
            </Grid>
          </MenuItem>
        ))}
        {renderExtraOption && <div><Divider />{renderExtraOption}</div>}
      </Select>
      {renderExtraActions && (
        <>
          <Divider orientation="vertical" className={classes.divider} />
          {renderExtraActions}
        </>
      )}
    </Paper>
  );
}

export default DefaultSelectionFilter;
