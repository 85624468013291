import React from "react";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import SearchInput from "components/inputs/search-input";
import { fetchSpecialtyProfilesResults } from "app/main/specialtyProfiles/actions/specialtyProfiles.actions";
import { getSpecialtyProfileSearchTerms, getPageInfo } from "app/main/specialtyProfiles/reducers/specialtyProfiles.reducers";

class SpecialtyProfilesSearch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onSearch = this.onSearch.bind(this);
  }

  onSearch = ({ searchParams }) => {
    const { pageInfo: { pageSize }, orgUnitId } = this.props;
    this.props.fetchSpecialtyProfilesResults(orgUnitId, 1, pageSize, searchParams);
  };

  render() {
    const { initialValues } = this.props;
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.onSearch}
        enableReinitialize
        render={({ handleSubmit }) => (
          <Field
            name="searchParams.name"
            placeholder="Search by Name"
            component={SearchInput}
            onSearch={handleSubmit}
          />
        )}
      />
    );
  }
}

export default connect(state => ({
  pageInfo: getPageInfo(state),
  initialValues: { searchParams: getSpecialtyProfileSearchTerms(state) },
}), { fetchSpecialtyProfilesResults })(SpecialtyProfilesSearch);
