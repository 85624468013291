import React, { useEffect } from "react";
import { map, split } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import Highlighter from "react-highlight-words";

import { ContextItem } from "components/items/default-item";
import { AutoComplete } from "components/inputs";
import { fetchProvidersResults } from "app/main/providers/actions/providers.actions";
import { getProviders } from "app/main/providers/reducers/providers.reducers";
import { enableFreeTextOnProviderInput } from "app/auth/store/reducers/system-configuration";

const normaliseOptions = options => map(options, x => ({
  label: x.name,
  value: x.name, // TODO temp -> save name for now??
  ...x,
}));

const Item = props => (
  <ContextItem
    className="flex-1"
    labelClassName="w-128"
    labelProps={{ variant: "caption", color: "textSecondary" }}
    renderIfEmpty
    {...props}
  />
);

const ProviderSummary = ({ provider, highlightTerm }) => (
  <div className="w-full">
    <Typography className="font-bold">
      {provider.label ? <Highlighter autoEscape searchWords={highlightTerm} textToHighlight={provider.label} /> : provider.label}
    </Typography>
    {(provider.providerNumber !== undefined || provider.contactNumber !== undefined) && (
    <div className="flex items-center justify-between">
      {provider.providerNumber ? (
        <Item
          label="Provider Number:"
          content={<Highlighter autoEscape searchWords={highlightTerm} textToHighlight={provider.providerNumber} />}
        />
      ) : provider.providerNumber}
      {provider.contactNumber !== undefined && <Item label="Contact Number:" content={provider.contactNumber} />}
    </div>
    )}
  </div>
);

// TODO TO SEARCH API
// perhaps structure it like patient selector?
// the search on provider number is not working
// as it currently map on name only
// add filterOptions={allOptions => allOptions} once api search being implemented
const ProviderSelectorInput = ({
  label,
  ...other
}) => {
  const dispatch = useDispatch();
  const providers = useSelector(getProviders);
  const isCreatable = useSelector(enableFreeTextOnProviderInput);

  useEffect(() => {
    dispatch(fetchProvidersResults());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AutoComplete
      withDivider
      isCreatable={isCreatable}
      label={label}
      createLabel={`Add ${label}`}
      options={normaliseOptions(providers)}
      renderOption={(option, { inputValue }) => {
        const searchWords = split(inputValue, " ");
        if (!option) return null;
        return <ProviderSummary provider={option} highlightTerm={searchWords} />;
      }}
      {...other}
    />
  );
};

export default ProviderSelectorInput;
