import React from "react";

import ProblemListContainer from "app/main/patients/problems/containers/problem-list-container";
import ProblemList from "app/main/patients/problems/components/problem-list";
import { HeaderAction } from "app/main/patients/components/patient-components";
import PatientRecordPageContent from "app/main/patients/components/patient-record-page-content";

export default function PatientProblemPage({ patientId }) {
  return (
    <ProblemListContainer
      patientId={patientId}
      renderContent={({ onRecordProblem, ...other }) => (
        <PatientRecordPageContent pageAction={onRecordProblem && <HeaderAction onClick={onRecordProblem} label="Add Problem" />}>
          <ProblemList {...other} />
        </PatientRecordPageContent>
      )}
    />
  );
}
