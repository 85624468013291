import { ApiActions } from "middleware/call-api";
import { ActionTypes,
  areConditionsLoading,
  areConditionsLoaded,
  getConditionById } from "../reducers/conditions.reducers";

export const setCurrentCondition = condition => ({
  type: ActionTypes.LOADED_CURRENT_CONDITION,
  payload: { condition },
});

export const fetchPatientConditions = patientId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/conditions`,
    bailout: state => areConditionsLoaded(state, patientId) || areConditionsLoading(state, patientId),
    entityType: "conditions",
    patientId,
  },
});

export const fetchConditionsById = (patientId, conditionId) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/conditions/${conditionId}`,
    types: [ActionTypes.LOADING_CURRENT_CONDITION, ActionTypes.LOADED_CURRENT_CONDITION, ActionTypes.ERROR_LOADING_CURRENT_CONDITION],
  },
});

/** * Add Condition */
export const recordNewCondition = patientId => setCurrentCondition({
  id: null,
  patientId,
  condition: null,
  status: null,
  severity: null,
  notes: null,
  endDate: null,
  reportedDate: null,
  onsetDate: null,
  isCurrent: true,
});

export const recordCondition = condition => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${condition.patientId}/conditions/new`,
    body: { condition },
    entityType: "condition",
    patientId: condition.patientId,
  },
});

export const editExistingCondition = (patientId, conditionId) => (dispatch, getState) => {
  const condition = getConditionById(getState(), patientId, conditionId);
  dispatch(setCurrentCondition(condition));
};

export const editCondition = condition => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/patient/${condition.patientId}/conditions/edit`,
    body: { condition },
    entityType: "condition",
    patientId: condition.patientId,
  },
});

export const removeCondition = (patientId, conditionId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/patient/${patientId}/conditions/remove`,
    body: { condition: { id: conditionId, patientId } },
    entityType: "condition",
    patientId,
    conditionId,
  },
});
