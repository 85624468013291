import { blue, deepOrange, blueGrey, grey } from "@material-ui/core/colors";

export default function getLogEntryTypeColor(type) {
  switch (type) {
    case "Summary":
      return blue[600];
    case "Warning":
      return deepOrange[500];
    case "Error":
      return deepOrange[900];
    case "Verbose":
      return blueGrey[600];
    default:
      return grey[300];
  }
}
