import { ApiActions } from "middleware/call-api";
import { isSystemConfigurationLoading, isSystemConfigurationLoaded } from "../reducers/system-configuration";

const loadSystemConfiguration = () => ({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: "/systemconfiguration",
    bailout: state => isSystemConfigurationLoading(state) || isSystemConfigurationLoaded(state),
    entityType: "systemConfiguration",
  },
});

export default loadSystemConfiguration;
