import { ActionTypes } from "../reducers/theme.reducer";

export const setCurrentTheme = name => ({
  type: ActionTypes.SET_CURRENT_THEME,
  payload: { name },
});

export const setDefaultTheme = () => ({
  type: ActionTypes.SET_DEFAULT_THEME,
});

export default setCurrentTheme;
