import { get, concat, find, orderBy, filter } from "lodash";
import curryConnector from "utils/connectors";

export const CONDITIONS_STATE_KEY = "conditions";

const curry = fn => curryConnector(fn, CONDITIONS_STATE_KEY);

export const ActionTypes = {
  LOADING_CONDITIONS: "LOADING_CONDITIONS",
  LOADED_CONDITIONS: "LOADED_CONDITIONS",
  ERROR_LOADING_CONDITIONS: "ERROR_LOADING_CONDITIONS",

  LOADING_CURRENT_CONDITION: "LOADING_CURRENT_CONDITION",
  ERROR_LOADING_CURRENT_CONDITION: "ERROR_LOADING_CURRENT_CONDITION",
  LOADED_CURRENT_CONDITION: "LOADED_CURRENT_CONDITION",

  SAVED_CONDITION: "SAVED_CONDITION",
  CREATED_CONDITION: "CREATED_CONDITION",
  DELETED_CONDITION: "DELETED_CONDITION",
};

const INITIAL_STATE = {
  all: {},
  current: null,
};

const addOrUpdateCondition = (state, action) => {
  const { payload: { condition, patientId } } = action;
  let conditions = get(state.all, [patientId, "conditions"], []);
  conditions = orderBy(concat(filter(conditions, x => x.id !== condition.id), [condition]), [a => a.givenDate || ""], ["desc"]);

  return {
    ...state,
    current: condition,
    all: { ...state.all, [patientId]: { ...state.all[patientId], conditions } },
  };
};

const removeCondition = (state, action) => {
  const { payload: { condition, patientId } } = action;
  let conditions = get(state.all, [patientId, "conditions"], []);
  conditions = orderBy(filter(conditions, x => x.id !== condition.id), [a => a.reportedDate || ""], ["desc"]);

  return {
    ...state,
    all: { ...state.all, [patientId]: { ...state.all[patientId], conditions } },
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_CONDITIONS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: true } },
      };
    case ActionTypes.ERROR_LOADING_CONDITIONS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: false, error: action.payload.message } },
      };
    case ActionTypes.LOADED_CONDITIONS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]:
            {
              ...state.all[action.payload.patientId],
              loading: false,
              conditions: action.payload.conditions,
            },
        },
      };
    case ActionTypes.LOADING_CURRENT_CONDITION:
      return {
        ...state,
        current: {
          ...state.current,
          loading: true,
        },
      };
    case ActionTypes.ERROR_LOADING_CURRENT_CONDITION:
      return {
        ...state,
        current: {
          ...state.current,
          error: action.error,
        },
      };
    case ActionTypes.LOADED_CURRENT_CONDITION:
      return {
        ...state,
        current: action.payload.condition,
      };
    case ActionTypes.SAVED_CONDITION:
    case ActionTypes.CREATED_CONDITION:
      return addOrUpdateCondition(state, action);
    case ActionTypes.DELETED_CONDITION:
      return removeCondition(state, action);
    case ActionTypes.SET_CURRENT_CONDITION:
      return {
        ...state,
        current: action.payload.condition,
      };
    default:
      return state;
  }
};

export const areConditionsLoading = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loading"], false));

export const areConditionsLoaded = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loaded"], false));

export const conditionsLoadingError = curry(({ localState }, patientId) => get(localState, ["all", patientId, "error"], null));

export const getConditionsByPatientId = curry(({ localState }, patientId) => get(localState, ["all", patientId, "conditions"], []));

export const getConditionById = curry(({ localState }, patientId, conditionId) => {
  const conditions = get(localState, ["all", patientId, "conditions"], []);
  return find(conditions, x => x.id === conditionId) || {};
});

export const getCurrentCondition = curry(({ localState }) => get(localState, ["current"], null));

export const isCurrentConditionLoading = curry(({ localState }) => get(localState, ["current", "loading"], false));

export const getErrorMessage = curry(({ localState }, patientId) => get(localState, ["all", patientId, "error"], null));
