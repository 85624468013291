import curryConnector from "utils/connectors";

export const APPLICATION_DISPENSING_STATE_KEY = "applicationDispensing";

const curry = fn => curryConnector(fn, APPLICATION_DISPENSING_STATE_KEY);

export const ActionTypes = {
  LOADING_APPLICATION_DISPENSING: "LOADING_APPLICATION_DISPENSING",
  ERROR_LOADING_APPLICATION_DISPENSING: "ERROR_LOADING_APPLICATION_DISPENSING",
  LOADED_APPLICATION_DISPENSING: "LOADED_APPLICATION_DISPENSING",
};

const INITIAL_STATE = {
  meta: {},
  dispensing: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_APPLICATION_DISPENSING:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.applicationId]: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };
    case ActionTypes.ERROR_LOADING_APPLICATION_DISPENSING:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.applicationId]: {
            loading: false,
            loaded: false,
            error: action.payload.message,
          },
        },
      };
    case ActionTypes.LOADED_APPLICATION_DISPENSING:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.applicationId]: {
            loading: false,
            loaded: true,
            error: null,
          },
        },
        dispensing: {
          ...state.dispensing,
          [action.payload.applicationId]: action.payload.applicationDispensing,
        },
      };
    default:
      return state || INITIAL_STATE;
  }
};

export const getApplicationDispensing = curry(({ localState }, applicationId) => localState.dispensing[applicationId] ?? []);

export const isApplicationDispensingLoading = curry(({ localState }, applicationId) => localState.meta[applicationId]?.loading);

export const isApplicationDispensingLoaded = curry(({ localState }, applicationId) => localState.meta[applicationId]?.loaded);
