import React from "react";
import { Field, FieldArray } from "formik";
import { map, isEmpty, capitalize, includes, size, find, concat, filter } from "lodash";
import { Divider } from "@material-ui/core";
import { useSelector } from "react-redux";

import { FilterSection, FilterForm } from "components/items/advanced-filter";
import DateRange from "components/inputs/date-range";
import { CheckboxInput, Checkbox, UserSelector, RadioButtonsGroup, TextInput } from "components/inputs";
import withPermissions from "permissions/withPermissions";
import SpecialtySelector from "app/main/specialtyProfiles/components/specialty-selector";
import SpecialtyRoleSelector from "app/main/specialtyProfiles/components/specialty-role-selector";
import SubSpecialtySelector from "app/main/specialtyProfiles/components/sub-specialty-selector";
import { getUser } from "app/auth/store/reducers/user.reducer";

const taskOptions = [
  { value: "CompletedTasks", label: "Contains Completed Tasks" },
  { value: "OutstandingTasks", label: "Contains Outstanding Tasks" },
  { value: "OverdueTasks", label: "Contains Overdue Tasks" },
];

const ReferralAdvancedFilterForm = ({
  handleSubmit,
  handleReset,
  referralStatusChoices,
  triageCategoryChoices,
  referralWorkflowSettings,
  triageCategorySettings,
  hasPermissionReferralGlobalAccess,
  ...other
}) => {
  const getWorkflowStateLabel = stateName => find(referralWorkflowSettings, x => x.workflowState === stateName)?.label || stateName;
  const getTriageCategoryLabel = category => find(triageCategorySettings, choice => choice.triageCategory === category)?.label;
  const currentUser = useSelector(getUser);
  const assignedToSpecialtyDefaultOptions = currentUser?.specialtyProfile ? [currentUser?.specialtyProfile] : [];

  return (
    <FilterForm
      onSubmit={handleSubmit}
      onReset={handleReset}
      {...other}
      filters={({ setFieldValue, values }) => (
        <div className="advanced-filter-sections">
          <FilterSection label="Referral Date" withDivider>
            <Field
              name="referralFilters.dateRange"
              component={DateRange}
              definedRanges={["today", "yesterday"]}
              onChange={value => {
                if (value) {
                  setFieldValue("referralFilters.startDate", value[0]);
                  setFieldValue("referralFilters.endDate", value[1]);
                } else {
                  setFieldValue("referralFilters.startDate", null);
                  setFieldValue("referralFilters.endDate", null);
                }
              }}
            />
          </FilterSection>
          <FilterSection label="Status" withDivider>
            <Field
              name="referralFilters.allStatus"
              label="All"
              component={CheckboxInput}
              showIcon={false}
              onChange={value => {
                if (value === true) {
                  setFieldValue("referralFilters.status", map(referralStatusChoices, s => s));
                } else {
                  setFieldValue("referralFilters.status", []);
                }
              }}
            />
            <Divider />
            <FieldArray
              name="referralFilters.status"
              render={() => (
                <div className="checkboxGroups">
                  {map(referralStatusChoices, s => (
                    <Checkbox
                      name="referralFilters.status"
                      label={capitalize(getWorkflowStateLabel(s))}
                      key={s}
                      checked={includes(values.referralFilters.status, s)}
                      showIcon={false}
                      onChange={e => {
                        const updatedStatuses = e.target.checked ? concat(values.referralFilters.status, s) : filter(values.referralFilters.status, x => x !== s);
                        setFieldValue("referralFilters.status", updatedStatuses);
                        setFieldValue("referralFilters.allStatus", size(updatedStatuses) === size(referralStatusChoices));
                      }}
                    />
                  ))}
                </div>
              )}
            />
            <Field
              name="referralFilters.daysSinceLastStatusChange"
              label="Days waiting in status"
              component={TextInput}
              disabled={isEmpty(values.referralFilters.status)}
              showHelperText={false}
              showIcon={false}
              type="number"
            />
          </FilterSection>
          <FilterSection label="Triage Category" withDivider>
            <Field
              name="referralFilters.allCategory"
              label="All"
              component={CheckboxInput}
              showIcon={false}
              onChange={value => {
                if (value === true) {
                  setFieldValue("referralFilters.category", map(triageCategoryChoices, s => s));
                } else {
                  setFieldValue("referralFilters.category", []);
                }
              }}
            />
            <Divider />
            <FieldArray
              name="referralFilters.category"
              render={() => (
                <div className="checkboxGroups">
                  {map(triageCategoryChoices, s => (
                    <Checkbox
                      name="referralFilters.category"
                      label={getTriageCategoryLabel(s)}
                      key={s}
                      checked={includes(values.referralFilters.category, s)}
                      showIcon={false}
                      onChange={e => {
                        const updatedStatuses = e.target.checked ? concat(values.referralFilters.category, s) : filter(values.referralFilters.category, x => x !== s);
                        setFieldValue("referralFilters.category", updatedStatuses);
                        setFieldValue("referralFilters.allCategory", size(updatedStatuses) === size(triageCategoryChoices));
                      }}
                    />
                  ))}
                </div>
              )}
            />
          </FilterSection>
          {/* TEMP hide for BHS release */}
          {/* <FilterSection withDivider>
            <Field
              name="referralFilters.isUrgent"
              label="Marked as Urgent"
              component={CheckboxInput}
              showIcon={false}
            />
            <Field
              name="referralFilters.unreadAddendum"
              label="Unread Updated Information"
              component={CheckboxInput}
              showIcon={false}
            />
          </FilterSection> */}
          <FilterSection label="Task" withDivider>
            <Field
              name="referralFilters.taskStatus"
              component={RadioButtonsGroup}
              choices={taskOptions}
              displayRow
              allowUnCheck
            />
            <Field
              name="referralFilters.taskAssignedToUserId"
              label="Assigned To"
              component={UserSelector}
              includeInactive
            />
          </FilterSection>
          <FilterSection label="Assigned To" withDivider>
            <Field
              name="referralFilters.assignedToSpecialtyId"
              label="Specialty"
              component={SpecialtySelector}
              disabled={!hasPermissionReferralGlobalAccess}
              onChange={() => {
                setFieldValue("referralFilters.assignedToRoleId", null);
                setFieldValue("referralFilters.onlyShowUnassigned", false);
              }}
              initialOptions={assignedToSpecialtyDefaultOptions}
            />
            <Field
              name="referralFilters.assignedToSubSpecialtyId"
              component={SubSpecialtySelector}
              specialtyProfileId={values.referralFilters.assignedToSpecialtyId?.value ?? values.referralFilters.assignedToSpecialtyId}
              onChange={() => {
                setFieldValue("referralFilters.onlyShowUnassigned", false);
              }}
            />
            {hasPermissionReferralGlobalAccess
              && (
              <Field
                name="referralFilters.onlyShowUnassigned"
                label="Not Assigned to a Specialty"
                component={CheckboxInput}
                showIcon={false}
                onChange={val => {
                  if (val === true) {
                    setFieldValue("referralFilters.assignedToSpecialtyId", null);
                    setFieldValue("referralFilters.assignedToSubSpecialtyId", null);
                  }
                }}
              />
              )}
            <Field
              name="referralFilters.assignedToRoleId"
              label="Role"
              component={SpecialtyRoleSelector}
              specialtyId={values.referralFilters.assignedToSpecialtyId?.value ?? values.referralFilters.assignedToSpecialtyId}
              disabled={!isEmpty(values.referralFilters.assignedToUserId)}
            />
            <Field
              name="referralFilters.assignedToUserId"
              label="User"
              component={UserSelector}
              disabled={!isEmpty(values.referralFilters.assignedToRoleId)}
              includeInactive
            />
          </FilterSection>
          <FilterSection>
            <Field
              name="referralFilters.createdByUserId"
              label="Created By"
              component={UserSelector}
              includeInactive
            />
          </FilterSection>
        </div>
      )}
    />
  );
};

export default withPermissions("ReferralGlobalAccess")(ReferralAdvancedFilterForm);
