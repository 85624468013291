import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import DefaultButton from "components/items/default-button";

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.background.control,
  },
  // styles to matching surveyjs button styles
  buttonContainer: {
    margin: "1em",
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    borderRadius: 2,
    boxShadow: "none",
    textTransform: "none",
    marginRight: "5px",
    fontSize: "0.85em",
    lineHeight: "2em",
    minWidth: 100,
  },
}));

const CustomSurveyPage = ({
  title,
  renderActions,
  children,
  onComplete,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <div className="flex flex-col h-full">
      <div className={clsx(classes.header, "flex items-center justify-between flex-wrap py-8 px-16")}>
        <Typography variant="h5" color="primary">{title}</Typography>
        {renderActions && <div className="flex justify-end">{renderActions}</div>}
      </div>
      <div className="flex-1 overflow-y-auto">
        {children}
      </div>
      {onComplete
        && (
        <div className={classes.buttonContainer}>
          <DefaultButton
            classes={{ root: classes.button }}
            disabled={disabled}
            onClick={() => onComplete()}
            label="Complete"
            size="medium"
            disableFocusRipple
          />
        </div>
        )}
    </div>
  );
};

export default CustomSurveyPage;
