import React from "react";
import { Collapse } from "@material-ui/core";

export default ({
  expanded,
  content,
}) => (
  <Collapse in={expanded} timeout="auto" unmountOnExit>
    <div className="p-16">
      {content}
    </div>
  </Collapse>
);
