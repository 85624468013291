import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { map, startCase } from "lodash";
import { getSpecialtyRoles, areSpecialtyRolesLoading } from "app/main/specialtyProfiles/reducers/specialtyProfiles.reducers";
import { AutoComplete, AutoCompleteLoading } from "components/inputs";
import { fetchSpecialtyRoles } from "app/main/specialtyProfiles/actions/specialtyProfiles.actions";
import { AssignedSpecialtyIcon } from "helpers/icon-finder";

export default ({
  label,
  specialtyId,
  showIcon = true,
  ...other
}) => {
  const dispatch = useDispatch();
  const options = useSelector(state => getSpecialtyRoles(state, specialtyId));
  const loading = useSelector(state => areSpecialtyRolesLoading(state, specialtyId));

  React.useEffect(() => {
    if (specialtyId) {
      dispatch(fetchSpecialtyRoles(specialtyId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialtyId]);

  if (loading) return <AutoCompleteLoading label={label} />;

  return (
    <AutoComplete
      icon={showIcon && <AssignedSpecialtyIcon />}
      label={label}
      options={map(options, x => ({ label: startCase(x.name), value: x.id }))}
      filterSelectedOptions
      getOptionSelected={(option, val) => option.value === val.value}
      {...other}
    />
  );
};
