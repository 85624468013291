import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Field } from "formik";
import * as Yup from "yup";

import { getPatientCardOptions } from "app/auth/store/reducers/system-configuration";
import Form from "components/form";
import { SelectInput, TextInput, DatePicker } from "components/inputs";
import { savePatientCard, removePatientCard } from "app/main/patients/actions/patients.actions";
import "utils/yup-validation";

const cardType = "Card Type";
const identifier = "Card number";
const expiryDate = "Expiry Date";

const schema = Yup.object().shape({
  patientCard: Yup.object().shape({
    patientCardTypeCode: Yup.string()
      .required(`${cardType} is required`).nullable(),
    patientCard: Yup.string()
      .when("patientCardTypeCode", {
        is: val => val && val === "MC",
        then: Yup.string().validateMedicareNumber(identifier).nullable(),
      })
      .when("patientCardTypeCode", {
        is: val => val && val === "DVA",
        then: Yup.string().validateIdentifier(identifier, { min: 8, max: 9, numberOnly: false }).nullable(),
      })
      .trim()
      .required(`${identifier} is required`)
      .nullable(),
    expiryDate: Yup.date().default(null).nullable(),
  }),
});

export default ({
  patientId,
  patientCard: card,
  onSucceed,
  ...other
}) => {
  const dispatch = useDispatch();
  const typeOptions = useSelector(getPatientCardOptions);
  const isEdit = card?.patientCardId;

  const handleRemove = () => {
    dispatch(removePatientCard(patientId, card?.patientCardId)).then(responds => {
      if (responds.error !== true) {
        onSucceed();
      }
    });
  };

  const handleSubmit = ({ patientCard }, { setErrors }) => {
    let data = patientCard;

    if (patientCard.expiryDate && patientCard.patientCardTypeCode === "MC") {
      data = {
        ...data,
        MedicareExpiryDate: patientCard.expiryDate,
      };
    }

    dispatch(savePatientCard(patientId, data)).then(responds => {
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  let { patientCard } = schema.cast();

  if (card) {
    patientCard = card;
  }

  return (
    <Form
      initialValues={{ patientCard }}
      validationSchema={schema}
      onSubmit={handleSubmit}
      contentProps={{ ...other }}
      onRemove={isEdit ? () => handleRemove() : null}
      content={() => (
        <>
          <Field
            name="patientCard.patientCardTypeCode"
            label={cardType}
            component={SelectInput}
            options={typeOptions}
            icon="payment"
            required
          />
          <Field
            name="patientCard.patientCard"
            label={identifier}
            component={TextInput}
            required
          />
          <Field
            name="patientCard.expiryDate"
            label={expiryDate}
            icon=" "
            component={DatePicker}
          />
        </>
      )}
    />
  );
};
