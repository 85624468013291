import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { useMediaQuery } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { setSideBarStatus } from "app/store/actions/sidebars.actions";
import { isSidebarOpen } from "app/store/reducers/sidebars.reducer";

import StateContainer from "components/layouts/default-state-container";

const headerHeight = 200;
const toolbarHeight = 64;
const headerContentHeight = headerHeight - toolbarHeight;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    position: "relative",
    backgroundColor: theme.palette.background.default,
    overflow: "hidden",
    flex: "1 1 auto",
  },
  topBg: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    height: headerHeight,
    backgroundColor: theme.palette.primary[200],
    pointerEvents: "none",
  },
  header: {
    height: headerContentHeight,
    minHeight: headerContentHeight,
    maxHeight: headerContentHeight,
    display: "flex",
    color: theme.palette.primary.contrastText,
  },
  contentWrapper: {
    zIndex: 2,
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
  },
  contentCard: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    minHeight: 0,
    borderRadius: "8px 8px 0 0",
    margin: theme.spacing(0, 4),
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2),
    },
  },
  content: ({ hasTabs }) => ({
    flex: "1 1 auto",
    height: hasTabs ? "calc(100% - 64px)" : "100%",
    width: "100%",
    overflow: "auto",
    display: "flex",
    position: "absolute",
    top: hasTabs ? 64 : 0,
    flexDirection: "column",
  }),
  sidebar: {
  },
  tab: {
    height: 64,
    zIndex: 99,
  },
}));

const DefaultPageLayout = ({
  header,
  content,
  loading,
  error,
  accessDenied,
  classes,
  tabs,
  sidebar,
}) => {
  const theme = useTheme();
  const hasTabs = tabs;
  const styles = useStyles({ hasTabs });
  const dispatch = useDispatch();

  const isOpen = useSelector(isSidebarOpen);
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const menuVariant = matches ? "permanent" : "temporary";

  useEffect(() => {
    if (matches !== isOpen) {
      dispatch(setSideBarStatus(matches)); // also the height of the page
    }
    // eslint-disable-next-line
  }, [matches])

  return (
    <StateContainer loading={loading} error={error} accessDenied={accessDenied}>
      <div className={clsx(styles.root, classes?.root)}>
        <div className={styles.topBg} />
        <div className={clsx(styles.contentWrapper, classes?.contentWrapper)}>
          <div className={clsx(styles.header, classes?.header)}>
            {header}
          </div>
          <div className={clsx(styles.contentContainer, classes?.contentContainer)}>
            {sidebar && (
              <div className={clsx(styles.sidebar, classes?.sidebar)}>
                {typeof sidebar === "function" ? sidebar({ isOpen, menuVariant }) : sidebar}
              </div>
            )}
            <div className={clsx(styles.contentCard, sidebar && isOpen && "ml-0", classes?.contentCard)}>
              {tabs && <div className={clsx(styles.tab, classes?.tab)}>{tabs}</div>}
              <div className={clsx(styles.content, classes?.content)}>
                {content}
              </div>
            </div>
          </div>
        </div>
      </div>
    </StateContainer>
  );
};

export default DefaultPageLayout;
