import React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { find, map, isEmpty, join } from "lodash";
import { blue } from "@material-ui/core/colors";

import ReferralCategoryLabel from "app/main/referrals/components/referral-category-label";
import { getReferralActionTypeSettings } from "app/auth/store/reducers/system-configuration";
import { ArrowRightIcon } from "helpers/icon-finder";
import DefaultItem from "components/items/default-item";
import IconButton from "components/items/icon-button";
import ActivityLogItem from "components/items/activity-log/item";
import { getAllCodeSetValues } from "app/main/codeSet/reducers/codeSet.reducers";

const EditItem = (name, originalValue, newValue, description) => (
  <DefaultItem
    key={name}
    label={description}
    className="flex-col"
    alignment="items-start"
    labelProps={{ variant: "caption", color: "textSecondary" }}
    labelClassName="w-auto"
    content={<Typography noWrap>{originalValue} <ArrowRightIcon size={12} className="mx-8" /> {newValue} </Typography>}
  />
);

const ActionTypeItem = ({ activity }) => {
  const referralActionTypeSettings = useSelector(getReferralActionTypeSettings);
  const status = find(referralActionTypeSettings, x => x.actionType === activity.actionType) || { icon: "lens", color: blue[600], label: activity.actionType };
  const showPopup = !isEmpty(activity.message) || !isEmpty(activity.referralChangesJson);
  const codeSetValues = useSelector(getAllCodeSetValues);

  const displayCodeSetValue = codeSetValueId => find(codeSetValues, x => x.id === codeSetValueId)?.description;

  const actionCodeSet = () => {
    if (isEmpty(activity.codeSetValues)) return null;

    return map(activity.codeSetValues, x => <div key={x}>{displayCodeSetValue(x)}</div>);
  };

  const title = () => (
    <>
      <Typography style={{ color: status.color }} className="font-bold mr-8">{status.actionedName}</Typography>
      {activity.triageCategory
        && <ReferralCategoryLabel triageCategory={activity.triageCategory} isUrgent={activity.isUrgent} />}
    </>
  );

  const content = () => {
    if (activity.referralChangesJson) {
      return `${join(map(JSON.parse(activity.referralChangesJson), x => x.description), ", ")}`;
    }
    return actionCodeSet() ?? activity.message;
  };

  const popupContent = () => {
    if (activity.referralChangesJson) {
      return (
        <div className="items-container with-horizontal-divider sm">
          {map(JSON.parse(activity.referralChangesJson), x => (
            EditItem(x.name, x.originalValue, x.newValue, x.description)
          ))}
        </div>
      );
    }
    return (
      <div className="pt-16 items-container">
        {actionCodeSet()}
        <div>{activity.message}</div>
      </div>
    );
  };

  return (
    <ActivityLogItem
      date={activity.createdDateLocalised}
      user={activity.IsSystemAction && activity.actionedBy === null ? "System" : activity.actionedBy}
      icon={status.icon}
      iconColor={status.color}
      activeColor={status.color}
      title={(
        <div className="flex items-center">
          {title()}
        </div>
      )}
      content={content()}
      renderPopupContent={showPopup ? ({ close }) => (
        <>
          <div className="flex justify-between items-center">
            {title()}
            <div className="flex items-center justify-end">
              <IconButton title="Close" onClick={close} icon="close" />
            </div>
          </div>
          <Typography variant="caption">by {activity.actionedBy} at {activity.createdDateLocalised}</Typography>
          {popupContent()}
        </>
      ) : null}
    />
  );
};

export default ActionTypeItem;
