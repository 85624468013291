import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { reduce, map } from "lodash";

import { AutoComplete } from "components/inputs";
import { validateRequired } from "utils/validators";
import { getEndorsementTypeSettings, areEndorsementTypesLoading, getOrgUnitsForOrgUnitsSelector, isOrgUnitsSelectorLoading } from "app/main/orgUnits/reducers/orgUnit.reducers";
import { fetchOrgUnitsForSelector, fetchEndorsementTypeConfiguration } from "app/main/orgUnits/actions/orgUnit.actions";
import Form from "components/form";
import { OrgUnitsIcon, UserRoleIcon } from "helpers/icon-finder";

const orgUnitLabel = "Site";
const validateOrgUnit = value => validateRequired(orgUnitLabel)(value);

const endorsedByTypeLabel = "Endorsement Types";
const validateEndorsedByType = value => validateRequired(endorsedByTypeLabel)(value);

const EndorsementTypeFields = props => {
  const { selectedOrgUnitId, endorsementTypes } = props;
  const dispatch = useDispatch();

  const isOrgUnitLoading = useSelector(isOrgUnitsSelectorLoading);
  const orgUnits = useSelector(getOrgUnitsForOrgUnitsSelector);

  const endorsementTypesLoading = useSelector(state => areEndorsementTypesLoading(state, selectedOrgUnitId));
  const endorsementTypeSettings = useSelector(state => getEndorsementTypeSettings(state, selectedOrgUnitId));

  const endorsedByTypesOptions = reduce(endorsementTypeSettings, (results, endorsementTypeSetting) => {
    if (!endorsementTypeSetting.enabled) {
      return results;
    }

    if (endorsementTypes?.some(x => x.orgUnitId === selectedOrgUnitId && x.endorsedByType === endorsementTypeSetting.endorsedByType)) {
      return results;
    }

    return [...results, { value: endorsementTypeSetting.endorsedByType, label: endorsementTypeSetting.endorsedByTypeLabel }];
  }, []);

  return (
    <>
      <Field
        name="endorsementType.orgUnitId"
        loading={isOrgUnitLoading}
        label={orgUnitLabel}
        component={AutoComplete}
        options={map(orgUnits, orgUnit => ({ label: orgUnit.name, value: orgUnit.id }))}
        validate={validateOrgUnit}
        icon={<OrgUnitsIcon />}
        onChange={(value, change) => {
          change("endorsementType.orgUnitId", value?.value);
          change("endorsementType.orgUnitName", value?.label);
          dispatch(fetchEndorsementTypeConfiguration(value?.value));
        }}
      />
      <Field
        name="endorsementType.endorsedByType"
        label={endorsedByTypeLabel}
        component={AutoComplete}
        loading={endorsementTypesLoading}
        options={endorsedByTypesOptions}
        validate={validateEndorsedByType}
        icon={<UserRoleIcon />}
        onChange={(value, change) => {
          change("endorsementType.endorsedByType", value?.value);
          change("endorsementType.endorsedByTypeLabel", value?.label);
        }}
      />
    </>
  );
};

const EndorsementTypeForm = props => {
  const { endorsementTypes, onSubmit, ...other } = props;

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchOrgUnitsForSelector({ orgUnitTypes: ["Site"], currentOrgUnitId: other.orgUnit.id }));
  }, [dispatch, other.orgUnit.id]);

  return (
    <Form
      initialValues={{ endorsementType: { orgUnitId: null, endorsedByType: null } }}
      contentProps={other}
      onSubmit={onSubmit}
      content={({ values }) => (
        <EndorsementTypeFields selectedOrgUnitId={values.endorsementType.orgUnitId} endorsementTypes={endorsementTypes} />
      )}
    />
  );
};

export default EndorsementTypeForm;
