import React from "react";
import { withRouter } from "react-router-dom";

import DefaultPanelLayout from "components/items/default-panel-layout";
import EncounterFab from "app/main/patients/encounters/components/encounter-fab";
import EncounterSummaryListContainer from "app/main/patients/encounters/containers/encounter-summary-list-container";

import AppointmentList from "app/main/patients/appointments/components/appointment-list";
import EncounterList from "./encounter-list";

const EncounterSummaryPanel = ({
  patientId,
  history,
  ...other
}) => (
  <EncounterSummaryListContainer
    numberOfItems={3}
    patientId={patientId}
    renderContent={({
      encounter: {
        encounters,
        // actions
        onRecordEncounter,
        onEditEncounter,
        onRemoveEncounter,
        // states
        isEmpty: isEncountersEmpty,
        isError: isEncountersError,
      },
      appointment: {
        appointments,
        // actions
        onScheduleAppointment,
        onCancelAppointment,
        onEditAppointment,
        onRecordAppointmentEncounter,
        onRemoveAppointment,
        onCreateAssessment,
        // states
        isEmpty: isAppointmentsEmpty,
        isError: isAppointmentsError,
      },
      isEmpty,
      isLoading,
      isError,
    }) => (
      <DefaultPanelLayout
        isEmpty={isEmpty}
        isLoading={isLoading}
        error={isError}
        title="Encounters"
        icon="perm_contact_calendar"
        emptyTitle="No Recorded Encounters"
        headerRightContent={(
          <EncounterFab
            patientId={patientId}
            onScheduleAppointment={onScheduleAppointment}
            onRecordEncounter={onRecordEncounter}
          />
        )}
        action={!(isAppointmentsEmpty && isEncountersEmpty)
          && {
            label: "View More",
            onClick: () => history.push(`/patients/${patientId}?tab=encounters`),
          }}
        {...other}
      >
        <>
          <AppointmentList
            title="Upcoming Encounters"
            appointments={appointments}
            onRecordAppointmentEncounter={onRecordAppointmentEncounter}
            onRemoveAppointment={onRemoveAppointment}
            onEditAppointment={onEditAppointment}
            onCancelAppointment={onCancelAppointment}
            onCreateAssessment={onCreateAssessment}
            stateProps={{
              isEmpty: isAppointmentsEmpty,
              isError: isAppointmentsError,
            }}
            emptyTitle="No Upcoming Encounters"
          />
          <EncounterList
            title="Past Encounters"
            encounters={encounters}
            onRemoveEncounter={onRemoveEncounter}
            onEditEncounter={onEditEncounter}
            onCreateAssessment={onCreateAssessment}
            className="mt-24"
            stateProps={{
              isEmpty: isEncountersEmpty,
              isError: isEncountersError,
            }}
            emptyTitle="No Past Encounters"
          />
        </>
      </DefaultPanelLayout>
    )}
  />
);

export default withRouter(EncounterSummaryPanel);
