import React from "react";
import { ThemeProvider as Provider } from "@material-ui/styles";
import { useSelector } from "react-redux";

const ThemeProvider = props => {
  const mainTheme = useSelector(({ theme }) => theme.currentTheme);
  return (
    <Provider theme={mainTheme}>
      {props.children}
    </Provider>
  );
};

export default React.memo(ThemeProvider);
