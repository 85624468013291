import { get, mapKeys, map } from "lodash";
import curryConnector from "utils/connectors";

export const SPECIALTY_PROFILES_STATE_KEY = "specialtyprofiles";
export const SPECIALTY_PROFILES_PAGE_SIZE = 50;
export const SPECIALTY_PROFILES_PAGE_SIZE_OPTIONS = [5, 10, 25, 50];

const curry = fn => curryConnector(fn, SPECIALTY_PROFILES_STATE_KEY);

export const ActionTypes = {
  LOADING_SPECIALTY_PROFILES: "LOADING_SPECIALTY_PROFILES",
  LOADED_SPECIALTY_PROFILES: "LOADED_SPECIALTY_PROFILES",
  ERROR_LOADING_SPECIALTY_PROFILES: "ERROR_LOADING_SPECIALTY_PROFILES",

  LOADING_SPECIALTY_PROFILE: "LOADING_SPECIALTY_PROFILE",
  LOADED_SPECIALTY_PROFILE: "LOADED_SPECIALTY_PROFILE",
  ERROR_LOADING_SPECIALTY_PROFILE: "ERROR_LOADING_SPECIALTY_PROFILE",
  RESET_CURRENT_SPECIALTY_PROFILE: "RESET_CURRENT_SPECIALTY_PROFILE",

  SET_CURRENT_SPECIALTY_PROFILE: "SET_CURRENT_SPECIALTY_PROFILE",
  SAVED_SPECIALTY_PROFILE: "SAVED_SPECIALTY_PROFILE",
  CREATED_SPECIALTY_PROFILE: "CREATED_SPECIALTY_PROFILE",

  LOADING_SEARCHED_SPECIALTIES: "LOADING_SEARCHED_SPECIALTIES",
  ERROR_LOADING_SEARCHED_SPECIALTIES: "ERROR_LOADING_SEARCHED_SPECIALTIES",
  LOADED_SEARCHED_SPECIALTIES: "LOADED_SEARCHED_SPECIALTIES",

  LOADING_SUB_SPECIALTIES: "LOADING_SUB_SPECIALTIES",
  ERROR_LOADING_SUB_SPECIALTIES: "ERROR_LOADING_SUB_SPECIALTIES",
  LOADED_SUB_SPECIALTIES: "LOADED_SUB_SPECIALTIES",
  LOADING_SPECIALTY_ROLES: "LOADING_SPECIALTY_ROLES",
  ERROR_LOADING_SPECIALTY_ROLES: "ERROR_LOADING_SPECIALTY_ROLES",
  LOADED_SPECIALTY_ROLES: "LOADED_SPECIALTY_ROLES",
};

const INITIAL_STATE = {
  all: [],
  pages: {},
  pageInfo: { pageNumber: 1, pageSize: SPECIALTY_PROFILES_PAGE_SIZE, totalRecords: 0 },
  searchParams: { },
  current: {},
  searchedSpecialtyProfiles: [],
  subSpecialties: [],
  specialtyRoles: [],
  meta: {
    current: {
      loading: false,
      loaded: false,
      error: null,
    },
    subSpecialties: {
      loading: false,
      loaded: false,
      error: null,
    },
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_SPECIALTY_PROFILES:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: true, error: false } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.ERROR_LOADING_SPECIALTY_PROFILES:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: false, error: action.payload.message } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.LOADED_SPECIALTY_PROFILES:
      return {
        ...state,
        all: { ...state.all, ...mapKeys(action.payload.specialtyProfiles, x => x.id) },
        pages: { ...state.pages, [action.payload.pageInfo.pageNumber]: { loading: false, error: false, loaded: true, ids: map(action.payload.specialtyProfiles, x => x.id) } },
        pageInfo: action.payload.pageInfo,
        searchParams: action.payload.searchParams,
      };
    case ActionTypes.LOADING_SPECIALTY_PROFILE:
      return {
        ...state,
        meta: {
          ...state.meta,
          current: {
            ...state.meta.current,
            loading: true,
          },
        },
      };
    case ActionTypes.SET_CURRENT_SPECIALTY_PROFILE:
    case ActionTypes.LOADED_SPECIALTY_PROFILE:
      return {
        ...state,
        current: action.payload.specialtyProfile,
        meta: {
          ...state.meta,
          current: {
            ...state.meta.current,
            loading: false,
            loaded: true,
            error: null,
          },
        },
      };
    case ActionTypes.ERROR_LOADING_SPECIALTY_PROFILE:
      return {
        ...state,
        meta: {
          ...state.meta,
          current: {
            loading: false,
            loaded: false,
            error: action.payload.message, // confirm correct? used to be action.error???
          },
        },
      };
    case ActionTypes.RESET_CURRENT_SPECIALTY_PROFILE:
      return {
        ...state,
        current: INITIAL_STATE.current,
        meta: {
          ...state.meta,
          current: INITIAL_STATE.meta.current,
        },
      };
    case ActionTypes.SAVED_SPECIALTY_PROFILE:
    case ActionTypes.CREATED_SPECIALTY_PROFILE:
      return {
        ...state,
        current: action.payload.specialtyProfile,
        all: { ...state.all, [action.payload.specialtyProfile.id]: { ...action.payload.specialtyProfile } },
      };
    case ActionTypes.LOADING_SEARCHED_SPECIALTIES:
      return {
        ...state,
        searchedSpecialtyProfiles: { ...state.specialtyProfiles, loading: true },
      };
    case ActionTypes.ERROR_LOADING_SEARCHED_SPECIALTIES:
      return {
        ...state,
        searchedSpecialtyProfiles: { ...state.specialtyProfiles, loading: false, error: action.payload.message },
      };
    case ActionTypes.LOADED_SEARCHED_SPECIALTIES:
      return {
        ...state,
        searchedSpecialtyProfiles: {
          ...state.searchedSpecialtyProfiles,
          all: action.payload.specialtyProfiles,
          loading: false,
          loaded: true,
        },
      };
    case ActionTypes.LOADING_SUB_SPECIALTIES:
      return {
        ...state,
        meta: {
          ...state.meta,
          subSpecialties: { loading: true },
        },
      };
    case ActionTypes.LOADED_SUB_SPECIALTIES:
      return {
        ...state,
        subSpecialties: action.payload.subSpecialties,
        meta: {
          ...state.meta,
          subSpecialties: { loading: false, loaded: true, error: null },
        },
      };
    case ActionTypes.ERROR_LOADING_SUB_SPECIALTIES:
      return {
        ...state,
        meta: {
          ...state.meta,
          subSpecialties: { loading: false, loaded: false, error: action.payload.message },
        },
      };
    case ActionTypes.LOADING_SPECIALTY_ROLES:
      return {
        ...state,
        specialtyRoles: {
          ...state.specialtyRoles,
          [action.payload.specialtyId]: { loading: true, loaded: false, error: false, all: null },
        },
      };
    case ActionTypes.LOADED_SPECIALTY_ROLES:
      return {
        ...state,
        specialtyRoles: {
          ...state.specialtyRoles,
          [action.payload.specialtyId]: { loading: false, loaded: true, error: false, all: action.payload.specialtyRoles },
        },
      };
    case ActionTypes.ERROR_LOADING_SPECIALTY_ROLES:
      return {
        ...state,
        specialtyRoles: {
          ...state.specialtyRoles,
          [action.payload.specialtyId]: { loading: false, loaded: false, error: action.payload.message, all: null },
        },
      };
    default:
      return state || INITIAL_STATE;
  }
};

export const isPageLoading = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loading"], false);
});

export const isPageLoaded = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loaded"], false);
});

export const getPageInfo = curry(({ localState }) => localState.pageInfo);

export const getErrorMessage = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "error"], null);
});

export const isCurrentSpecialtyProfileLoading = curry(state => state.localState.meta.current.loading === true);

export const isCurrentSpecialtyProfileLoaded = curry(state => state.localState.meta.current.loaded === true);

export const isCurrentSpecialtyProfileError = curry(state => state.localState.meta.current.error);

export const getCurrentSpecialtyProfile = curry(state => get(state.localState, ["current"], null));

export const getSpecialtyProfileSearchTerms = curry(({ localState }) => localState.searchParams);

export const getAllSpecialtyProfiles = curry(({ localState }) => {
  const specialtyProfiles = get(localState, ["all"], {});
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  return map(get(localState.pages, [pageNumber, "ids"], []), key => specialtyProfiles[key]);
});

export const areSearchedSpecialtyProfilesLoading = curry(state => get(state.localState.searchedSpecialtyProfiles, ["loading"], false) === true);

export const areSearchedSpecialtyProfilesLoaded = curry(state => get(state.localState.searchedSpecialtyProfiles, ["loaded"], false) === true);

export const areSubSpecialtiesLoading = curry(state => state.localState.meta.subSpecialties.loading === true);

export const areSubSpecialtiesLoaded = curry(state => state.localState.meta.subSpecialties.loaded === true);

export const getSubSpecialties = curry(({ localState }) => localState.subSpecialties);

export const getSpecialtyRoles = curry(({ localState }, specialtyId) => get(localState.specialtyRoles, [specialtyId, "all"], null));

export const areSpecialtyRolesLoading = curry(({ localState }, specialtyId) => get(localState.specialtyRoles, [specialtyId, "loading"], false) === true);

export const areSpecialtyRolesLoaded = curry(({ localState }, specialtyId) => get(localState.specialtyRoles, [specialtyId, "loaded"], false) === true);
