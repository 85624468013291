import { get, concat, find, orderBy, filter } from "lodash";
import curryConnector from "utils/connectors";

export const ALLERGIES_STATE_KEY = "allergies";

const curry = fn => curryConnector(fn, ALLERGIES_STATE_KEY);

export const ActionTypes = {
  LOADING_ALLERGIES: "LOADING_ALLERGIES",
  LOADED_ALLERGIES: "LOADED_ALLERGIES",
  ERROR_LOADING_ALLERGIES: "ERROR_LOADING_ALLERGIES",

  LOADING_CURRENT_ALLERGY: "LOADING_CURRENT_ALLERGY",
  ERROR_LOADING_CURRENT_ALLERGY: "ERROR_LOADING_CURRENT_ALLERGY",
  LOADED_CURRENT_ALLERGY: "LOADED_CURRENT_ALLERGY",

  SAVED_ALLERGY: "SAVED_ALLERGY",
  CREATED_ALLERGY: "CREATED_ALLERGY",
  DELETED_ALLERGY: "DELETED_ALLERGY",
};

const INITIAL_STATE = {
  all: {},
  current: null,
};

const addOrUpdateAllergy = (state, action) => {
  const { payload: { allergy, patientId } } = action;
  let allergies = get(state.all, [patientId, "allergies"], []);
  allergies = orderBy(concat(filter(allergies, x => x.id !== allergy.id), [allergy]), [a => a.reportedDate || ""], ["desc"]);

  return {
    ...state,
    current: allergy,
    all: { ...state.all, [patientId]: { ...state.all[patientId], allergies } },
  };
};

const removeAllergy = (state, action) => {
  const { payload: { allergy, patientId } } = action;
  let allergies = get(state.all, [patientId, "allergies"], []);
  allergies = orderBy(filter(allergies, x => x.id !== allergy.id), [a => a.reportedDate || ""], ["desc"]);

  return {
    ...state,
    all: { ...state.all, [patientId]: { ...state.all[patientId], allergies } },
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_ALLERGIES:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: true } },
      };
    case ActionTypes.ERROR_LOADING_ALLERGIES:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: false, error: action.payload.message } },
      };
    case ActionTypes.LOADED_ALLERGIES:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]:
            {
              ...state.all[action.payload.patientId],
              loading: false,
              allergies: action.payload.allergies,
            },
        },
      };
    case ActionTypes.LOADING_CURRENT_ALLERGY:
      return {
        ...state,
        current: {
          ...state.current,
          loading: true,
        },
      };
    case ActionTypes.ERROR_LOADING_CURRENT_ALLERGY:
      return {
        ...state,
        current: {
          ...state.current,
          error: action.error,
        },
      };
    case ActionTypes.LOADED_CURRENT_ALLERGY:
      return {
        ...state,
        current: action.payload.allergy,
      };
    case ActionTypes.SAVED_ALLERGY:
    case ActionTypes.CREATED_ALLERGY:
      return addOrUpdateAllergy(state, action);
    case ActionTypes.DELETED_ALLERGY:
      return removeAllergy(state, action);
    case ActionTypes.SET_CURRENT_ALLERGY:
      return {
        ...state,
        current: action.payload.allergy,
      };
    default:
      return state;
  }
};

export const areAllergiesLoading = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loading"], false));

export const areAllergiesLoaded = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loaded"], false));

export const allergiesLoadingError = curry(({ localState }, patientId) => get(localState, ["all", patientId, "error"], null));

export const getAllergiesByPatientId = curry(({ localState }, patientId) => get(localState, ["all", patientId, "allergies"], []));

export const getAllergyById = curry(({ localState }, patientId, allergyId) => {
  const allergies = get(localState, ["all", patientId, "allergies"], []);
  return find(allergies, x => x.id === allergyId) || {};
});

export const getCurrentAllergy = curry(({ localState }) => get(localState, ["current"], null));

export const isCurrentAllergyLoading = curry(({ localState }) => get(localState, ["current", "loading"], false));

export const getErrorMessage = curry(({ localState }, patientId) => get(localState, ["all", patientId, "error"], null));
