import React from "react";
import { isEmpty, map, find } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Icon, Hidden, Typography } from "@material-ui/core";
import clsx from "clsx";
import Highlighter from "react-highlight-words";

import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import PatientIdentifierForm from "app/main/patients/components/patient-identifier-form";
import getPatientIdentifierLabel from "app/main/patients/helpers/get-patient-identifier-label";
import withPermissions from "permissions/withPermissions";
import { getPrimaryPatientIdentifierSettings } from "app/auth/store/reducers/system-configuration";
import DefaultItem from "components/items/default-item";

const IdentifierItem = ({ showIcon, children }) => (
  <div className="flex-row-container">
    {showIcon && <Hidden smDown><div className="mr-4 flex"><Icon fontSize="small">account_box</Icon></div></Hidden>}
    <div className="flex-row-container with-gutter enable-shrink">
      {children}
    </div>
  </div>
);

const PatientIdentifierLabel = ({
  patientId,
  patientIdentifiers,
  hasPermissionPatientsIdentifiersUpdate,
  showIcon = true,
  textVariant = "body1",
  highlightTerm = [],
  disabled,
}) => {
  const dispatch = useDispatch();
  const settings = useSelector(getPrimaryPatientIdentifierSettings);
  const codes = settings?.displayPatientIdentifierTypes;
  const primaryPatientIdentifier = settings?.type;
  const identifierOptions = settings?.options;

  if (isEmpty(codes)) return null;

  const editPatientIdentifier = identifier => (
    dispatch(openDialog({
      children: (
        <PatientIdentifierForm
          patientIdentifier={identifier}
          patientId={patientId}
          onCancel={() => dispatch(closeDialog())}
          onSucceed={() => dispatch(closeDialog())}
          disabledPatientIdentifierTypeCode
        />
      ),
    }))
  );

  return (
    <IdentifierItem showIcon={showIcon}>
      {map(codes, code => {
        let item = !isEmpty(patientIdentifiers) ? find(patientIdentifiers, x => x.patientIdentifierTypeCode === code) : null;
        const label = find(identifierOptions, x => x.value === code)?.label ?? getPatientIdentifierLabel(code);
        const enableEdit = !disabled && patientId && (code === primaryPatientIdentifier || code === "SLHMN") && hasPermissionPatientsIdentifiersUpdate;
        const textContent = item?.identifier ?? "None Recorded";
        const content = () => (
          <Typography
            onClick={enableEdit ? () => editPatientIdentifier(item) : null}
            className={clsx(enableEdit ? "cursor-pointer" : "cursor-default")}
            color={enableEdit ? "primary" : "inherit"}
            variant={textVariant}
          >
            {isEmpty(highlightTerm) ? textContent : (
              <Highlighter autoEscape searchWords={highlightTerm} textToHighlight={textContent} />
            )}
          </Typography>
        );

        item = {
          ...item,
          patientIdentifierTypeCode: code,
        };

        return (
          <DefaultItem
            key={code}
            label={label}
            content={content()}
            className="flex-wrap sm:flex-no-wrap"
            labelClassName="w-auto mr-4"
            labelProps={{ variant: textVariant }}
            role="presentation"
            onClick={enableEdit ? () => editPatientIdentifier(item) : null}
            textColor={enableEdit ? "primary" : "inherit"}
          />
        );
      })}
    </IdentifierItem>
  );
};

export default withPermissions("PatientsIdentifiersUpdate")(PatientIdentifierLabel);
