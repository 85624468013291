import React from "react";
import TabContent from "components/items/tab-content";
import ReferralWorkListPanel from "app/main/referralWorklist/components/referral-worklist-panel";
import ReferralActivityPanel from "app/main/referralWorklist/components/referral-activity-panel";

export default ({
  referral,
}) => (
  <TabContent>
    <ReferralWorkListPanel referral={referral} />
    <ReferralActivityPanel referral={referral} isLast />
  </TabContent>
);
