import { blue } from "@material-ui/core/colors";

export const primary = {
  50: "#e0ebf3",
  100: "#b3cee2",
  200: "#80aece",
  300: "#4d8dba",
  400: "#2674ac",
  500: "#005c9d",
  600: "#005495",
  700: "#004a8b",
  800: "#004181",
  900: "#00306f",
  A100: "#9ebfff",
  A200: "#6b9eff",
  A400: "#387cff",
  A700: "#1f6cff",
  contrastText: "#fff",
};

export const secondary = blue;
