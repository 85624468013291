import { ApiActions } from "middleware/call-api";
import { ActionTypes,
  areImmunisationsLoading,
  areImmunisationsLoaded,
  getImmunisationById } from "../reducers/immunisations.reducers";

export const setCurrentImmunisation = immunisation => ({
  type: ActionTypes.LOADED_CURRENT_IMMUNISATION,
  payload: { immunisation },
});

export const fetchPatientImmunisations = patientId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/immunisations`,
    bailout: state => areImmunisationsLoaded(state, patientId) || areImmunisationsLoading(state, patientId),
    entityType: "immunisations",
    patientId,
  },
});

export const fetchImmunisationsById = (patientId, immunisationId) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/immunisations/${immunisationId}`,
    types: [ActionTypes.LOADING_CURRENT_IMMUNISATION, ActionTypes.LOADED_CURRENT_IMMUNISATION, ActionTypes.ERROR_LOADING_CURRENT_IMMUNISATION],
    patientId,
    immunisationId,
  },
});

/** * Add Immunisation */
export const recordNewImmunisation = patientId => setCurrentImmunisation({
  id: null,
  patientId,
  vaccine: null,
  immunityPeriod: null,
  indication: null,
  note: null,
  givenDate: null,
  givenBy: null,
  expiry: null,
});

export const recordImmunisation = immunisation => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${immunisation.patientId}/immunisations/new`,
    body: { immunisation },
    entityType: "immunisation",
    patientId: immunisation.patientId,
  },
});

export const editExistingImmunisation = (patientId, immunisationId) => (dispatch, getState) => {
  const immunisation = getImmunisationById(getState(), patientId, immunisationId);
  dispatch(setCurrentImmunisation(immunisation));
};

export const editImmunisation = immunisation => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/patient/${immunisation.patientId}/immunisations/edit`,
    body: { immunisation },
    entityType: "immunisation",
    patientId: immunisation.patientId,
  },
});

export const removeImmunisation = (patientId, immunisationId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/patient/${patientId}/immunisations/remove`,
    body: { immunisation: { id: immunisationId, patientId } },
    entityType: "immunisation",
    patientId,
    immunisationId,
  },
});
