import React from "react";
import { useSelector } from "react-redux";

import withPermissions from "permissions/withPermissions";
import ScreenHeader from "components/items/screen-header";
import { getOrgUnitSettings } from "app/auth/store/reducers/system-configuration";
import OrgUnitSearch from "./orgUnit-search";

const OrgUnitPageHeader = ({
  history,
  orgUnitType,
  parentOrgUnitId,
  parentOrgUnitType,
  disabledSearch,
  hasPermissionOrgUnitCreate,
}) => {
  const orgUnitsSettings = useSelector(state => getOrgUnitSettings(state, orgUnitType));

  const enableOrgUnitCreate = hasPermissionOrgUnitCreate && orgUnitsSettings?.parentOrgUnitType === parentOrgUnitType;

  return (
    <ScreenHeader
      title={orgUnitsSettings?.namePlural}
      renderSearch={!disabledSearch && <OrgUnitSearch orgUnitId={parentOrgUnitId} />}
      action={enableOrgUnitCreate && {
        label: `Add ${orgUnitsSettings?.nameSingle ?? "Org Unit"}`,
        onClick: () => history.push(`/admin/org-units/${orgUnitType}/new?parentOrgUnitId=${parentOrgUnitId}&parentOrgUnitType=${parentOrgUnitType}`),
      }}
    />
  );
};

export default withPermissions("OrgUnitCreate")(OrgUnitPageHeader);
