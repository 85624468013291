import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import withPermissions from "permissions/withPermissions";
import DefaultPageLayout from "components/layouts/default-page-layout";
import useSetUrl from "app/main/admin/hooks/useSetUrl";

import { createNewUser } from "../actions/users.actions";
import UserForm from "../components/user-form";

const UserNewPage = ({
  route,
  hasPermissionUsersCreate,
}) => {
  const dispatch = useDispatch();
  const { goBack, goBackWithState } = useSetUrl(route);

  useEffect(() => {
    dispatch(createNewUser());
  }, [dispatch]);

  return (
    <DefaultPageLayout
      accessDenied={!hasPermissionUsersCreate}
      content={(
        <UserForm
          submitLabel="Add"
          onCancel={goBack}
          onSucceed={goBackWithState}
        />
      )}
    />
  );
};

export default withPermissions("UsersCreate")(UserNewPage);
