import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Field } from "formik";

import { getCurrentCodeSetPageInfo, getCurrentSearchParams } from "app/main/codeSet/reducers/codeSet.reducers";
import { fetchCodeSetValues } from "app/main/codeSet/actions/codeSet.actions";
import AdvancedFilter, { FilterForm, FilterSection } from "components/items/advanced-filter";

import { TextInput, CheckboxInput } from "components/inputs";

const CodeSetValueSearch = () => {
  const { codeSetTypeCode } = useParams();
  const dispatch = useDispatch();
  const currentSearchParams = useSelector(getCurrentSearchParams);
  const pageInfo = useSelector(getCurrentCodeSetPageInfo);

  const searchCodeSetValue = params => dispatch(fetchCodeSetValues(1, pageInfo.pageSize, { ...params, codeSetTypeCode }));

  const onSearchText = val => searchCodeSetValue({ ...currentSearchParams, name: val });

  const handleSubmit = ({ searchParams }) => {
    searchCodeSetValue(searchParams);
  };

  const handleReset = () => {
    searchCodeSetValue(null);
  };

  return (
    <AdvancedFilter
      onSearch={onSearchText}
      searchValue={currentSearchParams?.name ?? ""}
      withPaperWrapper={false}
      filterContentAnchor="right"
      content={onClose => (
        <FilterForm
          initialValues={{ searchParams: currentSearchParams }}
          onSubmit={handleSubmit}
          onReset={handleReset}
          onClose={onClose}
          filters={() => (
            <div>
              <FilterSection>
                <Field
                  label="Code | Description"
                  name="searchParams.name"
                  component={TextInput}
                  icon="search"
                />
              </FilterSection>
              <FilterSection>
                <Field
                  name="searchParams.showInactive"
                  label="Show inactive values"
                  component={CheckboxInput}
                />
              </FilterSection>
            </div>
          )}
        />
      )}
    />
  );
};

export default CodeSetValueSearch;
