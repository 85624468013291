import React from "react";
import { Icon, IconButton } from "@material-ui/core";
import { isEmpty, map } from "lodash";
import { connect } from "react-redux";
import clsx from "clsx";

import DefaultPanelLayout from "components/items/default-panel-layout";
import DiseaseStateForm from "app/main/patients/components/disease-state-form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { setCurrentDiseaseState } from "app/main/patients/actions/patients.actions";
import TextLink from "components/items/text-link";
import withPermissions from "permissions/withPermissions";
import { CareSummaryIcon } from "helpers/icon-finder";
import DefaultItem from "components/items/default-item";

class CareSummaryPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.updateDiseaseState = this.updateDiseaseState.bind(this);
  }

  updateDiseaseState = (patientId, diseaseState) => {
    this.props.setCurrentDiseaseState({ patientId, diseaseState });
    this.props.openDialog({
      children: <DiseaseStateForm title="Disease State" onSucceed={() => this.props.closeDialog()} />,
    });
  };

  render() {
    const {
      patientId,
      diseaseStates,
      hasPermissionPatientsDiseaseStateUpdate,
      hasPermissionPatientsDiseaseStateView,
      ...other
    } = this.props;

    const addDiseaseStates = diseaseState => (hasPermissionPatientsDiseaseStateUpdate ? (() => this.updateDiseaseState(patientId, diseaseState)) : null);

    let DiseaseState = () => <TextLink onClick={addDiseaseStates(null)} content="None Recorded" />;

    if (!isEmpty(diseaseStates)) {
      DiseaseState = () => (
        <div className="flex-row-container enable-shrink">
          {map(diseaseStates, (diseaseState, index) => {
            const isPrimary = diseaseState.status === "Primary";
            const indicator = isPrimary ? " (P)" : " (S)";
            const isLast = index + 1 === diseaseStates.length;
            return (
              <TextLink
                className={clsx(!isLast && "mr-8")}
                key={String(isPrimary ? `primaryDiseaseState${index}` : `secondaryDiseaseState${index}`)}
                onClick={addDiseaseStates(diseaseState)}
                content={`${diseaseState.name}${indicator}`}
              />
            );
          })}
          <IconButton onClick={addDiseaseStates(null)} color="primary" disabled={!hasPermissionPatientsDiseaseStateUpdate}>
            <Icon>add_circle</Icon>
          </IconButton>
        </div>
      );
    }

    return (
      <DefaultPanelLayout
        title="Care Summary"
        icon={<CareSummaryIcon />}
        emptyTitle="No Recorded Care Summary"
        accessDenied={!hasPermissionPatientsDiseaseStateView}
        {...other}
      >
        <DefaultItem label="DISEASE STATE" labelClassName="w-192" content={<DiseaseState />} />
      </DefaultPanelLayout>
    );
  }
}

export default withPermissions(
  "PatientsDiseaseStateUpdate",
  "PatientsDiseaseStateView",
)(connect(
  null,
  {
    setCurrentDiseaseState,
    openDialog,
    closeDialog,
  },
)(CareSummaryPanel));
