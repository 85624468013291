import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { find, startCase, reduce, includes, map } from "lodash";

import { AutoComplete } from "components/inputs";
import { validateRequired } from "utils/validators";
import { getAllRoles, getOrgUnitSettings } from "app/auth/store/reducers/system-configuration";
import { getOrgUnitsForOrgUnitsSelector, isOrgUnitsSelectorLoading } from "app/main/orgUnits/reducers/orgUnit.reducers";
import { fetchOrgUnitsForSelector } from "app/main/orgUnits/actions/orgUnit.actions";
import Form from "components/form";
import { OrgUnitsIcon, UserRoleIcon } from "helpers/icon-finder";

const orgUnitLabel = "Site";
const validateOrgUnit = value => validateRequired(orgUnitLabel)(value);

const roleLabel = "Role";
const validateRole = value => validateRequired(roleLabel)(value);

const UserRoleForm = props => {
  const { userRoles, onSubmit, ...other } = props;
  const dispatch = useDispatch();
  const isOrgUnitLoading = useSelector(isOrgUnitsSelectorLoading);
  const allRoles = useSelector(getAllRoles);
  const orgUnits = useSelector(getOrgUnitsForOrgUnitsSelector);
  const orgUnitSettings = useSelector(getOrgUnitSettings);

  const getRoleOptions = orgUnitId => reduce(allRoles, (results, role) => {
    if (!orgUnitId) return results;

    const orgUnit = find(orgUnits, x => x.id === orgUnitId);
    const selectedOrgUnitSettings = find(orgUnitSettings, x => x.orgUnitType === orgUnit?.orgUnitType);

    if (userRoles?.some(x => x.roleId === role.roleId && x.orgUnitId === orgUnitId)) {
      return results;
    }

    if (selectedOrgUnitSettings?.accessibleRoles == null || includes(selectedOrgUnitSettings?.accessibleRoles, role.roleName)) {
      return [...results, { label: role.roleDescription ?? startCase(role.roleName), value: role.roleId }];
    }

    return results;
  }, []);

  React.useEffect(() => {
    dispatch(fetchOrgUnitsForSelector({ orgUnitTypes: ["Site", "Org"], currentOrgUnitId: other.orgUnit.id }));
  }, [dispatch, other.orgUnit.id]);

  return (
    <Form
      initialValues={{ userRole: { orgUnitId: null, orgUnitName: null, roleId: null, roleName: null } }}
      contentProps={other}
      onSubmit={onSubmit}
      content={({ values }) => (
        <>
          <Field
            name="userRole.orgUnitId"
            loading={isOrgUnitLoading}
            label={orgUnitLabel}
            component={AutoComplete}
            options={map(orgUnits, orgUnit => ({ label: orgUnit.name, value: orgUnit.id }))}
            validate={validateOrgUnit}
            icon={<OrgUnitsIcon />}
            onChange={(value, change) => {
              change("userRole.orgUnitId", value?.value);
              change("userRole.orgUnitName", value?.label);
            }}
          />
          <Field
            name="userRole.roleId"
            label={roleLabel}
            component={AutoComplete}
            options={getRoleOptions(values.userRole?.orgUnitId)}
            validate={validateRole}
            icon={<UserRoleIcon />}
            onChange={(value, change) => {
              change("userRole.roleId", value?.value);
              change("userRole.roleName", value?.label);
            }}
          />
        </>
      )}
    />
  );
};

export default UserRoleForm;
