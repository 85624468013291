import React from "react";
import { useDispatch } from "react-redux";
import connect from "react-redux/es/connect/connect";
import { Field } from "formik";
import { get } from "lodash";
import { PhoneIcon, CellPhoneIcon, AssignedUserIcon } from "helpers/icon-finder";
import CheckboxInput from "components/inputs/checkbox";
import { NoteInput, TextInput } from "components/inputs/text-input";
import SelectInput from "components/inputs/select-input";
import Form from "components/form";
import { validateRequired, validateMaxLength } from "utils/validators";
import { recordOrUpdateContact } from "app/main/patients/actions/contacts.actions";
import { recordOrUpdateNextOfKin } from "app/main/patients/actions/patients.actions";
import { getErrorMessage, areContactsLoading, getCurrentContact, getNextOfKinContact } from "app/main/patients/reducers/contacts.reducers";
import { getRelationshipTypeValues } from "app/auth/store/reducers/system-configuration";

const firstName = "First Name";
const lastName = "Last Name";
const contactNumber = "Contact Number";
const alternativeContactNumber = "Alternative Contact Number";
const contactType = "Contact Type";
const relationshipDescription = "Relationship Description";

const validateFirstName = value => validateRequired(firstName)(value) || validateMaxLength(firstName, 60)(value);
const validateLastName = value => validateRequired(lastName)(value) || validateMaxLength(lastName, 60)(value);
const validateContactNumber = value => validateRequired(contactNumber)(value) || validateMaxLength(contactNumber, 60)(value);
const validateRelationshipDescription = validateMaxLength(relationshipDescription, 255);

const PatientContactForm = ({
  isNOK,
  patientId,
  onSucceed,
  initialValues,
  relationshipTypeValues,
  hasExistingNextOfKinContact,
  ...other
}) => {
  const dispatch = useDispatch();
  const validateSingleNOK = value => {
    if (get(initialValues.contact, ["relationshipType", "value"]) !== "NextOfKin" && value === "NextOfKin" && hasExistingNextOfKinContact) return "Only one Next of Kin contact allowed";
    return validateRequired(contactType)(value);
  };

  const handleSubmit = ({ contact }, { setErrors }) => {
    const relationshipTypeValue = contact.relationshipType?.value ?? contact.relationshipType; // need to get the "value" if adding new contact because returning label / value from api
    const isNOKContact = relationshipTypeValue === "NextOfKin";
    const newContact = { ...contact, relationshipType: relationshipTypeValue };
    const apiCall = isNOKContact ? recordOrUpdateNextOfKin : recordOrUpdateContact;

    dispatch(apiCall(patientId, newContact)).then(response => {
      if (response.error !== true) {
        onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  return (
    <Form
      contentProps={other}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      content={() => (
        <>
          <Field
            name="contact.isEmergencyContact"
            label="Emergency Contact"
            component={CheckboxInput}
          />
          {!isNOK && (
          <Field
            name="contact.relationshipType"
            component={SelectInput}
            icon={<AssignedUserIcon />}
            label={contactType}
            options={relationshipTypeValues}
            validate={validateSingleNOK}
          />
          )}
          <div className="flex flex-col sm:flex-row">
            <div className="flex-1">
              <Field
                name="contact.givenName"
                icon="person"
                label={firstName}
                component={TextInput}
                validate={validateFirstName}
              />
            </div>
            <div className="flex-1">
              <Field
                name="contact.familyName"
                label={lastName}
                component={TextInput}
                validate={validateLastName}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="flex-1">
              <Field
                name="contact.contact"
                icon={<PhoneIcon />}
                label={contactNumber}
                component={TextInput}
                validate={validateContactNumber}
              />
            </div>
            <div className="flex-1">
              <Field
                name="contact.alternativeContact"
                icon={<CellPhoneIcon />}
                label={alternativeContactNumber}
                component={TextInput}
              />
            </div>
          </div>
          <Field
            name="contact.relationshipDescription"
            label={relationshipDescription}
            component={NoteInput}
            validate={validateRelationshipDescription}
          />
        </>
      )}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const { patientId, isNOK } = ownProps;
  let contact = getCurrentContact(state);
  const hasExistingNextOfKinContact = getNextOfKinContact(state, patientId);
  const relationshipTypeValues = getRelationshipTypeValues(state);

  contact = {
    ...contact,
    relationshipType: isNOK ? "NextOfKin" : contact.relationshipType,
  };

  return {
    relationshipTypeValues,
    hasExistingNextOfKinContact,
    initialValues: { contact },
    error: getErrorMessage(state, patientId),
    loading: areContactsLoading(state, patientId),
  };
};

export default connect(mapStateToProps, {})(PatientContactForm);
