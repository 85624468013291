import React from "react";
import { Typography, Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
import { usePopupState, bindPopover, bindHover } from "material-ui-popup-state/hooks";

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(2),
    maxWidth: 320,
  },
  link: {
    cursor: "pointer",
  },
  defaultCursor: {
    cursor: "default",
  },
}));

export default function PopupLink(props) {
  const { label, onClick, content } = props;
  const classes = useStyles();
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
  const popupProps = isEmpty(content) ? null : { ...bindHover(popupState) };

  return (
    <div onClick={onClick} role="presentation" className={onClick ? classes.link : classes.defaultCursor}>
      <Typography color="primary" {...popupProps}>{label}</Typography>
      <Popover
        {...bindPopover(popupState)}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
      >
        {content}
      </Popover>
    </div>
  );
}
