import React, { Component } from "react";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";

import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import EditReferralNoteForm from "app/main/referrals/components/edit-referral-note-form";
import { getUser } from "app/auth/store/reducers/user.reducer";
import withPermissions from "permissions/withPermissions";
import IconButton from "components/items/icon-button";
import ActivityLogItem from "components/items/activity-log/item";
import { NoteIcon } from "helpers/icon-finder";

class NoteItem extends Component {
  onClose = () => {
    this.props.closeDialog();
  };

  popupEditNoteForm = activity => {
    this.props.openDialog({
      children: (
        <EditReferralNoteForm
          title="Notes"
          onSucceed={this.onClose}
          referralId={activity.referralId}
          note={activity.note}
          noteId={activity.id}
          hasPermissionReferralNotesDelete={this.props.hasPermissionReferralNotesDelete}
        />
      ),
    });
  };

  render() {
    const { activity, currentUser, hasPermissionReferralNotesModify, canEdit } = this.props;

    return (
      <ActivityLogItem
        icon={<NoteIcon />}
        title="Note"
        content={activity.note}
        date={activity.createdDateLocalised}
        user={activity.addedBy}
        id={activity.id}
        renderPopupContent={({ close }) => (
          <>
            <div className="flex justify-between items-center">
              <Typography className="font-bold">Note</Typography>
              <div className="flex items-center justify-end">
                <IconButton
                  title={canEdit ? "Edit note" : "Cannot edit note on cancelled referral"}
                  onClick={() => {
                    this.popupEditNoteForm(activity);
                    close();
                  }}
                  disabled={currentUser.userId !== activity.addedByUserId || !hasPermissionReferralNotesModify || !canEdit}
                  icon="edit"
                />
                <div className="ml-16">
                  <IconButton title="Close" onClick={close} icon="close" />
                </div>
              </div>
            </div>
            {activity.lastEditedDateTimeUtc ? (
              <Typography variant="caption">Last edited {activity.editDateLocalised}</Typography>
            ) : <Typography variant="caption">Added by {activity.addedBy} at {activity.createdDateLocalised}</Typography>}
            <div className="pt-16">
              {activity.note}
            </div>
          </>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentUser: getUser(state),
});

export default withPermissions("ReferralNotesModify", "ReferralNotesDelete")(connect(mapStateToProps, { openDialog, closeDialog })(NoteItem));
