import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import downloadFromApi, { openPdf } from "utils/download-from-api";
import { previewAttachmentImage, removeApplicationAttachment } from "app/main/applications/actions/applications.actions";
import { showMessage } from "app/store/actions/message.actions";

class ApplicationAttachmentContainer extends React.Component {
  showErrorMessage = response => {
    this.props.showMessage({
      message: response.payload.exceptionMessage || response.payload.message,
      variant: "error",
    });
  }

  onPreviewImage = attachmentId => {
    const { applicationId, orgUnitId } = this.props;
    return this.props.previewAttachmentImage(applicationId, attachmentId, orgUnitId).then(response => {
      if (response.error === true) {
        this.showErrorMessage(response);
      }
      return response;
    });
  }

  onPreviewPDF = attachmentId => {
    const { applicationId, orgUnitId } = this.props;
    openPdf(`api/applications/${orgUnitId}/${applicationId}/downloadAttachment/${attachmentId}`);
  }

  onDownloadFile = attachmentId => {
    const { applicationId, orgUnitId } = this.props;
    downloadFromApi(`api/applications/${orgUnitId}/${applicationId}/downloadAttachment/${attachmentId}`);
  }

  onRemoveFile = (attachmentId, fileId) => {
    const { applicationId, orgUnitId } = this.props;

    return this.props.removeApplicationAttachment(applicationId, attachmentId, fileId, orgUnitId).then(response => {
      if (response.error === true) {
        this.showErrorMessage(response);
      }
    });
  }

  render() {
    const { renderContent } = this.props;

    return (
      renderContent({
        fetchImageFileData: this.onPreviewImage,
        previewPDF: this.onPreviewPDF,
        downloadFile: this.onDownloadFile,
        removeFile: this.onRemoveFile,
      })
    );
  }
}

ApplicationAttachmentContainer.propTypes = {
  applicationId: PropTypes.string.isRequired,
};

export default connect(
  null,
  {
    previewAttachmentImage,
    removeApplicationAttachment,
    showMessage,
  },
)(ApplicationAttachmentContainer);
