import { isUnauthorisedError } from "utils/errorutil";
import { setAuthErrorMessage } from "app/auth/store/actions/user.actions";

const errorHandler = (res, { dispatch }) => {
  if (isUnauthorisedError(res)) {
    // set the authorization message to statusText
    dispatch(setAuthErrorMessage(res?.statusText));
  }

  return res;
};

export default errorHandler;
