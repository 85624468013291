import React from "react";
import { concat } from "lodash";
import EncounterListContainer from "app/main/patients/encounters/containers/encounter-list-container";
import AppointmentListContainer from "app/main/patients/appointments/containers/appointment-list-container";

// this component combines appointment and encounter list
const EncounterSummaryListContainer = ({
  patientId,
  numberOfItems,
  renderContent,
}) => (
  <EncounterListContainer
    numberOfItems={numberOfItems}
    patientId={patientId}
    renderContent={({ ...encounterProps }) => (
      <AppointmentListContainer
        numberOfItems={numberOfItems}
        patientId={patientId}
        renderContent={({ ...appointmentProps }) => {
          const {
            encounters,
            // states
            isEmpty: isEncountersEmpty,
            isError: isEncountersError,
            isLoading: isEncountersLoading,
          } = encounterProps;

          const {
            appointments,
            // states
            isEmpty: isAppointmentsEmpty,
            isError: isAppointmentsError,
            isLoading: isAppointmentsLoading,
          } = appointmentProps;

          const allEncounters = concat(encounters, appointments);

          const isEmpty = isEncountersEmpty && isAppointmentsEmpty;
          const isLoading = isEncountersLoading || isAppointmentsLoading;
          const isError = isEncountersError || isAppointmentsError;

          return (
            <>
              {renderContent({
                allEncounters,
                encounter: encounterProps,
                appointment: appointmentProps,
                isLoading,
                isEmpty,
                isError,
              })}
            </>
          );
        }}
      />
    )}
  />
);

export default EncounterSummaryListContainer;
