import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";

import ScreenHeader from "components/items/screen-header";
import withPermissions from "permissions/withPermissions";
import DefaultButton from "components/items/default-button";
import { ExcelExportIcon, CodeSetFileImportIcon } from "helpers/icon-finder";
import UserUploadForm from "app/main/users/components/user-upload-form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { showMessage } from "app/store/actions/message.actions";
import { fetchUsersResults } from "app/main/users/actions/users.actions";
import { getPageInfo } from "app/main/users/reducers/users.reducers";

import downloadFromApi from "utils/download-from-api";

import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import DialogPopup from "components/items/dialog-popup";
import UsersSearch from "./users-search";

const UsersPageHeader = props => {
  const { hasPermissionUsersCreate, history, orgUnit } = props;
  const dispatch = useDispatch();
  const pageInfo = useSelector(getPageInfo);
  const { pageSize } = pageInfo;
  const [loadingExport, setLoadingExport] = useState(false);
  const signedInOrgUnit = useSelector(getSignedInOrgUnit);

  const searchUsers = searchParams => dispatch(fetchUsersResults(1, pageSize, searchParams));

  const onSucceed = responsePayload => {
    dispatch(closeDialog());

    if (Object.keys(responsePayload).length > 0) {
      dispatch(showMessage({
        variant: "error",
        message: `Failure to import the following users/rows:\n\n${Object.values(responsePayload)}`,
      }));
    } else {
      dispatch(showMessage({
        variant: "success",
        message: "Successfully imported all users.",
      }));
    }

    searchUsers();// Refresh the list
  };

  const requestExcelFile = url => {
    dispatch(closeDialog());
    setLoadingExport(true);
    downloadFromApi(url).then(() => setLoadingExport(false));
  };

  const handleExport = () => dispatch(openDialog({
    children: (
      <DialogPopup
        title="Export options"
        content={<Typography>Select your export option.</Typography>}
        renderActions={(
          <>
            {/* <DefaultButton
              onClick={() => requestExcelFile(`api/users/${signedInOrgUnit.id}/exportUsers`)}
              label="All Users"
              size="medium"
            /> */}
            <DefaultButton
              onClick={() => requestExcelFile(`api/users/${signedInOrgUnit.id}/exportUserTemplate`)}
              label="Blank Import Template"
              size="medium"
            />
          </>
        )}
      />
    ),
  }));

  const handleImport = () => dispatch(openDialog({
    children: (
      <UserUploadForm
        title="Import users"
        onSucceed={onSucceed}
      />
    ),
  }));

  return (
    <ScreenHeader
      title="Users"
      renderSearch={<UsersSearch orgUnit={orgUnit} />}
      renderActions={(
        hasPermissionUsersCreate && (
        <>
          <DefaultButton
            label="Add a user"
            variant="outlined"
            color="inherit"
            icon="add"
            onClick={() => history.push("/admin/users/new")}
          />
          <DefaultButton
            label="Export"
            variant="outlined"
            color="inherit"
            loading={loadingExport}
            icon={<ExcelExportIcon />}
            onClick={handleExport}
          />
          <DefaultButton
            label="Import"
            variant="outlined"
            color="inherit"
            icon={<CodeSetFileImportIcon />}
            onClick={handleImport}
          />
        </>
        )
      )}
    />
  );
};

export default withPermissions("UsersCreate")(UsersPageHeader);
