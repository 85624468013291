import React from "react";
import { Typography } from "@material-ui/core";
import { ContextNoteItem, ContextItem } from "components/items/default-item";
import normaliseDateTime from "app/main/patients/helpers/normalise-date-time";
import { ProcedureIcon, ConditionIcon } from "helpers/icon-finder";
import PatientContextListItem, { ItemContent } from "app/main/patients/components/patient-context-list-item";

const ProcedureListItem = ({
  item: {
    procedure,
    notes,
    datePerformed,
    relatedCondition,
  },
  ...other
}) => {
  const procedureDate = normaliseDateTime(datePerformed);

  return (
    <PatientContextListItem
      title={procedure}
      {...other}
      renderItemContent={() => (
        <ItemContent
          title={procedure}
          icon={<ProcedureIcon />}
          content={<Typography variant="caption">{procedureDate.date}</Typography>}
        />
      )}
      renderPreviewContent={() => (
        <>
          <ContextItem icon="date_range" content={procedureDate.date} />
          <ContextNoteItem content={notes} />
          <ContextItem icon={<ConditionIcon/>} content={relatedCondition} />
        </>
      )}
    />
  );
};

export default ProcedureListItem;
