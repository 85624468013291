import React from "react";
import LoadingState from "components/items/loading-state";
import useEndorsementTypes from "hooks/use-endorsement-types";
import OrgUnitEndorsementTypeListItem from "../components/orgUnit-endorsement-type-list-item";

export default props => {
  const { loadingEndorsementTypes, endorsementTypeSettings } = useEndorsementTypes(props.orgUnit?.id);

  if (loadingEndorsementTypes) return <LoadingState />;

  return (
    <OrgUnitEndorsementTypeListItem initialFields={endorsementTypeSettings} {...props} />
  );
};
