import { getPatientLabel } from "utils/get-environment-variables";
import PatientMergeLogEntryPage from "./pages/patient-merge-log-entry-page";

const patientLabel = getPatientLabel();

const PatientMergeLogConfig = {
  routes: [
    {
      path: "/admin/patientMerge/:id",
      component: PatientMergeLogEntryPage,
      exact: true,
      title: `${patientLabel.single} Merge Log`,
      backUrl: "/admin/?tab=patientMerge",
    },
  ],
};

export default PatientMergeLogConfig;
