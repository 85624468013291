import React from "react";
import { connect } from "react-redux";
import { forEach, isEmpty } from "lodash";
import { checkPermission } from "app/auth/store/reducers/user.reducer";

/*
*
*  Represents a higher order React function that checks for the permissions given
*  and exposes them to the component with the format hasPermission'permissionName'
*  This can be used to avoid having to define many checkPermission elements on components
*  and pages that need them
*   Usage: withPermissions("CandidatesView", "CandidatesAssign", "CandidatesHeader")(CandidatePage)
*          then within render or other part of CandidatePage
*          if (this.props.hasPermissionCandidatesView) {
*            // do something useful that only users with that permission can do
*          }
*
*/

export default (...permissionStrings) => {
  if (isEmpty(permissionStrings)) {
    throw new Error("Permission strings must be called with permissions");
  }

  const stateToProps = state => {
    const permissionProps = {};
    forEach(permissionStrings, permission => {
      permissionProps[`hasPermission${permission}`] = checkPermission(state, permission);
    });
    return permissionProps;
  };

  return WrappedComponent => connect(stateToProps)(props => <WrappedComponent {...props} />);
};
