import React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";

import PrimaryHeaderPortal from "portals/primaryHeaderPortal";
import { getDisplayNameForExternalSourceDocumentWorklist } from "app/auth/store/reducers/system-configuration";

export default () => {
  const extSourceDocumentTitle = useSelector(getDisplayNameForExternalSourceDocumentWorklist);
  return (
    <PrimaryHeaderPortal>
      <Typography variant="h6">{extSourceDocumentTitle}</Typography>
    </PrimaryHeaderPortal>
  );
};
