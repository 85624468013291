import moment from "moment";
import { PreferredDateFormat, PreferredDateTimeFormat } from "utils/date";

function utcToLocal(date) {
  return moment.utc(date).local();
}

export default function formatDateTime(dateTimeUtc, includeTime) {
  const date = utcToLocal(dateTimeUtc);
  return includeTime ? date.format(PreferredDateTimeFormat) : date.format(PreferredDateFormat);
}

export function formatUtcDate(dateTimeUtc, format) {
  const date = utcToLocal(dateTimeUtc);

  if (!date.isValid()) return "-";

  if (!format) return date;

  return date.format(format);
}
