import React from "react";
import { Avatar as NativeAvatar } from "@material-ui/core";
import * as colors from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";

const randomMatColor = hueVal => {
  const hue = hueVal || "400";
  const mainColors = [
    "red",
    "pink",
    "purple",
    "deepPurple",
    "indigo",
    "blue",
    "lightBlue",
    "cyan",
    "teal",
    "green",
    "lightGreen",
    "lime",
    "yellow",
    "amber",
    "orange",
    "deepOrange",
  ];
  const randomColor = mainColors[Math.floor(Math.random() * mainColors.length)];
  return colors[randomColor][hue];
};

const DEFAULT_SIZES = {
  sm: 24,
  md: 38,
  lg: 46,
  xl: 72,
};

function getInitials(name) {
  const n = name.split(" ");
  let it = "";
  let i = 0;

  while (i < n.length) {
    it += n[i][0];
    i += 1;
  }
  return (it.toUpperCase());
}

const useStyles = makeStyles(() => ({
  avatar: {
    boxShadow: "0 1px 5px 0 rgba(0, 0, 0, .06), 0 2px 2px 0 rgba(0, 0, 0, .03), 0 2px 1px -2px rgba(0, 0, 0, .02)",
  },
  avatarBackgroundColor: {
    backgroundColor: randomMatColor(),
  },
}));

export const Avatar = ({
  src,
  name,
  displayDefaultImage,
  defaultBackgroundColor,
  size,
}) => {
  const classes = useStyles();
  let avatarSrc = "";

  if (displayDefaultImage && src === undefined) {
    avatarSrc = `${process.env.PUBLIC_URL}/assets/images/avatars/profile.jpg`;
  } else {
    avatarSrc = src;
  }

  const width = typeof size === "number"
    ? size
    : DEFAULT_SIZES[size] || DEFAULT_SIZES.md;

  const height = width;

  return (
    <NativeAvatar
      src={avatarSrc}
      alt="avatar"
      className={clsx(!defaultBackgroundColor && classes.avatarBackgroundColor, classes.avatar)}
      style={{ height, width }}
    >
      {name && getInitials(name)}
    </NativeAvatar>
  );
};

Avatar.propTypes = {
  displayDefaultImage: PropTypes.bool,
  defaultBackgroundColor: PropTypes.bool,
  name: PropTypes.string,
};

Avatar.defaultProps = {
  displayDefaultImage: true,
  defaultBackgroundColor: true,
};

export default Avatar;
