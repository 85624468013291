import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { TableCell, TableRow } from "@material-ui/core";
import PagingTable from "components/items/paging-table";
import { formatUtcDate } from "helpers/format-date-time";
import { PreferredShortDateTimeFormat } from "utils/date";
import { useHistory } from "react-router-dom";
import { getPatientLabel } from "utils/get-environment-variables";
import { PatientSummary } from "app/main/patients/components/patient-details";

import { getPageInfo, getSearchParams, isPageLoading, getErrorMessage, getPatientMergeLogs, PATIENT_MERGE_LOGS_PAGE_SIZE } from "../reducers/patientMerge.reducers";
import { fetchPatientMergeLogResults, getPatientMergeLogById } from "../actions/patientMerge.actions";

const patientLabel = getPatientLabel();

const PatientMergeLogTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(isPageLoading);
  const error = useSelector(getErrorMessage);
  const pageInfo = useSelector(getPageInfo);
  const searchParams = useSelector(getSearchParams);
  const patientMergeLogs = useSelector(getPatientMergeLogs);

  useEffect(() => {
    dispatch(fetchPatientMergeLogResults(1, PATIENT_MERGE_LOGS_PAGE_SIZE, null, false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeRowsPerPage = pageSize => dispatch(fetchPatientMergeLogResults(1, pageSize, searchParams));
  const onChangePage = pageNumber => dispatch(fetchPatientMergeLogResults(pageNumber + 1, pageInfo.pageSize, searchParams));

  const onClick = id => {
    dispatch(getPatientMergeLogById(id)).then(() => history.push(`/admin/patientMerge/${id}`));
  };

  const renderTableHeader = () => (
    <TableRow>
      <TableCell>Date Created</TableCell>
      <TableCell>Master Record</TableCell>
      <TableCell>Deprecated Record</TableCell>
      <TableCell>Type</TableCell>
      <TableCell>Status</TableCell>
    </TableRow>
  );

  const renderTableRow = log => (
    <TableRow key={log.id} hover onClick={() => onClick(log.id)}>
      <TableCell className="w-92">{formatUtcDate(log.createdDateTimeUtc, PreferredShortDateTimeFormat)}</TableCell>
      <TableCell><PatientSummary patient={log.masterRecord} /></TableCell>
      <TableCell><PatientSummary patient={log.deprecatedRecord} /></TableCell>
      <TableCell>{log.type}</TableCell>
      <TableCell>{log.status}</TableCell>
    </TableRow>
  );

  return (
    <PagingTable
      loading={loading}
      error={error}
      data={patientMergeLogs}
      rowsPerPage={pageInfo?.pageSize}
      columnSpan={5}
      page={pageInfo?.pageNumber - 1}
      count={pageInfo?.totalRecords}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      emptyMessage={`No ${patientLabel.single} Merge Records`}
      tableHead={renderTableHeader}
      tableRow={item => renderTableRow(item)}
    />
  );
};

export default PatientMergeLogTable;
