import React from "react";
import { withRouter } from "react-router-dom";

import DefaultPanelLayout from "components/items/default-panel-layout";
import ProblemList from "app/main/patients/problems/components/problem-list";
import ProblemListContainer from "app/main/patients/problems/containers/problem-list-container";
import { ProblemIcon } from "helpers/icon-finder";
import { HeaderAction } from "app/main/patients/components/patient-components";

const ProblemSummaryPanel = ({
  patientId,
  history,
  ...other
}) => (
  <ProblemListContainer
    numberOfItems={3}
    patientId={patientId}
    renderContent={({
      problems,
      // actions
      onRecordProblem,
      onEditProblem,
      onRemoveProblem,
      onResolveProblem,
      onRenewProblem,
      // states
      isLoading,
      isEmpty,
      isError,
    }) => (
      <DefaultPanelLayout
        isEmpty={isEmpty}
        isLoading={isLoading}
        error={isError}
        title="Problems"
        icon={<ProblemIcon />}
        headerRightContent={<HeaderAction onClick={onRecordProblem} isRelative label="Add Problem" />}
        action={!isEmpty
         && {
           label: "View More",
           onClick: () => history.push(`/patients/${patientId}?tab=problems`),
         }}
        emptyTitle="No Recorded Problems"
        {...other}
      >
        <ProblemList
          problems={problems}
          onEditProblem={onEditProblem}
          onRemoveProblem={onRemoveProblem}
          onResolveProblem={onResolveProblem}
          onRenewProblem={onRenewProblem}
        />
      </DefaultPanelLayout>
    )}
  />
);

export default withRouter(ProblemSummaryPanel);
