import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import { getRecentProcedures, getProceduresByPatientId, areProceduresLoading, getErrorMessage } from "app/main/patients/reducers/procedures.reducers";
import { fetchPatientProcedures, editExistingProcedure, recordNewProcedure, removeProcedure, fetchProceduresById } from "app/main/patients/actions/procedures.actions";
import RecordProcedureForm from "app/main/patients/procedures/components/record-procedure-form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import withPermissions from "permissions/withPermissions";

class ProcedureListContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    const { patientId } = this.props;
    if (patientId) {
      this.props.fetchPatientProcedures(patientId);
    }
    this.onRecordProcedure = this.onRecordProcedure.bind(this);
    this.onEditProcedure = this.onEditProcedure.bind(this);
  }

  onClose = () => this.props.closeDialog();

  openRecordProcedureForm = (title, isEdit) => {
    this.props.openDialog({
      children: <RecordProcedureForm
        title={title}
        onSucceed={this.onClose}
        isEdit={isEdit}
        assignedToUserId={this.props.assignedToUserId}
      />,
    });
  }

  onRecordProcedure = () => {
    this.props.recordNewProcedure(this.props.patientId);
    this.openRecordProcedureForm("Add Procedure", false);
  };

  onEditProcedure = procedureId => {
    this.props.editExistingProcedure(this.props.patientId, procedureId);
    this.openRecordProcedureForm("Edit Procedure", true);
  };

  onRemoveProcedure = procedureId => {
    this.props.removeProcedure(this.props.patientId, procedureId);
    this.onClose();
  };

  render() {
    const {
      loading,
      error,
      procedures,
      renderContent,
      hasPermissionProceduresCreate,
      hasPermissionProceduresUpdate,
      hasPermissionProceduresDelete,
    } = this.props;

    return (
      renderContent({
        procedures,
        onRecordProcedure: hasPermissionProceduresCreate ? this.onRecordProcedure : null,
        onEditProcedure: hasPermissionProceduresUpdate ? this.onEditProcedure : null,
        onRemoveProcedure: hasPermissionProceduresDelete ? this.onRemoveProcedure : null,
        isLoading: loading,
        isError: error,
        isEmpty: isEmpty(procedures),
      })
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { patientId } = ownProps;
  const procedures = ownProps.numberOfItems ? getRecentProcedures(state, patientId, ownProps.numberOfItems) : getProceduresByPatientId(state, patientId);
  return {
    procedures,
    error: getErrorMessage(state, patientId),
    loading: areProceduresLoading(state, patientId),
  };
};

export default withPermissions(
  "ProceduresDelete",
  "ProceduresUpdate",
  "ProceduresCreate",
)(connect(
  mapStateToProps,
  {
    openDialog,
    closeDialog,
    fetchProceduresById,
    fetchPatientProcedures,
    recordNewProcedure,
    editExistingProcedure,
    removeProcedure,
  },
)(ProcedureListContainer));
