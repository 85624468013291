import React, { Component } from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { TableHead, Table, TableBody, TablePagination, TableContainer } from "@material-ui/core";
import LoadingState from "components/items/loading-state";
import EmptyState from "components/items/empty-state";
import { getError } from "utils/errorutil";

import TablePaginationActions from "./actions";

class PagingTable extends Component {
  constructor(props) {
    super(props);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  handleChangePage = (event, page) => {
    this.props.onChangePage(page);
  };

  handleChangeRowsPerPage = event => {
    this.props.onChangeRowsPerPage(event.target.value);
  };

  render() {
    const {
      data,
      emptyMessage,
      tableHead,
      tableRow,
      rowsPerPage = 10,
      page,
      count,
      pageSizeOptions = [5, 10, 25, 50],
      columnSpan,
      loading,
      error,
    } = this.props;

    if (loading) {
      return <LoadingState />;
    }

    if (!isEmpty(error)) {
      const message = getError(error);
      return <div>{message.message}</div>;
    }

    if (isEmpty(data)) {
      return (
        <EmptyState title={emptyMessage} />
      );
    }

    return (
      <div className="flex-1 w-full flex flex-col">
        <TableContainer className="flex-1">
          <Table>
            {tableHead && (
            <TableHead>
              {tableHead()}
            </TableHead>
            )}
            <TableBody>
              {data.map((item, index) => tableRow(item, index))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={pageSizeOptions}
          page={page}
          count={count}
          colSpan={columnSpan || 5}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
        />
      </div>
    );
  }
}

PagingTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PagingTable;
