import React from "react";
import { Card, CardHeader, CardContent, CardActions } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { find } from "lodash";
import { useSelector } from "react-redux";

import IconButton from "components/items/icon-button";
import ReferralAssignTo from "app/main/referralWorklist/components/referral-assign-to";
import { ContextItem } from "components/items/default-item";
import formatDaysFromNow from "helpers/format-days-from-now";
import formatDate from "helpers/format-date";
import { ReferralOrgIcon } from "helpers/icon-finder";
import ItemStatus from "components/items/item-status";
import ReferralCategoryLabel from "app/main/referrals/components/referral-category-label";
import { getReferralWorkflowStateSettings } from "app/auth/store/reducers/system-configuration";

const useStyles = makeStyles(theme => ({
  headerContainer: {
    padding: theme.spacing(1, 0, 1, 2),
  },
  headerAction: {
    marginRight: 0,
    paddingLeft: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(0, 2, 1),
  },
  action: {
    borderTop: `1px dashed ${theme.palette.divider}`,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const Item = props => <ContextItem labelClassName="w-128" iconClassName="w-128" renderIfEmpty {...props} />;

const ReferralCard = ({
  referral,
  goToReferral,
}) => {
  const classes = useStyles();
  const referralWorkflowSettings = useSelector(getReferralWorkflowStateSettings);

  const status = find(referralWorkflowSettings, x => x.workflowState === referral.referralStatus);

  return (
    <Card className={classes.root}>
      <CardHeader
        classes={{ root: classes.headerContainer, content: "flex items-center justify-between", action: classes.headerAction }}
        action={<IconButton title="Go to Referral" icon="arrow_forward" onClick={goToReferral} />}
        title={<ItemStatus label={status?.label ?? referral.status} color={status?.color} icon={status?.icon} textProps={{ variant: "body1" }} />}
        subheader={<ReferralCategoryLabel triageCategory={referral.triageCategory} isUrgent={referral.isUrgent} />}
        disableTypography
      />
      <CardContent className={classes.content}>
        <div className="items-container">
          <Item label="From" content={`${referral.referrerFullName ? `${referral.referrerFullName}` : ""}${referral.referrerProviderNumber ? ` (${referral.referrerProviderNumber})` : ""}`} />
          <Item label="For" content={referral.referredToServiceDescription} />

          <Item label="Referral Date" content={`${formatDate(referral.referralDateUtc)} (${formatDaysFromNow(referral.referralDateUtc)})`} />
          <Item label="Referral Period" content={referral.referralPeriod} />

          <Item icon={<ReferralOrgIcon />} content={referral.referrerOrganisationName} />
          <Item icon="phone" content={referral.referrerPhone} />
        </div>
      </CardContent>
      <CardActions className={classes.action}>
        <ReferralAssignTo referral={referral} disabled />
      </CardActions>
    </Card>
  );
};

export default ReferralCard;
