import React from "react";
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { isString } from "lodash";

const useStyles = makeStyles(theme => ({
  confirmButton: {
    "&.disabled": {
      backgroundColor: theme.palette.background.default,
    },
  },
  alertButton: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  warningButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
}));

const ConfirmationDialog = ({
  type,
  title,
  content,
  onConfirm,
  confirmLabel,
  cancelLabel,
  onCancel,
}) => {
  const classes = useStyles();

  return (
    <>
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent className={clsx(!title && "p-24")}>
        {isString(content)
          ? (
            <DialogContentText id="alert-dialog-description">
              {content}
            </DialogContentText>
          ) : content}
      </DialogContent>
      <DialogActions className="justify-center">
        <Button
          color="secondary"
          autoFocus
          onClick={onConfirm}
          className={clsx("flex-1", classes.confirmButton, type === "alert" && classes.alertButton, type === "warning" && classes.warningButton)}
        >
          {confirmLabel}
        </Button>
        <Button onClick={onCancel} className="flex-1">
          {cancelLabel}
        </Button>
      </DialogActions>
    </>
  );
};

ConfirmationDialog.propTypes = {
  type: PropTypes.oneOf(["confirmation", "alert", "warning"]),
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  type: "confirmation",
  confirmLabel: "Confirm",
  cancelLabel: "Cancel",
};

export default ConfirmationDialog;
