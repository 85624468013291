import React, { useRef } from "react";
import FileUploadOutlineIcon from "mdi-react/FileUploadOutlineIcon";
import { join } from "lodash";
import IconButton from "components/items/icon-button";

const FileUploaderButton = ({
  disabled,
  onChange,
  acceptTypes, // e.g. ["application/pdf"],
}) => {
  const ref = useRef();

  const handleClick = () => ref?.current.click();

  return (
    <>
      <IconButton component="label" title="Choose a file" icon={<FileUploadOutlineIcon />} disabled={disabled} onClick={handleClick} />
      <input
        type="file"
        capture="camera"
        ref={ref}
        style={{ display: "none" }}
        onChange={onChange}
        accept={join(acceptTypes, ",")}
      />
    </>
  );
};

export default FileUploaderButton;
