import React from "react";

import { ContextNoteItem, ContextItem } from "components/items/default-item";
import { AllergyIcon } from "helpers/icon-finder";
import { IsCurrentStatus } from "app/main/patients/components/patient-components";
import SeverityRating from "app/main/patients/components/severity-rating";
import PatientContextListItem, { ItemContent } from "app/main/patients/components/patient-context-list-item";

const ratings = [
  { value: 1, label: "Minor" },
  { value: 2, label: "Moderate" },
  { value: 3, label: "Significant" },
  { value: 4, label: "Severe" },
];

const AllergyListItem = ({
  item: {
    allergyTerm,
    note,
    severity,
    reaction,
    isCurrent,
  },
  ...other
}) => {
  const status = isCurrent === true ? "Current" : "Resolved";

  return (
    <PatientContextListItem
      title={allergyTerm}
      {...other}
      renderItemContent={() => (
        <ItemContent
          title={allergyTerm}
          icon={<AllergyIcon />}
          status={<IsCurrentStatus status={status} />}
          content={(
            <>
              <SeverityRating ratings={ratings} severity={severity} contentOnly />
              {reaction}
            </>
          )}
        />
      )}
      renderPreviewContent={() => (
        <>
          <IsCurrentStatus status={status} />
          <SeverityRating ratings={ratings} severity={severity} />
          <ContextItem icon="report_problem" content={reaction} />
          <ContextNoteItem content={note} />
        </>
      )}
    />
  );
};

export default AllergyListItem;
