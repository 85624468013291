import React from "react";
import { CircularProgress } from "@material-ui/core";
import BaseTextField from "components/inputs/components/base-text-field";

export default ({ label }) => (
  <BaseTextField
    iconComponent={<CircularProgress size={20} />}
    label={label}
    value="Loading..."
  />
);
