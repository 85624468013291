import React from "react";
import { Typography, Icon, IconButton } from "@material-ui/core";
import { isEmpty, map, size } from "lodash";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import TextLink from "components/items/text-link";
import withPermissions from "permissions/withPermissions";
import PatientAssignUserForm from "app/main/patients/components/patient-assign-user-form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";

const AssignedUser = ({
  patientId,
  withoutLabel,
  hasPermissionEnrolmentAssign,
  enrolments,
  enrolmentStatus,
  disabled,
}) => {
  const dispatch = useDispatch();
  // show in disabled state if the user doesn't have edit permission or there's no patientId property
  const isDisabled = disabled || !hasPermissionEnrolmentAssign || isEmpty(patientId);

  // show enrolment button if not enroled in both programs and only on patient header
  const showAddEnrolmentButton = !disabled && size(enrolments) < 2;

  const onAssignUser = enrolment => (
    dispatch(openDialog({
      children: (
        <PatientAssignUserForm
          title="Assign User"
          onSucceed={() => dispatch(closeDialog())}
          patientId={patientId}
          enrolment={enrolment}
          enrolments={enrolments}
          enrolmentStatus={enrolmentStatus}
        />
      ),
    }))
  );

  return (
    <div className="flex-row-container enable-shrink">
      {!withoutLabel && <div className="mr-4"><Typography variant="body1">ASSIGNED TO</Typography></div>}
      {isEmpty(enrolments) ? (
        <TextLink
          content="UNASSIGNED"
          onClick={disabled ? null : () => onAssignUser()}
        />
      ) : (
        <div className="flex-row-container with-gutter enable-shrink">
          {map(enrolments, enrolment => (
            <TextLink
              key={enrolment.id}
              content={`${enrolment.assignedToUser} (${enrolment.enrolmentType.label})`}
              onClick={isDisabled ? null : () => onAssignUser(enrolment)}
              className={clsx(disabled && "isDisableLink")}
            />
          ))}
        </div>
      )}
      {showAddEnrolmentButton && (
        <IconButton onClick={() => onAssignUser()} color="primary" className="p-8 -mt-8 -mb-8">
          <Icon fontSize="small">add_circle</Icon>
        </IconButton>
      )}
    </div>
  );
};

export default withPermissions("EnrolmentAssign")(AssignedUser);
