import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, map } from "lodash";

import DefaultPageLayout from "components/layouts/default-page-layout";
import { ContextItem } from "components/items/default-item";
import { formatUtcDate } from "helpers/format-date-time";
import { PreferredDateFormat, PreferredShortDateTimeFormat } from "utils/date";
import ResponsiveTable, { TableCell, TableRow, TableBody } from "components/items/responsive-table";
import ItemStatus from "components/items/item-status";
import { PatientSummary } from "app/main/patients/components/patient-details";

import { getPatientMergeLogById, resetCurrentPatientMergeLog } from "../actions/patientMerge.actions";
import { getCurrent, isCurrentLoading } from "../reducers/patientMerge.reducers";
import getLogEntryTypeColor from "../helpers/get-log-entry-type-color";

const Item = props => <ContextItem renderIfEmpty labelClassName="w-160" {...props} />;

const PatientDetail = ({ patient }) => <PatientSummary patient={patient} textVariant="body1" titleVariant="h6" showAddress />;

const PatientMergeLogEntryPage = ({
  match: { params: { id } },
}) => {
  const dispatch = useDispatch();
  const currentLog = useSelector(getCurrent);
  const loading = useSelector(isCurrentLoading);

  useEffect(() => {
    // only call this when current user is empty and is not loading
    // needed when page refreshes
    if (!loading && isEmpty(currentLog)) {
      dispatch(getPatientMergeLogById(id));
    }
    // clear current user
    return () => dispatch(resetCurrentPatientMergeLog());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLogItem = (item, index) => (
    <TableBody key={index}>
      <TableRow>
        <TableCell title="Date">{formatUtcDate(item.createdDateTimeUtc, PreferredDateFormat)}</TableCell>
        <TableCell title="Time">{formatUtcDate(item.createdDateTimeUtc, "H:mm:ss")}</TableCell>
        <TableCell title="Type"><div className="flex"><ItemStatus label={item.logType} backgroundColor={getLogEntryTypeColor(item.logType)} /></div></TableCell>
        <TableCell title="Description">{item.stampedDescription}</TableCell>
        <TableCell title="Comment">{item.comment}</TableCell>
        <TableCell title="Username">{item.username}</TableCell>
      </TableRow>
    </TableBody>
  );

  const renderHeader = () => (
    <TableRow>
      <TableCell className="w-80">Date</TableCell>
      <TableCell className="w-56">Time</TableCell>
      <TableCell>Type</TableCell>
      <TableCell>Description</TableCell>
      <TableCell>Comment</TableCell>
      <TableCell className="w-96">Username</TableCell>
    </TableRow>
  );

  return (
    <>
      <DefaultPageLayout
        loading={loading}
        content={(
          <div className="p-24">
            <div className="items-container md">
              <Item label="Created Date" content={formatUtcDate(currentLog.createdDateTimeUtc, PreferredShortDateTimeFormat)} />
              <Item label="Merge Type" content={currentLog.type} />
              <Item label="Status" content={currentLog.status} />
              <Item label="Started Date" content={formatUtcDate(currentLog.startedDateTimeUtc, PreferredShortDateTimeFormat)} />
              <Item label="Completed Date" content={formatUtcDate(currentLog.completedDateTimeUtc, PreferredShortDateTimeFormat)} />
              <div className="flex flex-col md:flex-row">
                <Item className="flex-1" alignment="items-start" label="Master Patient" content={<PatientDetail patient={currentLog.masterRecord} />} />
                <Item className="md:ml-24 mt-24 md:mt-0 flex-1" alignment="items-start" label="Deprecated Patient" content={<PatientDetail patient={currentLog.deprecatedRecord} />} />
              </div>
              <Item
                label="Log Entries"
                className="flex-col"
                alignment="items-start"
                content={(
                  <ResponsiveTable
                    isEmpty={isEmpty(currentLog.logEntry)}
                    renderTableContent={map(currentLog.logEntry, (log, index) => renderLogItem(log, index))}
                    renderTableHeader={renderHeader()}
                  />
                )}
              />
            </div>
          </div>
        )}
      />
    </>
  );
};

export default PatientMergeLogEntryPage;
