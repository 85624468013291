import React from "react";
import { useDispatch } from "react-redux";

import ScreenHeader from "components/items/screen-header";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { getPatientLabel } from "utils/get-environment-variables";

import PatientMergeLogSearch from "./patient-merge-log-search";
import PatientMergeForm from "./patient-merge-form";

const patientLabel = getPatientLabel();

const PatientMergeLogHeader = ({ title = `${patientLabel.single} Merge Logs` }) => {
  const dispatch = useDispatch();

  const onClose = () => dispatch(closeDialog());

  const openMerge = () => dispatch(openDialog({
    maxWidth: "md",
    children: (<PatientMergeForm onSucceed={onClose} onCancel={onClose} title={`${patientLabel.single} Merge`} />),
  }));

  return (
    <ScreenHeader
      title={title}
      renderSearch={<PatientMergeLogSearch />}
      action={{
        label: "Add Merge",
        onClick: openMerge,
      }}
    />
  );
};

export default PatientMergeLogHeader;
