import { isNaN, has, isEmpty, get, size } from "lodash";
import moment from "moment";
import validator from "au-bn-validator";
import { AcceptedDateFormats } from "./date";

export const validateIdentifiers = (fieldName, value, options) => {
  const minLength = options?.min;
  const maxLength = options?.max;
  const numberOnly = options?.numberOnly ?? true;

  if (options?.required && isEmpty(value)) {
    return `${fieldName} is required`;
  }

  if (!isEmpty(value)) {
    if (numberOnly && isNaN(Number(value))) {
      return `${fieldName} should only contain numbers`;
    } if (minLength === maxLength && size(value) !== minLength) {
      return `${fieldName} must be ${minLength} characters`;
    } if (minLength && size(value) < minLength) {
      return `${fieldName} must contain at least ${minLength} characters`;
    } if (maxLength && size(value) > maxLength) {
      return `${fieldName} must contain ${maxLength} characters or less`;
    }
  }

  return "";
};

export const validateRequired = fieldName => value => ((typeof value !== "undefined" && value !== null && value !== "") ? undefined : `${fieldName} is required`);

export const validateEmailFormat = () => value => (value && !/^[A-Z0-9'!#$%&'*+-/=?^_`{|}~.]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? "This does not look like a valid email address."
  : undefined);

export const validatePhoneFormat = () => value => (value && !/^[0-9+ ]*$/.test(value)
  ? "This does not look like a valid phone number."
  : undefined);

export const validatePostcodeFormat = () => value => (value && !/^[0-9]{4}$/.test(value)
  ? "This does not look like a valid postcode."
  : undefined);

export const validateMinLength = (fieldName, minLength) => val => (val && val.length >= minLength
  ? undefined
  : `${fieldName} must be at least ${minLength} characters long.`);

export const validateMaxLength = (fieldName, maxLength) => val => (!val || val.length <= maxLength
  ? undefined
  : `${fieldName} must be less than ${maxLength} characters long.`);

export const validateMatchingEmail = () => (value, allValues) => (value !== allValues.email
  ? "Emails do not match, please try again."
  : undefined);

export const validateMulti = fieldName => value => {
  if (value === undefined) return undefined;

  return isEmpty(value) ? `At least one ${fieldName} is required` : undefined;
};

export const validateNumber = (fieldName, options) => value => {
  if (value === undefined) return undefined;

  if (isNaN(Number(value))) {
    return `${fieldName} should only contain numbers.`;
  }

  if (has(options, "minLength") && value < options.minLength) {
    return `${fieldName} must be greater than or equal to ${options.minLength}`;
  }

  if (has(options, "maxLength") && value > options.maxLength) {
    return `${fieldName} must be less than or equal to ${options.maxLength}`;
  }

  return undefined;
};

export const validatePatientIdentifier = (fieldName, options) => value => {
  return validateIdentifiers(fieldName, value, options);
};

// eslint-disable-next-line global-require
export const validateMedicareNumber = () => value => (isEmpty(value) || (value && validator.validateMedicareNumber(value)) ? undefined : "Please enter a valid Medicare Number");

export const validateMatchingPassword = () => (value, allValues) => (value !== allValues.password
  ? "Passwords do not match, please try again."
  : undefined);

// Prepackaged combos
export const validateRequiredEmail = [
  validateRequired("Email Address"),
  validateEmailFormat("Email"),
];

export const validateRequiredPassword = [
  validateRequired("Password"),
  validateMinLength("Password", 5),
];

export const validateDateIsAfter = (firstFieldName, secondFieldName, secondFieldValuePath, options) => (firstFieldValue, allValues) => {
  const secondFieldValue = get(allValues, secondFieldValuePath, null);

  if (isEmpty(firstFieldValue) || isEmpty(secondFieldValue)) {
    return undefined;
  }

  const firstFieldMomentVal = has(options, "relativeDate") && options.relativeDate ? moment(firstFieldValue.date, AcceptedDateFormats) : moment(firstFieldValue, AcceptedDateFormats);
  const secondFieldMomentVal = has(options, "relativeDate") && options.relativeDate ? moment(secondFieldValue.date, AcceptedDateFormats) : moment(secondFieldValue, AcceptedDateFormats);

  return firstFieldMomentVal >= secondFieldMomentVal ? undefined : `${firstFieldName} has to be after ${secondFieldName}`;
};

export const validateDateIsBefore = (firstFieldName, secondFieldName, secondFieldValuePath, options) => (firstFieldValue, allValues) => {
  const secondFieldValue = get(allValues, secondFieldValuePath, null);

  if (isEmpty(firstFieldValue) || isEmpty(secondFieldValue)) {
    return undefined;
  }

  const firstFieldMomentVal = has(options, "relativeDate") && options.relativeDate ? moment(firstFieldValue.date, AcceptedDateFormats) : moment(firstFieldValue, AcceptedDateFormats);
  const secondFieldMomentVal = has(options, "relativeDate") && options.relativeDate ? moment(secondFieldValue.date, AcceptedDateFormats) : moment(secondFieldValue, AcceptedDateFormats);

  return firstFieldMomentVal <= secondFieldMomentVal ? undefined : `${firstFieldName} has to be before ${secondFieldName}`;
};

export const validateIfRequired = (fieldName, dependentFieldValuePath) => (value, allValues) => {
  const dependentFieldValue = get(allValues, dependentFieldValuePath, null);

  if (dependentFieldValue === null) {
    return undefined;
  }

  return (typeof value !== "undefined" && value !== null) ? undefined : `${fieldName} is required`;
};

export const validateDateOfBirth = (fieldName, isRequired) => value => {
  const today = moment().startOf("day");

  if (isRequired && ((typeof value === "undefined" || value === null || value === ""))) {
    return `${fieldName} is required`;
  }

  if (value && !moment(value, "YYYY-MM-DD").isValid()) {
    return "Invalid Date";
  }

  if (value && moment(value).isAfter(today)) {
    return `${fieldName} can not be in the future`;
  }

  return null;
};

export const validateFileAttachmentRequired = isOptional => value => ((!isOptional && isEmpty(value)) ? "Please choose a file" : undefined);
