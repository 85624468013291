import { get, mapKeys, map } from "lodash";
import curryConnector from "utils/connectors";
import { defaultSearchParams } from "app/main/referralWorklist/reducers/referralWorklist.reducers";

export const PATIENT_REFERRALS_STATE_KEY = "patientReferrals";
export const REFERRALS_PAGE_SIZE = 10;
const curry = fn => curryConnector(fn, PATIENT_REFERRALS_STATE_KEY);

export const ActionTypes = {
  LOADING_PATIENT_REFERRALS: "LOADING_PATIENT_REFERRALS",
  LOADED_PATIENT_REFERRALS: "LOADED_PATIENT_REFERRALS",
  ERROR_LOADING_PATIENT_REFERRALS: "ERROR_LOADING_PATIENT_REFERRALS",

  LOADING_PATIENT_ACTIVE_REFERRALS: "LOADING_PATIENT_ACTIVE_REFERRALS",
  LOADED_PATIENT_ACTIVE_REFERRALS: "LOADED_PATIENT_ACTIVE_REFERRALS",
  ERROR_LOADING_PATIENT_ACTIVE_REFERRALS: "ERROR_LOADING_PATIENT_ACTIVE_REFERRALS",

  RESET_PATIENT_REFERRALS_SEARCH: "RESET_PATIENT_REFERRALS_SEARCH",
  SET_DEFAULT_PATIENT_REFERRALS_SEARCH_PARAMS: "SET_DEFAULT_PATIENT_REFERRALS_SEARCH_PARAMS",
};

const INITIAL_STATE = {
  all: {},
  searchParams: defaultSearchParams,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_PATIENT_REFERRALS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]: {
            ...state.all[action.payload.patientId],
            pages: {
              ...state.all[action.payload.patientId]?.pages,
              [action.payload.pageNumber]: { loading: true, loaded: false, error: false },
            },
            pageInfo: { ...state.all[action.payload.patientId]?.pageInfo, pageNumber: action.payload.pageNumber },
          },
        },
        searchParams: { patientId: action.payload.patientId, ...state.searchParams },
      };
    case ActionTypes.LOADED_PATIENT_REFERRALS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]: {
            ...state.all[action.payload.patientId],
            all: mapKeys(action.payload.referrals, x => x.id),
            pages: {
              ...state.all[action.payload.patientId]?.pages,
              [action.payload.pageNumber]: { loading: false, error: false, loaded: true, ids: map(action.payload.referrals, x => x.id) },
            },
            pageInfo: action.payload.pageInfo,
          },
        },
        searchParams: { patientId: action.payload.patientId, ...action.payload.searchParams },
      };
    case ActionTypes.ERROR_LOADING_PATIENT_REFERRALS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]: {
            ...state.all[action.payload.patientId],
            pages: {
              ...state.all[action.payload.patientId]?.pages,
              [action.payload.pageNumber]: { loading: false, error: action.payload.message, loaded: false },
            },
          },
        },
      };
    case ActionTypes.LOADING_PATIENT_ACTIVE_REFERRALS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]: {
            ...state.all[action.payload.patientId],
            actives: { loading: true },
          },
        },
      };
    case ActionTypes.LOADED_PATIENT_ACTIVE_REFERRALS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]: {
            ...state.all[action.payload.patientId],
            actives: {
              loading: false,
              loaded: true,
              error: null,
              referrals: action.payload.referrals,
            },
          },
        },
      };
    case ActionTypes.ERROR_LOADING_PATIENT_ACTIVE_REFERRALS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]: {
            ...state.all[action.payload.patientId],
            actives: { loading: false, loaded: false, error: action.payload.message },
          },
        },
      };
    case ActionTypes.RESET_PATIENT_REFERRALS_SEARCH:
      return {
        ...state,
        searchParams: defaultSearchParams,
      };
    case ActionTypes.SET_DEFAULT_PATIENT_REFERRALS_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: { ...defaultSearchParams, ...action.payload },
      };
    default:
      return state;
  }
};

export const getReferralsForPatient = curry(({ localState }, patientId) => {
  const data = get(localState, ["all", patientId], null);
  if (!data?.all) return null;
  const pageNumber = data.pageInfo.pageNumber || 1;
  return map(get(data.pages, [pageNumber, "ids"], []), key => data.all[key]);
});

export const isPageLoading = curry(({ localState }, patientId, pageNumber) => {
  const data = get(localState, ["all", patientId], null);
  const number = pageNumber || get(data?.pageInfo, ["pageNumber"], 1);
  return get(data, ["pages", number, "loading"], false);
});

export const isPageLoaded = curry(({ localState }, patientId, pageNumber) => {
  const data = get(localState, ["all", patientId], null);
  const number = pageNumber || get(data?.pageInfo, ["pageNumber"], 1);
  return get(data, ["pages", number, "loaded"], false);
});

export const getErrorMessage = curry(({ localState }, patientId, pageNumber) => {
  const data = get(localState, ["all", patientId], null);
  const number = pageNumber || get(data?.pageInfo, ["pageNumber"], 1);
  return get(data, ["pages", number, "error"], null);
});

export const getPatientReferralPageInfo = curry(({ localState }, patientId) => get(localState, ["all", patientId, "pageInfo"], null));
export const getPatientReferralSearchParams = curry(({ localState }) => get(localState, ["searchParams"], null));
export const isPatientActiveReferralsLoading = curry(({ localState }, patientId) => get(localState, ["all", patientId, "actives", "loading"], false));
export const isPatientActiveReferralsLoaded = curry(({ localState }, patientId) => get(localState, ["all", patientId, "actives", "loaded"], false));
export const getPatientActiveReferrals = curry(({ localState }, patientId) => get(localState, ["all", patientId, "actives", "referrals"], null));
