import moment from "moment";
import { isEmpty } from "lodash";
import { PreferredTimeFormat } from "utils/date";

export default function formatTime(input, format) {
  if (isEmpty(input)) {
    return "";
  }

  let formattedTime = input;

  if (!moment.isMoment(formattedTime)) {
    formattedTime = (moment(formattedTime, "HH:mm:ss", true).isValid()) ? moment(formattedTime, "HH:mm:ss") : moment(formattedTime);
  }

  if (!formattedTime.isValid()) {
    return "";
  }

  if (!isEmpty(format)) {
    return formattedTime.format(format);
  }

  return formattedTime.format(PreferredTimeFormat);
}
