import React from "react";
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import { getReferralById } from "app/main/referrals/reducers/referrals.reducers";
import { actionPanel } from "components/styles/base";
import ViewPatientRecordButton from "app/main/patients/components/view-patient-record-button";
import { getExistingPatientById } from "app/main/patients/actions/patients.actions";
import PatientDetails from "app/main/patients/components/patient-details";

import { cancelledWorkflowState } from "../helpers/workflow-state-names";
import ReferralWorkflowPanel from "./referral-workflow-panel";
import ReferralPatientDetails from "./referral-patient-details";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
    },
  },
  content: {
    display: "flex",
    flex: "1 1 auto",
    padding: `${theme.spacing(2)}px`,
    [theme.breakpoints.up("lg")]: {
      marginTop: 0,
    },
  },
  action: {
    height: "100%",
    minWidth: actionPanel.width,
    [theme.breakpoints.up("md")]: {
      width: actionPanel.width,
      display: "flex",
    },
  },
}));

const ReferralHeader = ({
  referral,
  showMedicalRecordButton = true,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { patient, referralStatus } = referral;

  const isCancelled = referralStatus === cancelledWorkflowState;

  const goToPatientMedicalRecordPage = () => dispatch(getExistingPatientById(patient.patientId, true)).then(() => {
    history.push({
      pathname: `/referralWorklist/patient/${patient.patientId}`,
      fromUrl: `/referralWorklist?referralId=${referral.id}`,
    });
  });

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <PatientDetails
          renderContent={(
            <ReferralPatientDetails
              goToPatientRecordPage={showMedicalRecordButton ? () => goToPatientMedicalRecordPage() : null}
              patient={patient}
              identifierProps={{ disabled: isCancelled }}
            />
          )}
          renderRightContent={showMedicalRecordButton && <ViewPatientRecordButton onClick={goToPatientMedicalRecordPage} />}
        />
      </div>
      <div className={classes.action}>
        <ReferralWorkflowPanel referral={referral} />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  referral: ownProps.referral || getReferralById(state, ownProps.referralId),
});

export default (connect(
  mapStateToProps,
  {},
)(ReferralHeader));
