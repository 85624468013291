import { getPatientLabel } from "utils/get-environment-variables";
import SecureWorklistPage from "./pages/secure-worklist-page";

export const WorklistConfig = {
  routes: [
    {
      title: `${getPatientLabel().single} Worklist`,
      path: "/worklist",
      component: SecureWorklistPage,
      exact: true,
      isIndexPage: true,
    },
  ],
};

export default WorklistConfig;
