/* eslint-disable max-len */
import React from "react";
import { connect } from "react-redux";
import { map, isEmpty, size } from "lodash";
import { Typography, Icon, withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import CalendarMultipleIcon from "mdi-react/CalendarMultipleIcon";
import clsx from "clsx";

import DefaultPanelLayout from "components/items/default-panel-layout";
import EmptyState, { NoneRecordedState } from "components/items/empty-state";
import DefaultButton from "components/items/default-button";
import DefaultItem from "components/items/default-item";
import getReportBackStatus from "app/main/applications/helpers/get-report-back-status";
import ReportBackForm from "app/main/applications/components/report-back-form";
import { showMessage } from "app/store/actions/message.actions";
import { onRemoveReportBack, cloneApplication, onUploadReportBackAttachment, removeReportBackAttachment } from "app/main/applications/actions/applications.actions";

import formatDate from "helpers/format-date";
import { ApplicationItemTextArea } from "app/main/applications/components/application-item";
import ConfirmationDialog from "components/items/confirmation-dialog";
import IconButton from "components/items/icon-button";
import ResourceForm from "app/main/applications/components/resource-form";
import ApplicationAttachmentContainer from "app/main/applications/components/application-attachment-container";
import ApplicationNoteContent from "app/main/applications/components/application-note-content";
import { PreviewPdfButton, DownloadButton } from "app/main/applications/components/attachment/attachment-action-buttons";

import { getHelperTextBySectionName } from "app/auth/store/reducers/system-configuration";
import ApplicationRemoveButton from "./application-remove-button";
import ApplicationEditButton from "./application-edit-button";

const styles = theme => ({
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  content: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
});

class ReportBackPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentReportBack: null,
    };
  }

  onCloseDialog = () => {
    this.props.closeDialog();
    this.setState({ currentReportBack: null });
  }

  openReportBack = reportBack => {
    const isNew = !reportBack;
    this.props.openDialog({
      children: (
        <ReportBackForm
          onSucceed={() => this.props.closeDialog()}
          title={`${isNew ? "New" : "Edit"} Report Back`}
          reportBack={reportBack}
          {...this.props}
        />
      ),
    });
  };

  onRemove = reportBack => {
    const { id: applicationId, orgUnitId } = this.props;
    this.setState({ currentReportBack: reportBack });
    this.props.onRemoveReportBack(applicationId, reportBack.id, orgUnitId).then(response => {
      this.setState({ currentReportBack: null });

      if (response.error === true) {
        this.props.showMessage({ message: response.payload.exceptionMessage || "Error occurred while deleting the report back", variant: "error" });
      } else {
        this.props.showMessage({ message: "Successfully removed the report back", variant: "success" });
      }
    });
  };

  onCopyApplication = () => {
    const { helperText } = this.props;

    this.props.openDialog({
      maxWidth: "xs",
      fullScreen: false,
      children: (
        <ConfirmationDialog
          onCancel={() => this.onCloseDialog()}
          onConfirm={() => {
            this.props.cloneApplication(this.props.orgUnitId, this.props.id).then(response => {
              if (response.error === true) {
                this.props.showMessage({ message: response.payload.exceptionMessage || "An error has occurred", variant: "error" });
              } else {
                this.props.scrollItemToView(response.payload.id);
                this.props.history.push(`/applications?applicationId=${response.payload.id}`);
              }
            });
            this.onCloseDialog();
          }}
          title={<div className="text-center">Copy this Application?</div>}
          content={helperText.copyApplicationForm}
        />
      ),
    });
  };

  onAddResource = reportBackId => {
    const handleSubmitAttachment = ({ resource }, { setSubmitting }, attachedFile) => {
      const { description, comment } = resource;
      if (attachedFile) {
        const { id: fileId, fileName, mimeType, fileScanStatus, scannedDateTimeUtc, fileScanMessage } = attachedFile;
        this.props.onUploadReportBackAttachment(this.props.id, reportBackId, this.props.orgUnitId, {
          fileId,
          description,
          fileName,
          mimeType,
          comment,
          fileScanStatus,
          scannedDateTimeUtc,
          fileScanMessage,
        }).then(response => {
          setSubmitting(false);
          if (response.error !== true) {
            this.props.closeDialog();
          }
        });
      } else {
        this.props.onUploadReportBackAttachment(this.props.id, reportBackId, this.props.orgUnitId, { comment })
          .then(response => {
            setSubmitting(false);
            if (response.error !== true) {
              this.props.closeDialog();
            }
          });
      }
    };

    this.props.openDialog({
      children: (
        <ResourceForm
          handleSubmit={handleSubmitAttachment}
          title="Submit a Report Back"
          resourceType="attachment"
          fileOptional
          showComment
          {...this.props}
        />
      ),
    });
  }

  renderAttachmentPanel(reportBack) {
    const { id: applicationId, canView, canEdit, canAttachmentView, orgUnitId } = this.props;

    if (isEmpty(reportBack.reportBackSubmissions)) return null;

    return (
      <div className="items-container mt-8">
        <ApplicationAttachmentContainer
          applicationId={applicationId}
          orgUnitId={orgUnitId}
          renderContent={({
            previewPDF,
            downloadFile,
          }) => (
            map(reportBack.reportBackSubmissions, (reportBackSubmission, index) => {
              if (!canAttachmentView) return <ApplicationNoteContent label="Comments">{reportBackSubmission.comment}</ApplicationNoteContent>;
              const { applicationAttachment, comment } = reportBackSubmission;
              const hasFileAttachment = !isEmpty(applicationAttachment);
              const hasComment = !isEmpty(comment);
              const hasSubmission = hasFileAttachment || hasComment;
              const canRemoveSubmission = !hasSubmission || (canEdit && ((hasFileAttachment && applicationAttachment?.canEdit) || (hasComment && reportBackSubmission?.canEdit)));
              const canPreviewOrDownloadFile = hasFileAttachment && canView;

              return (
                <React.Fragment key={index}>
                  <div className={clsx("flex justify-between min-h-48 mr-16", !(index === 0) && "with-horizontal-divider")}>
                    <div>
                      <Typography className="font-bold">Report Back Submission</Typography>
                      <Typography variant="caption">Submitted by {reportBackSubmission.submittedByUser} on {formatDate(reportBackSubmission.submittedDateTimeUtc)}</Typography>
                      <div className="items-container mt-12">
                        {hasFileAttachment && (
                        <>
                          <Typography variant="caption">File Name</Typography>
                          <Typography variant="body2">{applicationAttachment.fileName}</Typography>
                          <Typography variant="caption">File Description</Typography>
                          <Typography variant="body2">{applicationAttachment.description}</Typography>
                        </>
                        )}
                        {hasComment && (
                        <>
                          <Typography variant="caption">Comments</Typography>
                          <Typography variant="body2">{reportBackSubmission.comment}</Typography>
                        </>
                        )}
                      </div>
                    </div>
                    <div className="flex items-start">
                      {canPreviewOrDownloadFile && DownloadButton(() => downloadFile(applicationAttachment.id))}
                      {canPreviewOrDownloadFile && PreviewPdfButton(() => previewPDF(applicationAttachment.id), null, "Preview Attachment")}
                      {canRemoveSubmission && <ApplicationRemoveButton onClick={() => this.props.removeReportBackAttachment(applicationId, reportBack.id, this.props.orgUnitId, { attachmentId: isEmpty(applicationAttachment) ? null : applicationAttachment.id, reportBackSubmissionId: reportBackSubmission.id })} />}
                    </div>
                  </div>
                </React.Fragment>
              );
            })
          )}
        />
      </div>
    );
  }

  render() {
    const { reportBacks, canView, classes, canCopy, canEdit, canUpload, helperText } = this.props;

    if (!canView) return null;

    return (
      <DefaultPanelLayout
        title="Report Backs"
        icon={<CalendarMultipleIcon />}
        isEmpty={isEmpty(reportBacks)}
        emptyState={canEdit
          ? <EmptyState icon={<CalendarMultipleIcon />} subtitle="Add Report Back" onClick={() => this.openReportBack()} />
          : <NoneRecordedState />}
        accessDenied={!canView}
        headerActions={canEdit
          && [
            {
              label: "Add Report Back",
              icon: "add",
              onClick: () => this.openReportBack(),
            },
          ]}
        subtitle={<Typography variant="caption" color="textSecondary">{helperText.reportBackCopyApplication}</Typography>}
      >
        <div className="items-container">
          {map(reportBacks, reportBack => {
            const statusConfig = getReportBackStatus(reportBack.receivedStatus);
            const { canEdit: canEditThisReport, canUpload: canUploadAttachment } = reportBack;

            return (
              <React.Fragment key={reportBack.id}>
                <div className={clsx(classes.itemContainer)}>
                  <div className={classes.content}>
                    <div className="flex items-center mr-32">
                      <div className="flex mr-16">
                        <Icon className="w-48">date_range</Icon>
                        <Typography className="font-bold">DUE DATE</Typography>
                      </div>
                      {formatDate(reportBack.dueDate)}
                    </div>
                    <div className="flex-1 flex items-center">
                      <DefaultItem content={statusConfig.label} icon={statusConfig.icon} textColor={statusConfig.color} iconColor={statusConfig.color} />
                      {reportBack.receivedDateTimeUtc && <Typography variant="caption" className="ml-4"> on {formatDate(reportBack.receivedDateTimeUtc)}</Typography>}
                    </div>
                  </div>
                  <div className="flex-row-container with-gutter">
                    {canCopy && size(reportBack.reportBackSubmissions) > 0 && <DefaultButton label="Create Application" onClick={() => this.onCopyApplication()} size="small" variant="outlined" className="mr-8" />}
                    {canEditThisReport && <ApplicationEditButton onClick={() => this.openReportBack(reportBack)} />}
                    {canEditThisReport && <ApplicationRemoveButton title="Are you sure you want to remove this report back?" onClick={() => this.onRemove(reportBack)} />}
                    {canUploadAttachment && canUpload && <IconButton icon="note_add" title="Submit a Report Back" iconSize={24} onClick={() => this.onAddResource(reportBack.id)} />}
                  </div>
                </div>
                <ApplicationItemTextArea label="Notes" content={reportBack.notes} className="flex-col" />
                <ApplicationItemTextArea label="Submissions" content={this.renderAttachmentPanel(reportBack)} className="flex-col" />
              </React.Fragment>
            );
          })}
        </div>
      </DefaultPanelLayout>
    );
  }
}

const mapStateToProps = state => ({
  helperText: getHelperTextBySectionName(state, "reportBack"),
});

export default connect(
  mapStateToProps,
  {
    showMessage,
    onRemoveReportBack,
    cloneApplication,
    onUploadReportBackAttachment,
    removeReportBackAttachment,
  },
)(withStyles(styles)(withRouter(ReportBackPanel)));
