import ReactDOM from "react-dom";

export default function SidebarTogglePortal(props) {
  const sidebar = document.getElementById("side-bar-toggle");
  if (sidebar === null) {
    return null;
  }
  return ReactDOM.createPortal(
    props.children,
    sidebar,
  );
}
