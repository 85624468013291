import React from "react";
import { createFilterOptions } from "@material-ui/lab";
import { isUndefined } from "lodash";
import AutoComplete from "./auto-complete";

const CreatableAutoComplete = ({
  createLabel,
  onSelectCustomOption,
  onChange,
  ...other
}) => {
  const { form: { setFieldValue }, field: { name }, ignoreInputMatchingFilter, sorter } = other;

  const filterOptions = (allOptions, params) => {
    const options = !isUndefined(sorter) ? sorter(allOptions, params.inputValue) : allOptions;

    const optionFilter = ignoreInputMatchingFilter ? createFilterOptions({ stringify: () => params.inputValue }) : createFilterOptions();
    const filtered = optionFilter(options, params);

    // Suggest the creation of a new value
    if (params.inputValue !== "") {
      filtered.push({
        inputValue: params.inputValue,
        label: `${createLabel} "${params.inputValue}"`,
      });
    }

    return filtered;
  };

  const getOptionLabel = option => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }

    return option.label;
  };

  const handleOnChange = (event, newValue) => {
    if (typeof newValue === "string") {
      setFieldValue(name, {
        label: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setFieldValue(name, {
        label: newValue.inputValue,
        value: newValue.inputValue,
      });

      if (onSelectCustomOption) {
        onSelectCustomOption(newValue);
      }
    } else {
      setFieldValue(name, newValue);
    }

    if (onChange) {
      onChange(newValue, setFieldValue);
    }
  };

  return (
    <AutoComplete
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      handleOnChange={handleOnChange}
      {...other}
    />
  );
};

CreatableAutoComplete.defaultProps = {
  createLabel: "Add",
  selectOnFocus: true,
  clearOnBlur: true,
  freeSolo: true,
};

export default CreatableAutoComplete;
