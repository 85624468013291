import React from "react";
import Chip from "@material-ui/core/Chip";

const CandidateEnrolmentChip = ({
  label,
}) => (
  <Chip size="small" label={label} className="mr-4" />
);

export default function getEnrolmentTypeIcon({ type, label, key }) {
  switch (type) {
    case "SalveoNursing":
      return <CandidateEnrolmentChip label={label} key={key} />;
    case "WelfareCheck":
      return <CandidateEnrolmentChip label={label} key={key} />;
    default:
      return null;
  }
}
