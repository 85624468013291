import { get, size, forEach } from "lodash";

export const isUnauthorisedError = response => get(response, ["status"], null) === 401;

export const isNotFoundError = response => get(response, ["status"], null) === 404;

export const isInvalidError = response => get(response, ["status"], null) === 422;

export const isBadRequestError = response => get(response, ["status"], null) === 400;

export const isForbiddenError = response => get(response, ["status"], null) === 403;

export const isServerError = response => get(response, ["status"], null) === 500;

export const isSuccess = response => get(response, ["status"], null) === 200;

export const getError = response => {
  let notificationTitle = "ERROR";
  if (isNotFoundError(response?.meta)) {
    notificationTitle = "404 Errors";
  } else if (isInvalidError(response?.meta)) {
    notificationTitle = "An Error Occurred";
  } else if (isBadRequestError(response?.meta)) {
    notificationTitle = "Validation Errors";
  } else if (isForbiddenError(response?.meta)) {
    notificationTitle = "Access Denied";
  } else if (isServerError(response?.meta)) {
    notificationTitle = "Server Error Occurred";
  }

  const notificationMessages = [];
  const error = response?.payload;
  if (error) {
    if (error && error.errors) {
      const { errors } = error;

      if (size(errors) > 0) {
        forEach(errors, err => {
          if (err.detail && err.detail.modelState) {
            forEach(err.detail.modelState, props => {
              notificationMessages.push(err.detail.modelState[props].join(", "));
            });
          } else if (err.title) {
            notificationMessages.push(err.title);
          }
        });
      }
    } else if (typeof error === "string" || error instanceof String) {
      notificationMessages.push(error);
    } else if (error.exceptionMessage) {
      // Should only be available if customErrors are off for the API
      notificationMessages.push(error.exceptionMessage);
    } else if (error.message) {
      notificationMessages.push(error.message);
    }
  }
  return {
    title: notificationTitle,
    message: notificationMessages.join("<br />"),
  };
};
