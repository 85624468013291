import React, { useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import qs from "qs";
import withPermissions from "permissions/withPermissions";
import AppContext from "app/AppContext";
import useSetUrl from "app/main/admin/hooks/useSetUrl";
import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import DefaultPageLayout from "components/layouts/default-page-layout";
import OrgUnitForm from "../components/orgUnit-form";

const OrgUnitNewPage = props => {
  const { history, match: { params: { orgUnitType } }, route, location, hasPermissionOrgUnitCreate } = props;
  const { goBack } = useSetUrl(route);
  const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { parentOrgUnitId, parentOrgUnitType } = queryString;
  const orgUnit = { orgUnitType, parentOrgUnitId };
  const singInOrgUnitType = useSelector(getSignedInOrgUnit)?.orgUnitType;
  const appContext = useContext(AppContext);
  const { setBackURL } = appContext;
  const includeFromUrl = (parentOrgUnitType !== "Org" && (orgUnitType !== singInOrgUnitType));

  useEffect(() => {
    if (includeFromUrl) setBackURL(`/admin/org-units/${parentOrgUnitType}/${parentOrgUnitId}?tab=orgUnits`);
    else setBackURL(history?.location?.fromUrl ?? history?.location?.backUrl ?? "/admin?tab=orgUnits");
  });

  const onCancel = () => {
    if (includeFromUrl) history.push({ pathname: `/admin/org-units/${parentOrgUnitType}/${parentOrgUnitId}`, search: "?tab=orgUnits" });
    else goBack();
  };

  const onSucceed = orgUnitId => {
    history.push({
      pathname: `/admin/org-units/${orgUnitType}/${orgUnitId}`,
      fromUrl: includeFromUrl ? `/admin/org-units/${parentOrgUnitType}/${parentOrgUnitId}?tab=orgUnits` : null,
    });
  };

  return (
    <DefaultPageLayout
      accessDenied={!hasPermissionOrgUnitCreate}
      content={(
        <OrgUnitForm
          orgUnit={orgUnit}
          orgUnitType={orgUnitType}
          onCancel={onCancel}
          onSucceed={orgUnitId => onSucceed(orgUnitId)}
        />
      )}
    />
  );
};

export default withPermissions("OrgUnitCreate")(OrgUnitNewPage);
