import { head, find, filter, map } from "lodash";
import curryConnector from "utils/connectors";
import { ActionTypes as ReferralsActionTypes } from "app/main/referrals/reducers/referrals.reducers";

export const REFERRAL_WORKLIST_STATE_KEY = "referralWorklist";

const curry = fn => curryConnector(fn, REFERRAL_WORKLIST_STATE_KEY);

export const ActionTypes = {
  SEARCHING_REFERRALS: "SEARCHING_REFERRALS",
  SEARCHED_REFERRALS: "SEARCHED_REFERRALS",
  ERROR_SEARCHING_REFERRALS: "ERROR_SEARCHING_REFERRALS",

  UPDATE_REFERRAL_WORKLIST_FILTER: "UPDATE_REFERRAL_WORKLIST_FILTER",
  RESET_REFERRAL_WORKLIST_FILTER: "RESET_REFERRAL_WORKLIST_FILTER",
  SETUP_REFERRAL_WORKLIST_FILTERS: "SETUP_REFERRAL_WORKLIST_FILTERS",

  UPDATE_REFERRAL_WORKLIST_FILTER_TAGS: "UPDATE_REFERRAL_WORKLIST_FILTER_TAGS",
  RESET_REFERRAL_WORKLIST_FILTER_TAGS: "RESET_REFERRAL_WORKLIST_FILTER_TAGS",
};

const defaultFilterOptions = insertOptions => {
  const options = [
    { value: "active", label: "Active Referrals" },
    // TEMP hide for BHS release
    // { value: "unread", label: "Unread Updates" },
    { value: "waitingPeriodLongerThan30Days", label: "Waiting Longer Than 30 Days" },
    { value: "myOutstandingTasks", label: "My Outstanding Tasks" },
    { value: "myReferrals", label: "My Referrals" },
    { value: "advancedFilter", label: "Advanced Filter" },
  ];

  if (insertOptions) {
    options.splice(1, 0, ...insertOptions);
  }

  return options;
};

const initialOptions = defaultFilterOptions();
const initialDefaultFilterOption = head(initialOptions);

export const defaultSearchParams = {
  term: null,
  status: null,
  category: null,
  startDate: null,
  endDate: null,
  assignedTo: null,
  onlyShowUnassigned: false,
  unreadAddendum: false,
  isUrgent: false,
  showOldestFirst: false,
  allStatus: true,
  allCategory: true,
  taskStatus: null,
  assignedToSpecialtyId: null,
  assignedToRoleId: null,
  assignedToUserId: null,
  daysSinceLastStatusChange: null,
  assignedToSubSpecialtyId: null,
  taskAssignedToUserId: null,
  taskAssignedToUser: null,
};

export const getPresetParams = (options, filterValue, presetSearchParams) => {
  const value = filterValue?.value ?? filterValue;
  const statusFilter = find(options, x => x.value === value && x?.title === "Referrals"); // Referrals status filter

  const searchParams = { ...defaultSearchParams, ...presetSearchParams };

  if (statusFilter) {
    return {
      ...searchParams,
      allStatus: false,
      status: [value],
    };
  }

  switch (value) {
    case "active":
      return {
        ...searchParams,
        allStatus: false,
        status: map(filter(options, setting => setting.isActive === true), x => x.value),
      };
    case "unread":
      return {
        ...searchParams,
        unreadAddendum: true,
      };
    case "waitingPeriodLongerThan30Days":
      return {
        ...searchParams,
        daysSinceLastStatusChange: "30",
        allStatus: false,
        status: ["Requested", "AwaitingInformation"],
      };
    default:
      return searchParams;
  }
};

const INITIAL_STATE = {
  searchParams: defaultSearchParams,
  loading: false,
  error: null,
  loaded: false,
  presetFilter: initialDefaultFilterOption,
  pageNumber: 1,
  total: 0,
  filterOptions: initialOptions,
  defaultFilterOption: initialDefaultFilterOption,
  filterIsSetUp: false,
  searchTags: null,
  showStatusTags: false,
};

const setUpFilters = (state, actions) => {
  const settings = filter(actions.payload.settings, x => x.isEnabled === true);
  const presetOptions = map(settings,
    x => ({ value: x.workflowState, label: x.label, title: "Referrals", isActive: x.isActive }));
  const options = defaultFilterOptions(presetOptions);

  const searchParams = {
    ...getPresetParams(presetOptions, initialDefaultFilterOption),
    ...actions.payload.searchParams,
  };

  return {
    ...state,
    filterOptions: options,
    searchParams,
    filterIsSetUp: true,
  };
};

const updatePresetFilter = (state, action) => {
  const { presetFilter, showStatusTags, preSetSearchParams } = action.payload;
  return {
    ...state,
    presetFilter: action.payload.presetFilter,
    searchParams: presetFilter === "advancedFilter" ? state.searchParams : getPresetParams(state.filterOptions, presetFilter, preSetSearchParams),
    showStatusTags,
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SEARCHING_REFERRALS:
      return { ...state, loading: true, error: null, loaded: false };
    case ActionTypes.ERROR_SEARCHING_REFERRALS:
      return { ...state, loading: false, error: action.payload.message, loaded: false };
    case ActionTypes.SEARCHED_REFERRALS:
      return {
        ...state,
        loading: false,
        error: null,
        loaded: true,
        searchParams: action.payload.searchParams,
        pageNumber: action.payload.pageInfo.pageNumber,
        total: action.payload.pageInfo.totalRecords,
      };
    case ActionTypes.UPDATE_REFERRAL_WORKLIST_FILTER:
      return updatePresetFilter(state, action);
    case ActionTypes.RESET_REFERRAL_WORKLIST_FILTER:
      return {
        ...state,
        // this will need to come from action payload in the future
        // if the top of default filter come from api
        // e.g. no longer "Active Referrals"
        presetFilter: initialDefaultFilterOption,
        defaultFilterOption: initialDefaultFilterOption,
        searchParams: action.payload.searchParams,
      };
    case ActionTypes.SETUP_REFERRAL_WORKLIST_FILTERS:
      return setUpFilters(state, action);
    case ReferralsActionTypes.CREATE_MANUAL_REFERRAL:
      return {
        ...state,
        total: state.total + 1,
      };
    case ActionTypes.UPDATE_REFERRAL_WORKLIST_FILTER_TAGS:
      return {
        ...state,
        searchTags: action.payload,
      };
    case ActionTypes.RESET_REFERRAL_WORKLIST_FILTER_TAGS:
      return {
        ...state,
        searchTags: INITIAL_STATE.searchTags,
      };
    default:
      return state || INITIAL_STATE;
  }
};

export const getSearchParameters = curry(({ localState }) => localState.searchParams);

export const isReferralWorklistLoading = curry(({ localState }) => localState.loading === true);

export const isReferralWorklistLoaded = curry(({ localState }) => localState.loaded === true);

export const getErrorMessage = curry(({ localState }) => localState.error);

export const getReferralWorklistFilter = curry(({ localState }) => localState.presetFilter);

export const getTotalNumberOfReferral = curry(({ localState }) => localState.total);

export const getCurrentPageOfReferral = curry(({ localState }) => localState.pageNumber);

export const bailOutOfLoadingReferrals = curry(({ localState }, pageNumber) => {
  const { loading, total } = localState;

  if (loading || (pageNumber === 1 && total !== 0)) {
    return true;
  }
  return false;
});

export const getFilterOptions = curry(({ localState }) => localState.filterOptions);

export const getDefaultFilterOptionSearchParams = curry(({ localState }) => {
  const { filterOptions, defaultFilterOption } = localState;

  return getPresetParams(filterOptions, defaultFilterOption);
});

export const areFiltersLoaded = curry(({ localState }) => localState.filterIsSetUp === true);

export const getSearchTags = curry(({ localState }) => localState.searchTags);

export const shouldShowStatusSearchTags = curry(({ localState }) => localState.showStatusTags === true);
