import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { map, isEmpty } from "lodash";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

import { ReferralWorklistIcon } from "helpers/icon-finder";
import DefaultPanelLayout from "components/items/default-panel-layout";
import withPermissions from "permissions/withPermissions";
import PanelContent from "components/items/panel-content";
import { getPatientActiveReferrals, isPatientActiveReferralsLoading, isPatientActiveReferralsLoaded } from "app/main/referrals/reducers/patientReferrals.reducers";
import { fetchReferral } from "app/main/referrals/actions/referrals.actions";
import { fetchPatientActiveReferrals } from "app/main/referrals/actions/patientReferrals.actions";

import ReferralCard from "./referral-card";

const ActiveReferralListPanel = ({
  patientId,
  ...other
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector(state => isPatientActiveReferralsLoading(state, patientId));
  const isLoaded = useSelector(state => isPatientActiveReferralsLoaded(state, patientId));
  const patientReferrals = useSelector(state => getPatientActiveReferrals(state, patientId));

  useEffect(() => {
    if (!isLoaded) {
      dispatch(fetchPatientActiveReferrals(patientId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  const goToReferral = ({ referralId }) => {
    dispatch(fetchReferral(referralId)).then(() => {
      history.push({
        pathname: `/patients/${patientId}/referrals/${referralId}`,
        fromUrl: history.location.pathname + history.location.search,
      });
    });
  };

  const hasReferrals = !isEmpty(patientReferrals);

  return (
    <DefaultPanelLayout
      title="Active Referrals"
      icon={<ReferralWorklistIcon />}
      isEmpty={!hasReferrals}
      isLoading={isLoading}
      classes={{ content: clsx(hasReferrals && "p-0"), contentContainer: clsx(hasReferrals && "pb-8 bg-transparent") }}
      elevation={!hasReferrals ? 1 : 0}
      emptyTitle="No Recorded Referrals"
      {...other}
    >
      <PanelContent>
        {map(patientReferrals, x => (
          <ReferralCard
            key={x.id}
            referral={x}
            goToReferral={() => goToReferral({ referralId: x.id })}
          />
        ))}
      </PanelContent>
    </DefaultPanelLayout>
  );
};

export default withPermissions("ReferralsCreate")(ActiveReferralListPanel);
