import React, { useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CardTextOutlineIcon from "mdi-react/CardTextOutlineIcon";
import { includes, every, isEmpty, size } from "lodash";
import { Alert, Pagination } from "@material-ui/lab";
import { Fade, Typography } from "@material-ui/core";

import { TextInput, DatePicker, SelectInput } from "components/inputs";
import { getSexOptions, getExternalPatientSearchFields, getPrimaryPatientIdentifier } from "app/auth/store/reducers/system-configuration";
import { fetchPatientSearchResults, resetPatientSearch } from "app/main/patients/actions/patientsSearch.actions";
import { PATIENTS_SEARCH_PAGE_SIZE, getPageInfo, getSearchParams } from "app/main/patients/reducers/patientsSearch.reducers";
import PatientSearchResults from "app/main/patients/components/external-patient-search-button/patient-search-results";
import PanelContent from "components/items/panel-content";
import DefaultButton from "components/items/default-button";
import DialogPopup from "components/items/dialog-popup";

const schema = Yup.object().shape({
  patientSearchForm: Yup.object().shape({
    primaryIdentifier: Yup.string().nullable(),
    givenName: Yup.string().nullable(),
    familyName: Yup.string().nullable(),
    dateOfBirth: Yup.date().default(null).nullable(),
    sex: Yup.string().nullable(),
    medicareNumber: Yup.string().validateMedicareNumber("Medicare Number").nullable(),
    dvaNumber: Yup.string().nullable(),
    isInvalid: Yup.bool().when(["primaryIdentifier", "givenName", "familyName", "dateOfBirth", "sex", "medicareNumber", "dvaNumber"], {
      is: (primaryIdentifier, givenName, familyName, dateOfBirth, sex, medicareNumber, dvaNumber) => {
        const arr = [primaryIdentifier, givenName, familyName, dateOfBirth, sex, medicareNumber, dvaNumber];
        if (every(arr, x => isEmpty(x))) {
          return true;
        }
        return false;
      },
      then: Yup.bool().required(),
      otherwise: Yup.bool(),
    }).nullable(),
  }),
});

const PatientSearchForm = ({
  title,
  onContinue,
  onClose,
}) => {
  const dispatch = useDispatch();
  const sexOptions = useSelector(getSexOptions);
  const enableFields = useSelector(getExternalPatientSearchFields);
  const primaryIdentifier = useSelector(getPrimaryPatientIdentifier);
  const pageInfo = useSelector(getPageInfo);
  const searchParams = useSelector(getSearchParams);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const initialValues = schema.cast();

  const showPaging = pageInfo.totalRecords > PATIENTS_SEARCH_PAGE_SIZE;

  const clear = () => { dispatch(resetPatientSearch()); setSelectedPatient(null); };

  const isEnabled = fieldName => includes(enableFields, fieldName) === true;

  const handleSubmit = ({ patientSearchForm }, { setSubmitting }) => {
    setSelectedPatient(null);
    dispatch(fetchPatientSearchResults(1, PATIENTS_SEARCH_PAGE_SIZE, patientSearchForm)).then(() => {
      setSubmitting(false);
    });
  };

  const handleChangePage = (event, page) => {
    if (page !== pageInfo.pageNumber) {
      dispatch(fetchPatientSearchResults(page, pageInfo.pageSize, searchParams));
    }
  };

  const formContent = props => (
    <form className="px-32 py-16" onSubmit={props.handleSubmit}>
      <div className="flex flex-col sm:flex-row">
        {isEnabled("PrimaryIdentifier") && (
        <Field
          name="patientSearchForm.primaryIdentifier"
          component={TextInput}
          label={primaryIdentifier}
          icon={<CardTextOutlineIcon />}
        />
        )}
        {isEnabled("MedicareNumber") && (
        <Field
          name="patientSearchForm.medicareNumber"
          component={TextInput}
          label="Medicare Number"
        />
        )}
        {isEnabled("DvaNumber") && (
        <Field
          name="patientSearchForm.dvaNumber"
          component={TextInput}
          label="DVA Number"
        />
        )}
      </div>
      <PanelContent>
        {isEnabled("GivenName") && (
        <Field
          name="patientSearchForm.givenName"
          component={TextInput}
          label="Given Name"
          icon="person"
        />
        )}
        {isEnabled("FamilyName") && (
        <Field
          name="patientSearchForm.familyName"
          component={TextInput}
          label="Family Name"
        />
        )}
      </PanelContent>
      <PanelContent>
        {isEnabled("DateOfBirth") && (
        <Field
          name="patientSearchForm.dateOfBirth"
          component={DatePicker}
          label="Date of Birth"
        />
        )}
        {isEnabled("Sex") && (
        <Field
          name="patientSearchForm.sex"
          component={SelectInput}
          options={sexOptions}
          label="Sex"
        />
        )}
      </PanelContent>
      <div className="flex items-center justify-between">
        <Fade in={!isEmpty(props.errors?.patientSearchForm?.isInvalid)}>
          <Alert severity="error">{`You must specify search criteria${size(enableFields) > 1 ? " in at least one field" : ""}.`}</Alert>
        </Fade>
        <DefaultButton icon="search" label="Search" type="submit" size="medium" loading={props.isSubmitting} />
      </div>
    </form>
  );

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {props => {
        const onResetAction = () => { props.resetForm(); clear(); };
        const onContinueAction = () => { onContinue(selectedPatient); clear(); };
        const onCancelAction = () => { props.resetForm(); clear(); onClose(); };

        return (
          <DialogPopup
            title={title}
            renderHeaderContent={(
              <>
                {formContent(props)}
                <div className="flex items-center justify-between mb-16 mx-24">
                  <Typography variant="caption" color="textSecondary">Total results: {pageInfo?.totalRecords}</Typography>
                  {showPaging && (
                    <Pagination
                      count={Math.ceil(pageInfo?.totalRecords / pageInfo?.pageSize) ?? 1}
                      page={pageInfo?.pageNumber ?? 1}
                      onChange={handleChangePage}
                      size="small"
                    />
                  )}
                </div>
              </>
            )}
            renderActions={(
              <div className="flex flex-1 items-center justify-between">
                <DefaultButton size="medium" label="Reset" variant="text" onClick={onResetAction} />
                <div className="flex-row-container with-gutter">
                  <DefaultButton size="medium" label="Continue" disabled={selectedPatient === null} onClick={onContinueAction} />
                  <DefaultButton size="medium" label="Cancel" variant="text" color="default" onClick={onCancelAction} />
                </div>
              </div>
            )}
            content={(<PatientSearchResults selectedPatient={selectedPatient} setSelectedPatient={setSelectedPatient} />)}
          />
        );
      }}
    </Formik>
  );
};

export default PatientSearchForm;
