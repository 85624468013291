import { ApiActions } from "middleware/call-api";
import { ActionTypes,
  areMedicationsLoading,
  areMedicationsLoaded,
  getMedicationById } from "../reducers/medications.reducers";

export const setCurrentMedication = medication => ({
  type: ActionTypes.LOADED_CURRENT_MEDICATION,
  payload: { medication },
});

export const fetchPatientMedications = patientId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/medications`,
    bailout: state => areMedicationsLoaded(state, patientId) || areMedicationsLoading(state, patientId),
    entityType: "medications",
    patientId,
  },
});

export const fetchMedicationsById = (patientId, medicationId) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/medications/${medicationId}`,
    types: [ActionTypes.LOADING_CURRENT_MEDICATION, ActionTypes.LOADED_CURRENT_MEDICATION, ActionTypes.ERROR_LOADING_CURRENT_MEDICATION],
  },
});

/** * Add Medication */
export const recordNewMedication = patientId => setCurrentMedication({
  id: null,
  patientId,
  term: null,
  form: null,
  isCurrent: true,
  strength: null,
  startDate: null,
  endDate: null,
  note: null,
  doseAmount: null,
  doseUnit: null,
  frequency: null,
  frequencyQualifier: null,
  manualDirections: null,
  withFood: false,
  prn: false,
});

export const recordMedication = medication => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${medication.patientId}/medications/new`,
    body: { medication },
    entityType: "medication",
    patientId: medication.patientId,
  },
});

export const editExistingMedication = (patientId, medicationId) => (dispatch, getState) => {
  const medication = getMedicationById(getState(), patientId, medicationId);
  dispatch(setCurrentMedication(medication));
};

export const editMedication = medication => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/patient/${medication.patientId}/medications/edit`,
    body: { medication },
    entityType: "medication",
    patientId: medication.patientId,
  },
});

export const removeMedication = (patientId, medicationId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/patient/${patientId}/medications/remove`,
    body: { medication: { id: medicationId, patientId } },
    entityType: "medication",
    patientId,
    medicationId,
  },
});

// Medication history
export const recordMedicationHistoryEntry = (patientId, medicationHistoryEntry) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${patientId}/medicationHistory/new`,
    body: { medicationHistoryEntry },
    entityType: "medication",
    patientId,
  },
});

export const fetchPatientMedicationHistory = (patientId, assessmentItemId) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/medicationHistory/fetchPatientMedicationHistory/${assessmentItemId}`,
    entityType: "medications",
    patientId,
  },
});
