import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import { fetchOrgUnitsResults } from "app/main/orgUnits/actions/orgUnit.actions";
import { ORG_UNIT_PAGE_SIZE_OPTIONS, ORG_UNIT_PAGE_SIZE, getOrgUnits, isPageLoading, getErrorMessage, getOrgUnitSearchParams, getOrgUnitPageInfo } from "app/main/orgUnits/reducers/orgUnit.reducers";
import PagingTable from "components/items/paging-table";
import { OrgUnitHeader, OrgUnitListItem } from "./orgUnit-list-item";

const OrgUnitList = ({ history, orgUnitType, parentOrgUnitId, parentOrgUnitType }) => {
  const dispatch = useDispatch();
  const loading = useSelector(isPageLoading);
  const error = useSelector(getErrorMessage);
  const pageInfo = useSelector(getOrgUnitPageInfo);
  const searchParams = useSelector(getOrgUnitSearchParams);
  const orgUnits = useSelector(getOrgUnits);
  const signedInOrgUnit = useSelector(getSignedInOrgUnit);

  const searchOrgUnits = (pageNumber, pageSize, params) => dispatch(fetchOrgUnitsResults(parentOrgUnitId, pageNumber, pageSize,
    { ...params, orgUnitTypes: [orgUnitType], currentOrgUnitId: parentOrgUnitId, showDeprecated: true }));

  React.useEffect(() => {
    if (orgUnitType) {
      searchOrgUnits(1, ORG_UNIT_PAGE_SIZE, {});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitType]);

  const onChangeRowsPerPage = pageSize => searchOrgUnits(1, pageSize, searchParams);
  const onChangePage = pageNumber => searchOrgUnits(pageNumber + 1, pageInfo.pageSize, searchParams);

  return (
    <PagingTable
      loading={loading}
      error={error}
      data={orgUnits}
      rowsPerPage={pageInfo.pageSize}
      pageSizeOptions={ORG_UNIT_PAGE_SIZE_OPTIONS}
      page={pageInfo.pageNumber - 1}
      count={pageInfo.totalRecords}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      emptyMessage="No Results"
      tableHead={OrgUnitHeader}
      tableRow={item => <OrgUnitListItem key={item.id} orgUnit={item} history={history} parentOrgUnitId={parentOrgUnitId} parentOrgUnitType={parentOrgUnitType} signedInOrgUnit={signedInOrgUnit} />}
    />
  );
};

export default OrgUnitList;
