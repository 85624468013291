import React, { useContext, useState, useEffect } from "react";
import { Hidden, Typography, LinearProgress } from "@material-ui/core";
import { isEmpty, startCase, size, get, filter, reduce, orderBy } from "lodash";
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import ChatPanel, { SecondaryHeader } from "components/items/chat-panel";
import PatientSurvey from "app/main/patients/components/patient-survey";
import MedicationHistoryAssessment from "app/main/patients/components/medication-history-assessment";
import SurveySelectForm from "app/main/patients/components/survey-select-form";
import AssessmentPrintHistory from "app/main/patients/components/assessment-print-history";
import PatientSummaryHeader from "app/main/patients/components/patient-summary-header";
import { getCurrentPatient } from "app/main/patients/reducers/patients.reducers";
import { getExistingPatientById } from "app/main/patients/actions/patients.actions";
import { getCurrentAssessment, isCurrentAssessmentLoading, getSurveyListWithSetting, getCurrentSurvey, isCurrentSurveyLoading, getAssessmentPrintHistory } from "app/main/patients/reducers/assessments.reducers";
import { fetchAssessmentById, setCurrentAssessment, completeAssessment, getSurveyBySurveyId, setCurrentSurvey, getAssessmentItemById, fetchAssessmentPrintHistory, setAssessmentPrintHistory } from "app/main/patients/actions/assessments.actions";
import { getSurveySettings } from "app/auth/store/reducers/system-configuration";
import EmptyState from "components/items/empty-state";
import formatDate from "helpers/format-date";
import LoadingState from "components/items/loading-state";
import DefaultButton from "components/items/default-button";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import ConfirmationDialog from "components/items/confirmation-dialog";
import PatientSurveySideBar from "app/main/patients/components/patient-survey-sidebar";
import AppContext from "app/AppContext";
import withPermissions from "permissions/withPermissions";
import { AssessmentIcon } from "helpers/icon-finder";
import IconButton from "components/items/icon-button";

const useStyles = makeStyles(theme => ({
  secondaryHeader: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.control,
  },
}));

const PatientSurveyPage = ({
  match: { params: { patientId, assessmentId } },
  history,
  location,
  currentPatient,
  isLoadingAssessment,
  currentAssessment,
  surveySettings,
  surveyListWithSetting,
  isCurrentSurveyLoading: surveyLoading,
  hasPermissionAssessmentsPrint,
  currentSurvey,
  assessmentPrintHistory,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const { setBackURL } = appContext;

  const [selectedGroup, setSelectedGroup] = useState(null);
  const surveyList = get(currentAssessment, "assessmentItems", []);
  const surveyIsEmpty = isEmpty(surveyList);

  useEffect(() => {
    const { fromUrl } = location;
    // exclude route that comes from worklist page, set it default to patient assessment tab
    if (fromUrl && fromUrl !== "/worklist") {
      setBackURL(fromUrl);
    } else {
      setBackURL(`/patients/${patientId}?tab=assessments`);
    }

    if (isEmpty(currentPatient)) {
      dispatch(getExistingPatientById(patientId));
    }

    if (isEmpty(currentAssessment) || currentAssessment.id !== assessmentId) {
      dispatch(fetchAssessmentById(patientId, assessmentId));
    }

    if (isEmpty(assessmentPrintHistory)) {
      dispatch(fetchAssessmentPrintHistory({ patientId, assessmentId }));
    }

    return () => {
      dispatch(setCurrentAssessment(null));
      dispatch(setCurrentSurvey(null));
      dispatch(setAssessmentPrintHistory(null));
      setBackURL(null);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => dispatch(closeDialog());

  const onCompleteAssessment = assessment => {
    dispatch(completeAssessment(patientId, assessment));
  };

  const setCurrentSurveyAndGroup = (item, groupName) => {
    setSelectedGroup(groupName);
    if (item.surveyType === "MedicationHistory") {
      dispatch(getAssessmentItemById({ patientId, assessmentItemId: item.id }));
    } else {
      dispatch(getSurveyBySurveyId({ patientId, surveyId: item.id }));
    }
  };

  const confirmComplete = () => {
    dispatch(openDialog({
      maxWidth: "xs",
      children: (
        <ConfirmationDialog
          onCancel={onClose} // do nothing at the moment
          onConfirm={() => {
            onCompleteAssessment({ id: currentAssessment.id });
            onClose();
            history.push(`/patients/${patientId}?tab=assessments`);
          }}
          content={`Complete this ${startCase(currentAssessment.type)} assessed by ${currentAssessment.assessmentBy} on ${formatDate(currentAssessment.startedDateTimeUtc)}?`}
        />
      ),
    }));
  };

  const onClickItem = item => setCurrentSurveyAndGroup(item, item.groupName);
  const onClickGroup = group => setSelectedGroup(group);

  const openSurveySelectForm = (title, isPrinting) => {
    const { id, assessmentItems, status } = currentAssessment;
    const completedAssessments = filter(surveyList, s => s.status === "Completed" || s.status === "NotRequired");
    const surveys = reduce(isPrinting ? completedAssessments : surveyList, (result, { surveyType }) => ({ ...result, [surveyType]: !isPrinting }), {});
    const isAssessmentCompleted = status === "Completed";

    dispatch(openDialog({
      children: (
        <SurveySelectForm
          onSucceed={onClose}
          title={title}
          surveySettings={orderBy(surveySettings, "surveyType")}
          initialValues={{ surveys }}
          currentAssessmentId={id}
          isPrinting={isPrinting}
          isAssessmentCompleted={isAssessmentCompleted}
          patientId={patientId}
          assessmentItems={isPrinting ? completedAssessments : assessmentItems}
          submitLabel={isPrinting ? "Print" : "Save"}
        />
      ),
    }));
  };

  const openAssessmentPrintHistory = () => {
    dispatch(openDialog({
      disableBackdropClick: false,
      children: (
        <AssessmentPrintHistory
          title="Assessment Printing History"
          assessmentPrintHistory={assessmentPrintHistory}
          patientId={patientId}
          onSucceed={onClose}
        />
      ),
    }));
  };

  const surveyActions = () => {
    const showManageAssessmentButton = currentAssessment.type === "FollowUpAssessment" && currentAssessment.status !== "Completed";
    const showPrintHistory = !isEmpty(assessmentPrintHistory);
    const disablePrintButton = size(filter(surveyList, s => s.status === "Completed" || s.status === "NotRequired")) === 0;

    return (
      <div className="flex">
        {showManageAssessmentButton && <IconButton onClick={() => openSurveySelectForm("Manage Assessments")} title="Manage Assessments" icon={<AssessmentIcon />} />}
        {hasPermissionAssessmentsPrint && (
        <>
          <IconButton onClick={() => openSurveySelectForm("Print Assessments", true)} title="Print Assessment" icon="print" disabled={disablePrintButton} />
          {showPrintHistory && <IconButton onClick={() => openAssessmentPrintHistory()} title="Print History" icon="history" />}
        </>
        )}
      </div>
    );
  };

  const renderSurveyHeader = () => {
    if (!currentAssessment) {
      return null;
    }

    const completed = size(filter(surveyList, survey => (survey.status === "Completed" || survey.status === "NotRequired")));
    const requiredAssessmentsCompleted = completed !== size(surveyList);

    return (
      <>
        <div className="flex-row-container with-gutter">
          <div>
            <Typography>{startCase(currentAssessment.type)}</Typography>
            <Typography variant="caption">
              Started {formatDate(currentAssessment.startedDateTimeUtc)}
            </Typography>
          </div>
          {currentAssessment.status !== "Completed"
          && (
            <DefaultButton
              variant="outlined"
              size="small"
              label="Complete"
              disabled={requiredAssessmentsCompleted}
              onClick={() => confirmComplete()}
            />
          )}
          <Hidden mdUp>
            {surveyActions()}
          </Hidden>
        </div>
        <div className="mt-16 sm:mb-8">
          <LinearProgress value={(completed / size(surveyList)) * 100} variant="determinate" />
          <div className="flex justify-between items-center mt-4">
            <Typography variant="caption">Progress</Typography>
            <Typography variant="caption">{completed} / {size(surveyList)}</Typography>
          </div>
        </div>
        <Hidden smDown>
          {surveyActions()}
        </Hidden>
      </>
    );
  };

  const renderContent = () => {
    const showNoJsonMessage = (isEmpty(currentSurvey.surveyJson) && currentSurvey.surveyType !== "MedicationHistory");

    if (surveyLoading) {
      return <LoadingState />;
    }

    if (showNoJsonMessage) {
      return <EmptyState title="No json yet" />;
    }

    return currentSurvey.surveyType === "MedicationHistory"
      ? (
        <MedicationHistoryAssessment
          hasPermissionAssessmentsPrint={hasPermissionAssessmentsPrint}
          assessmentId={assessmentId}
          patientId={patientId}
          title={currentSurvey.displayName}
          allowNotRequired={currentSurvey.allowNotRequired}
          isCompleted={currentSurvey.status === "Completed" || currentSurvey.status === "NotRequired"}
          {...currentSurvey}
        />
      )
      : (
        <PatientSurvey
          hasPermissionAssessmentsPrint={hasPermissionAssessmentsPrint}
          assessmentId={assessmentId}
          patientId={patientId}
          allowNotRequired={currentSurvey.allowNotRequired && currentSurvey.surveyType !== "FollowUpAssessment"}
          title={currentSurvey.displayName}
          isCompleted={currentSurvey.status === "Completed" || currentSurvey.status === "NotRequired"}
          {...currentSurvey}
        />
      );
  };

  if (isLoadingAssessment) {
    return <LoadingState />;
  }

  const selectedItem = currentSurvey || {};

  return (
    <>
      <PatientSummaryHeader patient={currentPatient} />
      <Hidden mdUp>
        <SecondaryHeader>
          <div className={classes.secondaryHeader}>
            {renderSurveyHeader()}
          </div>
        </SecondaryHeader>
      </Hidden>
      <ChatPanel
        empty={isEmpty(selectedItem)}
        renderHeader={() => null}
        renderContent={() => renderContent(selectedItem)}
        renderStaticSideBar={() => (
          <PatientSurveySideBar
            empty={isEmpty(currentAssessment)}
            surveyIsEmpty={surveyIsEmpty}
            surveyListWithSetting={surveyListWithSetting}
            onClickItem={onClickItem}
            onClickGroup={onClickGroup}
            selectedItem={selectedItem}
            selectedGroup={selectedGroup}
            renderSurveyHeader={renderSurveyHeader()}
          />
        )}
        emptyIcon={<AssessmentIcon />}
        emptyTitle="Select An Assessment"
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    currentPatient: getCurrentPatient(state),
    isLoadingAssessment: isCurrentAssessmentLoading(state),
    currentAssessment: getCurrentAssessment(state),
    surveySettings: getSurveySettings(state),
    surveyListWithSetting: getSurveyListWithSetting(state),
    isCurrentSurveyLoading: isCurrentSurveyLoading(state),
    currentSurvey: getCurrentSurvey(state),
    assessmentPrintHistory: getAssessmentPrintHistory(state),
  };
}

export default withPermissions(
  "AssessmentsPrint",
)(connect(mapStateToProps)(PatientSurveyPage));
