import React from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";

import { Field } from "formik";
import { includes, isEmpty, get, map, size, head, find } from "lodash";
import { DateTimePicker, UserSelector, SelectInput, NoteInput, AutoComplete } from "components/inputs";
import { getCurrentAppointment } from "app/main/patients/reducers/appointments.reducers";
import { getPatientEnrolmentOptions, getPatientEnrolmentsById } from "app/main/patients/reducers/patients.reducers";
import { scheduleAppointment, editAppointment } from "app/main/patients/actions/appointments.actions";
import { getAssessmentByHeaderId, getDefaultPatientAssessment } from "app/main/patients/reducers/assessments.reducers";
import { getLocationTypeValues, getEnrolmentTypeValues } from "app/auth/store/reducers/system-configuration";
import Form from "components/form";
import { encounterTypes, encounterTypesWelfare, createOption } from "app/main/patients/helpers/encounter-options";
import { validateRequired } from "utils/validators";
import { setRecentSelectedUser } from "app/main/users/actions/users.actions";
import { CircleIconP } from "helpers/icon-finder";
import EncounterAssessmentInput from "app/main/patients/encounters/components/encounter-assessment-input";

const encounterTypeOptions = encounterTypes.map(createOption);
const encounterTypeOptionsWelfare = map(encounterTypesWelfare, encounterType => createOption(encounterType));
const allEncounterOptions = [...encounterTypes, ...encounterTypesWelfare];

const programLabel = "Program";
const userLabel = "Assigned To User";
const startDateTimeUtcLabel = "Scheduled Date/Time";
const encounterTypeLabel = "Purpose";
const currentAssessmentLabel = "Assessment";
const locationLabel = "Location (optional)";
const notesLabel = "Notes (optional)";

const validateProgram = validateRequired(programLabel);
const validateUser = validateRequired(userLabel);
const validateStartDateTimeUtc = validateRequired(startDateTimeUtcLabel);
const validateEncounterType = validateRequired(encounterTypeLabel);
const validateAssessmentChoice = validateRequired(currentAssessmentLabel);

class ScheduleAppointmentForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      encounterOptions: props.isWelfareProgram ? encounterTypeOptionsWelfare : encounterTypeOptions,
    };
  }

  handleSubmit = ({ appointment }, { setErrors }) => {
    let newAppointment = {
      ...appointment,
      assignedUserId: appointment.assignedUserId?.value,
      timeZone: moment.tz.guess(),
      status: "Booked",
    };

    const encounterType = newAppointment.encounterType?.value ?? newAppointment.encounterType;

    if (includes(allEncounterOptions, encounterType)) {
      newAppointment = { ...newAppointment, encounterType };
    } else {
      newAppointment = { ...newAppointment, reason: newAppointment.encounterType.label, encounterType: "Other" };
    }

    if (!isEmpty(newAppointment.assessmentId)) {
      newAppointment = { ...newAppointment, assessmentId: newAppointment.assessmentId === "NewAssessment" ? null : newAppointment.assessmentId };
    }

    const apiCall = this.props.isEdit ? this.props.editAppointment : this.props.scheduleAppointment;
    apiCall(newAppointment).then(responds => {
      if (responds.error !== true) {
        this.props.setRecentSelectedUser(appointment.assignedUserId);
        this.props.onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  render() {
    const {
      isEdit,
      initialValues,
      enrolmentOptions,
      showEnrolmentOptions,
      locationOptions,
      patientId,
      defaultInitialAssessment,
      ...other
    } = this.props;

    const { encounterOptions } = this.state;

    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        contentProps={other}
        content={({ values }) => {
          let { appointment: { encounterType } } = values;
          encounterType = encounterType?.value ?? encounterType;

          return (
            <>
              {showEnrolmentOptions && (
                <Field
                  name="appointment.enrolmentType"
                  component={SelectInput}
                  icon={<CircleIconP />}
                  validate={validateProgram}
                  label={programLabel}
                  disabled={isEdit}
                  options={enrolmentOptions}
                  onChange={(value, change) => {
                    change("appointment.encounterType", null);
                    if (value && value === "WelfareCheck") {
                      this.setState({ encounterOptions: encounterTypeOptionsWelfare });
                    } else {
                      this.setState({ encounterOptions: encounterTypeOptions });
                    }
                  }}
                />
              )}
              <Field
                name="appointment.assignedUserId"
                label={userLabel}
                component={UserSelector}
                validate={validateUser}
              />
              <Field
                name="appointment.startDateTimeUtc"
                label={startDateTimeUtcLabel}
                component={DateTimePicker}
                validate={validateStartDateTimeUtc}
              />
              <Field
                name="appointment.encounterType"
                icon="mode_comment"
                label={encounterTypeLabel}
                component={AutoComplete}
                options={encounterOptions}
                validate={validateEncounterType}
                isCreatable
                createLabel="Other:"
                onChange={(value, change) => {
                  if (value) {
                    if (value.value === "FollowUpAssessment") {
                      change("appointment.assessmentId", "NewAssessment");
                    } else if (value.value === "InitialAssessment") {
                      change("appointment.assessmentId", defaultInitialAssessment);
                    }
                  }
                }}
              />
              {((encounterType === "InitialAssessment") || (encounterType === "FollowUpAssessment")) && (
              <Field
                name="appointment.assessmentId"
                icon="mode_comment"
                label={currentAssessmentLabel}
                component={EncounterAssessmentInput}
                validate={validateAssessmentChoice}
                encounterType={encounterType}
                patientId={patientId}
              />
              )}
              <Field
                name="appointment.location"
                component={SelectInput}
                icon="location_on"
                label={locationLabel}
                options={locationOptions}
              />
              <Field
                name="appointment.details"
                label={notesLabel}
                component={NoteInput}
              />
            </>
          );
        }}
      />
    );
  }
}

export default connect((state, ownProps) => {
  const { isEdit, patientId } = ownProps;
  let appointment = getCurrentAppointment(state);
  const enrolmentTypes = getEnrolmentTypeValues(state);
  const enrolments = getPatientEnrolmentsById(state, patientId);
  const enrolmentOptions = getPatientEnrolmentOptions(state, patientId, enrolmentTypes);
  const assessment = getAssessmentByHeaderId(state, appointment.patientId, appointment.assessmentHeaderId);
  // check the enrolment type so the correct encounter options are set
  const isWelfareProgram = appointment.enrolmentType === "WelfareCheck" || (size(enrolments) < 2 && get(head(enrolments), ["enrolmentType"]) === "WelfareCheck");
  // only show options for the programs that the client is enroled in
  const showEnrolmentOptions = size(enrolmentOptions) > 1;
  // TODO the user of the head enrolment might be disabled (inactive)
  const assignedTo = head(enrolments);

  if (isEdit) {
    appointment = {
      ...appointment,
      encounterType: appointment.encounterType === "Other" ? { value: appointment.encounterType, label: appointment.reason } : find(allEncounterOptions, x => x === appointment.encounterType),
      assessmentId: appointment.assessmentHeaderId ? assessment?.id : "NewAssessment",
    };
  } else {
    appointment = {
      ...appointment,
      enrolmentType: head(enrolmentOptions)?.value,
    };
  }

  const assignedUserId = appointment?.assignedUserId || assignedTo?.assignedToUserId;

  appointment = {
    ...appointment,
    assignedUserId: assignedUserId ? {
      value: assignedUserId,
      label: appointment?.assignedToDisplayName || assignedTo?.assignedToUser,
    } : null,
  };

  return ({
    initialValues: { appointment },
    locationOptions: getLocationTypeValues(state),
    enrolmentOptions,
    showEnrolmentOptions,
    isWelfareProgram,
    defaultInitialAssessment: getDefaultPatientAssessment(state, patientId, "InitialAssessment"),
  });
}, {
  scheduleAppointment,
  editAppointment,
  setRecentSelectedUser,
  getEnrolmentTypeValues,
  getPatientEnrolmentsById,
})(ScheduleAppointmentForm);
