import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Field } from "formik";
import { FormControlLabel, Checkbox, Typography } from "@material-ui/core";
import { map } from "lodash";
import { Alert } from "@material-ui/lab";
import TagHeartOutlineIcon from "mdi-react/TagHeartOutlineIcon";

import { NoteInput } from "components/inputs/text-input";
import RadioButtonsGroup from "components/inputs/radio-buttons-group";
import { acceptReferralAction } from "app/main/referrals/actions/referrals.actions";
import UrgentStatus from "app/main/referrals/components/urgent-status";
import Form from "components/form";
import { getEnabledTriageCategorySettings } from "app/auth/store/reducers/system-configuration";
import CodeSetValueSelector from "app/main/codeSet/components/code-set-value-selector";

const AcceptReferralForm = ({
  referralId,
  onSucceed,
  schema,
  message,
  fieldSettings,
  fieldLabels,
  ...other
}) => {
  const dispatch = useDispatch();
  const [isUrgent, setIsUrgent] = useState(false);
  const [isUrgentDisabled, setUrgentIsDisabled] = useState(false);
  const initialValues = schema.cast();
  const triageCategories = useSelector(getEnabledTriageCategorySettings);

  const isUrgentControl = () => (
    <FormControlLabel
      control={(
        <Checkbox
          onChange={() => setIsUrgent(!isUrgent)}
          value="isUrgent"
          disabled={isUrgentDisabled}
          checked={isUrgent}
        />
      )}
      label={<UrgentStatus labelOnly />}
    />
  );

  const categoryChoices = map(triageCategories, category => ({
    value: category.triageCategory,
    label: (<Typography style={{ color: category.color }} className="font-bold">{category.label}</Typography>),
    description: category.description,
    renderExtraContent: category.triageCategory === "Category1" && isUrgentControl(false),
  }));

  const handleSubmit = ({ referral }, { setSubmitting, setErrors }) => {
    const acceptReferral = {
      ...referral,
      referralId,
      isUrgent,
      episodeHealthConditionCodeId: referral.episodeHealthConditionCodeId?.value ?? referral.episodeHealthConditionCodeId,
    };

    dispatch(acceptReferralAction(acceptReferral)).then(responds => {
      setSubmitting(false);
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  return (
    <Form
      contentProps={other}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      variant="filled"
      content={() => (
        <>
          {message && <Alert severity="warning">{message}</Alert>}
          <div className="ml-32">
            <Field
              name="referral.triageCategory"
              component={RadioButtonsGroup}
              choices={categoryChoices}
              onChange={value => {
                if (value !== "Category1") {
                  setUrgentIsDisabled(true);
                } else {
                  setUrgentIsDisabled(false);
                }
              }}
            />
          </div>
          {fieldSettings?.episodeHealthCondition && (
            <Field
              name="referral.episodeHealthConditionCodeId"
              component={CodeSetValueSelector}
              label={fieldLabels.episodeHealthCondition}
              codeSetTypeCode={fieldSettings.episodeHealthCondition.fieldName}
              required={fieldSettings.episodeHealthCondition.required}
              icon={<TagHeartOutlineIcon />}
            />
          )}
          {fieldSettings?.note && (
            <Field
              name="referral.note"
              component={NoteInput}
              label={fieldLabels.note}
              required={fieldSettings.note.required}
            />
          )}
        </>
      )}
    />
  );
};

export default AcceptReferralForm;
