import { ApiActions } from "middleware/call-api";
import { isEmpty, forEach } from "lodash";
import { ActionTypes, CANDIDATES_PAGE_SIZE, isPageLoading } from "../reducers/candidates.reducers";

export const setCurrentCandidate = candidate => ({
  type: ActionTypes.SET_CURRENT_CANDIDATE,
  payload: { candidate },
});

export const fetchCandidateResults = (pageNumber = 1, pageSize = CANDIDATES_PAGE_SIZE, searchParams) => (dispatch, getState) => {
  let url = `/candidates?pageNumber=${pageNumber}&pageSize=${pageSize}`;

  if (!isEmpty(searchParams)) {
    forEach(searchParams, (value, key) => {
      if (!isEmpty(value)) {
        url += `&${key}=${value}`;
      }
    });
  }

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "CANDIDATES",
      pageNumber,
      bailout: isPageLoading(getState()),
    },
  });
};

export const registerCandidate = model => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: "/candidates/register",
    body: { model },
    types: ["REGISTERING_CANDIDATE", ActionTypes.REGISTERED_CANDIDATE, "ERROR_REGISTERING_CANDIDATE"],
  },
});
