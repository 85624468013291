import React from "react";
import { Field } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";

import Form from "components/form";
import { DatePicker, UserSelector, NoteInput, TextAreaInput, SelectInput } from "components/inputs";
import { StatusIcon } from "helpers/icon-finder";

const appealedByLabel = "Appealed By";
const appealDateLabel = "Appeal Date";
const notesLabel = "Notes";
const outcomeLabel = "Outcome";
const statusLabel = "Status";

const statusOptions = [
  { value: "Open", label: "Open" },
  { value: "Closed", label: "Closed" },
];

const schema = Yup.object().shape({
  appeal: Yup.object().shape({
    appealedBy: Yup.string()
      .max(255, "Too Long!")
      .required(`${appealedByLabel} is required`).nullable(),
    appealDate: Yup.date()
      .required(`${appealDateLabel} is required`).nullable(),
    status: Yup.string()
      .required(`${statusLabel} is required`).nullable(),
    outcome: Yup.string()
      .max(255, "Too Long")
      .nullable(),
    notes: Yup.string()
      .max(4000, "Too Long")
      .nullable(),
  }),
});

const AppealForm = ({
  handleSubmit,
  applicationAppeal,
  ...other
}) => {
  let { appeal } = schema.cast();
  if (!isEmpty(applicationAppeal)) {
    appeal = applicationAppeal;
  }

  return (
    <Form
      initialValues={{ appeal }}
      onSubmit={handleSubmit}
      contentProps={other}
      validationSchema={schema}
      content={() => (
        <>
          <Field
            name="appeal.appealDate"
            component={DatePicker}
            label={appealDateLabel}
            required
          />
          <Field
            name="appeal.appealedBy"
            component={UserSelector}
            label={appealedByLabel}
            icon="person"
            required
          />
          <Field
            name="appeal.status"
            icon={<StatusIcon />}
            component={SelectInput}
            options={statusOptions}
            label={statusLabel}
            required
          />
          <Field
            name="appeal.outcome"
            icon="list_alt"
            component={TextAreaInput}
            label={outcomeLabel}
            maxLength={255}
          />
          <Field
            name="appeal.notes"
            component={NoteInput}
            label={notesLabel}
            maxLength={4000}
          />
        </>
      )}
    />
  );
};

export default AppealForm;
