import React from "react";
import { Field } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { isEmpty } from "lodash";

import Form from "components/form";
import { onSaveAdditionalInformation } from "app/main/applications/actions/applications.actions";
import { NoteInput } from "components/inputs/text-input";

const additionalInformationLabel = "Note";

const schema = Yup.object().shape({
  application: Yup.object().shape({
    additionalInformation: Yup.string()
      .max(10000, "Too long!")
      .nullable(),
  }),
});

class AdditionalInformationForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = ({ application }, { setErrors }) => {
    const { applicationId, orgUnitId } = this.props;

    this.props.onSaveAdditionalInformation(applicationId, application.additionalInformation, orgUnitId).then(response => {
      if (response.error !== true) {
        this.props.onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  }

  render() {
    const { initialValues, isEdit, canEdit, ...other } = this.props;

    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        contentProps={other}
        validationSchema={schema}
        onRemove={isEdit && canEdit ? (formProps => this.handleSubmit({ application: { additionalInformation: null } }, { ...formProps })) : null}
        content={() => (
          <Field
            name="application.additionalInformation"
            component={NoteInput}
            label={additionalInformationLabel}
            rows={8}
            maxLength={10000}
          />
        )}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { additionalInformation: information, id } = ownProps;

  let { application } = schema.cast();

  if (!isEmpty(information)) {
    application = { additionalInformation: information };
  }
  return ({
    initialValues: { application },
    applicationId: id,
  });
};

export default connect(mapStateToProps, {
  onSaveAdditionalInformation,
})(AdditionalInformationForm);
