import { ApiActions } from "middleware/call-api";
import { get } from "lodash";
import moment from "moment";
import { ActionTypes,
  areInterventionsLoading,
  areInterventionsLoaded,
  getInterventionById } from "../reducers/interventions.reducers";

export const setCurrentIntervention = intervention => ({
  type: ActionTypes.LOADED_CURRENT_INTERVENTION,
  payload: { intervention },
});

export const fetchPatientInterventions = patientId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/interventions`,
    bailout: state => areInterventionsLoaded(state, patientId) || areInterventionsLoading(state, patientId),
    entityType: "interventions",
    patientId,
  },
});

export const fetchInterventionsById = (patientId, interventionId) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/interventions/${interventionId}`,
    types: [ActionTypes.LOADING_CURRENT_INTERVENTION, ActionTypes.LOADED_CURRENT_INTERVENTION, ActionTypes.ERROR_LOADING_CURRENT_INTERVENTION],
    patientId,
    interventionId,
  },
});

/** * Add Intervention */
export const recordNewIntervention = patientId => setCurrentIntervention({
  id: null,
  patientId,
  recordedDateTimeUtc: moment(),
  priority: null,
  description: null,
  note: null,
  startDate: null,
  removedDateTimeUtc: null,
});

export const editExistingIntervention = (patientId, interventionId) => (dispatch, getState) => {
  const intervention = getInterventionById(getState(), patientId, interventionId);
  dispatch(setCurrentIntervention(intervention));
};

export const recordOrUpdateIntervention = (patientId, intervention) => async dispatch => {
  const INTERVENTION_ACTION = get(intervention, "id", false)
    ? ["UPDATING_INTERVENTION", ActionTypes.UPDATED_INTERVENTION, "ERROR_UPDATING_INTERVENTION"]
    : ["CREATING_INTERVENTION", ActionTypes.CREATED_INTERVENTION, "ERROR_CREATING_INTERVENTION"];

  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/patient/${patientId}/interventions/record`,
      body: { intervention },
      types: INTERVENTION_ACTION,
      patientId,
    },
  });
};

export const removeIntervention = (patientId, interventionId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/patient/${patientId}/interventions/remove`,
    body: { interventionId },
    entityType: "intervention",
    patientId,
    interventionId,
  },
});

export const resolveIntervention = (patientId, interventionId) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/patient/${patientId}/interventions/resolve`,
    body: { interventionId },
    types: ["RESOLVING_INTERVENTION", ActionTypes.RESOLVE_INTERVENTION, "ERROR_RESOLVING_INTERVENTION"],
    patientId,
  },
});

export const renewIntervention = (patientId, interventionId) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/patient/${patientId}/interventions/renew`,
    body: { interventionId },
    types: ["RENEWING_INTERVENTION", ActionTypes.RESOLVE_INTERVENTION, "ERROR_RENEWING_INTERVENTION"],
    patientId,
  },
});
