import React from "react";
import { useDispatch } from "react-redux";
import { showMessage } from "app/store/actions/message.actions";
import { getError } from "utils/errorutil";

import AttachmentUploadButton from "../components/orgUnit-attachment-upload-button";

const OrgUnitFileAttachmentPage = ({
  label,
  onUploadFile,
  acceptFormat,
  orgUnitId,
  loading,
  onRemoveFile,
  currentAttachment,
  onDownloadFile,
}) => {
  const dispatch = useDispatch();

  return (
    <AttachmentUploadButton
      label={label}
      icon="attachment"
      orgUnitId={orgUnitId}
      loading={loading}
      currentAttachment={currentAttachment}
      required
      onDownloadFile={onDownloadFile}
      acceptFormat={acceptFormat}
      onRemove={currentOrgUnitId => dispatch(onRemoveFile(currentOrgUnitId)).then(() => {
        dispatch(showMessage({
          title: `${label} Removed`,
          variant: "success",
          message: `The ${label} is Removed`,
        }));
      })}
      onChange={e => {
        const value = e.target.files[0];
        if (value) {
          const reader = new FileReader();
          reader.readAsDataURL(value);
          reader.onload = () => {
            const formData = new FormData();
            formData.append("file", value);
            dispatch(onUploadFile(orgUnitId, formData)).then(response => {
              if (response.error === true) {
                const errorMessage = getError(response);
                dispatch(showMessage({
                  title: errorMessage.title,
                  variant: "error",
                  message: errorMessage.message,
                  anchorOrigin: { vertical: "bottom", horizontal: "center" },
                }));
              } else {
                dispatch(showMessage({
                  title: `${label} Uploaded`,
                  variant: "success",
                  message: `The ${label} is uploaded`,
                }));
              }
            });
          };
        }
      }}
    />
  );
};

export default OrgUnitFileAttachmentPage;
