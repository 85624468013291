// global variables

// main header toolbar config
export const header = {
  height: 64,
};

export const drawer = {
  width: 400,
};

export const actionPanel = {
  width: 240,
};
