import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Typography, Popover } from "@material-ui/core";
import { Formik } from "formik";
import { isEmpty, get, isString, flatMap } from "lodash";

import DefaultButton from "components/items/default-button";
import { closeDialog } from "app/store/actions/dialog.actions";
import DialogPopup from "components/items/dialog-popup";
import PopupMenu from "components/items/popup-menu";
import { showMessage } from "app/store/actions/message.actions";
import FormContext from "./FormContext";

/**
 * this component uses Formik to handle submit status and etc.
 * for the list of props, visit: https://jaredpalmer.com/formik/docs/api/formik#setsubmitting-issubmitting-boolean-void
 * this component can be rendered in two modes
 * - one is display in a pop up dialog by setting isModal to true (default to true)
 * - other one is inline form, usage example - userForm, patientForm
 * required props are:
 * - initialValues
 * - onSubmit
 * - content -> form fields / content, extends all props and functions from formik
 */
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  content: {
    padding: theme.spacing(6, 6, 6, 2),
  },
  defaultContent: {
    flex: "1 1 auto",
    "& > *:not(:first-child):not(:empty)": {
      marginTop: theme.spacing(2),
    },
  },
  removeButton: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
    "&.disabled": {
      backgroundColor: theme.palette.background.default,
    },
  },
  extraActions: {
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
}));

/**
 * the function onRemove extends form props - use it like:
 * onRemove={formProps => this.handleRemove({whatever you want to pass to your function, ...formProps })}
 * in the handleSubmit function, you need to use setErrors if there is error coming back the responds
 */
const RemoveActionButton = ({
  loading,
  disabled,
  onClick,
  buttonSize,
  removeLabel = "Remove",
  confirmationMessage,
}) => {
  const classes = useStyles();
  return (
    <PopupMenu
      trigger={popupState => (
        <DefaultButton
          disabled={disabled}
          size={buttonSize}
          label={removeLabel}
          className={clsx(classes.removeButton, disabled && "disabled")}
          loading={loading}
          {...popupState}
        />
      )}
      content={({ close, ...other }) => (
        <Popover
          {...other}
        >
          <div className="p-16">
            <Typography>{confirmationMessage || "Are you sure?"}</Typography>
            <div className="mt-16 flex justify-between">
              <DefaultButton
                onClick={() => { onClick(); close(); }}
                label={`Confirm ${removeLabel}`}
                size="medium"
                className={clsx(classes.removeButton, "flex-1")}
              />
              <DefaultButton
                color="default"
                label="Cancel"
                className="ml-16 flex-1"
                size="medium"
                variant="text"
                onClick={() => close()}
              />
            </div>
          </div>
        </Popover>
      )}
    />
  );
};

/**
 * Action buttons contains
 * - save -> submit the form
 * - cancel -> close the dialog if its modal, go back to the previous page or whatever is passed down from props
 * - remove -> only render if onRemove function has been passed in
 */
const ActionButtonComponent = ({
  history,
  onRemove,
  onCancel,
  disabled,
  isModal = true,
  showCancelButton = true,
  submitLabel = "Save",
  cancelLabel = "Cancel",
  buttonSize = "medium",
  extraActions,
  renderSubmitButton,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    handleSubmit,
    errors,
    setSubmitting,
    isSubmitting,
    status,
    resetForm,
  } = otherProps;
  const [isRemoving, setRemovingState] = useState(false);
  const disableActionButton = disabled || isSubmitting;

  const saving = isSubmitting && isEmpty(errors) && !isRemoving;
  const removing = isRemoving && isEmpty(errors);

  const errorMessage = get(errors, "message", null);
  const errorTitle = get(errors, "title", null);
  const apiErrors = get(status, "apiErrors", null);

  const onClose = () => {
    if (onCancel) {
      onCancel();
    }
    dispatch(closeDialog());
  };

  const cancel = () => {
    if (isModal) {
      onClose();
    } else if (onCancel) {
      onCancel();
      resetForm();
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    const reset = () => { setSubmitting(false); setRemovingState(false); };
    if (errorMessage) {
      dispatch(showMessage({
        variant: "error",
        title: errorTitle,
        message: errorMessage,
        anchorOrigin: { vertical: isModal ? "top" : "bottom", horizontal: isModal ? "center" : "right" },
        onClose: reset,
        onExit: reset,
      }));
    }
  }, [dispatch, isModal, errorTitle, errorMessage, setSubmitting]);

  return (
    <div className={clsx("flex flex-1 items-center ", isModal ? "justify-between" : "justify-center")}>
      <div>
        {onRemove
          && (
            <RemoveActionButton
              onClick={() => { setRemovingState(true); onRemove(otherProps); }}
              loading={removing}
              disable={disableActionButton}
              buttonSize={buttonSize}
              setRemovingState={setRemovingState}
              {...otherProps}
            />
          )}
        {extraActions && <div className={classes.extraActions}>{extraActions}</div>}
      </div>
      <div className="flex items-center justify-end">
        {renderSubmitButton
          ? renderSubmitButton({ ...otherProps }) : (
            <DefaultButton
              onClick={handleSubmit}
              disabled={disableActionButton || !isEmpty(apiErrors)}
              label={submitLabel}
              size={buttonSize}
              loading={saving}
              type="submit"
            />
          )}
        {showCancelButton && (
          <DefaultButton
            color="default"
            variant="text"
            onClick={() => cancel()}
            label={cancelLabel}
            className="ml-16"
            size={buttonSize}
            disabled={isSubmitting}
          />
        )}
      </div>
    </div>
  );
};

/**
 * ContentComponent
 * add the ability to focus on the first invalid input, note: not apply to custom inputs e.g. react-select
 * display field error message in snackbar, note: not for nested validation object
 */
const ContentComponent = ({ content, formProps, showRequiredMessages }) => {
  const { errors, isSubmitting, submitCount } = formProps;
  const dispatch = useDispatch();

  useEffect(() => {
    // check if the user try to submit the form
    // this will prevent focus event when user fullfil one of the error field
    if (!isEmpty(errors) && isSubmitting) {
      const firstInvalidInput = document.querySelector('*[aria-invalid="true"]');
      if (firstInvalidInput) {
        firstInvalidInput.focus();
      }
    }
  });

  useEffect(() => {
    if (showRequiredMessages && !isEmpty(errors) && submitCount > 0) {
      const message = flatMap(errors, x => flatMap(x, (errorContent, index) => {
        if (isString(errorContent)) {
          return <Typography key={index.toString()}>{errorContent}</Typography>;
        }
        return "";
      }));
      dispatch(showMessage({
        variant: "error",
        title: "Please correct the highlighted fields below",
        message,
        autoHideDuration: null,
      }));
    }
  }, [showRequiredMessages, errors, dispatch, submitCount]);

  return content(formProps);
};

const Form = ({
  isModal = true,
  content,
  contentClassName,
  contentProps,
  showRequiredMessages,
  disabled,
  classes,
  renderHeaderContent,
  variant,
  ...otherProps
}) => {
  const styles = useStyles();
  return (
    isModal ? (
      <Formik
        enableReinitialize
        {...otherProps}
      >
        {props => (
          <DialogPopup
            {...contentProps}
            renderHeaderContent={renderHeaderContent}
            renderActions={<ActionButtonComponent isModal disabled={disabled} {...contentProps} {...otherProps} {...props} />}
            content={(
              <FormContext.Provider value={{ formDisabled: disabled, formFieldVariant: variant }}>
                <div className={styles.defaultContent}><ContentComponent content={content} formProps={props} showRequiredMessages={showRequiredMessages} /></div>
              </FormContext.Provider>
            )}
          />
        )}
      </Formik>
    ) : (
      <Formik
        enableReinitialize
        {...otherProps}
      >
        {props => (
          <FormContext.Provider value={{ formDisabled: disabled, formFieldVariant: variant }}>
            <div className={clsx(styles.root, classes?.root)}>
              <div className={clsx(styles.content, styles.defaultContent, classes?.content)}>
                <ContentComponent content={content} formProps={props} showRequiredMessages={showRequiredMessages} />
              </div>
              {!disabled && (
                <div className="p-24">
                  <ActionButtonComponent isModal={false} disabled={disabled} {...contentProps} {...otherProps} {...props} />
                </div>
              )}
            </div>
          </FormContext.Provider>
        )}
      </Formik>
    )
  );
};

export default Form;
