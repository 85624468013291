import React from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import { Typography, Grid, Divider } from "@material-ui/core";
import moment from "moment";
import { includes, isEmpty, get, map, size, head, reduce, lowerFirst, find } from "lodash";
import { getCurrentEncounter } from "app/main/patients/reducers/encounters.reducers";
import { recordEncounter, editEncounter } from "app/main/patients/actions/encounters.actions";
import { getAssessmentByHeaderId, getDefaultPatientAssessment } from "app/main/patients/reducers/assessments.reducers";
import { getPatientEnrolmentOptions, getPatientEnrolmentsById } from "app/main/patients/reducers/patients.reducers";
import { encounterTypes, encounterTypesWelfare, createOption } from "app/main/patients/helpers/encounter-options";
import { validateRequired, validateDateIsBefore, validateDateIsAfter, validateNumber, validateIfRequired } from "utils/validators";
import RadioButtonsGroup from "components/inputs/radio-buttons-group";
import CheckboxInput from "components/inputs/checkbox";
import Form from "components/form";
import { DateTimePicker, UserSelector, SelectInput, NumberInput, NoteInput, AutoComplete } from "components/inputs";
import { setRecentSelectedUser } from "app/main/users/actions/users.actions";
import { getContactMethodValues, getLocationTypeValues, getContactValues, getEnrolmentTypeValues } from "app/auth/store/reducers/system-configuration";
import { CircleIconP } from "helpers/icon-finder";
import getWelfareQuestion from "app/main/patients/helpers/get-welfare-question";
import { getUser } from "app/auth/store/reducers/user.reducer";
import EncounterAssessmentInput from "app/main/patients/encounters/components/encounter-assessment-input";

const encounterTypeOptions = map(encounterTypes, encounterType => createOption(encounterType));
const encounterTypeOptionsWelfare = map(encounterTypesWelfare, encounterType => createOption(encounterType));
const allEncounterOptions = [...encounterTypes, ...encounterTypesWelfare];

const welfareChoices = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];
const consentedChoices = [
  { value: true, label: "Consent Obtained" },
  { value: false, label: "Did Not Consent" },
];

const programLabel = "Program";
const purposeLabel = "Purpose";
const nextContactPurposeLabel = "Purpose";
const contactMethodLabel = "Contact Method";
const participantUserIdLabel = "Conducted By";
const startDateTimeUtcLabel = "Start Date / Time";
const endDateTimeUtcLabel = "End Date";
const nextContactDateLabel = "Scheduled Date / Time";
const durationLabel = "Duration";
const currentAssessmentLabel = "Assessment";
const nextContactAssessmentLabel = "Assessment";
const welfareAnswerLabel = "Answer";
const nextContactDetailsLabel = "Details (optional)";
const detailsLabel = "Details (optional)";
const locationLabel = "Location (optional)";
const contactInitiatedByLabel = "Contact Initiated By (optional)";
const doNotContactAgainLabel = "Do Not Contact Again";

const validateProgram = validateRequired(programLabel);
const validatePurpose = validateRequired(purposeLabel);
const validateContactMethod = validateRequired(contactMethodLabel);
const validateParticipantUserId = validateRequired(participantUserIdLabel);
const validateStartDateTimeUtc = value => validateRequired(startDateTimeUtcLabel)(value) || validateDateIsBefore(startDateTimeUtcLabel, endDateTimeUtcLabel, ["encounter", "endDateTimeUtc"])(value);
const validateEndDateTimeUtc = validateDateIsAfter(endDateTimeUtcLabel, startDateTimeUtcLabel, ["encounter", "startDateTimeUtc"]);
const validateNextContactPurpose = validateIfRequired(nextContactPurposeLabel, ["encounter", "nextContactDate"]);
const validateNextContactDate = validateIfRequired(nextContactDateLabel, ["encounter", "nextContactPurpose"]);
const validateConsented = validateRequired("consented");
const validateWelfareCheck = validateRequired(welfareAnswerLabel);
const validateDuration = validateNumber(durationLabel, { minLength: 0 });
const validateAssessmentChoice = validateRequired(currentAssessmentLabel);
const validateNextContactAssessmentChoice = validateRequired(nextContactAssessmentLabel);

class RecordEncounterForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      encounterOptions: props.isWelfareProgram ? encounterTypeOptionsWelfare : encounterTypeOptions,
    };
  }

  handleSubmit = ({ encounter }, { setErrors }) => {
    let newEncounter = {
      ...encounter,
      participantUserId: encounter.participantUserId?.value,
      status: !isEmpty(encounter.endDateTimeUtc) ? "Completed" : "InProgress",
      contactInitiatedBy: encounter?.contactInitiatedBy?.label ?? encounter?.contactInitiatedBy,
    };
    const encounterType = newEncounter.encounterType?.value ?? newEncounter.encounterType;
    const nextContactPurpose = newEncounter.nextContactPurpose?.value ?? newEncounter.nextContactPurpose;

    if (includes(allEncounterOptions, encounterType)) {
      newEncounter = { ...newEncounter, encounterType };
    } else {
      newEncounter = { ...newEncounter, reason: newEncounter.reason ?? newEncounter.encounterType.label, encounterType: "Other" };
    }

    if (!isEmpty(nextContactPurpose)) {
      if (includes(allEncounterOptions, nextContactPurpose)) {
        newEncounter = { ...newEncounter, nextContactPurpose };
      } else {
        newEncounter = { ...newEncounter, nextContactReason: newEncounter.nextContactReason ?? newEncounter.nextContactPurpose.label, nextContactPurpose: "Other" };
      }
    }

    if (!isEmpty(newEncounter.nextContactAssessmentId)) {
      newEncounter = { ...newEncounter, nextContactAssessmentId: newEncounter.nextContactAssessmentId === "NewAssessment" ? null : newEncounter.nextContactAssessmentId };
    }

    if (!isEmpty(newEncounter.assessmentId)) {
      newEncounter = { ...newEncounter, assessmentId: newEncounter.assessmentId === "NewAssessment" ? null : newEncounter.assessmentId };
    }

    if (newEncounter.encounterType === "WelfareCheck") {
      const answers = map(newEncounter.welfareQuestionnaire, (answer, questionType) => ({
        questionType,
        answer,
        question: getWelfareQuestion(questionType),
      }));

      newEncounter = { ...newEncounter, welfareQuestionsJson: JSON.stringify(answers) };
    }

    const apiCall = this.props.isEdit ? this.props.editEncounter : this.props.recordEncounter;
    apiCall(newEncounter).then(responds => {
      if (responds.error !== true) {
        this.props.setRecentSelectedUser(encounter.participantUserId);
        this.props.onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  render() {
    const {
      isEdit,
      disabledEncounterType,
      initialValues,
      enrolmentOptions,
      showEnrolmentOptions,
      patientId,
      defaultInitialAssessment,
      ...other
    } = this.props;

    const { encounterOptions } = this.state;

    return (
      <Form
        contentProps={other}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        content={({ values }) => {
          const { encounter: { startDateTimeUtc, endDateTimeUtc, duration, consented, welfareQuestionnaire } } = values;
          let { encounter: { encounterType, nextContactPurpose } } = values;
          encounterType = get(encounterType, ["value"], encounterType);
          nextContactPurpose = get(nextContactPurpose, ["value"], nextContactPurpose);

          return (
            <>
              {showEnrolmentOptions && (
                <Field
                  name="encounter.enrolmentType"
                  component={SelectInput}
                  icon={<CircleIconP />}
                  validate={validateProgram}
                  label={programLabel}
                  disabled={isEdit || disabledEncounterType}
                  options={enrolmentOptions}
                  required
                  onChange={(value, change) => {
                    change("encounter.encounterType", null);
                    if (value === "WelfareCheck") {
                      this.setState({ encounterOptions: encounterTypeOptionsWelfare });
                    } else {
                      this.setState({ encounterOptions: encounterTypeOptions });
                    }
                  }}
                />
              )}
              <Field
                name="encounter.encounterType"
                icon="mode_comment"
                label={purposeLabel}
                component={AutoComplete}
                options={encounterOptions}
                validate={validatePurpose}
                isCreatable
                createLabel="Other:"
                disabled={isEdit || disabledEncounterType}
                onChange={(value, change) => {
                  if (value != null) {
                    if (!includes(allEncounterOptions, value.value)) {
                      change("encounter.nextContactDate", null);
                    }

                    if ((value.value === "ConsentCall" || value.value === "WelfareCheck")) {
                      change("encounter.contactMethod", "Phone");
                    } else {
                      change("encounter.contactMethod", "Visit");
                    }

                    if (value.value === "FollowUpAssessment") {
                      change("encounter.assessmentId", "NewAssessment");
                    } else if (value.value === "InitialAssessment") {
                      change("encounter.assessmentId", defaultInitialAssessment);
                    } else if (value.value !== "ConsentCall") {
                      change("encounter.doNotContactAgain", null);
                    } else if (value.value !== "ConsentVisit") {
                      change("encounter.consented", null);
                    }
                  }
                }}
              />
              {((encounterType === "InitialAssessment") || (encounterType === "FollowUpAssessment")) && (
                <Field
                  name="encounter.assessmentId"
                  icon="mode_comment"
                  label={currentAssessmentLabel}
                  component={EncounterAssessmentInput}
                  validate={validateAssessmentChoice}
                  encounterType={encounterType}
                  patientId={patientId}
                  disabled={isEdit}
                />
              )}
              <Field
                name="encounter.contactMethod"
                component={SelectInput}
                icon="call"
                validate={validateContactMethod}
                label={contactMethodLabel}
                disabled={isEdit}
                options={this.props.contactMethodOptions}
              />
              <Field
                name="encounter.location"
                component={SelectInput}
                icon="location_on"
                label={locationLabel}
                options={this.props.locationOptions}
              />
              <Field
                name="encounter.contactInitiatedBy"
                label={contactInitiatedByLabel}
                icon="person"
                component={AutoComplete}
                options={this.props.contactOptions}
                isCreatable
                createLabel="Contact initiated by: "
              />
              <Field
                name="encounter.participantUserId"
                label={participantUserIdLabel}
                component={UserSelector}
                validate={validateParticipantUserId}
              />
              <Field
                name="encounter.startDateTimeUtc"
                label={startDateTimeUtcLabel}
                component={DateTimePicker}
                validate={value => validateStartDateTimeUtc(value, values)}
                onChange={(value, change) => {
                  if (duration !== null && duration !== undefined && isEmpty(endDateTimeUtc)) {
                    const newEndDateTimeUtc = moment(value).add(Number(duration), "m");
                    change("encounter.endDateTimeUtc", newEndDateTimeUtc);
                  } else if (!isEmpty(endDateTimeUtc)) {
                    const newDuration = moment.duration(moment(endDateTimeUtc).diff(value));
                    const mins = newDuration.asMinutes();
                    change("encounter.duration", mins > 0 ? mins : 0);
                  }
                }}
              />
              <div className="flex fle-col sm:flex-row">
                <Field
                  name="encounter.duration"
                  label={durationLabel}
                  suffix="mins"
                  component={NumberInput}
                  icon="schedule"
                  validate={validateDuration}
                  onChange={(value, change) => {
                    if (!isEmpty(startDateTimeUtc)) {
                      const newEndDateTimeUtc = moment(startDateTimeUtc).add(Number(value), "m");
                      change("encounter.endDateTimeUtc", newEndDateTimeUtc);
                    }
                  }}
                  className="flex-1"
                />
                <div className="flex w-56 items-center justify-center">
                  <Typography>OR</Typography>
                </div>
                <Field
                  name="encounter.endDateTimeUtc"
                  label={endDateTimeUtcLabel}
                  component={DateTimePicker}
                  validate={value => validateEndDateTimeUtc(value, values)}
                  onChange={(value, change) => {
                    const isValidateDate = moment(value).isValid();

                    if (!isEmpty(startDateTimeUtc) && isValidateDate) {
                      const newDuration = moment.duration(value.diff(startDateTimeUtc));
                      const mins = newDuration.asMinutes();
                      change("encounter.duration", mins > 0 ? mins : 0);
                    }
                  }}
                />
              </div>
              {(encounterType === "ConsentVisit" || encounterType === "ConsentCall") && (
                <Field
                  name="encounter.consented"
                  component={RadioButtonsGroup}
                  isBoolean
                  choices={consentedChoices}
                  validate={validateConsented}
                  displayRow
                  onChange={(value, change) => {
                    if (value !== false) {
                      change("encounter.doNotContactAgain", null);
                    }
                  }}
                />
              )}

              {(encounterType === "WelfareCheck") && (
                <div className="ml-32">
                  {map(welfareQuestionnaire, (answer, questionType) => (
                    <div key={questionType}>
                      <Field
                        label={getWelfareQuestion(questionType)}
                        name={`encounter.welfareQuestionnaire[${questionType}]`}
                        component={RadioButtonsGroup}
                        isBoolean
                        choices={welfareChoices}
                        validate={validateWelfareCheck}
                        displayRow
                        displayErrorAbove
                      />
                    </div>
                  ))}
                </div>
              )}

              <Field
                name="encounter.details"
                label={detailsLabel}
                component={NoteInput}
              />

              <Divider variant="middle" className="my-24" />
              <Grid container direction="row" alignItems="center">
                <Grid item xs={3}>
                  <Typography className="font-bold">Next Contact</Typography>
                </Grid>
                <Grid item xs>
                  <Field
                    name="encounter.doNotContactAgain"
                    label={doNotContactAgainLabel}
                    disabled={(encounterType !== "ConsentCall" || encounterType !== "ConsentVisit") && consented !== false}
                    component={CheckboxInput}
                  />
                </Grid>
              </Grid>
              <Field
                name="encounter.nextContactDate"
                label={nextContactDateLabel}
                validate={value => validateNextContactDate(value, values)}
                component={DateTimePicker}
              />
              <Field
                name="encounter.nextContactPurpose"
                icon="mode_comment"
                label={nextContactPurposeLabel}
                component={AutoComplete}
                options={encounterOptions}
                validate={value => validateNextContactPurpose(value, values)}
                isCreatable
                createLabel="Other:"
                onChange={(value, change) => {
                  if (value) {
                    if (value.value === "FollowUpAssessment") {
                      change("encounter.nextContactAssessmentId", "NewAssessment");
                    } else if (value.value === "InitialAssessment") {
                      change("encounter.nextContactAssessmentId", defaultInitialAssessment);
                    }
                  }
                }}
              />
              {((nextContactPurpose === "InitialAssessment") || (nextContactPurpose === "FollowUpAssessment")) && (
                <Field
                  name="encounter.nextContactAssessmentId"
                  icon="mode_comment"
                  label={currentAssessmentLabel}
                  component={EncounterAssessmentInput}
                  validate={validateNextContactAssessmentChoice}
                  encounterType={nextContactPurpose}
                  patientId={patientId}
                />
              )}
              <Field
                name="encounter.nextContactDetails"
                label={nextContactDetailsLabel}
                component={NoteInput}
              />
            </>
          );
        }}
      />
    );
  }
}

export default connect((state, ownProps) => {
  const { isEdit, patientId, disabledEncounterType } = ownProps;
  let encounter = getCurrentEncounter(state);
  const enrolmentTypes = getEnrolmentTypeValues(state);
  const enrolments = getPatientEnrolmentsById(state, patientId);
  const enrolmentOptions = getPatientEnrolmentOptions(state, patientId, enrolmentTypes);
  const currentUser = getUser(state);
  const assessment = getAssessmentByHeaderId(state, encounter.patientId, encounter.nextContactAssessmentHeaderId);

  // check the enrolment type so the correct encounter options are set
  const isWelfareProgram = get(encounter, ["enrolmentType", "value"]) === "WelfareCheck" || (size(enrolments) < 2 && get(head(enrolments), ["enrolmentType", "value"]) === "WelfareCheck");

  // only show enrolment options if erolled in more than 1 program
  const showEnrolmentOptions = size(enrolmentOptions) > 1;

  if (isEdit || disabledEncounterType) {
    const welfareQuestionnaireIsEmpty = encounter.welfareQuestionnaire
    && Object.keys(encounter.welfareQuestionnaire).every(key => encounter.welfareQuestionnaire[key] === null);
    if (!welfareQuestionnaireIsEmpty && get(encounter, "encounterType") === "WelfareCheck") {
      const welfareAnswers = reduce(encounter.welfareQuestionnaire, (r, a) => ({ ...r, [lowerFirst(a.questionType)]: a.answer }), {});
      encounter = { ...encounter, welfareQuestionnaire: welfareAnswers };
    }

    encounter = {
      ...encounter,
      // Show the 'other' encounterType reason entered by user
      encounterType: encounter.encounterType === "Other" ? { value: encounter.encounterType, label: encounter.reason } : find(allEncounterOptions, x => x === encounter.encounterType),
      // Show the 'other' nextContactPurpose entered by user
      nextContactPurpose: encounter.nextContactPurpose === "Other" ? { value: "Other", label: encounter.nextContactReason } : find(allEncounterOptions, x => x === encounter.nextContactPurpose),
      assessmentId: encounter.assessmentId ?? "NewAssessment",
      // get the assessment id - api returns the assessment header for the appointment, but expects an assessment id
      nextContactAssessmentId: assessment?.id ?? "NewAssessment",
    };
  } else {
    encounter = {
      ...encounter,
      enrolmentType: head(enrolmentOptions)?.value,
      participantUserId: {
        value: currentUser.userId,
        label: currentUser.name,
      },
    };
  }

  return ({
    initialValues: { encounter },
    contactMethodOptions: getContactMethodValues(state),
    contactOptions: getContactValues(state),
    locationOptions: getLocationTypeValues(state),
    enrolmentOptions,
    showEnrolmentOptions,
    isWelfareProgram,
    defaultInitialAssessment: getDefaultPatientAssessment(state, patientId, "InitialAssessment"),
  });
}, {
  recordEncounter,
  editEncounter,
  setRecentSelectedUser,
  getEnrolmentTypeValues,
  getPatientEnrolmentsById,
})(RecordEncounterForm);
