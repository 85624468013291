import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PatientForm from "app/main/patients/components/patient-form";
import { createNewPatient } from "app/main/patients/actions/patients.actions";
import DefaultPageLayout from "components/layouts/default-page-layout";
import useSetUrl from "app/main/admin/hooks/useSetUrl";

const PatientNewPage = ({
  route,
}) => {
  const dispatch = useDispatch();
  const { goBack, goBackWithState } = useSetUrl({ ...route });

  useEffect(() => {
    dispatch(createNewPatient());
  }, [dispatch]);

  return (
    <DefaultPageLayout
      content={(
        <PatientForm
          submitLabel="Add"
          onCancel={goBack}
          onSucceed={goBackWithState}
        />
      )}
    />
  );
};

export default PatientNewPage;
