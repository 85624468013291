import React from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import { NoteInput } from "components/inputs/text-input";
import { Alert } from "@material-ui/lab";

import { requestMoreInfoReferralAction } from "app/main/referrals/actions/referrals.actions";
import Form from "components/form";
import { validateRequired, validateMaxLength } from "utils/validators";

const messageLabel = "Message";
const validateMessage = value => validateRequired(messageLabel)(value) || validateMaxLength(messageLabel, 1000)(value);

class ReferralRequestMoreInfoForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = ({ referralRequestMoreInfo }, { setErrors }) => {
    const requestMoreInfo = {
      ...referralRequestMoreInfo,
      referralId: this.props.referralId,
    };

    this.props.requestMoreInfoReferralAction(requestMoreInfo).then(responds => {
      if (responds.error !== true) {
        this.props.onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  render() {
    const {
      message,
      ...other
    } = this.props;

    return (
      <Form
        contentProps={other}
        onSubmit={this.handleSubmit}
        initialValues={{ referralRequestMoreInfo: { message: null } }}
        variant="filled"
        content={() => (
          <>
            {message && <Alert severity="warning">{message}</Alert>}
            <Field
              name="referralRequestMoreInfo.message"
              component={NoteInput}
              label={messageLabel}
              icon="message"
              validate={validateMessage}
              autoFocus
            />
          </>
        )}
      />
    );
  }
}

export default connect(null, { requestMoreInfoReferralAction })(ReferralRequestMoreInfoForm);
