import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import withPermissions from "permissions/withPermissions";
import DefaultPageLayout from "components/layouts/default-page-layout";
import useSetUrl from "app/main/admin/hooks/useSetUrl";
import OrgUnitPrimaryHeader from "app/main/orgUnits/components/orgUnit-primary-header";
import ScreenHeader from "components/items/screen-header";

import { createNewSpecialtyProfile, resetCurrentSpecialtyProfile } from "../actions/specialtyProfiles.actions";
import SpecialtyProfileForm from "../components/specialtyProfile-form";

const SpecialtyProfileNewPage = ({
  match: { params: { id } },
  route,
  hasPermissionSpecialtyProfileCreate,
}) => {
  const dispatch = useDispatch();
  const { goBack, goBackWithState } = useSetUrl(route);

  useEffect(() => {
    dispatch(createNewSpecialtyProfile({ orgUnitId: id }));
    return () => dispatch(resetCurrentSpecialtyProfile());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <OrgUnitPrimaryHeader orgUnitId={id} />
      <DefaultPageLayout
        header={<ScreenHeader title="Add Specialty Profile" />}
        accessDenied={!hasPermissionSpecialtyProfileCreate}
        content={(
          <SpecialtyProfileForm
            submitLabel="Add"
            onCancel={goBack}
            onSucceed={goBackWithState}
          />
        )}
      />
    </>
  );
};

export default withPermissions("SpecialtyProfileCreate")(SpecialtyProfileNewPage);
