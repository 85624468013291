import React, { useContext } from "react";
import { AppBar, Toolbar, Typography, IconButton, Icon, Chip, Slide, useMediaQuery, Hidden } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import { find, get, map, filter, flattenDeep, concat } from "lodash";
import clsx from "clsx";
import AppContext from "app/AppContext";
import Logo from "components/items/hcs-logo";
import SiteLogo from "components/items/site-logo";
import ThemeSelector from "components/items/theme-selector";
import SiteSelector from "app/main/orgUnits/components/site-selector";
import { getUser, getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";

import UserMenu from "./app-menu";

const useStyles = makeStyles(theme => ({
  separator: {
    width: 1,
    height: 64,
    backgroundColor: theme.palette.divider,
  },
  bannerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    zIndex: 1,
  },
  banner: {
    border: `1px solid ${theme.palette.common.white}`,
    background: theme.palette.warning.main,
    color: theme.palette.getContrastText(theme.palette.warning.main),
    fontSize: 10,
  },
  bannerIcon: {
    color: "inherit",
    // set the size so it won't get overwrite
    width: "1em",
    height: "1em",
  },
  siteLogo: {
    height: 48,
  },
  content: {
    display: "flex",
    flex: "1 1 auto",
    alignItems: "center",
    minWidth: 0,
  },
  pageTitle: {
    marginLeft: theme.spacing(2),
    flex: "1 1 auto",
    minWidth: 0,
    "& .MuiTypography-root": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
}));

const ToolbarLayout = () => {
  const routes = useSelector(state => state.routes);
  const user = useSelector(getUser);
  const signedInOrgUnit = useSelector(getSignedInOrgUnit);

  // react-router-dom 5.2.0 updated that history will not be triggered as route changes,
  // hence useLocation should be used in this case to track the currentRoute
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const { backURL } = appContext;
  const theme = useTheme();
  const showFullLogo = useMediaQuery(theme.breakpoints.up("md"));
  const showLogo = useMediaQuery(theme.breakpoints.up("sm"));
  const parent = map(routes);
  const children = flattenDeep(filter(map(parent, x => x.routes), route => route !== undefined));
  const allRoutes = concat(parent, children);
  const currentRoute = find(allRoutes, route => matchPath(location.pathname,
    {
      path: route.path,
      strict: true,
      exact: true,
    }));
  const backUrl = backURL || get(currentRoute, "backUrl", false);
  const pageTitle = get(currentRoute, "title", null);
  const isIndexPage = get(currentRoute, "isIndexPage", false);

  let goBack = () => history.goBack();

  let ENV_MESSAGE = process.env.REACT_APP_SYSTEM_BANNER_MESSAGE;
  ENV_MESSAGE = ENV_MESSAGE && ENV_MESSAGE !== "" ? ENV_MESSAGE : null;
  const showMessage = ENV_MESSAGE || process.env.NODE_ENV === "development";

  if (backUrl) {
    goBack = () => history.push(backUrl);
  }

  const displayThemeSelector = find(user.userRoles, x => x.roleName === "Developer" && x.orgUnitId === signedInOrgUnit.id) !== undefined;

  return (
    <AppBar elevation={0} position="relative">
      <Toolbar disableGutters>
        {showMessage && (
          <div className={classes.bannerContainer}>
            <Chip
              classes={{ root: classes.banner, icon: classes.bannerIcon }}
              size="small"
              icon={<Icon fontSize="small" color="inherit">error</Icon>}
              label={ENV_MESSAGE || "NON-PRODUCTION VERSION"}
            />
          </div>
        )}
        <div className={classes.content}>
          <div id="side-bar-toggle" />
          {!isIndexPage && (
            <Slide direction="right" in={!isIndexPage} mountOnEnter unmountOnExit>
              <IconButton onClick={goBack} color="inherit" className="ml-8" edge="end">
                <Icon>arrow_back</Icon>
              </IconButton>
            </Slide>
          )}
          {showLogo && (
            <div className="ml-16 flex h-64">
              <Logo top={18} width={128} showText={showFullLogo} />
            </div>
          )}
          <div className={clsx(!showFullLogo && showLogo && "-ml-40", classes.pageTitle)}>
            {pageTitle
              ? <Typography variant="h6" color="inherit">{pageTitle}</Typography>
              : <div id="primary-header" />}
          </div>
        </div>
        <div className="flex">
          <Hidden smDown>
            <div className="h-64 flex items-center">
              <SiteLogo gutter={8} variant="header" classes={{ root: classes.siteLogo }} />
            </div>
          </Hidden>
          <div className={classes.separator} />
          <SiteSelector />
          {displayThemeSelector && (
            <>
              <div className={classes.separator} />
              <ThemeSelector />
            </>
          )}
          <div className={classes.separator} />
          <UserMenu />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default ToolbarLayout;
