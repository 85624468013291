import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";

import { isCurrentLoading, getCurrentOrgUnit } from "app/main/orgUnits/reducers/orgUnit.reducers";
import { fetchOrgUnitById } from "app/main/orgUnits/actions/orgUnit.actions";
import PrimaryHeaderPortal from "portals/primaryHeaderPortal";
import { getOrgUnitSettings } from "app/auth/store/reducers/system-configuration";

const OrgUnitPrimaryHeader = ({
  orgUnitId,
}) => {
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(false);
  const currentOrgUnit = useSelector(getCurrentOrgUnit);
  const isCurrentOrgUnitLoading = useSelector(isCurrentLoading);
  const orgUnitsSettings = useSelector(state => getOrgUnitSettings(state, currentOrgUnit?.orgUnitType));

  useEffect(() => {
    if (orgUnitId && !isCurrentOrgUnitLoading) {
      dispatch(fetchOrgUnitById(orgUnitId)).then(() => setInitialLoading(false));
    } else {
      setInitialLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitId]);

  if (initialLoading || !orgUnitsSettings || !currentOrgUnit) return null;

  return (
    <PrimaryHeaderPortal>
      <Typography variant="h6">{`${orgUnitsSettings?.nameSingle} - ${currentOrgUnit?.name}`}</Typography>
    </PrimaryHeaderPortal>
  );
};

export default OrgUnitPrimaryHeader;
