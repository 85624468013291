import React from "react";

import { ContextItem, ContextNoteItem } from "components/items/default-item";
import { AssignedUserIcon, InterventionIcon, AccountCheckIcon } from "helpers/icon-finder";
import normaliseDateTime from "app/main/patients/helpers/normalise-date-time";
import PatientContextListItem, { ItemContent } from "app/main/patients/components/patient-context-list-item";
import { DateItem, ResolvedText, PriorityStatus } from "app/main/patients/components/patient-components";

const InterventionListItem = ({
  item: {
    description,
    note,
    recordedBy,
    resolvedBy,
    priority,
    recordedDateTimeUtc,
    resolvedDateTimeUtc,
  },
  ...other
}) => {
  const recordedDate = normaliseDateTime(recordedDateTimeUtc);
  const resolvedDate = normaliseDateTime(resolvedDateTimeUtc);

  return (
    <PatientContextListItem
      title={description}
      {...other}
      renderItemContent={() => (
        <ItemContent
          title={description}
          icon={<InterventionIcon />}
          status={<PriorityStatus priority={priority} />}
          content={(resolvedDate
            ? (
              <>
                <ResolvedText />
                <DateItem date={resolvedDate} name={resolvedBy} />
              </>
            ) : <DateItem date={recordedDate} name={recordedBy} />

          )}
        />
      )}
      renderPreviewContent={() => (
        <>
          <PriorityStatus priority={priority} />
          {resolvedDateTimeUtc && <ContextItem icon={<AccountCheckIcon />} content={<ResolvedText />} />}
          <ContextItem icon="date_range" content={resolvedDateTimeUtc ? resolvedDate.dateTime : recordedDate.dateTime} />
          <ContextItem icon={<AssignedUserIcon />} content={resolvedDateTimeUtc ? resolvedBy : recordedBy} />
          <ContextNoteItem content={note} />
        </>
      )}
    />
  );
};

export default InterventionListItem;
