import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, find } from "lodash";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import qs from "qs";

import { fetchReferral, clearReferralSourceDocumentFiles, resetCurrentReferral } from "app/main/referrals/actions/referrals.actions";
import { getReferralsForWorklist } from "app/main/referrals/reducers/referrals.reducers";
import { isReferralWorklistLoading, getErrorMessage, getTotalNumberOfReferral, getCurrentPageOfReferral, getSearchParameters } from "app/main/referralWorklist/reducers/referralWorklist.reducers";
import ChatPanel from "components/items/chat-panel/index";
import DefaultListItem from "components/items/default-list-item";
import ReferralStatusBar from "app/main/referrals/components/referral-status-bar";
import DefaultButton from "components/items/default-button";
import formatDate from "helpers/format-date";
import InfiniteLoadingList from "components/items/infinite-Loading-list";
import { setMobileContentSidebarStatus } from "app/store/actions/sidebars.actions";
import { openDialog } from "app/store/actions/dialog.actions";
import withPermissions from "permissions/withPermissions";
import ReferralAdvancedFilter from "app/main/referralWorklist/components/referral-advanced-filter";
import { searchReferralResults, exportReferralResults } from "app/main/referralWorklist/actions/referralWorklist.actions";
import { getReferralWorkflowStateSettings } from "app/auth/store/reducers/system-configuration";
import { getUser } from "app/auth/store/reducers/user.reducer";
import EmptyState from "components/items/empty-state";
import { isMobileContentSidebarOpen } from "app/store/reducers/sidebars.reducer";
import { blue } from "@material-ui/core/colors";
import CreateReferralForm from "app/main/referrals/components/create-referral-form";
import ReferralPageContent from "app/main/referrals/components/referral-page-content";
import PatientReferralViewSelector from "app/main/referrals/components/patient-referral-view-selector";
import { ExcelExportIcon, ReferralWorklistIcon } from "helpers/icon-finder";
import useReferralSearch from "../hooks/useReferralSearch";

const hidePatientView = process.env.REACT_APP_HIDE_REFERRALS_PATIENT_VIEW === "true"; // temp, TODO replace with permission

const ReferralWorklistPage = ({
  forceLoad,
  hasPermissionReferralGlobalAccess,
  hasPermissionReferralsCreate,
  hasPermissionReferralsExport,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sidebarIsOpen = useSelector(isMobileContentSidebarOpen);
  const referralWorkflowSettings = useSelector(getReferralWorkflowStateSettings);
  const referrals = useSelector(getReferralsForWorklist);
  const loading = useSelector(isReferralWorklistLoading);
  const error = useSelector(getErrorMessage);
  const total = useSelector(getTotalNumberOfReferral);
  const pageNumber = useSelector(getCurrentPageOfReferral);
  const params = useSelector(getSearchParameters);
  const currentUser = useSelector(getUser);

  const { searchParams, onSearch, onExport, ...searchProps } = useReferralSearch({ params, searchAPI: searchReferralResults, exportAPI: exportReferralResults });

  useEffect(() => {
    if (forceLoad) {
      dispatch(resetCurrentReferral());
    }
    const presetURL = history.location.search ?? null;
    if (presetURL) {
      const queryString = qs.parse(presetURL, { ignoreQueryPrefix: true });
      const { referralId } = queryString;
      // make sure the referralId is not null
      if (referralId !== "null") {
        dispatch(fetchReferral(referralId));
      } else {
        history.push({ search: "" });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickItem = selectedItemId => {
    if (sidebarIsOpen) {
      dispatch(setMobileContentSidebarStatus(false));
    }
    dispatch(clearReferralSourceDocumentFiles());
    dispatch(fetchReferral(selectedItemId)).then(
      () => history.push(`/referralWorklist?referralId=${selectedItemId}`)
    );
  };

  const onAddReferral = () => dispatch(openDialog({ maxWidth: "md", children: <CreateReferralForm /> }));

  const getWorkflowStateSettings = stateName => find(referralWorkflowSettings, x => x.workflowState === stateName) || { icon: "lens", color: blue[600], label: "" };

  const renderItem = (item, selectedItem, resetCurrentTab) => {
    const { patient } = item;
    const referralStatusColor = getWorkflowStateSettings(item.referralStatus).color;
    const unread = item.hasUnreadAddendum;
    const isActive = !isEmpty(selectedItem) && selectedItem.id === item.id;

    return (
      <DefaultListItem
        highlight={unread}
        color={referralStatusColor}
        id={patient?.patientId}
        onClick={isActive ? null : () => { onClickItem(item.id); resetCurrentTab(); }}
        active={isActive}
        content={(
          <>
            <div className="flex-row-container justify-between">
              <Typography variant="body1" className={unread ? "font-bold" : ""}>
                {patient?.displayNameShort}
              </Typography>
              <Typography variant="caption">{formatDate(item.referralDateUtc)}</Typography>
            </div>
            <div className="flex-row-container justify-between mt-16">
              <ReferralStatusBar referral={item} referralStatus={item.referralStatus} />
              <Typography variant="caption">{item.referrerOrganisationName}</Typography>
            </div>
          </>
        )}
      />
    );
  };

  const renderSidebarContent = (selectedItem, resetCurrentTab) => {
    if (!hasPermissionReferralGlobalAccess && currentUser.specialtyProfileId === null) {
      return <div className="p-16"><EmptyState title="No Specialty Configured" subtitle="Please contact a system administrator" /></div>;
    }

    return (
      <InfiniteLoadingList
        initialLoad
        data={referrals}
        renderItem={item => renderItem(item, selectedItem, resetCurrentTab)}
        loading={loading}
        error={error}
        isEmptyStatus={isEmpty(referrals)}
        total={total}
        pageStart={pageNumber}
        loadFunction={page => {
          const number = page === undefined ? 1 : pageNumber + 1;
          return onSearch(params, number, null, forceLoad);
        }}
      />
    );
  };

  const extraActions = [
    hasPermissionReferralsExport && { label: "Export", icon: <ExcelExportIcon />, onClick: onExport },
  ];

  return (
    <ReferralPageContent
      renderContent={({ header, content, loading: loadingCurrent, currentReferral, resetTab }) => (
        <ChatPanel
          loading={loadingCurrent}
          empty={!currentReferral}
          renderSidebarHeader={() => (
            <>
              {!hidePatientView && (
                <div className="mb-8">
                  <PatientReferralViewSelector />
                </div>
              )}
              <ReferralAdvancedFilter
                onSearch={onSearch}
                searchParams={searchParams}
                currentUser={currentUser}
                renderExtraActions={total > 0 && extraActions}
                renderFilterInfo={(
                  <div className="flex justify-between items-center">
                    <Typography variant="caption">Total Results: {total}</Typography>
                    {hasPermissionReferralsCreate && <DefaultButton label="Add referral" onClick={onAddReferral} />}
                  </div>
                )}
                {...searchProps}
              />
            </>
          )}
          renderSidebarContent={() => renderSidebarContent(currentReferral, resetTab)}
          renderContent={({ width }) => content({ width })}
          renderHeader={() => header()}
          emptyIcon={<ReferralWorklistIcon />}
          emptyTitle="Select a referral to view details"
        />
      )}
    />
  );
};

export default withPermissions(
  "ReferralsExport",
  "ReferralsCreate",
  "ReferralGlobalAccess",
)(ReferralWorklistPage);
