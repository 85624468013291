import React from "react";
import { map } from "lodash";
import { Field } from "formik";
import HeartPulseIcon from "mdi-react/HeartPulseIcon";
import ChipInput from "components/inputs/chip-input";
import { AutoComplete } from "components/inputs/auto-complete";

import AdvancedFilter, { FilterTags, FilterForm, FilterSection } from "components/items/advanced-filter";
import getCandidateDiseaseStateDescription from "app/main/candidates/helpers/get-candidate-disease-state-description";
import { defaultSearchParams } from "app/main/candidateWorklist/reducers/candidateWorklist.reducers";

const diseaseStateOptions = [
  {
    value: "HF",
    label: "Heart Failure",
  },
  {
    value: "IHD",
    label: "Ischemic Heart Disease",
  },
  {
    value: "DM",
    label: "Diabetes Mellitus",
  },
  {
    value: "COPD",
    label: "Chronic Obstructive Pulmonary Disease",
  },
];

class CandidateAdvancedFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTags: null,
    };
  }

  componentDidMount() {
    const candidateFilter = this.buildCandidateFilterObjects(this.props.initialValues.candidateFilter.diseaseState, this.props.initialValues.candidateFilter.postcode);
    this.setSearchTags(candidateFilter.diseaseState, candidateFilter.postcode);
  }

  setSearchTags = (diseaseState, postcode) => {
    const searchTags = [
      diseaseState !== null && diseaseState.length && `Disease state: ${map(diseaseState, state => ` ${state.label}`)}`,
      postcode !== null && postcode.length && `${map(postcode, code => ` ${code.label}`)}`,
    ];
    this.setState({ searchTags });
  }

  buildCandidateFilterObjects = (diseaseState, postcode) => ({
    postcode: map(postcode, code => (
      {
        label: code,
        value: code,
      })),
    diseaseState: map(diseaseState, disease => (
      {
        label: getCandidateDiseaseStateDescription(disease) || disease,
        value: disease,
      })),
  })

  handleSubmit = ({ candidateFilter }) => {
    const { searchParams } = this.props;
    const { diseaseState, postcode } = candidateFilter;

    let postcodeValue = postcode;
    if (postcodeValue) {
      postcodeValue = map(postcode, code => code.value);
    }

    let diseaseStateValue = diseaseState;
    if (diseaseStateValue) {
      diseaseStateValue = map(diseaseState, state => state.value);
    }

    const newCandidateFilter = {
      diseaseState: diseaseStateValue,
      postcode: postcodeValue,
    };

    const updatedSearchParams = { ...searchParams, ...newCandidateFilter };
    this.props.onSearch(updatedSearchParams, null, null, true);
    this.setSearchTags(diseaseState, postcode);
  }

  handleReset = () => {
    this.props.onSearch(defaultSearchParams, null, null, true);
    this.setState({ searchTags: null });
  }

  onSearchText = searchTerm => {
    this.props.onSearch({ ...this.props.searchParams, name: searchTerm }, null, null, true);
  }

  render() {
    const { total, initialValues, renderFilterInfo, searchParams } = this.props;
    const { searchTags } = this.state;

    const newInitialValues = {
      candidateFilter: this.buildCandidateFilterObjects(initialValues.candidateFilter.diseaseState, initialValues.candidateFilter.postcode),
    };

    return (
      <AdvancedFilter
        renderFilterInfo={renderFilterInfo}
        totalResults={total}
        onSearch={searchText => this.onSearchText(searchText)}
        searchValue={searchParams?.name}
        renderFilterTags={(
          <FilterTags tags={searchTags} />
        )}
        content={onClose => (
          <FilterForm
            initialValues={newInitialValues}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            onReset={this.handleReset}
            filters={() => (
              <div className="advanced-filter-sections">
                <FilterSection withDivider>
                  <Field
                    name="candidateFilter.postcode"
                    label="Postcode"
                    icon="map-marker"
                    component={ChipInput}
                    multiline
                    textarea
                  />
                </FilterSection>
                <FilterSection withDivider>
                  <Field
                    name="candidateFilter.diseaseState"
                    label="Disease State"
                    icon={<HeartPulseIcon />}
                    component={AutoComplete}
                    options={diseaseStateOptions}
                    isMulti
                    createLabel="Search: "
                    multiline
                    textarea
                  />
                </FilterSection>
              </div>
            )}
          />
        )}
      />
    );
  }
}

export default CandidateAdvancedFilter;
