import React from "react";
import { map } from "lodash";
import AllergyListItem from "app/main/patients/allergies/components/allergy-list-item";
import StateContainer from "components/layouts/default-state-container";

const AllergyList = ({
  allergies,
  onEditAllergy,
  onRemoveAllergy,
  isEmpty,
  isError,
  isLoading,
}) => (
  <StateContainer loading={isLoading} error={isError} empty={isEmpty} emptyTitle="No Recorded Allergies">
    {map(allergies, (allergy, index) => (
      <AllergyListItem
        item={allergy}
        key={allergy.id}
        isFirst={index === 0}
        onEdit={onEditAllergy ? () => onEditAllergy(allergy.id) : null}
        onRemove={onRemoveAllergy ? () => onRemoveAllergy(allergy.id) : null}
      />
    ))}
  </StateContainer>
);

export default AllergyList;
