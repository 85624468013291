import React from "react";
import { connect } from "react-redux";

import DefaultPanelLayout from "components/items/default-panel-layout";
import { getCurrentReferralSortedActivities } from "app/main/referrals/reducers/referrals.reducers";

import ReferralActivityLog from "app/main/referrals/components/referral-activity-log";

function ReferralActivityPanel(props) {
  const { referral, activities, ...other } = props;
  return (
    <DefaultPanelLayout
      title="Correspondence, Notes and Activity"
      icon="chat"
      {...other}
    >
      <ReferralActivityLog activities={activities} referralId={referral.id} referral={referral} />
    </DefaultPanelLayout>
  );
}

const mapStateToProps = state => ({
  activities: getCurrentReferralSortedActivities(state),
});

export default connect(
  mapStateToProps,
  null,
)(ReferralActivityPanel);
