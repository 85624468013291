import React, { useState, useEffect } from "react";
import { isEmpty, some, every } from "lodash";
import * as Yup from "yup";
import moment from "moment";

import LoadingState from "components/items/loading-state";
import "utils/yup-validation";
import useReferralFieldSettings from "app/main/referrals/hooks/useReferralFieldSettings";
import AddReferralFormComponent from "./form";

const AddReferralForm = props => {
  const { isLoadingSettings, fieldSettings, fieldLabels } = useReferralFieldSettings();

  const [defaultReferralDate, setDefaultReferralDate] = useState(null);
  const requiredMessage = "This field is required";
  const maxLengthMessage = "Exceeds maximum length";

  useEffect(() => {
    // set default referral date when component mounted,
    // prevent re-render
    setDefaultReferralDate(moment());
  }, []);

  if (isLoadingSettings) return <LoadingState />;

  const schema = Yup.object().shape({
    referral: Yup.object().shape({
      referralSourceDocument: Yup.object().shape({
        fileName: Yup.string().nullable(),
      }).default({}).nullable(),
      referralDateUtc: Yup.date()
        .required(requiredMessage)
        .default(defaultReferralDate)
        .nullable(),
      referralPeriod: Yup.string().max(100, maxLengthMessage).nullable(),
      referredToSpecialty: Yup.string()
        .required(requiredMessage)
        .nullable(),
      // config fields
      assignToSubSpecialty: Yup.string()
        .fieldSettingsValidation(fieldLabels.assignToSubSpecialty, fieldSettings?.assignToSubSpecialty)
        .nullable(),
      mbsConsent: Yup.string()
        .nullable()
        .fieldSettingsValidation(fieldLabels.mbsConsent, fieldSettings?.mbsConsent)
        .default(false),
      specialistConsultant: Yup.string()
        .max(50, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.specialistConsultant, fieldSettings?.specialistConsultant)
        .nullable(),
      typeOfReferralCodeId: Yup.string()
        .max(50, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.typeOfReferral, fieldSettings?.typeOfReferral)
        .nullable(),
      reasonForReferralCodeId: Yup.string()
        .max(50, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.reasonForReferralCode, fieldSettings?.reasonForReferralCode)
        .nullable(),
      referrerAdvisedPriorityCodeId: Yup.string()
        .max(50, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.referrerAdvisedPriority, fieldSettings?.referrerAdvisedPriority)
        .nullable(),
      referringLocationCodeId: Yup.string()
        .max(50, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.referringLocation, fieldSettings?.referringLocation)
        .nullable(),
      referringInternalLocationCodeId: Yup.string()
        .max(50, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.referringInternalLocation, fieldSettings?.referringInternalLocation)
        .nullable(),
      referrerFullName: Yup.string()
        .max(300, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.referrerFullName, fieldSettings?.referrerFullName)
        .nullable(),
      referrerOrganisationName: Yup.string()
        .max(300, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.referrerOrganisationName, fieldSettings?.referrerOrganisationName)
        .nullable(),
      reasonNote: Yup.string()
        .max(500, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.reasonNote, fieldSettings?.reasonNote)
        .nullable(),
      presentingComplaintNote: Yup.string()
        .max(500, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.presentingComplaintNote, fieldSettings?.presentingComplaintNote)
        .nullable(),
      relevantPatientHistoryNote: Yup.string()
        .max(500, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.relevantPatientHistoryNote, fieldSettings?.relevantPatientHistoryNote)
        .nullable(),
      interpreterRequired: Yup.bool()
        .nullable()
        .fieldSettingsValidation(fieldLabels.interpreterRequired, fieldSettings?.interpreterRequired)
        .default(false),
      preferredLanguage: Yup.string()
        .when("interpreterRequired", {
          is: true,
          then: Yup.string().trim().required(requiredMessage),
          otherwise: Yup.string().notRequired(),
        })
        .max(100, maxLengthMessage)
        .nullable(),
      nok: Yup.object().shape({
        givenName: Yup.string().max(60, maxLengthMessage).nullable(),
        familyName: Yup.string().max(60, maxLengthMessage).nullable(),
        contact: Yup.string().max(60, maxLengthMessage).nullable(),
        isValid: Yup.string().when(["givenName", "familyName", "contact"], {
          is: (givenName, familyName, contact) => {
            const arr = [givenName, familyName, contact];
            if (!every(arr, x => isEmpty(x)) && some(arr, x => isEmpty(x))) {
              return true;
            }
            return false;
          },
          then: Yup.string().trim().required("Please complete next of kin details"),
          otherwise: Yup.string(),
        }).nullable(),
      }).default({})
        .fieldSettingsValidation(fieldLabels.nok, fieldSettings?.nok)
        .nullable(),
    }),
  });

  return (
    <AddReferralFormComponent
      schema={schema}
      fieldSettings={fieldSettings}
      fieldLabels={fieldLabels}
      {...props}
    />
  );
};

export default AddReferralForm;
