import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdvancedFilter, { FilterTags, FilterSort } from "components/items/advanced-filter";
import { makeStyles } from "@material-ui/core/styles";
import DefaultButton from "components/items/default-button";
import ReferralAdvancedFilterForm from "app/main/referralWorklist/components/referral-advanced-filter-form";
import { getSearchTags } from "app/main/referralWorklist/reducers/referralWorklist.reducers";
import translateReferralSearchParams from "app/main/referralWorklist/helpers/translate-search-params";
import { resetReferralWorklistFilter } from "app/main/referralWorklist/actions/referralWorklist.actions";

const useStyles = makeStyles(theme => ({
  searchInput: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const PatientReferralListAdvancedFilter = ({
  patientId,
  total,
  pageNumber,
  pageSize,
  onSearch,
  searchParams,
  initialValues,
  onPageChange,
  ...other
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tags = useSelector(getSearchTags);
  const [sortOrderDesc, setSortOrderDesc] = useState(!searchParams.showOldestFirst);
  const [isSearching, setIsSearching] = useState(false);

  React.useEffect(() => {
    setSortOrderDesc(!searchParams.showOldestFirst);
  }, [searchParams.showOldestFirst]);

  const handleSubmit = ({ referralFilters }) => {
    const newReferralFilters = translateReferralSearchParams(referralFilters);
    const newParams = { ...searchParams, ...newReferralFilters, patientId };
    onSearch(newParams, null, null, true);
  };

  const handleReset = () => dispatch(resetReferralWorklistFilter()).then(res => {
    const params = translateReferralSearchParams(res.payload.searchParams);
    onSearch({ ...searchParams, patientId }, null, null, true);
    setSortOrderDesc(!params.showOldestFirst);
  });

  const handleSortOrderChange = () => {
    setSortOrderDesc(!sortOrderDesc);
    setIsSearching(true);
    const params = translateReferralSearchParams(searchParams);
    onSearch({ ...params, patientId, showOldestFirst: sortOrderDesc }, null, null, true).then(() => setIsSearching(false));
  };

  return (
    <AdvancedFilter
      withPaperWrapper={false}
      onSearch={text => onSearch({ ...searchParams, patientId, term: text }, null, null, true)}
      searchValue={searchParams?.term ?? ""}
      renderFilterTags={<FilterTags tags={tags} showDivider={false} />}
      searchInputProps={{ classes: { root: "flex-auto px-8", input: classes.searchInput } }}
      variant="accordion"
      renderActions={(
        <FilterSort
          sortType="date"
          sortOrder={sortOrderDesc ? "desc" : "asc"}
          onClick={handleSortOrderChange}
          sortDescendingLabel="Referral Date - Newest First"
          sortAscending="Referral Date - Oldest First"
          isLoading={isSearching}
        />
      )}
      content={onClose => (
        <ReferralAdvancedFilterForm
          showHeader={false}
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          handleReset={handleReset}
          onClose={onClose}
          renderActions={props => (
            <div className="flex-row-container with-gutter">
              <DefaultButton label="Clear" className="w-320" onClick={() => { props.onReset(); props.onClose(); }} variant="outlined" size="medium" />
              <DefaultButton label="Search" className="w-320" onClick={() => { props.handleSubmit(); props.onClose(); }} size="medium" />
            </div>
          )}
          {...other}
        />
      )}
    />
  );
};

export default PatientReferralListAdvancedFilter;
