import React from "react";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";

import DialogPopup from "components/items/dialog-popup";
import DefaultButton from "components/items/default-button";
import { closeDialog } from "app/store/actions/dialog.actions";
import { undoReferralAction } from "app/main/referrals/actions/referrals.actions";
import { find } from "lodash";
import { getReferralWorkflowStateSettings } from "app/auth/store/reducers/system-configuration";
import { rejectedWorkflowState, acceptedWorkflowState, requestedWorkflowState } from "../helpers/workflow-state-names";

class UndoReferralDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getWorkflowStateLabel = stateName => find(this.props.referralWorkflowSettings, x => x.workflowState === stateName)?.label || stateName;

  getPreviousStatus = currentStatus => {
    switch (currentStatus) {
      case "Accepted":
      case "AwaitingInformation":
      case "Rejected":
        return this.getWorkflowStateLabel(requestedWorkflowState);
      case "Completed":
        return this.getWorkflowStateLabel(acceptedWorkflowState);
      case "Archived":
        return this.getWorkflowStateLabel(rejectedWorkflowState);
      default:
        return null;
    }
  };

  handleSubmit = ({ referral }) => {
    const undoReferral = {
      referralId: this.props.referral.id,
      note: referral ? referral.note : null,
    };
    this.props.undoReferralAction(undoReferral).then(payload => {
      if (payload.error !== true) {
        this.props.onSucceed();
      }
    });
  };

  render() {
    return (
      <DialogPopup
        title="Undo Referral Status"
        content={<Typography>{`Set this referral back to ${this.getPreviousStatus(this.props.referral.referralStatus)}?`}</Typography>}
        renderActions={(
          <>
            <DefaultButton
              onClick={() => { this.handleSubmit(this.props.referral); }}
              label="Confirm"
              size="medium"
            />
            <DefaultButton color="default" onClick={() => this.props.closeDialog()} label="Cancel" className="ml-16" size="medium" />
          </>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  referralWorkflowSettings: getReferralWorkflowStateSettings(state),
});

export default connect(mapStateToProps, { undoReferralAction, closeDialog, getReferralWorkflowStateSettings })(UndoReferralDialog);
