import React from "react";
import { Avatar, Button, Icon, Popover, Typography, ButtonBase, Hidden, Divider } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { startCase, filter, map, join } from "lodash";
import { makeStyles, fade } from "@material-ui/core/styles";

import { logoutUser, recordUserLogout } from "app/auth/store/actions/user.actions";
import { showMessage } from "app/store/actions/message.actions";
import { getUser, isCurrentUserLoading, getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import SiteMenu, { Item } from "components/items/site-menu";
import LoadingState from "components/items/loading-state";
import { getApiVersion, getApiDescription, getExternalHelpPage, getDisplayNameForExternalSourceDocumentWorklist } from "app/auth/store/reducers/system-configuration";
import withPermissions from "permissions/withPermissions";
import PopupMenu from "components/items/popup-menu";
import { getPatientLabel } from "utils/get-environment-variables";
import { ReferralWorklistIcon, PatientWorklistIcon, DocumentWorklistIcon, IPAWorklistIcon, CandidateWorklistIcon, AdminSettingIcon, UserIcon } from "helpers/icon-finder";
import HCSLogo from "components/items/hcs-logo";
import SiteLogo from "components/items/site-logo";
import downloadFromApi from "utils/download-from-api";
import DownloadIcon from "mdi-react/DownloadIcon";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import UserProfileForm from "app/main/users/components/user-profile-form";

const patientLabel = getPatientLabel();

const useStyles = makeStyles(theme => ({
  avatar: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: fade(theme.palette.secondary.light, 0.8),
  },
}));

const UserMenu = ({
  hasPermissionReferralsView,
  hasPermissionPatientsWorklistView,
  hasPermissionCandidatesView,
  hasPermissionIPAWorklistView,
  hasPermissionExternalSourceDocumentView,
  hasPermissionAdminView,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector(getUser);
  const loading = useSelector(isCurrentUserLoading);
  const version = useSelector(getApiVersion);
  const apiDescription = useSelector(getApiDescription);
  const externalHelpPage = useSelector(getExternalHelpPage);
  const extSourceDocumentTitle = useSelector(getDisplayNameForExternalSourceDocumentWorklist);
  const signedInOrgUnit = useSelector(getSignedInOrgUnit);

  if (loading) {
    return (
      <Button className="h-64" color="inherit">
        <Avatar classes={classes.avatar}>
          <LoadingState />
        </Avatar>
      </Button>
    );
  }

  const menuItems = [
    hasPermissionIPAWorklistView && {
      icon: <IPAWorklistIcon />,
      label: "IPA Worklist",
      path: "/applications",
      state: { forceLoad: true },
    },
    hasPermissionPatientsWorklistView && {
      icon: <PatientWorklistIcon />,
      label: `${patientLabel.single} Worklist`,
      path: "/worklist",
      state: { forceLoad: true },
    },
    hasPermissionCandidatesView && {
      icon: <CandidateWorklistIcon />,
      label: "Candidate Worklist",
      path: "/candidateWorklist",
      state: { forceLoad: true },
    },
    hasPermissionReferralsView && {
      icon: <ReferralWorklistIcon />,
      label: "Referral Worklist",
      path: "/referralWorklist",
      state: { forceLoad: true },
    },
    hasPermissionExternalSourceDocumentView && {
      icon: <DocumentWorklistIcon />,
      label: extSourceDocumentTitle,
      path: "/externalDocumentWorklist",
      state: { forceLoad: true },
    },
    // TODO: temporary put hasPermissionIPAWorklistView
    // to prevent this menu show from other projects
    signedInOrgUnit && hasPermissionIPAWorklistView && {
      icon: <DownloadIcon />,
      label: "IPA Blank Form",
      withDivider: true,
      onClick: () => {
        dispatch(() => downloadFromApi(`api/orgUnits/${signedInOrgUnit.id}/downloadIPABlankForm`));
      },
    },
    {
      icon: <UserIcon />,
      label: "User Profile",
      withDivider: true,
      onClick: () => dispatch(openDialog({
        children: (
          <UserProfileForm
            title="User Profile"
            onSucceed={() => {
              dispatch(showMessage({ variant: "success", message: "Successfully updated user profile" }));
              dispatch(closeDialog());
            }}
          />
        ),
      })),
    },
    hasPermissionAdminView && {
      icon: <AdminSettingIcon />,
      label: "Admin",
      path: "/admin",
      withDivider: true,
    },
  ];

  let userName = "";
  if (user.firstName) {
    userName += user.firstName[0];
  }

  if (user.lastName) {
    userName += user.lastName[0];
  }

  const currentUserRoles = filter(user.userRoles, x => x.orgUnitId === signedInOrgUnit.id);

  return (
    <PopupMenu
      anchorRef="userMenu"
      trigger={popupState => (
        <ButtonBase id="userMenu" className="h-64 px-8" {...popupState} color="inherit">
          <Avatar className={classes.avatar}>{userName}</Avatar>
          <div className="hidden md:flex flex-col ml-12 items-start">
            <Typography color="inherit" variant="body2">
              {user.name}
            </Typography>
            <Typography color="inherit" variant="caption">
              {join(map(currentUserRoles, userRole => userRole?.roleDescription ?? startCase(userRole?.roleName)), ", ")}
            </Typography>
            {user.specialtyProfile
              && (
              <Typography color="inherit" variant="caption">
                {user.specialtyProfile.name}
              </Typography>
              )}
          </div>
          <Icon className="text-2xl ml-12 hidden sm:flex" variant="action">keyboard_arrow_down</Icon>
        </ButtonBase>
      )}
      content={({ close, ...other }) => (
        <Popover
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          {...other}
        >
          <>
            <Hidden mdUp>
              <SiteLogo classes={{ root: "h-56" }} variant="menu" />
              <Divider />
            </Hidden>
            <SiteMenu menuItems={menuItems} onClose={() => close()} />
            {/* Help Page */}
            {externalHelpPage && <Item onClick={() => { window.open(externalHelpPage, "_blank"); close(); }} icon="help" label="Help" />}
            <Divider />
            {/* Logout */}
            <Item
              icon="exit_to_app"
              label="Logout"
              onClick={() => {
                dispatch(recordUserLogout()).then(response => {
                  if (!response.error) {
                    dispatch(logoutUser());
                    close();
                  }
                });
              }}
            />
            {/* App version */}
            <div className="px-16 py-4 flex justify-end">
              <HCSLogo variant="color" showText={false} width={96} top={8} left={128} />
              <div className="flex items-center">
                <Typography variant="body2" color="textSecondary" className="font-bold mr-4">{apiDescription}</Typography>
                <Typography variant="body2">{version}</Typography>
              </div>
            </div>
          </>
        </Popover>
      )}
    />
  );
};

export default withPermissions(
  "ReferralsView",
  "PatientsWorklistView",
  "CandidatesView",
  "IPAWorklistView",
  "ExternalSourceDocumentView",
  "AdminView",
)(UserMenu);
