import React from "react";
import { Tooltip } from "@material-ui/core";
import { getPatientLabel } from "utils/get-environment-variables";
import DefaultButton from "components/items/default-button";

const patientLabel = getPatientLabel();

const ViewPatientRecordButton = ({
  onClick,
}) => {
  const label = `${patientLabel?.single ?? "Medical"} Record`;
  return (
    <Tooltip title={`View ${label}`}>
      <div className="flex">
        <DefaultButton variant="outlined" label={label} onClick={onClick} />
      </div>
    </Tooltip>
  );
};

export default ViewPatientRecordButton;
