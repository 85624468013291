export function encounterTypeDisplay(encounterType) {
  return encounterType.replace(/([A-Z])/g, " $1").trim();
}

export function encounterSubjectDisplay(encounterType, subject) {
  if (encounterType === "Other") {
    return subject;
  }
  return encounterTypeDisplay(encounterType);
}
