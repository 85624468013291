import React from "react";
import { Field } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { isEmpty } from "lodash";

import Form from "components/form";
import { UserSelector } from "components/inputs";
import SelectInput from "components/inputs/select-input";
import { onSaveOrUpdateEndorsementRequest } from "app/main/applications/actions/applications.actions";
import useEndorsementTypes from "hooks/use-endorsement-types";

const userLabel = "User";
const type = "Type";

const schema = Yup.object().shape({
  endorsement: Yup.object().shape({
    endorsedByType: Yup.string()
      .required(`${type} is required`)
      .nullable(),
    endorsedUserId: Yup.string()
      .nullable(),
  }),
});

const RequestEndorsementForm = props => {
  const { applicationId, orgUnitId, ...other } = props;
  const dispatch = useDispatch();
  const { endorsementTypeOptions } = useEndorsementTypes();

  let { endorsement } = schema.cast();

  if (!isEmpty(props.endorsement)) {
    endorsement = {
      ...props.endorsement,
      endorsedUserId:
        props.endorsement.endorsedUserId
          ? {
            value: props.endorsement.endorsedUserId,
            label: props.endorsement.endorsedBy,
          }
          : null,
    };
  }

  const handleSubmit = (value, { setErrors }) => {
    const { id, endorsedUserId: endorsedBy, endorsedByType } = value.endorsement;

    const newEndorsement = {
      id,
      endorsedByType,
      endorsedBy: endorsedBy?.label,
      endorsedUserId: endorsedBy?.value,
    };

    dispatch(onSaveOrUpdateEndorsementRequest(applicationId, orgUnitId, newEndorsement)).then(response => {
      if (response.error !== true) {
        props.onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  return (
    <Form
      initialValues={{ endorsement }}
      onSubmit={handleSubmit}
      contentProps={other}
      validationSchema={schema}
      content={({ values }) => (
        <>
          <Field
            name="endorsement.endorsedByType"
            component={SelectInput}
            label={type}
            disabled={values.endorsement.automaticallyAdded}
            options={endorsementTypeOptions}
            required
            onChange={(value, change) => {
              change("endorsement.endorsedUserId", null);
            }}
          />
          <Field
            name="endorsement.endorsedUserId"
            component={UserSelector}
            label={userLabel}
            includeAllUsers
            loadOptions={values.endorsement.endorsedByType != null}
            filteredEndorsedByType={values.endorsement.endorsedByType}
            orgUnitId={orgUnitId}
            icon="person"
          />
        </>
      )}
    />
  );
};

export default RequestEndorsementForm;
