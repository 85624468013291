import { isEqual, toNumber } from "lodash";

export const formatCostOutput = cost => {
  let formattedCost = cost;
  if (typeof formattedCost === "string") {
    formattedCost = toNumber(cost);
  }

  if (formattedCost == null || Number.isNaN(formattedCost)) {
    return "Unknown";
  }

  // toFixed -> decimal points
  // toLocaleString -> comma's in thousands
  // parse float -> allows use of toLocaleString
  let formattedOutput = parseFloat(formattedCost.toFixed(2)).toLocaleString();

  // Check that after the decimal point, there is two numbers.
  // If it has just has one, as trailing zeroes are trimmed by parsing to a float
  if (!isEqual(formattedOutput, "0")) {
    const splitCheck = formattedOutput.split(".");
    if (splitCheck.length > 1 && splitCheck[1].length < 2) {
      formattedOutput += "0";
    }
  }

  // If it came in as a round number, add the trailing zeroes
  if (!formattedOutput.includes(".")) {
    formattedOutput += ".00";
  }

  return `$${formattedOutput}`;
};
