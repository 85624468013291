import moment from "moment-timezone";
import { isEmpty, map, find, includes } from "lodash";

import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import downloadFromApi from "utils/download-from-api";
import { getReferralWorkflowStateSettings, getAllTriageCategorySettings, getReferralWorklistSettings } from "app/auth/store/reducers/system-configuration";
import { getUser } from "app/auth/store/reducers/user.reducer";

import { ActionTypes, bailOutOfLoadingReferrals, shouldShowStatusSearchTags, getDefaultFilterOptionSearchParams } from "../reducers/referralWorklist.reducers";

const getDefaultSpecialtySearchParams = (state, presetFilter) => {
  const currentUser = getUser(state);
  const { specialtyProfileId, roleId, specialtyProfileAvailableRoleIds, userId } = currentUser;

  let defaultParams = {
    assignedToSpecialtyId: specialtyProfileId,
    assignedToRoleId: includes(specialtyProfileAvailableRoleIds, roleId) ? roleId : null,
  };

  if (presetFilter === "myOutstandingTasks") {
    defaultParams = {
      ...defaultParams,
      taskStatus: "OutstandingTasks",
      taskAssignedToUserId: userId,
    };
  }

  if (presetFilter === "myReferrals") {
    defaultParams = {
      ...defaultParams,
      createdByUserId: userId,
    };
  }

  return defaultParams;
};

// only get default sort order when
// 1. first set up referral worklist filters
// 2. user reset the filters
const getDefaultSortOrder = state => {
  const referralWorklistSettings = getReferralWorklistSettings(state);
  const defaultSorOrder = referralWorklistSettings?.worklistSortOrder;

  return {
    showOldestFirst: defaultSorOrder === "asc",
  };
};

export const updateReferralWorkListFilter = (presetFilter, showStatusTags) => (dispatch, getState) => new Promise(resolve => {
  const defaultSpecialtySearchParams = getDefaultSpecialtySearchParams(getState(), presetFilter);

  dispatch({ type: ActionTypes.UPDATE_REFERRAL_WORKLIST_FILTER, payload: { presetFilter, showStatusTags, preSetSearchParams: defaultSpecialtySearchParams } });
  const state = getState();
  resolve(state?.referralWorklist.searchParams);
});

export const setUpReferralWorklistFilter = () => (dispatch, getState) => {
  const settings = getReferralWorkflowStateSettings(getState());
  const defaultSpecialtySearchParams = getDefaultSpecialtySearchParams(getState());
  const defaultSortOrderSearchParam = getDefaultSortOrder(getState());
  // set to default user's specialty and role (if has been set)
  return dispatch({
    type: ActionTypes.SETUP_REFERRAL_WORKLIST_FILTERS,
    payload: {
      settings,
      searchParams: { ...defaultSpecialtySearchParams, ...defaultSortOrderSearchParam },
    },
  });
};

export const resetReferralWorklistFilter = () => (dispatch, getState) => {
  const defaultSearchParams = getDefaultFilterOptionSearchParams(getState());
  const defaultSpecialtySearchParams = getDefaultSpecialtySearchParams(getState());
  const defaultSortOrderSearchParam = getDefaultSortOrder(getState());

  return dispatch({
    type: ActionTypes.RESET_REFERRAL_WORKLIST_FILTER,
    payload: {
      searchParams: {
        ...defaultSearchParams,
        ...defaultSpecialtySearchParams,
        ...defaultSortOrderSearchParam,
      },
    },
  });
};

export const searchReferralResults = (searchParams, pageNumber = 1, pageSize = 20, forceLoad = false) => (dispatch, getState) => {
  const params = formatSearchParams(searchParams);
  const timeZone = moment.tz.guess();
  const url = `/referrals?pageNumber=${pageNumber}&pageSize=${pageSize}&timeZone=${timeZone}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: !forceLoad && bailOutOfLoadingReferrals(getState(), pageNumber),
      types: [
        ActionTypes.SEARCHING_REFERRALS,
        ActionTypes.SEARCHED_REFERRALS,
        ActionTypes.ERROR_SEARCHING_REFERRALS,
      ],
      pageNumber,
    },
  });
};

export const exportReferralResults = (searchParams, pageNumber = 1, pageSize = 20) => async () => {
  const params = formatSearchParams(searchParams);
  const timeZone = moment.tz.guess();
  const url = `api/referrals/export?pageNumber=${pageNumber}&pageSize=${pageSize}&timeZone=${timeZone}${params}`;

  const response = await downloadFromApi(url);
  return response.statusText;
};

export const clearReferralWorkListFilterTags = () => ({
  type: ActionTypes.RESET_REFERRAL_WORKLIST_FILTER_TAGS,
});

export const updateReferralWorkListFilterTags = searchParams => (dispatch, getState) => {
  if (!searchParams) return null;
  const referralWorkflowSettings = getReferralWorkflowStateSettings(getState());
  const triageCategorySettings = getAllTriageCategorySettings(getState());
  const showStatusTags = shouldShowStatusSearchTags(getState());

  const getWorkflowStateLabel = stateName => find(referralWorkflowSettings, x => x.workflowState === stateName)?.label || stateName;
  const getTriageCategoryLabel = category => find(triageCategorySettings, choice => choice.triageCategory === category)?.label;

  const {
    status,
    category,
    startDate,
    endDate,
    unreadAddendum,
    isUrgent,
    allStatus,
    allCategory,
    onlyShowUnassigned,
    assignedTo,
    assignedToSpecialtyId,
    assignedToRoleId,
    assignedToUserId,
    daysSinceLastStatusChange,
    assignedToSubSpecialtyId,
    taskStatus,
    taskAssignedToUserId,
    taskAssignedToUser,
    createdByUserId,
    createdByUser,
  } = searchParams;

  let categoryTags = null;
  let statusTags = null;
  let assignedToSpecialtyLabel = null;
  let assignedToUser = null;
  let assignedToSubSpecialtyLabel = null;

  if (assignedTo) {
    if (assignedToSpecialtyId && assignedToRoleId) {
      assignedToSpecialtyLabel = `${assignedTo.specialtyProfileName} (${assignedTo.roleName})`;
    } else if (assignedToRoleId) {
      assignedToSpecialtyLabel = assignedTo.roleName;
    } else if (assignedToSpecialtyId) {
      assignedToSpecialtyLabel = assignedTo.specialtyProfileName;
    }
    if (assignedToUserId) {
      assignedToUser = assignedTo.userName;
    }
    if (assignedToSubSpecialtyId) {
      assignedToSubSpecialtyLabel = assignedTo.subSpecialtyName;
    }
  }

  if (!isEmpty(status) && !allStatus) {
    statusTags = `${map(status, s => ` ${getWorkflowStateLabel(s)}`)}`;
  }
  if (!isEmpty(category) && !allCategory) {
    categoryTags = `${map(category, c => ` ${getTriageCategoryLabel(c)}`)}`;
  }

  const tags = [
    showStatusTags && statusTags,
    showStatusTags && daysSinceLastStatusChange && `${daysSinceLastStatusChange} Day${daysSinceLastStatusChange > 1 ? "s" : ""} Waiting In Status`,
    categoryTags,
    startDate && endDate && `Searched Date From ${startDate} To ${endDate}`,
    unreadAddendum && "Unread Updates",
    isUrgent && "Marked As Urgent",
    assignedToSpecialtyLabel && `Assigned To ${assignedToSpecialtyLabel}`,
    assignedToSubSpecialtyLabel && `Assigned To ${assignedToSubSpecialtyLabel}`,
    assignedToUserId && `Assigned to ${assignedToUser}`,
    onlyShowUnassigned && "Not Assigned To A Specialty",
    taskStatus === "CompletedTasks" && "Contains Completed Task",
    taskStatus === "OutstandingTasks" && "Contains Outstanding Task",
    taskStatus === "OverdueTasks" && "Contains Overdue Task",
    taskAssignedToUserId && `Task Assigned To ${taskAssignedToUser}`,
    createdByUserId && `Referral Created By ${createdByUser}`,
  ];

  return dispatch({
    type: ActionTypes.UPDATE_REFERRAL_WORKLIST_FILTER_TAGS,
    payload: tags,
  });
};
