import React from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import { registerCandidate } from "app/main/candidates/actions/candidates.actions";
import { UserSelector } from "components/inputs";
import { validateRequired } from "utils/validators";
import Form from "components/form";
import { setRecentSelectedUser } from "app/main/users/actions/users.actions";
import { getUser } from "app/auth/store/reducers/user.reducer";
import { getEnrolmentTypeValues } from "app/auth/store/reducers/system-configuration";
import { TextInput } from "components/inputs/text-input";
import { find } from "lodash";

const userLabel = "Assigned To Nurse";
const validateUser = validateRequired(userLabel);

class CandidateRegistrationForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = ({ candidateRegistration }, { setErrors }) => {
    const newCandidate = { ...candidateRegistration,
      assignedUserId: candidateRegistration.assignedUserId.value,
      enrolmentType: this.props.enrolmentType.value,
      candidateId: this.props.id };

    this.props.registerCandidate(newCandidate).then(responds => {
      if (responds.error !== true) {
        this.props.setRecentSelectedUser(candidateRegistration.assignedUserId);
        this.props.onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  render() {
    const { initialValues, ...other } = this.props;

    return (
      <Form
        contentProps={other}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        content={() => (
          <>
            <Field
              name="candidateRegistration.assignedUserId"
              label={userLabel}
              component={UserSelector}
              validate={validateUser}
            />
            <Field
              name="candidateRegistration.enrolmentType"
              component={TextInput}
              disabled
              label="Program"
            />
          </>
        )}
      />
    );
  }
}

export default connect((state, props) => {
  const currentUser = getUser(state);
  const enrolmentTypes = getEnrolmentTypeValues(state);
  const enrolmentType = props.isSalveoProgram ? find(enrolmentTypes, x => x.value === "SalveoNursing") : find(enrolmentTypes, x => x.value === "WelfareCheck");

  const initialValues = {
    candidateRegistration: {
      assignedUserId: {
        value: currentUser.userId,
        label: currentUser.name,
      },
      enrolmentType: enrolmentType.label,
    },
  };

  return {
    enrolmentType,
    initialValues,
  };
}, { registerCandidate, setRecentSelectedUser })(CandidateRegistrationForm);
