import React from "react";
import { map, capitalize } from "lodash";
import { IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";

import formatDate from "helpers/format-date";
import { RecordIcon } from "helpers/icon-finder";
import ResponsiveTable, { TableCell, TableRow, TableBody } from "components/items/responsive-table";
import { recordNewMedicationHistoryEntry, editExistingMedicationHistoryEntry, recordMedicationHistoryAction } from "app/main/patients/actions/medicationHistory.actions";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import RecordMedicationHistoryForm from "app/main/patients/medications/components/record-medication-history-form";
import DefaultButton from "components/items/default-button";
import { showMessage } from "app/store/actions/message.actions";

import AssessmentRadioButtons from "./assessment-radio-buttons";

const MedicationHistoryTable = ({
  error, loading, isEmpty, medicationHistory, isCompleted, patientId, assessmentItemId, isEditable,
}) => {
  const dispatch = useDispatch();
  const disabled = isCompleted && !isEditable;

  const openRecordMedicationHistoryForm = (title, isEdit) => {
    dispatch(openDialog({
      children: (
        <RecordMedicationHistoryForm
          title={title}
          onSucceed={() => dispatch(closeDialog())}
          isEdit={isEdit}
          patientId={patientId}
          assessmentItemId={assessmentItemId}
        />
      ),
    }));
  };

  const onAddNew = () => {
    dispatch(recordNewMedicationHistoryEntry());
    openRecordMedicationHistoryForm("Add Medication", false);
  };

  const onEdit = medicationId => {
    dispatch(editExistingMedicationHistoryEntry(patientId, medicationId));
    openRecordMedicationHistoryForm("Edit Medication", true);
  };

  const EditButton = ({ id }) => (
    <IconButton disabled={disabled} onClick={() => onEdit(id)}>
      <RecordIcon size={20} />
    </IconButton>
  );

  const handleMedicationActionTypeChange = (medicationHistoryId, actionType) => {
    const newAction = {
      assessmentItemId,
      medicationActionType: actionType,
      historyEntryId: medicationHistoryId,
    };

    dispatch(recordMedicationHistoryAction(patientId, newAction)).then(response => {
      if (response.error) {
        const { exceptionMessage, innerException } = response.payload;
        dispatch(showMessage({
          message: `${capitalize(exceptionMessage)} ${innerException ? `: ${innerException.exceptionMessage}` : ""}`,
          variant: "error",
        }));
      }
    });
  };

  const renderTableHeader = () => (
    <TableRow>
      <TableCell>Status</TableCell>
      <TableCell>Medication</TableCell>
      <TableCell>Form</TableCell>
      <TableCell>Strength</TableCell>
      <TableCell className="min-w-160">Start Date</TableCell>
      <TableCell className="min-w-160">Ceased Date</TableCell>
      <TableCell className="w-32" />
    </TableRow>
  );

  const renderTableRow = item => (
    <TableBody key={item.id}>
      <TableRow>
        <TableCell title="Status" rowSpan={item.note && 2}>
          <AssessmentRadioButtons
            readOnly={isCompleted && !isEditable}
            id={item.id}
            patientMedicationId={item.patientMedicationId}
            actionType={item.actionType}
            handleRadioButtonChange={(id, value) => handleMedicationActionTypeChange(id, value)}
          />
        </TableCell>
        <TableCell title="Medication">{item.term}</TableCell>
        <TableCell title="Form">{item.form}</TableCell>
        <TableCell title="Strength">{item.strength}</TableCell>
        <TableCell title="Start Date">{formatDate(item.startDate)}</TableCell>
        <TableCell title="Ceased Date">{formatDate(item.endDate)}</TableCell>
        <TableCell isEditButton><EditButton id={item.id} /></TableCell>
      </TableRow>
      {item.note && (
        <TableRow>
          <TableCell component="th" colSpan={6}>{item.note}</TableCell>
        </TableRow>
      )}
    </TableBody>
  );

  const renderTableFooter = () => <div className="flex"><DefaultButton size="small" variant="outlined" onClick={() => onAddNew()} label="Add New Medication" /></div>;

  return (
    <ResponsiveTable
      title="Medications"
      disabled={disabled}
      isLoading={loading}
      isEmpty={isEmpty}
      error={error}
      renderTableContent={map(medicationHistory, m => renderTableRow(m))}
      renderTableHeader={renderTableHeader()}
      renderTableFooter={(!isCompleted || isEditable) && renderTableFooter()}
    />
  );
};

export default MedicationHistoryTable;
