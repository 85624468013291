import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import { isCurrentCodeSetLoading, areCodeSetValuesLoaded, areCodeSetValuesLoading } from "app/main/codeSet/reducers/codeSet.reducers";
import { forEach } from "lodash";
import { getCodeSetTypes } from "app/auth/store/reducers/system-configuration";

export const fetchCodeSetValues = (pageNumber, pageSize, searchParams) => dispatch => {
  const params = formatSearchParams(searchParams);
  const url = `/codeSet/getCodeSetValues?pageNumber=${pageNumber}&pageSize=${pageSize}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: state => isCurrentCodeSetLoading(state, pageNumber),
      entityType: "CURRENT_CODE_SET_VALUES",
      pageNumber,
    },
  });
};

export const addCodeSetValue = (codeSet, codeSetValue) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/codeSet/${codeSet.id}/new`,
    body: { codeSetValue },
    entityType: "CODE_SET_VALUE",
    codeSetType: codeSet.codeSetTypeCode,
  },
});

export const editCodeSetValue = (codeSet, codeSetValue) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/codeSet/${codeSet.id}/edit`,
    body: { codeSetValue },
    entityType: "CODE_SET_VALUE",
    codeSetType: codeSet.codeSetTypeCode,
  },
});

export const fetchMultipleCodeSetValues = codeSetTypeCodes => dispatch => {
  let url = "/codeSet/getCodeSetValuesByType?";

  forEach(codeSetTypeCodes, value => {
    url += `&codeSetTypeCodes=${value}`;
  });

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: state => areCodeSetValuesLoaded(state) || areCodeSetValuesLoading(state),
      entityType: "MULTIPLE_CODE_SET_VALUES",
    },
  });
};

export const fetchAllCodeSetValues = () => (dispatch, getState) => {
  const codeSetTypeCodes = getCodeSetTypes(getState());
  return dispatch(fetchMultipleCodeSetValues(codeSetTypeCodes));
};
