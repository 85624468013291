import React from "react";
import { isEmpty, find, get, isString, filter } from "lodash";
import Select from "react-select";
import { Typography } from "@material-ui/core";
import AsyncCreatableSelect from "react-select/async-creatable";
import Creatable from "react-select/creatable";
import AsyncSelect from "react-select/async";
import checkIfEmpty from "helpers/check-if-empty";

import BaseTextInput from "./base-text-input";
import SelectStyles from "./styles";

function SingleValue(props) {
  return (
    <Typography>
      {props.children}
    </Typography>
  );
}

const components = {
  SingleValue,
};

const filterOptions = (inputValue, items) => items.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()));

const promiseOptions = (inputValue, items) => new Promise(resolve => {
  setTimeout(() => {
    resolve(filterOptions(inputValue, items));
  }, 1000);
});

function formatCreateLabel(inputValue, createLabel) {
  return (
    <Typography
      color="textSecondary"
    >
      {createLabel || "Create:"}
      {inputValue}
    </Typography>
  );
}

export class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.getValue();
    this.state = { active: !checkIfEmpty(props.field.value) };
  }

  componentDidUpdate(props) {
    if (this.props.options !== props.options) {
      this.getValue();
    }
  }

  getValue() {
    const { options, field } = this.props;
    if (!isEmpty(options) && !isEmpty(field.value)) {
      let option = null;
      if (typeof field.value === "string") {
        option = find(options, x => x.value === field.value);
      } else if (typeof field.value === "object" && !isEmpty(field.value.value) && isEmpty(field.value.label)) {
        option = find(options, x => x.value === field.value.value);
      }
      if (option) {
        field.onChange(field.name)(option);
      }
    }
  }

  render() {
    const {
      field,
      label,
      options,
      form: { touched, errors, setFieldValue },
      onChange,
      isCreatable,
      createLabel,
      icon,
      disabled,
      isSearchable,
      isClearable,
      menuPortalTarget,
      menuPlacement,
      menuPosition,
      placeholder,
      isAsync,
      loadOptions,
      noOptionsMessage,
      isMulti,
      inputProps,
      cacheOptions,
      ...other
    } = this.props;

    const CreatableSelectProps = {
      formatCreateLabel: inputValue => formatCreateLabel(inputValue, createLabel),
      loadOptions: loadOptions ? inputValue => loadOptions(inputValue) : inputValue => promiseOptions(inputValue, options),
      defaultOptions: true,
      cacheOptions,
    };

    const AsyncSelectProps = {
      loadOptions: loadOptions ? inputValue => loadOptions(inputValue) : inputValue => promiseOptions(inputValue, options),
      defaultOptions: true,
      cacheOptions,
    };

    let selectProps = isCreatable ? CreatableSelectProps : {};
    selectProps = isAsync ? AsyncSelectProps : selectProps;

    let InputComponent = Select;

    if (isCreatable) {
      InputComponent = Creatable;
    } else if (isCreatable && isAsync) {
      InputComponent = AsyncCreatableSelect;
    } else if (isAsync) {
      InputComponent = AsyncSelect;
    }

    let inputValue = isString(field.value)
      ? filter(options, option => option.value === field.value || option.label === field.value)
      : field.value;

    // consider custom created option
    if (isEmpty(inputValue) && !isEmpty(field.value) && isCreatable) {
      inputValue = { value: field.value, label: field.value };
    }

    return (
      <BaseTextInput
        label={label}
        value={inputValue}
        ref={ref => { this.input = ref; }}
        icon={icon}
        error={get(errors, field.name, null)}
        touched={get(touched, field.name, null)}
        {...other}
        renderInput={(
          <InputComponent
            options={options}
            styles={SelectStyles}
            isClearable={isClearable}
            placeholder={placeholder}
            isDisabled={disabled}
            isSearchable={disabled}
            menuPortalTarget={menuPortalTarget}
            menuPlacement={menuPlacement}
            menuPosition={menuPosition}
            components={components}
            isMulti={isMulti}
            onChange={value => {
              field.onChange(field.name)(value);
              if (onChange) {
                onChange(value, setFieldValue);
              }
            }}
            noOptionsMessage={noOptionsMessage ? () => noOptionsMessage() : () => (<div> No {label} recorded</div>)}
            value={inputValue}
            onFocus={() => this.input.onFocus()}
            onBlur={() => this.input.onBlur()}
            {...selectProps}
            {...inputProps}
          />
        )}
      />
    );
  }
}

AutoComplete.defaultProps = {
  isSearchable: true,
  isClearable: true,
  menuPlacement: "bottom",
  menuPosition: "absolute",
  menuPortalTarget: document.body,
  placeholder: null,
  isMulti: false,
  cacheOptions: true,
};

export default AutoComplete;
