import React from "react";
import { find, get } from "lodash";
import PatientDetails, { NameLabel, PatientAgeLabel, TextLabel, PatientConsentStatus, PatientIdentifierLabel, AddressDetailsLabel, EmailLabel, ContactDetailsLabel, NextOfKin, AssignedUser } from "app/main/patients/components/patient-details";
import withPermissions from "permissions/withPermissions";

const PatientHeader = ({
  patient: { patientId, displayNameFull, birthDate, sex, patientIdentifiers, address, email, contactDetails, isDead },
  summary: { enrolments },
  patientNameOnClick,
  rightContent,
  hasPermissionEnrolmentView,
}) => {
  const salveoEnrolment = find(enrolments, x => x.enrolmentType.value === "SalveoNursing");
  const { unfinancial, removedAsCandidate, doNotContactAgain, ineligibleProduct, enrolmentStatus, reason } = salveoEnrolment || {};

  return (
    <PatientDetails
      renderContent={(
        <div className="flex flex-col items-container">
          <div className="flex-row-container with-gutter">
            <NameLabel name={displayNameFull} onClick={patientNameOnClick} />
            <PatientAgeLabel birthDate={birthDate} />
            <TextLabel className="font-bold" content={sex} />
            <TextLabel content={isDead && "Deceased"} />
            <TextLabel content={unfinancial && "unfinancial"} />
            <TextLabel content={removedAsCandidate && "Not in Hambs"} />
            <TextLabel content={doNotContactAgain && "Do Not Contact Again"} />
            <TextLabel content={ineligibleProduct && "Ineligible Product"} />
            <PatientConsentStatus consent={get(enrolmentStatus, "value", null)} reason={reason} />
          </div>
          <PatientIdentifierLabel patientId={patientId} patientIdentifiers={patientIdentifiers} />
        </div>
      )}
      renderExtraContent={(
        <div className="flex-row-container with-gutter">
          <AddressDetailsLabel address={address} />
          <EmailLabel email={email} />
          <ContactDetailsLabel contact={contactDetails} />
          <NextOfKin patientId={patientId} />
          {hasPermissionEnrolmentView && <AssignedUser enrolments={enrolments} patientId={patientId} enrolmentStatus={enrolmentStatus} />}
        </div>
      )}
      renderRightContent={rightContent}
    />
  );
};

export default withPermissions("EnrolmentView")(PatientHeader);
