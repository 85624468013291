import React, { useState } from "react";
import { isEmpty, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { fetchAssignmentOptions } from "app/main/referrals/actions/referrals.actions";
import { getAssignmentOptions, isAssignmentOptionsLoading } from "app/main/referrals/reducers/referrals.reducers";
import formatAssignmentOptions from "app/main/referrals/helpers/format-assignment-options";
import { AutoCompleteLoading } from "components/inputs";
import AssignToUserSelector from "./input";

const normaliseOptions = options => map(options, x => formatAssignmentOptions(x));

const AssignToUserSelectorContainer = props => {
  const dispatch = useDispatch();
  const defaultOptions = useSelector(getAssignmentOptions);
  const isLoading = useSelector(isAssignmentOptionsLoading);
  const [options, setOptions] = useState(normaliseOptions(defaultOptions));
  const [loading, setLoading] = useState(isEmpty(options));

  React.useLayoutEffect(() => {
    if (!loading) return;

    setLoading(true);
    dispatch(fetchAssignmentOptions())
      .then(res => setOptions(normaliseOptions(res.payload.assignmentOptions)))
      .finally(() => setLoading(false));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <AutoCompleteLoading />;

  return (
    <AssignToUserSelector
      defaultOptions={options}
      isLoading={isLoading}
      normaliseOptions={normaliseOptions}
      {...props}
    />
  );
};

export default AssignToUserSelectorContainer;
