import React from "react";
import { map } from "lodash";
import ContactListItem from "app/main/patients/contacts/components/contact-list-item";
import StateContainer from "components/layouts/default-state-container";

const ContactList = ({
  contacts,
  onEditContact,
  onRemoveContact,
  isEmpty,
  isError,
  isLoading,
}) => (
  <StateContainer loading={isLoading} error={isError} empty={isEmpty} emptyTitle="No Recorded Contacts">
    {map(contacts, (contact, index) => (
      <ContactListItem
        item={contact}
        key={contact.id}
        isFirst={index === 0}
        recordedBy={contact.recordedBy}
        // actions
        onEdit={onEditContact ? () => onEditContact(contact.id) : null}
        onRemove={onRemoveContact ? () => onRemoveContact(contact.id) : null}
      />
    ))}
  </StateContainer>
);

export default ContactList;
