import CodeSetIndexPage from "./pages/code-set-index-page";

const AdminConfig = {
  routes: [
    {
      path: "/admin/code-sets/:codeSetTypeCode",
      component: CodeSetIndexPage,
      title: "Code Set",
      exact: true,
    },
  ],
};

export default AdminConfig;
