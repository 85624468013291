import React from "react";
import { Tooltip, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChevronDoubleUpIcon from "mdi-react/ChevronDoubleUpIcon";
import IconComponent from "components/items/icon-component";
import { ApplicationItemWithWidth } from "app/main/applications/components/application-item";

const title = "Urgent Treatment Requested";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.error.dark,
  },
}));

const UrgentIcon = ({
  show,
  size = 20,
  iconOnly,
}) => {
  const classes = useStyles();

  if (!show) {
    return null;
  }

  const UrgentIconComponent = React.forwardRef((props, ref) => (
    <div className={classes.icon} ref={ref} {...props}>
      <IconComponent icon={<ChevronDoubleUpIcon />} size={size} />
    </div>
  ));

  if (iconOnly) {
    return <UrgentIconComponent />;
  }

  return (
    <Box className={classes.icon}>
      <Tooltip title={title}>
        <UrgentIconComponent />
      </Tooltip>
    </Box>
  );
};

export const UrgentIconItem = ({ show }) => <ApplicationItemWithWidth icon={<UrgentIcon iconOnly show={show} />} label={title} content="Yes" />;

export default UrgentIcon;
