import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import { getRecentInterventions, getInterventionsByPatientId, areInterventionsLoading, getErrorMessage } from "app/main/patients/reducers/interventions.reducers";
import { fetchPatientInterventions, editExistingIntervention, recordNewIntervention, removeIntervention, fetchInterventionsById, resolveIntervention, renewIntervention, recordOrUpdateIntervention } from "app/main/patients/actions/interventions.actions";
import RecordInterventionForm from "app/main/patients/interventions/components/record-intervention-form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import withPermissions from "permissions/withPermissions";

class InterventionListContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    const { patientId } = this.props;
    if (patientId) {
      this.props.fetchPatientInterventions(patientId);
    }
    this.onRecordIntervention = this.onRecordIntervention.bind(this);
    this.onEditIntervention = this.onEditIntervention.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = ({ intervention }, { setErrors }) => {
    this.props.recordOrUpdateIntervention(this.props.patientId, intervention).then(responds => {
      if (responds.error !== true) {
        this.onClose();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  onClose = () => this.props.closeDialog();

  openRecordInterventionForm = title => {
    this.props.openDialog({
      children: <RecordInterventionForm
        title={title}
        handleSubmit={this.handleSubmit}
      />,
    });
  }

  onRecordIntervention = () => {
    this.props.recordNewIntervention(this.props.patientId);
    this.openRecordInterventionForm("Add Intervention");
  };

  onEditIntervention = interventionId => {
    this.props.editExistingIntervention(this.props.patientId, interventionId);
    this.openRecordInterventionForm("Edit Intervention");
  };

  onRemoveIntervention = interventionId => {
    this.props.removeIntervention(this.props.patientId, interventionId);
    this.onClose();
  };

  onResolveIntervention = problemId => {
    this.props.resolveIntervention(this.props.patientId, problemId);
    this.onClose();
  };

  onRenewIntervention = problemId => {
    this.props.renewIntervention(this.props.patientId, problemId);
    this.onClose();
  };

  render() {
    const {
      loading,
      error,
      interventions,
      renderContent,
      hasPermissionInterventionsCreate,
      hasPermissionInterventionsUpdate,
      hasPermissionInterventionsDelete,
      hasPermissionInterventionsResolve,
      hasPermissionInterventionsRenew,
    } = this.props;

    return (
      renderContent({
        interventions,
        onRecordIntervention: hasPermissionInterventionsCreate ? this.onRecordIntervention : null,
        onEditIntervention: hasPermissionInterventionsUpdate ? this.onEditIntervention : null,
        onRemoveIntervention: hasPermissionInterventionsDelete ? this.onRemoveIntervention : null,
        onResolveIntervention: hasPermissionInterventionsResolve ? this.onResolveIntervention : null,
        onRenewIntervention: hasPermissionInterventionsRenew ? this.onRenewIntervention : null,
        isLoading: loading,
        isError: error,
        isEmpty: isEmpty(interventions),
      })
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { patientId } = ownProps;
  const interventions = ownProps.numberOfItems ? getRecentInterventions(state, patientId, ownProps.numberOfItems) : getInterventionsByPatientId(state, patientId);
  return {
    interventions,
    error: getErrorMessage(state, patientId),
    loading: areInterventionsLoading(state, patientId),
  };
};

export default withPermissions(
  "InterventionsDelete",
  "InterventionsUpdate",
  "InterventionsCreate",
  "InterventionsResolve",
  "InterventionsRenew",
)(connect(
  mapStateToProps,
  {
    openDialog,
    closeDialog,
    fetchInterventionsById,
    fetchPatientInterventions,
    recordNewIntervention,
    editExistingIntervention,
    removeIntervention,
    resolveIntervention,
    renewIntervention,
    recordOrUpdateIntervention,
  },
)(InterventionListContainer));
