import React from "react";
import { useHistory } from "react-router-dom";

import DefaultButton from "components/items/default-button";
import ActiveReferralPanel from "app/main/patientReferralWorklist/components/active-referral-panel";
import useAddPatientReferral from "app/main/patientReferralWorklist/hooks/useAddPatientReferral";

const PatientActiveReferralPanel = ({
  patientId,
  ...other
}) => {
  const history = useHistory();

  useAddPatientReferral({ patientId });

  return (
    <ActiveReferralPanel
      patientId={patientId}
      headerRightContent={(
        <DefaultButton
          fab
          label="View all referrals"
          onClick={() => history.push(`/patients/${patientId}/?tab=referrals`)}
        />
      )}
      {...other}
    />
  );
};

export default PatientActiveReferralPanel;
