import AdminIndexPage from "./pages/admin-index-page";

const AdminConfig = {
  routes: [
    {
      path: "/admin",
      component: AdminIndexPage,
      title: "Admin",
      exact: true,
      isIndexPage: true,
    },
  ],
};

export default AdminConfig;
