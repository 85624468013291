import React from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { isEmpty } from "lodash";
import formatDate from "helpers/format-date";
import Highlighter from "react-highlight-words";

import PatientIdentifierLabel from "./patient-identifier-label";
import AddressDetailsLabel from "./address-details-label";

const PatientSummary = ({
  patient,
  inlineContent,
  showAddress,
  titleVariant = "body1",
  textVariant = "body2",
  highlightTerm = [],
}) => {
  if (isEmpty(patient)) return "N/A";

  return (
    <div className="flex flex-col flex-1">
      <Typography className="font-bold" variant={titleVariant}>
        {isEmpty(highlightTerm) ? patient.displayNameFull : (
          <Highlighter autoEscape searchWords={highlightTerm} textToHighlight={patient.displayNameFull} />
        )}
      </Typography>
      <div className={clsx("flex", inlineContent ? "flex-row-container with-gutter" : "flex-col")}>
        <div className="flex-row-container with-gutter">
          <div className="flex-row-container with-gutter">
            <Typography variant={textVariant} className="font-bold mr-4">DOB</Typography>
            <Typography variant={textVariant} color="textPrimary">{formatDate(patient.birthDate)}</Typography>
          </div>
          <div className="flex-row-container with-gutter">
            <Typography variant={textVariant} className="font-bold mr-4">Sex</Typography>
            <Typography variant={textVariant} color="textPrimary">{patient.sex?.label ?? patient.sex ?? ""}</Typography>
          </div>
          {patient.isDead && <Typography variant={textVariant} className="font-bold" color="textPrimary">Deceased</Typography>}
        </div>
        <PatientIdentifierLabel
          showIcon={false}
          patientIdentifiers={patient.patientIdentifiers}
          textVariant={textVariant}
          highlightTerm={highlightTerm}
        />
      </div>
      {showAddress && <AddressDetailsLabel address={patient.address} showIcon={false} />}
    </div>
  );
};

export default PatientSummary;
