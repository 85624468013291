import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import { getRecentProblems, getProblemsByPatientId, areProblemsLoading, getErrorMessage } from "app/main/patients/reducers/problems.reducers";
import { fetchPatientProblems, editExistingProblem, recordNewProblem, removeProblem, fetchProblemsById, resolveProblem, renewProblem, recordOrUpdateProblem } from "app/main/patients/actions/problems.actions";
import RecordProblemForm from "app/main/patients/problems/components/record-problem-form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import withPermissions from "permissions/withPermissions";

class ProblemListContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    const { patientId } = this.props;
    if (patientId) {
      this.props.fetchPatientProblems(patientId);
    }
    this.onRecordProblem = this.onRecordProblem.bind(this);
    this.onEditProblem = this.onEditProblem.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = ({ problem }, { setErrors }) => {
    this.props.recordOrUpdateProblem(this.props.patientId, problem).then(responds => {
      if (responds.error !== true) {
        this.onClose();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  onClose = () => this.props.closeDialog();

  openRecordProblemForm = title => {
    this.props.openDialog({
      children: <RecordProblemForm
        title={title}
        handleSubmit={this.handleSubmit}
      />,
    });
  }

  onRecordProblem = () => {
    this.props.recordNewProblem();
    this.openRecordProblemForm("Add Problem");
  };

  onEditProblem = problemId => {
    this.props.editExistingProblem(this.props.patientId, problemId);
    this.openRecordProblemForm("Edit Problem");
  };

  onRemoveProblem = problemId => {
    this.props.removeProblem(this.props.patientId, problemId);
    this.onClose();
  };

  onResolveProblem = problemId => {
    this.props.resolveProblem(this.props.patientId, problemId);
    this.onClose();
  };

  onRenewProblem = problemId => {
    this.props.renewProblem(this.props.patientId, problemId);
    this.onClose();
  };

  render() {
    const {
      loading,
      error,
      problems,
      renderContent,
      hasPermissionProblemsCreate,
      hasPermissionProblemsUpdate,
      hasPermissionProblemsDelete,
      hasPermissionProblemsResolve,
      hasPermissionProblemsRenew,
    } = this.props;
    return (
      renderContent({
        problems,
        onRecordProblem: hasPermissionProblemsCreate ? this.onRecordProblem : null,
        onEditProblem: hasPermissionProblemsUpdate ? this.onEditProblem : null,
        onRemoveProblem: hasPermissionProblemsDelete ? this.onRemoveProblem : null,
        onResolveProblem: hasPermissionProblemsResolve ? this.onResolveProblem : null,
        onRenewProblem: hasPermissionProblemsRenew ? this.onRenewProblem : null,
        isLoading: loading,
        isError: error,
        isEmpty: isEmpty(problems),
      })
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { patientId } = ownProps;
  const problems = ownProps.numberOfItems ? getRecentProblems(state, patientId, ownProps.numberOfItems) : getProblemsByPatientId(state, patientId);
  return {
    problems,
    error: getErrorMessage(state, patientId),
    loading: areProblemsLoading(state, patientId),
  };
};

export default withPermissions(
  "ProblemsDelete",
  "ProblemsUpdate",
  "ProblemsCreate",
  "ProblemsResolve",
  "ProblemsRenew",
)(connect(
  mapStateToProps,
  {
    openDialog,
    closeDialog,
    fetchProblemsById,
    fetchPatientProblems,
    recordNewProblem,
    editExistingProblem,
    removeProblem,
    resolveProblem,
    renewProblem,
    recordOrUpdateProblem,
  },
)(ProblemListContainer));
