import React, { isValidElement, cloneElement } from "react";
import { isString, get } from "lodash";
import { Icon } from "@material-ui/core";
import PropTypes from "prop-types";

/**
 * render icon from either Material icons or Material community Icons
 * https://material.io/resources/icons/?style=baseline
 * https://materialdesignicons.com/
 * please note it has to be a valid element and icon
 * example
 * 1. pass string, it will used Material icons library
 * 2. pass Material icons as element
 * 3. pass material community icon as element
 */

const IconComponent = ({
  icon,
  size = 20,
  color,
  ...other
}) => {
  const muiIconStyle = {
    fontSize: size,
    color,
  };

  if (isString(icon)) {
    return <Icon color="inherit" style={{ ...muiIconStyle }} {...other}>{icon}</Icon>;
  } if (isValidElement(icon)) {
    if (get(icon, ["type", "muiName"]) === "Icon") {
      return cloneElement(icon, { style: muiIconStyle, color: "inherit" });
    }
    return cloneElement(icon, { size, color: "currentColor" }, null);
  }
  return null;
};

IconComponent.prototype = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Icon),
    PropTypes.element,
  ]),
};

export default IconComponent;
