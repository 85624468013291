import SecureExternalDocumentWorklistPage from "./pages/secure-external-document-worklist-page";

const ExternalDocumentWorklistConfig = {
  routes: [
    {
      path: "/externalDocumentWorklist",
      component: SecureExternalDocumentWorklistPage,
      exact: true,
      isIndexPage: true,
      routes: [
        {
          path: "/externalDocumentWorklist?fileName=:fileName",
          component: SecureExternalDocumentWorklistPage,
          exact: true,
          isIndexPage: true,
        },
      ],
    },
  ],
};

export default ExternalDocumentWorklistConfig;
