import React from "react";
import { map } from "lodash";
import ConditionListItem from "app/main/patients/conditions/components/condition-list-item";
import StateContainer from "components/layouts/default-state-container";

const ConditionList = ({
  conditions,
  onEditCondition,
  onRemoveCondition,
  isEmpty,
  isError,
  isLoading,
}) => (
  <StateContainer loading={isLoading} error={isError} empty={isEmpty} emptyTitle="No Recorded Medical Conditions">
    {map(conditions, (condition, index) => (
      <ConditionListItem
        item={condition}
        key={condition.id}
        isFirst={index === 0}
          // actions
        onEdit={onEditCondition ? () => onEditCondition(condition.id) : null}
        onRemove={onRemoveCondition ? () => onRemoveCondition(condition.id) : null}
      />
    ))}
  </StateContainer>
);

export default ConditionList;
