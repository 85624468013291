import { get, concat, find, filter, map } from "lodash";
import curryConnector from "utils/connectors";

export const MEDICATION_HISTORY_STATE_KEY = "medicationHistory";

const curry = fn => curryConnector(fn, MEDICATION_HISTORY_STATE_KEY);

export const ActionTypes = {
  LOADING_MEDICATION_HISTORY: "LOADING_MEDICATION_HISTORY",
  LOADED_MEDICATION_HISTORY: "LOADED_MEDICATION_HISTORY",
  ERROR_LOADING_MEDICATION_HISTORY: "ERROR_LOADING_MEDICATION_HISTORY",

  LOADED_CURRENT_MEDICATION_HISTORY_ENTRY: "LOADED_CURRENT_MEDICATION_HISTORY_ENTRY",
  CREATED_MEDICATION_HISTORY_ENTRY: "CREATED_MEDICATION_HISTORY_ENTRY",
  UPDATED_MEDICATION_HISTORY_ENTRY: "UPDATED_MEDICATION_HISTORY_ENTRY",
  UPDATED_MEDICATION_HISTORY_ACTION: "UPDATED_MEDICATION_HISTORY_ACTION",

  LOADED_CURRENT_MEDICATION_HISTORY_PROBLEM: "LOADED_CURRENT_MEDICATION_HISTORY_PROBLEM",
  UPDATED_MEDICATION_HISTORY_PROBLEM: "UPDATED_MEDICATION_HISTORY_PROBLEM",
  CREATED_MEDICATION_HISTORY_PROBLEM: "CREATED_MEDICATION_HISTORY_PROBLEM",

  LOADED_CURRENT_MEDICATION_HISTORY_INTERVENTION: "LOADED_CURRENT_MEDICATION_HISTORY_INTERVENTION",
  UPDATED_MEDICATION_HISTORY_INTERVENTION: "UPDATED_MEDICATION_HISTORY_INTERVENTION",
  CREATED_MEDICATION_HISTORY_INTERVENTION: "CREATED_MEDICATION_HISTORY_INTERVENTION",

  UPDATED_MEDICATION_HISTORY_STATUS: "UPDATED_MEDICATION_HISTORY_STATUS",
};

const INITIAL_STATE = {
  all: {},
  medicationHistories: null,
  currentProblem: null,
  currentIntervention: null,
};

const addItem = (state, patientId, data, entity) => {
  const arr = concat(get(state.all, [patientId, entity], []), data);

  return {
    ...state,
    all: {
      ...state.all,
      [patientId]: {
        ...state.all[patientId],
        [entity]: arr,
      },
    },
  };
};

const updateItem = (state, patientId, data, entity) => {
  let arr = get(state.all, [patientId, entity], []);

  arr = map(arr, entry => {
    if (entry.id === data.id) {
      return data;
    }
    return entry;
  });
  return {
    ...state,
    all: { ...state.all, [patientId]: { ...state.all[patientId], [entity]: arr } },
  };
};

const updateMedicationHistoryAction = (state, action) => {
  const { payload: { medicationHistoryEntry, patientId } } = action;
  let medicationHistory = get(state.all, [patientId, "medicationHistories"], []);

  medicationHistory = map(medicationHistory, entry => {
    if (entry.id === medicationHistoryEntry.id) {
      return {
        ...entry,
        actionType: medicationHistoryEntry.actionType,
        endDate: medicationHistoryEntry.endDate,
        isCurrent: medicationHistoryEntry.isCurrent,
      };
    }
    return entry;
  });
  return {
    ...state,
    current: medicationHistoryEntry,
    all: { ...state.all, [patientId]: { ...state.all[patientId], medicationHistories: medicationHistory } },
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_MEDICATION_HISTORY:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: true } },
      };
    case ActionTypes.ERROR_LOADING_MEDICATION_HISTORY:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: false, error: action.payload.message } },
      };
    case ActionTypes.LOADED_MEDICATION_HISTORY:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]:
            {
              ...state.all[action.payload.patientId],
              loading: false,
              medicationHistories: action.payload.medicationHistory,
              medicationProblems: action.payload.medicationProblems,
              medicationInterventions: action.payload.medicationInterventions,
            },
        },
      };
    case ActionTypes.LOADED_CURRENT_MEDICATION_HISTORY_ENTRY:
      return {
        ...state,
        currentMedication: action.payload.medicationHistoryEntry,
      };
    case ActionTypes.LOADED_CURRENT_MEDICATION_HISTORY_PROBLEM:
      return {
        ...state,
        currentProblem: action.payload.medicationProblem,
      };
    case ActionTypes.LOADED_CURRENT_MEDICATION_HISTORY_INTERVENTION:
      return {
        ...state,
        currentIntervention: action.payload.medicationIntervention,
      };
    case ActionTypes.SAVED_MEDICATION_HISTORY_ENTRY:
    case ActionTypes.CREATED_MEDICATION_HISTORY_ENTRY:
      return addItem(state, action.payload.patientId, action.payload.medicationHistoryEntry, "medicationHistories");
    case ActionTypes.UPDATED_MEDICATION_HISTORY_ENTRY:
      return updateItem(state, action.payload.patientId, action.payload.medicationHistoryEntry, "medicationHistories");
    case ActionTypes.UPDATED_MEDICATION_HISTORY_ACTION:
      return updateMedicationHistoryAction(state, action);
    case ActionTypes.CREATED_MEDICATION_HISTORY_PROBLEM:
      return addItem(state, action.payload.patientId, action.payload.medicationProblem, "medicationProblems");
    case ActionTypes.UPDATED_MEDICATION_HISTORY_PROBLEM:
      return updateItem(state, action.payload.patientId, action.payload.medicationProblem, "medicationProblems");
    case ActionTypes.CREATED_MEDICATION_HISTORY_INTERVENTION:
      return addItem(state, action.payload.patientId, action.payload.medicationIntervention, "medicationInterventions");
    case ActionTypes.UPDATED_MEDICATION_HISTORY_INTERVENTION:
      return updateItem(state, action.payload.patientId, action.payload.medicationIntervention, "medicationInterventions");
    default:
      return state;
  }
};

export const isMedicationHistoryLoading = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loading"], false));

export const isMedicationHistoryLoaded = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loaded"], false));

export const getMedicationHistoryByPatientId = curry(({ localState }, patientId) => get(localState, ["all", patientId, "medicationHistories"], []));

export const getMedicationProblems = curry(({ localState }, patientId) => get(localState, ["all", patientId, "medicationProblems"], []));

export const getMedicationInterventions = curry(({ localState }, patientId) => get(localState, ["all", patientId, "medicationInterventions"], []));

export const getMedicationEntryById = curry(({ localState }, patientId, medicationEntryId) => {
  const medicationHistory = get(localState, ["all", patientId, "medicationHistories"], []);
  return find(medicationHistory, x => x.id === medicationEntryId) || {};
});

export const getMedicationProblemById = curry(({ localState }, patientId, problemId) => {
  const medicationProblems = get(localState, ["all", patientId, "medicationProblems"], []);
  return find(medicationProblems, x => x.id === problemId) || {};
});

export const getMedicationInterventionById = curry(({ localState }, patientId, interventionId) => {
  const medicationInterventions = get(localState, ["all", patientId, "medicationInterventions"], []);
  return find(medicationInterventions, x => x.id === interventionId) || {};
});

export const getMedicationHistoryByActionType = curry(({ localState }, patientId, actionType) => {
  const medicationHistory = get(localState, ["all", patientId, "medicationHistories"], []);
  return filter(medicationHistory, x => x.actionType === actionType) || {};
});

export const getCurrentMedicationEntry = curry(({ localState }) => get(localState, ["currentMedication"], null));

export const getCurrentMedicationProblem = curry(({ localState }) => get(localState, ["currentProblem"], null));

export const getCurrentMedicationIntervention = curry(({ localState }) => get(localState, ["currentIntervention"], null));

export const getErrorMessage = curry(({ localState }, patientId) => get(localState, ["all", patientId, "error"], null));
