import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Typography, Badge, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    height: "auto",
    border: `1px solid ${theme.palette.common.white}`,
    boxShadow: `0 0 0 1px ${theme.palette.common.white}`,
    "&.color-default": {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.getContrastText(theme.palette.grey[200]),
    },
    "&.color-primary": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    "&.color-secondary": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.getContrastText(theme.palette.secondary.main),
    },
    "&.color-success": {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.getContrastText(theme.palette.success.main),
    },
    "&.color-info": {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.getContrastText(theme.palette.info.main),
    },
    "&.color-warning": {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.getContrastText(theme.palette.warning.main),
    },
    "&.color-error": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.getContrastText(theme.palette.error.main),
    },
  },
  text: {
    color: "inherit",
  },
}));

const TextTag = ({
  color,
  variant,
  label,
  type,
  ...other
}) => {
  const classes = useStyles();
  return (
    type === "badge" ? (
      <Badge
        color={color}
        classes={{ badge: clsx(classes.root, `color-${variant}`) }}
        badgeContent={<Typography variant="caption" className={clsx("font-bold text-base", classes.text)}>{label}</Typography>}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        {...other}
      />
    ) : (
      <Chip
        size="small"
        color={color}
        classes={{ root: clsx(classes.root, `color-${variant}`), label: clsx("font-bold text-base", classes.text) }}
        label={label}
        {...other}
      />
    )
  );
};

TextTag.propTypes = {
  type: PropTypes.oneOf(["chip", "badge"]),
  variant: PropTypes.oneOf(["default", "primary", "secondary", "success", "info", "warning", "error"]),
};

TextTag.defaultProps = {
  type: "chip",
  variant: "default",
};

export default TextTag;
