import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import clsx from "clsx";
import { isEmpty } from "lodash";
import IconComponent from "components/items/icon-component";

const InfoCard = ({
  icon = "error",
  severity = "info",
  title,
  headline,
  children,
  color,
  classes,
  ...other
}) => {
  return (
    <Alert
      className="p-16"
      icon={<IconComponent icon={icon} />}
      severity={severity}
      {...other}
    >
      {headline && <AlertTitle className={clsx(isEmpty(children) && "mb-0")}>{headline}</AlertTitle>}
      {title && <AlertTitle className={clsx(isEmpty(children) && "mb-0")}>{title}</AlertTitle>}
      {children}
    </Alert>
  );
};

export default InfoCard;
