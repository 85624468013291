import React from "react";
import ScreenHeader from "components/items/screen-header";
import withPermissions from "permissions/withPermissions";
import SpecialtyProfilesSearch from "app/main/specialtyProfiles/components/specialtyProfiles-search";

const OrgUnitSpecialtyProfilesPageHeader = ({
  id,
  orgUnit,
  history,
  hasPermissionSpecialtyProfileCreate,
}) => (
  <ScreenHeader
    renderSearch={<SpecialtyProfilesSearch orgUnitId={id} />}
    action={hasPermissionSpecialtyProfileCreate && {
      label: "add a specialty",
      onClick: () => history.push(`/admin/org-units/${orgUnit.orgUnitType}/${id}/specialtyProfiles/new`),
    }}
  />
);

export default withPermissions("SpecialtyProfileCreate")(OrgUnitSpecialtyProfilesPageHeader);
