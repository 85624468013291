import React from "react";
import TextLink from "components/items/text-link";
import { contactIsEmpty } from "app/main/patients/helpers/check-object-is-empty";

function getContact(contact) {
  switch (contact.preferredContactMethod) {
    case "Home" && contact.phoneHome != null:
      return {
        detail: contact.phoneHome,
        icon: "call",
      };
    case "Work" && contact.phoneWork != null:
      return {
        detail: contact.phoneWork,
        icon: "call",
      };
    case "Mobile" && contact.phoneMobile != null:
      return {
        detail: contact.phoneMobile,
        icon: "smartphone",
      };
    default:
      return {
        detail: contact.phoneMobile || contact.phoneHome,
        icon: "call",
      };
  }
}

export default function ContactDetailsLabel({ contact }) {
  if (contactIsEmpty(contact)) return null;

  if (typeof contact === "string") {
    return <TextLink icon="call" content={contact} />;
  }

  const contactMethod = getContact(contact);

  return (
    // onClick={() => window.open(`tel:${contactMethod.detail}`)} // need to trim
    <TextLink icon={contactMethod.icon} content={contactMethod.detail} />
  );
}
