import React from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { find } from "lodash";

import "utils/yup-validation";
import LoadingState from "components/items/loading-state";
import useFormFieldConfig from "hooks/use-form-field-config";
import { getAllCodeSetValues } from "app/main/codeSet/reducers/codeSet.reducers";
import { getEnabledTriageCategorySettings } from "app/auth/store/reducers/system-configuration";

import AcceptReferralForm from "./form";

export default ({ referral, ...other }) => {
  const { loadingFieldSettings, fieldSettings } = useFormFieldConfig("TriageForm");
  const codeSetValues = useSelector(getAllCodeSetValues);
  const triageCategories = useSelector(getEnabledTriageCategorySettings);

  if (loadingFieldSettings) return <LoadingState />;

  const fieldLabels = {
    episodeHealthCondition: fieldSettings?.episodeHealthCondition?.label || "Episode Health Condition",
    note: fieldSettings?.note?.label || "Message",
  };

  let referrerAdvisedPriority = null;
  let triageCategory = null;
  // if referrerAdvisedPriorityCodeId if not null
  // we look for the codeSetValue proprieties by its id
  if (referral.referralDocument?.referrerAdvisedPriorityCodeId) {
    referrerAdvisedPriority = find(codeSetValues, x => x.id === referral.referralDocument.referrerAdvisedPriorityCodeId);
  }

  // if setting for the codeSetValue is founded
  // we set the default value for triageCategory
  if (referrerAdvisedPriority) {
    triageCategory = find(triageCategories, x => x.codeSetValueCode === referrerAdvisedPriority.code)?.triageCategory ?? null;
  }

  const schema = Yup.object().shape({
    referral: Yup.object().shape({
      isUrgent: Yup.bool().nullable(),
      triageCategory: Yup.string()
        .required("This field is required")
        .default(triageCategory)
        .nullable(),
      episodeHealthConditionCodeId: Yup.string()
        .fieldSettingsValidation(fieldLabels.episodeHealthCondition, fieldSettings?.episodeHealthCondition)
        .nullable(),
      note: Yup.string()
        .fieldSettingsValidation(fieldLabels.note, fieldSettings?.note)
        .max(1000, "Too long")
        .nullable(),
    }),
  });

  return (
    <AcceptReferralForm
      schema={schema}
      fieldSettings={fieldSettings}
      fieldLabels={fieldLabels}
      triageCategoryOptions={triageCategories}
      {...other}
    />
  );
};
