import { ApiActions } from "middleware/call-api";
import { ActionTypes,
  areAllergiesLoading,
  areAllergiesLoaded,
  getAllergyById } from "../reducers/allergies.reducers";

export const setCurrentAllergy = allergy => ({
  type: ActionTypes.LOADED_CURRENT_ALLERGY,
  payload: { allergy },
});

export const fetchPatientAllergies = patientId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/allergies`,
    bailout: state => areAllergiesLoaded(state, patientId) || areAllergiesLoading(state, patientId),
    entityType: "allergies",
    patientId,
  },
});

export const fetchAllergiesById = (patientId, allergyId) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/allergies/${allergyId}`,
    types: [ActionTypes.LOADING_CURRENT_ALLERGY, ActionTypes.LOADED_CURRENT_ALLERGY, ActionTypes.ERROR_LOADING_CURRENT_ALLERGY],
    patientId,
    allergyId,
  },
});

/** * Add Allergy */
export const recordNewAllergy = patientId => setCurrentAllergy({
  id: null,
  patientId,
  allergyTerm: null,
  severity: null,
  reaction: null,
  note: null,
  reportedDate: null,
  resolvedDate: null,
  removedDateTimeUtc: null,
  isCurrent: true,
});

export const recordAllergy = allergy => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${allergy.patientId}/allergies/new`,
    body: { allergy },
    entityType: "allergy",
    patientId: allergy.patientId,
  },
});

export const editExistingAllergy = (patientId, allergyId) => (dispatch, getState) => {
  const allergy = getAllergyById(getState(), patientId, allergyId);
  dispatch(setCurrentAllergy(allergy));
};

export const editAllergy = allergy => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/patient/${allergy.patientId}/allergies/edit`,
    body: { allergy },
    entityType: "allergy",
    patientId: allergy.patientId,
  },
});

export const removeAllergy = (patientId, allergyId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/patient/${patientId}/allergies/remove`,
    body: { allergy: { id: allergyId, patientId } },
    entityType: "allergy",
    patientId,
    allergyId,
  },
});

