import React, { useEffect, useState } from "react";
import connect from "react-redux/es/connect/connect";
import { capitalize, isEmpty, every } from "lodash";
import { useDispatch } from "react-redux";
import DefaultButton from "components/items/default-button";

import { getMedicationHistoryByPatientId, isMedicationHistoryLoading, getErrorMessage, getMedicationHistoryByActionType, getMedicationProblems, getMedicationInterventions } from "app/main/patients/reducers/medicationHistory.reducers";
import { showMessage } from "app/store/actions/message.actions";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import ConfirmationDialog from "components/items/confirmation-dialog";
import { onMedicationHistoryComplete, printAssessmentItems, fetchAssessmentPrintHistory } from "app/main/patients/actions/assessments.actions";
import { fetchPatientMedicationHistory, onMedicationHistoryNotRequired, onMedicationHistoryRequired } from "app/main/patients/actions/medicationHistory.actions";
import CustomSurveyPage from "app/main/patients/components/custom-survey-page";

import MedicationHistoryTable from "./medication-history-table";
import PatientProblemTable from "./patient-problem-table";
import PatientInterventionTable from "./patient-intervention-table";

const AssessmentActionButton = ({ label, onClick, ...other }) => (
  <DefaultButton size="small" className="flex mx-4" variant="outlined" onClick={onClick} label={label} {...other} />
);

const MedicationHistoryAssessment = ({
  id,
  patientId,
  surveyType,
  assessmentId,
  title,
  status,
  medicationHistory,
  loading,
  error,
  isCompleted,
  pendingActions,
  allowNotRequired,
  medicationProblems,
  medicationInterventions,
  hasPermissionAssessmentsPrint,
}) => {
  const dispatch = useDispatch();
  const [isEditable, setEditable] = useState(false);

  useEffect(() => {
    if (patientId) {
      dispatch(fetchPatientMedicationHistory(patientId, id));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onComplete = () => {
    const messageProps = {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    };
    dispatch(onMedicationHistoryComplete(patientId, { id, assessmentId, surveyType })).then(response => {
      if (response.error) {
        const { exceptionMessage, innerException } = response.payload;
        dispatch(showMessage({
          message: `${capitalize(exceptionMessage)} ${innerException ? `: ${innerException.exceptionMessage}` : ""}`,
          variant: "error",
          ...messageProps,
        }));
      } else {
        dispatch(showMessage({
          message: "Assessment Completed",
          variant: "success",
          autoHideDuration: 5000,
          ...messageProps,
        }));
      }
    });
  };

  const updateAssessmentStatus = (apiCall, content, editable) => {
    dispatch(openDialog({
      children: (
        <ConfirmationDialog
          type="warning"
          title="Are you sure?"
          content={content}
          onConfirm={() => {
            dispatch(apiCall(patientId, { id }));
            setEditable(editable);
            dispatch(closeDialog());
          }}
          onCancel={() => dispatch(closeDialog())}
        />
      ),
    }));
  };

  const printMedicationHistory = () => {
    dispatch(openDialog({
      children: (
        <ConfirmationDialog
          type="warning"
          title="Print Medication History"
          content="Are you sure you want to print the following assessment?"
          onConfirm={() => {
            dispatch(printAssessmentItems({ patientId, currentAssessmentId: assessmentId, surveys: { MedicationHistory: true } }));
            dispatch(fetchAssessmentPrintHistory({ patientId, assessmentId }));
            dispatch(closeDialog());
          }}
          onCancel={() => dispatch(closeDialog())}
        />
      ),
    }));
  };

  const assessmentNotRequired = () => {
    updateAssessmentStatus(onMedicationHistoryNotRequired, "This will make the assessment item not required", false);
  };

  const assessmentRequired = () => {
    updateAssessmentStatus(onMedicationHistoryRequired, "This will make the current assessment item required", true);
  };

  const assessmentInProgress = () => {
    updateAssessmentStatus(onMedicationHistoryRequired, "This will set the current assessment item back to in progress", true);
  };

  const sections = [medicationHistory, medicationInterventions, medicationProblems];
  const showEditButton = isCompleted && status !== "NotRequired";
  const showNotRequiredButton = !isCompleted && allowNotRequired;
  const showRequiredButton = status === "NotRequired" && allowNotRequired;
  const showPrintButton = isCompleted && !isEditable && hasPermissionAssessmentsPrint;

  const tableProps = {
    loading,
    error,
    isCompleted,
    patientId,
    assessmentItemId: id,
    isEditable,
  };

  return (
    <CustomSurveyPage
      title={title}
      disabled={isCompleted || !isEmpty(pendingActions) || every(sections, isEmpty)}
      onComplete={onComplete}
      renderActions={(
        <>
          {showEditButton && <AssessmentActionButton onClick={() => assessmentInProgress()} label="enable edit" />}
          {showNotRequiredButton && <AssessmentActionButton onClick={() => assessmentNotRequired()} label="not required" />}
          {showRequiredButton && <AssessmentActionButton onClick={() => assessmentRequired()} label="required" />}
          {showPrintButton && <AssessmentActionButton onClick={() => printMedicationHistory(id)} label="print" />}
        </>
      )}
    >
      <MedicationHistoryTable
        medicationHistory={medicationHistory}
        isEmpty={isEmpty(medicationHistory)}
        {...tableProps}
      />
      <PatientInterventionTable
        medicationInterventions={medicationInterventions}
        isEmpty={isEmpty(medicationInterventions)}
        {...tableProps}
      />
      <PatientProblemTable
        medicationProblems={medicationProblems}
        isEmpty={isEmpty(medicationProblems)}
        {...tableProps}
      />
    </CustomSurveyPage>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { patientId } = ownProps;
  const pendingActions = getMedicationHistoryByActionType(state, patientId, "Pending");
  const medicationHistory = getMedicationHistoryByPatientId(state, patientId);
  const medicationProblems = getMedicationProblems(state, patientId);
  const medicationInterventions = getMedicationInterventions(state, patientId);

  return {
    pendingActions,
    medicationHistory,
    medicationProblems,
    medicationInterventions,
    error: getErrorMessage(state, patientId),
    loading: isMedicationHistoryLoading(state, patientId),
  };
};

export default connect(mapStateToProps, {})(MedicationHistoryAssessment);
