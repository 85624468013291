/* eslint-disable no-nested-ternary */
import React from "react";
import { InputLabel, Input, LinearProgress, FilledInput } from "@material-ui/core";
import { isEmpty } from "lodash";
import clsx from "clsx";

import checkIfEmpty from "helpers/check-if-empty";
import FormContext from "components/FormContext";
import BaseInputField from "./base-input-component";
import DisabledInput from "./disabled-input";
import HelperTextComponent from "./helper-text-component";
import { textFieldHook } from "./useInput";

export const BaseInput = props => (
  <Input
    classes={{ root: "z-10" }}
    {...props}
  />
);

export const BaseFilledInput = props => {
  const inputStyles = textFieldHook.useFilledInputStyles();
  return (
    <FilledInput
      disableUnderline
      classes={inputStyles}
      {...props}
    />
  );
};

const BaseTextInput = ({
  label,
  touched,
  error,
  name,
  helperText,
  inputComponent,
  required,
  value,
  maxLength,
  disabled,
  labelProps,
  readOnly,
  loading,
  showIcon,
  icon,
  iconComponent,
  disabledValue,
  defaultActive,
  multiline,
  showHelperText,
  ...other
}) => {
  const hasError = !isEmpty(error);
  const length = maxLength && value ? value.length : 0;

  return (
    <FormContext.Consumer>
      {({ formDisabled, formFieldVariant }) => {
        const disabledInput = readOnly || disabled || formDisabled;
        const inputProps = {
          id: name,
          error: touched && hasError,
          value,
          multiline,
        };

        return (
          <BaseInputField
            error={touched && hasError}
            helperText={(
              <HelperTextComponent
                showError={touched && error}
                error={error}
                helperText={helperText}
                renderExtraContent={maxLength && <span>{`${length} / ${maxLength}`}</span>}
              />
            )}
            required={required && checkIfEmpty(value)}
            icon={icon}
            iconComponent={iconComponent}
            showIcon={showIcon}
            multiline={multiline}
            classes={{ root: "flex-1" }}
            showHelperText={showHelperText}
            variant={formFieldVariant === "filled" && !disabledInput ? "filled" : "standard"}
          >
            {disabledInput
              ? <DisabledInput label={label} value={disabledValue || value} />
              : (
                <>
                  <InputLabel
                    shrink={defaultActive}
                    htmlFor={name}
                    className={clsx(formFieldVariant === "standard" && "z-0")}
                    {...labelProps}
                  >
                    {label}
                  </InputLabel>
                  {inputComponent
                    ? inputComponent({ formDisabled, formFieldVariant })
                    : (formFieldVariant === "filled" ? <BaseFilledInput {...inputProps} {...other} /> : <BaseInput {...inputProps} {...other} />
                    )}
                  {loading && <LinearProgress />}
                </>
              )}
          </BaseInputField>
        );
      }}
    </FormContext.Consumer>
  );
};

export default BaseTextInput;
