import { get, find, take, orderBy, concat, filter } from "lodash";
import curryConnector from "utils/connectors";
import { addItem, updateItem, removeItem } from "app/main/patients/helpers/reducer-helper-functions";

export const PROBLEMS_STATE_KEY = "problems";

const curry = fn => curryConnector(fn, PROBLEMS_STATE_KEY);

export const ActionTypes = {
  LOADING_PROBLEMS: "LOADING_PROBLEMS",
  LOADED_PROBLEMS: "LOADED_PROBLEMS",
  ERROR_LOADING_PROBLEMS: "ERROR_LOADING_PROBLEMS",

  LOADING_CURRENT_PROBLEM: "LOADING_CURRENT_PROBLEM",
  ERROR_LOADING_CURRENT_PROBLEM: "ERROR_LOADING_CURRENT_PROBLEM",
  LOADED_CURRENT_PROBLEM: "LOADED_CURRENT_PROBLEM",

  SAVED_PROBLEM: "SAVED_PROBLEM",
  UPDATED_PROBLEM: "UPDATED_PROBLEM",
  RESOLVE_PROBLEM: "RESOLVE_PROBLEM",
  RENEW_PROBLEM: "RENEW_PROBLEM",
  CREATED_PROBLEM: "CREATED_PROBLEM",
  DELETED_PROBLEM: "DELETED_PROBLEM",
};

const INITIAL_STATE = {
  all: {},
  current: null,
};

const resolveOrRenewProblem = (state, patientId, data, entity) => {
  let arr = get(state.all, [patientId, entity], []);

  if (data.resolvedDateTimeUtc !== null) {
    // add resolved problems to the bottom of list
    arr = concat(filter(arr, x => x.id !== data.id), [data]);
  } else {
    // get problems without resolvedDate
    let unresolvedProblems = filter(arr, x => x.resolvedDateTimeUtc === null);
    unresolvedProblems = orderBy(concat(filter(unresolvedProblems, x => x.id !== data.id), [data]), [p => p.recordedDateTimeUtc] || "", ["desc"]);

    // concat the ordered unresolved problems with the resolved problems at the bottom
    arr = concat(unresolvedProblems, (filter(arr, x => x.id !== data.id && x.resolvedDateTimeUtc !== null)));
  }

  return {
    ...state,
    all: {
      ...state.all,
      [patientId]: {
        ...state.all[patientId],
        [entity]: arr,
      },
    },
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_PROBLEMS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: true } },
      };
    case ActionTypes.ERROR_LOADING_PROBLEMS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: false, error: action.payload.message } },
      };
    case ActionTypes.LOADED_PROBLEMS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]:
            {
              ...state.all[action.payload.patientId],
              loading: false,
              loaded: true,
              problems: action.payload.problems,
            },
        },
      };
    case ActionTypes.LOADING_CURRENT_PROBLEM:
      return {
        ...state,
        current: {
          ...state.current,
          loading: true,
        },
      };
    case ActionTypes.ERROR_LOADING_CURRENT_PROBLEM:
      return {
        ...state,
        current: {
          ...state.current,
          error: action.error,
        },
      };
    case ActionTypes.LOADED_CURRENT_PROBLEM:
      return {
        ...state,
        current: action.payload.problem,
      };
    case ActionTypes.SAVED_PROBLEM:
    case ActionTypes.CREATED_PROBLEM:
      return addItem(state, action.payload.patientId, action.payload.problem, "problems", false);
    case ActionTypes.UPDATED_PROBLEM:
      return updateItem(state, action.payload.patientId, action.payload.problem, "problems");
    case ActionTypes.DELETED_PROBLEM:
      return removeItem(state, action.payload.patientId, action.payload.problem, "problems");
    case ActionTypes.RENEW_PROBLEM:
    case ActionTypes.RESOLVE_PROBLEM:
      return resolveOrRenewProblem(state, action.payload.patientId, action.payload.problem, "problems");
    case ActionTypes.SET_CURRENT_PROBLEM:
      return {
        ...state,
        current: action.payload.problem,
      };
    default:
      return state;
  }
};

export const areProblemsLoading = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loading"], false));

export const areProblemsLoaded = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loaded"], false));

export const problemsLoadingError = curry(({ localState }, patientId) => get(localState, ["all", patientId, "error"], null));

export const getProblemsByPatientId = curry(({ localState }, patientId) => get(localState, ["all", patientId, "problems"], []));

export const getProblemById = curry(({ localState }, patientId, problemId) => {
  const problems = get(localState, ["all", patientId, "problems"], []);
  return find(problems, x => x.id === problemId) || {};
});

export const getCurrentProblem = curry(({ localState }) => get(localState, ["current"], null));

export const isCurrentProblemLoading = curry(({ localState }) => get(localState, ["current", "loading"], false));

export const getRecentProblems = curry(({ localState }, patientId, number) => {
  const problems = get(localState, ["all", patientId, "problems"], []);
  return take(problems, number);
});

export const getErrorMessage = curry(({ localState }, patientId) => get(localState, ["all", patientId, "error"], null));
