import moment from "moment-timezone";

import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import { isPageLoading, isPageLoaded, ActionTypes } from "../reducers/patientAccessLog.reducers";

export const resetPatientMergeLogsFilters = () => ({
  type: ActionTypes.RESET_PATIENT_ACCESS_LOGS_FILTERS,
});

export const fetchPatientAccessLogResults = (pageNumber, pageSize, searchParams, forceLoad = true) => dispatch => {
  const params = formatSearchParams(searchParams);
  const timeZone = moment.tz.guess();
  const url = `/patientAccess?pageNumber=${pageNumber}&pageSize=${pageSize}&timeZone=${timeZone}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "PATIENT_ACCESS_LOGS",
      pageNumber,
      bailout: state => isPageLoading(state, pageNumber) || (!forceLoad && isPageLoaded(state, pageNumber)),
    },
  });
};
