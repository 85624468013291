import { addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  addWeeks } from "date-fns";

const date = {
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),

  startOfWeek: addDays(startOfWeek(new Date()), 1), // without addDays 1, start of the week will be starting from Sunday instead of Monday
  endOfWeek: addDays(endOfWeek(new Date()), 1), // without addDays 1, end of the week will be ending on Saturday instead of Sunday
  startOfLastWeek: addDays(startOfWeek(addDays(new Date(), -7)), 1), // same as startOfWeek
  endOfLastWeek: addDays(endOfWeek(addDays(new Date(), -7)), 1), // same as endOfWeek
  startOfNextWeek: addDays(addWeeks(startOfWeek(new Date()), 1), 1),
  endOfNextWeek: addDays(addWeeks(endOfWeek(new Date()), 1), 1),

  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfNextMonth: addMonths(startOfMonth(new Date()), 1),
  endOfNextMonth: addMonths(endOfMonth(new Date()), 1),
};

export function getToday() {
  return [date.startOfToday, date.endOfToday];
}

export function getYesterday() {
  return [date.startOfYesterday, date.endOfYesterday];
}

export function getThisWeek() {
  return [date.startOfWeek, date.endOfWeek];
}

export function getLastWeek() {
  return [date.startOfLastWeek, date.endOfLastWeek];
}

export function getNextWeek() {
  return [date.startOfNextWeek, date.endOfNextWeek];
}

export function getThisMonth() {
  return [date.startOfMonth, date.endOfMonth];
}

export function getLastMonth() {
  return [date.startOfLastMonth, date.endOfLastMonth];
}

export function getNextMonth() {
  return [date.startOfNextMonth, date.endOfNextMonth];
}

export function getDateRanges(label) {
  if (!label) return null;
  switch (label) {
    case "today":
      return getToday();
    case "yesterday":
      return getYesterday();
    case "thisWeek":
      return getThisWeek();
    case "thisMonth":
      return getThisMonth();
    case "lastWeek":
      return getLastWeek();
    case "lastMonth":
      return getLastMonth();
    case "nextWeek":
      return getNextWeek();
    case "nextMonth":
      return getNextMonth();
    default:
      return null;
  }
}
