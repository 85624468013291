import CandidatesIndexPage from "./pages/candidates-index-page";

export const CandidatesRouteConfig = {
  routes: [
    {
      path: "/candidates",
      component: CandidatesIndexPage,
      exact: true,
      title: "Candidates",
      isIndexPage: true,
    },
  ],
};

export default CandidatesRouteConfig;
