import React from "react";
import { useDispatch } from "react-redux";
import { Field } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import moment from "moment-timezone";

import Form from "components/form";
import { DatePicker, UserSelector, NoteInput } from "components/inputs";
import { addReferralTask, editReferralTask, removeReferralTask } from "app/main/referrals/actions/referrals.actions";

const dueDate = "Due Date";
const description = "Task";
const assignedToUser = "Assign to";

const schema = Yup.object().shape({
  task: Yup.object().shape({
    isComplete: Yup.bool().default(false).nullable(),
    description: Yup.string()
      .trim().required("Please enter details of the task")
      .max(2000, `${description} exceeds maximum length of 2000 characters`)
      .nullable(),
    dueDate: Yup.date().default(null).nullable(),
    assignedToUserId: Yup.string().nullable(),
  }),
});

const ReferralTaskForm = ({
  referralId,
  currentTask,
  onSucceed,
  onRemoveTask,
  canDeleteTask,
  ...other
}) => {
  const dispatch = useDispatch();

  const handleSubmit = ({ task }, { setSubmitting, setErrors }) => {
    const apiCall = currentTask ? editReferralTask : addReferralTask;

    const newTask = {
      ...task,
      assignedToUserId: task.assignedToUserId?.value ?? task.assignedToUserId,
      assignedTo: task.assignedToUserId?.label ?? task.assignedTo,
      timeZone: moment.tz.guess(),
    };

    dispatch(apiCall(referralId, newTask)).then(responds => {
      setSubmitting(false);
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  const handleRemove = ({ setErrors }) => {
    dispatch(removeReferralTask(referralId, currentTask.referralTaskId)).then(responds => {
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  let { task } = schema.cast();

  if (!isEmpty(currentTask)) {
    task = {
      referralTaskId: currentTask.referralTaskId,
      dueDate: currentTask.dueDate,
      description: currentTask.description,
      assignedToUserId: currentTask.assignedToUserId,
      assignedTo: currentTask.assignedTo,
    };
  }

  return (
    <Form
      initialValues={{ task }}
      validationSchema={schema}
      onSubmit={handleSubmit}
      contentProps={other}
      onRemove={canDeleteTask ? formProps => handleRemove(formProps) : null}
      variant="filled"
      content={({ setFieldValue }) => (
        <>
          <Field
            name="task.description"
            component={NoteInput}
            label="Task"
            required
            autoFocus
          />
          <Field
            name="task.dueDate"
            component={DatePicker}
            label={dueDate}
          />
          <Field
            name="task.assignedToUserId"
            component={UserSelector}
            label={assignedToUser}
            onChange={option => setFieldValue("task.assignedTo", option?.label ?? "")}
          />
        </>
      )}
    />
  );
};

export default ReferralTaskForm;
