import { ApiActions } from "middleware/call-api";
import { get } from "lodash";
import { ActionTypes,
  isMedicationHistoryLoading,
  isMedicationHistoryLoaded,
  getMedicationEntryById,
  getMedicationProblemById,
  getMedicationInterventionById } from "../reducers/medicationHistory.reducers";

export const fetchPatientMedicationHistory = (patientId, assessmentItemId) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/medicationHistory/fetchPatientMedicationHistory/${assessmentItemId}`,
    bailout: state => isMedicationHistoryLoaded(state, patientId) || isMedicationHistoryLoading(state, patientId),
    entityType: "medication_history",
    patientId,
  },
});

export const setCurrentMedicationIntervention = medicationIntervention => ({
  type: ActionTypes.LOADED_CURRENT_MEDICATION_HISTORY_INTERVENTION,
  payload: { medicationIntervention },
});

export const setCurrentMedicationProblem = medicationProblem => ({
  type: ActionTypes.LOADED_CURRENT_MEDICATION_HISTORY_PROBLEM,
  payload: { medicationProblem },
});

export const setCurrentMedicationHistoryEntry = medicationHistoryEntry => ({
  type: ActionTypes.LOADED_CURRENT_MEDICATION_HISTORY_ENTRY,
  payload: { medicationHistoryEntry },
});

export const editExistingMedicationHistoryEntry = (patientId, medicationId) => (dispatch, getState) => {
  const medicationEntry = getMedicationEntryById(getState(), patientId, medicationId);
  dispatch(setCurrentMedicationHistoryEntry(medicationEntry));
};

export const editExistingMedicationHistoryProblem = (patientId, problemId) => (dispatch, getState) => {
  const medicationProblem = getMedicationProblemById(getState(), patientId, problemId);
  dispatch(setCurrentMedicationProblem(medicationProblem));
};

export const editExistingMedicationHistoryIntervention = (patientId, interventionId) => (dispatch, getState) => {
  const medicationIntervention = getMedicationInterventionById(getState(), patientId, interventionId);
  dispatch(setCurrentMedicationIntervention(medicationIntervention));
};

export const recordNewMedicationIntervention = () => setCurrentMedicationIntervention({
  id: null,
  priority: null,
  description: null,
  note: null,
});

export const recordNewMedicationProblem = () => setCurrentMedicationProblem({
  id: null,
  priority: null,
  description: null,
  note: null,
});

export const recordNewMedicationHistoryEntry = () => setCurrentMedicationHistoryEntry({
  id: null,
  term: null,
  form: null,
  isCurrent: true,
  strength: null,
  startDate: null,
  endDate: null,
  note: null,
});

export const addOrSaveMedicationHistoryEntry = (patientId, medicationHistoryEntry) => async dispatch => {
  const MEDICATION_HISTORY_ACTION = get(medicationHistoryEntry, "id", false)
    ? ["UPDATING_MEDICATION_HISTORY_ENTRY", ActionTypes.UPDATED_MEDICATION_HISTORY_ENTRY, "ERROR_UPDATING_MEDICATION_HISTORY_ENTRY"]
    : ["CREATING_MEDICATION_HISTORY_ENTRY", ActionTypes.CREATED_MEDICATION_HISTORY_ENTRY, "ERROR_CREATING_MEDICATION_HISTORY_ENTRY"];

  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/patient/${patientId}/medicationHistory/editMedicationHistory`,
      body: { medicationHistoryEntry },
      types: MEDICATION_HISTORY_ACTION,
      patientId,
    },
  });
};

export const addOrSaveMedicationHistoryIntervention = (patientId, interventionId, medicationHistoryIntervention) => async dispatch => {
  const MEDICATION_HISTORY_INTERVENTION = interventionId
    ? ["UPDATING_MEDICATION_HISTORY_INTERVENTION", ActionTypes.UPDATED_MEDICATION_HISTORY_INTERVENTION, "ERROR_UPDATING_MEDICATION_HISTORY_INTERVENTION"]
    : ["CREATING_MEDICATION_HISTORY_INTERVENTION", ActionTypes.CREATED_MEDICATION_HISTORY_INTERVENTION, "ERROR_CREATING_MEDICATION_HISTORY_INTERVENTION"];

  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/patient/${patientId}/medicationHistory/recordIntervention`,
      body: { medicationHistoryIntervention },
      types: MEDICATION_HISTORY_INTERVENTION,
      patientId,
    },
  });
};

export const addOrSaveMedicationHistoryProblem = (patientId, problemId, medicationHistoryProblem) => async dispatch => {
  const MEDICATION_HISTORY_PROBLEM = problemId
    ? ["UPDATING_MEDICATION_HISTORY_PROBLEM", ActionTypes.UPDATED_MEDICATION_HISTORY_PROBLEM, "ERROR_UPDATING_MEDICATION_HISTORY_PROBLEM"]
    : ["CREATING_MEDICATION_HISTORY_PROBLEM", ActionTypes.CREATED_MEDICATION_HISTORY_PROBLEM, "ERROR_CREATING_MEDICATION_HISTORY_PROBLEM"];

  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/patient/${patientId}/medicationHistory/recordProblem`,
      body: { medicationHistoryProblem },
      types: MEDICATION_HISTORY_PROBLEM,
      patientId,
    },
  });
};

export const recordMedicationHistoryAction = (patientId, medicationHistoryAction) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${patientId}/medicationHistory/action`,
    body: { medicationHistoryAction },
    types: ["UPDATING_MEDICATION_HISTORY_ACTION", ActionTypes.UPDATED_MEDICATION_HISTORY_ACTION, "ERROR_UPDATING_MEDICATION_HISTORY_ACTION"],
    patientId,
  },
});

export const onMedicationHistoryNotRequired = (patientId, assessmentItem) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${patientId}/medicationHistory/setMedicationHistoryNotRequired`,
    body: { assessmentItem },
    types: ["UPDATING_MEDICATION_HISTORY_STATUS", ActionTypes.UPDATED_MEDICATION_HISTORY_STATUS, "ERROR_UPDATING_MEDICATION_HISTORY_STATUS"],
  },
});

export const onMedicationHistoryRequired = (patientId, assessmentItem) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${patientId}/medicationHistory/setMedicationHistoryRequired`,
    body: { assessmentItem },
    types: ["UPDATING_MEDICATION_HISTORY_STATUS", ActionTypes.UPDATED_MEDICATION_HISTORY_STATUS, "ERROR_UPDATING_MEDICATION_HISTORY_STATUS"],
  },
});
