import CandidateWorklistPage from "./pages/candidate-worklist-page";
import PatientCandidatePage from "./pages/candidate-patient-page";

export const CandidateWorklistConfig = {
  routes: [
    {
      title: "Candidate Worklist",
      path: "/candidateWorklist",
      component: CandidateWorklistPage,
      exact: true,
      isIndexPage: true,
    },
    {
      path: "/candidateWorklist/patients/:patientId",
      component: PatientCandidatePage,
      exact: true,
    },
  ],
};

export default CandidateWorklistConfig;
