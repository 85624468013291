import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flex: "1 1 auto",
    justifyContent: "space-between",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1 / 2),
    },
  },
  helperText: {
    color: theme.palette.text.secondary,
  },
}));

export default ({
  helperText,
  error,
  showError,
  renderExtraContent,
}) => {
  const classes = useStyles();

  return (
    <span className={classes.container}>
      <span className="flex flex-col flex-1">
        {showError && <span>{error}</span>}
        <span className={classes.helperText}>{helperText}</span>
      </span>
      {renderExtraContent && renderExtraContent}
    </span>
  );
};
