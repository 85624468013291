import React from "react";
import { withStyles, Typography, Grid } from "@material-ui/core";
import { connect } from "react-redux";

import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import DefaultPanelLayout from "components/items/default-panel-layout";
import formatDate from "helpers/format-date";
import formatDaysFromNow from "helpers/format-days-from-now";
import UrgentStatus from "app/main/referrals/components/urgent-status";
import withPermissions from "permissions/withPermissions";
import DefaultItem, { ContextItem } from "components/items/default-item";
import ReferralCategoryLabel from "app/main/referrals/components/referral-category-label";
import DefaultButton from "components/items/default-button";
import ReferralAccessLogDialog from "app/main/referrals/components/referral-access-log-dialog";
import { ReferralOrgIcon } from "helpers/icon-finder";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",

    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
      flexWrap: "nowrap",
    },
    "& > div": {
      flex: "1 1 auto",
      minWidth: 230,
      [theme.breakpoints.up("sm")]: {
        minWidth: "auto",
      },
      "&:not(:first-child)": {
        borderTopWidth: 1,
        borderTopColor: theme.palette.divider,
        paddingTop: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
          borderTopWidth: 0,
          borderLeftWidth: 1,
          borderLeftColor: theme.palette.divider,
          paddingLeft: theme.spacing(2),
          paddingTop: 0,
        },
      },
    },
  },
  assignedTo: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
});
class ReferralWorkListPanel extends React.PureComponent {
  onClose = () => {
    this.props.closeDialog();
  };

  isReferralAssigned = () => this.props.referral.assignedToUserId || this.props.referral.assignedToId

  render() {
    const {
      referral,
      classes,
      hasPermissionReferralAccessLog,
    } = this.props;

    const {
      triageCategory,
      isUrgent,
      referralDocumentUrgency,
      // assigned to required params
      referredToServiceDescription,
      referrerFullName,
      referrerPhone,
      referrerOrganisationName,
      referralDateUtc,
      referralPeriod,
      referrerProviderNumber,
      assignedToDisplayName,
      interpreterRequired,
      preferredLanguage,
      id,
      patient,
    } = referral;

    let StatusComponent = () => null;

    if (triageCategory || isUrgent) {
      StatusComponent = () => (
        <div className="flex-row-container">
          {triageCategory && <ReferralCategoryLabel triageCategory={triageCategory} isUrgent={isUrgent} />}
          {isUrgent && (<UrgentStatus iconOnly />)}
        </div>
      );
    }

    return (
      <DefaultPanelLayout
        title="Referral Summary"
        icon="perm_contact_calendar"
        status={<StatusComponent />}
        headerContent={(
          <ContextItem
            label="For"
            content={assignedToDisplayName ?? referredToServiceDescription}
            renderIfEmpty
            labelClassName="w-40"
          />
        )}
        headerRightContent={hasPermissionReferralAccessLog && <DefaultButton fab label="Referral Access Log" onClick={() => this.props.openDialog({ children: <ReferralAccessLogDialog referralId={id} /> })} />}
      >
        <div className={classes.container}>
          <div className="items-container sm">
            <ContextItem
              label="From"
              content={`${referrerFullName ?? ""}${referrerProviderNumber ? ` (${referrerProviderNumber})` : ""}`}
              renderIfEmpty
            />
            <ContextItem icon="call" content={referrerPhone} renderIfEmpty />
            <ContextItem icon={<ReferralOrgIcon />} content={referrerOrganisationName} renderIfEmpty />
          </div>
          <div className="mt-24 md:mt-0 items-container sm">
            {referralDocumentUrgency && (
              <DefaultItem
                icon={<UrgentStatus iconOnly />}
                content={<UrgentStatus labelOnly labelClassName="font-bold" />}
              />
            )}
            <DefaultItem
              icon="calendar_today"
              content={(
                <Grid>
                  <Typography variant="subtitle2">REFERRED ON</Typography>
                  <Grid container alignItems="center">
                    <Typography variant="caption" className="mr-4">{formatDate(referralDateUtc)}</Typography>
                    <Typography variant="caption">({formatDaysFromNow(referralDateUtc)})</Typography>
                  </Grid>
                </Grid>
              )}
            />
            <DefaultItem
              icon="access_time"
              content={(
                <Grid>
                  <Typography variant="subtitle2">REFERRAL PERIOD</Typography>
                  <Grid container alignItems="center">
                    <Typography variant="caption">{referralPeriod || "Not Recorded."}</Typography>
                  </Grid>
                </Grid>
              )}
            />
          </div>
          <div className="mt-24 md:mt-0 items-container sm">
            <DefaultItem
              label="NOK"
              content={(
                patient?.nextOfKin
                  ? (
                    <Grid container direction="column">
                      <Typography variant="caption">{patient.nextOfKin.givenName} {patient.nextOfKin.familyName}</Typography>
                      {patient.nextOfKin.contact && <Typography variant="caption">{patient.nextOfKin.contact}</Typography>}
                    </Grid>
                  )
                  : <Typography>Not Recorded</Typography>
              )}
            />
            {interpreterRequired && <DefaultItem icon="pan_tool" content="Interpreter Required" />}
            {preferredLanguage && <DefaultItem icon="language" content={preferredLanguage} />}
          </div>
        </div>
      </DefaultPanelLayout>
    );
  }
}

export default withPermissions("ReferralNotesCreate", "ReferralCorrespondenceCreate", "ReferralAccessLog")(withStyles(styles, { withTheme: true })(connect(
  null,
  {
    openDialog,
    closeDialog,
  },
)(ReferralWorkListPanel)));
