import { green, grey, red, blue } from "@material-ui/core/colors";

export default function getPriorityColour(priority) {
  switch (priority) {
    case "Low":
      return {
        color: green[400],
      };
    case "Normal":
      return {
        color: blue[600],
      };
    case "High":
      return {
        color: red[600],
      };
    default:
      return {
        color: grey[600],
      };
  }
}
