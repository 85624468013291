import React from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import { map, find, filter, lowerCase, isEmpty, concat, size } from "lodash";
import { withStyles, withTheme, fade } from "@material-ui/core/styles";
import { Typography, Icon } from "@material-ui/core";
import clsx from "clsx";
import { Alert } from "@material-ui/lab";

import Checkbox from "components/inputs/checkbox";
import Form from "components/form";
import { updateSurveyList, printAssessmentItems, fetchAssessmentPrintHistory } from "app/main/patients/actions/assessments.actions";
import getSurveyItemIcon from "app/main/patients/helpers/get-survey-item-icon";
import getAssessmentStatus from "app/main/patients/helpers/get-assessment-status";
import SearchInput from "components/inputs/search-input";
import { closeDialog } from "app/store/actions/dialog.actions";

const styles = theme => ({
  dummyTile: {
    width: "calc(33.33% - 8px)",
    height: 80,
  },
  buttonContainer: {
    width: "calc(33.33% - 8px)",
    height: 80,
    display: "flex",
    justifyContent: "center",
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: 4,
    borderRadius: 4,
    boxSizing: "border-box",
    "&:not(:nth-child(3n))": {
      marginRight: 4,
    },
    "&:hover": {
      backgroundColor: theme.palette.background.control,
    },
    "& > *": {
      width: "100%",
      "&.is-checked": {
        backgroundColor: theme.palette.primary[50],
      },
      "& label": {
        display: "flex",
        justifyContent: "center",
        height: "100%",
        marginLeft: 0,
        marginRight: 0,
        position: "relative",
      },
      "& .checkbox-control": {
        position: "absolute",
        right: 0,
        top: 0,
      },
    },
    "&.highlight": {
      border: `2px solid ${theme.palette.primary[500]}`,
      backgroundColor: fade("#f8ff00", 0.3),
    },
  },
  button: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  searchBox: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.control,
  },
});

const printMessage = "This assessment has not been completed, so the information available may change subsequent to printing";

class SurveySelectForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      selectedSurveys: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = ({ surveys }, { setErrors }) => {
    const { isPrinting, patientId, currentAssessmentId } = this.props;
    const newSurveyListModel = isPrinting
      ? {
        patientId,
        currentAssessmentId,
        surveys,
      } : {
        assessmentId: currentAssessmentId,
        surveySwitches: surveys,
      };

    const apiCall = isPrinting ? this.props.printAssessmentItems : this.props.updateSurveyList;

    apiCall(newSurveyListModel).then(response => {
      if (response.ok || !response.error) {
        if (isPrinting) {
          // Update the print history for this assessment
          this.props.fetchAssessmentPrintHistory({ patientId, assessmentId: currentAssessmentId });
        }
        this.props.onSucceed();
      } else {
        this.props.closeDialog();
        setErrors("An error has occurred");
      }
    });
  };

  renderItem = item => {
    const { classes, theme: { palette: { text } }, isPrinting } = this.props;
    const { selectedSurveys } = this.state;
    const { surveyType, displayName, status, groupName, highlight } = item;
    const assessmentStatus = getAssessmentStatus({ status });

    const textColor = status === "Completed" || status === "InProgress" || status === "NotRequired" ? assessmentStatus.color : text.secondary;
    const disabled = status === "Completed" || status === "InProgress" || groupName === "FollowUpAssessment";

    return (
      <div key={`surveys.${surveyType}`} className={clsx(classes.buttonContainer, highlight && "highlight")}>
        <Field
          name={`surveys.${surveyType}`}
          label={(
            <div className={classes.button}>
              <Icon style={{ color: textColor }}>{getSurveyItemIcon(surveyType)}</Icon>
              <Typography variant="caption" className="mt-8" style={{ color: textColor }}>{displayName}</Typography>
            </div>
          )}
          component={Checkbox}
          showIcon={false}
          labelPlacement="bottom"
          classes={{ root: "h-full", container: "h-full" }}
          disabled={isPrinting ? false : disabled}
          onChange={value => {
            if (value === true) {
              this.setState({ selectedSurveys: concat(selectedSurveys, surveyType) });
            } else {
              this.setState({ selectedSurveys: filter(selectedSurveys, x => x !== surveyType) });
            }
          }}
        />
      </div>
    );
  }

  // add empty blocks to tiles so they will have same block in each row
  normaliseItems = (items, length) => {
    const { classes } = this.props;
    const arr = items;

    if (items.length % length !== 0) {
      for (let i = items.length % length; i < length; i += 1) {
        arr.push(<div key={i} className={classes.dummyTile} />);
      }
    }

    return arr;
  }

  onSearch = e => {
    this.setState({ searchText: e.target.value });
  }

  render() {
    const {
      initialValues,
      classes,
      surveySettings,
      assessmentItems,
      isPrinting,
      isAssessmentCompleted,
      ...other
    } = this.props;

    const { searchText, selectedSurveys } = this.state;
    // Either construct list of all surveys with their status (manage) or only completed surveys (print)
    let surveyList = map(isPrinting ? assessmentItems : surveySettings, item => {
      const setting = find(surveySettings, s => s.surveyType === item.surveyType);
      const filterMatch = searchText !== "" && lowerCase(setting.displayName).indexOf(lowerCase(searchText)) !== -1;
      const match = find(assessmentItems, x => x.surveyType === item.surveyType);

      return {
        ...setting,
        status: match ? match.status : false,
        highlight: filterMatch,
      };
    });
    // TODO, once "enabled" setting is working, it should filter out the items that enabled = true
    // Temp exclude MedicalHistory from the selection list (old survey)
    surveyList = filter(surveyList, x => x.surveyType !== "MedicalHistory");

    const availableSurvey = isPrinting ? map(surveyList, survey => survey.surveyType)
      : map(filter(surveyList,
        x => x.status !== "Completed"
      && x.status !== "InProgress"
      && x.groupName !== "FollowUpAssessment"), survey => survey.surveyType);

    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        disabled={isPrinting && isEmpty(selectedSurveys)}
        enableReinitialize
        contentProps={other}
        content={({ setFieldValue }) => (
          <>
            {(isPrinting && !isAssessmentCompleted) && (
              <Alert severity="warning" icon={false}>
                {printMessage}
              </Alert>
            )}
            <div className={clsx(classes.searchBox, "flex items-center mb-8 mx-8")}>
              <Field
                name="searchSurvey"
                component={SearchInput}
                onChange={this.onSearch}
                value={searchText}
              />
              <Field
                name="allTypes"
                label="Select All"
                component={Checkbox}
                showIcon={false}
                checked={size(selectedSurveys) === size(availableSurvey)}
                onChange={value => {
                  if (value) {
                    map(availableSurvey, type => setFieldValue(`surveys.${type}`, true));
                    this.setState({ selectedSurveys: map(availableSurvey, type => type) });
                  } else {
                    map(availableSurvey, type => setFieldValue(`surveys.${type}`, false));
                    this.setState({ selectedSurveys: [] });
                  }
                }}
              />
            </div>
            <div className={clsx("flex flex-row flex-wrap justify-center", classes.container)}>
              {this.normaliseItems(map(surveyList, item => this.renderItem(item)), 3)}
            </div>
          </>
        )}
      />
    );
  }
}

export default withTheme(connect(null, {
  closeDialog,
  updateSurveyList,
  printAssessmentItems,
  fetchAssessmentPrintHistory,
})(withStyles(styles)(SurveySelectForm)));
