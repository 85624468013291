import { ActionTypes } from "../reducers/sidebars.reducer";

export const setMobileContentSidebarStatus = status => dispatch => {
  dispatch({
    type: ActionTypes.SET_MOBILE_CONTENT_SIDEBAR_STATUS,
    payload: status,
  });
};

export const setSideBarStatus = status => dispatch => {
  dispatch({
    type: ActionTypes.SET_SIDEBAR_STATUS,
    payload: status,
  });
};
