import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import AccountEdit from "mdi-react/AccountEditIcon";
import AccountCancel from "mdi-react/AccountCancelIcon";
import AccountCheck from "mdi-react/AccountCheckIcon";

import DefaultPageLayout from "components/layouts/default-page-layout";
import ScreenHeader from "components/items/screen-header";
import withPermissions from "permissions/withPermissions";
import DefaultButton from "components/items/default-button";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import ConfirmationDialog from "components/items/confirmation-dialog";
import formatDate from "helpers/format-date";
import { getSignedInOrgUnit, getUser } from "app/auth/store/reducers/user.reducer";
import useSetUrl from "app/main/admin/hooks/useSetUrl";

import { getExistingUserById, resetCurrentUser, deactivateUser, activateUser } from "../actions/users.actions";
import UserForm from "../components/user-form";
import { getCurrentUser, isCurrentUserLoading } from "../reducers/users.reducers";

const UserEditPage = ({
  match: { params: { id } },
  hasPermissionUsersMaintain,
  hasPermissionUsersMaintainDetail,
  route,
}) => {
  const dispatch = useDispatch();
  const loginUser = useSelector(getUser);
  const currentUser = useSelector(getCurrentUser);
  const loading = useSelector(isCurrentUserLoading);
  const signedInOrgUnit = useSelector(getSignedInOrgUnit);
  const [disabled, setDisabled] = useState(true);
  const enableEdit = hasPermissionUsersMaintain;
  const enableUpdateUserStatus = hasPermissionUsersMaintainDetail && loginUser.userId !== currentUser.userId;

  useSetUrl(route);

  const isActive = isEmpty(currentUser.inactiveDateTimeUtc);

  useEffect(() => {
    // only call this when current user is empty and is not loading
    // needed when page refreshes
    if (!loading && isEmpty(currentUser)) {
      dispatch(getExistingUserById(signedInOrgUnit?.id, id));
    }
    // clear current user
    return () => dispatch(resetCurrentUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => dispatch(closeDialog());

  const onUpdateUserStatus = () => dispatch(openDialog({
    disableBackdropClick: false,
    maxWidth: "xs",
    children: (
      <ConfirmationDialog
        type="warning"
        title={`${isActive ? "Deactivate" : "Activate"} user`}
        content={`Are you sure you want to ${isActive ? "deactivate" : "activate"} this user?`}
        onConfirm={() => {
          const apiCall = isActive ? deactivateUser : activateUser;

          dispatch(apiCall(currentUser.userId)).then(() => {
            onClose();
            setDisabled(true);
          });
        }}
        onCancel={onClose}
      />
    ),
  }));

  return (
    <DefaultPageLayout
      loading={loading}
      accessDenied={!enableEdit}
      header={(
        <ScreenHeader
          title={get(currentUser, "name")}
          subtitle={!isActive ? `Deactivated Date: ${formatDate(currentUser.inactiveDateTimeUtc)}` : ""}
          renderActions={enableEdit && (
            <>
              {enableUpdateUserStatus && (
                <DefaultButton
                  label={isActive ? "Deactivate" : "Activate"}
                  variant="outlined"
                  color="inherit"
                  icon={isActive ? <AccountCancel /> : <AccountCheck />}
                  onClick={onUpdateUserStatus}
                />
              )}
              <DefaultButton label="Edit" variant="outlined" color="inherit" icon={<AccountEdit />} onClick={() => setDisabled(false)} disabled={!disabled} />
            </>
          )}
        />
      )}
      content={(
        <UserForm
          user={currentUser}
          isEdit
          onCancel={() => setDisabled(true)}
          onSucceed={() => setDisabled(true)}
          disabled={disabled}
        />
      )}
    />
  );
};

export default withPermissions("UsersMaintain", "UsersMaintainDetail")(UserEditPage);
