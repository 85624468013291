import { createMuiTheme } from "@material-ui/core/styles";
import { merge } from "lodash";
import ThemesConfig, { mustHaveThemeOptions } from "../../theme/themesConfig";

export const SETTINGS_STATE_KEY = "settings";

export const ActionTypes = {
  SET_CURRENT_THEME: "SET_CURRENT_THEME",
  SET_DEFAULT_THEME: "SET_DEFAULT_THEME",
};

/**
 * THEMES
 */
function getInitialThemes() {
  const themesObj = Object.keys(ThemesConfig).length !== 0 ? ThemesConfig : ThemesConfig.default;
  return Object.assign({}, ...Object.entries(themesObj).map(([key, value]) => {
    const muiTheme = merge({}, mustHaveThemeOptions, value);
    return {
      [key]: createMuiTheme(merge({}, muiTheme)),
    };
  }));
}

const initialThemes = getInitialThemes();

const initialState = {
  themes: initialThemes,
  currentTheme: initialThemes.default,
  currentThemeName: "default",
  meta: {
    loading: true,
    loaded: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_THEME:
      return {
        ...state,
        currentTheme: state.themes[action.payload.name] || initialThemes.default,
        currentThemeName: action.payload.name ?? "default",
      };
    case ActionTypes.SET_DEFAULT_THEME:
      return {
        ...state,
        currentTheme: initialThemes.default,
        meta: {
          loading: false,
          loaded: true,
        },
      };
    default:
      return state;
  }
};
