import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { apiMiddleware } from "redux-api-middleware";
import { composeWithDevTools } from "redux-devtools-extension";
import createRootReducer from "app/store/reducers";
import fixApiEndpoint from "middleware/fix-api-endpoint";
import { makeApiCall, fetchFromApi, saveToApi, deleteFromApi, postToApi } from "middleware/call-api";

const appliedMiddleware = applyMiddleware(
  thunk,
  fetchFromApi,
  saveToApi,
  postToApi,
  deleteFromApi,
  fixApiEndpoint,
  makeApiCall,
  apiMiddleware,
);

// disable Redux Devtool in production
const composedEnhancers = process.env.NODE_ENV === "production"
  ? appliedMiddleware
  : composeWithDevTools(appliedMiddleware);

const store = createStore(
  createRootReducer(), // root reducer with router state
  composedEnhancers,
);

export default store;
