import React from "react";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { isCurrentUserLoading } from "app/auth/store/reducers/user.reducer";
import { AccessDeniedState } from "components/items/empty-state";
import LoadingState from "components/items/loading-state";
import withPermissions from "permissions/withPermissions";
import { isSystemConfigurationLoaded } from "app/auth/store/reducers/system-configuration";
import { setUpReferralWorklistFilter } from "app/main/referralWorklist/actions/referralWorklist.actions";
import { areFiltersLoaded } from "app/main/referralWorklist/reducers/referralWorklist.reducers";
import { fetchAllCodeSetValues } from "app/main/codeSet/actions/codeSet.actions";

import ReferralWorklistPage from "./referral-worklist-page";

const SecureReferralWorklistPage = ({ location, hasPermissionReferralsView }) => {
  const dispatch = useDispatch();
  const loading = useSelector(isCurrentUserLoading);
  const isSystemConfigLoaded = useSelector(isSystemConfigurationLoaded);
  const filtersLoaded = useSelector(areFiltersLoaded);
  const forceLoad = get(location, ["state", "forceLoad"], false);
  const [isLoadingSettings, setLoadingSettings] = React.useState(true);

  React.useEffect(() => {
    if (isSystemConfigLoaded && !filtersLoaded) {
      dispatch(setUpReferralWorklistFilter());
      setLoadingSettings(false);
      // load all codeSet values for display and used in selectors
      dispatch(fetchAllCodeSetValues());
    } else if (filtersLoaded) {
      setLoadingSettings(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSystemConfigLoaded]);

  if (loading || isLoadingSettings) return <LoadingState />;

  if (!hasPermissionReferralsView) return <AccessDeniedState />;

  return <ReferralWorklistPage forceLoad={forceLoad} />;
};

export default withPermissions("ReferralsView")(SecureReferralWorklistPage);
