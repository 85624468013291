import React from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { uploadIPABlankForm, removeIPABlankForm } from "app/main/orgUnits/actions/orgUnit.actions";
import { getIPABlankFormAcceptFormatAcceptFormat } from "app/auth/store/reducers/system-configuration";
import downloadFromApi from "utils/download-from-api";

import { getCurrentIPABlankForm, isCurrentOrgUnitIPABlankFormLoading } from "app/main/orgUnits/reducers/orgUnit.reducers";
import OrgUnitFileAttachmentPage from "./orgUnit-file-attachment-page";

const OrgUnitIPABlankFormPageContent = ({
  orgUnit,
  label,
}) => {
  const currentAttachment = useSelector(getCurrentIPABlankForm);
  const ipaBlankFormAcceptFormat = useSelector(getIPABlankFormAcceptFormatAcceptFormat);
  const isCurrentIPABlankFormLoading = useSelector(isCurrentOrgUnitIPABlankFormLoading);

  const orgUnitId = !isEmpty(orgUnit) ? orgUnit.id : null;

  return (
    <OrgUnitFileAttachmentPage
      label={label}
      currentAttachment={currentAttachment}
      loading={isCurrentIPABlankFormLoading}
      orgUnitId={orgUnitId}
      onDownloadFile={() => downloadFromApi(`api/orgUnits/${orgUnitId}/downloadIPABlankForm`)}
      onRemoveFile={removeIPABlankForm}
      onUploadFile={uploadIPABlankForm}
      acceptFormat={ipaBlankFormAcceptFormat}
    />
  );
};

export default OrgUnitIPABlankFormPageContent;
