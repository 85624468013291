import React from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { isCurrentUserLoaded } from "app/auth/store/reducers/user.reducer";
import { AccessDeniedState } from "components/items/empty-state";
import LoadingState from "components/items/loading-state";
import withPermissions from "permissions/withPermissions";
import ApplicationWorklistPage from "./application-worklist-page";

const SecureApplicationWorklistPage = ({ loading, location, history, hasPermissionIPAWorklistView }) => {
  const forceLoad = get(location, ["state", "forceLoad"], false);

  if (loading) return (<LoadingState />);

  if (!hasPermissionIPAWorklistView) return <AccessDeniedState />;

  return <ApplicationWorklistPage forceLoad={forceLoad} history={history} />;
};

const mapStateToProps = state => ({
  loading: isCurrentUserLoaded(state) === false,
});

export default withPermissions("IPAWorklistView")(connect(
  mapStateToProps,
  {
    isCurrentUserLoaded,
  },
)(SecureApplicationWorklistPage));
