import React from "react";
import { Typography, Box } from "@material-ui/core";
import clsx from "clsx";
import IconComponent from "components/items/icon-component";

const TextLink = ({
  icon, label, content, children, onClick, iconSize, variant, disabled, classes, ...other
}) => {
  const LinkComponent = () => (
    <div
      onClick={disabled ? null : onClick}
      role="presentation"
      className={clsx(!disabled && onClick ? "cursor-pointer" : "cursor-default", classes?.content)}
    >
      <Typography
        variant={variant || "body1"}
        color={!disabled && onClick ? "primary" : "inherit"}
        {...other}
      >
        {content || children}
      </Typography>
    </div>

  );

  if (icon || label) {
    return (
      <Box className={clsx("flex md:items-center", classes?.root)}>
        <Box className="flex items-center mr-4">
          {icon && <IconComponent icon={icon} />}
          {label && <Typography className="font-bold" variant={variant || "body1"}>{label}</Typography>}
        </Box>
        <LinkComponent />
      </Box>
    );
  }

  return (
    <LinkComponent />
  );
};

export default TextLink;
