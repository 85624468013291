/* eslint-disable func-names */
import * as Yup from "yup";
import validator from "au-bn-validator";
import checkIfEmpty from "helpers/check-if-empty";
import { isBoolean } from "lodash";
import { validateIdentifiers } from "./validators";

/**
 * Keep in mind that ES6 arrow function syntax cannot be used here as it retains the [this] from the enclosing lexical context.
 * You won’t be able to access methods and variables exposed to you by Yup unless you use older, more traditional function() {...} syntax.
 *
 * more info about add method: https://medium.com/@arkadyt/how-does-yup-addmethod-work-creating-custom-validation-functions-with-yup-8fddb71a5470
 */

Yup.addMethod(Yup.string, "validateRequired", function(name) {
  return this.test("validateRequired", name, function(value) {
    const { path, createError } = this;

    if (checkIfEmpty(value)) {
      return createError({ path, message: `${name} is required` });
    }
    return true;
  });
});

Yup.addMethod(Yup.string, "validateMedicareNumber", function(name) {
  return this.test("validateMedicareNumber", name, function(value) {
    const { path, createError } = this;

    if (value) {
      const isValid = validator.validateMedicareNumber(value);

      if (!isValid) {
        return createError({ path, message: `${name} is invalid` });
      }
    }

    return true;
  });
});

Yup.addMethod(Yup.string, "validateIdentifier", function(name, options) {
  return this.test("validateIdentifier", name, function(value) {
    const { path, createError } = this;
    const validateMessage = validateIdentifiers(name, value, options);

    if (!checkIfEmpty(validateMessage)) {
      return createError({ path, message: `${validateMessage}` });
    }

    return true;
  });
});

Yup.addMethod(Yup.mixed, "fieldSettingsValidation", function(name, options) {
  return this.test("validateRequired", name, function(value) {
    const { path, createError } = this;

    if (options?.required && isBoolean(this.options.originalValue)) {
      return true;
    }

    if (options?.required && checkIfEmpty(value)) {
      return createError({ path, message: `${name} is required` });
    }
    // add other validations here

    return true;
  });
});

Yup.addMethod(Yup.mixed, "validateLink", function(name, isOptional) {
  return this.test("validateRequired", name, function(value) {
    const { path, createError } = this;

    if (!isOptional && !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?\s*$/.test(value)) {
      return createError({ path, message: "This does not look like a valid URL." });
    }

    if (!isOptional && checkIfEmpty(value)) {
      return createError({ path, message: "Please enter a url" });
    }
    // add other validations here

    return true;
  });
});

Yup.addMethod(Yup.mixed, "fileAttachmentRequired", function(name, isOptional) {
  return this.test("validateRequired", name, function(value) {
    const { path, createError } = this;

    if (!isOptional && checkIfEmpty(value)) {
      return createError({ path, message: "Please choose a file" });
    }
    // add other validations here

    return true;
  });
});

Yup.addMethod(Yup.number, "validateDecimalNumber", function(name, regexPattern) {
  return this.test(
    "validateDecimalNumber",
    `${name} must be less than 1,000,000,000`,
    value => {
      if (!checkIfEmpty(value)) {
        const regex = new RegExp(regexPattern ?? /(^\d{0,9}(?!\.)$)|(^\d{0,9}\.\d{1,2}$)/);
        return regex.test(value);
      }
      return true;
    },
  );
});

Yup.addMethod(Yup.number, "validateDecimalNumberDecimalValues", function(name, regexPattern) {
  return this.test(
    "validateDecimalNumberDecimalValues",
    `${name} can only have up to 2 decimal places`,
    value => {
      if (!checkIfEmpty(value)) {
        const regex = new RegExp(regexPattern ?? /^(\d*(?:,\d{1,2})?(.\d{0,16}e-|.\d{0,16}e\+|.\d{0,16}e|).\d{0,2})$/);
        return regex.test(value);
      }
      return true;
    },
  );
});
