import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdvancedFilter, { FilterTags, FilterSort } from "components/items/advanced-filter";

import { getFilterOptions, getSearchTags } from "app/main/referralWorklist/reducers/referralWorklist.reducers";
import DefaultSelectionFilter from "components/items/default-selection-filter";
import { updateReferralWorkListFilter, resetReferralWorklistFilter } from "app/main/referralWorklist/actions/referralWorklist.actions";
import ReferralAdvancedFilterForm from "app/main/referralWorklist/components/referral-advanced-filter-form";
import translateReferralSearchParams from "app/main/referralWorklist/helpers/translate-search-params";

const ReferralAdvancedFilter = ({
  searchParams,
  onSearch,
  presetFilter,
  referralStatusChoices,
  triageCategoryChoices,
  referralWorkflowSettings,
  triageCategorySettings,
  initialValues,
  ...other
}) => {
  const dispatch = useDispatch();
  const filterOptions = useSelector(getFilterOptions);
  const tags = useSelector(getSearchTags);
  const [sortOrderDesc, setSortOrderDesc] = useState(!searchParams.showOldestFirst);
  const [isSearching, setIsSearching] = useState(false);

  const handleSubmit = ({ referralFilters }) => {
    const newReferralFilters = translateReferralSearchParams(referralFilters);
    const newParams = { ...searchParams, ...newReferralFilters };
    dispatch(updateReferralWorkListFilter("advancedFilter", true));
    onSearch(newParams, null, null, true);
  };

  const handleReset = () => dispatch(resetReferralWorklistFilter()).then(res => {
    const params = translateReferralSearchParams(res.payload.searchParams);
    onSearch(params, null, null, true);
    setSortOrderDesc(!params.showOldestFirst);
  });

  const onSearchText = text => {
    const params = translateReferralSearchParams(searchParams);
    onSearch({ ...params, term: text }, null, null, true);
  };

  const onUpdateFilter = filterValue => {
    dispatch(updateReferralWorkListFilter(filterValue)).then(res => {
      onSearch(res, null, null, true);
    });
  };

  const handleSortOrderChange = () => {
    setSortOrderDesc(!sortOrderDesc);
    setIsSearching(true);
    const params = translateReferralSearchParams(searchParams);
    onSearch({ ...params, showOldestFirst: sortOrderDesc }, null, null, true).then(() => setIsSearching(false));
  };

  return (
    <AdvancedFilter
      {...other}
      onSearch={searchText => onSearchText(searchText)}
      searchValue={searchParams?.term ?? ""}
      renderFilterTags={<FilterTags tags={tags} />}
      renderPresetFilter={({ openAdvancedFilter }) => (
        <div className="mb-8">
          <DefaultSelectionFilter
            filterValue={presetFilter}
            options={filterOptions}
            onChange={
              filterValue => {
                if (filterValue && presetFilter !== filterValue) {
                  if (filterValue === "advancedFilter") {
                    openAdvancedFilter();
                  } else {
                    onUpdateFilter(filterValue);
                  }
                }
              }
            }
            renderExtraActions={(
              <FilterSort
                sortType="date"
                sortOrder={sortOrderDesc ? "desc" : "asc"}
                onClick={handleSortOrderChange}
                sortDescendingLabel="Referral Date - Newest First"
                sortAscending="Referral Date - Oldest First"
                isLoading={isSearching}
              />
            )}
          />
        </div>
      )}
      content={onClose => (
        <ReferralAdvancedFilterForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          handleReset={handleReset}
          onClose={onClose}
          referralStatusChoices={referralStatusChoices}
          triageCategoryChoices={triageCategoryChoices}
          referralWorkflowSettings={referralWorkflowSettings}
          triageCategorySettings={triageCategorySettings}
        />
      )}
    />
  );
};

export default ReferralAdvancedFilter;
