import { useEffect, useContext } from "react";
import { replace } from "lodash";
import { useHistory, useParams } from "react-router-dom";

import AppContext from "app/AppContext";

export const useSetUrl = ({ backUrl, search, state }) => {
  const history = useHistory();
  const appContext = useContext(AppContext);
  const { setBackURL } = appContext;
  const { id, orgUnitType } = useParams();

  const params = {
    ":id": id,
    ":orgUnitType": orgUnitType,
  };

  const url = replace(backUrl, /:id|:orgUnitType/gi, val => params[val]);

  useEffect(() => {
    setBackURL({
      pathname: url,
      search,
      state,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => history.push({
    pathname: url,
    search,
  });

  const goBackWithState = () => history.push({
    pathname: url,
    search,
    state,
  });

  return { url, goBack, goBackWithState };
};

export default useSetUrl;
