import React from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import { Drawer, List, Hidden, Collapse, Box } from "@material-ui/core";
import { map, every, some, size, countBy, filter } from "lodash";
import clsx from "clsx";

import EmptyState from "components/items/empty-state";
import getAssessmentStatus from "app/main/patients/helpers/get-assessment-status";
import PatientSurveyListItem from "app/main/patients/components/patient-survey-list-item";
import getSurveyItemIcon from "app/main/patients/helpers/get-survey-item-icon";

const useStyles = makeStyles(theme => ({
  sidebarHeader: {
    backgroundColor: theme.palette.background.control,
    padding: theme.spacing(2),
  },
  drawer: {
    width: 72 + 4,
    position: "relative",
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("md")]: {
      width: 250,
    },
  },
  group: {
    borderColor: "transparent",
    transition: "border 0.3s ease",
    "&.selected": {
      borderColor: theme.palette.primary[300],
      borderWidth: 2,
      borderStyle: "solid",
    },
  },
  selectedGroupItem: {
    backgroundColor: fade(theme.palette.primary.light, 0.05),
  },
}));

const renderItem = ({ item, selectedItem, onClickItem }) => {
  const isCurrent = selectedItem.id === item.id;
  const assessmentStatus = getAssessmentStatus({ status: item.status });

  return (
    <div className="item.id">
      <PatientSurveyListItem
        selected={isCurrent}
        onClick={() => onClickItem(item)}
        icon={getSurveyItemIcon(item.surveyType)}
        status={assessmentStatus.label}
        statusIcon={assessmentStatus.icon}
        statusColor={assessmentStatus.color}
        label={item.displayName}
        shortName={item.shortName}
      />
    </div>
  );
};

const PatientSurveySideBar = ({
  empty,
  surveyIsEmpty,
  surveyListWithSetting,
  onClickItem,
  onClickGroup,
  selectedItem,
  renderSurveyHeader,
  selectedGroup,
}) => {
  const classes = useStyles();

  if (empty) return null;

  return (
    <Drawer
      variant="permanent"
      classes={{ paper: clsx(classes.drawer) }}
      ModalProps={{
        keepMounted: true,
        disablePortal: true,
        BackdropProps: { classes: { root: "absolute" } },
      }}
    >
      <Hidden smDown>
        <Box className={classes.sidebarHeader}>
          {renderSurveyHeader}
        </Box>
      </Hidden>
      {surveyIsEmpty
        ? <EmptyState title="No survey available" />
        : (
          <List classes={{ root: "py-0 overflow-y-auto overflow-x-hidden flex-1" }}>
            {map(surveyListWithSetting, x => {
              const isSelectedGroup = selectedGroup === x.groupName && !x.isSingleItem;
              let groupStatus = "NotStarted";
              // do not count the ones that is marked as not required
              const total = size(filter(x.items, item => item.status !== "NotRequired"));
              const completedCount = countBy(x.items, item => item.status === "Completed");

              if (every(x.items, ["status", "Completed"])) {
                groupStatus = "Completed";
              } else if (some(x.items, ["status", "InProgress"])) {
                groupStatus = "InProgress";
              }

              groupStatus = getAssessmentStatus({ status: groupStatus, isGroup: true });

              return (
                <Box
                  key={x.groupName || "none-group"}
                  className={clsx(classes.group, isSelectedGroup && "selected")}
                >
                  {!x.isSingleItem
                    && (
                      <PatientSurveyListItem
                        selected={isSelectedGroup}
                        onClick={() => onClickGroup(isSelectedGroup ? null : x.groupName)}
                        label={x.name}
                        status={`${completedCount.true || 0} / ${total}`}
                        statusIcon={groupStatus.icon}
                        statusColor={groupStatus.color}
                        isGroup
                        icon={getSurveyItemIcon(x.groupName)}
                      />
                    )}
                  <Collapse in={isSelectedGroup || x.isSingleItem}>
                    {map(x.items, item => (
                      <div key={item.id} className={clsx(isSelectedGroup && classes.selectedGroupItem)}>
                        {renderItem({ item, selectedItem, onClickItem })}
                      </div>
                    ))}
                  </Collapse>
                </Box>
              );
            })}
          </List>
        )}
    </Drawer>
  );
};

export default PatientSurveySideBar;
