import React from "react";

export default ({
  pageAction,
  children,
}) => (
  <>
    {pageAction && pageAction}
    <div className="bg-white flex-1">
      {children}
    </div>
  </>
);
