import moment from "moment";

export const DateFormatTypes = {
  SHORT: "DD/MM/YYYY",
  MEDIUM: "DD MMM YYYY",
  LONG: "MMMM Do, YYYY",
  ALT: "Do MMMM YYYY",
  SERIALIZED_DATE: "YYYY-MM-DD",
  SLEEP: "DD MMM",
  GRAPH: "Do MMMM YYYY",
};

export const DateTimeFormatTypes = {
  SERIALIZED_DATE_TIME: "YYYY-MM-DD HH:mm:ss",
};

export const PreferredDateFormat = "DD-MMM-YYYY";
export const PreferredTimeFormat = "h:mm a";
export const PreferredDateTimeFormat = "Do MMMM YYYY hh:mm a";
export const PreferredShortDateTimeFormat = "DD-MMM-YYYY hh:mm a";
export const PreferredLongDateTimeFormat = "dddd, Do MMMM YYYY hh:mm a";
export const AcceptedDateFormats = [
  "DD/MM/YYYY", "DD MMM YYYY", "MMMM Do, YYYY", "DD/MMM/YYYY",
  "LL", "ll", "L", "l",
  "MMM Do YY", "Do MMM YYYY", "Do MMMM YYYY",
  moment.ISO_8601,
  "YYYY MM DD HH ZZ", "YYYY-MM-DD", "DD-MMM-YYYY",
];

export const DateInputDisplayFormat = "DD/MM/yyyy";
export const DateTimeInputDisplayFormat = "DD/MM/yyyy HH:mm";
export const TimeInputDisplayFormat = "HH:mm";
export const TODAY = moment().format(DateFormatTypes.SERIALIZED_DATE);
