import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { uploadDecisionLetterTemplate, removeDecisionLetterTemplate } from "app/main/orgUnits/actions/orgUnit.actions";
import { getDecisionTemplateAcceptFormat } from "app/auth/store/reducers/system-configuration";
import downloadFromApi from "utils/download-from-api";

import { getCurrentDecisionLetterTemplate, isCurrentOrgUnitDecisionLetterTemplateLoading } from "app/main/orgUnits/reducers/orgUnit.reducers";
import DecisionLetterTemplateHelper from "app/main/orgUnits/components/decision-letter-template-helper";
import OrgUnitFileAttachmentPage from "./orgUnit-file-attachment-page";

const useStyles = makeStyles(theme => ({
  container: {
    "& > div:last-child": {
      marginBottom: theme.spacing(2),
    },
  },
}));

const OrgUnitDecisionLetterTemplatePageContent = ({
  orgUnit,
  label,
}) => {
  const classes = useStyles();
  const currentAttachment = useSelector(getCurrentDecisionLetterTemplate);
  const decisionTemplateAcceptFormat = useSelector(getDecisionTemplateAcceptFormat);
  const isDecisionLetterTemplateLoading = useSelector(isCurrentOrgUnitDecisionLetterTemplateLoading);

  const orgUnitId = !isEmpty(orgUnit) ? orgUnit.id : null;

  return (
    <div className={classes.container}>
      <OrgUnitFileAttachmentPage
        label={label}
        currentAttachment={currentAttachment}
        loading={isDecisionLetterTemplateLoading}
        onDownloadFile={() => downloadFromApi(`api/orgUnits/${orgUnitId}/downloadDecisionLetterTemplate`)}
        orgUnitId={orgUnitId}
        onRemoveFile={removeDecisionLetterTemplate}
        onUploadFile={uploadDecisionLetterTemplate}
        acceptFormat={decisionTemplateAcceptFormat}
      />
      <DecisionLetterTemplateHelper />
    </div>
  );
};

export default OrgUnitDecisionLetterTemplatePageContent;
