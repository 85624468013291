import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Field } from "formik";
import CardTextOutlineIcon from "mdi-react/CardTextOutlineIcon";

import { savePatientIdentifier, removePatientIdentifier } from "app/main/patients/actions/patients.actions";
import { SelectInput, TextInput, CheckboxInput } from "components/inputs";
import { getPatientIdentifierTypeOptions, getPrimaryPatientIdentifierSettings } from "app/auth/store/reducers/system-configuration";
import Form from "components/form";
import getPatientIdentifierLength from "app/main/patients/helpers/get-patient-identifier-length";
import "utils/yup-validation";

const typeLabel = "Type";
const identifierLabel = "Identifier";
const deprecatedLabel = "Is Deprecated";

const PatientIdentifierForm = ({
  patientId,
  patientIdentifier: identifier,
  onSucceed,
  disabledPatientIdentifierTypeCode,
  showIsDeprecated,
  ...other
}) => {
  const dispatch = useDispatch();
  const typeOptions = useSelector(getPatientIdentifierTypeOptions); // to return allowed options useSelector(state => getPatientIdentifierTypeOptions(state, true))
  const primaryPatientIdentifierSettings = useSelector(getPrimaryPatientIdentifierSettings);
  const primaryPatientIdentifier = primaryPatientIdentifierSettings?.type;
  const primaryPatientIdentifierMaxLength = primaryPatientIdentifierSettings?.maxLength;
  const primaryPatientIdentifierMinLength = primaryPatientIdentifierSettings?.minLength;
  const numericIdentifiersOnly = primaryPatientIdentifierSettings?.numericIdentifiersOnly;

  const handleRemove = () => {
    dispatch(removePatientIdentifier(patientId, identifier.patientIdentifierId)).then(responds => {
      if (responds.error !== true) {
        onSucceed();
      }
    });
  };

  const handleSubmit = ({ patientIdentifier }, { setErrors }) => {
    dispatch(savePatientIdentifier(patientId, patientIdentifier)).then(responds => {
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  const schema = Yup.object().shape({
    patientIdentifier: Yup.object().shape({
      patientIdentifierTypeCode: Yup.string()
        .required(`${typeLabel} is required`)
        .nullable(),
      identifier: Yup.string()
        .when("patientIdentifierTypeCode", (patientIdentifierTypeCode, YupSchema) => {
          const isPrimaryPatientIdentifier = patientIdentifierTypeCode === primaryPatientIdentifier;
          const patientIdentifierLength = getPatientIdentifierLength(patientIdentifierTypeCode) ?? 11;

          if (isPrimaryPatientIdentifier) {
            return YupSchema.validateIdentifier(identifierLabel, { min: primaryPatientIdentifierMinLength, max: primaryPatientIdentifierMaxLength, numberOnly: numericIdentifiersOnly });
          }
          return YupSchema.validateIdentifier(identifierLabel, { min: patientIdentifierLength, max: patientIdentifierLength });
        })
        .trim().required(`${identifierLabel} is required`)
        .nullable(),
    }),
  });

  const isEdit = identifier?.patientIdentifierId;

  let { patientIdentifier } = schema.cast();

  if (identifier) {
    patientIdentifier = identifier;
  }

  return (
    <Form
      contentProps={{ ...other, title: `${isEdit ? "Edit" : "Add"} Patient Identifier` }}
      initialValues={{ patientIdentifier }}
      validationSchema={schema}
      onSubmit={handleSubmit}
      onRemove={isEdit ? () => handleRemove() : null}
      content={() => (
        <>
          <Field
            name="patientIdentifier.patientIdentifierTypeCode"
            label={typeLabel}
            component={SelectInput}
            options={typeOptions}
            disabled={disabledPatientIdentifierTypeCode}
            required
            icon={<CardTextOutlineIcon />}
          />
          <Field
            name="patientIdentifier.identifier"
            label={identifierLabel}
            component={TextInput}
            required
          />
          {showIsDeprecated && (
            <div className="ml-32">
              <Field
                name="patientIdentifier.isDeprecated"
                component={CheckboxInput}
                label={deprecatedLabel}
                showIcon={false}
              />
            </div>
          )}
        </>
      )}
    />
  );
};

export default PatientIdentifierForm;
