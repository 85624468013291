import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { map } from "lodash";
import { AutoComplete } from "components/inputs";
import { AssignedSpecialtyIcon } from "helpers/icon-finder";
import { fetchSpecialtyProfilesForSelector } from "app/main/specialtyProfiles/actions/specialtyProfiles.actions";
import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";

export default ({
  label,
  showIcon = true,
  initialOptions = [],
  ...other
}) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState(initialOptions || []);
  const [loading, setLoading] = useState(false);
  const orgUnit = useSelector(getSignedInOrgUnit);

  return (
    <AutoComplete
      icon={showIcon && <AssignedSpecialtyIcon />}
      label={label}
      loading={loading}
      onOpen={() => {
        setLoading(true);
        dispatch(fetchSpecialtyProfilesForSelector(orgUnit.id)).then(response => {
          setOptions(response.error ? [] : response.payload.specialtyProfiles);
          setLoading(false);
        });
      }}
      options={map(options, x => ({ label: x.name, value: x.id }))}
      filterSelectedOptions
      getOptionSelected={(option, val) => option.value === val.value}
      filterOption={{ matchFrom: "start" }}
      {...other}
    />
  );
};
