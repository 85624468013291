import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import { ActionTypes, isPageLoading } from "app/main/patients/reducers/patientsSearch.reducers";

export const resetPatientSearch = () => ({
  type: ActionTypes.RESET_PATIENTS_SEARCH,
});

export const fetchPatientSearchResults = (pageNumber, pageSize, searchParams) => dispatch => {
  const params = formatSearchParams(searchParams);
  const url = `/patient/getExternalPatients?pageNumber=${pageNumber}&pageSize=${pageSize}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "PATIENTS_SEARCH",
      pageNumber,
      bailout: state => isPageLoading(state, pageNumber),
    },
  });
};

export const saveExternalPatient = patient => ({
  [ApiActions.POST_TO_API]: {
    endpoint: "/patient/saveExternalPatient",
    method: "POST",
    body: { patient },
    types: "EXTERNAL_PATIENT",
  },
});
