import moment from "moment-timezone";
import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import { getReferralWorklistSettings } from "app/auth/store/reducers/system-configuration";
import { isPageLoading, isPageLoaded, REFERRALS_PAGE_SIZE, isPatientActiveReferralsLoading, ActionTypes } from "../reducers/patientReferrals.reducers";

const timeZone = moment.tz.guess();

const getDefaultSpecialtySearchParams = state => {
  const referralWorklistSettings = getReferralWorklistSettings(state);
  const defaultSorOrder = referralWorklistSettings?.worklistSortOrder;

  return {
    showOldestFirst: defaultSorOrder === "asc",
  };
};

export const resetPatientActiveReferralSearch = () => ({
  type: ActionTypes.RESET_PATIENT_REFERRALS_SEARCH,
});

export const setUpPatientReferralDefaultSearchParams = () => (dispatch, getState) => {
  const defaultSpecialtySearchParams = getDefaultSpecialtySearchParams(getState());

  return dispatch({
    type: ActionTypes.SET_DEFAULT_PATIENT_REFERRALS_SEARCH_PARAMS,
    payload: defaultSpecialtySearchParams,
  });
};

export const fetchPatientReferrals = (searchParams, pageNumber = 1, forceReload = false) => dispatch => {
  const patientId = searchParams?.patientId;

  const params = formatSearchParams(searchParams);
  const url = `/referrals?pageNumber=${pageNumber}&pageSize=${REFERRALS_PAGE_SIZE}&timeZone=${timeZone}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      entityType: "PATIENT_REFERRALS",
      patientId,
      pageNumber,
      bailout: state => isPageLoading(state, patientId, pageNumber) || (!forceReload && isPageLoaded(state, patientId, pageNumber)),
    },
  });
};

export const fetchPatientActiveReferrals = (patientId, takeNumber) => dispatch => {
  const url = `/referrals?pageNumber=1&pageSize=${takeNumber ?? 2}&timeZone=${timeZone}&activeOnly=true&patientId=${patientId}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      entityType: "PATIENT_ACTIVE_REFERRALS",
      patientId,
      bailout: state => isPatientActiveReferralsLoading(state, patientId),
    },
  });
};
