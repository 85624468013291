import React from "react";
import { useDispatch } from "react-redux";
import { startCase } from "lodash";
import { Typography, CircularProgress } from "@material-ui/core";

import DefaultItem from "components/items/default-item";
import DefaultButton from "components/items/default-button";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import ReferralAssignUserForm from "app/main/referrals/components/referral-assign-user-form";
import { AssignedUserIcon, AssignedSpecialtyIcon } from "helpers/icon-finder";
import withPermissions from "permissions/withPermissions";
import TextLink from "components/items/text-link";
import { cancelledWorkflowState } from "app/main/referrals/helpers/workflow-state-names";

const ReferralAssignTo = ({
  hasPermissionReferralsAssign,
  referral,
  loading,
  disabled,
}) => {
  const dispatch = useDispatch();

  if (loading) {
    return (
      <CircularProgress size={20} />
    );
  }

  if (!referral) {
    return "N/A";
  }

  const { assignmentType, assignedToUserId, assignedToId, assignedToDisplayName, assignedToRoleName, referralStatus } = referral;

  const isAssigned = assignedToUserId || assignedToId;
  const isCancelled = referralStatus === cancelledWorkflowState;

  const openReferralAssignUserForm = () => {
    dispatch(openDialog({
      children: (
        <ReferralAssignUserForm
          title="Assign Referral"
          referral={referral}
          onSucceed={() => dispatch(closeDialog())}
        />
      ),
    }));
  };

  if (isAssigned) {
    return (
      <DefaultItem
        icon={assignmentType && assignmentType === "User" ? <AssignedUserIcon /> : <AssignedSpecialtyIcon />}
        content={(
          <div className="flex flex-row items-center sm:flex-col sm:items-start">
            <Typography variant="caption" className="mr-8">Assigned To </Typography>
            <TextLink
              disabled={disabled || !hasPermissionReferralsAssign || isCancelled}
              variant="caption"
              fontSize="small"
              content={`${assignedToDisplayName}${assignedToRoleName ? ` (${startCase(assignedToRoleName)})` : ""}`}
              onClick={() => openReferralAssignUserForm()}
            />
          </div>
        )}
      />
    );
  }

  return (
    <DefaultButton
      disabled={disabled || !hasPermissionReferralsAssign}
      label="Assign"
      icon={<AssignedUserIcon />}
      onClick={() => openReferralAssignUserForm()}
    />
  );
};

export default withPermissions("ReferralsAssign")(ReferralAssignTo);
