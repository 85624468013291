import React from "react";
import { find, some, get, isObject, orderBy, every, isEmpty } from "lodash";
import Select from "react-select";

import BaseTextInput from "./base-text-input";
import SelectStyles from "./styles";

const addLeadingNumber = value => (`0${value}`).slice(-2);
const dayOptions = [];
const yearOptions = [];
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const backDated = currentYear - 111;

for (let i = 1; i <= 31; i += 1) {
  dayOptions.push({
    value: addLeadingNumber(i),
    label: i,
  });
}

for (let y = backDated; y <= currentYear; y += 1) {
  yearOptions.push({
    value: y.toString(),
    label: y,
  });
}

const monthOptions = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

class DateOfBirthInput extends React.Component {
  constructor(props) {
    super(props);
    const { field: { value } } = props;
    let day; let month; let year = "";
    // if there is value
    if (value) {
      const date = value.split("-");
      // set the default option for year / month / day  selector
      year = find(yearOptions, option => option.value === date[0]);
      if (!year) {
        const yearVal = {
          value: date[0].toString(),
          label: date[0],
        };
        year = yearVal;
        // push year object to options
        yearOptions.push(yearVal);
      }
      month = find(monthOptions, option => option.value === date[1]);
      day = find(dayOptions, option => option.value === date[2]);
    }

    this.state = {
      day,
      month,
      year,
    };
  }

  onFocus = () => {
    const { field: { name }, form: { setFieldTouched, touched } } = this.props;
    // if field hasn't set to touched
    if (get(touched, name, null) === null) {
      setFieldTouched(name);
    }
  }

  onBlur = () => {
    const { field, form } = this.props;
    const { year, month, day } = this.state;
    const monthValue = isObject(month) ? month.value : month;
    const dayValue = isObject(day) ? day.value : day;
    const yearValue = isObject(year) ? year.value : year;

    const testValue = [yearValue, monthValue, dayValue];
    const dateInputIsNull = some(testValue, value => value === "");
    // only update the main input when 3 inputs have been filled
    if (!dateInputIsNull) {
      const date = `${yearValue}-${monthValue}-${dayValue}`;
      form.setFieldValue(field.name, date);
    }
  }

  handleSelectionChange = (name, date) => {
    const { value } = date;
    this.setState({ [name]: value });
  }

  handleMaxLength = (value, maxLength) => (value.length <= maxLength ? value : value.substr(0, maxLength));

  onMenuClose = nextRef => {
    if (nextRef) {
      nextRef.select.focus();
    }
  }

  InputContainer = () => {
    const inputProps = {
      onFocus: () => this.input.props.onFocus(),
      onBlur: () => this.input.props.onBlur(),
      menuPortalTarget: document.body,
      menuPosition: "fixed",
      className: "flex-1",
      styles: SelectStyles,
    };
    return (
      <div className="flex flex-1 justify-between items-end">
        <Select
          name="day"
          options={dayOptions}
          placeholder="Day"
          onChange={day => this.handleSelectionChange("day", day)}
          defaultValue={this.state.day}
          noOptionsMessage={() => "Invalid Day"}
          onInputChange={inputValue => this.handleMaxLength(inputValue, 2)}
          ref={ref => { this.day = ref; }}
          onMenuClose={() => this.onMenuClose(this.month)}
          {...inputProps}
        />
        <Select
          name="month"
          options={monthOptions}
          placeholder="Month"
          onChange={month => this.handleSelectionChange("month", month)}
          defaultValue={this.state.month}
          noOptionsMessage={() => "Invalid Month"}
          ref={ref => { this.month = ref; }}
          onMenuClose={() => this.onMenuClose(this.year)}
          {...inputProps}
        />
        <Select
          name="year"
          options={orderBy(yearOptions, "value", "desc")}
          placeholder="Year"
          onChange={year => this.handleSelectionChange("year", year)}
          defaultValue={this.state.year}
          noOptionsMessage={() => "Invalid Year"}
          onInputChange={inputValue => this.handleMaxLength(inputValue, 4)}
          ref={ref => { this.year = ref; }}
          {...inputProps}
        />
      </div>
    );
  }

  render() {
    const {
      field,
      form: { touched, errors },
      icon,
      options,
      disabled,
      inputProps,
      ...other
    } = this.props;

    const { year, month, day } = this.state;
    const testValue = [year, month, day];
    const dateIsNotNull = every(testValue, value => !isEmpty(value));
    let date = null;
    if (dateIsNotNull) {
      date = `${year}-${month}-${day}`;
    }

    const { name } = field;

    return (
      <BaseTextInput
        name={name}
        id="birthday"
        tabIndex="-1"
        defaultActive
        ref={ref => { this.input = ref; }}
        inputComponent={this.InputContainer}
        icon={icon || "date_range"}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        error={get(errors, name, null)}
        touched={get(touched, name, null)}
        value={date}
        {...other}
      />
    );
  }
}

export default DateOfBirthInput;
