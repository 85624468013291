import React from "react";
import PatientRecordContent from "app/main/patients/components/patient-record-content";

const PatientAccessLogPatientRecordPage = ({
  match: { params: { patientId } },
  ...other
}) => (
  <PatientRecordContent routePrefix="/admin?tab=patientAccess" patientId={patientId} {...other} />
);

export default PatientAccessLogPatientRecordPage;
