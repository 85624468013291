import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Field } from "formik";
import { TextInput } from "components/inputs/text-input";
import CheckboxInput from "components/inputs/checkbox";
import { getCurrentDiseaseState, hasPrimaryDiseaseState } from "app/main/patients/reducers/patients.reducers";
import { saveDiseaseState, removeDiseaseState } from "app/main/patients/actions/patients.actions";
import Form from "components/form";
import { validateRequired } from "utils/validators";

const diseaseStateLabel = "Disease State";
const validateDiseaseState = validateRequired(diseaseStateLabel);

class DiseaseStateForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = ({ diseaseState }, { setErrors }) => {
    const newDiseaseState = {
      ...diseaseState,
      status: diseaseState.isPrimary ? "Primary" : "Secondary",
    };

    this.props.saveDiseaseState(this.props.patientId, newDiseaseState).then(responds => {
      if (responds.error !== true) {
        this.props.onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  handleRemove = ({ diseaseStateId, setErrors }) => {
    this.props.removeDiseaseState(this.props.patientId, diseaseStateId).then(responds => {
      if (responds.error !== true) {
        this.props.onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  render() {
    const {
      initialValues,
      showPrimary,
      disablePrimary,
      ...other
    } = this.props;

    const allowRemove = !isEmpty(this.props.initialValues.diseaseState.status);

    return (
      <Form
        initialValues={initialValues}
        contentProps={other}
        onSubmit={this.handleSubmit}
        onRemove={allowRemove ? (formProps => this.handleRemove({ diseaseStateId: initialValues.diseaseState.id, ...formProps })) : null}
        content={() => (
          <>
            <Field
              name="diseaseState.name"
              label={diseaseStateLabel}
              component={TextInput}
              validate={validateDiseaseState}
              icon="flag"
            />
            {showPrimary
              && (
              <Field
                name="diseaseState.isPrimary"
                label="Primary"
                disabled={disablePrimary}
                component={CheckboxInput}
              />
              )}
          </>
        )}
      />
    );
  }
}

export default connect(state => {
  const currentDiseaseState = getCurrentDiseaseState(state);
  const isPrimary = !isEmpty(currentDiseaseState.diseaseState) ? currentDiseaseState.diseaseState.status === "Primary" : false;
  return {
    initialValues: { diseaseState: { ...currentDiseaseState.diseaseState,
      isPrimary } },
    patientId: currentDiseaseState.patientId,
    showPrimary: !hasPrimaryDiseaseState(state, currentDiseaseState.patientId) || isPrimary,
  };
},
{ saveDiseaseState, removeDiseaseState })(DiseaseStateForm);
