import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox, Box, Typography, Tooltip } from "@material-ui/core";
import moment from "moment";
import { truncate } from "lodash";
import TextBoxCheckOutlineIcon from "mdi-react/TextBoxCheckOutlineIcon";

import { ActivityLogItem } from "components/items/activity-log";
import IconButton from "components/items/icon-button";
import TextTag from "components/items/text-tag";
import { getUser } from "app/auth/store/reducers/user.reducer";
import withPermissions from "permissions/withPermissions";
import { editReferralTask } from "app/main/referrals/actions/referrals.actions";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import ReferralTaskForm from "app/main/referrals/components/referral-task-form";
import { ContextItem } from "components/items/default-item";
import formatDate from "helpers/format-date";
import formatDateTime from "helpers/format-date-time";
import { TaskIcon } from "helpers/icon-finder";

const Item = props => (
  <ContextItem
    className="flex-col"
    alignment="items-start"
    labelProps={{ variant: "caption", color: "textSecondary" }}
    labelClassName="w-auto"
    renderIfEmpty
    {...props}
  />
);

const TaskItem = ({
  activity,
  canEdit,
  canDelete,
  hasPermissionReferralTasksModify,
  hasPermissionReferralTasksDelete,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const isCompleted = activity?.completedDateTimeUtc !== null;
  const allowUpdate = user.userId === activity.addedByUserId && activity?.completedDateTimeUtc === null;
  const canEditTask = canEdit && hasPermissionReferralTasksModify && allowUpdate;
  const canDeleteTask = canDelete && hasPermissionReferralTasksDelete && allowUpdate;
  const formattedDueDate = formatDate(activity.dueDate);

  let isOverDue = false;
  if (activity && activity?.completedDateTimeUtc === null && activity?.dueDate !== null) {
    isOverDue = moment(activity?.dueDate).isBefore(moment(), "day");
  }

  const editTask = () => dispatch(
    openDialog({ children: (
      <ReferralTaskForm
        title="Edit Task"
        onSucceed={() => dispatch(closeDialog())}
        onCancel={() => dispatch(closeDialog())}
        referralId={activity.referralId}
        currentTask={activity}
        canDeleteTask={canDeleteTask}
      />
    ) }),
  );

  const onSubmit = task => dispatch(editReferralTask(activity.referralId, task));

  const editInfo = () => {
    if (isCompleted) {
      return <Typography variant="caption">Completed by {activity.completedBy} at {formatDateTime(activity.completedDateTimeUtc, true)}</Typography>;
    }

    if (activity.lastEditedDateTimeUtc) {
      return <Typography variant="caption">Last edit by {activity.lastEditedBy} at {formatDateTime(activity.lastEditedDateTimeUtc, true)}</Typography>;
    }

    return null;
  };

  return (
    <ActivityLogItem
      id={activity.id}
      date={activity?.createdDateLocalised ?? "Today"}
      user={activity.addedBy}
      icon={isCompleted ? <TextBoxCheckOutlineIcon /> : <TaskIcon />}
      content={(
        <Box className="break-word">
          {truncate(activity.description, { length: 150 })} {activity.dueDate && <TextTag className="ml-4" size="medium" label={`${isOverDue ? "Overdue: " : ""}${formattedDueDate}`} variant={isOverDue ? "warning" : "default"} />} {activity.assignedTo && <TextTag className="ml-4" label={activity.assignedTo} />}
        </Box>
      )}
      prefix={(
        <Tooltip title={`Mark as ${isCompleted ? "incomplete" : "complete"}`}>
          <div>
            <Checkbox
              disabled={!canEdit || !hasPermissionReferralTasksModify}
              checked={activity.completedDateTimeUtc !== null}
              className="p-0 mr-8"
              onChange={event => {
                onSubmit({ ...activity, isComplete: event.target.checked });
              }}
            />
          </div>
        </Tooltip>
      )}
      renderPopupContent={({ close }) => (
        <>
          <div className="flex justify-between items-center">
            <Typography className="font-bold">Task</Typography>
            <div className="flex items-center justify-end">
              {canEditTask && <IconButton title="Edit task" icon="edit" onClick={() => { editTask(); close(); }} />}
              <div className="ml-16">
                <IconButton title="Close" onClick={close} icon="close" />
              </div>
            </div>
          </div>
          <div>
            <Typography variant="caption">Added by {activity.addedBy} at {activity.createdDateLocalised}</Typography>
          </div>
          {editInfo()}
          <div className="pt-16 items-container with-horizontal-divider mds">
            <Item label="Description" content={activity.description} />
            <Item label="Due Date" content={formattedDueDate} />
            <Item label="Assigned To" content={activity.assignedTo} />
          </div>
        </>
      )}
    />
  );
};

export default withPermissions("ReferralTasksModify", "ReferralTasksDelete")(TaskItem);
