import * as Yup from "yup";
import "utils/yup-validation";
import { isNaN } from "lodash";

export default (fieldLabels, fieldSettings, decimalNumberRegex, decimalPlacesRegex) => {
  const statusRequiredMessage = "Status is required";
  const decisionDateRequiredMessage = "Decision Date is required";
  const tooLong = "Too Long!";
  const getBooleanDefaultValue = setting => (setting ? false : undefined);

  return Yup.object().shape({
    decision: Yup.object().shape({
      status: Yup.string().max(50, tooLong).required(statusRequiredMessage).nullable(),
      decisionDate: Yup.date().required(decisionDateRequiredMessage).nullable(),
      // config fields
      totalCost: Yup.number()
        .fieldSettingsValidation(fieldLabels.totalCost, fieldSettings?.totalCost)
        .validateDecimalNumber(fieldLabels.totalCost, decimalNumberRegex)
        .validateDecimalNumberDecimalValues(fieldLabels.totalCost, decimalPlacesRegex)
        .nullable()
        .transform(v => (isNaN(v) ? null : v)),
      approvalDuration: Yup.string()
        .max(50, tooLong)
        .fieldSettingsValidation(fieldLabels.approvalDuration, fieldSettings?.approvalDuration)
        .nullable(),
      urgentTreatmentApproval: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.urgentTreatmentApproval, fieldSettings?.urgentTreatmentApproval)
        .nullable()
        .default(getBooleanDefaultValue(fieldSettings?.urgentTreatmentApproval)),
      compassionateAccess: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.compassionateAccess, fieldSettings?.compassionateAccess)
        .nullable()
        .default(getBooleanDefaultValue(fieldSettings?.compassionateAccess)),
      outOfSessionAssessment: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.outOfSessionAssessment, fieldSettings?.outOfSessionAssessment)
        .nullable()
        .default(getBooleanDefaultValue(fieldSettings?.outOfSessionAssessment)),
      reportingYear: Yup.string()
        .matches(/^\b(19|[2-9][0-9])\d{2}\b/, {
          message: "Must be a valid year, formatted YYYY",
          excludeEmptyString: true,
        })
        .length(4, "Must be a valid year, formatted YYYY")
        .fieldSettingsValidation(fieldLabels.reportingYear, fieldSettings?.reportingYear)
        .nullable(),
      outcomeMeasures: Yup.string()
        .max(255, tooLong)
        .fieldSettingsValidation(fieldLabels.outcomeMeasures, fieldSettings?.outcomeMeasures)
        .nullable(),
      restrictionDetails: Yup.string()
        .max(500, tooLong)
        .fieldSettingsValidation(fieldLabels.restrictionDetails, fieldSettings?.restrictionDetails)
        .nullable(),
      notes: Yup.string()
        .max(2000, tooLong)
        .fieldSettingsValidation(fieldLabels.notes, fieldSettings?.notes)
        .nullable(),
      commentsForDecisionLetter: Yup.string()
        .max(2000, tooLong)
        .fieldSettingsValidation(fieldLabels.commentsForDecisionLetter, fieldSettings?.commentsForDecisionLetter)
        .nullable(),
      decisionRationale: Yup.string()
        .max(2000, tooLong)
        .fieldSettingsValidation(fieldLabels.decisionRationale, fieldSettings?.decisionRationale)
        .nullable(),
      isRatified: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.isRatified, fieldSettings?.isRatified)
        .default(false),
      agendaItemNumber: Yup.string()
        .fieldSettingsValidation(fieldLabels.agendaItemNumber, fieldSettings?.agendaItemNumber)
        .nullable(),
      expiryDate: Yup.string()
        .fieldSettingsValidation(fieldLabels.expiryDate, fieldSettings?.expiryDate)
        .nullable(),
    }),
  });
};
