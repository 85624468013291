import React from "react";
import { isEmpty, find, get } from "lodash";

import PatientDetails, { NameLabel, PatientAgeLabel, TextLabel, PatientIdentifierLabel, AddressDetailsLabel, EmailLabel, ContactDetailsLabel, AssignedUser, PatientConsentStatus } from "app/main/patients/components/patient-details";
import ViewPatientRecordButton from "app/main/patients/components/view-patient-record-button";

const CandidateHeader = ({
  candidate: {
    birthDate,
    firstname,
    surname,
    gender,
    patientIdentifiers,
    patientId,
    homeAddress,
    homePostCode,
    memberEmailAddress,
    enrolments,
    memberPhNo,
    personPhNo,
  },
  onClick,
}) => {
  const name = `${firstname} ${surname}`;
  const salveoEnrolment = find(enrolments, x => x.enrolmentType.value === "SalveoNursing");
  const { enrolmentStatus, doNotContactAgain } = salveoEnrolment || {};
  return (
    <PatientDetails
      renderContent={(
        <div className="flex-row-container with-gutter">
          <NameLabel name={name} />
          <PatientAgeLabel birthDate={birthDate} />
          <TextLabel className="font-bold" content={gender} />
          <TextLabel content={doNotContactAgain && "Do Not Contact Again"} />
          <PatientConsentStatus consent={get(enrolmentStatus, "value", null)} />
        </div>
      )}
      renderExtraContent={(
        <div className="flex flex-col items-container">
          <PatientIdentifierLabel disabled patientIdentifiers={patientIdentifiers} />
          <div className="flex-row-container with-gutter">
            <AddressDetailsLabel address={`${homeAddress} ${homePostCode}`} />
            <EmailLabel email={memberEmailAddress} />
            <ContactDetailsLabel contact={!isEmpty(memberPhNo) ? memberPhNo : personPhNo} />
            <AssignedUser disabled enrolments={enrolments} patientId={patientId} />
          </div>
        </div>
      )}
      renderRightContent={onClick && <ViewPatientRecordButton onClick={onClick} />}
    />
  );
};

export default CandidateHeader;
