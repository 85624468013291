import React from "react";
import { FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  helperText: {
    display: "flex",
    minHeight: 20,
  },
}));

const HelperText = ({
  error,
  helperText,
  classes,
}) => {
  const styles = useStyles();
  const includesHtml = /<\/?[a-z][\s\S]*>/i.test(helperText);
  return (
    <FormHelperText error={error} classes={{ root: clsx(styles.helperText, classes?.root) }}>
      {/* eslint-disable-next-line react/no-danger */}
      {includesHtml ? <span dangerouslySetInnerHTML={{ __html: helperText }} /> : helperText}
    </FormHelperText>
  );
};

export default HelperText;
