import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Field } from "formik";
import { isEmpty } from "lodash";
import * as Yup from "yup";

import Form from "components/form";
import { TextInput, CheckboxInput } from "components/inputs";
import { getOrgUnitSettings } from "app/auth/store/reducers/system-configuration";
import { addOrgUnit, editOrgUnit } from "app/main/orgUnits/actions/orgUnit.actions";
import OrgUnitSelector from "app/main/orgUnits/components/orgUnit-selector";
import SubFormularySelector from "app/main/applications/components/subformulary-selector";

const schema = Yup.object().shape({
  orgUnit: Yup.object().shape({
    name: Yup.string()
      .max(200, "Too Long!")
      .trim().required("Name is required")
      .nullable(),
    parentOrgUnitId: Yup.string().required("Parent is required").nullable(),
    externalIdentifier: Yup.string()
      .max(200, "Too Long!")
      .nullable(),
    subFormularyName: Yup.string()
      .nullable(),
    subFormularyId: Yup.string()
      .nullable(),
    drugSearchDisabled: Yup.bool()
      .nullable(),
  }),
});

const subFormularySelectionChange = (newValue, setFieldValue) => {
  const subFormularySelected = newValue;
  setFieldValue("orgUnit.subFormularyName", subFormularySelected ? newValue?.label : null);
  setFieldValue("orgUnit.subFormularyId", subFormularySelected ? newValue?.value : null);
};

const OrgUnitForm = ({
  disabled,
  onSucceed,
  orgUnitType,
  ...other
}) => {
  const dispatch = useDispatch();

  const orgUnitsSettings = useSelector(state => getOrgUnitSettings(state, other.orgUnit?.orgUnitType));
  const parentOrgUnitType = orgUnitsSettings?.parentOrgUnitType;

  const parentOrgUnitTypeSettings = useSelector(state => getOrgUnitSettings(state, parentOrgUnitType));
  const parentOrgUnitLabel = parentOrgUnitTypeSettings?.nameSingle ?? parentOrgUnitType;

  const nameLabel = "Name";
  const externalIdentifierLabel = "External Identifier";
  const subFormularyLabel = "Medication Search Sub-Formulary";
  const drugSearchDisableLabel = "Disable Formulary Medication Search";

  const handleSubmit = ({ orgUnit }, { setSubmitting, setErrors }) => {
    const apiCall = () => (orgUnit?.id ? dispatch(editOrgUnit(orgUnit?.id, orgUnit)) : dispatch(addOrgUnit(orgUnit?.parentOrgUnitId, orgUnit)));

    apiCall().then(res => {
      setSubmitting(false);
      if (res.error !== true) {
        onSucceed(res.payload.orgUnit.id);
      } else {
        setErrors(res.payload);
      }
    });
  };

  let { orgUnit } = schema.cast();

  if (!isEmpty(other.orgUnit)) {
    orgUnit = {
      ...other.orgUnit,
      drugSearchDisabled: orgUnitType === "Site" ? other.orgUnit.drugSearchDisabled : false,
      subFormulary: other.orgUnit.subFormularyId ? { value: other.orgUnit.subFormularyId, label: other.orgUnit.subFormularyName } : null,
    };
  }

  return (
    <Form
      initialValues={{ orgUnit }}
      onSubmit={handleSubmit}
      disabled={disabled}
      contentProps={other}
      validationSchema={schema}
      isModal={false}
      content={({ setFieldValue }) => (
        <>
          <Field
            name="orgUnit.name"
            component={TextInput}
            label={nameLabel}
            required
          />
          <Field
            name="orgUnit.parentOrgUnitId"
            label={parentOrgUnitLabel}
            orgUnitType={parentOrgUnitType}
            disabled
            component={OrgUnitSelector}
            onChange={(value, change) => {
              change("orgUnit.parentOrgUnitId", value?.value);
            }}
          />
          <Field
            name="orgUnit.externalIdentifier"
            component={TextInput}
            label={externalIdentifierLabel}
          />
          {orgUnitType === "Site" && (
            <>
              <Field
                name="orgUnit.subFormulary"
                component={SubFormularySelector}
                label={subFormularyLabel}
                orgUnitId={orgUnit?.id ?? orgUnit?.parentOrgUnitId}
                onChange={value => subFormularySelectionChange(value, setFieldValue)}
              />
              <Field
                name="orgUnit.drugSearchDisabled"
                component={CheckboxInput}
                label={drugSearchDisableLabel}
              />
            </>
          )}
        </>
      )}
    />
  );
};

export default OrgUnitForm;
