import React, { useState } from "react";
import { map } from "lodash";
import { useDispatch } from "react-redux";

import { fetchUserListForSelector } from "app/main/users/actions/users.actions";

import UserSelectorInput from "./input";

const formatUserOptions = options => map(options, option => ({ value: option.userId, label: option.name, isActive: option.isActive }));

const UserSelectorContainer = ({
  defaultOptions = [],
  loadOptions = true,
  orgUnitId = null,
  includeInactive = false,
  userHasPermission = null,
  filteredEndorsedByType = null,
  includeAllUsers = false,
  ...other
}) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState(defaultOptions);
  const [loading, setLoading] = useState(false);
  const [name, setNameParam] = useState(null);

  React.useEffect(() => {
    if (!loadOptions) {
      return;
    }

    setLoading(true);
    dispatch(fetchUserListForSelector({ orgUnitId, name, includeInactive, userHasPermission, filteredEndorsedByType, includeAllUsers }))
      .then(res => setOptions(formatUserOptions(res.payload.users)))
      .finally(() => setLoading(false));
  }, [dispatch, loadOptions, orgUnitId, name, includeInactive, userHasPermission, filteredEndorsedByType, includeAllUsers]);

  return (
    <UserSelectorInput
      isLoading={loading}
      setNameParam={setNameParam}
      options={options}
      {...other}
    />
  );
};

export default UserSelectorContainer;
