import React from "react";
import Typography from "@material-ui/core/Typography";

export default function PatientSexLabel({
  sex,
  variant,
  className,
  ...other
}) {
  if (!sex) return null;
  return (<Typography variant={variant || "body1"} className={className || "font-bold"} {...other}>{sex}</Typography>);
}
