import { get, mapKeys, map, concat, filter } from "lodash";
import curryConnector from "utils/connectors";

export const EXTERNAL_DOCUMENT_WORKLIST_STATE_KEY = "externalDocumentWorklist";
export const EXTERNAL_DOCUMENT_PAGE_SIZE = 50;
export const EXTERNAL_DOCUMENT_OPTIONS_PAGE_SIZE = 5;

const curry = fn => curryConnector(fn, EXTERNAL_DOCUMENT_WORKLIST_STATE_KEY);

export const defaultSearchParams = {
  name: "",
  sortBy: "CreatedDateDesc",
  showRemovedOnly: false,
};

export const ActionTypes = {
  LOADING_EXTERNAL_DOCUMENT_WORKLIST: "LOADING_EXTERNAL_DOCUMENT_WORKLIST",
  LOADED_EXTERNAL_DOCUMENT_WORKLIST: "LOADED_EXTERNAL_DOCUMENT_WORKLIST",
  ERROR_LOADING_EXTERNAL_DOCUMENT_WORKLIST: "ERROR_LOADING_EXTERNAL_DOCUMENT_WORKLIST",

  SET_CURRENT_EXTERNAL_DOCUMENT_WORKLIST: "SET_CURRENT_EXTERNAL_DOCUMENT_WORKLIST",
  DELETED_EXTERNAL_DOCUMENT_WORKLIST_ITEM: "DELETED_EXTERNAL_DOCUMENT_WORKLIST_ITEM",
  RESTORED_EXTERNAL_DOCUMENT_WORKLIST_ITEM: "RESTORED_EXTERNAL_DOCUMENT_WORKLIST_ITEM",

  LOADING_CURRENT_SOURCE_DOCUMENT_FILE: "LOADING_CURRENT_SOURCE_DOCUMENT_FILE",
  LOADED_CURRENT_SOURCE_DOCUMENT_FILE: "LOADED_CURRENT_SOURCE_DOCUMENT_FILE",
  ERROR_LOADING_CURRENT_SOURCE_DOCUMENT_FILE: "ERROR_LOADING_CURRENT_SOURCE_DOCUMENT_FILE",

  LOADING_EXTERNAL_DOCUMENT_OPTIONS: "LOADING_EXTERNAL_DOCUMENT_OPTIONS",
  LOADED_EXTERNAL_DOCUMENT_OPTIONS: "LOADED_EXTERNAL_DOCUMENT_OPTIONS",
  ERROR_LOADING_EXTERNAL_DOCUMENT_OPTIONS: "ERROR_LOADING_EXTERNAL_DOCUMENT_OPTIONS",
};

const INITIAL_STATE = {
  all: null,
  pageInfo: {
    pageNumber: 1,
    pageSize: EXTERNAL_DOCUMENT_PAGE_SIZE,
    totalRecords: 0,
  },
  pages: { loading: false, loaded: false, error: null },
  searchParams: defaultSearchParams,
  externalSourceDocumentForWorklist: null,
  current: { loading: false, loaded: false, error: null, document: null },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_EXTERNAL_DOCUMENT_WORKLIST:
    case ActionTypes.LOADING_EXTERNAL_DOCUMENT_OPTIONS:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: true, loaded: false, error: false } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.LOADED_EXTERNAL_DOCUMENT_WORKLIST:
    case ActionTypes.LOADED_EXTERNAL_DOCUMENT_OPTIONS:
      return {
        ...state,
        searchParams: action.payload.searchParams,
        pageInfo: action.payload.pageInfo,
        all: action.payload.isWorklist ? { ...state.all, ...mapKeys(action.payload.documents, x => x.externalSourceDocumentId) } : state.all,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: false, error: false, loaded: true } },
        externalSourceDocumentForWorklist: (action.payload.pageInfo.pageNumber === 1)
          ? map(action.payload.documents, x => x.externalSourceDocumentId)
          : concat(state.externalSourceDocumentForWorklist, map(action.payload.documents, x => x.externalSourceDocumentId)),
        options: !action.payload.isWorklist ? { ...state.options, ...mapKeys(action.payload.documents, x => x.externalSourceDocumentId) } : state.options,
      };
    case ActionTypes.ERROR_LOADING_EXTERNAL_DOCUMENT_WORKLIST:
    case ActionTypes.ERROR_LOADING_EXTERNAL_DOCUMENT_OPTIONS:
      return { ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: false, error: action.payload.message, loaded: false } } };
    case ActionTypes.SET_CURRENT_EXTERNAL_DOCUMENT_WORKLIST:
      return {
        ...state,
        current: { ...state.current, document: action.payload.document },
      };
    case ActionTypes.LOADING_CURRENT_SOURCE_DOCUMENT_FILE:
      return {
        ...state,
        current: { ...state.current, loading: true },
      };
    case ActionTypes.ERROR_LOADING_CURRENT_SOURCE_DOCUMENT_FILE:
      return {
        ...state,
        current: { ...state.current, loading: false, loaded: false, error: action.payload.message },
      };
    case ActionTypes.LOADED_CURRENT_SOURCE_DOCUMENT_FILE:
      return {
        ...state,
        current: { ...state.current, loading: false, loaded: true, document: action.payload },
      };
    case ActionTypes.DELETED_EXTERNAL_DOCUMENT_WORKLIST_ITEM:
      return {
        ...state,
        externalSourceDocumentForWorklist: filter(state.externalSourceDocumentForWorklist, id => id !== action.payload.externalSourceDocumentId),
        current: { ...state.current, document: null },
      };
    case ActionTypes.RESTORED_EXTERNAL_DOCUMENT_WORKLIST_ITEM:
      return {
        ...state,
        all: { ...state.all, [action.payload.externalSourceDocumentId]: { ...state.all[action.payload.externalSourceDocumentId], status: action.payload.status } },
        current: { ...state.current, document: { ...state.current.document, status: action.payload.status } },
      };
    default:
      return state || INITIAL_STATE;
  }
};

export const getExternalSourceDocumentList = curry(({ localState }) => {
  const documents = get(localState, ["all"], {});
  return map(localState.externalSourceDocumentForWorklist, key => documents[key]);
});

export const isPageLoading = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState?.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loading"], false);
});

export const isPageLoaded = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState?.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loaded"], false);
});

export const getPageErrorMessage = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState?.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "error"], null);
});

export const getPageInfo = curry(({ localState }) => get(localState, ["pageInfo"], null));

export const getSearchParams = curry(({ localState }) => get(localState, ["searchParams"], null));

export const getCurrentExternalSourceDocument = curry(({ localState }) => localState?.current.document);

export const isCurrentExternalSourceDocumentLoading = curry(({ localState }) => localState.current.loading === true);

export const getExternalSourceDocumentOptions = curry(({ localState }) => localState?.options);
