import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ContextItem } from "components/items/default-item";

const useStyles = makeStyles(theme => ({
  textarea: {
    flexDirection: "column",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  textareaLabel: {
    width: "100%",
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: 192,
    },
  },
  inputLabel: {
    width: "100%",
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: 192,
    },
  },
}));

const ApplicationItem = props => <ContextItem {...props} />;

const labelSetting = {
  variant: "caption",
  color: "textSecondary",
};

export const ApplicationItemWithWidth = ({ labelProps, ...other }) => {
  const classes = useStyles();
  return (<ApplicationItem labelProps={{ ...labelSetting, ...labelProps }} labelClassName={classes.textareaLabel} {...other} />);
};

export const ApplicationItemTextArea = ({ labelProps, ...other }) => {
  const classes = useStyles();
  return <ApplicationItem className={classes.textarea} alignment="items-start" labelProps={{ ...labelSetting, ...labelProps }} labelClassName={classes.textareaLabel} {...other} />;
};

export default ApplicationItem;
