import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { setSideBarStatus } from "app/store/actions/sidebars.actions";
import { isSidebarOpen } from "app/store/reducers/sidebars.reducer";

import Sidebar from "./sidebar";
import PanelContent from "./content";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 100%",
    height: "auto",
    overflow: "hidden",
    backgroundColor: theme.palette.background.main,
  },
  container: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "row",
    minHeight: 0,
    overflow: "hidden",

    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(1),
    },
  },
}));

const ChatPanel = ({
  renderContent,
  renderHeader,
  emptyIcon,
  emptyTitle,
  renderEmptyContent,
  loading,
  empty,
  renderStaticSideBar,
  renderSidebarHeader,
  renderSidebarContent,
  headerElevation,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isOpen = useSelector(isSidebarOpen);
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const menuVariant = matches ? "permanent" : "temporary";

  useEffect(() => {
    if (matches !== isOpen) {
      dispatch(setSideBarStatus(matches));
    }
    // eslint-disable-next-line
  }, [matches])

  return (
    <div className={classes.root}>
      <div
        id="secondary-header"
        className={classes.secondaryHeader}
      />
      <div className={classes.container}>
        <Sidebar
          renderStaticSideBar={renderStaticSideBar}
          renderSidebarHeader={renderSidebarHeader}
          renderSidebarContent={renderSidebarContent}
          menuVariant={menuVariant}
        />
        <PanelContent
          empty={empty}
          renderContent={renderContent}
          renderHeader={renderHeader}
          emptyIcon={emptyIcon}
          emptyTitle={emptyTitle}
          renderEmptyContent={renderEmptyContent}
          loading={loading}
          menuVariant={menuVariant}
          headerElevation={headerElevation}
        />
      </div>
    </div>
  );
};

ChatPanel.prototype = {
  selectedItem: PropTypes.object,
  renderSidebarContent: PropTypes.func,
  renderSidebarHeader: PropTypes.func,

  renderContent: PropTypes.func.isRequired,
  renderHeader: PropTypes.func.isRequired,
  renderEmptyContent: PropTypes.func,
  emptyIcon: PropTypes.string,
  emptyTitle: PropTypes.string,
};

export default ChatPanel;

export { default as PanelHeaderAction } from "portals/headerAction";
export { default as SecondaryHeader } from "portals/secondaryHeaderPortal";
