import React from "react";
import { Field } from "formik";
import { map } from "lodash";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import Form from "components/form";
import { validateEmailFormat, validatePhoneFormat } from "utils/validators";
import { TextInput } from "components/inputs";
import { getUser } from "app/auth/store/reducers/user.reducer";
import { updateUserProfile } from "app/main/users/actions/users.actions";
import { AutoComplete } from "components/inputs/auto-complete";

const mobileNumberLabel = "Mobile Number";
const emailAddressLabel = "Email Address";
const notificationPreferenceLabel = "Notification Preference";

const validateEmail = validateEmailFormat(emailAddressLabel);
const validatePhone = validatePhoneFormat(mobileNumberLabel);

const options = [
  { value: "SMS", label: "SMS" },
  { value: "Email", label: "Email" },
];

const schema = Yup.object().shape({
  userProfile: Yup.object().shape({
    phoneMobile: Yup.string().max(12, "Too long!").nullable(),
    email: Yup.string().max(100, "Too long!").nullable(),
  }),
});

const UserProfileForm = props => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getUser);

  const { email, phoneMobile, notificationPreference } = currentUser;
  const initialValues = { userProfile: { email, phoneMobile, notificationPreference: map(notificationPreference, x => ({ label: x, value: x })) } };

  const handleSubmit = ({ userProfile }, { setErrors }) => {
    const data = {
      ...userProfile,
      notificationPreference: map(userProfile.notificationPreference, x => x.value),
    };

    dispatch(updateUserProfile(data)).then(response => {
      if (response.error !== true) {
        props.onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      contentProps={props}
      validationSchema={schema}
      content={() => (
        <>
          <Field
            name="userProfile.phoneMobile"
            label={mobileNumberLabel}
            component={TextInput}
            validate={validatePhone}
          />
          <Field
            name="userProfile.email"
            component={TextInput}
            label={emailAddressLabel}
            validate={validateEmail}
          />
          <Field
            name="userProfile.notificationPreference"
            label={notificationPreferenceLabel}
            component={AutoComplete}
            options={options}
            isMulti
          />
        </>
      )}
    />
  );
};

export default UserProfileForm;
