/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { isEmpty, debounce } from "lodash";
import qs from "qs";

import ChatPanel from "components/items/chat-panel/index";
import PatientReferralViewSelector from "app/main/referrals/components/patient-referral-view-selector";
import DefaultListItem from "components/items/default-list-item";
import InfiniteLoadingList from "components/items/infinite-Loading-list";
import { fetchPatientReferrals, fetchPatientActiveReferrals } from "app/main/referrals/actions/patientReferrals.actions";
import { getExistingPatientById } from "app/main/patients/actions/patients.actions";
import { getCurrentPatient, isCurrentPatientLoading } from "app/main/patients/reducers/patients.reducers";
import PatientDetails, { NameLabel } from "app/main/patients/components/patient-details";
import AdvancedFilter from "components/items/advanced-filter";
import { isMobileContentSidebarOpen } from "app/store/reducers/sidebars.reducer";
import { setMobileContentSidebarStatus } from "app/store/actions/sidebars.actions";
import ReferralListPanel from "app/main/patientReferralWorklist/components/referral-list-panel";
import ActiveReferralListPanel from "app/main/patientReferralWorklist/components/active-referral-list-panel";
import ViewPatientRecordButton from "app/main/patients/components/view-patient-record-button";
import { getPatientReferralSearchParams } from "app/main/referrals/reducers/patientReferrals.reducers";
import ReferralPatientDetails from "app/main/referrals/components/referral-patient-details";
import { resetReferralWorklistFilter } from "app/main/referralWorklist/actions/referralWorklist.actions";
import { ReferralWorklistIcon } from "helpers/icon-finder";
import { getPatientLabel } from "utils/get-environment-variables";

import { searchPatientReferrals } from "../actions";
import { getPatientReferralsList, getPatientReferralWorklistPageInfo, getPatientReferralWorklistSearchParams, isPageLoading, getPageErrorMessage } from "../reducers/patientReferralWorklist.reducers";

const patientLabel = getPatientLabel();

const PatientReferralWorklistPage = ({ forceLoad }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const patients = useSelector(getPatientReferralsList);
  const pageInfo = useSelector(getPatientReferralWorklistPageInfo);
  const searchParams = useSelector(getPatientReferralWorklistSearchParams);
  const loading = useSelector(state => isPageLoading(state, pageInfo?.pageNumber));
  const error = useSelector(state => getPageErrorMessage(state, pageInfo?.pageNumber));
  const isSidebarOpen = useSelector(isMobileContentSidebarOpen);
  const patientReferralSearchParams = useSelector(getPatientReferralSearchParams);
  const [loadingItem, setLoadingItem] = useState(null);

  const selected = useSelector(getCurrentPatient);
  const loadingCurrent = useSelector(isCurrentPatientLoading);

  const fetchAllPatientReferrals = patientId => {
    const getActives = dispatch(fetchPatientActiveReferrals(patientId));
    const getAll = dispatch(fetchPatientReferrals({ ...patientReferralSearchParams, patientId }, 1, true));
    return Promise.all([getActives, getAll]);
  };

  useEffect(() => {
    dispatch(resetReferralWorklistFilter());
    dispatch(searchPatientReferrals());
  }, []);

  useEffect(() => {
    const queryString = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    const { patientId } = queryString;
    if (patientId) {
      dispatch(getExistingPatientById(patientId));
      fetchAllPatientReferrals(patientId);
    }
  }, []);

  const onClickItem = selectedItem => {
    setLoadingItem(selectedItem.patientId);

    if (isSidebarOpen) {
      dispatch(setMobileContentSidebarStatus());
    }
    if (selectedItem.referralCount > 0) {
      fetchAllPatientReferrals(selectedItem.patientId);
    }
    dispatch(getExistingPatientById(selectedItem.patientId, true)).then(() => {
      history.push(`/patientReferralWorklist?patientId=${selectedItem.patientId}`);
    });
  };

  const goToPatientMedicalRecordPage = () => history.push(`/PatientReferralWorklist/patients/${selected.patientId}`);

  const onSearch = useCallback(debounce((params, pageNumber, pageSize, forceToReLoad) => {
    dispatch(searchPatientReferrals(params, pageNumber, pageSize, forceToReLoad));
  }, 300));

  const onSearchText = val => onSearch({ ...searchParams, name: val }, 1, pageInfo.pageSize, true);

  const renderItem = item => {
    const isActive = !isEmpty(selected) && selected.patientId === item.patientId;
    return (
      <DefaultListItem
        classes={{ root: "h-76" }}
        id={item.patientId}
        onClick={isActive ? null : () => onClickItem(item)}
        active={isActive}
        content={(
          <div className="flex justify-between items-center">
            <div>
              <NameLabel name={item.displayNameFull} variant="body1" />
              {item.referralCount > 0
                && <Typography variant="caption">{`${item.referralCount} referral${item.referralCount > 1 ? "s" : ""}`}</Typography>}
            </div>
            {loadingItem === item.patientId && loadingCurrent && <CircularProgress size={20} />}
          </div>
        )}
      />
    );
  };

  const renderSidebarContent = () => (
    <InfiniteLoadingList
      initialLoad
      data={patients}
      renderItem={item => renderItem(item)}
      loading={loading}
      error={error}
      isEmptyStatus={isEmpty(patients)}
      total={pageInfo.totalRecords}
      pageStart={pageInfo.pageNumber}
      loadFunction={page => {
        const number = page === undefined ? 1 : pageInfo.pageNumber + 1;
        return onSearch(searchParams, number, pageInfo.pageSize, forceLoad);
      }}
    />
  );

  const renderHeader = () => (
    <Grid container className="py-24 px-16">
      <PatientDetails
        renderContent={(
          <ReferralPatientDetails
            patient={selected}
            goToPatientRecordPage={goToPatientMedicalRecordPage}
          />
        )}
        renderRightContent={<ViewPatientRecordButton onClick={goToPatientMedicalRecordPage} />}
      />
    </Grid>
  );

  const renderContent = () => (
    <>
      <ActiveReferralListPanel
        patientId={selected.patientId}
        fetchAllPatientReferrals={fetchAllPatientReferrals}
      />
      <ReferralListPanel patientId={selected.patientId} isLast />
    </>
  );

  return (
    <ChatPanel
      empty={isEmpty(selected)}
      renderSidebarHeader={() => (
        <>
          <div className="mb-8">
            <PatientReferralViewSelector />
          </div>
          <AdvancedFilter
            renderFilterInfo={<Typography variant="caption">Total Results: {pageInfo.totalRecords}</Typography>}
            onSearch={onSearchText}
            searchValue={searchParams?.name ?? ""}
          />
        </>
      )}
      renderSidebarContent={() => renderSidebarContent()}
      renderContent={() => renderContent()}
      renderHeader={() => renderHeader()}
      emptyIcon={<ReferralWorklistIcon />}
      emptyTitle={`Select a ${patientLabel.single} to view details`}
    />
  );
};

export default PatientReferralWorklistPage;
