import { blue, green, red, indigo } from "@material-ui/core/colors";

export default function getEndorsementStatus(decision) {
  switch (decision) {
    case "Supported":
      return {
        icon: "check",
        color: green[600],
        label: "SUPPORTED",
      };
    case "NotSupported":
      return {
        icon: "clear",
        color: red[600],
        label: "NOT SUPPORTED",
      };
    case "RequireMoreInformation":
      return {
        icon: "details",
        color: indigo[600],
        label: "More Information Required",
      };
    default:
      return {
        icon: "details",
        color: blue[600],
        label: "INCOMPLETE",
      };
  }
}
