import React from "react";
import { useHistory } from "react-router-dom";

import { ReferralWorklistIcon } from "helpers/icon-finder";
import DefaultPanelLayout from "components/items/default-panel-layout";
import PatientReferralsList from "app/main/patients/referrals/components/patient-referrals-list";

const ReferralListPanel = ({
  hasPermissionReferralsCreate,
  patientId,
  ...other
}) => {
  const history = useHistory();
  return (
    <DefaultPanelLayout
      title="Referrals"
      icon={<ReferralWorklistIcon />}
      {...other}
    >
      <PatientReferralsList
        patientId={patientId}
        fromUrl={history.location.pathname + history.location.search}
        showLoadReferrals={false}
      />
    </DefaultPanelLayout>
  );
};

export default ReferralListPanel;
