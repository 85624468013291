import React from "react";
import { map, sumBy, round } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Table, TableCell, TableRow, TableBody, TableHead, TableFooter, TableContainer } from "@material-ui/core";
import { TreatmentIcon } from "helpers/icon-finder";
import EmptyState from "components/items/empty-state";
import formatDate from "helpers/format-date";
import { formatCostOutput } from "helpers/format-cost"

import DefaultPanelLayout from "components/items/default-panel-layout";
import { loadApplicationDispensing } from "app/main/applicationWorklist/actions/applicationDispensing.actions";
import { getApplicationDispensing, isApplicationDispensingLoading, isApplicationDispensingLoaded } from "app/main/applicationWorklist/reducers/applicationDispensing.reducers";

const useStyles = makeStyles({
  cost: () => ({
    fontWeight: "bold",
    textAlign: "right",
    alignItems: "center",
  }),
  costContent: () => ({
    textAlign: "right",
  }),
});

const getQuantityDisplay = (quantity, packSize) => {
  if (packSize && packSize !== "0") {
    return `${quantity} x ${packSize}`;
  }

  return quantity;
};

const ApplicationDispensingPage = props => {
  const { id } = props.application;

  const classes = useStyles();
  const dispatch = useDispatch();
  const applicationDispensing = useSelector(state => getApplicationDispensing(state, id));
  const applicationDispensingLoading = useSelector(state => isApplicationDispensingLoading(state, id));
  const applicationDispensingLoaded = useSelector(state => isApplicationDispensingLoaded(state, id));
  const isEmpty = applicationDispensingLoaded && applicationDispensing.length === 0;
  const totalCost = round(sumBy(applicationDispensing, "cost"), 2);

  React.useEffect(() => {
    dispatch(loadApplicationDispensing(id, props.orgUnitId));
  }, [dispatch, id, props.orgUnitId]);

  const renderTableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell>Dispensed Date</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Quantity</TableCell>
        <TableCell className={classes.cost}>Cost</TableCell>
      </TableRow>
    </TableHead>
  );

  const renderTableRow = item => (
    <TableBody key={item.id}>
      <TableRow>
        <TableCell>{formatDate(item.dispensedDate)}</TableCell>
        <TableCell>{item.displayName}</TableCell>
        <TableCell>{getQuantityDisplay(item.quantity, item.packSize)}</TableCell>
        <TableCell className={classes.costContent}>{formatCostOutput(item.cost)}</TableCell>
      </TableRow>
    </TableBody>
  );

  const renderTableFooter = () => (
    <TableFooter>
      <TableRow>
        <TableCell className={classes.cost} colSpan={3}>Total Cost</TableCell>
        <TableCell className={classes.cost}>{formatCostOutput(totalCost)}</TableCell>
      </TableRow>
    </TableFooter>

  );

  const ApplicationDispensingList = () => (
    <TableContainer className="flex-1">
      <Table>
        {renderTableHeader()}
        {map(applicationDispensing, renderTableRow)}
        {renderTableFooter()}
      </Table>
    </TableContainer>
  );

  return (
    <DefaultPanelLayout
      title="Dispensing"
      icon={<TreatmentIcon />}
      isLoading={applicationDispensingLoading}
      isEmpty={isEmpty}
      emptyState={<EmptyState title="No Dispensing Recorded" />}
    >
      <ApplicationDispensingList />
    </DefaultPanelLayout>
  );
};

export default ApplicationDispensingPage;
