import React from "react";
import { Field } from "formik";
import { map, isNaN, filter } from "lodash";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useSelector } from "react-redux";
import Form from "components/form";
import RhombusOutlineIcon from "mdi-react/RhombusOutlineIcon";
import TimetableIcon from "mdi-react/TimetableIcon";
import { CheckboxInput, SelectInput, RadioButtonsGroup, NumberInput } from "components/inputs";
import { FilterSection } from "components/items/advanced-filter";
import DrugSelector from "app/main/applications/components/drug-selector";
import { MedicationIcon, TreatmentIcon, NoteIcon, DoseIcon } from "helpers/icon-finder";
import { TextInput, NoteInput, CurrencyInput } from "components/inputs/text-input";
import calculateCostEstimation from "helpers/calculate-cost-estimation";
import { fetchIndicationTermResults } from "app/main/codedTerms/actions/coded-term.actions";
import { useExternalMedicationCostQuery } from "app/auth/store/reducers/system-configuration";
import CodedTermSelector from "app/main/codedTerms/components/coded-term-selector";
import CodeSetValueSelector from "app/main/codeSet/components/code-set-value-selector";
import RestrictionDisplay from "./restriction-display";
import DrugCostInformation from "./drug-cost";

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    marginLeft: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
  doseFrequencyContainer: {
    width: "70%",
  },
}));

const durationTypeChoices = [
  { value: "TreatmentCourse", label: "Treatment Course" },
  { value: "CyclicalTreatment", label: "Cyclical Treatment" },
  { value: "OngoingTreatment", label: "Ongoing Treatment" },
];

const durationUnitCourseBasedChoices = [
  { value: "Doses", label: "Doses" },
  { value: "Days", label: "Days" },
  { value: "Weeks", label: "Weeks" },
  { value: "Months", label: "Months" },
];

const durationUnitCyclicalChoices = [
  { value: "Cycles", label: "Cycles" },
];

const durationUnitOngoingChoices = [
  { value: "Months", label: "Months" },
];

function getDurationUnitChoices(type) {
  switch (type) {
    case "TreatmentCourse":
    default:
      return durationUnitCourseBasedChoices;
    case "CyclicalTreatment":
      return durationUnitCyclicalChoices;
    case "OngoingTreatment":
      return durationUnitOngoingChoices;
  }
}

const normaliseStrengthNames = drugResultJsonString => {
  const json = JSON.parse(drugResultJsonString);
  return map(json.strengths, strength => ({
    value: strength.name,
    label: strength.name,
  }));
};

const drugSelectionChange = (newValue, setFieldValue) => {
  const searchedDrugSelected = newValue?.value;
  setFieldValue("treatment.drug", searchedDrugSelected ? newValue?.value.drugFullName : newValue?.inputValue ?? null);
  setFieldValue("treatment.drugResultJson", searchedDrugSelected ? JSON.stringify(newValue?.value) : null);
  setFieldValue("treatment.form", searchedDrugSelected ? newValue?.value.formName : null);
  setFieldValue("treatment.strength", null);
  setFieldValue("treatment.unitOfMeasureCost", null);
};

const autoCalculateCostEstimation = (values, setFieldValue) => {
  const estimationCost = calculateCostEstimation(values);

  if (isNaN(estimationCost)) {
    return;
  }

  const generateCostPerValue = () => {
    // remove the last character from the duration unit when the duration is singular (e.g. 1 day not 1 days)
    if (values.treatmentDurationValue === "1") {
      return values.treatmentDurationUnit.slice(0, -1);
    }

    return values.treatmentDurationUnit;
  };

  setFieldValue("treatment.cost", estimationCost);
  setFieldValue("treatment.costPer", `${values.treatmentDurationValue} ${generateCostPerValue()}`);
};

const TreatmentComponent = ({
  schema,
  treatment,
  fieldLabels,
  fieldSettings,
  handleSubmit,
  helperText,
  drugSearchEnabled,
  urgencyOfTreatmentStatusChoices,
  isUrgentTreatment,
  orgUnitId,
  other,
}) => {
  const classes = useStyles();
  const costSearchEnabled = useSelector(useExternalMedicationCostQuery);

  return (
    <Form
      initialValues={{ treatment }}
      onSubmit={handleSubmit}
      contentProps={other}
      validationSchema={schema}
      content={({ setFieldValue, values }) => {
        const searchedDrugSelected = values.treatment.drugResultJson != null;
        return (
          <>
            {drugSearchEnabled ? (
              <Field
                name="treatment.drug"
                component={DrugSelector}
                icon={<MedicationIcon />}
                label={fieldLabels.drug}
                onChange={value => drugSelectionChange(value, setFieldValue)}
                orgUnitId={orgUnitId}
                required
                initialDrugOptions={other.initialDrugOptions}
              />
            ) : (
              <Field
                name="treatment.drug"
                component={TextInput}
                icon={<MedicationIcon />}
                label={fieldLabels.drug}
                required
              />
            )}
            <div className="flex flex-col sm:flex-row">
              {fieldSettings?.form && (
              <Field
                name="treatment.form"
                component={TextInput}
                label={fieldLabels.form}
                required={fieldSettings.form.required}
                className="flex-1"
                helperText={helperText.form}
                maxLength={100}
                disabled={searchedDrugSelected}
              />
              )}
              {fieldSettings?.strength && (
                <div className="flex-1 mt-16 sm:mt-0">
                  {searchedDrugSelected
                    ? (
                      <Field
                        name="treatment.strength"
                        component={SelectInput}
                        label={fieldLabels.strength}
                        required={fieldSettings.strength.required}
                        options={normaliseStrengthNames(values.treatment.drugResultJson)}
                      />
                    )
                    : (
                      <Field
                        name="treatment.strength"
                        component={TextInput}
                        label={fieldLabels.strength}
                        required={fieldSettings.strength.required}
                        maxLength={100}
                      />
                    )}
                </div>
              )}
            </div>
            {searchedDrugSelected && <RestrictionDisplay drugResultJson={values.treatment.drugResultJson} strengthName={values.treatment.strength} />}

            {fieldSettings?.urgencyOfTreatmentGroup && (
            <FilterSection label={fieldLabels.urgencyTreatmentType} withDivider>
              <Field
                className="px-16"
                name="treatment.urgencyTreatmentType"
                component={RadioButtonsGroup}
                choices={filter(urgencyOfTreatmentStatusChoices, x => x.isUrgent === true)}
                displayRow
                allowUnCheck
                spacing={0}
                hideError
                required={fieldSettings.urgencyOfTreatmentGroup.required}
                onChange={value => {
                  if (value === null) {
                    setFieldValue("treatment.urgencyOfTreatmentRationale", null);
                  }
                }}
              />
              <Field
                className="px-16"
                name="treatment.urgencyTreatmentType"
                component={RadioButtonsGroup}
                helperText={helperText.urgencyTreatmentType}
                choices={filter(urgencyOfTreatmentStatusChoices, x => x.isUrgent === false)}
                displayRow
                allowUnCheck
                spacing={0}
                required={fieldSettings.urgencyOfTreatmentGroup.required}
                onChange={() => setFieldValue("treatment.urgencyOfTreatmentRationale", null)}
              />
              {isUrgentTreatment(values.treatment.urgencyTreatmentType) && (
              <Field
                name="treatment.urgencyOfTreatmentRationale"
                component={NoteInput}
                helperText={helperText.urgencyOfTreatmentRationale}
                icon={<NoteIcon />}
                label={fieldLabels.urgencyOfTreatmentRationale}
                rows="4"
                maxLength={1000}
              />
              )}
            </FilterSection>
              )}
            {fieldSettings?.brandGroup && (
              <Field
                name="treatment.brandSpecified"
                component={CheckboxInput}
                helperText={helperText.brandSpecified}
                label={fieldLabels.brandSpecified}
                required={fieldSettings.brandGroup.required}
                onChange={value => {
                  if (value === false) {
                    setFieldValue("treatment.brand", null);
                  }
                }}
              />
              )}
            {values.treatment.brandSpecified && fieldSettings?.brandGroup && (
              <Field
                name="treatment.brand"
                component={TextInput}
                icon={<NoteIcon />}
                label={fieldLabels.brand}
                required={fieldSettings.brandGroup.required}
                maxLength={50}
              />
            )}

            {fieldSettings?.medicationDoseAndFrequency && (
              <Field
                name="treatment.medicationDoseAndFrequency"
                component={TextInput}
                icon={<DoseIcon />}
                label={fieldLabels.medicationDoseAndFrequency}
                required={fieldSettings.medicationDoseAndFrequency.required}
                maxLength={200}
              />
              )}
            {fieldSettings?.treatmentDurationGroup && (
              <>
                <Typography variant="body1" className={clsx("font-bold", classes.sectionTitle)}>Treatment Duration </Typography>
                <div className="flex flex-col sm:flex-row items-center">
                  <Field
                    name="treatment.treatmentDurationType"
                    label={fieldLabels.treatmentDurationType}
                    component={SelectInput}
                    options={durationTypeChoices}
                    required={fieldSettings.treatmentDurationGroup.required}
                    onChange={() => {
                      setFieldValue("treatment.treatmentDurationValue", null);
                      setFieldValue("treatment.treatmentDurationUnit", null);
                    }}
                  />
                  <div className="mx-32 sm:mx-16"> ,</div>
                  <Field
                    name="treatment.treatmentDurationValue"
                    component={NumberInput}
                    label={fieldLabels.treatmentDurationValue}
                    required={fieldSettings.treatmentDurationGroup.required}
                    onChange={value => autoCalculateCostEstimation({ ...values.treatment, treatmentDurationValue: value }, setFieldValue)}
                  />
                  <Field
                    name="treatment.treatmentDurationUnit"
                    component={SelectInput}
                    options={getDurationUnitChoices(values.treatment.treatmentDurationType)}
                    label={fieldLabels.treatmentDurationUnit}
                    onChange={value => autoCalculateCostEstimation({ ...values.treatment, treatmentDurationUnit: value }, setFieldValue)}
                    required={fieldSettings.treatmentDurationGroup.required}
                  />
                </div>
              </>
            )}

            {(fieldSettings?.medicationDose || fieldSettings?.medicationFrequency) && (
              <>
                <Typography variant="body1" className={clsx("font-bold", classes.sectionTitle)}>Dose & Frequency </Typography>

                <div className={clsx("flex flex-col sm:flex-row items-center", classes.doseFrequencyContainer)}>
                  {fieldSettings?.medicationDose && (
                    <>
                      <Field
                        name="treatment.medicationDose"
                        component={NumberInput}
                        label={fieldLabels.medicationDose}
                        required={fieldSettings.medicationDose.required}
                        onChange={value => autoCalculateCostEstimation({ ...values.treatment, medicationDose: value }, setFieldValue)}
                        className="flex-1"
                        suffix={values.treatment.unitOfMeasure}
                      />
                      {fieldSettings?.medicationFrequency && (<div className="mx-32 sm:mx-16"> ,</div>)}
                    </>
                  )}
                  {fieldSettings?.medicationFrequency && (
                    <>
                      <Field
                        name="treatment.medicationFrequency"
                        component={NumberInput}
                        label={fieldLabels.medicationFrequency}
                        required={fieldSettings.medicationFrequency.required}
                        onChange={value => autoCalculateCostEstimation({ ...values.treatment, medicationFrequency: value }, setFieldValue)}
                        className="flex-1"
                      />
                      <div className="mx-32 sm:mx-16">times per Day</div>
                    </>
                    )}
                </div>
              </>
            )}
            {fieldSettings?.unitOfMeasureGroup && (
              <>
                <Typography variant="body1" className={clsx("font-bold", classes.sectionTitle)}>Inventory Cost </Typography>
                <div className="flex flex-col sm:flex-row sm:items-center">
                  {costSearchEnabled && (
                    <DrugCostInformation
                      drugResultsJson={values.treatment.drugResultJson}
                      strength={values.treatment.strength}
                    />
                  )}
                </div>
              </>
            )}
            {fieldSettings?.costGroup && (
              <>
                <Typography variant="body1" className={clsx("font-bold", classes.sectionTitle)}>Application Cost </Typography>
                <div className="flex flex-col sm:flex-row sm:items-center">
                  <Field
                    name="treatment.cost"
                    component={CurrencyInput}
                    label={fieldLabels.cost}
                    required={fieldSettings.costGroup.required}
                    className="flex-1"
                  />
                  <>
                    <div className="mx-32 sm:mx-16">per</div>
                    <Field
                      name="treatment.costPer"
                      component={TextInput}
                      label={fieldLabels.costPer}
                      required={fieldSettings.costGroup.required}
                      className="flex-1 mt-16 sm:mt-0"
                      maxLength={50}
                    />
                  </>
                </div>
              </>
            )}

            {fieldSettings?.requestedDuration && (
              <Field
                name="treatment.requestedDuration"
                component={TextInput}
                label={fieldLabels.requestedDuration}
                required={fieldSettings.requestedDuration.required}
                icon={<TimetableIcon />}
              />
            )}

            {fieldSettings?.route && (
              <Field
                name="treatment.route"
                component={CodeSetValueSelector}
                useCodeAsValue
                codeSetTypeCode="TreatmentRoute"
                label={fieldLabels.route}
                required={fieldSettings.route.required}
                icon={<RhombusOutlineIcon />}
                maxLength={50}
                isCreatable
                helperText={helperText.route}
                onChange={route => setFieldValue("treatment.route", route?.value ?? route?.inputValue)}
              />
            )}
            {fieldSettings?.sMFApprovedIndications && (
              <Field
                name="treatment.smfApprovedIndications"
                component={CheckboxInput}
                helperText={helperText.SMFApprovedIndications}
                label={fieldLabels.sMFApprovedIndications}
                required={fieldSettings.sMFApprovedIndications.required}
                onChange={value => {
                  if (value === false) {
                    setFieldValue("treatment.smfApprovedIndicationsComments", null);
                  }
                }}
              />
              )}
            {values.treatment.smfApprovedIndications && fieldSettings?.sMFApprovedIndications && (
              <Field
                name="treatment.smfApprovedIndicationsComments"
                component={NoteInput}
                icon={<NoteIcon />}
                helperText={helperText.SMFApprovedIndicationsComments}
                label={fieldLabels.sMFApprovedIndicationsComments}
                required={fieldSettings.sMFApprovedIndications.required}
                rows="4"
                maxLength={1000}
              />
            )}
            {fieldSettings?.tGAApprovedMedication && (
              <Field
                name="treatment.tgaApprovedMedication"
                component={CheckboxInput}
                helperText={helperText.TGAApprovedMedication}
                label={fieldLabels.tGAApprovedMedication}
                required={fieldSettings.tGAApprovedMedication.required}
              />
              )}
            {fieldSettings?.tGALicencedIndication && (
              <Field
                name="treatment.tgaLicencedIndication"
                component={CheckboxInput}
                helperText={helperText.TGALicencedIndication}
                label={fieldLabels.tGALicencedIndication}
                required={fieldSettings.tGALicencedIndication.required}
              />
              )}
            {fieldSettings?.pBSApprovedIndicationGroup && (
              <Field
                name="treatment.pbsApprovedIndication"
                component={CheckboxInput}
                helperText={helperText.PBSApprovedIndication}
                label={fieldLabels.pBSApprovedIndication}
                required={fieldSettings.pBSApprovedIndicationGroup.required}
                onChange={value => {
                  if (value === false) {
                    setFieldValue("treatment.pbsApprovedIndicationComments", null);
                  }
                }}
              />
              )}

            {values.treatment.pbsApprovedIndication && fieldSettings?.pBSApprovedIndicationGroup && (
              <Field
                name="treatment.pbsApprovedIndicationComments"
                component={NoteInput}
                icon={<NoteIcon />}
                helperText={helperText.PBSApprovedIndicationComments}
                label={fieldLabels.pBSApprovedIndicationComments}
                required={fieldSettings.pBSApprovedIndicationGroup.required}
                rows="4"
                maxLength={1000}
              />
            )}

            {fieldSettings?.nursingConsiderationsGroup && (
              <Field
                name="treatment.nursingConsiderations"
                component={CheckboxInput}
                helperText={helperText.nursingConsiderations}
                label={fieldLabels.nursingConsiderations}
                required={fieldSettings.nursingConsiderationsGroup.required}
                onChange={value => {
                  if (value === false) {
                    setFieldValue("treatment.nursingConsiderationRationale", null);
                  }
                }}
              />
              )}
            {values.treatment.nursingConsiderations && fieldSettings?.nursingConsiderationsGroup && (
              <Field
                name="treatment.nursingConsiderationRationale"
                component={NoteInput}
                helperText={helperText.nursingConsiderationRationale}
                icon={<NoteIcon />}
                label={fieldLabels.nursingConsiderationRationale}
                required={fieldSettings.nursingConsiderationsGroup.required}
                rows="4"
                maxLength={1000}
              />
            )}

            {fieldSettings?.compassionateAccess && (
              <Field
                name="treatment.compassionateAccess"
                component={CheckboxInput}
                label={fieldLabels.compassionateAccess}
                required={fieldSettings.compassionateAccess.required}
                helperText={helperText.compassionateAccess}
              />
              )}

            {fieldSettings?.priorApproval && (
            <Field
              name="treatment.priorApproval"
              helperText={helperText.priorApproval}
              component={CheckboxInput}
              label={fieldLabels.priorApproval}
              required={fieldSettings.priorApproval.required}
            />
              )}

            {fieldSettings?.indication && (
              <Field
                name="treatment.indicationTerm"
                component={CodedTermSelector}
                label={fieldLabels.indication}
                maxLength={120}
                required={fieldSettings.indication.required}
                apiCall={fetchIndicationTermResults}
                helperText={helperText.indication}
                onChange={codedTerm => {
                  if (codedTerm == null) {
                    setFieldValue("treatment.indicationTerm", null);
                    setFieldValue("treatment.indication", null);
                    return;
                  }

                  const manuallyAdded = codedTerm.inputValue;
                  const indicationTerm = manuallyAdded ? codedTerm.inputValue : codedTerm.term;

                  setFieldValue("treatment.indicationTerm", indicationTerm);
                  setFieldValue("treatment.indication", {
                    indicationTerm,
                    codedTerm: manuallyAdded ? null : codedTerm,
                  });
                }}
              />
              )}

            {fieldSettings?.additionalIndicationDetails && (
            <Field
              name="treatment.additionalIndicationDetails"
              component={NoteInput}
              icon={<NoteIcon />}
              label={fieldLabels.additionalIndicationDetails}
              required={fieldSettings.additionalIndicationDetails.required}
              maxLength={1000}
            />
                )}

            {fieldSettings?.relevantMedicalHistory && (
            <Field
              name="treatment.relevantMedicalHistory"
              component={NoteInput}
              icon={<NoteIcon />}
              label={fieldLabels.relevantMedicalHistory}
              required={fieldSettings.relevantMedicalHistory.required}
              rows="4"
              maxLength={1000}
            />
                )}

            {fieldSettings?.hasTreatmentCommenced && (
            <Field
              name="treatment.hasTreatmentCommenced"
              component={CheckboxInput}
              label={fieldLabels.hasTreatmentCommenced}
              required={fieldSettings.hasTreatmentCommenced.required}
              helperText={helperText.hasTreatmentCommenced}
            />
                )}
            {fieldSettings?.relevantTreatmentSoFar && (
            <Field
              name="treatment.relevantTreatmentSoFar"
              component={NoteInput}
              icon={<TreatmentIcon />}
              label={fieldLabels.relevantTreatmentSoFar}
              required={fieldSettings.relevantTreatmentSoFar.required}
              rows="4"
              helperText={helperText.relevantTreatmentSoFar}
              maxLength={500}
            />
                )}
            {fieldSettings?.treatmentIntent && (
            <Field
              name="treatment.treatmentIntent"
              component={CodeSetValueSelector}
              codeSetTypeCode="TreatmentIntent"
              useCodeAsValue
              icon={<NoteIcon />}
              label={fieldLabels.treatmentIntent}
              required={fieldSettings.treatmentIntent.required}
              helperText={helperText.treatmentIntent}
              isCreatable
              onChange={treatmentIntent => setFieldValue("treatment.treatmentIntent", treatmentIntent?.value ?? treatmentIntent?.inputValue)}
              maxLength={1000}
            />
                )}
            {fieldSettings?.rationale && (
            <Field
              name="treatment.rationale"
              component={NoteInput}
              icon={<NoteIcon />}
              label={fieldLabels.rationale}
              required={fieldSettings.rationale.required}
              rows="4"
              helperText={helperText.supportingEvidence}
              maxLength={500}
            />
                )}
            {fieldSettings?.safetyConcerns && (
            <Field
              name="treatment.safetyConcerns"
              component={NoteInput}
              icon={<NoteIcon />}
              label={fieldLabels.safetyConcerns}
              required={fieldSettings.safetyConcerns.required}
              rows="4"
              helperText={helperText.safetyConcerns}
              maxLength={1000}
            />
                )}
            {fieldSettings?.expectedOutcomeMeasures && (
              <Field
                name="treatment.expectedOutcomeMeasures"
                component={NoteInput}
                icon={<NoteIcon />}
                label={fieldLabels.expectedOutcomeMeasures}
                required={fieldSettings.expectedOutcomeMeasures.required}
                rows="4"
                helperText={helperText.expectedOutcomeMeasures}
                maxLength={1000}
              />
              )}
            {fieldSettings?.anticipatedImpactOnPrognosis && (
            <Field
              name="treatment.anticipatedImpactOnPrognosis"
              component={NoteInput}
              label={fieldLabels.anticipatedImpactOnPrognosis}
              required={fieldSettings.anticipatedImpactOnPrognosis.required}
              icon={<NoteIcon />}
              rows="4"
              maxLength={1000}
            />
                )}
            {fieldSettings?.patientToxicity && (
            <Field
              name="treatment.patientToxicity"
              component={NoteInput}
              icon={<NoteIcon />}
              label={fieldLabels.patientToxicity}
              required={fieldSettings.patientToxicity.required}
              rows="4"
              maxLength={1000}
            />
            )}
            {fieldSettings?.transitionPlan && (
            <Field
              name="treatment.transitionPlan"
              component={NoteInput}
              label={fieldLabels.transitionPlan}
              required={fieldSettings.transitionPlan.required}
              icon={<NoteIcon />}
              rows="4"
              maxLength={1000}
            />
            )}
            {fieldSettings?.transitionPlanPaediatric && (
            <Field
              name="treatment.transitionPlanPaediatric"
              component={NoteInput}
              label={fieldLabels.transitionPlanPaediatric}
              required={fieldSettings.transitionPlanPaediatric.required}
              icon={<NoteIcon />}
              rows="4"
              helperText={helperText.transitionPlanPaediatric}
              maxLength={1000}
            />
                )}
          </>
        );
      }}
    />
  );
};

export default TreatmentComponent;
