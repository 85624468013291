import ReactDOM from "react-dom";

export default function PanelHeaderAction(props) {
  const headerAction = document.getElementById("header-action");
  if (headerAction === null) {
    return null;
  }
  return ReactDOM.createPortal(
    props.children,
    document.getElementById("header-action"),
  );
}
