import React from "react";
import { withTheme } from "@material-ui/core/styles";
import { Typography, Popover } from "@material-ui/core";

import DefaultButton from "components/items/default-button";
import PopupMenu from "components/items/popup-menu";
import IconButton from "components/items/icon-button";

const ApplicationRemoveButton = ({
  theme: { palette },
  onClick,
  title,
  loading,
  iconSize = 24,
}) => (
  <PopupMenu
    trigger={popupState => (
      <IconButton
        {...popupState}
        loading={loading}
        icon="delete"
        title="Delete"
        iconSize={iconSize}
        style={{ color: palette.error.dark }}
      />
    )}
    content={({ close, ...other }) => (
      <Popover
        {...other}
      >
        <div className="p-16">
          <Typography>{title || "Are you sure?"}</Typography>
          <div className="mt-16 flex">
            <DefaultButton
              onClick={() => { onClick(); close(); }}
              label="Confirm remove"
              size="medium"
              style={{ backgroundColor: palette.error.dark }}
            />
            <DefaultButton
              color="default"
              label="Cancel"
              className="ml-16"
              size="medium"
              variant="text"
              onClick={() => close()}
            />
          </div>
        </div>
      </Popover>
    )}
  />
);

export default withTheme(ApplicationRemoveButton);
