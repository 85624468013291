import React from "react";
import { isEmpty, map, find, size, get } from "lodash";
import { connect } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { fetchPatientEncounters } from "app/main/patients/actions/encounters.actions";
import { fetchPatientAppointments } from "app/main/patients/actions/appointments.actions";
import { isPatientWorklistLoading, isPatientWorklistErrorLoading, getCurrentPatientId, getTotalNumberOfPatient, getCurrentPageOfPatient, getWorklistFilter, getPatientSearchParams } from "app/main/worklist/reducers/worklist.reducers";
import { getPatientsForWorklist } from "app/main/patients/reducers/patients.reducers";
import { getEncounterByPatientIds } from "app/main/patients/reducers/encounters.reducers";
import { getAppointmentByPatientIds } from "app/main/patients/reducers/appointments.reducers";
import PatientHeader from "app/main/patients/components/patient-header";
import WorklistItem from "app/main/worklist/components/worklist-item";
import ChatPanel from "components/items/chat-panel/index";
import PatientOverviewPage from "app/main/patients/components/patient-overview-page";
import InfiniteLoadingList from "components/items/infinite-Loading-list";
import { setMobileContentSidebarStatus } from "app/store/actions/sidebars.actions";
import { searchPatients } from "app/main/worklist/actions/worklist.actions";
import WorklistAdvancedFilter from "app/main/worklist/components/worklist-advanced-filter";
import { getAllEnrolmentStatus, getEnrolmentTypeValues } from "app/auth/store/reducers/system-configuration";
import { getUser } from "app/auth/store/reducers/user.reducer";
import { getExistingPatientById } from "app/main/patients/actions/patients.actions";
import { fetchPatientAssessments } from "app/main/patients/actions/assessments.actions";
import withPermissions from "permissions/withPermissions";
import { isMobileContentSidebarOpen } from "app/store/reducers/sidebars.reducer";
import ViewPatientRecordButton from "app/main/patients/components/view-patient-record-button";
import { PatientWorklistIcon } from "helpers/icon-finder";
import { getPatientLabel } from "utils/get-environment-variables";

const patientLabel = getPatientLabel();

class WorklistPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedPatientId: this.props.selectedId,
      forceLoad: props.forceLoad,
    };
    this.onClickItem = this.onClickItem.bind(this);
  }

  // eslint-disable-next-line no-async-promise-executor
  onSearchPatient = (searchParams, page, pageSize, forceLoad) => new Promise(async resolve => {
    const params = searchParams;

    if (!isEmpty(params.assignedToUser)) {
      params.assignedToUserId = params.assignedToUser.value;
    }

    setTimeout(() => {
      // Prevent multiple calls at same time. So that the loading status can be checked and be used to decided whether to continue the next call.
      resolve(this.props.searchPatients({ ...params }, page || 1, pageSize || 20, forceLoad));
    }, 500);
  });

  onClickItem = selectedPatientId => {
    this.setState({ selectedPatientId });
    if (this.props.isMobileContentSidebarOpen) {
      this.props.setMobileContentSidebarStatus(false);
    }

    this.props.getExistingPatientById(selectedPatientId, true);

    if (this.props.hasPermissionEncountersView) {
      this.props.fetchPatientEncounters(selectedPatientId);
    }

    if (this.props.hasPermissionAppointmentsView) {
      this.props.fetchPatientAppointments(selectedPatientId);
    }

    if (this.props.hasPermissionAssessmentsView) {
      this.props.fetchPatientAssessments(selectedPatientId);
    }
  };

  onUpdateForceLoad = cond => this.setState({ forceLoad: cond })

  renderItem = (item, selectedItem) => {
    const { patient, patientSummaries, nextEncounter } = item;
    const salveoEnrolment = find(patientSummaries.enrolments, x => x.enrolmentType.value === "SalveoNursing") || {};
    const welfareCheckEnrolment = find(patientSummaries.enrolments, x => x.enrolmentType.value === "WelfareCheck") || {};
    return (
      <WorklistItem
        key={patient.patientId}
        active={!isEmpty(selectedItem) && selectedItem.patient.patientId === patient.patientId}
        onClick={() => this.onClickItem(patient.patientId)}
        title={patient.displayNameShort}
        enrolmentStatus={!isEmpty(salveoEnrolment) && (get(salveoEnrolment.enrolmentStatus, "value", null))}
        assignedToUser={(!isEmpty(salveoEnrolment) && salveoEnrolment.assignedToUser) || (!isEmpty(welfareCheckEnrolment) && welfareCheckEnrolment.assignedToUser)}
        nextEncounter={nextEncounter}
      />
    );
  };

  renderSidebarContent = selectedItem => {
    const { patients, total, loading, error, pageNumber } = this.props;
    const { forceLoad } = this.state;
    return (
      <InfiniteLoadingList
        initialLoad
        data={patients}
        renderItem={item => this.renderItem(item, selectedItem)}
        loading={loading}
        error={error}
        isEmptyStatus={isEmpty(patients)}
        total={total}
        pageStart={pageNumber}
        loadFunction={page => {
          const number = page === undefined ? 1 : pageNumber + 1;
          return this.onSearchPatient(this.props.patientSearchParams, number, null, forceLoad);
        }}
      />
    );
  };

  renderHeader = selectedItem => (
    <Box p={2}>
      <PatientHeader
        formatDOB={false}
        patient={selectedItem.patient}
        summary={selectedItem.patientSummaries}
        patientNameOnClick={() => {
          this.props.history.push(`/patients/${selectedItem.patient.patientId}`);
        }}
        rightContent={<ViewPatientRecordButton onClick={() => this.props.history.push(`/patients/${selectedItem.patient.patientId}`)}/>}
      />
    </Box>
  );

  renderContent = selectedItem => (
    <PatientOverviewPage
      patientId={selectedItem.patient.patientId}
      diseaseStates={selectedItem.patientSummaries.diseaseStates}
    />
  );

  render() {
    const {
      patients, patientSearchParams, total, initialValues, currentUser, enrolmentStatusChoices, enrolmentTypeChoices, presetFilter,
    } = this.props;
    const { selectedPatientId } = this.state;
    const selectedItem = find(patients, x => x.patient.patientId === selectedPatientId) || {};

    return (
      <ChatPanel
        empty={isEmpty(selectedItem)}
        renderSidebarHeader={() => (
          <WorklistAdvancedFilter
            initialValues={initialValues}
            currentUser={currentUser}
            enrolmentStatusChoices={enrolmentStatusChoices}
            enrolmentTypeChoices={enrolmentTypeChoices}
            searchParams={patientSearchParams}
            presetFilter={presetFilter}
            onSearch={this.onSearchPatient}
            onUpdateForceLoad={this.onUpdateForceLoad}
            renderFilterInfo={(
              <div>
                <Typography variant="caption">Total Results: {total}</Typography>
              </div>
            )}
          />
        )}
        renderSidebarContent={() => this.renderSidebarContent(selectedItem)}
        renderContent={() => this.renderContent(selectedItem)}
        renderHeader={() => this.renderHeader(selectedItem)}
        emptyIcon={<PatientWorklistIcon />}
        emptyTitle={`Select a ${patientLabel.single} to view details`}
      />
    );
  }
}

export default withRouter(withPermissions(
  "AssessmentsView",
  "EncountersView",
  "AppointmentsView",
)(connect(state => {
  let patients = getPatientsForWorklist(state);
  const encounters = getEncounterByPatientIds(state, map(patients, x => x.patient.patientId));
  const appointments = getAppointmentByPatientIds(state, map(patients, x => x.patient.patientId));

  patients = map(patients, patient => ({
    ...patient,
    lastEncounter: encounters[patient.patient.patientId].lastEncounter || {},
    nextEncounter: appointments[patient.patient.patientId].nextEncounter || {},
  }));

  const params = getPatientSearchParams(state);
  const presetFilter = getWorklistFilter(state);
  const currentUser = getUser(state);

  const enrolmentStatusChoices = getAllEnrolmentStatus(state);
  const enrolmentTypeChoices = getEnrolmentTypeValues(state);

  let enrolmentType = get(params, "enrolmentType", null);

  if (enrolmentType) {
    enrolmentType = find(enrolmentTypeChoices, type => type.value === enrolmentType);
  }

  let enrolmentStatus = get(params, "enrolmentStatus", []);
  let allEnrolmentStatus = true;

  if (!isEmpty(enrolmentStatus) && size(enrolmentStatus) !== size(enrolmentStatusChoices)) {
    allEnrolmentStatus = false;
  } else {
    enrolmentStatus = map(enrolmentStatusChoices, s => s);
  }

  if (params.encounterStartDate && params.encounterEndDate) {
    params.encounterDateRange = [params.encounterStartDate, params.encounterEndDate];
  }

  if (params.appointmentStartDate && params.appointmentEndDate) {
    params.appointmentDateRange = [params.appointmentStartDate, params.appointmentEndDate];
  }

  const patientFilter = {
    ...params,
    enrolmentStatus,
  };

  return ({
    patients,
    presetFilter,
    patientSearchParams: patientFilter,
    currentUser,
    enrolmentStatusChoices,
    enrolmentTypeChoices,
    loading: isPatientWorklistLoading(state),
    error: isPatientWorklistErrorLoading(state),
    selectedId: getCurrentPatientId(state),
    total: getTotalNumberOfPatient(state),
    pageNumber: getCurrentPageOfPatient(state),
    isMobileContentSidebarOpen: isMobileContentSidebarOpen(state),
    initialValues: {
      patientFilter: {
        ...patientFilter,
        allEnrolmentStatus,
        enrolmentType,
      },
    },
  });
},
{
  fetchPatientEncounters,
  fetchPatientAppointments,
  setMobileContentSidebarStatus,
  searchPatients,
  getExistingPatientById,
  fetchPatientAssessments,
})(WorklistPage)));
