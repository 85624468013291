import React from "react";
import AppointmentList from "app/main/patients/appointments/components/appointment-list";
import PatientRecordPageContent from "app/main/patients/components/patient-record-page-content";
import AppointmentListContainer from "app/main/patients/appointments/containers/appointment-list-container";

export default function PatientAppointmentPage({ patientId }) {
  return (
    <AppointmentListContainer
      patientId={patientId}
      showAllStatus
      renderContent={({
        appointments,
        // states
        isEmpty: isAppointmentsEmpty,
        isError: isAppointmentsError,
        isLoading: isAppointmentsLoading,
      }) => (
        <PatientRecordPageContent>
          <AppointmentList
            appointments={appointments}
            stateProps={{
              isEmpty: isAppointmentsEmpty,
              isError: isAppointmentsError,
              isLoading: isAppointmentsLoading,
            }}
          />
        </PatientRecordPageContent>
      )}
    />
  );
}
