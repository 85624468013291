import React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";

import DefaultPanelLayout from "components/items/default-panel-layout";
import EmptyState, { NoneRecordedState } from "components/items/empty-state";
import AdditionalInformationForm from "app/main/applications/components/additional-information-form";
import { AdditionalInformationIcon } from "helpers/icon-finder";
import ApplicationNoteContent from "app/main/applications/components/application-note-content";
import { getHelperTextBySectionName } from "app/auth/store/reducers/system-configuration";

const AdditionalInformationPanel = ({
  additionalInformation,
  canView,
  canEdit,
  openDialog,
  closeDialog,
  ...other
}) => {
  const onClose = () => closeDialog();
  const helperText = useSelector(state => getHelperTextBySectionName(state, "additionalInformation"));

  const openTreatmentForm = (title, isEdit) => {
    openDialog({
      children: (
        <AdditionalInformationForm
          additionalInformation={additionalInformation}
          onSucceed={onClose}
          isEdit={isEdit}
          title={title}
          canEdit={canEdit}
          {...other}
        />
      ),
    });
  };

  const onCreate = () => {
    openTreatmentForm("Add Additional Information");
  };

  const onUpdate = () => {
    openTreatmentForm("Edit Additional Information", true);
  };

  return (
    <DefaultPanelLayout
      title="Additional Information"
      icon={<AdditionalInformationIcon />}
      isEmpty={!additionalInformation}
      emptyState={canEdit
        ? <EmptyState icon={<AdditionalInformationIcon />} subtitle="Add Additional Information" onClick={canEdit ? () => onCreate() : null} />
        : <NoneRecordedState />}
      accessDenied={!canView}
      headerActions={canEdit
        && (!additionalInformation
          ? [
            {
              label: "Add Additional Information",
              icon: "add",
              onClick: () => onCreate(),
            },
          ] : [
            {
              label: "Edit Additional Information",
              icon: "edit",
              onClick: () => onUpdate(),
            },
          ])}
      subtitle={<Typography variant="caption" color="textSecondary">{helperText.additionalInformation}</Typography>}
    >
      <ApplicationNoteContent>{additionalInformation}</ApplicationNoteContent>
    </DefaultPanelLayout>
  );
};

export default AdditionalInformationPanel;
