import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { TableCell, TableRow, TableHead, TableBody, Table, TableContainer } from "@material-ui/core";
import { map, startCase, sortBy } from "lodash";
import withPermissions from "permissions/withPermissions";

import { fetchCodeSetValues } from "app/main/codeSet/actions/codeSet.actions";
import { CODE_SET_VALUE_PAGE_SIZE, getCurrentSearchParams } from "app/main/codeSet/reducers/codeSet.reducers";
import { getCodeSetTypes } from "app/auth/store/reducers/system-configuration";

const CodeSetPageContent = ({
  history,
}) => {
  const dispatch = useDispatch();
  const codeSetTypes = useSelector(getCodeSetTypes);
  const searchParams = useSelector(getCurrentSearchParams);

  const goToCodeSetPage = codeSetTypeCode => dispatch(fetchCodeSetValues(1, CODE_SET_VALUE_PAGE_SIZE, { ...searchParams, codeSetTypeCode }))
    .then(() => history.push(`/admin/code-sets/${codeSetTypeCode}`));

  const renderTableHeader = () => (
    <TableRow>
      <TableCell>Code Sets</TableCell>
    </TableRow>
  );

  const renderTableRow = codeSetType => (
    <TableRow hover key={codeSetType} onClick={() => goToCodeSetPage(codeSetType)}>
      <TableCell>{startCase(codeSetType)}</TableCell>
    </TableRow>
  );

  return (
    <TableContainer className="flex-1">
      <Table>
        <TableHead>{renderTableHeader()}</TableHead>
        <TableBody>{map(sortBy(codeSetTypes), x => renderTableRow(x))}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default withPermissions("UsersUpdate", "SpecialtyProfileMaintain")(CodeSetPageContent);
