import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";

import { getApiDescription } from "app/auth/store/reducers/system-configuration";

const useStyles = makeStyles(() => ({
  root: ({ gutter }) => ({
    padding: `0 ${gutter}px`,
    height: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  image: {
    height: "inherit",
  },
}));

const SiteLogo = ({
  gutter = 0,
  variant,
  classes,
}) => {
  const styles = useStyles({ gutter });
  const theme = useTheme();
  const apiDescription = useSelector(getApiDescription);

  let logo = null;

  if (theme.logo) {
    logo = theme?.logo[variant];
  }

  if (!logo) return null;

  return (
    <div className={clsx(styles.root, classes?.root)}>
      <img src={logo} alt={apiDescription} className={clsx(styles.image, classes?.image)} />
    </div>
  );
};

SiteLogo.propTypes = {
  variant: PropTypes.oneOf(["default", "header", "menu", "icon"]),
  gutter: PropTypes.number,
};

SiteLogo.defaultProps = {
  variant: "default",
  gutter: 8,
};

export default SiteLogo;
