import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { map, isEqual, startCase } from "lodash";
import clsx from "clsx";
import SquareRoundedIcon from "mdi-react/SquareRoundedIcon";
import SquareRoundedOutlineIcon from "mdi-react/SquareRoundedOutlineIcon";
import AsteriskIcon from "mdi-react/AsteriskIcon";
import { useDispatch } from "react-redux";
import { Accordion, AccordionDetails, Typography, Button, InputBase, List, ListItem } from "@material-ui/core";

import IconComponent from "components/items/icon-component";
import IconButton from "components/items/icon-button";
import Form from "components/form";
import ConfirmationDialog from "components/items/confirmation-dialog";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { editOrgUnitFormFields } from "app/main/orgUnits/actions/orgUnit.actions";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    borderTop: `1px solid ${theme.palette.divider}`,
    "&.is-last": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  list: {
    margin: theme.spacing(2, 0),
    padding: 0,
    width: "100%",
    minHeight: 48,
    border: `1px solid ${theme.palette.divider}`,
    "& >*:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  listItem: {
    display: "flex",
    padding: theme.spacing(1),
  },
  icon: {
    width: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.error.main,
  },
  button: {
    display: "flex",
    "&.required": {
      color: theme.palette.error.main,
    },
    "&.enabled": {
      color: theme.palette.success.main,
    },
  },
  primaryText: {
    color: theme.palette.text.secondary,
    flex: 1,
    "&.enabled": {
      color: theme.palette.text.primary,
      fontWeight: "bold",
    },
  },
}));

const ToggleButton = ({ item, onClick, label, property, ...other }) => {
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      size="small"
      onClick={() => onClick(item)}
      startIcon={(
        <div className={clsx(classes.button, item[property] && property)}>
          <IconComponent icon={item[property] ? <SquareRoundedIcon /> : <SquareRoundedOutlineIcon />} />
        </div>
      )}
      {...other}
    >
      {label}
    </Button>
  );
};

const OrgUnitFormFieldListItem = ({
  expanded,
  onExpand,
  form,
  isLast,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialFields = JSON.parse(form?.configurationJson);
  const [fieldItems, setFieldItems] = useState(initialFields);

  const handleSubmit = (_data, { setSubmitting }) => {
    dispatch(editOrgUnitFormFields(form.orgUnitId, form.formType, {
      configurationJson: JSON.stringify(fieldItems),
    })).finally(() => {
      onExpand();
      setSubmitting(false);
    });
  };

  const toggleItemProperty = (item, property) => setFieldItems(map(fieldItems, x => {
    if (x.fieldId === item.fieldId) {
      let extra = {};
      // reset other props if enable is false ??
      if (property === "enabled" && item.required === true) {
        extra = {
          required: false,
        };
      }

      return {
        ...x,
        ...extra,
        [property]: !x[property],
      };
    }
    return x;
  }));

  const toggleFieldEnabledStatus = item => toggleItemProperty(item, "enabled");

  const toggleFiledRequiredStatus = item => toggleItemProperty(item, "required");

  const onFieldLabelChange = (fieldId, label) => setFieldItems(map(fieldItems, x => {
    if (x.fieldId === fieldId) {
      return {
        ...x,
        label,
      };
    }
    return x;
  }));

  const onCloseForm = () => {
    if (!isEqual(initialFields, fieldItems)) {
      dispatch(openDialog({
        maxWidth: "xs",
        children: (
          <ConfirmationDialog
            type="warning"
            onCancel={() => dispatch(closeDialog())}
            onConfirm={() => {
              setFieldItems(initialFields);
              onExpand();
              dispatch(closeDialog());
            }}
            title="Unsaved data"
            content="If you choose to continue, all the data will be discarded"
          />
        ),
      }));
    } else {
      onExpand();
    }
  };

  return (
    <Accordion
      expanded={expanded}
      square
      elevation={0}
      classes={{ root: clsx(classes.root, isLast && "is-last"), expanded: "m-0" }}
    >
      <div className="flex items-center px-16 py-8">
        <div className="flex-1">
          <Typography className="mx-16">{startCase(form.formType)}</Typography>
        </div>
        <IconButton title={expanded ? "Close" : "Edit"} icon={expanded ? "clear" : "edit"} onClick={onCloseForm} iconSize={16} />
      </div>
      <AccordionDetails className="flex flex-col items-center">
        <Form
          onSubmit={handleSubmit}
          isModal={false}
          classes={{ content: "p-0" }}
          onCancel={onCloseForm}
          initialValues={initialFields}
          content={() => (
            <List className={classes.list}>
              {map(fieldItems, x => (
                <ListItem button key={x.fieldId} className={classes.listItem} disableRipple>
                  <div className="flex-1 flex items-center">
                    <div className={classes.icon}>
                      {x.required && <IconComponent icon={<AsteriskIcon />} size={12} />}
                    </div>
                    <InputBase
                      classes={{ root: clsx(classes.primaryText, x.enabled && "enabled") }}
                      defaultValue={x.label}
                      onChange={event => onFieldLabelChange(x.fieldId, event.target.value)}
                    />
                    <Typography color="textSecondary" variant="caption">{`${startCase(x.fieldName)}`}</Typography>

                  </div>
                  <div className="flex-row-container with-gutter ml-16">
                    <ToggleButton
                      item={x}
                      property="enabled"
                      label="Enabled"
                      disabled={x.enableRequired}
                      onClick={toggleFieldEnabledStatus}
                    />
                    <ToggleButton
                      onClick={toggleFiledRequiredStatus}
                      item={x}
                      property="required"
                      label="Mandatory"
                      disabled={!x.enabled || !x.editRequired}
                    />
                  </div>
                </ListItem>
              ))}
            </List>
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default OrgUnitFormFieldListItem;
