import moment from "moment-timezone";

import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import { isPageLoaded, isPageLoading } from "../reducers/patientReferralWorklist.reducers";

export const searchPatientReferrals = (searchParams, pageNumber = 1, pageSize = 20, forceLoad = false) => dispatch => {
  const params = formatSearchParams(searchParams);
  const timeZone = moment.tz.guess();
  const url = `/patientReferral?pageNumber=${pageNumber}&pageSize=${pageSize}&timeZone=${timeZone}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: state => isPageLoading(state, pageNumber) || (!forceLoad && isPageLoaded(state, pageNumber)),
      entityType: "PATIENT_REFERRAL_WORKLIST",
      pageNumber,
    },
  });
};
