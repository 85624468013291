import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ListItemIcon, ListItem, Hidden, ListItemText, Typography, Icon, Tooltip, Box } from "@material-ui/core";
import clsx from "clsx";

const breakpoints = "md";

const useStyles = makeStyles(theme => ({
  listItem: {
    "&:before": {
      content: '""',
      display: "block",
      width: 4,
      backgroundColor: "transparent",
      transition: "background 0.5s ease",
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
    },
    position: "relative",
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: theme.palette.divider,
    height: 56,
    padding: 0,
    flexDirection: "column",
    [theme.breakpoints.up(breakpoints)]: {
      flexDirection: "row",
      height: 88,
    },
  },
  indicator: ({ selected }) => ({
    "&:after": {
      content: '""',
      position: "absolute",
      right: 0,
      bottom: 0,
      width: 0,
      height: 0,
      transition: "border 0.3s",
      borderStyle: "solid",
      borderWidth: "0 0 40px 40px",
      borderColor: `transparent transparent ${selected ? theme.palette.primary[50] : theme.palette.divider} transparent`,
    },
  }),
  active: ({ activeColor, isGroup }) => ({
    "&:before": {
      backgroundColor: isGroup ? "transparent" : theme.palette.primary[500],
    },
    backgroundColor: activeColor,
  }),
  indicatorIcon: ({ selected }) => ({
    position: "absolute",
    right: 0,
    bottom: 0,
    color: selected ? theme.palette.primary[500] : theme.palette.grey[700],
    zIndex: theme.zIndex.tooltip,
  }),
  listItemIconContainer: {
    position: "relative",
    display: "flex",
    height: 32,
    width: 40,
    justifyContent: "center",
    marginTop: theme.spacing(1),
    zIndex: theme.zIndex.tooltip,
    [theme.breakpoints.up(breakpoints)]: {
      marginTop: 0,
      minWidth: 56,
    },
  },
  listItemIcon: {
    marginRight: 0,
    minWidth: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  statusIcon: ({ statusColor }) => ({
    width: 8,
    height: 8,
    borderRadius: 8 / 2,
    borderWidth: 2,
    borderColor: theme.palette.divider,
    position: "absolute",
    right: 0,
    bottom: 0,
    backgroundColor: statusColor,
  }),
}));

const PatientSurveyListItem = ({
  selected,
  onClick,
  icon,
  status,
  statusColor,
  statusIcon,
  component: StatusIconComponent = statusIcon,
  label,
  isGroup,
  activeColor,
  shortName,
}) => {
  const classes = useStyles({ activeColor, selected, isGroup, statusColor });

  return (
    <ListItem
      key={label}
      button
      className={clsx(classes.listItem, selected && classes.active)}
      onClick={onClick}
    >
      <Tooltip title={shortName ?? label}>
        <div className={classes.listItemIconContainer}>
          {icon
            && (
              <ListItemIcon className={classes.listItemIcon}>
                {icon}
              </ListItemIcon>
            )}
          <Hidden mdUp>
            {!isGroup && <div className={classes.statusIcon} />}
          </Hidden>
        </div>
      </Tooltip>
      <Hidden smDown>
        <ListItemText
          disableTypography
          classes={{ root: "mr-8" }}
          primary={<Box variant="caption" fontWeight={selected && isGroup ? "Bold" : "Regular"}>{label}</Box>}
          secondary={(
            <Typography
              variant="caption"
              className="flex items-center mt-8"
            >
              {statusIcon && <StatusIconComponent color={statusColor} size={12} className="mr-8" />}
              {status}
            </Typography>
            )}
        />
      </Hidden>
      {isGroup
        && (
          <div className={classes.indicator}>
            <Icon className={classes.indicatorIcon}>{selected ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
          </div>
        )}
    </ListItem>
  );
};

export default PatientSurveyListItem;
