import moment from "moment-timezone";

import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import { isPageLoading, isPageLoaded, ActionTypes, isCurrentLoading } from "../reducers/patientMerge.reducers";

export const resetPatientMergeLogsFilters = () => ({
  type: ActionTypes.RESET_PATIENT_MERGE_LOGS_FILTERS,
});

export const resetCurrentPatientMergeLog = () => ({
  type: ActionTypes.RESET_CURRENT_PATIENT_MERGE_LOG,
});

export const fetchPatientMergeLogResults = (pageNumber, pageSize, searchParams, forceLoad = true) => dispatch => {
  const params = formatSearchParams(searchParams);
  const timeZone = moment.tz.guess();
  const url = `/patientMerge?pageNumber=${pageNumber}&pageSize=${pageSize}&timeZone=${timeZone}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "PATIENT_MERGE_LOGS",
      pageNumber,
      bailout: state => isPageLoading(state, pageNumber) || (!forceLoad && isPageLoaded(state, pageNumber)),
    },
  });
};

export const addMergePatient = (masterId, deprecatedId) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patientMerge/add/${masterId}/${deprecatedId}`,
    entityType: "PATIENT_MERGE",
  },
});

export const getPatientMergeLogById = id => ({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patientMerge/${id}`,
    entityType: "CURRENT_PATIENT_MERGE_LOG",
    bailout: state => isCurrentLoading(state),
  },
});
