import React, { useEffect } from "react";
import { TableRow, TableCell } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, startCase } from "lodash";

import DefaultPageLayout from "components/layouts/default-page-layout";
import ScreenHeader from "components/items/screen-header";
import DefaultButton from "components/items/default-button";
import { fetchCodeSetValues } from "app/main/codeSet/actions/codeSet.actions";
import { CODE_SET_VALUE_PAGE_SIZE,
  CODE_SET_VALUE_PAGE_SIZE_OPTIONS,
  getCurrentCodeSet,
  isCurrentCodeSetLoading,
  getCurrentCodeSetPageInfo,
  getCurrentSearchParams,
  getCurrentErrorMessage } from "app/main/codeSet/reducers/codeSet.reducers";
import PagingTable from "components/items/paging-table";
import withPermissions from "permissions/withPermissions";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import CodeSetValueForm from "app/main/codeSet/components/code-set-value-form";
import { formatUtcDate } from "helpers/format-date-time";
import { DateTimeInputDisplayFormat } from "utils/date";
import CodeSetValueSearch from "app/main/codeSet/components/code-set-value-search";
import TextTag from "components/items/text-tag";

const CodeSetIndexPage = ({
  match: { params: { codeSetTypeCode } },
  hasPermissionCodeSetUpdate,
  hasPermissionCodeSetCreate,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(isCurrentCodeSetLoading);
  const error = useSelector(getCurrentErrorMessage);
  const pageInfo = useSelector(getCurrentCodeSetPageInfo);
  const currentCodeSet = useSelector(getCurrentCodeSet);
  const searchParams = useSelector(getCurrentSearchParams);

  const onSearch = (pageNumber, pagesize) => dispatch(fetchCodeSetValues(pageNumber, pagesize, { ...searchParams, codeSetTypeCode }));

  useEffect(() => {
    if (isEmpty(currentCodeSet?.codeSetValues) || currentCodeSet.type !== codeSetTypeCode) {
      onSearch(1, CODE_SET_VALUE_PAGE_SIZE);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeSetTypeCode]);

  const onChangeRowsPerPage = pageSize => onSearch(1, pageSize);
  const onChangePage = pageNumber => onSearch(pageNumber + 1, pageInfo.pageSize);

  const openCodeSetValueForm = (isEdit, codeSetValue) => dispatch(openDialog({
    children: (
      <CodeSetValueForm
        title={`${isEdit ? "Update" : "Add"} Code Set Value`}
        onSucceed={() => {
          dispatch(closeDialog());
          onSearch(1, CODE_SET_VALUE_PAGE_SIZE);
        }}
        currentCodeSetValue={codeSetValue}
        currentCodeSet={currentCodeSet}
      />
    ),
  }));

  const addCodeSetValue = () => openCodeSetValueForm();
  const editCodeSetValue = codeSetValue => openCodeSetValueForm(true, codeSetValue);

  const renderTableHeader = () => (
    <TableRow>
      <TableCell className="w-48" />
      <TableCell className="w-72">Code</TableCell>
      <TableCell>Description</TableCell>
      <TableCell>Active Date</TableCell>
      <TableCell>Deprecate Date</TableCell>
      <TableCell className="w-128">Sort Order</TableCell>
    </TableRow>
  );

  const renderTableRow = value => (
    <TableRow key={value.id} hover={hasPermissionCodeSetUpdate} onClick={hasPermissionCodeSetUpdate ? () => editCodeSetValue(value) : null}>
      <TableCell>{value.isActive === false ? <TextTag label="INACTIVE" /> : null}</TableCell>
      <TableCell>{value.code}</TableCell>
      <TableCell>{value.description}</TableCell>
      <TableCell>{formatUtcDate(value.activeDateTimeUtc, DateTimeInputDisplayFormat)}</TableCell>
      <TableCell>{formatUtcDate(value.deprecatedDateUtc, DateTimeInputDisplayFormat)}</TableCell>
      <TableCell>{value.sortOrder}</TableCell>
    </TableRow>
  );

  return (
    <DefaultPageLayout
      accessDenied={!(hasPermissionCodeSetUpdate || hasPermissionCodeSetCreate)}
      header={hasPermissionCodeSetCreate && (
        <ScreenHeader
          title={startCase(codeSetTypeCode)}
          renderSearch={<CodeSetValueSearch />}
          renderActions={(
            <DefaultButton
              label="Add Value"
              variant="outlined"
              color="inherit"
              icon="add"
              onClick={addCodeSetValue}
            />
          )}
        />
      )}
      content={(
        <PagingTable
          loading={loading}
          error={error}
          data={currentCodeSet?.codeSetValues}
          rowsPerPage={pageInfo.pageSize}
          columnSpan={3}
          pageSizeOptions={CODE_SET_VALUE_PAGE_SIZE_OPTIONS}
          page={pageInfo.pageNumber - 1}
          count={pageInfo.totalRecords}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
          emptyMessage="No Code Set Values Recorded"
          tableHead={renderTableHeader}
          tableRow={item => renderTableRow(item)}
        />
      )}
    />
  );
};

export default withPermissions("CodeSetUpdate", "CodeSetCreate")(CodeSetIndexPage);
