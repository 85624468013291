import moment from "moment-timezone";
import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";

import { ActionTypes, bailOutOfLoadingApplications, defaultPageNumber, defaultPageSize } from "../reducers/applicationWorklist.reducers";

export const setApplicationWorkListFilter = presetFilter => dispatch => dispatch({
  type: ActionTypes.SET_APPLICATION_WORKLIST_FILTERS,
  payload: { presetFilter },
});

export const searchApplications = (searchParams, pageNumber = defaultPageNumber, pageSize = defaultPageSize, forceLoad = false) => (dispatch, getState) => {
  const params = formatSearchParams(searchParams);
  const timeZone = moment.tz.guess();
  const url = `/applications/${searchParams.orgUnitId}?pageNumber=${pageNumber}&pageSize=${pageSize}&timeZone=${timeZone}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: !forceLoad && bailOutOfLoadingApplications(getState(), pageNumber),
      types: [
        ActionTypes.SEARCHING_APPLICATIONS,
        ActionTypes.SEARCHED_APPLICATIONS,
        ActionTypes.ERROR_SEARCHING_APPLICATIONS,
      ],
      pageNumber,
    },
  });
};
