import React from "react";
import { useDispatch, useSelector } from "react-redux";

import TabContent from "components/items/tab-content";
import PDFPreview from "components/items/pdf-preview";
import { getReferralPDFPreview, isReferralPDFPreviewLoading, errorLoadingReferralPDFPreview } from "app/main/referrals/reducers/referrals.reducers";
import { fetchPDFPreview } from "app/main/referrals/actions/referrals.actions";

export default ({
  referral,
  pdfWidth,
}) => {
  const dispatch = useDispatch();
  const pdfData = useSelector(getReferralPDFPreview);
  const base64Data = pdfData ? `data:application/pdf;base64,${pdfData}` : null;

  const isPDFDataLoading = useSelector(isReferralPDFPreviewLoading);
  const errorLoadingPdfData = useSelector(errorLoadingReferralPDFPreview);

  React.useEffect(() => {
    if (referral?.referralDocument.canViewPdf) {
      dispatch(fetchPDFPreview(referral.id));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TabContent withGutters accessDenied={!referral?.referralDocument.canViewPdf} loading={isPDFDataLoading} error={errorLoadingPdfData}>
      <PDFPreview file={base64Data} width={pdfWidth} />
    </TabContent>
  );
};
