import React, { useState, useEffect } from "react";
import { Icon, Input, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { truncate } from "lodash";

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    paddingLeft: theme.spacing(2),
  },
  input: {
    "&::-ms-clear": {
      display: "none",
    },
  },
}));

const SearchInput = ({
  value,
  onChange,
  onSearch,
  searchPlaceholder,
  renderExtraActions,
  classes,
}) => {
  const styles = useStyles();
  const [inputValue, setInputValue] = useState(value || "");

  useEffect(() => {
    setInputValue(value === null ? "" : value);
  }, [value]);

  const enterPressed = event => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      onSearch(inputValue);
    }
  };

  const inputChanged = event => {
    setInputValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <Input
      placeholder={truncate(searchPlaceholder) || "Search"}
      disableUnderline
      fullWidth
      value={inputValue}
      inputProps={{ "aria-label": "Search" }}
      onChange={inputChanged}
      onKeyPress={enterPressed}
      classes={{ root: styles.root, input: styles.input, ...classes }}
      endAdornment={(
        <>
          {value && (
            <IconButton onClick={() => { onSearch(null); setInputValue(""); }}>
              <Icon fontSize="small">clear</Icon>
            </IconButton>
          )}
          <IconButton onClick={() => onSearch(inputValue)}>
            <Icon fontSize="small">search</Icon>
          </IconButton>
          {renderExtraActions && renderExtraActions}
        </>
      )}
    />
  );
};

export default SearchInput;
