import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { find } from "lodash";
import { Typography } from "@material-ui/core";

import DefaultPanelLayout from "components/items/default-panel-layout";
import { loadApplicationHistory } from "app/main/applicationWorklist/actions/applicationHistory.actions";
import { getApplicationHistory, isApplicationHistoryLoading, isApplicationHistoryLoaded } from "app/main/applicationWorklist/reducers/applicationHistory.reducers";
import ActivityLog from "components/items/activity-log";
import ActivityLogItem from "components/items/activity-log/item";
import { getApplicationStatusValues } from "app/auth/store/reducers/system-configuration";

const ApplicationHistoryActivityLog = props => (
  <ActivityLog>
    {props.applicationHistory.map((history, index) => {
      const color = find(props.applicationStatusSettings, x => x.value === history.applicationStatus.value)?.color || "inherit";

      return (
        <ActivityLogItem
          key={history.id}
          isFirst={index === 0}
          isLast={index + 1 === props.applicationHistory.length}
          date={history.changedDateTime}
          user={history.changedBy}
          icon="label_important"
          title={<Typography style={{ color }} className="font-bold">{history.applicationStatus.label}</Typography>}
          content={history.reason}
          iconColor={find(props.applicationStatusSettings, x => x.value === history.applicationStatus.value)?.color || "inherit"}
        />
      );
    })}
  </ActivityLog>
);

const ApplicationHistoryPage = props => {
  const { id, status } = props.application;

  const dispatch = useDispatch();
  const applicationHistory = useSelector(state => getApplicationHistory(state, id));
  const applicationHistoryLoading = useSelector(state => isApplicationHistoryLoading(state, id));
  const applicationHistoryLoaded = useSelector(state => isApplicationHistoryLoaded(state, id));
  const applicationStatusChanged = applicationHistoryLoaded === true && status !== applicationHistory[0]?.applicationStatus.value;
  const applicationStatusSettings = useSelector(getApplicationStatusValues);

  React.useEffect(() => {
    dispatch(loadApplicationHistory(id, props.orgUnitId, applicationStatusChanged));
  }, [dispatch, id, applicationStatusChanged, props.orgUnitId]);

  return (
    <DefaultPanelLayout
      title="History"
      icon="history"
      isLoading={applicationHistoryLoading}
      isEmpty={applicationHistoryLoaded && applicationHistory.length === 0}
    >
      <ApplicationHistoryActivityLog applicationHistory={applicationHistory} applicationStatusSettings={applicationStatusSettings} />
    </DefaultPanelLayout>
  );
};

export default ApplicationHistoryPage;
