import React from "react";
import { Typography, Box } from "@material-ui/core";
import { isString, truncate } from "lodash";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import IconComponent from "components/items/icon-component";
import checkIfEmpty from "helpers/check-if-empty";
import { NoteIcon } from "helpers/icon-finder";

const useStyles = makeStyles(theme => ({
  prefix: {
    display: "flex",
    alignItems: "center",
    flex: "0 0 auto",
  },
  content: ({ textColor }) => ({
    flex: "1 1 auto",
    width: "100%", // fix for IE - text wrapping
    color: textColor || theme.palette.text.primary,
  }),
  prefixItem: {
    display: "flex",
    alignItems: "center",
    width: 48,
  },
}));

const ItemComponent = ({
  label,
  icon,
  content,
  className,
  iconClassName,
  labelClassName,
  contentClassName,
  style,
  iconColor,
  textColor,
  showRequiredLabel,
  labelProps,
  alignment,
}) => {
  const classes = useStyles({ textColor });

  return (
    <Box className={clsx("flex", alignment || "items-center", className)} style={style}>
      {(label || icon)
        && (
          <Box className={classes.prefix}>
            {icon
              && (
              <Box className={clsx(classes.prefixItem, iconClassName)}>
                <IconComponent icon={icon} color={iconColor} />
              </Box>
              )}
            {label
              && (
                <Box className={clsx(classes.prefixItem, labelClassName)}>
                  {isString(label) ? <Typography className="font-bold mr-4" variant="subtitle2" {...labelProps}>{label.toUpperCase()}</Typography> : label}
                  {showRequiredLabel ? <Typography color="error">*</Typography> : null}
                </Box>
              )}
          </Box>
        )}
      <Box className={clsx(classes.content, contentClassName)}>
        {isString(content) ? <Typography color="inherit">{content}</Typography> : content}
      </Box>
    </Box>
  );
};

export const ContextItem = ({
  label,
  icon,
  content,
  renderIfEmpty,
  showRequiredLabel,
  emptyContent = "Not Recorded",
  ...other
}) => {
  // do not render the component if content supplied is empty and renderIfEmpty is not set to true
  if (checkIfEmpty(content) && !renderIfEmpty && !showRequiredLabel) return null;
  return (
    <ItemComponent icon={icon} label={label} content={renderIfEmpty && checkIfEmpty(content) ? emptyContent : content} showRequiredLabel={showRequiredLabel} {...other} />
  );
};

export const ContextNoteItem = ({ content }) => <ContextItem icon={<NoteIcon />} content={truncate(content, { length: 150 })} />;

export default ItemComponent;
