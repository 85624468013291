import { get, concat, find, orderBy, filter, take } from "lodash";
import curryConnector from "utils/connectors";
import { addItem, updateItem, removeItem } from "app/main/patients/helpers/reducer-helper-functions";

export const INTERVENTIONS_STATE_KEY = "interventions";

const curry = fn => curryConnector(fn, INTERVENTIONS_STATE_KEY);

export const ActionTypes = {
  LOADING_INTERVENTIONS: "LOADING_INTERVENTIONS",
  LOADED_INTERVENTIONS: "LOADED_INTERVENTIONS",
  ERROR_LOADING_INTERVENTIONS: "ERROR_LOADING_INTERVENTIONS",

  LOADING_CURRENT_INTERVENTION: "LOADING_CURRENT_INTERVENTION",
  ERROR_LOADING_CURRENT_INTERVENTION: "ERROR_LOADING_CURRENT_INTERVENTION",
  LOADED_CURRENT_INTERVENTION: "LOADED_CURRENT_INTERVENTION",

  SAVED_INTERVENTION: "SAVED_INTERVENTION",
  UPDATED_INTERVENTION: "UPDATED_INTERVENTION",
  RESOLVE_INTERVENTION: "RESOLVE_INTERVENTION",
  RENEW_INTERVENTION: "RENEW_INTERVENTION",
  CREATED_INTERVENTION: "CREATED_INTERVENTION",
  DELETED_INTERVENTION: "DELETED_INTERVENTION",
};

const INITIAL_STATE = {
  all: {},
  current: null,
};

const resolveOrRenewIntervention = (state, patientId, data, entity) => {
  let arr = get(state.all, [patientId, entity], []);

  if (data.resolvedDateTimeUtc !== null) {
    // add resolved interventions to the bottom of list
    arr = concat(filter(arr, x => x.id !== data.id), [data]);
  } else {
    // get interventions without resolvedDate
    let unresolvedInterventions = filter(arr, x => x.resolvedDateTimeUtc === null);
    unresolvedInterventions = orderBy(concat(filter(unresolvedInterventions, x => x.id !== data.id), [data]), [p => p.recordedDateTimeUtc] || "", ["desc"]);

    // concat the ordered unresolved problems with the resolved problems at the bottom
    arr = concat(unresolvedInterventions, (filter(arr, x => x.id !== data.id && x.resolvedDateTimeUtc !== null)));
  }

  return {
    ...state,
    all: {
      ...state.all,
      [patientId]: {
        ...state.all[patientId],
        [entity]: arr,
      },
    },
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_INTERVENTIONS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: true } },
      };
    case ActionTypes.ERROR_LOADING_INTERVENTIONS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: false, error: action.payload.message } },
      };
    case ActionTypes.LOADED_INTERVENTIONS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]:
            {
              ...state.all[action.payload.patientId],
              loading: false,
              loaded: true,
              interventions: action.payload.interventions,
            },
        },
      };
    case ActionTypes.LOADING_CURRENT_INTERVENTION:
      return {
        ...state,
        current: {
          ...state.current,
          loading: true,
        },
      };
    case ActionTypes.ERROR_LOADING_CURRENT_INTERVENTION:
      return {
        ...state,
        current: {
          ...state.current,
          error: action.error,
        },
      };
    case ActionTypes.LOADED_CURRENT_INTERVENTION:
      return {
        ...state,
        current: action.payload.intervention,
      };
    case ActionTypes.SAVED_INTERVENTION:
    case ActionTypes.CREATED_INTERVENTION:
      return addItem(state, action.payload.patientId, action.payload.intervention, "interventions", false);
    case ActionTypes.UPDATED_INTERVENTION:
      return updateItem(state, action.payload.patientId, action.payload.intervention, "interventions");
    case ActionTypes.DELETED_INTERVENTION:
      return removeItem(state, action.payload.patientId, action.payload.intervention, "interventions");
    case ActionTypes.RENEW_INTERVENTION:
    case ActionTypes.RESOLVE_INTERVENTION:
      return resolveOrRenewIntervention(state, action.payload.patientId, action.payload.intervention, "interventions");
    case ActionTypes.SET_CURRENT_INTERVENTION:
      return {
        ...state,
        current: action.payload.intervention,
      };
    default:
      return state;
  }
};

export const areInterventionsLoading = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loading"], false));

export const areInterventionsLoaded = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loaded"], false));

export const interventionsLoadingError = curry(({ localState }, patientId) => get(localState, ["all", patientId, "error"], null));

export const getInterventionsByPatientId = curry(({ localState }, patientId) => get(localState, ["all", patientId, "interventions"], []));

export const getInterventionById = curry(({ localState }, patientId, interventionId) => {
  const interventions = get(localState, ["all", patientId, "interventions"], []);
  return find(interventions, x => x.id === interventionId) || {};
});

export const getCurrentIntervention = curry(({ localState }) => get(localState, ["current"], null));

export const getRecentInterventions = curry(({ localState }, patientId, number) => {
  const interventions = get(localState, ["all", patientId, "interventions"], []);
  return take(interventions, number);
});

export const isCurrentInterventionLoading = curry(({ localState }) => get(localState, ["current", "loading"], false));

export const getErrorMessage = curry(({ localState }, patientId) => get(localState, ["all", patientId, "error"], null));
