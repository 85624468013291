import React, { useCallback } from "react";
import { debounce } from "lodash";
import { Chip } from "@material-ui/core";

import { AutoComplete } from "components/inputs";
import { AssignedUserIcon } from "helpers/icon-finder";

const UserSelector = ({
  options,
  setNameParam,
  isLoading,
  includeInactive,
  defaultOptions,
  ...other
}) => {
  const selectedUser = other.field.value;

  const onInputChange = useCallback(debounce((event, val, action) => {
    if (action === "input") {
      setNameParam(val);
    }
  }, 300));

  // ensure currently selected user remains in the list of options
  const optionsIncludingSelected = selectedUser == null || options.some(x => x.value === selectedUser.value)
    ? options
    : [...options, selectedUser];

  return (
    <AutoComplete
      loading={isLoading}
      icon={<AssignedUserIcon />}
      options={optionsIncludingSelected}
      onInputChange={onInputChange}
      getOptionSelected={(o, val) => o.value === val.value}
      renderOption={option => (
        <div className="flex flex-wrap justify-between flex-auto">
          {option.label}
          {option.isActive === false && <Chip size="small" label="Inactive" />}
        </div>
      )}
      {...other}
    />
  );
};

export default UserSelector;
