import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Popover, Button } from "@material-ui/core";
import PopupMenu from "components/items/popup-menu";
import IconComponent from "components/items/icon-component";
import DefaultButton from "components/items/default-button";

const useStyles = makeStyles(theme => ({
  actionMenu: {
    height: 128,
    display: "flex",
    "& > *:not(:first-child):before": {
      content: '""',
      display: "block",
      width: 1,
      height: 128,
      backgroundColor: theme.palette.divider,
    },
  },
  actionButton: {
    width: 128,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 1),
  },
  buttonTitle: {
    marginTop: theme.spacing(1),
  },
  actionText: {
    padding: 0,
  },
}));

export const ActionButton = ({ icon, label, onClick, close }) => {
  const classes = useStyles();

  return (
    <Button color="secondary" classes={{ label: classes.actionButton, text: classes.actionText }} onClick={() => { onClick(); close(); }}>
      {icon && <IconComponent icon={icon} />}
      <Typography variant="caption" className={classes.buttonTitle}>{label}</Typography>
    </Button>
  );
};

export const TriggerButton = ({ icon = "add", label = "Add", ...props }) => <DefaultButton {...props} label={label} icon={icon} fab />;

const MultipleActionButtons = ({
  renderTrigger,
  children,
}) => {
  const validChildren = React.Children.toArray(children).filter(child => child !== null);
  const classes = useStyles();
  return (
    <PopupMenu
      trigger={popupState => renderTrigger({ ...popupState })}
      content={({ close, ...other }) => (
        <Popover
          {...other}
        >
          <div className={classes.actionMenu}>
            {React.Children.map(validChildren, child => React.cloneElement(child, { close }))}
          </div>
        </Popover>
      )}
    />
  );
};

export default MultipleActionButtons;
