import React from "react";
import { Typography } from "@material-ui/core";
import HelpRhombusOutlineIcon from "mdi-react/HelpRhombusOutlineIcon";
import DefaultPanelLayout from "components/items/default-panel-layout";
import { ApplicationItemWithWidth } from "app/main/applications/components/application-item";
import ApplicationPanelContent from "app/main/applications/components/application-panel-content";

const HelperLabel = label => <Typography className="font-bold" variant="subtitle2">{label }</Typography>;

const DecisionLetterTemplateHelper = () => (
  <DefaultPanelLayout
    title="Decision Template Helper"
    icon={<HelpRhombusOutlineIcon />}
  >
    <ApplicationPanelContent>
      <>
        <div className="items-container">
          <ApplicationItemWithWidth label={HelperLabel("«GivenName»")} content="Display the applicant given name" />
          <ApplicationItemWithWidth label={HelperLabel("«FamilyName»")} content="Display the applicant family name" />
          <ApplicationItemWithWidth label={HelperLabel("«ApplicantPositionTitle»")} content="Display the applicant's position title" />
          <ApplicationItemWithWidth label={HelperLabel("«ApplicantDepartment»")} content="Display the applicant department" />
          <ApplicationItemWithWidth label={HelperLabel("«ApplicantEmailAddress»")} content="Display the applicant email address" />
        </div>
        <div className="items-container with-horizontal-divider">
          <ApplicationItemWithWidth label={HelperLabel("«IPANumber»")} content="Display the IPA Number" />
          <ApplicationItemWithWidth label={HelperLabel("«ApplicationStatus»")} content="Display the application status" />
        </div>
        <div className="items-container with-horizontal-divider">
          <ApplicationItemWithWidth label={HelperLabel("«Medication»")} content="Display the application requested drug" />
        </div>
        <div className="items-container with-horizontal-divider">
          <ApplicationItemWithWidth label={HelperLabel("«PatientFullName»")} content="Display the patient full name" />
          <ApplicationItemWithWidth label={HelperLabel("«PatientDateOfBirth»")} content="Display the patient date of birth" />
          <ApplicationItemWithWidth label={HelperLabel("«PatientIdentifier»")} content="Display the patient identifier" />
        </div>
        <div className="items-container with-horizontal-divider">
          <ApplicationItemWithWidth label={HelperLabel("«Decision»")} content="Display the application decision status" />
          <ApplicationItemWithWidth label={HelperLabel("«ApprovalDate»")} content="Display the application decision date" />
          <ApplicationItemWithWidth label={HelperLabel("«ExpiryDate»")} content="Display the application decision expiry date" />
          <ApplicationItemWithWidth label={HelperLabel("«ApprovalDuration»")} content="Display the application decision approval duration" />
          <ApplicationItemWithWidth label={HelperLabel("«ApproverName»")} content="Display the application decision approver" />
          <ApplicationItemWithWidth label={HelperLabel("«DecisionRationale»")} content="Display the application decision rationale" />
          <ApplicationItemWithWidth label={HelperLabel("«CommentsForDecisionLetter»")} content="Display the comments for the decision letter" />
        </div>
      </>
    </ApplicationPanelContent>
  </DefaultPanelLayout>
);

export default DecisionLetterTemplateHelper;
