import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { map } from "lodash";
import { makeStyles, fade } from "@material-ui/core/styles";
import { Menu, MenuItem } from "@material-ui/core";

import { setCurrentTheme } from "app/store/actions/theme.actions";
import PopupMenu from "components/items/popup-menu";

const useStyles = makeStyles(theme => ({
  theme: {
    display: "flex",
    alignItems: "center",
  },
  blocks: {
    display: "flex",
    "& > div:not(:first-child)": {
      borderLeftWidth: 0,
      backgroundColor: "#fff",
    },
  },
  block: {
    height: 20,
    width: 20,
    display: "block",
    border: `1px solid ${fade(theme.palette.common.white, 0.5)}`,
  },
  text: {
    marginLeft: theme.spacing(1),
  },
}));

const ColorSchemeComponent = ({ themeName, primaryColor, secondaryColor, showLabel = true }) => {
  const classes = useStyles();

  return (

    <div className={classes.theme}>
      <div className={classes.blocks}>
        <div className={classes.block} style={{ backgroundColor: primaryColor }} />
        <div className={classes.block} style={{ backgroundColor: secondaryColor }} />
      </div>
      {showLabel && <div className={classes.text}>{themeName}</div>}
    </div>
  );
};

const ThemeSelector = () => {
  const dispatch = useDispatch();
  const currentTheme = useSelector(state => state.theme.currentTheme);

  const currentThemeName = useSelector(state => state.theme.currentThemeName);
  const themes = useSelector(state => state.theme.themes);

  const themeOptions = map(themes, (value, key) => {
    const primaryColor = value.palette.primary.main;
    const secondaryColor = value.palette.secondary.main;

    return {
      label: <ColorSchemeComponent themeName={key} primaryColor={primaryColor} secondaryColor={secondaryColor} />,
      value: key,
    };
  });

  return (
    <PopupMenu
      trigger={popupState => (
        <div {...popupState} className="flex px-8 cursor-pointer">
          <ColorSchemeComponent
            themeName={currentThemeName}
            primaryColor={currentTheme.palette.primary.main}
            secondaryColor={currentTheme.palette.secondary.main}
            showLabel={false}
          />
        </div>
      )}
      content={({ close, ...other }) => (
        <Menu {...other}>
          {map(themeOptions, option => (
            <MenuItem value={option.value} key={option.value} onClick={() => { dispatch(setCurrentTheme(option.value)); close(); }}>
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    />
  );
};

export default ThemeSelector;
