import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, map, startCase } from "lodash";
import { useHistory } from "react-router-dom";

import { fetchPatientAssessments, fetchAssessmentById, removeAssessment } from "app/main/patients/actions/assessments.actions";
import { getAssessmentsByPatientId, areAssessmentsLoading, assessmentsLoadingError } from "app/main/patients/reducers/assessments.reducers";
import DefaultButton from "components/items/default-button";
import getAssessmentStatus from "app/main/patients/helpers/get-assessment-status";
import { AssignedUserIcon } from "helpers/icon-finder";
import normaliseDateTime from "app/main/patients/helpers/normalise-date-time";
import StateContainer from "components/layouts/default-state-container";
import PatientContextListItem, { ItemContent } from "app/main/patients/components/patient-context-list-item";
import { DateItem } from "app/main/patients/components/patient-components";
import DefaultItem, { ContextItem } from "components/items/default-item";
import PatientRecordPageContent from "app/main/patients/components/patient-record-page-content";
import withPermissions from "permissions/withPermissions";
import { closeDialog } from "app/store/actions/dialog.actions";

const AssessmentStatus = status => (
  <DefaultItem
    icon="fiber_manual_record"
    content={getAssessmentStatus({ status }).label}
    iconColor={getAssessmentStatus({ status }).color}
  />
);

// if its empty create init assessment, else follow ups
const PatientAssessmentsPage = ({
  patientId,
  hasPermissionAssessmentsDelete,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const assessments = useSelector(state => getAssessmentsByPatientId(state, patientId));
  const isLoading = useSelector(state => areAssessmentsLoading(state, patientId));
  const isError = useSelector(state => assessmentsLoadingError(state, patientId));

  useEffect(() => {
    if (patientId) {
      dispatch(fetchPatientAssessments(patientId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onProcess = (assessmentId, status, closeContext) => {
    dispatch(fetchAssessmentById(patientId, assessmentId)).then(responds => {
      // double check on this, it doesn't have error in the object
      if (responds.error !== true) {
        closeContext();
        history.push({
          pathname: `/patients/${patientId}/assessments/${assessmentId}`,
          fromUrl: `${history.location.pathname}?tab=assessments`,
        });
      }
      // handle when its error
    });
  };

  const onRemove = assessmentId => dispatch(removeAssessment(patientId, assessmentId)).then(() => dispatch(closeDialog()));

  return (
    <PatientRecordPageContent>
      <StateContainer loading={isLoading} error={isError} empty={isEmpty(assessments)} emptyTitle="No Recorded Assessments">
        {map(assessments, (assessment, index) => {
          const { id, status, startedDateTimeUtc, type, assessmentBy } = assessment;
          const assessmentDate = normaliseDateTime(startedDateTimeUtc);
          let actionLabel = "View";

          if (status === "NotStarted") {
            actionLabel = "Start";
          } else if (status === "InProgress") {
            actionLabel = "Continue";
          }

          return (
            <PatientContextListItem
              key={id}
              isFirst={index === 0}
              title={startCase(type)}
              onRemove={hasPermissionAssessmentsDelete ? () => onRemove(id) : null}
              renderItemContent={() => (
                <ItemContent
                  title={startCase(type)}
                  status={AssessmentStatus(status)}
                  content={<DateItem date={assessmentDate} name={assessmentBy} />}
                />
              )}
              renderPreviewContent={({ close }) => (
                <>
                  {AssessmentStatus(status)}
                  <ContextItem icon="date_range" content={assessmentDate.dateTime} />
                  <ContextItem icon={<AssignedUserIcon />} content={assessmentBy} />
                  <DefaultButton
                    className="w-full"
                    variant="outlined"
                    onClick={() => onProcess(id, status, close)}
                    label={actionLabel}
                  />
                </>
              )}
            />
          );
        })}
      </StateContainer>
    </PatientRecordPageContent>
  );
};

export default withPermissions("AssessmentsDelete")(PatientAssessmentsPage);
