import React from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { isCurrentUserLoaded } from "app/auth/store/reducers/user.reducer";
import { AccessDeniedState } from "components/items/empty-state";
import LoadingState from "components/items/loading-state";
import withPermissions from "permissions/withPermissions";
import WorklistPage from "./worklist-page";

const SecureWorklistPage = ({ loading, location, hasPermissionPatientsWorklistView }) => {
  const forceLoad = get(location, ["state", "forceLoad"], false);

  if (loading) return <LoadingState />;

  if (!hasPermissionPatientsWorklistView) return <AccessDeniedState />;

  return <WorklistPage forceLoad={forceLoad} />;
};

const mapStateToProps = state => ({
  loading: isCurrentUserLoaded(state) === false,
});

export default withPermissions("PatientsWorklistView")(connect(
  mapStateToProps,
  {
    isCurrentUserLoaded,
  },
)(SecureWorklistPage));
