/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce, map, filter, split } from "lodash";

import { AutoComplete } from "components/inputs";
import { fetchPatientResults } from "app/main/patients/actions/patients.actions";
import { isPageLoading, PATIENTS_PAGE_SIZE } from "app/main/patients/reducers/patients.reducers";
import isSearchStringValid from "helpers/is-search-string-valid";
import { getPatientLabel } from "utils/get-environment-variables";
import { PatientSummary } from "app/main/patients/components/patient-details";
import IconButton from "components/items/icon-button";
import EmptyState from "components/items/empty-state";
import PatientSearchCard from "app/main/patients/components/patient-search-card";

const patientLabel = getPatientLabel();

const normaliseOptions = patients => map(patients, p => ({
  value: p.patientId,
  label: p.displayNameFull,
  ...p,
}));

export default function PatientSelector({ required, filteredPatient, icon, ...other }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => isPageLoading(state, 1));
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [initialOpen, setInitialOpen] = useState(false);
  const [selectedOption, setSelectOption] = useState(null);

  const { form, field } = other;

  const getPatientData = searchParams => {
    dispatch(fetchPatientResults(1, PATIENTS_PAGE_SIZE, searchParams)).then(res => {
      const newOptions = res?.payload?.patients;
      if (newOptions) {
        let updatedOptions = normaliseOptions(newOptions);
        if (filteredPatient) {
          updatedOptions = filter(updatedOptions, x => x.value !== filteredPatient.value);
        }
        setOptions(updatedOptions);
      }
    });
  };

  useEffect(() => {
    if (options && filteredPatient) {
      setOptions(filter(options, x => x.value !== filteredPatient.value));
    }
  }, [filteredPatient]);

  useEffect(() => {
    if (!isSearchStringValid(searchValue)) {
      setOpen(false);
    }
  }, [searchValue]);

  const onInputChange = (event, val) => {
    setSearchValue(val);
  };

  const onSearch = debounce(() => {
    setOptions([]);
    if (isSearchStringValid(searchValue)) {
      const searchParams = { name: searchValue };
      getPatientData(searchParams);
      setOpen(true);
      if (initialOpen === false) {
        setInitialOpen(true);
      }
    }
  }, 600);

  const onInputKeyPress = event => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      onSearch();
    }
  };

  const onClearSelection = () => {
    setOptions([]);
    setSelectOption(null);
    setSearchValue("");
    form.setFieldValue(field.name, null);
  };

  return (
    <>
      <div className="flex items-center">
        <AutoComplete
          className="flex-1"
          inputValue={searchValue}
          loading={isLoading}
          options={options}
          onInputChange={onInputChange}
          required={required}
          onKeyPress={onInputKeyPress}
          forcePopupIcon={false}
          clearOnBlur={false}
          open={open}
          helperText={field.value ? "" : "Enter Name, Identifier or Medicare Number to search"}
          noOptionsText={`No ${patientLabel.plural} Found`}
          getOptionSelected={(option, val) => option.value === val.value}
          renderOption={(option, { inputValue }) => {
            const searchWords = split(inputValue, " ");
            return <PatientSummary patient={option} highlightTerm={searchWords} />;
          }}
          filterOptions={allOptions => allOptions} // display all options return from api
          onClose={() => setOpen(false)}
          onBlur={() => {
            if (initialOpen) {
              form.setFieldTouched(field.name);
            }
          }}
          withDivider
          loadingText="Searching..."
          onChange={option => setSelectOption(option)}
          icon={icon}
          disableClearable
          {...other}
        />
        <IconButton title="Search" onClick={onSearch} icon="search" color="primary" />
      </div>
      <PatientSearchCard
        withColor={selectedOption}
        action={selectedOption && <IconButton title="Remove selection" icon="clear" onClick={onClearSelection} />}
        classes={{ root: "mt-0" }}
      >
        {selectedOption ? <PatientSummary patient={selectedOption} /> : <EmptyState icon={icon} />}
      </PatientSearchCard>
    </>
  );
}
