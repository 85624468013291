import React, { useState, useEffect } from "react";
import { map, size, head, isEmpty } from "lodash";
import { useSelector } from "react-redux";
import EmptyState from "components/items/empty-state";
import { getCurrentOrgUnitFormFieldConfigs, isCurrentLoading } from "app/main/orgUnits/reducers/orgUnit.reducers";
import OrgUnitFormFieldListItem from "../components/orgUnit-form-field-list-item";

export default () => {
  const loading = useSelector(isCurrentLoading);
  const forms = useSelector(getCurrentOrgUnitFormFieldConfigs);
  const [expanded, setExpanded] = useState("");

  useEffect(() => {
    // only set default expand when there is one set of form config
    if (!loading && size(forms) === 1) {
      setExpanded(head(forms)?.formType);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (loading) return null;

  if (isEmpty(forms)) return <EmptyState title="No Form Field configurations available" />;

  const handleChange = panel => {
    setExpanded(expanded === panel ? false : panel);
  };

  return map(forms, (form, index) => (
    <OrgUnitFormFieldListItem
      key={form.formType}
      form={form}
      onExpand={() => handleChange(form.formType)}
      expanded={expanded === form.formType}
      isLast={index + 1 === size(forms)}
    />
  ));
};