import React from "react";
import { Field } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import PoundIcon from "mdi-react/PoundIcon";
import NoteTextOutlineIcon from "mdi-react/NoteTextOutlineIcon";
import SortIcon from "mdi-react/SortIcon";

import Form from "components/form";
import { TextInput, DateTimePicker } from "components/inputs";
import { isNaN } from "lodash";
import { addCodeSetValue, editCodeSetValue } from "app/main/codeSet/actions/codeSet.actions";

const codeLabel = "Code";
const descriptionLabel = "Description";
const activeDateLabel = "Active Date Time";
const DeprecateDateLabel = "Deprecate Date Time";

const sortOrderLabel = "Sort Order";

const schema = Yup.object().shape({
  codeSetValue: Yup.object().shape({
    code: Yup.string()
      .trim().required(`${codeLabel} is required`)
      .max(50, "Too Long!")
      .nullable(),
    description: Yup.string()
      .trim().required(`${descriptionLabel} is required`)
      .max(255, "Too Long!")
      .nullable(),
    activeDateTimeUtc: Yup.date().default(null).nullable(),
    sortOrder: Yup.number().default(0).nullable()
      .transform(v => (isNaN(v) ? null : v)),
  }),
});

const CodeSetValueForm = ({
  currentCodeSet,
  currentCodeSetValue,
  onSucceed,
  ...other
}) => {
  const dispatch = useDispatch();
  const handleSubmit = ({ codeSetValue }, { setSubmitting }) => {
    const apiCall = codeSetValue.id ? editCodeSetValue : addCodeSetValue;

    dispatch(apiCall(currentCodeSet, codeSetValue)).then(response => {
      setSubmitting(false);
      if (response.error !== true) {
        onSucceed();
      }
    });
  };

  let { codeSetValue } = schema.cast();

  if (currentCodeSetValue) {
    codeSetValue = currentCodeSetValue;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ codeSetValue }}
      validationSchema={schema}
      contentProps={other}
      content={() => (
        <>
          <Field
            name="codeSetValue.code"
            component={TextInput}
            label={codeLabel}
            icon={<PoundIcon />}
          />
          <Field
            name="codeSetValue.description"
            component={TextInput}
            label={descriptionLabel}
            icon={<NoteTextOutlineIcon />}
          />
          <Field
            name="codeSetValue.activeDateTimeUtc"
            component={DateTimePicker}
            label={activeDateLabel}
          />
          <Field
            name="codeSetValue.deprecatedDateUtc"
            component={DateTimePicker}
            label={DeprecateDateLabel}
          />
          <Field
            name="codeSetValue.sortOrder"
            component={TextInput}
            label={sortOrderLabel}
            type="number"
            icon={<SortIcon />}
          />
        </>
      )}
    />
  );
};

export default CodeSetValueForm;
