import React from "react";
import { useSelector } from "react-redux";
import { find } from "lodash";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ContextItem } from "components/items/default-item";
import { calculateAge, toDOBFormat, toReadableAge } from "app/main/patients/helpers/format-dob";
import { addressIsEmpty } from "app/main/patients/helpers/check-object-is-empty";
import { getPatientIdentifier } from "app/main/patients/helpers/get-patient-display-item";
import { getPrimaryPatientIdentifier, getPatientIdentifierTypeOptions } from "app/auth/store/reducers/system-configuration";
import formatDateTime from "helpers/format-date-time";
import formatDate from "helpers/format-date";

import LoadingState from "components/items/loading-state";
import { getAllCodeSetValues } from "app/main/codeSet/reducers/codeSet.reducers";
import useReferralFieldSettings from "app/main/referrals/hooks/useReferralFieldSettings";

const useStyles = makeStyles(theme => ({
  headingBox: {
    backgroundColor: "#CBDEF3",
    padding: theme.spacing(1 / 2),
    border: "1px solid #002060",
    maxWidth: 615,
    width: "100%",
  },
  content: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
}));

const Item = ({ multiple, ...props }) => (
  <ContextItem
    labelClassName="w-256 mr-16"
    renderIfEmpty
    alignment="items-start"
    className={clsx(multiple && "flex-wrap sm:flex-no-wrap")}
    {...props}
  />
);

const NoteContent = ({ children }) => {
  const classes = useStyles();

  if (!children) return null;
  return (
    <Typography className={classes.content}>{children}</Typography>
  );
};

const Header = ({ header }) => {
  const classes = useStyles();
  return (
    <div className={classes.headingBox}>
      <Typography variant="body2" className="font-bold">{header}</Typography>
    </div>
  );
};

const ReferralDetailsPanel = ({
  referral,
}) => {
  const { patient, referralDocument } = referral;
  const primaryPatientIdentifier = useSelector(getPrimaryPatientIdentifier);
  const typeOptions = useSelector(state => getPatientIdentifierTypeOptions(state, true));
  const primaryPatientIdentifierLabel = find(typeOptions, x => x.value === primaryPatientIdentifier).label ?? "Identifier";

  const codeSetValues = useSelector(getAllCodeSetValues);
  const displayCodeSetValue = codeSetValueId => find(codeSetValues, x => x.id === codeSetValueId)?.description;

  const { isLoadingSettings, fieldSettings, fieldLabels } = useReferralFieldSettings();

  if (isLoadingSettings) return <LoadingState />;

  return (
    <>
      <Header header="Patient Information" />
      <div className="items-container my-8">
        <Item label={primaryPatientIdentifierLabel} content={getPatientIdentifier(patient.patientIdentifiers, primaryPatientIdentifier)} />
        <Item label="Patient" content={patient.displayNameFull} />
        <Item label="Medicare NO" content="" />
        <Item label="DOB" content={toDOBFormat(patient.birthDate)} />
        <Item label="Age" content={toReadableAge(calculateAge(patient.birthDate))} />
        <Item label="Gender" content={patient.sex} />
        <Item
          label="Residential Address"
          content={!addressIsEmpty(patient.address) ? `${patient.address.street}, ${patient.address.suburb}, ${patient.address.state}, ${patient.address.postcode}` : ""}
        />
        <Item label="Home Phone" content={patient.contactDetails.phoneHome} />
        <Item label="Mobile Phone" content={patient.contactDetails.phoneMobile} />
        <Item label="Work Phone" content={patient.contactDetails.phoneWork} />
      </div>
      <Header header="Referrer Information" />
      <div className="items-container my-8">
        {fieldSettings?.referrerFullName && <Item label={fieldLabels.referrerFullName} content={referralDocument.referrerFullName} />}
        <Item label="Provider Number" content={referralDocument.referrerProviderNumber} />
        {fieldSettings?.referrerOrganisationName && <Item label={fieldLabels.referrerOrganisationName} content={referralDocument.referrerOrganisationName} />}
        {fieldSettings?.designation && (<Item label={fieldLabels.designation} content={displayCodeSetValue(referral.referralDocument.designationCodeId)} />)}
        {fieldSettings?.referringLocation && (<Item label={fieldLabels.referringLocation} content={displayCodeSetValue(referral.referralDocument.referringLocationCodeId)} />)}
        {fieldSettings?.referringInternalLocation && (<Item label={fieldLabels.referringInternalLocation} content={displayCodeSetValue(referral.referralDocument.referringInternalLocationCodeId)} />)}
      </div>
      <Header header="Clinical Referral Information" />
      <div className="items-container my-8">
        {fieldSettings?.mbsConsent && (<Item label={fieldLabels.mbsConsent} content={referral.referralDocument.mbsConsent ? "Yes" : "No"} />)}
        <Item label="Referral date" content={formatDateTime(referral.referralDateUtc, true)} />
        {fieldSettings?.referralPeriod && <Item label={fieldLabels.referralPeriod} content={referral.referralPeriod} />}
        <Item label="Referred to" content={referral.assignedToDisplayName ?? referral.referredToServiceDescription} />
        {fieldSettings?.assignToSubSpecialty && <Item label={fieldLabels.assignToSubSpecialty} content={referral.referralAssignedToSubSpecialtyName} />}
        {fieldSettings?.specialistConsultant && (<Item label={fieldLabels.specialistConsultant} content={referral.referralDocument.specialistConsultant} />)}
        {fieldSettings?.reasonForReferralCode && (<Item label={fieldLabels.reasonForReferralCode} content={displayCodeSetValue(referral.referralDocument.reasonForReferralCodeId)} />)}
        {fieldSettings?.reasonNote && <Item label={fieldLabels.reasonNote} content={<NoteContent>{referral.reasonNote}</NoteContent>} multiple />}
        {fieldSettings?.presentingComplaintNote && <Item label={fieldLabels.presentingComplaintNote} content={<NoteContent>{referral.presentingComplaintNote}</NoteContent>} multiple />}
        {fieldSettings?.relevantPatientHistoryNote && <Item label={fieldLabels.relevantPatientHistoryNote} content={<NoteContent>{referral.relevantPatientHistoryNote}</NoteContent>} multiple />}
        {fieldSettings?.referrerAdvisedPriority && (<Item label={fieldLabels.referrerAdvisedPriority} content={displayCodeSetValue(referral.referralDocument.referrerAdvisedPriorityCodeId)} />)}
        {/* <----------------------------------------divider----------------------------------------> */}
        {fieldSettings?.typeOfReferral && (<Item label={fieldLabels.typeOfReferral} content={displayCodeSetValue(referral.referralDocument.typeOfReferralCodeId)} />)}
        {fieldSettings?.referralExpiry && (<Item label={fieldLabels.referralExpiry} content={formatDate(referral.referralDocument.referralExpiryDate)} />)}
        {fieldSettings?.claimType && (<Item label={fieldLabels.claimType} content={displayCodeSetValue(referral.referralDocument.claimTypeCodeId)} />)}
        {fieldSettings?.clinicType && (<Item label={fieldLabels.clinicType} content={displayCodeSetValue(referral.referralDocument.clinicTypeCodeId)} />)}
        {/* <----------------------------------------divider----------------------------------------> */}
        {fieldSettings?.interpreterRequired && <Item label={fieldLabels.interpreterRequired} content={referral.interpreterRequired ? "Yes" : "No"} />}
        {fieldSettings?.interpreterRequired && referral.interpreterRequired && <Item label="Preferred Language" content={referral.preferredLanguage} />}
        {referral.attachmentCount > 0 && <Header header="File Attachments" /> }
      </div>
    </>
  );
};

export default ReferralDetailsPanel;
