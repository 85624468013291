import { get, mapKeys, map, concat, isEmpty } from "lodash";
import curryConnector from "utils/connectors";
import { ActionTypes as WorkListActionTypes } from "app/main/candidateWorklist/reducers/candidateWorklist.reducers";

export const CANDIDATES_STATE_KEY = "candidates";
export const CANDIDATES_PAGE_SIZE = 50;
export const CANDIDATES_PAGE_SIZE_OPTIONS = [5, 10, 25, 50];

const curry = fn => curryConnector(fn, CANDIDATES_STATE_KEY);

export const ActionTypes = {
  LOADING_CANDIDATES: "LOADING_CANDIDATES",
  LOADED_CANDIDATES: "LOADED_CANDIDATES",
  ERROR_LOADING_CANDIDATES: "ERROR_LOADING_CANDIDATES",
  SET_CURRENT_CANDIDATE: "SET_CURRENT_CANDIDATE",
  REGISTERED_CANDIDATE: "REGISTERED_CANDIDATE",
};

const INITIAL_STATE = {
  all: {},
  pages: {},
  pageInfo: { pageNumber: 1, pageSize: CANDIDATES_PAGE_SIZE, totalRecords: 0 },
  searchParams: { },
  current: {},
  candidatesForWorkList: [],
};

function addCandidateEnrolments(state, action) {
  const { id, enrolments } = action.payload.candidate;
  const arr = get(state.all, [id, "enrolments"], []);
  return {
    ...state,
    all: { ...state.all,
      [id]: { ...state.all[id], ...action.payload.candidate, enrolments: isEmpty(arr) ? [enrolments] : concat(arr, enrolments) } },
    current: action.payload.candidate,
  };
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_CANDIDATES:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: true, error: false } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.ERROR_LOADING_CANDIDATES:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: false, error: action.payload.message } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.LOADED_CANDIDATES:
      return {
        ...state,
        all: { ...state.all, ...mapKeys(action.payload.candidates, x => x.id) },
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: false, error: false, ids: map(action.payload.candidates, x => x.id) } },
        pageInfo: action.payload.pageInfo,
        searchParams: action.payload.searchParams,
      };
    case WorkListActionTypes.SEARCHING_CANDIDATES:
      return {
        ...state,
        candidatesForWorkList: (action.payload.pageNumber === 1) ? [] : state.candidatesForWorkList,
      };
    case WorkListActionTypes.SEARCHED_CANDIDATES:
      return {
        ...state,
        all: { ...state.all, ...mapKeys(action.payload.candidates, x => x.id) },
        candidatesForWorkList: (action.payload.pageInfo.pageNumber === 1) ? map(action.payload.candidates, x => x.id) : concat(state.candidatesForWorkList, map(action.payload.candidates, x => x.id)),
      };
    case ActionTypes.REGISTERED_CANDIDATE:
      return addCandidateEnrolments(state, action);
    case ActionTypes.SET_CURRENT_CANDIDATE:
      return {
        ...state,
        current: action.payload.candidate,
      };
    default:
      return state;
  }
};

export const getAllCandidates = curry(({ localState }) => {
  const candidates = get(localState, ["all"], {});
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  return map(get(localState.pages, [pageNumber, "ids"], []), key => candidates[key]);
});

export const isPageLoading = curry(({ localState }) => {
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", pageNumber, "loading"], false);
});

export const isPageLoaded = curry(({ localState }) => {
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", pageNumber, "loading"], null) === false;
});

export const getErrorMessage = curry(({ localState }) => {
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", pageNumber, "error"], null);
});

export const getCandidateSearchTerms = curry(({ localState }) => localState.searchParams);

export const getPageInfo = curry(({ localState }) => localState.pageInfo);

export const getCandidateById = curry(({ localState }, patientId) => get(localState, ["all", patientId], {}));

export const getCurrentCandidate = curry(({ localState }) => localState.current);

export const getCandidatesForWorklist = curry(({ localState }) => {
  const candidates = get(localState, ["all"], {});
  return map(localState.candidatesForWorkList, key => candidates[key]);
});
